import React from "react";
// Customizable Area Start

import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TablePagination,
  TableCell,
  TableBody,
  Table,
  Box,
  IconButton,
  styled
} from "@material-ui/core";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import { TableRowsSkeleton } from "../../../components/src/customComponents/TableRowsSkeleton.web";
import RegionListController, { IRowData, Props, configJSON } from "./CfRegionListController.web";
import TablePopover from "../../../components/src/TablePopover.web";
import SortingTableHeader from "../../../components/src/SortingTableHeader";
import SortingTableCell from "../../../components/src/SortingTableCell";
import PageHeader from "../../../components/src/PageHeader";
import FilterIcon from "@material-ui/icons/FilterList";
import FilterPopover, { IFilter } from "../../../components/src/FilterPopover";
import { renderBaseOnConditions, sortStringCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";
import { FilterBox } from "../../../components/src/customComponents/CustomTags.web";
import CustomSwitch from "../../../components/src/CustomSwitch.web";
import { CustomSnackbar } from "../../../components/src/customComponents/CustomSnackbar.web";

export default class RegionList extends RegionListController {
  constructor(props: Props) {
    super(props);
  }

  handleReturnToggle = (rowData: IRowData) => {
    return(
      <CustomSwitch
        checked={rowData.enabled_zatca_invoice}
        onChange={(checked) => this.handleToggleCheck(checked, rowData)}
      />
    )
  };

  render() {
    const rows = this.state.regions
      .map((region) => {
        const areaCount = region?.attributes.area_count;
        const name = region?.attributes.branch_name;
        const email = region?.attributes.email;
        const phone = region?.attributes.telephone;
        const branchId = region?.attributes.branch_id;
        const enabled_zatca_invoice = region?.attributes.enabled_zatca_invoice;
        const status = region?.attributes.activated ? "Active" : "Deactivated";
        const area =
          region?.attributes.areas.reduce((prev, current, index) => {
            return (
              prev +
              current.attributes.area_name +
              (index === region?.attributes.areas.length - 1 ? "" : ", ")
            );
          }, "") || "--";
        const id = region?.id;

        return this.createData(name, email, phone, branchId, area, id, status, areaCount, enabled_zatca_invoice);
      });

    const filteredRowsRegion = rows.slice(
      this.state.page * this.state.pageSize,
      (this.state.page + 1) * this.state.pageSize
    );

    const emptyRowsRegion = this.state.pageSize - filteredRowsRegion.length;
    const sortingData = this.state.sortingData;

    return (
      <>
        <Box style={webStyle.mainWrapper}>
          
            <Box>
              <Box className="pageWrapper">
                <PageHeader
                  data-test-id='regionListHeader'
                  header={"Regions/Business Area"}
                  buttonText={"Add Region"}
                  permissionStatus={this.state.permissionStatus.createPermission}
                  filterComponent={
                    <div style={{ display: "flex" }}>
                      <FilterBox
                        data-test-id="filterBtn"
                        onClick={(event) => {
                          this.setState({
                            filterAnchor: event.currentTarget,
                          });
                        }}
                        className={sortStringCondition(
                          this.state.isAppliedFilter,
                          "active-border",
                          ""
                        )}
                      >
                        <FilterIcon color={this.handleReturnColorType()} />
                        {renderBaseOnConditions(
                          this.state.isAppliedFilter,
                          <div className="filterActiveIcon"></div>,
                          <></>
                        )}
                      </FilterBox>
                      <FilterPopover
                        onFilterChange={this.handleRegionFilterChange}
                        filters={this.state.filters}
                        anchor={this.state.filterAnchor}
                        onAutoCompleteChange={(title: string, value: string) => {
                          this.setState({ suggestionFieldTitle: title });
                          this.handleRegionAutoComplete(title, value);
                        }}
                        onClose={() => {
                          this.setState({ filterAnchor: undefined });
                        }}
                      />
                    </div>
                  }
                  onButtonClick={this.handleAddRegion}
                />
                <TableContainer component={Paper} className="flexItem">
                  <Table style={webStyle.table} aria-label='customized table'>
                    <TableHead className="stickyHeader">
                      <TableRow style={{ background: "#204B9C", color: "#FFFFFF" }}>
                        <SortingTableHeader
                          sortingData={sortingData}
                          title='Region Name'
                          sortingKey='branch_name'
                          {...this.sortingProps}
                          type='left'
                        />

                        <SortingTableHeader
                          sortingData={sortingData}
                          title='Email Address'
                          sortingKey='email'
                          {...this.sortingProps}
                        />

                        <SortingTableHeader
                          sortingData={sortingData}
                          title='Telephone'
                          sortingKey='telephone'
                          {...this.sortingProps}
                        />
                        <SortingTableHeader
                          sortingData={sortingData}
                          title='Branch ID'
                          sortingKey='branch_id'
                          {...this.sortingProps}
                        />
                        <SortingTableHeader
                          sortingData={sortingData}
                          title='Area'
                          sortingKey='area_name'
                          {...this.sortingProps}
                        />

                        <SortingTableHeader
                          sortingData={sortingData}
                          title='Area Count'
                          sortingKey='area_count'
                          {...this.sortingProps}
                        />
                        <SortingTableHeader
                          sortingData={sortingData}
                          title='Status'
                          sortingDisabled
                          sortingKey=''
                          {...this.sortingProps}
                        />
                        <SortingTableHeader
                          sortingData={sortingData}
                          title='Zatca invoice status'
                          sortingDisabled
                          sortingKey=''
                          {...this.sortingProps}
                        />
                        <SortingTableHeader
                          sortingData={sortingData}
                          title=''
                          {...this.sortingProps}
                          type='action'
                          sortingKey=''
                          sortingDisabled
                        />
                      </TableRow>
                    </TableHead>
                    {filteredRowsRegion.length > 0 && !this.state.isLoading && <TableBody>
                      {filteredRowsRegion.map((rowData, index) => (
                        <TableRow key={index + "TableRow"}>
                          <SortingTableCell width='20vw' type='left' content={rowData.name} />
                          <SortingTableCell width='15vw' content={rowData.email} />
                          <SortingTableCell width='15vw' content={rowData.phone} />
                          <SortingTableCell width='20vw' content={rowData.branchId} />
                          <SortingTableCell width='15vw' content={rowData.area} />
                          <SortingTableCell width='20vw' content={rowData.areaCount} />
                          <SortingTableCell width='20vw' content={rowData.status} />
                          <SortingTableCell
                            width='10vw'
                            type='right'
                            content={this.handleReturnToggle(rowData)}
                          />
                          <SortingTableCell
                            data-test-id="moreBtnTableCell"
                            width='20vw'
                            type='action'
                            content={
                              <IconButton
                                data-test-id={`moreButton-${rowData.id}`}
                                id={"IconButton" + index}
                                onClick={(event) => {
                                  const position = event.currentTarget.getBoundingClientRect();
                                  this.setState({
                                    popOverOpened: true,
                                    popOverItemId: rowData.id,
                                    popOverItemStatus: rowData.status,
                                    popOverTop: (position.top || 0) + window.scrollY,
                                    popOverLeft: (position.left || 0) + window.scrollX,
                                  });
                                }}
                              >
                                <MoreVertIcon />
                              </IconButton>
                            }
                          />
                        </TableRow>
                      ))}
                      {emptyRowsRegion > 0 && (
                        <TableRow
                          style={{
                            height: emptyRowsRegion * 53,
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>}
                    {this.state.isLoading && 
                    <>{Array(5)
                      .fill(1)
                      .map((_, index) => (
                        <TableBody key={index} data-test-id='tableData'>
                          <TableRow>
                          {[...Array(7)].map((_, cellIndex) => (
                             <TableRowsSkeleton key={cellIndex} />
                            ))}

                           
                          </TableRow>
                        </TableBody>
                      ))}</>
                  }
                  </Table>
                  {!this.state.isLoading && filteredRowsRegion.length === 0 && (
                    <div
                      style={{
                        width: "100%",
                        fontWeight: 601,
                        fontSize: 16,
                        textAlign: "center",
                      }}
                    >
                      {configJSON.noRecordFound}
                    </div>
                  )}
                </TableContainer>
                {filteredRowsRegion.length > 0 && <TablePagination
                  data-test-id='pagination'
                  component='div'
                  rowsPerPageOptions={[10]}
                  rowsPerPage={this.state.pageSize}
                  count={rows.length}
                  onPageChange={this.handlePageChange}
                  page={this.state.page}
                />}
              </Box>
              <TablePopover
                data-test-id={configJSON.popoverTestID}
                permissionStatus={this.state.permissionStatus}
                onClose={() => this.setState({ popOverOpened: false })}
                left={this.state.popOverLeft}
                open={this.state.popOverOpened}
                top={this.state.popOverTop}
                onView={() => this.handleViewRegion(this.state.popOverItemId)}
                onEdit={() => this.handleEditRegion(this.state.popOverItemId)}
                onActivate={this.handleActivateRegion}
                onDeactivate={() => this.handleDeactivateRegion()}
                isActive={this.state.popOverItemStatus === "Active" ? true : false}
                deactivateTitle='Region'
              />
              <CustomSnackbar
                data-test-id='custom-snackbar'
                open={this.state.snackbarOpen}
                onClose={this.handleCloseCustomSnackbar}
                errorMessage={this.state.snackbarMessage}
                severity={this.state.snackbarServerity}
                autoHideDuration={3000}
              />
            </Box>
          
        </Box>
      </>
    );
  }
}

const webStyle = {
  table: {
    minWidth: 700,
  },
  mainWrapper: {
    fontFamily: "Montserrat",
  },
  pageHeaderRegion: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "29px",
  },
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 32,
    alignItems: "center",
  },
  addButtonRegion: {
    background: "#204B9C",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
    height: "56px",
    width: "184px",
    borderRadius: "8px",
  },
  contentWrapper: {
    marginRight: 32,
    marginTop: 56,
  },
  filterButtonRegion: {
    border: "solid 1px rgba(32, 75, 156, 1)",
    display: "grid",
    height: "56px",
    width: "56px",
    placeItems: "center",
    borderRadius: "15px",
    cursor: "pointer",
  },
  tableHeaderRegion: {
    background: "#204B9C",
    color: "FFFFFF",
    width: "18%",
    fontSize: "18px",
    fontWeight: 600,
    fontFamily: "Montserrat !important",
  },
};

// Customizable Area End
