Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "NavigationMenu";
exports.labelBodyText = "NavigationMenu Body";

exports.btnExampleTitle = "CLICK ME";
exports.test = "test";

exports.openDrawerText = "Open Drawer";
exports.userName = "Jordan Ramsay";
exports.userDesignation = "Miami Herica";
exports.logout = "Logout";

exports.jsonApiContentType = "application/json";
exports.getMenuItemsEndPoint = "navmenu/navigation_menu";
exports.getApiMethodType = "GET";
exports.userShortNameKey = "userShortName";
exports.tokenKey = "token";
exports.subGroupKey = "sub_group_key";
exports.subPemrssion = "subPemrssion";
exports.orderPemrssion = "orderPemrssion";
exports.cashierCoverage = "cashierCoverage";
exports.plancoverage = "plantCoverage";
exports.stylecoverage = "stylecoverage";
exports.SearchGroupStore = "Search Group Store";
exports.StoreGroupFilter = "StoreGroupFilter";

exports.getNotificationCountEndPoint = "/bx_block_notifications/notifications/notification_count";

exports.Strings = {
  pageContainer: {
    logoutLabel: "Logout",
    viewProfileLabelValue: "View Profile",
    viewProfileLabel:"View Profile"
  }
}

exports.Strings = {
  pageContainer: {
    logoutLabel: "Logout",
    viewProfileLabelValue: "View Profile",
    viewProfileLabel:"View Profile"
  }
}
exports.className = {
  dummy: {
    logoutLabel: "className",
    viewProfileLabelValue: "View_Profile",
    viewProfileLabel:"View Profile"
  }
}

exports.getAllRegionEndPoint = "bx_block_regions/regions?dropdown=true&allow_access=true"
exports.getAllStoreEndPoint = "bx_block_store_management/store_managements?dropdown=true&allow_access=true"
exports.testgen = "testgen"
exports.StoreGroupWithArea = "Store Group With Area"

exports.dashboard = "Dashboard"
exports.NewStoreGroupStoreList = "NewStoreGroupStoreList"
exports.StoreGroupDropdownSearch = "StoreGroupDropdownSearch"
exports.SidebarB2BTabs = "SidebarB2BTabs"

exports.printerSettingApi = "bx_block_settings/printer_settings?printer_setting_type=receipt_printer"
exports.responsiveDrawerValue = {
  "1024": "165px",
  "1280": "206px",
  "1366": "220px",
}
exports.storeGrpSuggestionEndpoint = "bx_block_store_management/store_groups/store_group_dropdown_suggestion"
exports.b2bMenuItemsTest = "B2BSettingInnerItems"
// Customizable Area End