// Customizable Area Start
import React from "react";
import { Box, styled } from "@material-ui/core";
import { FilterList as FilterIcon } from "@material-ui/icons";
import FilterPopover, { IFilter } from "../../components/src/FilterPopover";
import { renderBaseOnConditions, sortStringCondition } from "../../blocks/utilities/src/CustomBlockHelpers"
import { LoadingComponent } from "../../components/src/Loader.web";
// Customizable Area End

// Customizable Area Start

interface Props {
  regionVal: string;
  storeVal: string;
  groupVal?: string;
  handleFilterButton: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  handleCloseFilterButton: () => void;
  handleFilterChange: (filters: IFilter[]) => void;
  handleNavigateTransfterAmt?: () => void;
  filterAnchor: HTMLDivElement | undefined;
  filters: IFilter[];
  periodDateRange: string;
  dateRange?: string;
  timeRange?: string;
  elementBeforeFilter?: React.ReactNode;
  elementAfterFilter?: React.ReactNode;
  isGroup? : boolean;
  isAppliedFilter?: boolean;
  filterLoading?: boolean;
}
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
const DashboardHeader: React.FC<Props> = (props) => {
  const {
    filters,
    filterAnchor,
    handleFilterButton,
    handleCloseFilterButton,
    handleFilterChange,
    regionVal,
    storeVal,
    periodDateRange,
    dateRange,
    timeRange,
    elementBeforeFilter = null,
    elementAfterFilter = null,
    isGroup,
    groupVal,
    isAppliedFilter = false,
    filterLoading = false
  } = props;

  const handleReturnColorType = () => {
    return isAppliedFilter ? "primary" : "inherit";
  };

  return (
    <>
      <Box className='headerWrapper'>
        <h2 className='PageHeading'>
          <span> Region :</span> <span className='datePeriod'>{regionVal}</span>
          <span className="storeval"> Store : </span>
          <span className='datePeriod'>{storeVal}</span>
         {isGroup && 
       (<>  <span className="storeval"> Group : </span>
          <span className='datePeriod'>{groupVal}</span></>)
          }
        </h2>
      </Box>
      <FlexStyleBox className='flexstyle'>
        <Box className="rangeBox" data-test-id="range-box">
          {
            dateRange ? 
            <h2 className='PageHeading'>
              Date : <span className='datePeriod'>{dateRange}</span>
            </h2>
            : null
          }
          <h2 className='PageHeading'>
            Period : <span className='datePeriod'>{periodDateRange}</span>{" "}
          </h2>
          {
            timeRange ? 
            <h2 className='PageHeading'>
              Time : <span className='datePeriod'>{timeRange}</span>
            </h2>
            : null
          }
        </Box>
        <Box className='d-flex'>
          {
            elementBeforeFilter
          }
          
          <LoadingComponent
            disableParentWidth
            isLoading={filterLoading}
            height={44}
            width={44}
            isAllowed={true}
            children={
              <div
                data-test-id='filterBtn'
                onClick={handleFilterButton}
                className={sortStringCondition(
                  isAppliedFilter,
                  "filterButtonAccount active-border",
                  "filterButtonAccount"
                )}
              >
                <FilterIcon color={handleReturnColorType()} />
                {renderBaseOnConditions(
                  isAppliedFilter,
                  <div className="filterActiveIcon"></div>,
                  <></>
                )}
              </div>
            }
          />
          <FilterPopover
            onClose={handleCloseFilterButton}
            anchor={filterAnchor}
            onFilterChange={handleFilterChange}
            filters={filters}
          />
          {
            elementAfterFilter
          }
        </Box>
      </FlexStyleBox>
    </>
  );
};

const FlexStyleBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

export default DashboardHeader;
// Customizable Area End
