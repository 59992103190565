// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { IFilterItem } from "../../promocodes/src/PromoCodeListController.web";
import { IFilter } from "../../../components/src/FilterPopover";
import { ISortingData } from "../../../components/src/OrderTable/src/TableCell";
import { navigateTo } from "../../utilities/src/CustomBlockHelpers";
export const configJSON = require("./config");
import { makeApiMessage } from "../../../components/src/common";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey,
  checkIsFilterApplied
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
import { sortCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";
import { handleMomentDateFormat } from "./utils";
import moment from "moment";
// Customizable Area End

// Customizable Area Start

export interface ITransactionList {
  id: number;
  type: string;
  attributes: {
    id: number;
    transfer_id: string;
    account_id: number;
    driver_account_id: number;
    transfer_from_id: number;
    transfer_from_type: string;
    transfer_to_id: number;
    transfer_to_type: string;
    status: string;
    driver_confirmed_at: string | null;
    driver_status: string;
    station: string | null;
    region_id: number;
    opening_cash_date: string | null;
    reason: string;
    created_at: string;
    driver: {
      id: number;
      first_name: string;
      last_name: string;
      full_name: string;
    };
    created_by: {
      id: number;
      first_name: string;
      last_name: string;
      full_name: string;
    };
    transfer_from: {
      id: number;
      first_name: string;
      last_name: string;
      full_name: string;
    };
    transfer_to: {
      id: number;
      store_name: string;
      store_id: string;
      store_address: string;
    };
    is_cashier: boolean;
    received_by: {
      id: number;
      first_name: string;
      last_name: string;
      full_name: string;
    };
    amount: string;
    currency: string;
    confirm_amount: string;
    variance: string;
    opening_cash: string;
    petty_cash: string;
    cash_from_orders: string;
    cash_in: string;
    cash_out: string;
    declare_cash: string;
    variance_amount: string
  };
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: {};
  id: string;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  filterAnchor: { currentTarget: {} } | undefined | HTMLDivElement;
  filters: IFilterItem[];
  searchText: string
  sortingData: ISortingData;
  selectedTab: string;
  page: number;
  pageSize: number;
  transactionList: Array<ITransactionList>;
  transactionMetaData: {
    [field: string]: string;
  };
  totalCount: number;
  isOpenAmtInput: boolean;
  editAmt: string;
  editAmtId: number | null;
  permissionStatus: PermissionStatus;
  query: string;
  regionIdQuery: string;
  storeIdQuery: string;
  isAppliedFilter: boolean;
  // Customizable Area End
}

export interface SS { }

export default class OutGoingTransactionController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getTransactionListMessageId: string = "";
  editAmtMessageId: string = "";
  exportAllApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.LayoutDataMessage),
      getName(MessageEnum.BroadcastNavbarDataMessage),
      getName(MessageEnum.SearchTextMessage)
    ];
    this.state = {
      isAppliedFilter: false,
      regionIdQuery:"",
      storeIdQuery: "",
      filterAnchor: undefined,
      page: 1,
      totalCount: 0,
      searchText:"",
      isOpenAmtInput: false,
      editAmt: "",
      editAmtId: null,
      pageSize: 10,
      filters: [
        {
          title: "Date",
          type: "dateselect",
          datevalue: { from: "", to: "" },
          value: "",
          options: [
            {
              label: "Today",
              value: "today",
            },
            {
              label: "Last 7 days",
              value: "last7",
            },
            {
              label: "Last 30 days",
              value: "last30",
            },
            {
              label: "Specific Dates",
              value: "specific",
            },
          ],
        },
      ],
      sortingData: {
        transfer_id: "",
        to_store: "",
        date: "",
        from: "",
        driver: "",
        amount: "",
        reason: "",
        variance: "",
        status: "",
      },
      selectedTab: configJSON.Tabs.Strings.outgoingTransaction,
      transactionList: [],
      transactionMetaData: {},
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      },
      query: ""
    };

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(_from: string, message: Message) {
    // Customizable Area Start
    const requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.handleTransactionListRes(requestId, responseJson);
    this.handleEditAmtRes(requestId, responseJson)
    this.handleResForExportAll(_from,message)
    this.receiveLayoutInfo(message)
    this.getBroadcastedData(message)
    this.obtainSearchText(message)
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getOutgoingTransactionList();
    // Customizable Area End
  }

  // Customizable Area Start

  receiveLayoutInfo = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.LayoutMessageData)
        );
        if (recievedData.userContext) {
          this.handleUserChange(recievedData.userContext)
        }
    }
  }

  getBroadcastedData = (message: Message) => {
    if (message.id === getName(MessageEnum.BroadcastNavbarDataMessage)) {
      const recievedData = message.getData(
        getName(MessageEnum.BroadcastNavbarData)
      );
      if (recievedData.regionMultiId) {
        this.setState({ regionIdQuery: `&filter_by[region_ids]=${recievedData.regionMultiId}`, storeIdQuery: `&filter_by[store_ids]=${recievedData.storeId}` }, () => this.checkStorageFilter())
      }
    }
  }

  obtainSearchText = (message: Message) => {
    if (message.id === getName(MessageEnum.SearchTextMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.SearchMessageText)
        );
        if (recievedData) {
          this.handleSearch(recievedData.searchText)
        }
    }
  }
  
  getOutgoingTransactionList(pageNumber = 1) {
    const dateFilterQuery = this.handleDateFilterParam().dateFilterQueryVal
    const apiUrl =
      configJSON.transferRequestsListApi +
      `?request_type=outgoing` +
      `&page_no=${pageNumber}${this.state.query}${sortCondition(this.state.searchText as unknown as boolean, "&filter_by[query]=" + this.state.searchText, "")}${dateFilterQuery}${this.state.regionIdQuery}${this.state.storeIdQuery}&request_page=cashier`;
    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: "GET",
    });

    this.getTransactionListMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleTransactionListRes(
    requestId: string,
    response: { data: []; meta: { [field: string]: string } }
  ) {
    if (requestId === this.getTransactionListMessageId) {
      this.setState({
        transactionList: response?.data,
        transactionMetaData: response?.meta,
        totalCount: Number(response?.meta?.total_count),
      });
    }
  }

  handleDateFilterParam = () => {
    const dateFilter = this.state. filters.find((item) => item?.title === 'Date')?.value;
    let startDate, endDate;
    const today = moment().format('YYYY-MM-DD');
    let commonEndDate = today;
    let comomStartDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
    switch (dateFilter) {
      case 'last7':
        endDate = commonEndDate;
        startDate = comomStartDate;
        break;
      case 'today':
        endDate = today;
        startDate = today;
        break;
      case 'specific':
        const dateFilter = this.state.filters.find((item) => item?.title === 'Date')?.datevalue;
        if (dateFilter?.from && dateFilter?.to) {
          startDate = moment(dateFilter.from).format('YYYY-MM-DD');
          endDate = moment(dateFilter.to).format('YYYY-MM-DD');
        }
        break;
      case 'last30':
        endDate = today;
        startDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
        break;
      default:
        endDate = today;
         startDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
    }

    const dateFilterQueryVal = startDate && endDate 
      ? `&filter_by[created_at]=true&filter_by[start_date]=${startDate}&filter_by[end_date]=${endDate}` 
      : '';

    const endDateText = endDate ? handleMomentDateFormat(endDate,'DD-MM-YYYY') : moment().format('DD-MM-YYYY')
    const startDateText = startDate ? handleMomentDateFormat(startDate,'DD-MM-YYYY') : moment().subtract(7, 'days').format('DD-MM-YYYY')
    return {dateFilterQueryVal, startDateText, endDateText}
  };

  handleSearch = (value: string) => {
    this.setState({ page: 1, searchText: value },()=> this.getOutgoingTransactionList());
  };

  handleFilterChange = (filters: IFilter[]) => {
    if (checkIsFilterApplied(filters)) {
      localStorage.setItem("OutGoingTrans_filter_value", JSON.stringify(filters));
    } else {
      localStorage.removeItem("OutGoingTrans_filter_value");
    };
    this.setState({
      isAppliedFilter: checkIsFilterApplied(filters),
      filters,
      page: 1,
    },this.getOutgoingTransactionList);
  };

  getTableCellType(index: number) {
    if (index === 0) {
      return "left";
    } else if (index === 8) {
      return "right";
    } else {
      return "middle";
    }
  }

  getSortingProps() {
    return {
      sortingData: this.state.sortingData,
      onChange: (data: ISortingData) => this.setState({ sortingData: data }),
      onQueryChange: (query: string) => this.handleQueryChange(`&${query}`),
    };
  }

  handleQueryChange = (query: string) => {
    this.setState({ query }, () => this.getOutgoingTransactionList());
  };

  handlePageChange(pageVal: number) {
    this.setState({ page: pageVal + 1 }, () =>
      this.getOutgoingTransactionList(pageVal + 1)
    );
  }

  handleNavigateTransfterAmt = () => {
    navigateTo({
      props: this.props,
      screenName: "TransferAmount",
    });
  };

  handleTabSeletion = (event: React.ChangeEvent<{}>, newValue: string) => {
    this.setState({ selectedTab: newValue });
    const tabString = configJSON.Tabs.Strings;
    if (newValue === tabString.overview) {
      navigateTo({
        props: this.props,
        screenName: "CfCashier",
      });
    } else {
      navigateTo({
        props: this.props,
        screenName: "IncomingTransaction",
      });
    }
  };

  handleFilterButton = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    this.setState({
      filterAnchor: event.currentTarget,
    });
  };

  handleCloseFilterButton = () => {
    this.setState({
      filterAnchor: undefined,
    });
  };

  handleRedirectDetail = (item:ITransactionList,index:number)=>{
    if(index === 8){
      return
    }
    navigateTo({
      id: `cashier/${item.id}`,
      props: this.props,
      screenName: "OutGoingTransactionDetails",
    });
  }

  handleOpenAmtInput = (item: ITransactionList) => {
    this.setState({
      isOpenAmtInput: true,
      editAmt: item.attributes.amount,
      editAmtId: item.id,
    });
  };

  handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!isNaN(Number(value))) {
      this.setState({
        editAmt: value,
      });
    }
  };

  handleUpdateConfirmAmt = () => {
    const body = {
      amount: this.state.editAmt,
    };
    const message = makeApiMessage({
      url: `${configJSON.transferRequestsListApi}/${this.state.editAmtId}`,
      method: "PUT",
      body: JSON.stringify(body),
    });

    this.editAmtMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  handleEditAmtRes(
    requestId: string,
    response: { status: number; message: string }
  ) {
    if (requestId === this.editAmtMessageId) {
      this.setState(
        {
          isOpenAmtInput: false,
          editAmt: "",
          editAmtId: null,
        },
        () => this.getOutgoingTransactionList()
      );
    }
  }

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.cashierPermission;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    })
  };

  exportAll = async () => {
    const dateFilterQuery = this.handleDateFilterParam().dateFilterQueryVal
    const requestMessage = makeApiMessage({
      method: configJSON.validationApiMethodType,
      url: `${configJSON.exportAllEndpoint}?request_type=outgoing${dateFilterQuery}${this.state.storeIdQuery}&request_page=cashier`,
    });

    this.exportAllApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResForExportAll = async (from: string, message: Message) => {
    if (this.exportAllApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        location.href = responseJson.data.url;
      }
    }
  }

  checkStorageFilter = () => {
    const applied_customer_filter = localStorage.getItem("OutGoingTrans_filter_value");
    if (applied_customer_filter) {
      this.setState(
        {
          filters: JSON.parse(applied_customer_filter),
          isAppliedFilter: checkIsFilterApplied(
            JSON.parse(applied_customer_filter)
          ),
        },
        () => {
          this.getOutgoingTransactionList()
        }
      );
    } else {
      this.getOutgoingTransactionList()
    }
  };

  // Customizable Area End
}
