import React from "react";

// Customizable Area Start
import {
  Box,
  InputLabel,
  Paper,
  Grid,
  TextField,
  styled,
} from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import { CustomSnackbar } from "../../../components/src/customComponents/CustomSnackbar.web";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";
import AddOrganizationController, {
  Props,
  configJSON,
} from "./AddOrganizationController.web";
import { Styles } from "@material-ui/styles";
import { withStyles, Theme } from "@material-ui/core/styles";
import { colors } from "../../utilities/src/Colors";
// Customizable Area End

export class AddOrganization extends AddOrganizationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  checkError(error: any, touch: any) {
    if (error && touch) {
      return true;
    }
  }

  finalErrorMessage(error: any, touch: any) {
    if (error) {
      if (touch) {
        return (
          <div
            data-test-id='nameError'
            style={{ color: "red", fontSize: "14px", margin: "5px 5px 5px 0" }}
          >
            {error}
          </div>
        );
      } else {
        return null;
      }
    } else {
      return;
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const validationSchema = Yup.object({
      org_name: Yup.string().required("Organization name is required."),
      org_taxnumber: Yup.string().nullable()
        .test(
          "no-leading-space",
          "Tax number cannot start with spaces",
          (value) => {
            if (value) {
              // Check if the input starts with spaces
              const startsWithSpace = /^\s/.test(value);
              return !startsWithSpace;
            }
            return true;
          }
        )
        .test(
          "no-special-characters",
          "Tax number cannot contain special characters",
          (value) => {
            if (value) {
              // Check for special characters using a regular expression
              const specialCharsRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\|/]/;
              const containsSpecialChars = specialCharsRegex.test(value);
              return !containsSpecialChars;
            }
            return true;
          }
        ),
    });
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box>
        <CustomLoader data-test-id='loader' loading={this.state.isLoading} />
        
          <Box className={classes.mainContainer}>
            <Box className={classes.orgTitleContainer}>
              <p className={classes.orgTitle}>
                {this.state.isEdit
                  ? configJSON.editOrganization
                  : configJSON.createOrganization}
              </p>
            </Box>
            <Formik
              data-test-id='formikAddOrganization'
              initialValues={this.state.addOrgintialValue}
              validationSchema={validationSchema}
              onSubmit={(values: any, actions: any) => {
                this.handleSubmit(values);
              }}
              enableReinitialize={true}
            >
              {({ errors, touched, handleSubmit, getFieldProps }: any) => (
                <form
                  onSubmit={handleSubmit}
                  data-test-id='addOrganizationForm'
                >
                  <CustomPaper>
                    <Grid container spacing={3} id='modal-content'>
                      <Grid item xs={12} md={6}>
                        <InputLabel className={classes.fieldLabel}>
                          {configJSON.orgName}
                        </InputLabel>
                        <InputField
                          error={this.checkError(
                            errors.org_name,
                            touched.org_name
                          )}
                          data-test-id='orgNameInput'
                          id='org_name'
                          name='org_name'
                          fullWidth={true}
                          placeholder={configJSON.orgNamePlaceholder}
                          variant='outlined'
                          {...getFieldProps("org_name")}
                        />
                        {this.finalErrorMessage(
                          errors.org_name,
                          touched.org_name
                        )}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <InputLabel className={classes.fieldLabel}>
                          {configJSON.orgTaxNumber}
                        </InputLabel>
                        <InputField
                          error={this.checkError(
                            errors.org_taxnumber,
                            touched.org_taxnumber
                          )}
                          data-test-id='orgTaxInput'
                          id='org_taxnumber'
                          name='org_taxnumber'
                          fullWidth={true}
                          placeholder={configJSON.orgNumberPlaceholder}
                          variant='outlined'
                          {...getFieldProps("org_taxnumber")}
                        />
                        {this.finalErrorMessage(
                          errors.org_taxnumber,
                          touched.org_taxnumber
                        )}
                      </Grid>
                    </Grid>
                  </CustomPaper>
                  <Box className={classes.btnDiv}>
                    <PrimayBtn
                      data-test-id='btnCancel'
                      onClick={() => this.handleBackTotListing()}
                      className='buttonSecondary'
                    >
                      Cancel
                    </PrimayBtn>
                    <PrimayBtn type='submit'>
                      {this.state.isEdit ? "Save" : "Add"}
                    </PrimayBtn>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
          <CustomSnackbar
            data-test-id='errorSnakBar'
            open={this.state.errorSnackbarOpen}
            onClose={this.handleSnackbarClose}
            errorMessage={this.state.errorMessage}
            severity='error'
          />
          <CustomSnackbar
            data-test-id='succesSnakBar'
            open={this.state.successSnackbarOpen}
            onClose={this.handleSuccessSnackbarClose}
            errorMessage={this.state.successMessage}
            severity='success'
          />
       
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const InputField: any = withStyles({
  root: {
    width: "100%",
    paddingTop: "10px",
    borderRadius: "8px",
    fontFamily: "Montserrat",
    "& .MuiOutlinedInput-root": {
      fontWeight: 400,
      fontSize: "14px",
      border: "1px solid #ECECEC",
      fontFamily: "Montserrat",
      borderRadius: "8px",
      "& fieldset": {
        fontSize: "14px",
        borderRadius: "8px",
        borderWidth: 1,
        fontWeight: 400,
        fontFamily: "Montserrat",
      },
    },
    "& ::placeholder": {
      opacity: 1,
      color: colors().darkliver,
      fontSize: "14px",
      fontFamily: "Montserrat",
      "@media only screen and (max-width: 1024px)": {
          opacity: 1,
          color: colors().darkliver,
          fontSize: "12px",
          fontFamily: "Montserrat",
      },
    },
    "@media only screen and (max-width: 1024px)": {
      '& .MuiInputBase-input': {
        padding:'14.9px 14px'
      },
    },
  },
})(TextField);

const CustomPaper: any = withStyles({
  root: {
    borderRadius: "12px",
    background: "#fff",
    boxShadow:
      "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 5px 32px rgba(0, 0, 0, 0.06)",
    padding: "32px",
  },
})(Paper);

const PrimayBtn = styled("button")({
  background: "#204B9C",
  color: "#FFFFFF",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "24px",
  padding: "16px 0",
  width: "126px",
  borderRadius: "8px",
  marginRight: "32px",
  textTransform: "none",
  outline: "none",
  border: "none",
  cursor: "pointer",
  "&.buttonSecondary": {
    background: "#F1F5F9",
    color: "#64748B",
  },
  "@media only screen and (max-width: 600px)": {
    width: "calc(100% - 64px)",
    marginRight: 0,
    marginTop: 16,
  },
  "@media only screen and (max-width: 1024px)": {
    fontSize: "16px",
    width: "128px",
    height: "44px",
    padding: "10px 0",
  },  
});

export const useStyles = () => {
  return {
    mainContainer: {
      width: "100%",
      fontFamily: "Montserrat, sans-serif",
    },
    orgTitleContainer: {
      display: "flex",
      padding: "32px 0",
    },
    orgTitle: {
      fontSize: "24px",
      lineHeight: "30px",
      fontWeight: 600,
      fontStyle: "normal",
      fontFamily: "Montserrat",
      color: colors().black,
      "@media only screen and (max-width: 1024px)": {
        fontSize: "16px",
        fontWeight: 600,
        color: colors().viewTextColor,
      },
    },
    fieldLabel: {
      fontFamily: "Montserrat, sans-serif !important",
      marginBottom: "2px",
      color: colors().charcoal,
      fontSize: "16px !important",
      fontWeight: 600,
      "@media only screen and (max-width: 1024px)": {
        fontSize: "13px !important",
        fontWeight: 600,
        color: colors().charcoal,
      },  
    },
    btnDiv: {
      marginTop: "48px",
      marginBottom: "40px",
      display: 'flex',
      flexWrap: 'wrap-reverse'
    }
  }
};

export default withStyles(useStyles as Styles<Theme, {}, string>)(AddOrganization);
// Customizable Area End
