import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from "yup";
import React from "react";
import { ClassNameMap } from "@material-ui/styles";
import {AllImageIconType, SectionType, ServiceListType, SectionListType} from "./SevicesTypes"
import { navigateTo } from "../../../../packages/blocks/utilities/src/CustomBlockHelpers";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
    PermissionStatus,
    checkForNewPermissonStatus,
    customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";

export const configData = require("./config");

interface ImageProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
    key?: string | number ;
}

export interface GetIconType {
    id: string,
    item: React.ReactNode,
    title: string,
    attributes: {
        image: string;
        name: string;

    };

}



export interface PreFilledType {
    id?: string,
    name?: string,
    title?: string,
    serviceName: string | undefined,
    serviceShortName: string | undefined,
    serviceSecondaryName: string,
    selectedIcon:  GetIconType | undefined,
    selectedIconName?: string,
    selectedSections: string[] | undefined | string,
    onlineOrder: boolean | undefined,
    selectedSectionName?: string[]
    selectedIconId?: string
}




// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: ClassNameMap;
    // Customizable Area End
}

interface S {
    token: string;
    // Customizable Area Start
    permissionStatus: PermissionStatus;
    selectedType: string,
    anchorEl: null | HTMLElement,

    allIcons: GetIconType[],
    allImages: AllImageIconType[],

    errorMessage: string,
    loading: boolean,
    selectedIcon: string,

    selectedFile: string | null | ArrayBuffer,
    selectedOptions: string[] | string,

    serviceList: ServiceListType[],
    sectionLists: SectionListType[],

    iconList: GetIconType[],
    preFilledEdit: PreFilledType | null,

    selectedIconId:string
   // Customizable Area End
}

interface SS {
    id: any;
}

export default class ViewServicesController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getIconsCallId: string = "";
    getImagesCallId: string = "";
    createIconsCallId: string = "";
    getServiceListCallId: string = "";
    getSectionListCallId: string = "";
    addServiceCallId: string = "";
    getEditPreFilledCallId: string = "";
    deActivateSericeCallId: string = "";
    editId: string = ""



    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area Start

            
            
            getName(MessageEnum.LayoutDataMessage),
            getName(MessageEnum.RestAPIRequestMessage)
            
            // Customizable Area End
        ];

        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

 
        this.state = {
            // Customizable Area Start
            selectedType: '',
            permissionStatus: {
                mainPermission: false,
                createPermission: false,
                viewPermission: false,
                editPermission: false,
                deactivatePermission: false
            },
            allIcons: [],

            token: '',
            anchorEl: null,

            allImages: [],
            loading: false,

            errorMessage: "",
            selectedIcon: 'select type',

            selectedFile: "",
            selectedOptions: [],
            sectionLists: [],
            preFilledEdit: null,

            serviceList: [],
            iconList: [],
      
            selectedIconId:""

       // Customizable Area End
        };



    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        this.receiveDataFromLayout(message)
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            switch (apiRequestCallId) {

              case this.getEditPreFilledCallId:
                    this.setState({ loading: false });

                   

                    if (responseJson) {
                        
                        const preFilledInfo: PreFilledType = {} as PreFilledType;
                        
                        preFilledInfo.serviceName = responseJson?.data?.attributes?.name;
                        preFilledInfo.serviceShortName = responseJson?.data?.attributes?.short_name;
                        preFilledInfo.serviceSecondaryName = responseJson?.data?.attributes?.second_name;
                        preFilledInfo.selectedSections =  this.getAllSelectedSectionIds(responseJson?.data?.attributes?.sections.data || []);
                        preFilledInfo.selectedSectionName = this.getAllSectionName(responseJson?.data?.attributes?.sections?.data)
                        
                        preFilledInfo.id = responseJson?.data.id;
                        preFilledInfo.selectedIcon = this.getSelctedIcon(responseJson.data.attributes.icon_id)
                        
                        
                        preFilledInfo.selectedIconId =  responseJson.data.attributes.icon_id
                        preFilledInfo.onlineOrder = responseJson?.data?.attributes?.online_order
                        
                     this.setState({

                            preFilledEdit: preFilledInfo,
                     
                        })
    
                      }
                    

                    break;

                    case this.getIconsCallId:
                    this.setState({
                        loading: false
                    });
                    if (responseJson) {
                        const parsedAllIcons: GetIconType[] = [];


                        responseJson.data.forEach((item: GetIconType) => {

                            const tempObj: GetIconType = {} as GetIconType;


                            tempObj.id = item.id;


                            tempObj.item = (<div style={{ display: "flex", gap: "20px", alignItems: "center"}}>

                                <img src={item.attributes?.image} height={24} width={24} alt={item.attributes.name} />

                                <span>{item.attributes.name}</span>

                            </div>);
                            tempObj.title = item.attributes.name
                           parsedAllIcons.push(tempObj);

                        })

                        this.getPreFilledValues();
                        this.setState({
                            allIcons: parsedAllIcons
                        })




                    }
    
                    break;
               
        
            }

        }
        // Customizable Area End
    }


    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.editId = this.props.navigation?.history.location?.state?.state
        this.getAllIcons();

        // Customizable Area End
       
    }

        





  


  // Customizable Area Start

  receiveDataFromLayout = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.LayoutMessageData)
        );
        if (recievedData.userContext) {
          this.handleUserChange(recievedData.userContext)
        }
    }
  }
  
    handleUserChange = (userContext: IUserContext) => {
        const apiKey = customPermissionApiKey.servicePermission;
        const userData = userContext.user?.attributes.permission_groups;
        const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
        this.setState({
            permissionStatus: value
        })
    };

    token = localStorage.getItem('token');


    FormSchema = Yup.object().shape({
        service_name: Yup.string()
            .required("Service name is required"),
        service_secondayName: Yup.string()
            .required("Secondory name is required"),
        selected_sections: Yup.array().of(
            Yup.string().required('please select at least one ')
        ).required('Cannot be empty'),
        selected_icon: Yup.string()
            .required("Select at aleast one Icon")
    })



  
    

 getAllSectionName = (sectionList: SectionType[]) => {

        const parsedSectionName: string[] = []

        sectionList.forEach((item: SectionType) => {
            parsedSectionName.push(item.attributes.section_name)
        })

        return parsedSectionName

    }

    getAllSelectedSectionIds = (sectionList: SectionType[]) => {
        const parsedSectionIds: string[] = []

        sectionList.forEach((section: SectionType) => {

            parsedSectionIds.push(section.id)

        })

        return parsedSectionIds
    }

    handleRedirect = () => {
        navigateTo({props: this.props, screenName: 'ServicesList'})
    }

    getAllIcons = () => {
        this.setState({ loading: true });
        const headers = {
            "Content-Type": configJSON.categoryApiContentType,
            token: this.token,
        };

        const iconsListDataMessagess = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getIconsCallId = iconsListDataMessagess.messageId;

        iconsListDataMessagess.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getIconsListsAPIEndPoint
        );

        iconsListDataMessagess.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        iconsListDataMessagess.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetType
        );

        runEngine.sendMessage(iconsListDataMessagess.id, iconsListDataMessagess);
    }


  getPreFilledValues = () => {

       
        this.setState({ loading: true });
        const headers = {
            "Content-Type": configJSON.categoryApiContentType,
            token: this.token,
        };

        const preFilledServiceDataMessagess = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getEditPreFilledCallId = preFilledServiceDataMessagess.messageId;

        preFilledServiceDataMessagess.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getServiceListAPIEndPoint}/${this.editId}`
        );

        preFilledServiceDataMessagess.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        preFilledServiceDataMessagess.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetType
        );

        runEngine.sendMessage(preFilledServiceDataMessagess.id, preFilledServiceDataMessagess);

    }




    getSelctedIcon = (iconId: string):GetIconType | undefined => {
        if(iconId){
            

            const dataBody = this.state?.allIcons.find((option) => option.id == iconId)
            

            return dataBody
        }
     }






    // Customizable Area End
}
