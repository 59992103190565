// Customizable Area Start
import React from "react";
import ZatcaHistoryController, {
  Props,
  configJSON,
} from "./ZatcaHistoryController";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  Button,
  CircularProgress,
  Snackbar,
  TablePagination
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import PageContainer from "../../../blocks/navigationmenu/src/PageContainer.web";
import { colors } from "../../../blocks/utilities/src/Colors";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarTodayRounded } from "@material-ui/icons";
import { renderBaseOnConditions } from "../../../blocks/utilities/src/CustomBlockHelpers";
import { CustomSnackbar } from "../../../components/src/customComponents/CustomSnackbar.web";
// Customizable Area End

// Customizable Area Start
const String = configJSON.zatacHistory;
// Customizable Area End

export default class ZatcaHistory extends ZatcaHistoryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderFilterPart = () => {
    const { selectedFromDate, selectedToDate } = this.state;
    return (
      <Box className='header-dropdown'>
        <Box className='date-picker'>
          <DatePicker
            showIcon
            dateFormat='dd/MM/yyyy'
            data-test-id='from-date'
            toggleCalendarOnIconClick
            placeholderText={String.fromDate}
            selected={selectedFromDate ? new Date(selectedFromDate) : null}
            onChange={(date) => this.handleFromDate(date)}
            icon={<CalendarTodayRounded />}
          />
        </Box>
        <Box className='date-picker'>
          <DatePicker
            showIcon
            data-test-id='toDate'
            dateFormat='dd/MM/yyyy'
            toggleCalendarOnIconClick
            placeholderText={String.toDate}
            minDate={new Date(selectedFromDate)}
            selected={selectedToDate ? new Date(selectedToDate) : null}
            onChange={(date) => this.handleToDate(date)}
            icon={<CalendarTodayRounded />}
          />
        </Box>
        <Button
          className='filter-buton'
          data-test-id='filter-button'
          onClick={() => this.handleAppliedFilter()}
        >
          {String.filter}
        </Button>
        <Button
          disabled={!selectedFromDate && !selectedToDate}
          className='clear-button'
          data-test-id='clear-button'
          onClick={() => this.handleClearFilter()}
        >
          {String.clear}
        </Button>
      </Box>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      zatacaHistoryList,
      isLoading,
      snackbarOpen,
      snackbarMessage,
      snakcbarSeverity,
    } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box>
        <>
          {renderBaseOnConditions(
            this.state.permissionStatus,
            <Box>
              <TableWrapper className="pageWrapper">
                <Box className='top-header'>
                  <h3>{String.zatcaHistory}</h3>
                  {this.renderFilterPart()}
                </Box>
                <Box className='table-container-wrap'>
                  <TableContainer component={Paper} className="flexItem">
                    <Table size='medium'>
                      <TableHead className="stickyHeader" style={{backgroundColor: "white"}}>
                        <TableRow>
                          <CustomTableCell align='left'>
                            {String.orderId}
                          </CustomTableCell>
                          <CustomTableCell align='left'>
                            {String.invoiceId}
                          </CustomTableCell>
                          <CustomTableCell align='left'>
                            {String.type}
                          </CustomTableCell>
                          <CustomTableCell align='left'>
                            {String.customer}
                          </CustomTableCell>
                          <CustomTableCell align='left'>
                            {String.taxable}
                          </CustomTableCell>
                          <CustomTableCell align='left'>
                            {String.tax}
                          </CustomTableCell>
                          <CustomTableCell align='left'>
                            {String.total}
                          </CustomTableCell>
                          <CustomTableCell align='left'>
                            {String.sendToZatca}
                          </CustomTableCell>
                          <CustomTableCell align='left'>
                            {String.statusCode}
                          </CustomTableCell>
                          <CustomTableCell align='left'>
                            {String.zatcaStatus}
                          </CustomTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {renderBaseOnConditions(
                          isLoading,
                          <CustomTableRow>
                            <CustomTableCell colSpan={12}>
                              <Box style={{ textAlign: "center" }}>
                                <CircularProgress
                                  style={{
                                    width: 24,
                                    height: 24,
                                    color: "#204B9C",
                                  }}
                                />
                              </Box>
                            </CustomTableCell>
                          </CustomTableRow>,
                          <>
                            {renderBaseOnConditions(
                              zatacaHistoryList.length > 0,
                              <>
                                {zatacaHistoryList.map((zataca) => (
                                  <CustomTableRow key={zataca.id}>
                                    <CustomTableCell align='left'>
                                      ---
                                    </CustomTableCell>
                                    <CustomTableCell align='left'>
                                      {zataca.invoice_no}
                                    </CustomTableCell>
                                    <CustomTableCell align='left'>
                                      {zataca.invoice_type}
                                    </CustomTableCell>
                                    <CustomTableCell align='left'>
                                      {zataca.customer_name}
                                    </CustomTableCell>
                                    <CustomTableCell align='left'>
                                      {zataca.taxable}
                                    </CustomTableCell>
                                    <CustomTableCell align='left'>
                                      {zataca.tax}
                                    </CustomTableCell>
                                    <CustomTableCell align='left'>
                                      {zataca.total}
                                    </CustomTableCell>
                                    <CustomTableCell align='left'>
                                      {this.handleSendtozatcaDate(
                                        zataca.issue_date
                                      )}
                                    </CustomTableCell>
                                    <CustomTableCell align='left'>
                                      {zataca.status_code}
                                    </CustomTableCell>
                                    <CustomTableCell align='left'>
                                      {JSON.stringify(zataca.response)}
                                    </CustomTableCell>
                                  </CustomTableRow>
                                ))}
                              </>,
                              <CustomTableRow>
                                <CustomTableCell colSpan={12}>
                                  <p className='no-data-found'>
                                    {String.noDataFound}
                                  </p>
                                </CustomTableCell>
                              </CustomTableRow>
                            )}
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    onPageChange={(_event, page) => {
                      this.handlePageChange(page);
                    }}
                    rowsPerPageOptions={[10]}
                    count={this.state.meta.total_count}
                    component='div'
                    data-test-id='pagination'
                    page={this.state.page}
                    rowsPerPage={this.state.pageSize}
                  />
                </Box>
              </TableWrapper>
            </Box>,
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={!this.state.permissionStatus}
            >
              <Alert severity='warning'>{String.permissionMessage}</Alert>
            </Snackbar>,
          )}
          <CustomSnackbar
            open={snackbarOpen}
            onClose={this.handleSnackbarClose}
            errorMessage={snackbarMessage}
            severity={snakcbarSeverity}
          />
        </>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const TableWrapper = styled(Box)({
  background: "transparent",
  display: "flex",
  flexDirection: "column",
  "& .top-header": {
    display: "flex",
    justifyContent: "space-between",
    padding: "12px 0",
    "& h3": {
      fontSize: "18px",
      fontFamily: "Montserrat",
    },
  },
  "& .table-container-wrap": {
    flex: "1 1 0",
    margin: "12px 0",
    display: "flex",
    flexDirection: "column"
  },
  "& .header-dropdown": {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 2,
  },
  "& .date-picker": {
    marginRight: 20,
    "& .react-datepicker__calendar-icon": {
      right: 0,
      padding: "0.4rem",
      fontSize: 18,
    },
    "& .react-datepicker__view-calendar-icon input": {
      padding: "10px 12px 10px 12px",
      width: "132px",
      borderRadius: "6px",
      border: `1px solid ${colors().lightborder}`,
      height: "37px",
    },
  },
  "& .filter-buton": {
    fontSize: "14px",
    fontFamily: "Montserrat",
    cursor: "pointer",
    background: colors().cyancobaltblue,
    color: colors().white,
    textTransform: "capitalize",
  },
  "& .clear-button": {
    margin: "0 0 0 8px",
    fontSize: "14px",
    fontFamily: "Montserrat",
    cursor: "pointer",
    background: "rgb(241, 245, 249)",
    color: "rgb(100, 116, 139)",
    textTransform: "capitalize",
  },
  "& .no-data-found": {
    textAlign: "center",
    fontSize: "16px",
    fontFamily: "Montserrat",
    fontWeight: 600,
  },
});

const CustomTableCell = styled(TableCell)({
  fontSize: "14px",
  fontFamily: "Montserrat",
});

const CustomTableRow = styled(TableRow)({
  "&:nth-child(even)": {
    background: colors().lightborder,
  },
});
// Customizable Area End
