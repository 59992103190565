// Customizable Area Start
import React from "react";
import B2bCustomerGroupController, {
  Props,
  configJSON,
} from "./B2bCustomerGroupController";
import {
  styled,
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  CircularProgress,
  TablePagination,
  Avatar,
} from "@material-ui/core";
import FilterIcon from "@material-ui/icons/FilterList";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {
  ListingPageHeading,
  FilterBox,
  MainButtonComponent,
  ActionPopover,
} from "../../../components/src/customComponents/CustomTags.web";
import {
  sortStringCondition,
  renderBaseOnConditions,
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { LoadingComponent } from "../../../components/src/Loader.web";
import { colors } from "../../../blocks/utilities/src/Colors";
import { avtarImg } from "./assets";
import SearchOutlined from "@material-ui/icons/SearchOutlined";
import {
  TableRow,
  TableCell,
  OrderTable,
  RowContainer,
} from "../../../components/src/OrderTable/src";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";
import ConfirmationModal from "../../../components/src/customComponents/ConfirmationModal.web";
import { CustomSnackbar } from "../../../components/src/customComponents/CustomSnackbar.web";
import InfiniteScroll from "react-infinite-scroll-component";
const IconSortingUp = require("../../../components/src/assets/sort/sort top.png");
const IconSortingNormal = require("../../../components/src/assets/sort/sort.png");
const IconSortingDown = require("../../../components/src/assets/sort/sort down.png");
import FilterPopover from "../../../components/src/FilterPopover";
// Customizable Area End

// Customizable Area Start
const Strings = configJSON.strings.B2bCustomerGroupList;
// Customizable Area End

export default class B2bCustomerGroup extends B2bCustomerGroupController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleReturnValue = (value: string | null) => {
    if (value) {
      return value;
    } else {
      return <Typography className={"group-detail-name"}>---</Typography>;
    }
  };

  handleReturnPieaces = (value: number | null) => {
    if (value !== null) {
      return value;
    } else {
      return <Typography className={"group-detail-name"}>---</Typography>;
    }
  };

  handleTableNullValue = (value: string | number | null) => {
    if (value) {
      return value;
    } else {
      return <>---</>;
    }
  };

  handleReturnShowAllModel = () => {
    const {
      isOpenShowAllCustomers,
      tableListingLoading,
      customerModalDetails,
      customerModalTableList,
    } = this.state;
    return (
      <ActionDialog open={isOpenShowAllCustomers} maxWidth={"md"}>
        <DialogContent className={"p-24"}>
          <Typography className={"dialog-heading"}>
            {Strings.customers}
          </Typography>
          <Box className={"customer-top-head"}>
            <Box className={"customer-group-head"}>
              <Typography className={"customer-group-name"}>
                {Strings.customerGroup}
              </Typography>
              <Typography className={"customer-group-name font-semi-bold"}>
                {customerModalDetails?.attributes.group_name}
              </Typography>
            </Box>
            <Box className={"search-modal-input"}>
              <TextField
                className={"searchInput"}
                placeholder={Strings.search}
                type={"search"}
                variant={"outlined"}
                fullWidth
                size={"small"}
                onChange={(event) =>
                  this.debouncedFetchSearchResults(event.target.value)
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position={"start"}>
                      <SearchOutlined style={{ color: "#4D4D4D" }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
          <Box
            className={"table-container"}
            data-test-id={"table-container-scrolldiv"}
            onScroll={() => this.fetchMoreTableCustomers()}
          >
            <InfiniteScroll
              dataLength={this.state.customerModalTableList.length}
              next={this.fetchMoreTableCustomers}
              hasMore={
                this.state.customerModalPage <
                this.state.customerModalMeta.total_pages
              }
              loader={<></>}
              height={200}
            >
              <OrderTable>
                <RowContainer header>
                  <TableRow columns={"1fr 1fr 1fr"}>
                    {[
                      {
                        title: Strings.tableString_customers,
                        sortingKey: "name",
                      },
                      {
                        title: Strings.tableString_emailId,
                        sortingKey: "email",
                      },
                      {
                        title: Strings.tableString_mobileNo,
                        sortingKey: "phone",
                      },
                    ]
                      .filter((item: { title: string }) => {
                        return item.title;
                      })
                      .map(
                        ({
                          title,
                          sortingKey,
                        }: {
                          title: string;
                          sortingKey?: string;
                        }) => (
                          <TableCell
                            header
                            key={title}
                            content={title}
                            className={"f-16 align-item-center"}
                            sortingKey={sortingKey}
                            sortingIcons={[
                              IconSortingNormal,
                              IconSortingUp,
                              IconSortingDown,
                            ]}
                            sortingDisabled={!Boolean(sortingKey)}
                            {...this.getSortingProps()}
                          />
                        )
                      )}
                  </TableRow>
                </RowContainer>
                {renderBaseOnConditions(
                  tableListingLoading,
                  <RowContainer>
                    <Box className={"tableBodyLoader"}>
                      <CircularProgress />
                    </Box>
                  </RowContainer>,
                  <>
                    {renderBaseOnConditions(
                      customerModalTableList.length > 0,
                      <RowContainer>
                        {customerModalTableList.map((data) => {
                          return (
                            <TableRow
                              key={data.id}
                              className={"table-rows"}
                              columns={"1fr 1fr 1fr"}
                            >
                              {[
                                this.handleTableNullValue(data.attributes.name),
                                this.handleTableNullValue(
                                  data.attributes.email
                                ),
                                this.handleTableNullValue(
                                  data.attributes.full_phone_number
                                ),
                              ].map((item, index) => {
                                return (
                                  <TableCell
                                    key={`cell-${index}`}
                                    style={{ wordBreak: "break-all" }}
                                    content={item as React.ReactNode}
                                    className={"f-12"}
                                    type={this.getTypeOfOrderTable(index)}
                                  />
                                );
                              })}
                            </TableRow>
                          );
                        })}
                      </RowContainer>,
                      <RowContainer>
                        <Box className={"w-100"}>
                          <Typography className={"noDataFound"}>
                            {Strings.noCustomersFound}
                          </Typography>
                        </Box>
                      </RowContainer>
                    )}
                  </>
                )}
              </OrderTable>
            </InfiniteScroll>
          </Box>
          <DialogActions className={"dialogActionWrapper"}>
            <MainButtonComponent
              data-test-id={"btn-cancel"}
              className={"buttonSecondary"}
              onClick={() => this.onCloseShowCustomerDialog()}
            >
              {Strings.cancel}
            </MainButtonComponent>
          </DialogActions>
        </DialogContent>
      </ActionDialog>
    );
  };

  handleReturnActionPopver = () => {
    const { openActionPopover, currentClickedRow, permissionStatus } =
      this.state;
    const open = Boolean(this.state.openActionPopover);
    const popOverId = open ? "simple-popover" : undefined;

    return (
      <ActionPopover
        data-test-id={"action-popver"}
        style={{ boxShadow: "none" }}
        id={popOverId}
        open={open}
        anchorEl={openActionPopover}
        onClose={this.handleCloseActionPopver}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {renderBaseOnConditions(
          permissionStatus.viewPermission ||
            permissionStatus.editPermission ||
            permissionStatus.activatePermission ||
            permissionStatus.deactivatePermission,
          <div className={"popoverContainer"}>
            {renderBaseOnConditions(
              permissionStatus.viewPermission,
              <Box
                className={"popoverButton"}
                onClick={() => this.handleNavigateToView()}
              >
                {Strings.view}
              </Box>,
              <></>
            )}
            {renderBaseOnConditions(
              permissionStatus.editPermission,
              <Box
                className={"popoverButton"}
                onClick={() => this.handleNavigateToEdit()}
              >
                {Strings.edit}
              </Box>,
              <></>
            )}
            {renderBaseOnConditions(
              permissionStatus.deactivatePermission &&
                currentClickedRow.attributes.activated,
              <Box
                className={"popoverButton"}
                data-test-id={"deactivate-menu"}
                onClick={() => this.handleActivateDeactivate()}
              >
                {Strings.deactivate}
              </Box>,
              <></>
            )}
            {renderBaseOnConditions(
              (permissionStatus.activatePermission as boolean) &&
                !currentClickedRow.attributes.activated,
              <Box
                className={"popoverButton"}
                data-test-id={"activate-menu"}
                onClick={() => this.handleActivateDeactivate()}
              >
                {Strings.activate}
              </Box>,
              <></>
            )}
          </div>,
          <div className={"popoverContainer"}>
            <Box className={"noPermissions"}>{Strings.noPermissions}</Box>
          </div>
        )}
      </ActionPopover>
    );
  };

  renderDeactivateModal = () => {
    const { currentClickedRow, openDeactiveModel } = this.state;

    return (
      <ConfirmationModal
        data-test-id={"deactiveModel"}
        open={openDeactiveModel}
        handleClose={this.handleCloseConfirmModal}
        handleConfirm={this.handleDeactivate}
        title={Strings.confirmDeactivate}
        message={{
          id: currentClickedRow.id,
          title: currentClickedRow.attributes.group_name,
        }}
        confirmText={Strings.deactivate}
        dynamic={true}
      />
    );
  };

  renderConfirmDeactivateModal = () => {
    const { currentClickedRow, confirmDeactiveModalOpen } = this.state;

    return (
      <ConfirmationModal
        data-test-id={"confirmDeactiveModel"}
        open={confirmDeactiveModalOpen}
        handleClose={this.handleCloseConfirmModal}
        handleConfirm={this.handleGoBackToListing}
        title={Strings.groupDeactivateMessage}
        message={{
          id: currentClickedRow.id,
          title: currentClickedRow.attributes.group_name,
        }}
        confirmText={Strings.confirmButtonText}
        displayItem={true}
      />
    );
  };

  renderSnackBar = () => {
    const { snackBarOpen, snackBarMessage, severity } = this.state;

    return (
      <CustomSnackbar
        data-test-id={"custom-snackbar"}
        open={snackBarOpen}
        onClose={() => this.handleSnackbarClose()}
        errorMessage={snackBarMessage}
        autoHideDuration={1500}
        severity={severity}
        hideIcon={true}
      />
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start

    const {
      customerGroupList,
      isOpenShowAllCustomers,
      listingLoading,
      meta,
      openActionPopover,
      openDeactiveModel,
      confirmDeactiveModalOpen,
      snackBarOpen,
      permissionStatus,
      isLoadingPermission,
    } = this.state;
    const open = Boolean(this.state.openActionPopover);
    const popoverId = open ? "simple-popover" : undefined;

    // Customizable Area End
    return (
      // Customizable Area Start
      <B2bCustomerGroupWrapper>
        <CustomLoader loading={listingLoading} />
        <Box className={"topHeaderBox"}>
          <ListingPageHeading>{Strings.customerGroups}</ListingPageHeading>
          <Box className={"topCustomerRightBox"}>
            <FilterBox
              data-test-id={"filterBtn"}
              className={sortStringCondition(
                this.state.isAppliedFilter,
                "active-border",
                ""
              )}
              onClick={(event) => {
                this.onFilterClickHandler(event);
              }}
            >
              <FilterIcon color={this.handleReturnColorType()} />
              {renderBaseOnConditions(
                this.state.isAppliedFilter,
                <div className={"filterActiveIcon"} />,
                <></>
              )}
            </FilterBox>
            <FilterPopover
              onClose={this.handleClosePopOver}
              anchor={this.state.filterAnchor as HTMLDivElement}
              onFilterChange={this.handleFilterChange}
              onAutoCompleteChange={this.debouncedCustomerGroupSuggestion}
              filters={this.state.filters}
            />
            <Box>
              <LoadingComponent
                isLoading={isLoadingPermission}
                height={56}
                width={207}
                isAllowed={permissionStatus.createPermission}
                children={
                  <MainButtonComponent
                    className={"mr-0 w-none f-weight-600"}
                    onClick={this.handleNaviagetToAddPage}
                  >
                    {Strings.addCustomerGroup}
                  </MainButtonComponent>
                }
              />
            </Box>
          </Box>
        </Box>
        <Box className={"group-wrapper"}>
          {customerGroupList.map((item) => {
            return (
              <Box
                className={sortStringCondition(
                  item.attributes.activated,
                  "group-box",
                  "group-box opacity-7"
                )}
              >
                <Box className={"group-box-top"}>
                  <Box className={"group-name-total"}>
                    <Typography className={"group-name"}>
                      {item.attributes.group_name}
                    </Typography>
                    <Box className={"span-dot"} />
                    <Typography className={"group-name"}>
                      {item.attributes.accounts_count}
                    </Typography>
                  </Box>
                  <IconButton
                    className={"actionMenuBtn"}
                    data-test-id={`more-menu-button-${item.id}`}
                    aria-describedby={popoverId}
                    onClick={(event) =>
                      this.handleOpenActionPopver(event, item)
                    }
                  >
                    <MoreVertIcon />
                  </IconButton>
                </Box>
                <Box className={"group-details-wrapper"}>
                  <Box className={"group-detail-inner"}>
                    <Typography
                      className={"group-detail-name"}
                    >{`Company :`}</Typography>
                    <Typography className={"group-detail-name"}>
                      {item.attributes.company.name}
                    </Typography>
                  </Box>
                  <Box className={"group-detail-inner"}>
                    <Typography
                      className={"group-detail-name"}
                    >{`Designation :`}</Typography>
                    <Typography className={"group-detail-name"}>
                      {this.handleReturnValue(item.attributes.designation)}
                    </Typography>
                  </Box>
                  <Box className={"group-detail-inner"}>
                    <Typography
                      className={"group-detail-name"}
                    >{`No. of Items per Wash :`}</Typography>
                    <Typography className={"group-detail-name"}>
                      {this.handleReturnPieaces(
                        item.attributes.no_of_items_per_wash
                      )}
                    </Typography>
                  </Box>
                  <Box className={"group-detail-inner"}>
                    <Typography
                      className={"group-detail-name"}
                    >{`No. of Pieces per item :`}</Typography>
                    <Typography className={"group-detail-name"}>
                      {this.handleReturnPieaces(
                        item.attributes.no_of_pieces_per_item
                      )}
                    </Typography>
                  </Box>
                </Box>
                {renderBaseOnConditions(
                  item.attributes.accounts_count > 0,
                  <Box className={"customer-wrapper"}>
                    {item.attributes.accounts.slice(0, 4).map((userItem) => {
                      return (
                        <Box className={"customer-inner-box"} key={userItem.id}>
                          <Box className={"customer-image-box"}>
                            <Avatar alt={"Avtar Image"} src={""}>
                              {userItem.name[0].toUpperCase()}
                            </Avatar>
                          </Box>
                          <Box className={"customer-details"}>
                            <Typography className={"group-detail-name"}>
                              {this.handleReturnValue(userItem.name)}
                            </Typography>
                            <Typography className={"group-detail-name"}>
                              {this.handleReturnValue(userItem.email)}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>,
                  <Box className={"w-100"}>
                    <Typography className={"noDataFound"}>
                      {Strings.noCustomersFound}
                    </Typography>
                  </Box>
                )}
                {item.attributes.accounts_count > 4 && (
                  <Box className={"customer-bottom-box"}>
                    <Typography>{`+${
                      item.attributes.accounts_count - 4
                    } More`}</Typography>
                    <Box
                      data-test-id={`show-more-${item.id}`}
                      className={"show-more-link"}
                      onClick={() => this.handleOpenShowAll(item)}
                    >
                      <Typography>{Strings.showAll}</Typography>
                      <ChevronRightIcon />
                    </Box>
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
        {customerGroupList.length === 0 && !listingLoading && (
          <Typography className={"noDataFoundString"}>
            {Strings.tabelString_noAccountDataFound}
          </Typography>
        )}
        {renderBaseOnConditions(
          meta && meta.total_pages > 1,
          <TablePagination
            onPageChange={(_event, page) => this.handleOnPageChange(page)}
            count={this.state.meta.total_count}
            component={"div"}
            data-test-id={"pagination"}
            page={this.state.page - 1}
            rowsPerPage={this.state.per_page}
            rowsPerPageOptions={[10]}
            className={"tablePaginationBox"}
          />,
          <></>
        )}
        {openDeactiveModel && this.renderDeactivateModal()}
        {confirmDeactiveModalOpen && this.renderConfirmDeactivateModal()}
        {isOpenShowAllCustomers && this.handleReturnShowAllModel()}
        {openActionPopover && this.handleReturnActionPopver()}
        {snackBarOpen && this.renderSnackBar()}
      </B2bCustomerGroupWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const B2bCustomerGroupWrapper = styled(Box)({
  width: "100%",
  "& .topHeaderBox": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "17px 0 32px 0",
  },
  "& .topCustomerRightBox": {
    display: "flex",
    alignItems: "center",
    gap: "16px",
  },
  "& .group-wrapper": {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "16px",
    padding: "0 0 32px 0",
  },
  "& .opacity-7": {
    opacity: "0.7",
  },
  "& .group-box": {
    width: "100%",
    borderRadius: "12px",
    padding: "16px 12px",
    background: colors().white,
    border: `1px solid ${colors().lightborder}`,
  },
  "& .group-box-top": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .group-name-total": {
    display: "flex",
    alignItems: "center",
  },
  "& .span-dot": {
    display: "inline-flex",
    minHeight: "5px",
    minWidth: "5px",
    background: colors().darkjunglegreen,
    borderRadius: "8px",
    margin: "0 12px",
  },
  "& .group-name": {
    fontSize: "16px",
    lineHeight: "22px",
    color: colors().darkjunglegreen,
    fontFamily: "Montserrat",
    fontWeight: 600,
  },
  "& .actionMenuBtn": {
    padding: "8px",
    color: colors().darkjunglegreen,
  },
  "& .group-details-wrapper": {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "12px",
    margin: "24px 0",
    "@media only screen and (max-width: 1024px)": {
      gridTemplateColumns: "1fr",
      gap: "10px",
    },
  },
  "& .group-detail-inner": {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    padding: "4px 8px",
    border: `1px solid ${colors().lightborder}`,
    borderRadius: "8px",
  },
  "& .group-detail-name": {
    fontSize: "14px",
    lineHeight: "22px",
    color: colors().darkjunglegreen,
    fontFamily: "Montserrat",
    fontWeight: 500,
  },
  "& .customer-wrapper": {
    display: "grid",
    gap: "20px",
    gridTemplateColumns: "1fr 1fr",
    "@media only screen and (max-width: 1024px)": {
      gridTemplateColumns: "1fr",
      gap: "10px",
    },
  },
  "& .customer-inner-box": {
    padding: "8px",
    background: colors().ghostwhite,
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    gap: "16px",
  },
  "& .customer-image-box": {
    width: "52px",
    height: "52px",
    borderRadius: "50%",
    overflow: "hidden",
    border: `1px solid ${colors().brightgray}`,
    "& img": {
      width: "100%",
      height: "100%",
    },
  },
  "& .customer-bottom-box": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "12px 0 0 0",
  },
  "& .show-more-link": {
    color: colors().cyancobaltblue,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    gap: "8px",
    "& p": {
      fontSize: "16px",
      lineHeight: "24px",
      fontFamily: "Montserrat",
      fontWeight: 700,
    },
  },
  "& .tablePaginationBox": {
    width: "100%",
    padding: "8px 0",
  },
  "& .noDataFound": {
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "Montserrat",
    textAlign: "center",
    padding: "8px 0 12px 0",
  },
  "& .noDataFoundString": {
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: 600,
    fontFamily: "Montserrat",
    textAlign: "center",
    padding: "8px 0 12px 0",
  },
  "& .w-100": {
    width: "100%",
  },
  "& .MuiAvatar-root": {
    width: "100%",
    height: "100%",
  },
  "& .MuiAvatar-colorDefault": {
    backgroundColor: colors().brightgray,
    color: colors().cyancobaltblue,
  },
});

const ActionDialog = styled(Dialog)({
  "& .MuiDialog-paperScrollPaper": {
    width: "100%",
    borderRadius: "24px",
    "@media only screen and (max-width: 1024px)": {
      maxWidth: "792px",
    },
  },
  "& .MuiDialogContent-root": {
    padding: "24px",
    display: "flex",
    flexDirection: "column",
  },
  "& .table-container": {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
  "& .dialog-heading": {
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: 600,
    fontFamily: "Montserrat",
    letterSpacing: "-0.5%",
    textAlign: "center" as const,
    margin: "0 0 24px 0",
  },
  "& .dialogActionWrapper": {
    justifyContent: "center",
    margin: "24px 0 0 0",
  },
  "& .customer-group-head": {
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
  "& .customer-group-name": {
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 500,
    fontFamily: "Montserrat",
  },
  "& .font-semi-bold": {
    fontWeight: 600,
  },
  "& .search-modal-input": {
    maxWidth: "228px",
    width: "100%",
  },
  "& .searchInput": {
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      border: "1px solid #ECECEC",
    },
    "& .MuiInputBase-input::placeholder": {
      color: "#4D4D4D",
    },
  },
  "& .customer-top-head": {
    margin: "24px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  "& .tableBodyLoader": {
    textAlign: "center",
    paddingBottom: 15,
  },
  "& .noDataFound": {
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "Montserrat",
    textAlign: "center",
    padding: "8px 0 12px 0",
  },
});
// Customizable Area End
