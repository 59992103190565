import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
// Customizable Area End

export const configJSON = require("./config");
// Customizable Area Start

export enum FormMode {
  Create,
  Edit,
  View,
}

enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH",
}

export interface IProfession {
  data :  {
    attributes: {
        profession_unique_id: string,
        profession_name: string
    }
  }
}

export interface IRenderProfessionView {
  data :  {
    attributes: {
        profession_unique_id: string,
        profession_name: string
    }
  }
}

export interface IForm {
           id: number,
            profession_unique_id: string,
            profession_name: string,
            active: boolean        
}

export interface IRenderView {
  data :  {
    attributes: {
        profession_unique_id: string,
        profession_name: string
    }
  }
            
}

const ITEM_HEIGHT = 80;
const ITEM_PADDING_TOP = 8;

export type ValueViewType = string | undefined | null | number | boolean;
export type RenderViewValue = string | undefined | null | number | boolean;

export const MenuProps: any = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  },
  getContentAnchorEl: null
};

// Customizable Area End

export interface Props {
  // Customizable Area Start
  classes?: any;
  navigation: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  formErrors: { [key: string]: string };
  form: IProfession;
  isLoading: boolean;
  isSubmitting: boolean;
  formMode: FormMode;
  dynamicContent: string;
  snackbarOpen: boolean;
  snackbarMessage: string;
  severity: string;
  randomProfessionId: string;
  permissionStatus: PermissionStatus;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreateProfessionController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  professionID: string = " ";
  private formInitialValues: IProfession = {
    data :  {
      attributes: {
          profession_unique_id: "",
          profession_name: "",
      }
    }
  }

  public RequestMessage = {
    GetProfessionId: this.buildRequestMessage(Method.GET),
    CreateProfession: this.buildRequestMessage(Method.POST),
    GetProfessionDetail: this.buildRequestMessage(Method.GET),
    UpdateProfession: this.buildRequestMessage(Method.PUT),
    Null: undefined as any,
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.LayoutDataMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      formErrors: {},
      form: { ...this.formInitialValues },
      isLoading: false,
      isSubmitting: false,
      formMode: FormMode.Create,
      dynamicContent: "",
      snackbarOpen: false,
      snackbarMessage: "",
      severity : "",
      randomProfessionId: "",
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      }
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    this.receiveLayoutData(message)
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
     

    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const callID = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if(callID === this.professionID) {
      let unique_id = response.profession_unique_id;
      this.setState ({randomProfessionId: unique_id})
    }
    
    switch(callID) {

        case this.RequestMessage.CreateProfession.messageId:
        this.handleCreateProfessionResponse(response);
        break;

        case this.RequestMessage.GetProfessionDetail.messageId:
        this.handleGetProfessionDetailResponse(response);
        break;

        case this.RequestMessage.UpdateProfession.messageId:
        this.handleUpdateProfessionResponse(response);
        break;
     }
    // Customizable Area End
  }
}
  // Customizable Area Start
  receiveLayoutData = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
      const recievedData = message.getData(
        getName(MessageEnum.LayoutMessageData)
      );
      if(recievedData.userContext) {
        this.handleUserChange(recievedData.userContext)
      }
    }
  }

  getProfessionsUniqueId = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem(configJSON.token),
    };

    const professionRandomApiEndPoint = configJSON.generateRandomProfessionId 
      
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.professionID = getDataMsg.messageId;

    getDataMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), professionRandomApiEndPoint);
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  private handleUpdateProfessionResponse(response: {
    data: { id: string };
    full_messages: string[];
  }) {
    if (response && response.data && response.data.id) {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: configJSON.editProfessionSuccess,
        severity: configJSON.severitySuccess,
      });
      setTimeout(()=> {
        this.setState({
          isSubmitting: false,
        })
        this.props.navigation.navigate("ProfessionList");
      },2000)
      
    } else {
      this.setState({
        isSubmitting: false,
        snackbarOpen: true,
        snackbarMessage: response && response.full_messages[0],
        severity: configJSON.typeError
      });
    }
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getProfessionsUniqueId()  
    const path = window.location.pathname;
    const professionId = window.localStorage.getItem("profession_id");
    this.processPath(path, professionId as string);
  }

  processPath = (path: string, professionId: string) => {
    if (path === "/Marketing-ProfessionList-ProfessionEdit") {
      this.setState({ formMode: FormMode.Edit });
      this.getProfessionDetailCall(professionId)
    } else if (path === "/Marketing-ProfessionList-ProfessionView") {
      this.setState({ formMode: FormMode.View });
      this.getProfessionDetailCall(professionId)
    }
  };

  handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackbarOpen: false });
  };

  onFormElementChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    property: string
  ) => {
    const { form } = this.state;
    const updatedAttributes = {
      ...form.data.attributes,
      [property]: event.target.value,
    };
  
    this.setState({
      form: {
        ...form,
        data: {
          ...form.data,
          attributes: updatedAttributes,
        },
      },
    });
  };
   

  handleClickCancel = () => {
    this.props.navigation.goBack();
  };

  handleEditProfessionClick = () => {
    this.setState({ formMode: FormMode.Edit });
    this.props.navigation.navigate("ProfessionEdit");
  };

  handleOnSubmit = () => {
    const formErrors: { [key: string]: string } = {};
    if (!this.state.form || this.state.isSubmitting) {
      return;
    }
    Object.entries(this.state.form).forEach(([key, value]) => { 
    });

    if (Object.keys(formErrors).length > 0) {
      this.setState({ formErrors, isSubmitting: false });
      return;
    }
    
    let professionData = {...this.state.form}
    professionData.data.attributes.profession_unique_id = this.state.randomProfessionId;

    if (this.state.formMode === FormMode.Edit) {
      this.updateProfessionCall(
        {
         ...this.state.form,
        },
        window.localStorage.getItem("profession_id") as string
      );
    } else {
    this.createProfessionCall({
       ...professionData
    })
    }
  };

  fillFormErrors = () => {
    this.setState({
      formErrors: {
        profession_unique_id: " ",
        profession_name: " ",
      },
    });
  };

  changeFormMode = (formMode: FormMode) => {
    this.setState({
      formMode,
    });
  };

  private handleGetProfessionDetailResponse(response: {
    data: {
        id: string;
        type: string;
        attributes: {
          
          profession_unique_id: string;
          profession_name: string;
        
        }
    }
  }) {
    if (response) {
      const {
        data: {
          attributes: {
            profession_unique_id,
            profession_name, 
          },
        },
      } = response;

      const professionDetailFormdata = {
          data: {
            attributes : {
              profession_unique_id,
              profession_name,
            }
          }  
      };
      this.setState({
        form:professionDetailFormdata, 
        isLoading: false,
      });
    }
  }

  private handleCreateProfessionResponse(response: {
    data: { id: string };
    full_messages: string[];
  }) {

    if (response && response.data && response.data.id) {
      this.setState({
        isSubmitting: false,
        snackbarOpen: true,
        snackbarMessage: configJSON.addSuccessProfessionText,
        severity: configJSON.severitySuccess,
      });
      // setTimeout(()=> {
        this.props.navigation.navigate("ProfessionList");
      // },2000)
    } else {
      this.setState({
        isSubmitting: false,
        snackbarOpen: true,
        snackbarMessage: response && response.full_messages[0],
        severity: configJSON.typeError
      });
    }
  }

  updateProfessionCall = (form: IProfession, profession_id: string) => {
    this.setState({ isSubmitting: true, formErrors: {} });

    this.RequestMessage.UpdateProfession.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.professionsApiEndPoint}/${profession_id}`
    );

    this.RequestMessage.UpdateProfession.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(form)
    );

    runEngine.sendMessage(
      this.RequestMessage.UpdateProfession.id,
      this.RequestMessage.UpdateProfession
    );
  };

  createProfessionCall = (form: IProfession) => {
    this.setState({ isSubmitting: true, formErrors: {} }); 
  
    this.RequestMessage.CreateProfession.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(form)
    );

    runEngine.sendMessage(
      this.RequestMessage.CreateProfession.id,
      this.RequestMessage.CreateProfession
    );
  };

  getProfessionDetailCall = (profession_id: string) => {
    this.setState({ isLoading: true });

    this.RequestMessage.GetProfessionDetail.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.professionsApiEndPoint}/${profession_id}`
    );

    runEngine.sendMessage(
      this.RequestMessage.GetProfessionDetail.id,
      this.RequestMessage.GetProfessionDetail
    );
  };


  private buildRequestMessage(method: Method): Message {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.professionsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMessage),
      configJSON.APIBaseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      this.getHeaderMessage()
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method.toString()
    );

    return requestMessage;
  }
  getHeaderMessage() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem(configJSON.token),
    };

    return JSON.stringify(header);
  }

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.professionPermission;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    })
  };
  // Customizable Area End
}
