import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start
import { getEnvironment } from "../../../framework/src/Utilities";
import { styled } from "@material-ui/core";
import { LOGO_BASE64 } from "../../../components/src/PrintRaw.web";
import { colors } from "../../utilities/src/Colors";

const SAMPLE_LABEL_COMMANDS = [
  '^XA\n',
  '^CI27\n',
  '^MMT\n',
  '^PW406\n',
  '^LL670\n',
  '^FO100,5\n',
  {
    type: 'raw', format: 'image', flavor: 'base64', data: LOGO_BASE64,
    options: { language: "ZPL" }
  },
  '^BY1,3,60^FT110,135^BCN,,N,N\n',
  '^FH\\^FD>:1A2B3C4D5F6G7H^FS\n',
  '^FO3,255^GB400,2,1^FS\n',
  '^FT0,160^A0N,26,28^FB406,1,5,C^FH\\^FDFactory_AH\\5C&^FS\n',
  '^FPH,1^FT0,185^A0N,14,15^FB395,1,4,C^FH\\^FD4411002\\5C&^FS\n',
  '^FT0,200^A0N,14,15^FB406,1,4,C^FH\\^FDMuthukumar - 0550737500\\5C&^FS\n',
  '^FT0,225^A0N,22,24^FB402,1,4,C^FH\\^FD#24-000001\\5C&^FS\n',
  '^FPH,1^FT0,245^A0N,12,12^FB395,1,3,C^FH\\^FD12-10-2024  09.32AM\\5C&^FS\n',
  '^FT20,300^A0N,16,17^FB270,2,1,L,0^FH\\^FDBlanket Medium^FS\n',
  '^FT300,285^A0N,16,17^FH\\^FD-    01^FS\n',
  '^FT20,340^A0N,16,17^FB270,2,1,L,0^FH\\^FDBlanket Large^FS\n',
  '^FT300,325^A0N,16,17^FH\\^FD-    01^FS\n',
  '^FT20,380^A0N,16,17^FB270,2,1,L,0^FH\\^FDPillow Case^FS\n',
  '^FT300,365^A0N,16,17^FH\\^FD-    07^FS\n',
  '^FO3,420^GB400,2,1^FS\n',
  '^FT15,460^A0N,17,18^FH\\^FDTotal Items - 12^FS\n',
  '^FT330,460^A0N,17,18^FH\\^FD90^FS\n',
  '^FO320,436^GB40,40,2^FS\n',
  '^FO115,610^GB180,35,1^FS\n',
  '^FT125,635^A0N,20,22^FH\\^FB160,1,4,C^FDBunch - 1/12\\5C&^FS\n',
  '^PQ1,0,1,Y\n',
  '^XZ\n'
] as Array<string | PrintData>

const SAMPLE_LABEL_COMMANDS_2 = [
  '^XA\n',
  '^MMT\n',
  '^PW406\n',
  '^LL670\n',
  '^LS0\n',
  '^FO130,20^GFA,537,697,17,:Z64:eJxdkrFLAlEcx3/n6XlY6IsKDQStpa2IIEVIn3TuLu4SzpEkEiTcg5aLJMca/QPCIZeDhtyanKL9oKVITKhBsu7XexeKd2943O9zn+H73vsCdBi4loQjN5DR9hroBoDYcwPdCxDHXsMBEgG/MyuIPw4wwwpAEiA4BcTPDeIAJ4hs+nxTQwSR8iSoCCPZ4oD50vK7mQ2rhp09fhSGHIMqSfmXbrTayce/AbvmhpI2hodVYTCZwA5JKWuxxgGiA/hm5sSnrYu9FyyVSkTiW2HFMawwAJudRBiLBOjsrAKo6swo4bP+O/0rlg/bue/52yCrUmBeANt4sC5ZsMlMsPhogU71rygH/lqRUqCalpuIpJGLq+xp+bP7Mjxy8kKgf16ndDlV0bRtHKLNws1WxrImna3BQMJeYgSR/nWd5p/2KlpDzYQW2hB6u8+Wz7p3m8N0HBI8BilE43S/Wl/XSOQVx7egXhgGUxljhikunb+cNA1alNH9tkleD0+F0NsP3dugnLdjAW8LAduu8Q/fX8lL:42AE\n',
  '^BY2,3,60^FT20,126^BCN,,N,N\n',
  '^FH\\^FD>:1A2B3C4D5F6G7H^FS\n',
  '^FO3,251^GB400,2,1^FS\n',
  '^FT0,145^A0N,20,18^FB406,1,5,C^FH\\^CI28^FDFactory_AH\\5C&^FS^CI27\n',
  '^FPH,1^FT0,165^A0N,14,15^FB395,1,4,C^FH\\^CI28^FD4411002\\5C&^FS^CI27\n',
  '^FT0,185^A0N,14,15^FB406,1,4,C^FH\\^CI28^FDMuthukumar - 0550737500\\5C&^FS^CI27\n',
  '^FT0,204^A0N,17,18^FB402,1,4,C^FH\\^CI28^FD#24-000001\\5C&^FS^CI27\n',
  '^FPH,1^FT0,227^A0N,12,12^FB395,1,3,C^FH\\^CI28^FD12-10-2024  09.32AM\\5C&^FS^CI27\n',
  '^FT20,300^A0N,17,18^FB270,2,1,L,0^FH\\^CI28^FDBlanket Medium^FS^CI27\n',
  '^FT300,285^A0N,17,18^FH\\^CI28^FD-    01^FS^CI27\n',
  '^FT20,340^A0N,17,18^FB270,2,1,L,0^FH\\^CI28^FDBlanket Large^FS^CI27\n',
  '^FT300,325^A0N,17,18^FH\\^CI28^FD-    01^FS^CI27\n',
  '^FT20,380^A0N,17,18^FB270,2,1,L,0^FH\\^CI28^FDPillow Case^FS^CI27\n',
  '^FT300,365^A0N,17,18^FH\\^CI28^FD-    07^FS^CI27\n',
  '^FO3,420^GB400,2,1^FS\n',
  '^FT15,460^A0N,17,18^FH\\^CI28^FDTotal Items - 12^FS^CI27\n',
  '^FT330,460^A0N,17,18^FH\\^CI28^FD90^FS^CI27\n',
  '^FO320,436^GB40,40,2^FS\n',
  '^FO115,610^GB160,35,1^FS\n',
  '^FT125,635^A0N,17,18^FH\\^CI28^FB140,1,4,C^FDBunch - 1/12\\5C&^FS^CI27\n',
  '^PQ1,0,1,Y\n',
  '^XZ\n'
]
// Customizable Area End

import Cflabelprinters3Controller, {
  Props,
  configJSON,
} from "./Cflabelprinters3Controller";
import { PrintData } from "qz-tray";

export default class Cflabelprinters3 extends Cflabelprinters3Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const isDev = getEnvironment() === "dev"
    if (!isDev) return null;
    return (
      // Customizable Area Start
      <StyledWrapper>
        <h3 data-test-id={configJSON.pageTitleTest}>Label Printing</h3>
        <button
          onClick={() => this.handlePrintLabel(
            [
              '^XA\n',
              '^FO50,50^ADN,36,20^FDTEST PRINTING LOGO\n',
              {
                type: 'raw', format: 'image', flavor: 'base64', data: LOGO_BASE64,
                options: { language: "ZPL" }
              },
              '^FS\n',
              '^XZ\n'
            ],
          )}
        >Sample Logo</button>
        <button
          data-test-id={configJSON.sampleLabelBtnTest}
          onClick={() => {
            this.handlePrintLabel(SAMPLE_LABEL_COMMANDS)
          }}
        >
          Sample Label
        </button>
        <button
          data-test-id={configJSON.sampleLabelBtnTest}
          onClick={() => {
            this.handlePrintLabel(SAMPLE_LABEL_COMMANDS_2)
          }}
        >
          Sample Label 2
        </button>
        <div className="row">
          <button
            data-test-id={configJSON.sampleLabelBtnTest}
            className="warning"
            onClick={() => {
              const labelWithConfigCommands = [
                '~CT~~CD,~CC^~CT~\n',
                '^XA\n',
                '~TA000\n',
                '~JSN\n',
                '^LT0\n',
                '^MNW\n',
                '^MTT\n',
                '^PON\n',
                '^PMN\n',
                '^LH0,0\n',
                '^JMA\n',
                '^PR6,6\n',
                '~SD15\n',
                '^JUS\n',
                '^LRN\n',
                '^CI27\n',
                '^PA0,1,1,0\n',
                '^XZ\n',
                ...SAMPLE_LABEL_COMMANDS
              ];
              this.handlePrintLabel(labelWithConfigCommands)
            }}>
            Sample Label With Configs
          </button>
          <p>(This will reset some printer configurations. Skip if current printer settings are working fine or if other sample labels above work.)</p>
        </div>
      </StyledWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledWrapper = styled("div")({
  "& button": {
    border: 0,
    background: colors().cyancobaltblue,
    color: "white",
    margin: 20,
    padding: 20,
    fontSize: 16,
    fontWeight: 600,
    borderRadius: 8,
    cursor: "pointer",
    "&.warning": {
      background: "crimson"
    }
  },
  "& .row": {
    display: "flex",
    gap: 10,
    alignItems: "center"
  }
})
// Customizable Area End
