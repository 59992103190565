import React from "react";
// Customizable Area Start
import {
  Typography,
  TextField,
  Button,
  Modal,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  Select,
  MenuItem,
  Snackbar,
  Grid,
  styled,
  IconButton
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineOutlined from "@material-ui/icons/AddCircleOutlineOutlined";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";
import AddRegionController, { Props, configJSON } from "./AddRegionController.web";
import PageContainer from "../../../blocks/navigationmenu/src/PageContainer.web";
import { imgDelete, imgPlus } from "./assets";
import Loader from "../../../components/src/Loader.web";
import { sortCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";
import { InputLabel } from "../../../components/src/customComponents/CustomTags.web";
import { CustomAutoCompleteSearchable } from "../../../components/src/customComponents/CustomAutoCompleteSearchable.web";


export default class AddRegion extends AddRegionController {
  constructor(props: Props) {
    super(props);
  }

  renderTextRegion = (
    title: string,
    options: {
      placeHolder?: string;
      value?: string;
      onChange?: (value: string) => void;
      required?: boolean;
      isDisabled?: boolean,
    },
    error?:string|null,
    showDeleteIcon?: boolean
  ) => {
    const formErrors = this.state.formErrors;
    return (
      <Grid item xs={12} md={6} style={webStyle.columnContainerRegion}>
        {title && (
          <>
            <div className="inputLabelContainer">
              <InputLabel style={{marginBottom:"0px"}}>{title + (sortCondition(options.required as boolean, "*", ""))}</InputLabel>
              {showDeleteIcon && <IconButton onClick={()=>this.handleDeleteDynamicField(title)} data-test-id="fieldDeleteBtn4Region" className="deleteIconBtn" aria-label="delete" size="small">
                <DeleteIcon className="deleteIcon" fontSize="inherit" />
              </IconButton>}
            </div>
            <TextField 
              disabled={options.isDisabled}
              data-test-id={title}
              key={title}
              value={options.value}
              className="inputField"
              error={(options.required && !options.value) || !!formErrors[title] || Boolean(error)}
              variant='outlined'
              placeholder={options.placeHolder || "Enter " + title}
              type={"text"}
              onChange={(e) => {
                options.onChange && options.onChange(e.target.value);
              }}
            />
            {formErrors[title] && <div style={webStyle.errorText}>{formErrors[title]}</div>}
          </>
        )}
        {error&&<ErrorMessage>{error}</ErrorMessage>}
      </Grid>
    );
  };

  renderSelectInputRegion = (
    title: string,
    selectOptions: { text: string; value: string }[] | string[],
    options: {
      required?: boolean;
      placeHolder?: string;
      onChange?: (value: string) => void;
      value?: string;
    },
    showDeleteIcon?: boolean,
    error?:string|null
  ) => {
    const placeHldr = options.placeHolder || "Select " + title;
    let fixedSelectOptions: { text: string; value: string }[] = [];
    if (typeof selectOptions[0] === "string") {
      selectOptions = selectOptions as string[];
      fixedSelectOptions = selectOptions.map((option: string) => ({ text: option, value: option }));
    } else {
      fixedSelectOptions = selectOptions as { text: string; value: string }[];
    }
    const formErrors = this.state.formErrors;

    return (
      <Grid item xs={12} md={6} style={webStyle.columnContainerRegion}>
        {title && (
          <>
            <div className="inputLabelContainer">
              <div style={{marginBottom:"0px"}} className="inputLabelRegion">{title + (sortCondition(options.required as boolean, "*", ""))}</div>
              {showDeleteIcon && <IconButton onClick={() => this.handleDeleteDynamicField(title)} data-test-id="fieldDeleteBtn4Region" className="deleteIconBtn" aria-label="delete" size="small">
                <DeleteIcon className="deleteIcon" fontSize="inherit" />
              </IconButton>}
            </div>

            <Select
              key={title}
              data-test-id={title}
              value={options.value}
              renderValue={(value) =>
                fixedSelectOptions.find((option) => option.value === value)?.text || placeHldr
              }
              onChange={(event) => {
                options.onChange && options.onChange(event.target.value as string);
              }}
              error={(options.required && !options.value) || !!formErrors[title]}
              placeholder={placeHldr}
              className="inputField"
              variant='outlined'
              displayEmpty
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              {fixedSelectOptions.map((selectOption) => (
                <MenuItem value={selectOption.value}>{selectOption.text}</MenuItem>
              ))}
            </Select>
            {formErrors[title] || error && <div style={webStyle.errorText}>{formErrors[title] || error}</div>}
          </>
        )}
      </Grid>
    );
  };

  renderOthersInfoColumnRegion = (
    columnKey: string,
    valueColumn: string | { title: string; isSelected: boolean }[]
  ) =>
    Array.isArray(valueColumn)
      ? this.renderSelectInputRegion(
        columnKey,
        valueColumn.map((item) => item.title),
        {
          value: valueColumn.find((item) => item.isSelected)?.title,
          onChange: (value) => {
            this.setState({
              regionInfo: {
                ...this.state.regionInfo,
                attributes: {
                  ...this.state.regionInfo.attributes,
                  others: {
                    ...this.state.regionInfo.attributes.others,
                    [columnKey]: valueColumn.map((item) => ({
                      ...item,
                      isSelected: item.title === value,
                    })),
                  },
                },
              },
            });
          },
        },
        true,
        this.validateOtherField(this.state.otherFieldError,columnKey)
      )
      : this.renderTextRegion(columnKey, {
        value: valueColumn,
        onChange: (value) => {
          this.setState({
            regionInfo: {
              ...this.state.regionInfo,
              attributes: {
                ...this.state.regionInfo.attributes,
                others: { ...this.state.regionInfo.attributes.others, [columnKey]: value },
              },
            },
          });
        },
      },this.validateOtherField(this.state.otherFieldError,columnKey),true);

  renderOthersInfoRegion = () => {
    if (!this.state.regionInfo.attributes.others) return null;
    const otherInfoKeys = Object.keys(this.state.regionInfo.attributes.others);
    const returnVal = [];

    for (let i = 0; i < otherInfoKeys.length; i += 2) {
      const key1 = otherInfoKeys[i];
      const key2 = otherInfoKeys[i + 1];
      const value1 = this.state.regionInfo.attributes.others[key1];
      const value2 = this.state.regionInfo.attributes.others[key2];
      returnVal.push(
        <>
          {this.renderOthersInfoColumnRegion(key1, value1)}
          {key2 ? this.renderOthersInfoColumnRegion(key2, value2) : this.renderTextRegion("", {})}
        </>
      );
    }

    return returnVal;
  };

  render() {
    return (
      <StyledWrapper>
        <CustomLoader loading={this.state.isLoading} />
        <div style={webStyle.mainContainer}>
          <HeaderStyle>{configJSON.addRegionHeader}</HeaderStyle>
          <Grid container spacing={4} className="formContainer">
            {this.renderTextRegion(configJSON.regionName, {
              value: this.state.regionInfo.attributes.branch_name,
              onChange: (value) => {
                this.setState({
                  regionInfo: {
                    ...this.state.regionInfo,
                    attributes: { ...this.state.regionInfo.attributes, branch_name: value },
                  },
                });
              },
            })}


             <Grid item xs={12} md={6} style={webStyle.columnContainerRegion}>
              <div className="inputLabelRegion">{configJSON.company}</div>
                <CustomAutoCompleteSearchable 
                    dataTestID="company"
                    value={this.state.regionInfo.attributes.company_data} 
                    optionList={this.state.companies.map((company) => ({
                      option: company.attributes.name,
                      id: company.id + "",
                    }))} 
                    debouncedFunction={()=>{}} 
                    handleScroll={this.handleScrollCompanyDropdown} 
                    handleSelectOptions={(value:any)=>{
                      this.setState({
                        regionInfo: {
                          ...this.state.regionInfo,
                          attributes: {
                            ...this.state.regionInfo.attributes,
                            company_id: parseInt(value.id),
                            company_data:{
                              id:value.id,
                              option:value.option
                            }
                          },
                        }
                      });
                    }} 
                    selectedItem={this.state.regionInfo.attributes.company_data} 
                    placeholder={"Select " + configJSON.company} 
                    isError={Boolean(this.state.formErrors && this.state.formErrors[configJSON.company])}
                />
                {this.state.formErrors[configJSON.company] && <div style={webStyle.errorText}>{this.state.formErrors[configJSON.company]}</div>}
               </Grid>

            {this.renderTextRegion(configJSON.regionShortName, {
              value: this.state.regionInfo.attributes.branch_short_name,
              onChange: (value) => {
                this.setState({
                  regionInfo: {
                    ...this.state.regionInfo,
                    attributes: { ...this.state.regionInfo.attributes, branch_short_name: value },
                  },
                });
              },
            })}
            {this.renderTextRegion(configJSON.regionId, {
              value: this.state.regionInfo.attributes.branch_id,
              isDisabled: true,
              onChange: (value) => {
                this.setState({
                  regionInfo: {
                    ...this.state.regionInfo,
                    attributes: { ...this.state.regionInfo.attributes, branch_id: value },
                  },
                });
              },
            })}
            {this.renderTextRegion(configJSON.email, {
              value: this.state.regionInfo.attributes.email,
              onChange: (value) => {
                this.setState({
                  regionInfo: {
                    ...this.state.regionInfo,
                    attributes: { ...this.state.regionInfo.attributes, email: value },
                  },
                });
              },
            })}
            {this.renderTextRegion(configJSON.address, {
              value: this.state.regionInfo.attributes.address,
              placeHolder: "Enter Company/Area/City",
              onChange: (value) => {
                this.setState({
                  regionInfo: {
                    ...this.state.regionInfo,
                    attributes: { ...this.state.regionInfo.attributes, address: value },
                  },
                });
              },
            })}
            {this.renderTextRegion(configJSON.telephone, {
              value: this.state.regionInfo.attributes.telephone,
              placeHolder: "Enter Telephone Number",
              onChange: (value) => {
                value = value.replace(/[^0-9+]/g, "");
                this.setState({
                  regionInfo: {
                    ...this.state.regionInfo,
                    attributes: { ...this.state.regionInfo.attributes, telephone: value },
                  },
                });
              },
            })}
            {this.renderOthersInfoRegion()}
            <Grid item xs={12}>
              <Button
                variant='contained'
                data-test-id={"AddButton"}
                size='small'
                onClick={this.onAddButtonClick}
                startIcon={<AddCircleOutlineOutlined />}
                style={{ ...webStyle.addBtn, textTransform: "none" }}
              >
                {configJSON.addField}
              </Button>
            </Grid>
          </Grid>

          <Grid container direction='row' style={{ marginTop: 48 , paddingBottom: 16 }}>
            <Button
              variant='contained'
              onClick={this.onCancel}
              data-test-id={"CancelButton"}
              size='medium'
              className="cancelBtn"
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              data-test-id={"SaveButton"}
              size='medium'
              onClick={this.onSave}
              className="saveButton"
              color='secondary'
              disabled={this.state.isLoading}
            >
              Add
            </Button>
          </Grid>
          <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={!!this.state.snackBarMessage}
          autoHideDuration={4000}
          onClose={this.closeSnackBar}
        >
          <Alert severity='success'>{this.state.snackBarMessage}</Alert>
        </Snackbar>
        </div>
       
        <Modal
          open={this.state.addModalVisible}
          onClose={() => this.setState({ addModalVisible: false })}
        >
          <Box sx={{ ...webStyle.addModalStyle, width: { xs: '90%', md: '720px' } }}>
            <div>
              <Typography variant='h6' style={{ ...webStyle.addModalHeader, textAlign: "center" }}>
                {configJSON.addField}
              </Typography>
              <div
                style={{ gap: "50px", display: "flex", alignItems: "center", marginBottom: "32px" }}
              >
                <div style={webStyle.addModalCategory}>Select Category:</div>
                <RadioGroup
                  data-test-id={"CategoryRadioGroup"}
                  value={this.state.modalCategory}
                  row
                  onChange={this.onModalCategoryChange}
                >
                  <FormControlLabel value='text' control={<Radio />} label='TextField' />
                  <FormControlLabel value='dropdown' control={<Radio />} label='Dropdown' />
                </RadioGroup>
              </div>

              {this.state.modalCategory === "text" ? (
                <div style={{ display: "flex", gap: "32px" }}>
                  <Grid container spacing={2}>
                    {this.renderTextRegion(configJSON.title, {
                      value: this.state.modalTitle,
                      onChange: (value) => this.setState({ modalTitle: value }),
                    },this.state.modalError.title)}
                    {this.renderTextRegion(configJSON.textFieldText, {
                      value: this.state.modalText,
                      onChange: (value) => this.setState({ modalText: value }),
                    },this.state.modalError.text)}
                  </Grid>
                </div>
              ) : (
                <div style={{ display: "flex", flexDirection: "column", width: "auto" }}>
                  {this.renderTextRegion(configJSON.title, {
                    value: this.state.modalTitle,
                    onChange: (value) => this.setState({ modalTitle: value }),
                  },this.state.modalError.title)}
                  <div style={{ maxHeight: "300px", overflow: "auto" }}>
                    {this.state.modalOptions.map((option, index) => {
                      return (
                        <div style={{ display: "flex", marginTop: '12px' }}>
                          {this.renderTextRegion("Option " + (index + 1), {
                            value: option.title,
                            onChange: (value) => {
                              const options = [...this.state.modalOptions];
                              options[index].title = value;
                              this.setState({ modalOptions: options });
                            },
                          },this.state.modalError.option)}
                          {index === this.state.modalOptions.length - 1 ? (
                            <Button
                              data-test-id={"AddOptionButton"}
                              variant='contained'
                              size='medium'
                              onClick={this.onAddOption}
                              style={webStyle.plusButton}
                              color='secondary'
                            >
                              <img src={imgPlus} />
                            </Button>
                          ) : (
                            <Button
                              data-test-id={"DeleteOptionButton"}
                              variant='text'
                              style={webStyle.deleteButton}
                              onClick={() => this.onDeleteOption(index)}
                              size='medium'
                            >
                              <img src={imgDelete} />
                            </Button>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>

            <div
              style={{
                display: "flex",
                gap: "32px",
                justifyContent: "center",
                marginTop: '12px'
              }}
            >
              <Button
                variant='contained'
                onClick={this.onModalCancelClick}
                style={{
                  ...webStyle.cancelBtn,
                  color: "rgba(100, 116, 139, 1)",
                  fontSize: "16px",
                  textTransform: "none",
                }}
                size='medium'
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                onClick={this.onModalAddClick}
                size='medium'
                style={{ ...webStyle.saveButton, textTransform: "none", fontSize: "16px" }}
                color='secondary'
              >
                Add
              </Button>
            </div>
          </Box>
        </Modal>
     
      </StyledWrapper>
    );
  }
}

const webStyle = {
  inputStyle: {
    height: "100px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    flexDirection: "column",
  },
  formContainer: {
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.04), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    marginTop: 16,
    padding: 32,
    borderRadius: "12px",
  },
  regionHeader: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "24px",
    padding: "0 16px"
  },
  inputLabelRegion: {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "20px",
    alignItems: "center",
    fontFamily: "Montserrat !important",
    letterSpacing: "-0.3px",
    color: "#1A1A1A",
    marginBottom:"6px"
  },
  inputField: {
    fontFamily: "Montserrat !important",
    width: "100%",
    height: "56px",
    marginTop: "4px",
    marginBottom: "0px",
  },
  rowContainer: {
    display: "flex",
    gap: "32px",
  },
  mainContainer: {
    fontFamily: "Montserrat"
  },
  columnContainerRegion: {
    width: "100%",
  },
  addBtn: {
    fontSize: "16px",
    backgroundColor: "#F1F5F9",
    fontWeight: 700,
    marginTop: "8px",
    color: "rgba(77, 77, 77, 1)",
  },
  cancelBtn: {
    backgroundColor: "#F1F5F9",
    fontWeight: 700,
    width: "128px",
    height: "56px",
    color: "#64748B",
    fontSize: "16px",
  },
  saveButton: {
    color: "#FFFFFF",
    backgroundColor: "#204B9C",
    fontSize: "16px",
    fontWeight: 700,
    width: "128px",
    height: "56px",
  },
  addModalStyle: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#FFFFFF",
    border: "1px solid #000",
    boxShadow: 24,
    p: 4,
    width: "720px",
    borderRadius: "24px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    fontFamily: "Montserrat !important",
  },
  addModalCategory: {
    fontFamily: "Montserrat !important",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "24px",
  },
  errorText: {
    color: "red",
    fontSize: "12px",
    marginTop: "4px",
  },
  plusButton: {
    backgroundColor: "#204B9C",
    width: "56px",
    height: "56px",
    marginTop: "23px",
    marginLeft: "20px",
  },
  deleteButton: {
    width: "56px",
    height: "56px",
    marginTop: "23px",
    marginLeft: "20px",
  },
  addModalHeader: {
    color: "#1A1A1A",
    marginBottom: "32px",
    fontWeight: 600,
    fontFamily: "Montserrat",
  },
};

const HeaderStyle = styled(Typography)({
  ...webStyle.regionHeader,
  "@media only screen and (max-width: 1024px)": {
    fontSize: "16px",
  }
})
const ErrorMessage = styled("p")({
  color: "red",
  fontSize: "14px",
  margin: "5px 5px 5px 0",
  fontFamily: 'Montserrat'
})
const StyledWrapper = styled('div')({
  "& .inputLabelContainer": {
    justifyContent: "space-between",
    marginBottom: "6px",
    minHeight: "24px",
    display: "flex",
    alignItems: "center",
    "@media only screen and (max-width: 1024px)": {
      minHeight: "19px",
    },
    "&:hover .deleteIconBtn": {
      display: "initial",
    },
    "& .deleteIconBtn": {
      display: "none"
    },
    "& .deleteIcon": {
      "@media only screen and (max-width: 1024px)": {
        fontSize: "13px",
      }
    }
  },
  "& .inputLabelRegion": {
    ...webStyle.inputLabelRegion,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "13px"
    }
  },
  "& .inputField": {
    ...webStyle.inputField,
    "@media only screen and (max-width: 1024px)": {
      height: "44px"
    },
    "& .MuiInputBase-input": {
      "@media only screen and (max-width: 1024px)": {
        fontSize: "12px",
        padding: "14.9px 14px"
      }
    }
  },
  "& .cancelBtn": {
    ...webStyle.cancelBtn,
    textTransform: "none",
    margin: '10px',
    "@media only screen and (max-width: 1024px)": {
      height: "44px"
    }
  },
  "& .saveButton": {
    ...webStyle.saveButton,
    textTransform: "none",
    margin: '10px',
    "@media only screen and (max-width: 1024px)": {
      height: "44px"
    }
  },
  "& .formContainer": {
    ...webStyle.formContainer,
    "@media only screen and (max-width: 1024px)": {
      padding: "16px"
    }
  }
})
// Customizable Area End
