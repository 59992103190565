import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { getStorageData, setStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { ReceiptPrinterSetting } from "../../navigationmenu/src/HeaderController.web";
import { navigateTo, CustomEnums, getCustomEnumName, HardwarePermissionStatus, customPermissionApiKey, checkForHardwarePermissonStatus } from "../../utilities/src/CustomBlockHelpers";
import { makeApiMessage } from "../../../components/src/common";
import { chunk, cloneDeep, flatten, toString } from "lodash";
import qzTray, { PrintArguments, PrintData, PrinterOptions } from "qz-tray";
import { KEYUTIL, KJUR, stob64, hextorstr } from "jsrsasign";
import { FormikProps } from "formik";
import FileUpdater from "../../../components/src/customComponents/FileUpdater";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
import { IUserContext } from "../../navigationmenu/src/PageContainerController.web";
import * as assets from "./assets";

export interface InvoiceRawData {
  "company_detail": {
    "registration_no": string,
    "vat_number": string | null
  },
  "store_detail": {
    "store_name": string,
    "store_id": string | number,
    "store_address_arabic": string | null,
    "ar_sub_cr_number": string | number | null
  },
  "customer_account": {
    "full_name": string,
    "full_phone_number": string
  },
  "order_created_user": {
    "full_name": string,
    "first_name_arabic": string,
    "last_name_arabic": string
  },
  "order": {
    "order_id": number
    "invoice_number": string,
    "drop_date": string,
    "sub_total": string,
    "applied_discount": string,
    "taxable_amount": string,
    "tax": number,
    "total": string
    "note": string | null
  },
  "order_items": Array<{
    "total_incl_vat": number,
    "vat": number,
    "unit_price": string,
    "quantity": number,
    "service_name": string,
    "product_second_name": string,
    "product_name": string,
    "product_desc": string
    "notes": string | null
    "upcharge_details": Array<unknown>
    "preferences": string | null
    "preferences_specifications": string
  }>,
  "earned_points": number,
  "used_points": number,
  "available_points": number,
  "total_pieces": number,
  "order_request_type": "invoice",
  "item_services": {
    [key: string]: number
  },
  "item_preferences": {
    [key: string]: number
  },
  "qr": string,
  "barcode_url": string,
  "invoice_number_label": string,
  "order_label_text": string,
  "terms": {
    "id": null,
    "description": string,
    "description_ar": string
  }
}

export interface SalesPostedRawData {
  title: string, 
  orders :{
    invoice_number : string | null, 
    order_number: string | null, 
    total_pieces: number, 
    id: string | number | null
  }[]
    store_name:string, 
    store_id: string, 
    total_orders: number, 
    total_pieces: number,
    date: string, 
    time: string, 
    driver_name: string
}

const RESUME_ON_ERRORS = true as PrintArguments | boolean

const DEFAULT_RECEIPT_PRINTER_SETTING = {
  id: -1,
  account_id: -1,
  printer_name: null,
  printer_type: null,
  station: null,
  auto_print_receipt: false,
  auto_print_pay_on_pickup: false,
  receipt_print_new_order: false,
  receipt_print_clean_page: false,
  receipt_print_ready_page: false,
  receipt_print_delivered_page: false,
  no_of_copies: 1,
  printer_setting_type: "receipt_printer",
}

const LOADING_ID = -2

const globalHardwareSetting = {
  qzTrayPrinter: "",
  garmentTagQzTrayPrinter: "",
}
export const globalReceiptPrinterSetting: ReceiptPrinterSetting = { ...DEFAULT_RECEIPT_PRINTER_SETTING, id: -2 }

const RECEIPT_CHARACTERS_PER_LINE = 42

const LOGO = {
  type: "raw",
  format: "image",
  flavor: "file",
  data: assets.logo,
  options: { language: "ESCPOS", dotDensity: "double" }
}

const FOOTER = {
  type: "raw",
  format: "image",
  flavor: "file",
  data: assets.footer,
  options: { language: "ESCPOS", dotDensity: "double" }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  snackbarMode?: boolean;
  isGarmentTagSetting?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedStation: string;
  printers: string[];
  stations: string[];
  snackbarMessage: string;
  initialFormValue: ReceiptPrinterSetting;
  permissionStatus: HardwarePermissionStatus | null
  loading: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PrintController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getSigningDataCallId = "";
  getOrderDetailCallId = "";
  verifyOutFilesCallId = "";
  updateMissingOutFilesCallId = "";
  getReceiptPrinterSettingCallId = "";
  getGarmentTagSettingCallId = "";
  updateSettingCallId = "";
  shouldScanFile = Boolean(this.props.snackbarMode);
  pathsToScanFiles: string[] = [];
  verifyList: { fileName: string; filePath: string }[] = []
  isSigned = false;

  imageInputRef = React.createRef<HTMLInputElement>()
  pdfInputRef = React.createRef<HTMLInputElement>()
  formRef = React.createRef<FormikProps<ReceiptPrinterSetting>>()
  fileUpdater: FileUpdater = FileUpdater.getInstance();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getCustomEnumName(CustomEnums.CustomActionReducers),
      getCustomEnumName(CustomEnums.ReceiptPrinterSettingUpdated),
      getName(MessageEnum.LayoutDataMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedStation: "",
      printers: [],
      stations: Array(5).fill(0).map((element, index) => `Station ${index + 1}`),
      snackbarMessage: "",
      initialFormValue: globalReceiptPrinterSetting,
      permissionStatus: null,
      loading: globalReceiptPrinterSetting.id === LOADING_ID
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.receiveDataFromLayout(message)
    if (getName(MessageEnum.SessionResponseMessage) === message.id && !this.props.snackbarMode) {
      const receivedData = message.getData(getName(MessageEnum.SessionResponseData))
      if (receivedData?.printers) {
        this.setState({ printers: receivedData.printers })
      }
    }

    this.receiveGlobalReceiptPrinterSetting(message)

    if (this.props.snackbarMode) {
      this.receiveActions(message)
      this.receiveApiResponse(message)
    } else {
      this.receiveSettingsResponse(message)
    }
    // Customizable Area End
  }

  // Customizable Area Start
  receiveDataFromLayout = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
      const recievedData = message.getData(
        getName(MessageEnum.LayoutMessageData)
      );
      if (recievedData.userContext) {
        this.handleUserChange(recievedData.userContext)
      }
    }
  }
  async componentDidMount(): Promise<void> {
    super.componentDidMount()

    const [qzTrayPrinter, garmentTagQzTrayPrinter] = await Promise.all([
      configJSON.receiptPrinterStorageKey,
      configJSON.garmentTagStorageKey,
    ]
      .map(async storageKey => {
        const storedData = await getStorageData(storageKey)
        return toString(storedData)
      }
      ));
    Object.assign(globalHardwareSetting, { qzTrayPrinter, garmentTagQzTrayPrinter })
    this.setState((prev) => ({ initialFormValue: { ...prev.initialFormValue, printer_name: this.props.isGarmentTagSetting ? garmentTagQzTrayPrinter : qzTrayPrinter } }), () => {
      if (this.props.snackbarMode) {
        this.fetchCert()
      }
      else {
        this.requestSession()
      }
    });
    if (this.props.isGarmentTagSetting) this.getSetting("getGarmentTagSettingCallId")
  }
  async componentWillUnmount(): Promise<void> {
    super.componentWillUnmount()
    if (qzTray.websocket.isActive() && this.props.snackbarMode) {
      await qzTray.file.stopListening()
      qzTray.websocket.disconnect()
    }
  }

  fetchCert = async () => {
    const requestMessage = makeApiMessage({
      url: configJSON.getQzTrayCertEndPoint,
      method: configJSON.httpGetMethod,
    });
    this.getSigningDataCallId = requestMessage.messageId;
    this.send(requestMessage)
  }

  setupQZTray = async (certificate?: string, privateKeyInput?: string) => {
    const message = new Message(getName(MessageEnum.SessionSaveMessage))
    try {
      if (certificate && privateKeyInput) {
        qzTray.security.setCertificatePromise((resolve: Function) => {
          resolve(certificate)
        })
        qzTray.security.setSignatureAlgorithm("SHA512");
        qzTray.security.setSignaturePromise((toSign: string) => (resolve: Function, reject: Function) => {
          try {
            const privateKey = KEYUTIL.getKey(privateKeyInput)
            const signature = new KJUR.crypto.Signature({ "alg": "SHA512withRSA" })
            signature.init(privateKey)
            signature.updateString(toSign)
            const hexStr = signature.sign()
            resolve(stob64(hextorstr(hexStr)))
          } catch (error) {
            console.error(error)
            reject(error)
          }
        })
      } else {
        this.setState({ snackbarMessage: "error fetching QZ Tray certificate" })
      }
      qzTray.websocket.setClosedCallbacks([() => this.fileUpdater.retryToConnect()])
      if (!qzTray.websocket.isActive()) {
        await qzTray.websocket.connect();
      }
      this.isSigned = Boolean(certificate && privateKeyInput)
      if (this.pathsToScanFiles.length) {
        this.scanFiles(this.pathsToScanFiles)
      }
      const printers = await qzTray.printers.find();
      const printerList = flatten([printers])
      this.setState({
        printers: printerList,
      })
      message.addData(getName(MessageEnum.SessionResponseData), { printers: printerList })
    } catch (error) {
      this.setState({ printers: [] })
      message.addData(getName(MessageEnum.SessionResponseData), { printers: [] })
      console.error(error)
    } finally {
      this.send(message)
      this.requestSession()
    }
  }

  receiveApiResponse = (message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getSigningDataCallId) {
        const { certificate, decrytped_private_key } = responseJson?.qz_tray_key || {}
        this.setupQZTray(certificate, decrytped_private_key)
      }

      if (apiRequestCallId === this.verifyOutFilesCallId && responseJson?.missing_out_files) {
        this.updateMissingOutFiles(responseJson?.missing_out_files || [])
      }

      if (apiRequestCallId === this.updateMissingOutFilesCallId) {
        this.verifyList = []
        this.fileUpdater.updateMetalProgetti()
      }

      this.fileUpdater.receiveApiResponse(apiRequestCallId)
    }
  }

  receiveSettingsResponse = (message: Message) => {
    if (getCustomEnumName(CustomEnums.ReceiptPrinterSettingUpdated) === message.id && !this.props.isGarmentTagSetting) {
      this.handleGetPrinterSetting(globalReceiptPrinterSetting)
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if ([this.updateSettingCallId, this.getGarmentTagSettingCallId].includes(apiRequestCallId)) {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson?.data) {
          if (this.updateSettingCallId === apiRequestCallId) {
            this.handleSavePrinterSelection();
          } else {
            this.handleGetPrinterSetting(responseJson.data);
          }
        } else {
          this.setState({ loading: false, snackbarMessage: "unable to update printer setting" })
        }
      }
    }
  }

  receiveActions = async (message: Message) => {
    if (getCustomEnumName(CustomEnums.CustomActionReducers) === message.id) {
      const action = message.getData(getCustomEnumName(CustomEnums.CustomReducerAction));
      const payload = message.getData(getCustomEnumName(CustomEnums.CustomReducerPayload));
      switch (action) {
        case "PRINT_RECEIPT": {
          const numOfCopies = globalReceiptPrinterSetting.no_of_copies || 1
          const printData = this.generateRawInvoice(payload)
          this.printWithQzTray(Array(numOfCopies).fill(printData), globalHardwareSetting.qzTrayPrinter, { encoding: "IBM864" })
          break;
        }
        case "PRINT_SALES_POSTED": {
          const printData = this.generateRawSalesPosted(payload)
          this.printWithQzTray(printData as Array<PrintData>, globalHardwareSetting.qzTrayPrinter, { encoding: "IBM864" })
          break;
        }
        case "PRINT_FILE": {
          this.printFromFile(payload)
          break;
        }
        case "PRINT_TAGS":
          this.printTags(payload)
          break;
        case "WRITE_FILES":
          this.writeFiles(payload)
          break;
        case "SCAN_FILES": {
          this.getSetting("getReceiptPrinterSettingCallId")
          this.receiveScanAction(payload || [])
          break;
        }
        case "LOG_OUT":
          this.handleLogout()
          break;
        default:
          break;
      }
    }
  }

  receiveGlobalReceiptPrinterSetting = (message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === this.getReceiptPrinterSettingCallId) {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        const updateData: ReceiptPrinterSetting = responseJson?.data || DEFAULT_RECEIPT_PRINTER_SETTING
        delete updateData.updated_at
        delete updateData.created_at
        delete updateData.printer_setting_type
        Object.assign(globalReceiptPrinterSetting, updateData)
        const updateSettingMessage = new Message(getCustomEnumName(CustomEnums.ReceiptPrinterSettingUpdated))
        this.send(updateSettingMessage)
      }
    }
  }

  handleLogout = async () => {
    Object.assign(globalReceiptPrinterSetting, { ...DEFAULT_RECEIPT_PRINTER_SETTING, id: -2 })
    await this.fileUpdater.stopListening()
    this.shouldScanFile = true
  }

  receiveScanAction = (paths: string[]) => {
    if (this.isSigned && qzTray.websocket.isActive()) {
      this.scanFiles(paths)
    }
    else {
      this.pathsToScanFiles = paths
    }
  }

  changePrinter = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newSelectedPrinter = toString(event.target.value);
    this.formRef.current?.setFieldValue("printer_name", newSelectedPrinter)
  }

  changeStation = (event: React.ChangeEvent<{ value: unknown }>) => this.setState({ selectedStation: toString(event.target.value) })

  requestSession = () => {
    const message = new Message(getName(MessageEnum.SessionRequestMessage))
    this.send(message)
  }

  scanFiles = async (paths: string[]) => {
    if (!this.shouldScanFile) return;
    try {
      const scanResults = await Promise.all(paths.flatMap(path => this.scanPath(path)))
      const files = scanResults.flat()
      if (files.length) {
        this.verifyOutFiles(files)
      }
      this.fileUpdater.startListening(paths)
      return files
    } catch (error) {
      console.error(error)
      return null
    } finally {
      this.shouldScanFile = false
    }
  }

  scanPath = async (path: string) => {
    try {
      const files = await qzTray.file.list(path, { sandbox: false, shared: true })
      return files.map(file => ({ filePath: path + "\\" + file, fileName: file }))
    } catch (error) {
      runEngine.debugLog("ERROR SCANNING FILES AT", path)
      console.error(error)
      return []
    }
  }

  verifyOutFiles = (files: { fileName: string; filePath: string }[]) => {
    const apiMessage = makeApiMessage({
      url: configJSON.verifyOutFilesEndPoint,
      method: configJSON.httpPostMethod,
      body: JSON.stringify({
        file_names: files.map(file => file.fileName)
      })
    })
    this.verifyOutFilesCallId = apiMessage.messageId
    this.verifyList = files
    this.send(apiMessage)
  }

  updateMissingOutFiles = async (fileNames: string[]) => {
    const allData = await Promise.all(fileNames.map(async fileName => {
      try {
        const filePath = this.verifyList.find(item => item.fileName === fileName)?.filePath
        if (!filePath) return null;
        const file_content = await qzTray.file.read(filePath, { shared: true, sandbox: false })
        return {
          file_name: fileName,
          file_content
        }
      } catch (error) {
        return null
      }
    }))
    const postData = allData.filter(Boolean) as Array<{ file_name: string, file_content: string }>
    if (postData.length) {
      const apiMessage = makeApiMessage({
        url: configJSON.updateOutFiles,
        method: configJSON.httpPostMethod,
        body: JSON.stringify({
          data: postData
        })
      })
      this.updateMissingOutFilesCallId = apiMessage.messageId
      this.send(apiMessage)
    }
  }

  printWithQzTray = async (
    printData: PrintData[][] | PrintData[],
    printerName: string,
    printerOptions?: PrinterOptions
  ) => {
    runEngine.debugLog("qztray print data and config", { printData, printerName })
    if (!printData.length) return;
    if (!this.state.printers.includes(printerName)) {
      return this.setState({ snackbarMessage: this.errorMessage() });
    }
    try {
      if (!qzTray.websocket.isActive()) {
        await qzTray.websocket.connect();
      }
      const config = qzTray.configs.create(printerName, printerOptions)
      await qzTray.print(config, printData as PrintData[], RESUME_ON_ERRORS as PrintArguments)
    } catch (error) {
      this.setState({ snackbarMessage: error instanceof Error ? error.message : "Print Error" })
      console.error(error)
    }
  }

  printTags = (rawDatas: Array<string>) => {
    if (!this.state.printers.includes(globalHardwareSetting.garmentTagQzTrayPrinter)) {
      return this.setState({ snackbarMessage: this.errorMessage("Garment Tag Printer") });
    }
    const printData = rawDatas.map(rawData => [
      {
        data: rawData,
        type: "raw",
        format: "command",
        flavor: 'plain',
      }
    ]) as PrintData[][]
    this.printWithQzTray(
      printData,
      globalHardwareSetting.garmentTagQzTrayPrinter
    )
  }

  printFromFile = (payload: { data: string; format: "image" | "pdf" }) => {
    const numOfCopies = globalReceiptPrinterSetting.no_of_copies || 1
    const itemPrintData = {
      type: "pixel",
      flavor: "file",
      ...payload
    }
    const printData = Array(numOfCopies).fill([itemPrintData])
    this.printWithQzTray(printData, globalHardwareSetting.qzTrayPrinter);
  }

  writeFiles = async (payload: { file_path: string, file_content: string }[]) => {
    try {
      await Promise.all(payload.map(fileItem =>
        qzTray.file.write(fileItem.file_path, { sandbox: false, shared: true, data: fileItem.file_content }))
      )
      this.setState({ snackbarMessage: configJSON.writeFileSuccessMsg })
    } catch (error) {
      this.setState({ snackbarMessage: configJSON.sharedFolderConnectionError })
      console.error(error)
    }
  }

  handleCloseSnackbar = () => this.setState({ snackbarMessage: "" })

  testPrintFile = (isPrintImage?: boolean) => {
    const data = isPrintImage ? this.imageInputRef.current?.value : this.pdfInputRef.current?.value
    const printMessage = new Message(getCustomEnumName(CustomEnums.CustomActionReducers))
    printMessage.addData(getCustomEnumName(CustomEnums.CustomReducerAction), "PRINT_FILE")
    printMessage.addData(getCustomEnumName(CustomEnums.CustomReducerPayload), {
      format: isPrintImage ? "image" : "pdf",
      data
    })
    this.send(printMessage)
  }

  testPrintRaw = async (printData: Array<PrintData | string>) => {
    runEngine.debugLog("RAW DATA TEST", { printData })
    try {
      const storedPrinter = await getStorageData(configJSON.receiptPrinterStorageKey);
      const config = qzTray.configs.create(storedPrinter, { encoding: "IBM864" })
      await qzTray.print(config, printData as PrintData[])
    } catch (error) {
      console.error(error)
    }

  }

  handleRedirect = () => this.props.navigation && navigateTo({ id: "", props: this.props, screenName: "AdvancedSearch" })

  handleSave = (formValues: ReceiptPrinterSetting) => {
    const printer_name = this.currentPrinterName()
    const updateValues: Partial<ReceiptPrinterSetting> = this.props.isGarmentTagSetting
      ? {
        id: formValues.id,
        account_id: formValues.account_id,
        printer_name
      }
      : {
        ...formValues,
        printer_name
      }
    this.updatePrinterSetting(updateValues)
  }


  handleSavePrinterSelection = async () => {
    const printerName = this.currentPrinterName()
    const storageKey = this.storageKey() as "qzTrayPrinter" | "garmentTagQzTrayPrinter"
    if (printerName) {
      await setStorageData(storageKey, printerName)
      globalHardwareSetting[storageKey] = printerName
    } else {
      await removeStorageData(storageKey)
      globalHardwareSetting[storageKey] = ""
    }
    this.setState({ loading: false, snackbarMessage: configJSON.saveSettingSuccessMsg })
    if (!this.props.isGarmentTagSetting) {
      this.getSetting("getReceiptPrinterSettingCallId")
    }
  }

  storageKey = () => this.props.isGarmentTagSetting ? configJSON.garmentTagStorageKey : configJSON.receiptPrinterStorageKey;

  currentPrinterName = () => {
    const printer_name = toString(this.formRef.current?.values.printer_name)
    const printerName = this.state.printers.includes(printer_name) ? printer_name : ""
    return printerName
  }

  handleUserChange = (context: IUserContext) => {
    const apiKey = customPermissionApiKey.hardwarePermission;
    const userData = context.user?.attributes.permission_groups;
    const value = checkForHardwarePermissonStatus(apiKey, userData as unknown as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    });
  }
  errorMessage = (printerType?: string) => `Select a printer in ${printerType || configJSON.receiptPrinter} setting page to print`;

  handleGetPrinterSetting = (newPrinterSetting?: ReceiptPrinterSetting) => {
    if (!newPrinterSetting) return this.setState({ loading: false, snackbarMessage: "unable to fetch printer setting" })
    const clonedPrinterSetting = cloneDeep(newPrinterSetting)
    delete clonedPrinterSetting.created_at
    delete clonedPrinterSetting.updated_at
    delete clonedPrinterSetting.printer_setting_type
    this.setState({
      initialFormValue: {
        ...clonedPrinterSetting,
        printer_name: toString(this.formRef.current?.values.printer_name)
      },
      loading: false
    })
  }

  updatePrinterSetting = (updateValues: Partial<ReceiptPrinterSetting>) => {
    this.setState({ loading: true })
    const requestMessage = makeApiMessage({
      method: configJSON.httpPutMethod,
      url: configJSON.printerSettingsApi + updateValues.id,
      body: JSON.stringify({
        data: updateValues
      })
    })
    this.updateSettingCallId = requestMessage.messageId
    this.send(requestMessage)
  }

  getSetting = (callId: "getGarmentTagSettingCallId" | "getReceiptPrinterSettingCallId") => {
    const requestMessage = makeApiMessage({
      method: configJSON.httpGetMethod,
      url: callId === "getGarmentTagSettingCallId" ? configJSON.garmentTagSettingApi : configJSON.receiptPrinterSettingApi,
    })
    this[callId] = requestMessage.messageId
    this.send(requestMessage)
  }

  getString = (codeNumber: number) => String.fromCharCode(codeNumber)

  generateRawInvoice = (orderData: InvoiceRawData) => {
    const line = "-".repeat(RECEIPT_CHARACTERS_PER_LINE) + "\n"
    const receipt =
      [
        "\x1B\x61\x01" +
        "\x1B\x74\x25" +
        "شركة مغاسل الجبر المحدودة" +
        "\n" +
        "\x1B\x00" +
        "Aljabrlaundries Co Ltd\n" +
        "فاتورة ضريبية مبسطة" +
        "\r\n\n" +
        `${orderData.order_label_text}\n\n` +
        '\x1b\x21\x30' + orderData.store_detail.store_name +
        "\n" +
        '\x1b\x21\x0A\x1B\x45\x0A' +
        `${orderData.store_detail.store_id}\n` +
        `CR: ${orderData.company_detail.registration_no} | VAT: ${orderData.company_detail.vat_number}\n` +
        `Sub CR: ${orderData.store_detail.ar_sub_cr_number}\n` +
        line +
        "\n" +
        '\x1B\x21\x30' +
        `${orderData.invoice_number_label}\n` +
        '\x1b\x21\x0A\x1B\x45\x0A',
        {
          type: "raw",
          format: "image",
          flavor: "base64",
          data: `data:image/png;base64,${orderData.barcode_url}`,
          options: { language: "ESCPOS", dotDensity: "double" }
        },
        `${orderData.order.invoice_number.split("").join(" ")}\n` +
        line +
        `Drop Date: ${orderData.order.drop_date}\n` +
        `Storekeeper : ${orderData.order_created_user.full_name}\n` +
        line +
        `${orderData.customer_account.full_name} | Mob: ${orderData.customer_account.full_phone_number}\n` +
        "\x1B\x61\x00" +
        line +
        "Total\n" +
        "Includ.   VAT  Unit  Qty  Svc. Description\n" +
        "VAT            Price\n" +
        "- - - - - - - - - - - - - - - - - - - - - \n" +
        this.getRawItems(orderData.order_items) +
        "- - - - - - - - - - - - - - - - - - - - - \n" +
        `${toString(orderData.order.sub_total).padEnd(23)}Sub Total | المجموع\n` +
        `${toString(orderData.order.applied_discount).padEnd(28)}Discount | خصم\n` +
        `${toString(orderData.order.taxable_amount).padEnd(9)}Taxable Amount | الإجمالي قبل الضر\n` +
        `${toString(orderData.order.tax).padEnd(22)}VAT | القيمة المضافة\n` +
        `${toString(orderData.order.total).padEnd(27)}Total Incl. VAT\n` +
        "\x1B\x61\x02" + "\u200eالإجماليشامل الإجمالي" + " \n\x1B\x61\x00" +
        `${"0.00".padEnd(13)}Used Credit | المستخدم الرصيد\n` +
        `\x1B\x21\x30${toString(orderData.order.total).padEnd(10)}\x1B\x21\x00\x1B\x45\x0DNet Payable\x1B\x45\x0A\n\n` +
        "\x1B\x61\x02" + "\u200eمستحق الدفع صافي" + " \n\x1B\x61\x00" +
        line +
        `\x1B\x21\x30${toString(orderData.total_pieces).padEnd(8)}\x1B\x21\x00\x1B\x45\x0DTotal Pieces | مجموع القطع\x1B\x45\x0A\n` +
        line + "-" +
        "\n" +
        "\x1B\x61\x01"
      ]

    const logo = [
      "\x1B\x40" +
      "\x1B\x61\x01",
      LOGO
    ]

    const payload = [
      ...logo,
      ...receipt,
      {
        type: "raw",
        format: "image",
        flavor: "base64",
        data: orderData.qr,
        options: { language: "ESCPOS", dotDensity: "double" }
      },
      "\x1B\x61\x01",
      FOOTER,
      {
        type: "raw",
        format: "html",
        flavor: "plain",
        data: `<div style="display:flex;justify-content:space-between;gap: 16px;"><div>${orderData.terms.description}</div><div>${orderData.terms.description_ar}</div></div>`,
        options: { language: "ESCPOS", pageWidth: 400, dotDensity: "double" }
      },
      "\x0A\x1D\x56\x00"
    ]
    return payload
  }

  getRawItems = (orderItems: InvoiceRawData["order_items"]) => {
    const widths = {
      sub_total: 9,
      vat: 4,
      unit_price: 5,
      qty: 4,
      service_short_name: 4,
      desc: 11,
      total: RECEIPT_CHARACTERS_PER_LINE
    };
    const formattedItems = orderItems.map(item => {
      const lines = [];
      const maxLines = Math.max(
        Math.ceil(toString(item.total_incl_vat).length / widths.sub_total),
        Math.ceil(toString(item.vat).length / widths.vat),
        Math.ceil(toString(item.unit_price).length / widths.unit_price),
        Math.ceil(toString(item.quantity).length / widths.qty),
        Math.ceil(toString(item.service_name).length / widths.service_short_name),
        Math.ceil(toString(item.product_name).length / widths.desc)
      );

      for (let line = 0; line < maxLines; line++) {
        lines.push([
          this.padField(widths.sub_total, this.getChunk(widths.sub_total, line, item.total_incl_vat)),
          this.padField(widths.vat, this.getChunk(widths.vat, line, item.vat)),
          this.padField(widths.unit_price, this.getChunk(widths.unit_price, line, item.unit_price)),
          this.padField(widths.qty, this.getChunk(widths.qty, line, item.quantity)),
          this.padField(widths.service_short_name, this.getChunk(widths.service_short_name, line, item.service_name)),
          this.getChunk(widths.desc, line, item.product_name).padStart(widths.desc),
        ].join(" "));
      }
      const productSecondName = this.conditionalString(item.product_second_name, "\u200e" + item.product_second_name + " \x0A", "")
      const notes = this.conditionalString(item.notes, ("\u200e" + item.notes + " \x0A"), "")
      const itemPreference = this.getPreferencesString(item.preferences)
      const combinedInformation = productSecondName + notes + itemPreference
      const productInformation = this.conditionalString(combinedInformation, "\x1B\x61\x02" + combinedInformation + "\x1B\x61\x00", "")
      lines.push(productInformation)
      return lines.join("\n");
    });
    return formattedItems.join("");
  }


  padField = (width: number, value: unknown) => {
    return toString(value).padEnd(width);
  }

  getChunk = (width: number, lineIndex: number, value: unknown) => {
    const start = lineIndex * width;
    return toString(value).substring(start, width + start);
  }

  conditionalString = (condition: unknown, valueIfTrue: string, valueIfFalse: string) => condition ? valueIfTrue : valueIfFalse

  getPreferencesString = (preferencesString: string | null) => {
    if (!preferencesString) return ""
    if (preferencesString.length < RECEIPT_CHARACTERS_PER_LINE) return (preferencesString + " \n")
    const preferences = preferencesString.split(" / ")
    let result = ""
    let currentNumOfCharacters = 0
    let endCharacters = " \n"
    for (const preference of preferences) {
      if (preference.length >= RECEIPT_CHARACTERS_PER_LINE) {
        const chunkArrays = chunk(preference, RECEIPT_CHARACTERS_PER_LINE - 1)
        const chunks = chunkArrays.map(chunkArray => chunkArray.join(""))
        const nextString = chunks.join(" \n")
        result = result + this.conditionalString(currentNumOfCharacters, "\n", "") + nextString + " \n"
        currentNumOfCharacters = 0
        endCharacters = ""
      } else {
        const nextString = this.conditionalString(currentNumOfCharacters, ` / ${preference}`, preference)
        const numOfCharacters = currentNumOfCharacters + nextString.length
        if (numOfCharacters < RECEIPT_CHARACTERS_PER_LINE) {
          result += nextString
          currentNumOfCharacters = numOfCharacters
        } else {
          result = result + "\n" + preference
          currentNumOfCharacters = preference.length
        }
      }
    }
    return result + endCharacters
  }

  generateRawSalesPosted = (salesPostedData: SalesPostedRawData) => {
    const line = "_".repeat(RECEIPT_CHARACTERS_PER_LINE) + "\n\n"
    const printData = [
      "\x1B\x40" +
      "\x1B\x61\x01",
      LOGO,
      "\x1B\x21\x30Sales Posted\x1B\x21\x00\x0A" +
      line +
      `\x1B\x45\x0DStore Name: ${salesPostedData.store_name}, Store ID: ${salesPostedData.store_id}\x1B\x45\x0A\x0A` +
      `Date: ${salesPostedData.date}, Time: ${salesPostedData.time}\n` +
      `Driver Name: ${salesPostedData.driver_name}\n` +
      line + "\x1B\x61\x00" +
      this.salesPostedTable(
        salesPostedData.orders.map(
          order => ({
            order_number: toString(order.invoice_number) || toString(order.order_number),
            total_pieces: toString(order.total_pieces)
          })
        ),
        toString(salesPostedData.total_orders),
        toString(salesPostedData.total_pieces)
      ) +
      line + "\x1B\x61\x01",
      FOOTER,
      "\x0A\x1D\x56\x00"
    ]
    return printData
  }

  salesPostedTable = (items: {order_number: string; total_pieces: string}[], totalOrderCount: string, totalPiecesCount: string) => {
    let output = "";
    output += "\x1B\x45\x0D" + " ".repeat(12) + "Orders" + " ".repeat(7) + "Pieces" + "\x1B\x45\x0A\n";
    output += "-".repeat(RECEIPT_CHARACTERS_PER_LINE) + "\n";
    items.forEach(item => {
        output += this.formatOrdersPiecesRow(item.order_number, item.total_pieces);
    });

    output += "_".repeat(RECEIPT_CHARACTERS_PER_LINE) +"\n\n"
    output += " ".repeat(18) + "\x1B\x45\x0DTotal\n";
    output += this.formatOrdersPiecesRow(totalOrderCount, totalPiecesCount, true, "   ") + '\x1B\x45\x0A';

    return output;
}

  formatOrdersPiecesRow = (
    orderCount: string, 
    piecesCount: string,
    isTotalLine?: boolean,
    divider = ' | ', 
    maxOrderCountLength = 20, 
    maxPiecesCountLength = 19, 
  ) => {
    let formattedLines = "";
    const orderLines = this.chunkString(orderCount, maxOrderCountLength);
    const piecesLines = this.chunkString(piecesCount, maxPiecesCountLength);

    const maxLines = Math.max(orderLines.length, piecesLines.length);

    for (let i = 0; i < maxLines; i++) {
        const orderLine = toString(orderLines[i]);
        const piecesLine = toString(piecesLines[i]);

        const orderAligned = (orderLines.length === 1) ? this.centerAlign(orderLine, maxOrderCountLength, 10) : orderLine;
        const piecesAligned = (piecesLines.length === 1) ? this.centerAlign(piecesLine, maxPiecesCountLength, 10, true) : piecesLine;

        formattedLines += orderAligned.padEnd(maxOrderCountLength) + divider + piecesAligned.padEnd(maxPiecesCountLength) + "\n";
    }

    if (
      (orderLines.length > 1 || piecesLines.length > 1)
      && !isTotalLine
    ) {
        formattedLines += " ".repeat(21) + "|\n";
    }

    return formattedLines;
  }

  centerAlign = (text: string, maxLength: number, shiftSpaces = 0, shiftToLeft?: boolean) => {
    const leftSpaces = shiftToLeft ? 0 : shiftSpaces
    const rightSpaces = shiftToLeft ? shiftSpaces: 0
    if (leftSpaces && text.length >= maxLength - leftSpaces) {
      return text.padStart(maxLength)
    }
    if (rightSpaces && text.length >= maxLength - rightSpaces) {
      return text.padEnd(maxLength)
    }
    const totalPadding = maxLength - text.length - leftSpaces - rightSpaces;
    const leftPadding = Math.floor(totalPadding / 2);
    const rightPadding = totalPadding - leftPadding
    return " ".repeat(leftPadding + leftSpaces) + text + " ".repeat(rightPadding + rightSpaces);
  }

  chunkString = (inputString: string, maxLength: number) => chunk(inputString, maxLength).map(chunkArray => chunkArray.join("")) 
  // Customizable Area End
}
