import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ISortingData } from "../../../components/src/SortingTableHeader";
import { IFilter } from "../../../components/src/FilterPopover";
import { makeApiMessage } from "../../../components/src/common";

// Customizable Area Start
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  checkIsFilterApplied,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
interface OrganizationListItem {
  id: number,
  type: string,
  attributes: {
    id: number,
    name: string,
    tax_number: string,
    active: boolean
  }
}
interface OrganizationAttrItem {
  id: number,
  name: string,
  tax_number: string,
  active: boolean
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isLoading: boolean;
  organizations: OrganizationListItem[];
  sortingData: ISortingData;
  filterAnchor: any;
  filters: IFilter[];
  page: number;
  pageSize: number;
  searchTextOrganization: string;
  query: string;
  openDeactiveModel: boolean;
  deActivatedItem: OrganizationAttrItem;
  deactivated: boolean;
  openAction: any;
  organization: OrganizationListItem;
  suggestionFieldTitle: string;
  meta: {
    next_page: number;
    pervious_page: number;
    total_pages: number;
    total_count: number;
    current_page: number
  };
  permissionStatus: PermissionStatus;
  isAppliedFilter: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class OrganizationListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getOrganizationListCallId: string = "";
  deactivateOrgApiId: string = "";
  activateOrgApiId: string = "";
  filterSuggestionApiCallId: string = "";

  handleQueryChange = (query: string) => {
    this.setState({ query }, () => this.getOrganizationList());
  };

  sortingProps = {
    onQueryChange: (query: string) => this.handleQueryChange(query),
    onChange: (sortingData: ISortingData) => this.setState({ sortingData }),
  };

  async componentDidMount() {
    this.handleStorageFilter();
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.LayoutDataMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SearchTextMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isLoading: false,
      sortingData: {
        name: "",
        tax_number: ""
      },
      filterAnchor: false,
      suggestionFieldTitle: "",
      filters: [
        {
          title: "Organization",
          type: "autocompolete",
          value: "",
          apiKey: "organization",
          options: [],
        },
        {
          title: "Tax Number",
          type: "autocompolete",
          value: "",
          apiKey: "tax_number",
          options: [],
        },
      ],
      page: 0,
      pageSize: 10,
      searchTextOrganization: "",
      query: "",
      organizations: [],
      openDeactiveModel: false,
      deActivatedItem: {
        id: 0,
        name: "",
        tax_number: "",
        active: false
      },
      deactivated: false,
      openAction: null,
      organization: {
        id: 0,
        type: "",
        attributes: {
          id: 0,
          name: "",
          tax_number: "",
          active: false
        }
      },
      meta: {
        next_page: 1,
        pervious_page: 1,
        total_pages: 1,
        total_count: 1,
        current_page: 1
      },
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      },
      isAppliedFilter: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      switch(apiRequestCallId) {
        case this.getOrganizationListCallId:
          this.handleOrgListResp(responseJson);
          break;
        case this.deactivateOrgApiId:
          this.handleDeactivateOrgResp(responseJson);
          break;
        case this.activateOrgApiId:
          this.handleActivateOrgResp(responseJson);
          break;
        case this.filterSuggestionApiCallId:
          this.handleFilterSuggResp(responseJson);
          break;
      }  
    }

    this.receiveSearchTextData(message)
    this.receiveDataFromLayout(message)
    // Customizable Area End
  }

  // Customizable Area Start
  receiveSearchTextData = (message: Message) => {
    if (message.id === getName(MessageEnum.SearchTextMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.SearchMessageText)
        );
        if (recievedData) {
          this.handleSearchOrganization(recievedData.searchText)
        }
    }
  }
  receiveDataFromLayout = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.LayoutMessageData)
        );
        if (recievedData.userContext) {
          this.handleUserChange(recievedData.userContext)
        }
    }
  }

  handlePageChange = (
    page: number
  ) => { this.setState({ page }, () => this.getOrganizationList()) };

  handleSearchOrganization = (value: string) => {	
    this.setState(	
      {	
        page: 0,
        searchTextOrganization: value,
      },	
      this.getOrganizationList	
    );
  };

  navigateAddOrganizationPage = () => {
    this.props.navigation.navigate("AddOrganization");
  }

  openOrgPopver = (event: any) => {
    this.setState({ filterAnchor: event.currentTarget });
  }

  closeOrgPopver = () => {
    this.setState({ filterAnchor: undefined });
  }

  handleCloseConfirmModal = () => {
    this.setState({ openDeactiveModel: false, })
  }

  handleDeactivate = () => {
    this.deActivateOrganization(this.state.deActivatedItem);
    this.setState({ openDeactiveModel: false });
  };

  handleGoBackToListing = () => {
    this.setState({ deactivated: false });
    this.getOrganizationList();
  };

  handlepopver = (event: any, item: OrganizationListItem) => {
    this.setState({
      openAction: event.currentTarget,
      organization: item,
    }); 
  }

  handleCloseActionList = () => {
    this.setState({ openAction: null })
  };

  handleViewOrganizationDetails = () => {
    this.props.navigation.navigate("ViewOrganization", { organizationId: this.state.organization.id });
    this.handleCloseActionList();
  }

  handleEditOrganizationDetails = () => {
    this.props.navigation.navigate("EditOrganization", { organizationId: this.state.organization.id });
    this.setState({ openAction: null });
    this.handleCloseActionList();
  }

  handleDeactiveOrganizationDetails = (deActivatedItem: OrganizationAttrItem) => {
    if(deActivatedItem.active) {
      this.setState({
        openAction: null,
        deActivatedItem: deActivatedItem,
        openDeactiveModel: true,
      });
    }else {
      this.activeOrganization(deActivatedItem)
    }
  }

  handleFilterChange = (filters: IFilter[]) => {
    // set filter applied value set
    if (checkIsFilterApplied(filters)) {
      localStorage.setItem("organization_filter_value", JSON.stringify(filters));
    } else {
      localStorage.removeItem("organization_filter_value");
    };

    this.setState(
      {
        filters,
        isAppliedFilter: checkIsFilterApplied(filters),
        page: 0,
      },
      this.getOrganizationList
    );
  };

  handleClearFilter = () => {
    let updated = this.state.filters.map((item: IFilter) => {
      item.value = "";
      item.options = [];
      return item;
    });
    this.setState({ filters: updated });
  }

  handleFilterAutocompleteChange = (title: string, value: string) => {
    this.setState({ suggestionFieldTitle: title });
    this.handleOrganizationAutoComplete(title, value);
  }

  getOrganizationList = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem(configJSON.token),
    };
    const nameFilter = this.state.filters.find((item:any) => item.title === "Organization")?.value;
    const taxNumFilter = this.state.filters.find((item:any) => item.title === "Tax Number")?.value;
    const searchTextOrganization = this.state.searchTextOrganization;

    const orgnizationListApiEndPoint =
      configJSON.getAllOrgListEndPoint +
      "?page_no=" +
      (this.state.page + 1) +
      "&per_page=" + 10 +
      this.state.query +
      (nameFilter ? `&filter_by[name]=${nameFilter}` : "") +
      (taxNumFilter ? `&filter_by[tax_number]=${taxNumFilter}` : "") +
      (searchTextOrganization ? `&filter_by[query]=${searchTextOrganization}` : "")

    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getOrganizationListCallId = getDataMsg.messageId;

    getDataMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), orgnizationListApiEndPoint);
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  deActivateOrganization = (item: any) => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem(configJSON.token)
    };
    const apiUrl = configJSON.orgApiEndPoint + "/" + item.id + "/" + configJSON.textDeactivate
    const deactiveOrgmessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deactivateOrgApiId = deactiveOrgmessage.messageId;
    deactiveOrgmessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );
    deactiveOrgmessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    deactiveOrgmessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.typeput
    );
    runEngine.sendMessage(deactiveOrgmessage.id, deactiveOrgmessage);
  }

  activeOrganization = (item: any) => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem(configJSON.token)
    };
    const apiUrl = configJSON.orgApiEndPoint + "/" + item.id + "/" + configJSON.textActivate
    const deactiveOrgmessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.activateOrgApiId = deactiveOrgmessage.messageId;
    deactiveOrgmessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );
    deactiveOrgmessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    deactiveOrgmessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.typeput
    );
    runEngine.sendMessage(deactiveOrgmessage.id, deactiveOrgmessage);
  }

  handleOrgListResp = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({
        meta: responseJson.meta,
        organizations: responseJson.data
      });
    }
  };

  handleDeactivateOrgResp = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({deactivated: true});
    }
  };

  handleActivateOrgResp = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.handleCloseActionList();
      this.getOrganizationList();
    }
  };

  handleOrganizationAutoComplete = (title: string, value: string) => {
    this.state.filters.forEach((item: IFilter) => {
      if (item.title === title) item.value = value;
      return item;
    });

    let ApiUrl;
    if(title === "Organization") {
      ApiUrl = configJSON.getOrgSuggestionListEndPoint;
    } else if (title === "Tax Number") {
      ApiUrl = configJSON.getTaxNumSuggestionListEndPoint;
    };

    let requestMessage = makeApiMessage({
      url: ApiUrl + value,
      method: "GET",
    });
    this.filterSuggestionApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleFilterSuggResp = (responseJson: any) => {
    if(responseJson.suggestion !== "no keywords found") {
      const list = responseJson?.suggestion?.map((value: string) => {
        return {
          label: value,
          value,
        }
      }) || [];
      const updatedFilters = this.state.filters.map((item: IFilter) => {
        if(item.value !== ""){
          if (item.title === this.state.suggestionFieldTitle) item.options = list;
          return item;
        } else {
          if (item.title === this.state.suggestionFieldTitle) item.options = [];
          return item;
        }
      });
      this.setState({ filters: updatedFilters });
    } else {
      const updatedFilters = this.state.filters.map((item: IFilter) => {
        if (item.title === this.state.suggestionFieldTitle) item.options = [];
          return item;
      });
      this.setState({ filters: updatedFilters });
    }
  }

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.organisationPermission;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    })
  };

  handleReturnColorType = () => {
    const { isAppliedFilter } = this.state;
    return isAppliedFilter ? "primary" : "inherit";
  };

  handleStorageFilter = () => {
    const applied_organization_filter = localStorage.getItem("organization_filter_value");
    if (applied_organization_filter) {
      this.setState(
        {
          filters: JSON.parse(applied_organization_filter),
          isAppliedFilter: checkIsFilterApplied(
            JSON.parse(applied_organization_filter)
          ),
        },
        () => this.getOrganizationList()
      );
    } else {
      this.getOrganizationList();
    }
  };
  // Customizable Area End
}
