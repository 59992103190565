import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  styled,
  Typography,
  Avatar,
  TextField,
  Divider,
  InputAdornment,
} from "@material-ui/core";
import OutGoingTransactionDetailsController, {
  Props,
} from "./OutGoingTransactionDetailsController.web";
import { colors } from "../../utilities/src/Colors";
import {
  arrowBack,
  completedIcon,
  pendingIcon,
  printIcon,
  storeIcon,
  uploadIcon,
} from "./assets";
import { WhiteContainer } from "./TransferAmount.web";
import { MoreHorizOutlined } from "@material-ui/icons";
export const configJSON = require("./config");
const Strings = configJSON.TransactionDetails.Strings;
import { renderBaseOnConditions } from "../../../blocks/utilities/src/CustomBlockHelpers";
import { handleMomentDateFormat, saudiTimeView } from "./utils";
// Customizable Area End

class OutGoingTransactionDetails extends OutGoingTransactionDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  getDetailsForCashier = () => {
    const details = this.state.transactionDetails?.attributes;
    let isCashier = details?.is_cashier;
    return (
      <>
        {isCashier ? (
          <>
            <AssignHeading>{Strings.store}</AssignHeading>
            <AssignValue>
              <img src={storeIcon} className='storeimg' />
              <span className='storetext'>
                {" "}
                {details?.transfer_to.store_name}{" "}
              </span>
            </AssignValue>
          </>
        ) : (
          <>
            <AssignHeading>{Strings.cashier}</AssignHeading>
            <AssignValue>
              <Avatar alt='Remy Sharp' className='avtartimg' />
              <span className='storetext'>
                {" "}
                {details?.received_by?.full_name}{" "}
              </span>
            </AssignValue>
          </>
        )}
      </>
    );
  };

  getDetailsForStorKeeper = () => {
    const details = this.state.transactionDetails?.attributes;
    let isCashier = details?.is_cashier;

    return (
      <>
        {!isCashier ? (
          <>
            <AssignHeading>Store</AssignHeading>
            <AssignValue>
              <img src={storeIcon} className='storeimg' />
              <span className='storetext'>
                {" "}
                {details?.transfer_from.store_name}{" "}
              </span>
            </AssignValue>
          </>
        ) : (
          <>
            <AssignHeading>{Strings.cashier}</AssignHeading>
            <AssignValue>
              <Avatar alt='Remy Sharp' className='avtartimg' />
              <span className='storetext'>
                {" "}
                {details?.received_by?.full_name}{" "}
              </span>
            </AssignValue>
          </>
        )}
      </>
    );
  };

  getAssignedSection = () => {
    const details = this.state.transactionDetails?.attributes;
    const pageType = this.props.navigation.getParam("pageType");
    return (
      <div>
        <Typography className='PageHeadingDetail'>Assigned To</Typography>
        <div className='assigndiv'>
          {pageType === "cashier" &&
            this.getDetailsForCashier()}
          {pageType === "cashup" &&
            this.getDetailsForStorKeeper()}
        </div>
        <div className='assigndiv'>
          <AssignHeading>Driver</AssignHeading>
          <AssignValue>
            <Avatar alt='Remy Sharp' />
            <div>
              <Typography className='profilename'>
                {details?.driver.first_name} {details?.driver.last_name}
              </Typography>
              <Typography className='profileemail'>
                {details?.driver?.email}
              </Typography>
            </div>
          </AssignValue>
        </div>
      </div>
    );
  };

  getDetailsSection = () => {
    const details = this.state.transactionDetails?.attributes;
    return (
      <ChildGridContainer>
        <div>
          <HeadingText>{Strings.transactionID}</HeadingText>
          <ValueText>{details?.transfer_id}</ValueText>
        </div>
        <div>
          <HeadingText>{Strings.created}</HeadingText>
          <ValueText>
            {handleMomentDateFormat(
              details?.created_at ? details?.created_at : "",
              "DD-MM-YYYY [at] hh:mm A"
            )}
          </ValueText>
        </div>

        <div>
          <HeadingText>{Strings.from}</HeadingText>
          <ValueText>
            {details?.transfer_from?.full_name ||
              details?.transfer_from.store_name}
          </ValueText>
        </div>

        <div>
          <HeadingText>{Strings.to}</HeadingText>
          <ValueText>
            {details?.transfer_to.store_name || details?.transfer_to?.name}
          </ValueText>
        </div>

        <div>
          <HeadingText>{Strings.assignedBy}</HeadingText>
          <ValueText>{details?.created_by?.full_name}</ValueText>
        </div>

        <div>
          <HeadingText>{Strings.amount}</HeadingText>
          <ValueText>SAR {details?.amount}</ValueText>
        </div>
      </ChildGridContainer>
    );
  };

  handleButtonRender = () => {
    const details = this.state.transactionDetails?.attributes;
    const isPending = details?.status === "pending";
    const { permissionStatus, isAmtEnable } = this.state;
    const isCashier = details?.["request_page"] === "cashier";
    const pageType = this.props.navigation.getParam("pageType");

    
    if (!isPending) return null;
    
    const canEdit = pageType !== "cashup" || isCashier;
    const canMarkAsDone = (pageType === "cashier" && !isCashier) ||
      (pageType === "cashup" && isCashier);

    return (
      <>
        {isAmtEnable ? (
          <Button
            onClick={() => this.handlEditAmt("edit")}
            data-test-id='confirm-btn'
            className='confirmBtn'
          >
            Confirm
          </Button>
        ) : (
          <>
            {canEdit && permissionStatus.CashReceivePermission && (
              <Button
                onClick={this.handleEditAmtEnable}
                data-test-id='btn-edit'
                className='editbtn'
              >
                Edit
              </Button>
            )}
            {canMarkAsDone && permissionStatus.CashReceivePermission && (
              <Button
                onClick={() => this.handlEditAmt("markasdone")}
                data-test-id='mark-as-done'
                className='markAsDone'
              >
                Mark As Done
              </Button>
            )}
          </>
        )}
      </>
    );
  };
  

  // Customizable Area End

  render() {
    // Customizable Area Start
    const details = this.state.transactionDetails?.attributes;
    const isPending = details?.status === "pending";
    // Customizable Area End

    return (
      // Customizable Area Start
      // 
      <>
        <StyledDetailWrapper>
          <Box className='mainconatiner'>
            <Box className='headerdetailWrapper'>
              <h2 className='PageHeadingDetail'>
                <PendingDiv>
                  <img
                    className='backarrow'
                    onClick={this.handleBack}
                    data-test-id='back-btn'
                    src={arrowBack}
                  />{" "}
                  {this.getTitleAsPerRole()}
                </PendingDiv>
              </h2>
              <Box className='d-flex-details'>
                <Box
                  className={this.state.isAmtEnable ? "valuebox" : "disablebox"}
                >
                  <StyledTextField
                    variant='outlined'
                    inputProps={{
                      "data-test-id": "confirm-amount-input",
                    }}
                    disabled={this.state.isAmtEnable ? false : true}
                    value={this.state.editConfirmAmt}
                    onChange={this.handleAmountChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <SARTypography>SAR</SARTypography>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                {
                  renderBaseOnConditions(
                    this.state.transactionDetails?.attributes?.request_page === "cashier",
                    null,
                    <img
                      alt={"print"}
                      src={printIcon}
                      className={this.printIconClasses()}
                      data-test-id='btn-print'
                      onClick={() => this.handlePrintInvoice()}
                    />
                  ) 
                }
                {this.handleButtonRender()}
              </Box>
            </Box>
            <Box>
              <WhiteContainer>
                <PendingDiv>
                  <img src={isPending ? pendingIcon : completedIcon} />
                  <span
                    className={isPending ? "statustext" : "completedstatus"}
                  >
                    {details?.status}
                  </span>
                </PendingDiv>
                <MainGridContainer>
                  {this.getDetailsSection()}
                  <Divider orientation='vertical' variant='middle' flexItem />
                  {this.getAssignedSection()}
                </MainGridContainer>
              </WhiteContainer>
              <GridContainer>
                <div>
                  <StyledTextArea
                    data-test-id = "Note_text"
                    minRows={5}
                    multiline
                    fullWidth
                    placeholder='Type your Note here..'
                    value={this.state.comment}
                    inputProps={{
                      "data-test-id": "field-description",
                    }}
                    onChange={this.handleGetNote}
                    disabled={this.state.transactionDetails?.attributes.status==="completed"?true:false}
                    />
                  {this.state.notesError.comment&&<ErrorText>{this.state.notesError.comment}</ErrorText>}
                  <div className='uploadimgdiv'>
                    <input ref={this.fileInputRef} data-test-id="file_upload" type="file" onChange={this.imageChangeHandler} style={{ display: 'none' }}/>
                    <Button
                      id='confAmountSubmitBtn'
                      data-test-id = "upload_image_btn"
                      className='uploadimg'
                      variant='outlined'
                      endIcon={
                        <>
                          <img src={uploadIcon} />
                        </>
                      }
                      onClick={() => { this.fileInputRef?.current && this.fileInputRef?.current?.click() }}
                    >
                      {this.state.attachmentFile?.name?this.state.attachmentFile.name:"Upload Attachment"}
                    </Button>

                    <Button
                      id='confAmountSubmitBtn'
                      className='confAmountSubmitBtn'
                      data-test-id='confirm-amount-submit'
                      onClick={this.handleCreateNote}
                      disabled={this.state.transactionDetails?.attributes.status==="completed"?true:false}
                    >
                      Submit
                    </Button>
                  </div>
                </div>

                <WhiteContainerActivity>
                  <div className='mainActivity'>
                    <Typography className='activitytext'>
                      Activity log & Notes
                    </Typography>
                    <MoreHorizOutlined />
                  </div>
                  <Divider className='maindivider' />
                  <div className='activityLog' data-test-id = "Activity_Post" onScroll={this.handleScroll}>
                    <Typography className='todaytext'>Today</Typography>
                    {this.state.activeLogAndNoted.map((data) => {
                      return <div className='profiledetails'>
                        <Avatar src={data.attributes.account.employee_profile_pic} alt='Remy Sharp' className='avtartimg' />
                        <div>
                          <Typography className='profilelog'>
                            <span className='profilename'> {data.attributes.account.full_name} </span>{" "}
                            <span className='dot'> • </span>{" "}
                            <span className='timelog'>
                              {saudiTimeView(
                                data.attributes?.created_at
                              )}</span>
                          </Typography>
                          <div className='transferdetails'>
                            
                              {
                                data.attributes.comment_type==="notes"
                                ?
                                <Typography className='transferdetailstext'> <Span>Note:{" "}</Span>{data.attributes.comment}</Typography>
                                :
                                <Box>
                                  <Typography className='transferdetailstext'>{this.handleGetComment(data.attributes.comment)[0]}</Typography>
                                  <Typography className='transferdetailstext'>{this.handleGetComment(data.attributes.comment)[1]}</Typography>
                                </Box>

                              }
                              
                            
                          </div>
                        </div>
                      </div>
                    }
                    )
                    }
                  </div>
                </WhiteContainerActivity>
              </GridContainer>
            </Box>
          </Box>
        </StyledDetailWrapper>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const StyledDetailWrapper = styled(Box)({
  "& .mainconatiner": {
    paddingBottom: "30px",
  },
  "& .backarrow": {
    cursor: "pointer",
  },
  "& .headerdetailWrapper": {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginBottom: 12,
    alignItems: "center",
  },
  "& .d-flex-details": {
    display: "flex",
    alignItems: "center",
  },
  "& .PageHeadingDetail": {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: colors().darkjunglegreen,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
    },
  },
  "& .assigndiv": {
    marginTop: "20px",
  },
  "& .markAsDone": {
    textTransform: "unset",
    fontFamily: "Montserrat",
    background: colors().cyancobaltblue,
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: colors().white,
    height: "44px",
    width: "143px",
    borderRadius: "8px",
    "&:hover": {
      background: colors().cyancobaltblue,
      color: colors().white,
    },
    "@media only screen and (max-width: 1024px)": {
      height: 44,
    },
  },
  "& .confirmBtn": {
    textTransform: "unset",
    fontFamily: "Montserrat",
    background: colors().cyancobaltblue,
    fontWeight: 600,
    marginRight: 10,
    fontSize: "16px",
    lineHeight: "24px",
    color: colors().white,
    height: "44px",
    width: "143px",
    borderRadius: "8px",
    "&:hover": {
      background: colors().cyancobaltblue,
      color: colors().white,
    },
    "@media only screen and (max-width: 1024px)": {
      height: 44,
    },
  },
  "& .valuebox": {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    marginRight: "24px",
  },
  "& .disablebox": {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    marginRight: "24px",
    opacity: "30%",
  },
  "& .print-icon": {
    width: "33px",
    height: "33px",
    marginRight: "24px",
    cursor: "pointer",
    "& .print-disabled": {
      opacity: 0.5
    }
  },
  "& .profilename": {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "25px",
    color: colors().black,
  },
  "& .dot": {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "11px",
    lineHeight: "13px",
    color: colors().darkliver,
  },
  "& .editBtn": {
    width: "108px",
    height: "44px",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    borderRadius: "8px",
    marginRight: 10,
    textTransform: "unset",
    backgroundColor: colors().brightgray,
    color: colors().darkliver,
  },
  "& .timelog": {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "11px",
    lineHeight: "13px",
    color: colors().darkliver,
  },
  "& .profileemail": {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "13px",
    lineHeight: "25px",
    color: colors().darkliver,
  },
  "& .confAmountSubmitBtn": {
    width: "108px",
    height: "44px",
    padding: "10px 16px 10px 16px",
    borderRadius: "8px",
    background: colors().cyancobaltblue,
    color: colors().background,
    textTransform: "none",
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "16px",
  },
  "& .uploadimgdiv": {
    marginTop: "20px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  "& .uploadimg": {
    borderStyle: "dashed solid",
    border: "1px solid",
    borderColor: colors().lightborder,
    width: "216px",
    height: "44px",
    textTransform: "none",
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "14px",
    display:"none"
  },
  "& .activitytext": {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "19px",
    lineHeight: "22px",
    color: colors().black,
  },
  "& .mainActivity": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  "& .maindivider": {
    marginTop: "30px",
    backgroundColor: colors().dividerColor,
  },
  "& .activityLog": {
    marginTop: "15px",
    maxHeight:"300px",
    overflow:"scroll"
  },
  "& .todaytext": {
    textAlign: "center",
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: colors().black,
  },
  "& .profiledetails": {
    display: "flex",
    alignItem: "center",
    gap: "18px",
    marginBottom: "20px"
  },
  "& .avtartimg": {
    width: "44px",
    height: "44px",
  },
  "& .transferdetails": {
    marginTop: "12px",
  },
  "& .transferdetailstext": {
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: colors().black,
  },
  "& .storetext": {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "13px",
    lineHeight: "15px",
    color: colors().black,
  },
});

const SARTypography = styled("span")({
  fontFamily: "Montserrat",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "24px",
  color: colors().darkjunglegreen,
});

const ValueTypography = styled("span")({
  fontFamily: "Montserrat",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  color: colors().darkjunglegreen,
});

const MainGridContainer = styled("div")({
  gridGap: 32,
  gridTemplateColumns: "1fr 0fr 1fr",
  marginTop: "30px",
  display: "grid",
});

const GridContainer = styled("div")({
  gridGap: 32,
  gridTemplateColumns: "1fr 1fr",
  marginTop: "30px",
  display: "grid",
});

const ChildGridContainer = styled("div")({
  gridGap: 32,
  gridTemplateColumns: "1fr 1fr",
  display: "grid",
});

const PendingDiv = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  "& .statustext": {
    color: colors().pendingColor,
    fontWeight: 600,
    fontSize: 16,
    lineHeight: "22px",
  },
  "& .completedstatus": {
    color: colors().cyancobaltblue,
    fontWeight: 600,
    fontSize: 16,
    lineHeight: "22px",
  },
});

const HeadingText = styled("div")({
  fontWeight: 400,
  fontSize: 14,
  lineHeight: "22px",
  paddingBottom: 12,
  color: colors().darkliver,
  "@media only screen and (max-width: 1024px)": {
    fontSize: 12,
  },
});

const ValueText = styled("div")({
  fontWeight: 500,
  fontSize: 16,
  lineHeight: "22px",
  color: colors().darkjunglegreen,
  "@media only screen and (max-width: 1024px)": {
    fontSize: 14,
  },
});

const AssignHeading = styled(Typography)({
  fontWeight: 600,
  fontSize: 13,
  lineHeight: "15px",
  color: colors().black,
  "@media only screen and (max-width: 1024px)": {
    fontSize: 12,
  },
});

const AssignValue = styled(Typography)({
  fontWeight: 500,
  fontSize: 13,
  lineHeight: "15px",
  color: colors().black,
  display: "flex",
  alignItems: "center",
  gap: "12px",
  marginTop: "18px",
  "@media only screen and (max-width: 1024px)": {
    fontSize: 12,
  },
  "& .storeimg": {
    backgroundColor: colors().cyancobaltblue,
    width: "44px",
    borderRadius: "20px",
    padding: "8px",
    height: "44px",
  },
});

const StyledTextArea = styled(TextField)({
  marginTop: 32,
  borderRadius: 8,
  "& .MuiInput-underline::before, & .MuiInput-underline::after": {
    border: "none !important",
  },
  "& .MuiInput-multiline": {
    padding: 8,
    borderRadius: 8,
    border: "1px solid var(--border, #ECECEC)",
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.87)",
  },
});

export const WhiteContainerActivity = styled("div")({
  // paddingBottom: "20px",
  borderRadius: 12,
  padding: "24px 32px",
  background: "#FFF",
  boxShadow:
    "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
});

const StyledTextField = styled(TextField)({
  borderRadius: 8,
  border: "1px solid var(--border, #ECECEC)",
  width: "200px",
  "& .MuiInputBase-input": {
    padding: "12px 14px",
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "22px",
    color: colors().darkjunglegreen,
  },
  "@media only screen and (max-width: 1024px)": {
    "& .MuiInputBase-input": {
      fontSize: "12px",
      padding: "12px 14px",
    },
  },
});
const ErrorText = styled("p")({
  fontSize:"12px",
  marginTop:"5px",
  color:colors().error,
})
const Span = styled("span")({
  color:"#2686D5",
  fontWeight:600
})

export default OutGoingTransactionDetails;
// Customizable Area End
