import React, { useState } from 'react';
import { toString } from 'lodash';
import { Select, styled, Box, TextField } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import {
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/moment";
import { colors } from '../../blocks/utilities/src/Colors';
import moment, { Moment } from "moment";
import { clockIcon } from './commonAssets/assets';

type Props = {
    timeRange: {
        from: string
        to: string
    }
    onChangeTimeRange: (fromTime: string, toTime: string) => void
}

const CustomTimeRangePicker: React.FC<Props> = (props) => {
    const { timeRange, onChangeTimeRange } = props
    const [open, setOpen] = useState(false)

    const handleFromTimeChange = (time: string) => {
        updateTimeRange(time, timeRange.to);
    };

    const handleToTimeChange = (time: string) => {
        updateTimeRange(timeRange.from, time);
    };

    const updateTimeRange = (fromTime: string, toTime: string) => {
        onChangeTimeRange(fromTime, toTime)
    }

    const formatTime = (inputTime: string) => {
        if (!inputTime) return null;
        return moment(inputTime, "HH:mm").format("hh:mm A");
    }

    return (
        <StyledSelect
            variant="outlined"
            data-test-id="custom-time-range-picker"
            displayEmpty
            fullWidth
            onClick={() => {
                if (!open) setOpen(true)
            }}
            renderValue={() => timeRange.from && timeRange.to ? `${formatTime(timeRange.from)} to ${formatTime(timeRange.to)}` : <span className='empty-value'>Select Time</span>}
            placeholder='Select Time'
            IconComponent={() => <img src={clockIcon} />}
            MenuProps={{
                open,
                onClose: () => setOpen(false),
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                },
                PaperProps: {
                    style: {
                        minWidth: 250,
                        marginTop: 12,
                        boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
                        borderRadius: 8,
                        padding: 0,
                    }
                },
                getContentAnchorEl: null,
            }}
        >
            <StyledWrapper>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Box className="time-field">
                        <p>From</p>
                        <StyledTimeInput
                            id="time"
                            type="time"
                            onChange={(event) => {
                                handleFromTimeChange(toString(event.target.value))
                            }}
                            value={timeRange.from || ""}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Box>
                    <Box className="time-field">
                        <p>To</p>
                        <StyledTimeInput
                            id="time"
                            type="time"
                            onChange={(event) => {
                                handleToTimeChange(toString(event.target.value))
                            }}
                            value={timeRange.to || ""}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Box>
                    <ClearIcon data-test-id="clear-time-range" className="clear-icon" onClick={() => updateTimeRange("", "")} />
                </MuiPickersUtilsProvider>
            </StyledWrapper>
        </StyledSelect>
    )
}

const StyledSelect = styled(Select)({
    marginTop: 6,
    paddingRight: 16,
    "& .MuiSelect-select": {
        fontFamily: '"Montserrat", sans-serif',
        fontSize: 16,
        fontWeight: 500,
        textAlign: "left",
        lineHeight: "24px",
        borderRadius: 8,
        background: "white",
        paddingTop: 16,
        paddingBottom: 16,
        "@media only screen and (max-width: 1280px)": {
            fontSize: 14,
        },
        "@media only screen and (max-width: 1024px)": {
            fontSize: 12,
        }
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: `${colors().lightborder} !important`,
        borderRadius: 8,
    },
    "& .empty-value": {
        color: colors().darkliver,
        fontSize: 14,
        fontWeight: 400,
    }
});

const StyledWrapper = styled(Box)({
    padding: 12,
    display: "flex",
    flexWrap: "wrap",
    gap: 16,
    "& .time-field": {
        display: "flex",
        alignItems: "center",
        gap: 10
    },
    "& .clear-icon": {
        alignSelf: "center",
        cursor: "pointer"
    }
})

const StyledTextField = styled(TextField)({
    maxWidth: 150,
    "& .MuiInputBase-root": {
        borderRadius: 8,
        fontFamily: "Montserrat",
        fontWeight: 500,
        fontSize: 16,
        lineHeight: 1.4,
        "& .MuiInputBase-input": {
            padding: "10px 16px",
            height: 24,
        }
    }
})

const StyledTimeInput = styled(TextField)({
    width: "100%",
    "& .MuiInputBase-input": {
        borderRadius: 8,
        minHeight: "1.1876em",
        padding: "17.5px 14px",
        border: "1px solid var(--border, #ECECEC)",
        boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.87)",
        "&:hover": {
            border: "1px solid rgba(0, 0, 0, 0.87)",
        },
    },
    "& .MuiInput-underline::before, & .MuiInput-underline::after": {
        border: "none !important",
    },
    "@media only screen and (max-width: 1024px)": {
        '& .MuiInputBase-input': {
            fontSize: '12px',
            padding: '14.9px 14px'
        },
    },
});

export default CustomTimeRangePicker;