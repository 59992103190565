Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfcontractmanagement7";
exports.labelBodyText = "cfcontractmanagement7 Body";
exports.httpGetMethod = "GET";
exports.httpPutMethod = "PUT";

exports.btnExampleTitle = "CLICK ME";
exports.noDataMsg = "No Option Found";

exports.strings = {
  b2bBussinessListString: {
    headingTitle: "Business Accounts",
    exportimport: "Export/Import",
    addAccount: "Add Account",
    tabelString_customerName: "Customer Name",
    tabelString_subCompanyName: "Sub-Company",
    tabelString_email: "Email",
    tabelString_phoneNo: "Phone No.",
    tabelString_companyName: "Company Name",
    tabelString_catalogue: "Catalogue",
    tabelString_startDate: "Start Date",
    tabelString_endDate: "End Date",
    tabelString_status: "Status",
    tabelString_noAccountDataFound: "No Account Data Found",
    tabelString_viewStatus: "View Status",
    noPermissions: "No Permissions",
    view: "View",
    edit: "Edit",
    delete: "Delete",
    renew: "Renew",
    exportimportText: "Export/Import",
  },
};

exports.apiEndPoints = {
  businessAccount: "bx_block_cfcontractmanagement7/business_accounts",
  b2bCompaniesList : "bx_block_company/companies?dropdown=true&type=b2b_company",
  b2bSubCompaniesList: "bx_block_company/companies?dropdown=true&type=b2b_company&company_type=Sub-Company&filter_by[parent_company_id]=",
  contractManagerList: "bx_block_cfcontractmanagement7/business_accounts/contract_managers",
  regionList: "bx_block_regions/regions?dropdown=true",
  areaList: "bx_block_regions/areas?dropdown=true&region_ids=",
  b2bStoresList: "bx_block_store_management/store_managements?dropdown=true&allow_access=true&filter_by[enabled_b2b_order]=true&area_ids=",
  priceList: "bx_block_cfpricelist/b2b_price_lists?dropdown=true&company_id=",
  b2bCustomerGroups: "bx_block_account_groups/business_customer_groups?dropdown=true&company_id=",
  productsList: "bx_block_catalogue/product_masters?dropdown=true&price_list_id=",
  businessAccountFilterSuggestion: "bx_block_cfcontractmanagement7/business_accounts/business_account_suggestion"
}

exports.b2bBusinessAccountFilterStorageKey = "b2b_business_account_filter_value"
exports.renewFormTest = "renew-business-account-form"
// Customizable Area End