import qzTray, { EventData } from "qz-tray";
import { isEqual, sortBy, throttle } from "lodash";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { makeApiMessage } from "../common";
import { CustomEnums, getCustomEnumName } from "../../../blocks/utilities/src/CustomBlockHelpers";

const configJSON = require("./config");

const processingFiles = new Set<string>(); 

class FileUpdater {
    static instance: FileUpdater;
    listeningPaths: string[] = [];
    filesToUpdate: Array<{ file_name: string; file_content: string }> = [];
    updateFileCalls: Array<{ messageId: string; files: string[] }> = [];
    reconnectTimer: ReturnType<typeof setTimeout> | undefined = undefined;

    constructor() {
        if (FileUpdater.instance) {
            return FileUpdater.instance;
        }
        this.listeningPaths = [];
        this.filesToUpdate = [];
        this.updateFileCalls = [];
        FileUpdater.instance = this;
    }

    startListening = async (inputPaths: string[]) => {
        runEngine.debugLog("LISTENING LISTS ", {oldList: this.listeningPaths, newList: inputPaths})
        if (isEqual(sortBy(inputPaths), sortBy(this.listeningPaths))) return;
        try {
            await qzTray.file.stopListening()
            qzTray.file.setFileCallbacks(async (eventData: EventData) => {
                runEngine.debugLog("FILE EVENT ", eventData)
                const receivedData = eventData as { file: string, eventType: string, type: string }
                const { eventType, file } = receivedData
                if (eventType === "ENTRY_MODIFY") {
                    const fileName = file.substring(file.lastIndexOf('\\') + 1)
                    if (processingFiles.has(fileName)) return;
                    try {
                        processingFiles.add(fileName)
                        let content = ""
                        if (file.endsWith(".out")) {
                            content = await qzTray.file.read(file, { sandbox: false, shared: true })
                        }
                        this.updateFileList(fileName, content)
                        this.throttledUpdateOutputFile()
                    } catch (error) {
                        console.error(error)
                    } finally {
                        processingFiles.delete(fileName)
                    }
                }
            })
            const results = await Promise.all(inputPaths.map(inputPath => this.startListeningPath(inputPath)))
            this.listeningPaths = results.filter(Boolean)
        } catch (error) {
            console.error("ERROR START LISTENING", error)
            this.listeningPaths = []
        } finally {
            runEngine.debugLog("LISTENING TO ", this.listeningPaths.join(", "))
        }
    }

    startListeningPath = async (path: string) => {
        try {
            await qzTray.file.startListening(path, { shared: true, sandbox: false, include: ["*.out"] })
            return path
        } catch (error) {
            console.error(`ERROR LISTENING TO: ${path}`,error)
            return ""
        }
    }

    stopListening = async () => {
        this.listeningPaths = []
        if (qzTray.websocket.isActive()) {
            await qzTray.file.stopListening()
        }
    }

    retryToConnect = () => {
        clearTimeout(this.reconnectTimer)
        this.reconnectTimer = setTimeout(() => {
            this.reconnect()
        }, 1000)
    }

    reconnect = async () => {
        try {
            await qzTray.websocket.connect()
            const results = await Promise.all(this.listeningPaths.map(inputPath => this.startListeningPath(inputPath)))
            this.listeningPaths = results.filter(Boolean)
        } catch (error) {
            console.error("ERROR WHILE TRYING TO RECONNECT",error)
        }
    }

    updateFileList = (file_name: string, file_content: string) => {
        runEngine.debugLog("UPDATE FILES LIST ", { file_name, file_content, filesToUpdate: this.filesToUpdate })
        const currentIndex = this.filesToUpdate.findIndex(item => item.file_name === file_name);
        if (currentIndex > -1) {
            this.filesToUpdate[currentIndex].file_content = file_content;
        } else {
            this.filesToUpdate.push({ file_name, file_content });
        }
    };

    throttledUpdateOutputFile = throttle(() => {
        this.updateOutputFile();
    }, 2000, { leading: false, trailing: true });

    updateOutputFile = () => {
        const apiMessage = makeApiMessage({
            url: configJSON.updateOutFileApi,
            method: "POST",
            body: JSON.stringify({
                data: this.filesToUpdate.filter(fileItem => fileItem.file_name.endsWith(".out")),
                in_file_names: this.filesToUpdate.filter(fileItem => fileItem.file_name.endsWith(".in"))
                    .map(item => item.file_name)
            }),
        })
        this.updateFileCalls.push({ messageId: apiMessage.messageId, files: this.filesToUpdate.map(item => item.file_name) })
        runEngine.sendMessage("Metal Progetti", apiMessage)
    }

    receiveApiResponse = (apiCallId: string) => {
        runEngine.debugLog("RECEIVED CALL ID ", { apiCallId, calls: this.updateFileCalls })
        const updateFileCallIndex = this.updateFileCalls.findIndex(apiCall => apiCall.messageId === apiCallId)
        if (updateFileCallIndex > -1) {
            const updatedFiles = this.updateFileCalls[updateFileCallIndex].files
            this.filesToUpdate = this.filesToUpdate.filter(item => !updatedFiles.includes(item.file_name))
            this.updateFileCalls.splice(updateFileCallIndex, 1);
            this.updateMetalProgetti()
        }
    }

    updateMetalProgetti = () => {
        const message = new Message(getCustomEnumName(CustomEnums.CustomActionReducers))
        message.addData(getCustomEnumName(CustomEnums.CustomReducerAction), "UPDATE_METALPROGETTI")
        runEngine.sendMessage("Metal Progetti", message)
    }

    static getInstance() {
        if (!FileUpdater.instance) {
            FileUpdater.instance = new FileUpdater();
        }
        return FileUpdater.instance;
    }
}

export default FileUpdater;