import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { ISortingData } from "../../../components/src/SortingTableHeader";
import { IFilter } from "../../../components/src/FilterPopover";

// Customizable Area Start
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  getStorageData,
} from "framework/src/Utilities";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  checkIsFilterApplied,
  customPermissionApiKey,
  navigateTo,
  debounce
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import {  PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
import {getFiterList, makeApiMessage } from "../../../components/src/common";

export interface ICountry {
  id: number;
  name: string;
}

const emptyB2BCompany: IB2BCompany = {
    id: "",
    type: "",
    attributes: {
        id: 0,
        name: "",
        name_arabic: "",
        registration_no: "",
        tin_no: "",
        created_at: "",
        updated_at: "",
        email: "",
        address: "",
        others: {},
        country_id: 0,
        number_of_vats: "",
        vat_number: "",
        vat_number_arabic: "",
        telephone: "",
        activated: false,
        vat_details_attributes: [],
        country: {
            id: 0,
            name: "",
        },
        sub_company_type: {
            id: 0,
            value: "",
        },
        parent_company_id: null,
        company_type: null,
        parent_company: null,
        sub_companies: null,
    },
};

interface B2BCompanyData {
    data: IB2BCompany[];
    meta: MetaData;
}

export interface IB2BCompany {
    id: string;
    type: string;
    attributes: IAttributes;
}

interface IAttributes {
    id: number;
    name: string;
    name_arabic: string;
    registration_no: string;
    tin_no: string;
    created_at: string;
    updated_at: string;
    email: string;
    address: string;
    others: Record<string, any>;
    country_id: number;
    number_of_vats: string;
    vat_number: string;
    vat_number_arabic: string;
    telephone: string;
    activated: boolean;
    vat_details_attributes: VATDetails[];
    country: Country;
    sub_company_type: SubCompanyType;
    parent_company_id: number | null;
    company_type: string | null;
    parent_company: string | null;
    sub_companies: string | null;
}

interface VATDetails {
    id: number;
    company_id: number;
    created_at: string;
    updated_at: string;
    default_tax: boolean;
    tax_calculator_id: number;
    tax_calculator_name: string;
    tax_calculator_rate: string;
}

interface Country {
    id: number;
    name: string;
}

interface SubCompanyType {
    id: number;
    value: string;
}

interface MetaData {
    total_pages: number;
    total_count: number;
    current_page: number;
    next_page: number | null;
    pervious_page: number | null;
}

export interface ISubCompantAttribute  {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    name_arabic: string;
    registration_no: string;
    tin_no: string;
    created_at: string;
    updated_at: string;
    email: string;
    address: string;
    others: Record<string, unknown>;
    country_id: number;
    number_of_vats: string;
    vat_number: string;
    vat_number_arabic: string;
    telephone: string;
    activated: boolean;
    vat_details_attributes: Array<{
      id: number;
      company_id: number;
      created_at: string;
      updated_at: string;
      default_tax: boolean;
      tax_calculator_id: number;
      tax_calculator_name: string;
      tax_calculator_rate: string;
    }>;
    country: {
      id: number;
      name: string;
    };
    sub_company_type: {
      id: number;
      value: string;
    };
    parent_company_id: number;
    company_type: string | null;
    parent_company: {
      data: {
        id: string;
        type: string;
        attributes: {
          id: number;
          name: string;
          number_of_vats: string;
        };
      };
    };
    sub_companies: null;
  };
}

interface ISubCompanyData {
  data: ISubCompantAttribute[],  
  meta: {
    total_pages: number;
    total_count: number;
    current_page: number;
    next_page: number | null;
    pervious_page: number | null;
  };
}



// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: {
    history:{push: Function}
  };
  id: string;
}
interface ExpandedRows {
  [key: string]: ISubCompantAttribute[] | undefined
}

export interface S {
  // Customizable Area Start
  drawerWidth: number;
  popOverOpened: boolean;
  popOverItemId: string;
  popOverItemStatus: string;
  popOverTop: number;
  popOverLeft: number;
  hoveredButton: string;
  companies: IB2BCompany[];
  subcompanies: ISubCompantAttribute[];
  countries: ICountry[];
  page: number;
  pageSize: number;
  subPageSize: number;
  subPage: number;
  isB2BCompany: boolean;
  sortingData: ISortingData;
  query: string;
  filterAnchor: HTMLDivElement | undefined;
  filters: IFilter[];
  searchTextCompany: string;
  suggestionFieldTitle: string;
  isSuperAdmin?: boolean;
  popOverItemTitle: string;
  permissionStatus: PermissionStatus;
  isCompanyListLoading: boolean;
  isLoadingPermission: boolean;
  isAppliedFilter: boolean;
  companyListPagination: MetaData;
  subCompanyListPagination: MetaData;
  expandedRow: number | null
  rowData: IB2BCompany
  expandedRows: ExpandedRows
  companyId: string
  isSubCompanyListLoading: boolean
  isBusAccModalOpen: boolean
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class B2BCompanytListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCompaniesCallId: string = "";
  getSubCompaniesCallId: string = "";
  getCountriesCallId: string = "";
  filterSuggestionApiCallId: string = "";
  tableRefs: HTMLElement | null = null;

  handleMoreMenu = (item: IB2BCompany, position: DOMRect) => {
    this.setState({
      popOverOpened: true,
      popOverItemId: item.id,
      popOverItemStatus: String(item.attributes.activated),
      popOverLeft: position.left + window.scrollX,
      popOverTop: position.top + window.scrollY,
      rowData: item
    });
  }

  receiveCountry(from: string, message: Message) {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.setState({ countries: responseJson.data });
  }

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.AccountTypeInfoMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SearchTextMessage),
      getName(MessageEnum.LayoutDataMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      popOverOpened: false,
      rowData: emptyB2BCompany,
      hoveredButton: "",
      popOverTop: 0,
      companies: [],
      subcompanies: [],
      page: 0,
      popOverItemStatus: "",
      popOverItemTitle: "",
      isB2BCompany: true,
      drawerWidth: 0,
      pageSize: 10,
      subPageSize: 10,
      subPage: 0,
      popOverItemId: "",
      popOverLeft: 0,
      countries: [],
      sortingData: {
        name: "",
        registration_no:"",
        email: "",
        telephone: "",
        country_name: "",
      },
      query: "",
      searchTextCompany: "",
      suggestionFieldTitle: "",
      filters: [
        {
          title: "Company Name",
          type: "autocompolete",
          apiKey: "company_name",
          value: "",
          options: [],
        },
        {
          title: "Register No.",
          type: "autocompolete",
          apiKey: "reg_no",
          value: "",
          options: [],
        }
      ],
      filterAnchor: undefined,
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      },
      isCompanyListLoading: false,
      isLoadingPermission: true,
      isAppliedFilter: false,
      companyListPagination: {
        total_pages: 0,
        total_count: 0,
        current_page: 0,
        next_page: null,
        pervious_page: null
      },
      subCompanyListPagination: {
        total_pages: 0,
        total_count: 0,
        current_page: 0,
        next_page: null,
        pervious_page: null
      },
      expandedRow: null,
      expandedRows: {},
      isSubCompanyListLoading: false,
      companyId: '',
      isBusAccModalOpen: false
    };
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getCompaniesCallId) {
        if (responseJson && responseJson.data) {
            let companies = responseJson.data as IB2BCompany[];
            this.setState({ companies,companyListPagination: responseJson.meta });
        }
        this.setState({
          isCompanyListLoading: false
        })
      } else if (apiRequestCallId === this.getCountriesCallId) {
        this.receiveCountry(from, message);
      }else if (apiRequestCallId === this.filterSuggestionApiCallId) {
        this.handleFilterSuggestionApiResponse(responseJson);
      }
      this.subCompanyReponse(apiRequestCallId,responseJson)
    }
    this.captureDataFromLayout(message)
    this.captureSearchTextData(message)
    // Customizable Area End
  }

  // Customizable Area Start

  captureDataFromLayout = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
      const recievedData = message.getData(
        getName(MessageEnum.LayoutMessageData)
      );
      if (recievedData.userContext) {
        this.handleUserChange(recievedData.userContext)
      }
    }
  }
  
  captureSearchTextData = (message: Message) => {
    if (message.id === getName(MessageEnum.SearchTextMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.SearchMessageText)
        );
        if (recievedData) {
          this.handleSearchCompany(recievedData.searchText)
        }
    }
  }

  createData(
    name: string,
    email: string,
    phone: string,
    status: string,
    country: string,
    id: string
  ) {
    return { name, email, phone, country, status, id };
  }

  getCompanies = () => {
    this.setState({
      isCompanyListLoading: true
    })
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem(configJSON.tokenKey),
    };
    this.getCompaniesCallId = getDataMsg.messageId;

    const nameFilter = this.state.filters.find(
      (item) => item.title === "Company Name"
    )?.value;

    const regNoFilter = this.state.filters.find(
      (item) => item.title === "Register No."
    )?.value;


    const searchTextCompany = this.state.searchTextCompany;
    const soritngQuery = this.state.query ?  `&${this.state.query}`: ''

    let url =
      configJSON.companyEndPoint +
      "?page_no=" +
      (this.state.page + 1) +
      (nameFilter ? `&filter_by[name]=${nameFilter}` : "") +
      (regNoFilter ? `&filter_by[registration_no]=${regNoFilter}` : "") +
      (searchTextCompany ? `&filter_by[query]=${searchTextCompany}` : "") +
      soritngQuery + `&type=b2b_company`

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodType
    );
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  getSubCompanyList = (id:string) => {
    this.setState({
      isSubCompanyListLoading: true
    })
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem(configJSON.tokenKey),
    };

    this.getSubCompaniesCallId = getDataMsg.messageId;

    let url =
      configJSON.companyEndPoint +
      "?page_no=" + (this.state.subPage + 1) 
      + `&type=b2b_company`
      + `&company_type=Sub-Company`
      + `&filter_by[parent_company_id]=${id}`

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodType
    );
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }

  subCompanyReponse = (apiRequestCallId: string,responseJson:ISubCompanyData) => {
    if (apiRequestCallId === this.getSubCompaniesCallId) {
      if (responseJson && responseJson.data) {
          let subcompanies = responseJson.data;
          this.setState({ 
            subcompanies,
            subCompanyListPagination: responseJson.meta,
            isSubCompanyListLoading:false,
            expandedRows: {[this.state.companyId]: subcompanies }
          });
      }
      this.setState({
        isSubCompanyListLoading: false
      })
    } 
  }

  getCountriesForCompany = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem(configJSON.tokenKey),
    };
    
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.countryEndPoint + "?dropdown=true"
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.countryApiMethodType
    );
    this.getCountriesCallId = getDataMsg.messageId;
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getCountriesForCompany();
    this.handleStorageFilter();
    // Customizable Area End
  }


  handleStorageFilter = () => {
    const applied_b2b_company_filter = window.localStorage.getItem(
      "b2b_company_filter"
    );
    if (applied_b2b_company_filter) {
      this.setState(
        {
          filters: JSON.parse(applied_b2b_company_filter),
          isAppliedFilter: checkIsFilterApplied(
            JSON.parse(applied_b2b_company_filter)
          ),
        },
        () => this.getCompanies()
      );
    } else {
      this.getCompanies();
    }
  };

  handlePageChange = (page: number) => {
    this.setState({ page }, () => this.getCompanies());
  };

  handleSubPageChange = (page: number) => {
    this.setState({ subPage:page }, () => this.getSubCompanyList(String(this.state.companyId)));
  };

  handleSearchCompany = (value: string) => {
    this.setState(
      {
        page: 0,
        searchTextCompany: value,
      },
      this.getCompanies
    );
  };

  handleAddCompany = () => {
    navigateTo({
      props: this.props,
      screenName: "CompanyCreation",
    })
  };
 
  handleQueryChange = (query: string) => {
    this.setState({ query }, () => this.getCompanies());
  };

  getSortingProps() {
    return {
      sortingData: this.state.sortingData,
      onQueryChange: (query: string) => this.handleQueryChange(`${query}`),
      onChange: (sortingData: ISortingData) => this.setState({ sortingData }),
    };
  }

  
  handleReturnColorType = () => {
    const { isAppliedFilter } = this.state;
    return isAppliedFilter ? "primary" : "inherit";
  };
  
  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.company;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value,
      isLoadingPermission: false
    })
  };

  handlePopoverMenuClose = () => {
    this.setState({ popOverOpened: false })
  }

  getTypeOfOrderTable = (rowIndex: number) => {
    if (rowIndex === 0) {
      return "left";
    } else if (rowIndex === 5) {
      return "right";
    } else {
      return "middle";
    }
  };

  toggleRow = async (id: string) => {
    const { expandedRows } = this.state;
    const isExpanded = expandedRows[id];
    if (!isExpanded) {
      this.setState({
        subPage:0, companyId:id, expandedRows: { [id]: [] }
      },()=> this.getSubCompanyList(id))
    } else {
      this.setState({
        subPage:0,
        expandedRows: { [id]: undefined },
      });
    }
  };

  handleEditCompany = (companyId: string) => {
    this.props.navigation.history.push("Settings-General-CompanyListEdit?companyId=" + companyId);
    window.localStorage.setItem("companyId", companyId);
  };

  handleOpenBusinessAccModal = (id:string) => {
    this.setState({popOverOpened:false,isBusAccModalOpen:true,companyId:id})
  }

  closeBusinessAccModal = () => {
    this.setState({isBusAccModalOpen:false,companyId:""})

  }

  handleViewCompany = (companyId: string) => {
    this.setState({ popOverOpened: false });
    this.props.navigation.history.push("Settings-General-CompanyListView/?companyId=" + companyId);
    window.localStorage.setItem("companyId", companyId);
  };

  onFilterClickHandler = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    this.setState({ filterAnchor: event.currentTarget });
  };

  handleClosePopOver = () => {
    this.setState({ filterAnchor: undefined });
  };

  handleFilterChange = (filters: IFilter[]) => {
    if (checkIsFilterApplied(filters)) {
      window.localStorage.setItem(
        "b2b_company_filter",
        JSON.stringify(filters)
      );
    } else {
      window.localStorage.removeItem("b2b_company_filter");
    }

    this.setState(
      {
        filters,
        isAppliedFilter: checkIsFilterApplied(filters),
        page: 0,
      },
      this.getCompanies
    );
  };

  handleGroupFilterAutoComplete = (title: string, value: string) => {
    this.setState({ suggestionFieldTitle: title });

    this.state.filters.forEach((item: IFilter) => {
      if (item.title === title) {
        item.value = value;
      }
      return item;
    });

    let ApiUrl = ''
    if (title === "Company Name") {
      ApiUrl = configJSON.companyNameSuggestionsEndPoint + `${value}` + `&type=b2b_company`;
    } else if (title === "Register No."){
      ApiUrl = configJSON.regNoSuggestionsEndPoint + `${value}` + `&type=b2b_company`;
    }

    let requestMessage = makeApiMessage({
      url: ApiUrl,
      method: "GET",
    });
    this.filterSuggestionApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  debouncedCustomerGroupSuggestion = debounce(
    this.handleGroupFilterAutoComplete,
    700
  );

  handleFilterSuggestionApiResponse = (response: { filter_names: string[] }) => {
    if (Array.isArray(response.filter_names)) {
      const updatedFilters = getFiterList(
        response,
        this.state.filters,
        this.state.suggestionFieldTitle
      );
      this.setState({ filters: updatedFilters });
    }
  };

  // Customizable Area End
}