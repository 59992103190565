import React from "react";
// Customizable Area Start
import { Box, Typography, Snackbar, Grid,styled } from "@material-ui/core";

import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import CustomTypography from "../../../components/src/customComponent/CustomTypography.web";
import CustomInput from "../../../components/src/customComponent/cutomInput.web";

import AddEditTaxController, { Props, configData } from "./AddEditTaxController.web";
import Alert from "@material-ui/lab/Alert";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";
import { style } from "./TaxListStyle";
import CustomButton from "../../../components/src/customComponent/customButton.web";
import { Formik, Form } from "formik";
import { StyledMainBox } from "./TaxList.web";

const theme = createTheme({});

// Customizable Area End

class AddEditTax extends AddEditTaxController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start


  render() {
    const classes = this.props.classes;

    return (
      //Merge Engine DefaultContainer
      <ThemeProvider theme={theme}>
       
        <StyledMainBox>
          <Box>
            <CustomLoader loading={this.state.loading || this.state.addBtnLoading} />
            <Box className="titleContainer">
              <Typography className="titleContainerText">
                {configData.taxInformation}
              </Typography>
            </Box>

            <Formik
              enableReinitialize
              validateOnBlur={true}
              initialValues={{
                ...this.state.intialValues,
              }}
              onSubmit={(values) => {
                this.updateTax(values);
              }}
              validationSchema={this.FormSchema}
              data-test-id='AddEditTaxFormik'
            >
              {({ errors, values, handleSubmit, handleChange, touched }) => {
                return (
                  //getting build error but in local build is successful
                  // @ts-ignore
                  <Form onSubmit={handleSubmit} translate='TaxDetail'>
                    <Box className={classes.addEditCard}>
                      <Box className={classes.editInputBox}>
                        <Grid container spacing={4}>
                          <Grid item xs={12} md={6}>
                            <TextLabel>{configData.taxName}</TextLabel>
                            <CustomInput
                              name='taxName'
                              data-test-id="taxName"
                              placeholder={configData.enterTaxName}
                              onChange={handleChange}
                              inputError={touched.taxName && errors.taxName}
                              value={values.taxName}
                            />
                            {touched.taxName && errors.taxName && (
                              <Typography className={classes.error}>{errors.taxName}</Typography>
                            )}
                          </Grid>
                          <Grid item xs={12} md={6}>
                          <TextLabel>{configData.taxPercentage}</TextLabel>
                            <CustomInput
                              name='taxRate'
                              data-test-id='taxRate'
                              placeholder={configData.enterTaxPercentage}
                              onChange={handleChange}
                              inputError={touched.taxRate && errors.taxRate}
                              value={values.taxRate}
                            />
                            {touched.taxRate && errors.taxRate && (
                              <Typography className={classes.error}>{errors.taxRate}</Typography>
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                    <Box>
                       <BottomContainer>
                          <button
                            data-test-id={configData.cancel}
                            onClick={() => this.handelCancelForm()}
                            className='cancel'
                          >
                            {configData.cancel}
                          </button>
                          <button
                            data-test-id={configData.add}
                            className='add'
                            type='submit'
                          >
                            {configData.add}
                          </button>
                    </BottomContainer>
                    </Box>
                    </Form>
                );
              }}
            </Formik>
            <Snackbar
              anchorOrigin={{
                vertical: configData.top,
                horizontal: configData.center,
              }}
              autoHideDuration={2000}
              open={this.state.snackbar.open}
              onClose={this.handleCloseSnackbar}
            >
              <Alert severity={this.state.snackbar.severity}>{this.state.snackbar.message}</Alert>
            </Snackbar>
          </Box>
        </StyledMainBox>
       
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
  }

  // Customizable Area End
}

// Customizable Area Start

export default withStyles(style)(AddEditTax);

const TextLabel = styled("label")({
  display: "block",
  fontSize: 16,
  lineHeight: "24px",
  fontWeight: 600,
  paddingBottom: 4,
  color: "var(--neutrals-cool-gray-700, #334155)",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "13px",
  },
});

const BottomContainer = styled("div")({
  gap: 32,
  display: "flex",
  paddingTop: 48,
  "& > button": {
    fontSize: 16,
    padding: 16,
    minWidth: 126,
    border: "none",
    fontWeight: 700,
    borderRadius: 8,
    cursor: "pointer",
    lineHeight: "24px",
    alignItems: "center",
    display: "inline-flex",
    justifyContent: "center",
    fontFamily: "Montserrat",
    "&:disabled": {
      opacity: 0.5,
    },
  },
  "& > .add": {
    color: "white",
    background: "var(--primary, #204B9C)",
    "@media only screen and (max-width: 1024px)": {
      width: "126px",
      height: "44px",
    },
  },
  "& > .cancel": {
    color: "#64748B",
    background: "var(--primary, #F1F5F9)",
    "@media only screen and (max-width: 1024px)": {
      width: "126px",
      height: "44px",
    },
  },
});
// Customizable Area End
