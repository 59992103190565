// Customizable Area Start
import React from "react";
import ViewPreferenceController, { Props } from "./ViewPreferenceController.web"
import {Box,styled } from "@material-ui/core";
import "./PreferenceList.css"
import { StyledMninDivWrapper } from "./AddPreference.web";
import { PrefHeading } from "./PreferenceList.web";
import { colors } from "../../../blocks/utilities/src/Colors";
import { renderBaseOnConditions } from "../../../blocks/utilities/src/CustomBlockHelpers";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

class ViewPreference extends ViewPreferenceController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
      // Customizable Area Start
        const {viewData ,iconDefault} = this.state
        return (
             <StyledMainBox>
                <StyledMninDivWrapper className="preference-list-container">
                    <PrefTopStyled className="preference-top">
                        <PrefHeading>Preference</PrefHeading>
                       {renderBaseOnConditions(this.state.permissionStatus.editPermission, <button className="editButton" onClick={()=> this.handleEditPreference(viewData)} data-test-id="edit-button">Edit</button>,<></>)}
                    </PrefTopStyled>
                    <StyleContainerTablev className="container-table">
                        <div className="view-detail">
                            <div className="view-detail-inner">
                                <div className="first-preference">
                                    <ViewTextLabel>Preference Name</ViewTextLabel>
                                    <ViewTextContent>{viewData?.attributes.preference_first_name}</ViewTextContent>
                                </div>
                                <div>
                                    <ViewTextLabel>Icon</ViewTextLabel>
                                    <div style={{ display: "flex", alignItems: "center", gap: 8}}>
                                    <img style={{ width: "20px", height: "20px" }} src={viewData?.attributes.gallery_icon === null ? iconDefault : viewData?.attributes.gallery_icon?.image}/>
                                    <ViewTextContent className="no-margin">{viewData?.attributes.gallery_icon?.name_translation  || "No Icon"}</ViewTextContent>
                                    </div>
                                </div>

                            </div>
                            <div className="view-detail-inner">
                                <ViewTextLabel>Preference Arabic Name</ViewTextLabel>
                                <ViewTextContent>{viewData?.attributes.preference_second_name}</ViewTextContent>
                            </div>
                        </div>
                    </StyleContainerTablev>
                    <div className="back-button preference-top">
                    <button className="backButton" onClick={this.handleViewCancel} data-test-id="cancel-button">Back</button>
                </div>
                </StyledMninDivWrapper>
             </StyledMainBox>
        );
      // Customizable Area End
    }
}

// Customizable Area Start
export default ViewPreference;

const PrefTopStyled = styled("div")({
    margin: "25px 0 !important"
})

const ViewTextLabel = styled("p")({
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "22px",
    color: colors().darkliver,
    fontFamily: "Montserrat",
    "@media only screen and (max-width: 1024px)": {
        fontSize: "16px !important",
        fontWeight: 600,
        color:`${colors().viewTextColor} !important`
    },
});

const ViewTextContent = styled("span")({
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "22px",
    color: "#1A1A1A",
    marginTop: "12px",
    "&.no-margin": {
      marginTop: 0
    },
    "@media only screen and (max-width: 1024px)": {
        fontSize: "13px !important",
        fontWeight: 500,
        color:`${colors().viewTextColor} !important`
    },
});

const StyledMainBox = styled(Box)({
    "& .backButton": {
        background: colors().cyancobaltblue,
        fontWeight: 600,
        fontSize: "16px !important",
        lineHeight: "24px",
        color: colors().white,
        height: "56px",
        width: '128px !important',
        borderRadius: "8px",
        textTransform: "unset",
        "@media only screen and (max-width: 1024px)": {
            fontSize: "16px !important",
            width: "128px !important",
            height: "44px !important",
            padding: "10px !important"
        },
    },
})

const StyleContainerTablev= styled("div")({
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06) !important",
    padding: "5px !important",
    border: "none !important",
    "& .view-detail":{
        marginBottom:"0 !important",
    },
    "& .view-detail-inner": {
        marginTop:"10px !important"
    }
});
// Customizable Area End
