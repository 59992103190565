import React, { useState } from "react";
import { styled } from "@material-ui/core"
import { KeyboardDatePicker, KeyboardDatePickerProps, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/moment";
import { colors } from "../../blocks/utilities/src/Colors";
import { calendarIcon } from "./commonAssets/assets";

class ExtendedUtils extends DateFnsUtils {
    getWeekdays(): string[] {
        return ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
    }
}


const StyledInlineDatePicker = (props: Partial<KeyboardDatePickerProps>) => {
    const [open, setOpen] = useState(false);

    return (
        <MuiPickersUtilsProvider utils={ExtendedUtils}>
            <StyledDatePicker
                open={open}
                onClick={() => setOpen(true)}
                onClose={() => setOpen(false)}
                autoOk
                disableToolbar
                variant='inline'
                format='DD/MM/yyyy'
                placeholder="Select Date"
                KeyboardButtonProps={{
                    "aria-label": "change date",
                }}
                keyboardIcon={<></>}
                maxDate={new Date()}
                value={null}
                onChange={() => {}}
                {...props}
                InputProps={{
                    startAdornment: <img src={calendarIcon} />,
                    style: {
                        paddingLeft: 10,
                        width: 211
                    },
                    ...props.InputProps
                }}
            />
        </MuiPickersUtilsProvider>
    )
}

export default StyledInlineDatePicker;

const StyledDatePicker = styled(KeyboardDatePicker)({
    "& > div:hover": {
        borderColor: "rgba(0, 0, 0, 0.87)",
    },
    "& > div": {
        borderRadius: 8,
        border: "2px solid #ECECEC",
        boxShadow: "0px 0px 1px #ECECEC",
    },
    "& .MuiInpputBase-root": {
        paddingLeft: 10
    },
    "& input, & .MuiInputBase-input::placeholder": {
        opacity: 1,
        fontSize: 14,
        color: colors().darkliver,
        fontFamily: "Montserrat",
        padding: '10px 5px',
        "@media only screen and (max-width: 1024px)": {
            fontSize: '12px',
        },
    },
    "& .MuiInput-underline::after, & .MuiInput-underline::before, & p": {
        display: "none",
    },
    "@media only screen and (max-width: 1024px)": {
        '& .MuiInputBase-input': {
            fontSize: '12px',
            padding: '10px 12px',
        },
        "& div button": {
            padding: "2px 1px"
        }
    },
});