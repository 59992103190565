export const userProfile = require("../assets/user-profile.png");
export const shelveIcon = require("../assets/image_shelves.png");
export const dashboardIcon = require("../assets/image_dashboard.svg");
export const orderIcon = require("../assets/image_order.png");
export const settingsIcon = require("../assets/image_settings.png");
export const accountIcon = require("../assets/image_account.png");
export const rolesIcon = require("../assets/image_roles.png");
export const regionIcon = require("../assets/image_region.png");
export const generalIcon = require("../assets/image_general.png");
export const activeUserIcon = require("../assets/user_active.png");
export const passiveUserIcon = require("../assets/user_passive.png");
export const testIcon = require("../assets/test.png");
export const areaIcon = require("../assets/image_area.png");
export const ProductsIcon = require("../assets/img_products.png");
export const ProductIcon = require("../assets/img_product.png");
export const CompanyIcon = require("../assets/companyIcon.png");
export const PlantIcon = require("../assets/plantIcon.png");
export const TaxIcon = require("../assets/taxIcon.png");
export const StoreIcon = require("../assets/storeIcon.png");
export const StoreGrpIcon = require("../assets/storeGrp.png");
export const ServicesIcon = require("../assets/services.png");
export const SectionIcon = require("../assets/section.png");
export const UpchargeIcon = require("../assets/upcharge.png");
export const ProductSpecificationIcon = require("../assets/productSpesification.png");
export const PreferenceIcon = require("../assets/preference.png");
export const GalleryIcon = require("../assets/gallery.png");
export const OrderCreationIcon = require("../assets/image_order creation.png");
export const pushPinIcon = require("../assets/push_pin.png");
export const marketingIcon = require("../assets/marketing.png");
export const loyaltyIcon = require("../assets/loyalty.png");
export const GroupIcon = require("../assets/group.svg");
export const OrganizationIcon = require("../assets/image_organisation.png");
export const CustomerListIcon = require("../assets/customer_list_icon.png");
export const promoCodeIcon = require("../assets/image-promo-code.png");
export const ProfessionIcon = require("../assets/profession.png");
export const RedeemIcon = require("../assets/redeemIcon.svg");
export const PriceListIcon = require("../assets/price_list_icon.png");
export const SearchIconDrawer = require("../assets/searchIcon.svg");
export const PlantOrderIcon = require("../assets/plant.png");
export const ExpandIcon = require("../assets/ExpandIcon.svg");
export const HomeCleanIcon = require("../assets/homeClean.svg");
export const HomeCleanProductIcon = require("../assets/HomeCleanProduct.svg");
export const HomeCleanSettingsIcon = require("../assets/home_cleaning_settings.svg");
export const HomeCleanOrdersIcon = require("../assets/HomeOrders.svg");
export const CashierIcon = require("../assets/image_cashier.png");
export const ReportIcon = require("../assets/image_reports_24px_rounded.svg")
export const ReceiptPrinterIcon = require("../assets/receipt_printer_icon.svg")
export const HardwareIcon = require("../assets/hardware_icon.svg")
export const GarmentTagPrinterIcon = require("../assets/garment_tag_printer.svg")
export const MetalProgettiIcon = require("../assets/metal_progetti_icon.svg")
export const logoIcon = require("../assets/image_logo.png");
export const CheckTrue = require("../assets/check_true.svg");
export const CheckFalse = require("../assets/check_false.svg");
export const activeB2b = require("../assets/activeB2b.png");
export const inActiveUser = require("../assets/inactive_user.png");
export const b2bSettingsIcon = require("../assets/b2b_settings.png");
export const businessAccountsIcon = require("../assets/business_accounts.png");
export const customerGroupsIcon = require("../assets/customer_groups.png");
export const customersIcon = require("../assets/customers.png");
export const productMasterIcon = require("../assets/product_master.png");
export const geofenceIcon = require("../assets/geofence.png");
export const companyB2BIcon = require("../assets/companyActive.png");





