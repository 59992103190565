// Customizable Area Start
import React from "react";
import {
  InputLabel,
  Typography,
  Grid,
  styled,
  Button,
  Box,
} from "@material-ui/core";

import { CustomPaper } from "../../../components/src/customComponents/CustomTags.web";
import { viewProductDetailsWebStyle } from "../../CfPriceList/src/CfViewProductDetails.web";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";

import B2BCustomerViewController, {
  Props,
  configJSON,
} from "./B2BCustomerViewController.web";

// Customizable Area End

export class B2BCustomerView extends B2BCustomerViewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  setCustomerData(data: string | number) {
    if (data !== undefined && data !== null && data !== "") {
      return <StyledTypography>{data}</StyledTypography>;
    } else {
      return <StyledTypography>--</StyledTypography>;
    }
  }

  setCustomerSecondData(data: string | number) {
    if (data !== undefined && data !== null && data !== "") {
      return <StyledSecondTypography>{data}</StyledSecondTypography>;
    } else {
      return <StyledSecondTypography>--</StyledSecondTypography>;
    }
  }

  render() {
    const { customerDetails } = this.state;
    return (
      <>
        {this.state.isLoading ? (
          <CustomLoader loading={this.state.isLoading} />
        ) : (
          <>
            <StyleHeaderDiv>
              <PageTitle>{configJSON.viewPageTitle.mainTitle}</PageTitle>
              <StyledButton
                data-test-id='editBtn'
                onClick={() => {
                  this.handleEditCustomer(this.state.customerId);
                }}
              >
                {configJSON.viewPageTitle.btnTxtEdit}
              </StyledButton>
            </StyleHeaderDiv>
            <CustomPaper style={viewProductDetailsWebStyle.paper}>
              <Grid container spacing={3} id='modal-content'>
                <Grid item xs={12} sm={12} md={6} data-test-id='txtName'>
                  <StyledInputLabel>
                    {configJSON.viewPageTitle.customerName}
                  </StyledInputLabel>
                  {this.setCustomerData(customerDetails.attributes.name)}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>
                    {configJSON.viewPageTitle.mobileNo}
                  </StyledInputLabel>
                  {this.setCustomerData(
                    customerDetails.attributes.full_phone_number
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>
                    {configJSON.viewPageTitle.emailID}
                  </StyledInputLabel>
                  {this.setCustomerData(
                    customerDetails.attributes.email as string
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>
                    {configJSON.viewPageTitle.companyName}
                  </StyledInputLabel>
                  {this.setCustomerData(
                    customerDetails.attributes.company?.name
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>
                    {configJSON.viewPageTitle.businessCustomerName}
                  </StyledInputLabel>
                  {this.setCustomerData(
                    customerDetails.attributes.business_account
                      .business_customer
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>
                    {configJSON.viewPageTitle.departmentName}
                  </StyledInputLabel>
                  {this.setCustomerData(
                    customerDetails.attributes.department_name as string
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>
                    {configJSON.viewPageTitle.departmentID}
                  </StyledInputLabel>
                  {this.setCustomerData(
                    customerDetails.attributes.department_id as string
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>
                    {configJSON.viewPageTitle.employeeID}
                  </StyledInputLabel>
                  {this.setCustomerData(customerDetails.attributes.employee_id)}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>
                    {configJSON.viewPageTitle.costCenter}
                  </StyledInputLabel>
                  {this.setCustomerData(
                    customerDetails.attributes.cost_center as string
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>
                    {configJSON.viewPageTitle.subCostCenter}
                  </StyledInputLabel>
                  {this.setCustomerData(
                    customerDetails.attributes.sub_cost_center as string
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>
                    {configJSON.viewPageTitle.gender}
                  </StyledInputLabel>
                  {this.setCustomerData(
                    customerDetails.attributes.gender?.label
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>
                    {configJSON.viewPageTitle.address}
                  </StyledInputLabel>
                  {this.setCustomerData(
                    customerDetails.attributes.address as string
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>
                    {configJSON.viewPageTitle.city}
                  </StyledInputLabel>
                  {this.setCustomerData(
                    customerDetails.attributes.city?.name_en
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>
                    {configJSON.viewPageTitle.pinCode}
                  </StyledInputLabel>
                  {this.setCustomerData(
                    customerDetails.attributes.postal_code as string
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>
                    {configJSON.viewPageTitle.notes}
                  </StyledInputLabel>
                  {this.setCustomerData(
                    customerDetails.attributes.notes as string
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>
                    {configJSON.viewPageTitle.privateNotes}
                  </StyledInputLabel>
                  {this.setCustomerData(
                    customerDetails.attributes.private_notes as string
                  )}
                </Grid>
              </Grid>
            </CustomPaper>
            <CustomPaper>
              <Grid container spacing={3} id='modal-content'>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>
                    {configJSON.viewPageTitle.catalogue}
                  </StyledInputLabel>
                  {this.setCustomerSecondData(
                    customerDetails.attributes.price_list?.name
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>
                    {configJSON.viewPageTitle.noofOrders}
                  </StyledInputLabel>
                  {this.setCustomerSecondData(
                    customerDetails.attributes.no_of_order as number
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>
                    {configJSON.viewPageTitle.noofPiecesPerOrder}
                  </StyledInputLabel>
                  {this.setCustomerSecondData(
                    customerDetails.attributes.no_of_pieces as number
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>
                    {configJSON.viewPageTitle.customerGroup}
                  </StyledInputLabel>
                  {this.setCustomerSecondData(
                    customerDetails.attributes.business_customer_group
                      ?.group_name
                  )}
                </Grid>
              </Grid>
            </CustomPaper>
            <Box style={{ padding: "25px 0px" }}>
              <StyledButton
                data-test-id='btnBack'
                onClick={() => {
                  this.handleBackToListing();
                }}
              >
                Back
              </StyledButton>
            </Box>
          </>
        )}
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start

const StyledInputLabel = styled(InputLabel)({
  fontFamily: "Montserrat",
  fontWeight: 600,
  fontSize: "18px",
  lineHeight: "15.6px",
  color: "#4D4D4D",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "16px",
  },
});

const StyledTypography = styled(Typography)({
  fontSize: "16px",
  fontWeight: 500,
  fontFamily: "Montserrat",
  lineHeight: "24px",
  marginTop: "5px",
  color: "#1A1A1A",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "13px",
    fontWeight: 500,
    lineHeight: "15.6px",
  },
});

const StyledSecondTypography = styled(Typography)({
  fontSize: "16px",
  fontWeight: 500,
  fontFamily: "Montserrat",
  lineHeight: "24px",
  marginTop: "5px",
  color: "#204B9C",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "13px",
    fontWeight: 500,
    lineHeight: "15.6px",
  },
});

const PageTitle = styled("div")({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "24px",
  lineHeight: "29px",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "16px",
  },
});

const StyledButton = styled(Button)({
  width: "184px",
  height: "56px",
  color: "rgb(255, 255, 255)",
  background: "rgb(32, 75, 156)",
  backgroundColor: "rgb(32, 75, 156)",
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "24px",
  borderRadius: "8px",
  marginLeft: "10px",
  textTransform: "unset",

  "&.MuiButton-root:hover": {
    backgroundColor: "rgb(32, 75, 156)",
  },
  "@media only screen and (max-width: 1024px)": {
    width: "126px !important",
    height: "44px !important",
  },
});

const StyleHeaderDiv = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  margin: "30px 0px",
  alignItems: "center",
});

export default B2BCustomerView;
// Customizable Area End
