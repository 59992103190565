import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH",
}

interface Icon {
  id: number;
  name: string;
  second_name: string;
  image_type: string;
  image: string | undefined;
}

export interface CustomerTypeViewInterface {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    maximum_points: string,
    minimum_points: string,
    customer_type_name: string,
    min_number_of_orders: string,
    max_number_of_orders: string,
    active: boolean;
    icon: Icon;
    no_of_users: any;
  };
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  classes?: any;
  navigation: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  isSubmitting: boolean;
  customerTypeDetail: CustomerTypeViewInterface;
  permissionStatus: PermissionStatus;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomerTypeViewController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  public RequestMessage = {
    GetCustomerTypeDetail: this.buildRequestMessageCustomerTyperForView(Method.GET),
    Null: undefined as any,
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.LayoutDataMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      isSubmitting: false,
      customerTypeDetail: {
        id: "",
        type: "",
        attributes: {
          id: 1,
          name: "",
          maximum_points: "",
          minimum_points: "",
          customer_type_name: "",
          min_number_of_orders: "",
          max_number_of_orders: "",
          active: true,
          icon: {
            id: 0,
            name: "",
            second_name: "",
            image_type: "",
            image: undefined,
          },
          no_of_users: "",
        }
      },
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      }
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    this.receiveDataFromLayout(message);
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const callID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (callID === this.RequestMessage.GetCustomerTypeDetail.messageId) {
      this.handleGetCustomerTypeDetailResponse(response);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  private handleGetCustomerTypeDetailResponse(response: { data: CustomerTypeViewInterface }) {
    if (response) {
      const { data } = response;

      this.setState({
        customerTypeDetail: data,
        isLoading: false,
      });
    }
  }

  receiveDataFromLayout = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.LayoutMessageData)
        );
        if (recievedData.userContext) {
            this.handleUserChange(recievedData.userContext)
        }
    }
  }

  handleCancelClick = () => {
    this.props.navigation.goBack();
  };

  handleEditCustomerTypeClick = () => {
    this.props.navigation.navigate("CustomerTypeEdit", {id: this.state.customerTypeDetail.id});
  };

  getCustomerTypeDetailCallForView = (customer_type_id: string) => {
    this.setState({ isLoading: true });

    this.RequestMessage.GetCustomerTypeDetail.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.customerTypeEndPoint}/${customer_type_id}`
    );

    runEngine.sendMessage(
      this.RequestMessage.GetCustomerTypeDetail.id,
      this.RequestMessage.GetCustomerTypeDetail
    );
  };

  private buildRequestMessageCustomerTyperForView(method: Method): Message {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.customerTypeEndPoint
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMessage), configJSON.APIBaseURL);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      this.getHeaderMessageCustomerTypeForView()
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method.toString());

    return requestMessage;
  }

  getHeaderMessageCustomerTypeForView() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem(configJSON.token),
    };

    return JSON.stringify(header);
  }

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.customerType;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    })
  };

  async componentDidMount() {
    super.componentDidMount();

    const customerTypeId = this.props.navigation.getParam("id", "defaultValue");
    this.getCustomerTypeDetailCallForView(customerTypeId as string);
  }
  // Customizable Area End
}
