// Customizable Area Start
import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import PageContainer from "../../..//blocks/navigationmenu/src/PageContainer.web";
import { styled } from "@material-ui/core";
import { renderBaseOnConditions } from "../../../blocks/utilities/src/CustomBlockHelpers";
export const configJSON = require("./config");

import CustomerTypeViewController, { Props } from "./CustomerTypeViewController.web";
// Customizable Area End

// Customizable Area Start
const StyledGrid = styled(Grid)({
  "& .pageTitleView": {
    "fontSize": "24px",
    "marginTop": "32px",
    "fontWeight": 600,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
      "marginTop": "16px",
    },
  },
  "& .editBtn": {
    marginTop: 32,
    "@media only screen and (max-width: 1024px)": {
      "marginTop": "16px",
    },
  },
  "& .fieldLabel": {
    fontSize: 16,
    marginBottom: "8px",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "13px !important"
    },
  },
  "& .fieldValue": {
    fontSize: 16,
    fontWeight: 500,
    marginTop: 4,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "12px !important"
    },
  },
  "& .pageContainerView": {
    "fontFamily": "Montserrat, sans-serif",
    "padding": "32.5px 48px",
    "background": "white"
  },
  "& .pageContainerView .MuiSelect-select.Mui-disabled": {
    "color": "#7e7e7e"
  },
  "& .buttonView": {
    "padding": "16px 34px",
    "borderRadius": "8px",
    "fontSize": "16px",
    "fontWeight": 700,
    "cursor": "pointer",
    "backgroundColor": "#204b9c",
    "color": "white",
    "textAlign": "center",
    "@media only screen and (max-width: 1024px)": {
      "padding": "12px 34px",
    },
  },
  "& .formContainerView": {
    "padding": "32px",
    "borderRadius": "12px",
    "boxShadow": "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)"
  },
  "& .formContainerView .MuiFormLabel-root": {
    "fontFamily": "Montserrat, sans-serif",
    "fontStyle": "normal",
    "fontWeight": 600,
    "fontSize": "16px",
    "letterSpacing": "-0.3px",
    "color": "#1a1a1a"
  },
  "& .iconStyle":{
    width: '24px', 
    marginRight: '24px',
    "@media only screen and (max-width: 1024px)": {
      width: '18px', 
    marginRight: '12px',
    },
  },
  "& .align-Center":{
    display: "flex",
    alignItems: "center"
  }
});
// Customizable Area End

export class CustomerTypeView extends CustomerTypeViewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderTitle = () => {
    return <div className='pageTitleView'>{configJSON.customerType}</div>;
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { customerTypeDetail, permissionStatus } = this.state;

    return (
      <>
          <div>
          <StyledGrid container direction='column' className='pageContainerView'>
            <Grid
              container
              direction='row'
              style={{
                marginBottom: 32,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {this.renderTitle()}
                {renderBaseOnConditions(permissionStatus.editPermission,
                  <div
                    data-test-id={"editCustomerType"}
                    onClick={this.handleEditCustomerTypeClick}
                    className='buttonView editBtn'
                  >
                    Edit
                  </div>,
                  <></>)}
            </Grid>
            <Grid container direction='column' className='formContainerView'>
              <Grid container direction='row'>
                <Grid item xs={12} sm={6} style={{ paddingRight: 32 }}>
                  <FormControl style={{ width: "100%" }}>
                    <FormLabel className="fieldLabel">Customer Class/Type Name</FormLabel>
                    <Typography
                      className="fieldValue"
                    >
                      {customerTypeDetail.attributes.customer_type_name || configJSON.blank}
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl style={{ width: "100%" }}>
                    <FormLabel className="fieldLabel">Customer Type Icon</FormLabel>
                    <Typography
                      className="fieldValue align-Center"
                    >
                      <img className="iconStyle" src={customerTypeDetail?.attributes?.icon?.image} alt={"Icon image"} />
                      {customerTypeDetail.attributes.icon?.name || configJSON.blank}

                    </Typography>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container direction='row' style={{ marginTop: 32 }}>
                <Grid item xs={12} sm={6} style={{ paddingRight: 32 }}>
                  <FormControl style={{ width: "100%" }}>
                    <FormLabel className="fieldLabel">
                      Minimum Points
                    </FormLabel>
                    <Typography
                      className="fieldValue"
                    >
                      {customerTypeDetail.attributes.minimum_points || configJSON.blank}
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl style={{ width: "100%" }}>
                    <FormLabel className="fieldLabel">
                    Maximum Points
                    </FormLabel>
                    <Typography
                      className="fieldValue"
                    >
                      {customerTypeDetail.attributes.maximum_points || configJSON.blank}
                    </Typography>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container direction='row' style={{ marginTop: 32 }}>
                <Grid item xs={12} sm={6} style={{ paddingRight: 32 }}>
                  <FormControl style={{ width: "100%" }}>
                    <FormLabel className="fieldLabel">
                    Minimum Number of Orders
                    </FormLabel>
                    <Typography
                      className="fieldValue"
                    >
                      {customerTypeDetail.attributes.min_number_of_orders || configJSON.blank}
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl style={{ width: "100%" }}>
                    <FormLabel className="fieldLabel">
                    Maximum Number of Orders
                    </FormLabel>
                    <Typography
                      className="fieldValue"
                    >
                      {customerTypeDetail.attributes.max_number_of_orders || configJSON.blank}
                    </Typography>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid container direction='row' style={{ margin: "48px 0 24px 0" }}>
              <div
                data-test-id={"cancelBtn"}
                onClick={this.handleCancelClick}
                className='buttonView'
                style={{
                  marginRight: 32,
                }}
              >
                {configJSON.backText}
              </div>
            </Grid>
          </StyledGrid>
          </div>
      </>
    );
    // Customizable Area End
  }
}

export default CustomerTypeView;
