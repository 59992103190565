import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Container,
    Typography,
    Card,
    CardContent,
    Grid,
    styled
} from "@material-ui/core";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { renderBaseOnConditions } from "../../../blocks/utilities/src/CustomBlockHelpers";
import ViewServicesController, { Props, configData } from "./ViewServicesController.web";
import styles from "./ViewServiceStyle";
import { styleOverride } from "./EditServicesStyle"



const theme = createTheme(styleOverride)





// Customizable Area End




class ViewServices extends ViewServicesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

  


    render() {

        const preFilledServiceData = this.state.preFilledEdit
        
        return (
            //Merge Engine DefaultContainer
            <ThemeProvider theme={theme}>
                <>
                    <CustomLoader loading={this.state.loading}/>
                    <ViewServicesWrapper className={`parentContainerMargin parentContainer`}>
                        <Box className="galleryContainer">

                            <Typography
                                className="heading"
                            >
                                {configData.service}
                            </Typography>
                            {renderBaseOnConditions(this.state.permissionStatus.editPermission,<Button
                                data-test-id="edit-btn"
                                variant="contained"
                                className="editButton"
                                onClick={() => this.props.navigation.history.push(`/Products-ServicesListEdit/${preFilledServiceData?.id}`, { state: preFilledServiceData?.id })}
                            >
                                {configData.edit}
                            </Button>,<></>)}
                        </Box>

                        <Box className="cardParentContainer">
                            <Card className="cardContainer">

                                <CardContent className="cardContent">
                                    <Grid container >

                                        <Grid item xs={5}>


                                            <Box>
                                                <Typography className="inputLabel">{configData.serviceName}</Typography>
                                                <Typography className="value">{preFilledServiceData?.serviceName}</Typography>
                                            </Box>

                                        </Grid>

                                        <Grid item xs={5}>


                                            <Box>
                                                <Typography className="inputLabel">
                                                    {configData.serviceSecondName}
                                                </Typography>
                                                <Typography className="value">
                                                    {preFilledServiceData?.serviceSecondaryName}
                                                </Typography>
                                            </Box>

                                        </Grid>
                                    </Grid>
                                   
                        <Grid container>
                                                <Grid item xs={5}>


                                    <Box>
                                        <Typography className="inputLabel">{configData.serviceShortName}</Typography>
                                        <Typography className="value">{preFilledServiceData?.serviceShortName}</Typography>
                                    </Box>

                                    </Grid>
                                        <Grid item xs={5}>
                                            <Box>
                                                <Typography className="inputLabel">{configData.service}</Typography>


                                                <ServiceContainer>
                                                    {preFilledServiceData?.selectedSectionName?.map((item: string) => (
                                                        <Box key={item} className="serviceInnerContainer">{item}</Box>
                                                    ))}
                                                </ServiceContainer>


                                            </Box>

                                        </Grid>
                                        </Grid>
                                        <Grid container>

                                        <Grid item xs={5}>
                                            < Typography className="inputLabel">{configData.icon}</Typography>
                                            <Box className="imageBox">
                                                {preFilledServiceData?.selectedIcon?.item || "---"}
                                            </Box>

                                        </Grid>
                                    </Grid>


                                    <Grid container>

                                        <Grid item xs={5}>
                                            {preFilledServiceData?.onlineOrder ?
                                              <Box className="innerContainer" >
                                                <Box className="activeOrder"></Box>
                                                <Typography className="innerText">{configData.activeForOrder}</Typography>
                                              </Box>
                                              :
                                              <Box className="innerContainer" >
                                                <Box
                                                  style={{
                                                    height: "16px",
                                                    width: "16px",
                                                    border: "1px solid #1a1a1a",
                                                    borderRadius: "50%",
                                                  }}  
                                                ></Box>
                                                <Typography className="innerText">{configData.inActiveForOrder}</Typography>
                                              </Box>
                                            }
                                    </Grid>
                                </Grid>



                            </CardContent>
                        </Card>

                        <Button
                            variant="contained"
                            data-test-id="cancel-btn"
                            className="cancelButton"
                            onClick={() => this.handleRedirect()}
                        >
                            {configData.back}
                        </Button>

                    </Box>
                </ViewServicesWrapper>
            </>
            </ThemeProvider >
            //Merge Engine End DefaultContainer
        );
    }
      // Customizable Area End
}

// Customizable Area Start
const ServiceContainer = styled("div")({
  display: "flex",
  gap: "10px",
  flexWrap: "wrap"
});

const ViewServicesWrapper = styled("div")({
        "& .inputLabel": {
        color: "#4D4D4D",
        fontWeight: 600,
        marginBottom: '10px',
        fontSize: '16px',
        "@media (max-width: 1024px)": {
            fontSize: '13px',
          },
    },

    "& .innerContainer": {
        display: "flex",
        gap: "10px",
        alignItems: "center",
        minWidth:"220px",
    },
    "& .innerText": {
        fontWeight:500,
        "@media (max-width: 1366px)": {
            fontSize: '14px',
            gap: "5px",
          },
    },
    "& .cancelButton": {
        backgroundColor: "#204B9C",
        color: "#FFFFFF",
        margin:"2rem",
        borderRadius: "8px",
        height: "56px",
        width: "128px",
        fontSize: "16px",
        '&:hover':{
            backgroundColor: "#204B9C",
            color: "#FFFFFF",
        },
        textTransform: 'none',
        fontWeight: 700,
          "@media (max-width: 1024px)": {
            width: '128px',
            height: '44px',
          padding:"10px 0"
          },
       
    },
    "& .activeOrder": {
        height: "16px",
        width: "16px",
        backgroundColor: "#059669",
        borderRadius: "50%",
      
    },
    "& .cardContent": {
        display: "flex",
        flexDirection: "column",
        gap: "32px",
    },
    "& .MuiCardContent-root": {
        padding: 0
    },
    "& .serviceContainer": {
        display: "flex",
        gap: "10px"
    },
    "& .serviceInnerContainer": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(32, 75, 156, 0.1)",
        color: '#204B9C',
        borderRadius: "8px",
        padding: "4px 8px",
        "@media (max-width: 1366px)": {
            borderRadius: "8px",
            padding: "4px 6px",
            fontSize:"14px"
          },
          "@media (max-width: 1024px)": {
            borderRadius: "6px",
            padding: "4px 6px",
          },
          "@media (max-width: 768px)": {
            borderRadius: "4px",
            padding: "2px 4px",
            fontSize:"12px"
          },
    },
    "& .galleryContainer": {
        display: "flex",
        borderColor: "1px solid red",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "30px 0",
    },
    "& .heading": {
        fontSize: "24px",
        fontWeight: 600,
        lineHeight: "29.26px",
        "@media (max-width: 1024px)": {
            fontSize: '16px',
            lineHeight:"19.2px",
          },
    },
    "& .editButton": {
        backgroundColor: "#204B9C",
        color: "#FFFFFF",
        borderRadius: "8px",
        height: "56px",
        width: '128px',
        padding:"16px",
        fontSize: "16px",
        '&:hover':{
            backgroundColor: "#204B9C",
            color: "#FFFFFF",
        },
        textTransform:'none',
        fontWeight: 700,
        "@media (max-width: 1024px)": {
            width: '128px',
             height: '44px',
          padding:"10px 0"
          },
    },
    "& .cardContainer": {
        minHeight: "266px",
        borderRadius: "12px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
        padding:"20px"
    },
    "& .cardParentContainer": {
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
    },

    "& .parentContainerMargin": {
        margin: "2rem 0",
    },

    "& .imageBox":{
        display: "flex",
        gap: "20px",
        alignItems: "center",
        color: '#1A1A1A !important',
        fontSize:'16px !important',
        fontWeight:500 
    },
    "& .parentContainer":{
        width: '100%',
        padding:'10px 49px',
      
    },
    "& .value":{
        fontWeight:500, 
        color: '#1A1A1A',
        "@media (max-width: 1366px)": {
            fontSize: '14px',
            fontWeight:400, 
          },
    }

  });
  

export default withStyles(styles)(ViewServices)
// Customizable Area End







