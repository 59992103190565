import React, { useEffect, useMemo, useRef, useState } from "react";
import { toString, debounce, uniqBy } from "lodash";
import { styled, Box } from "@material-ui/core"
import CustomComboBox from "../CustomComboBox.web";
import AutocompleteSelect from "../AutocompleteSelect.web";
import { CustomSearchableMultiSelect } from "../customComponent/CustomSearchableMultiSelect.web";
import NumberOfPiecesPerOrder from "./NumberOfPiecesPerOrder.web";
import { colors } from "../../../blocks/utilities/src/Colors";

type Option = {
    id: number
    option: string
}

type GroupConfig = {
    id?: string
    price_list: { id: number; name: string } | null;
    business_customer_group_id: number;
    product_master: Option[];
    no_of_order: number | null;
    order_period_type: string;
    pieces_period_type: string;
    item_pics_configurations_attributes: Array<{
        _destroy?: boolean,
        product_master_id: number,
        no_of_pieces: number | null
    }>
}
type Props = {
    groupName?: string;
    groupConfig: GroupConfig;
    catalogueOptions: Option[]
    itemOptions: Option[]
    handleUpdateGroup: (
        groupConfig: GroupConfig,
        fieldName: keyof GroupConfig,
        fieldValue: GroupConfig[keyof GroupConfig],
        secondFieldName?: keyof GroupConfig,
        secondFieldValue?: GroupConfig[keyof GroupConfig]
    ) => void
    handleScrollCatalogueDropdown: (event: React.SyntheticEvent) => void
    handleBlurCatalogueDropdown: () => void
    handleCatalogueAutoCompleteChange: (inputValue: string) => void
}

const ITEMS_PER_PAGE = 10

const BusinessAccountCustomerGroupConfig = (props: Props) => {
    const {
        groupName,
        groupConfig,
        catalogueOptions,
        itemOptions,
        handleUpdateGroup,
        handleScrollCatalogueDropdown,
        handleBlurCatalogueDropdown,
        handleCatalogueAutoCompleteChange,
    } = props
    const [itemList, setItemList] = useState(itemOptions.slice(0, ITEMS_PER_PAGE))
    const pageRef = useRef(1)
    const queryTextRef = useRef("")
    const disableLoadMoreItemsRef = useRef(false)

    const { product_master, item_pics_configurations_attributes } = groupConfig
    const selectedOptionForList = product_master.length && product_master.length === itemOptions.length ?
        [{ id: "-1", option: "Select All" }, ...itemOptions] : product_master
    const itemsWithPieces = product_master
        .map(product => ({
            product_master_id: product.id,
            name: product.option,
            no_of_pieces: item_pics_configurations_attributes.find(item => item.product_master_id === product.id)?.no_of_pieces || null
        }))

    const itemValue = product_master.length && product_master.length === itemOptions.length ? [{id: "-1", option: "Select All"}] : product_master

    const handleSelectItem = (
        option: { id: number | "-1"; option: string },
    ) => {
        let selectedProducts: Option[] = []
        const { product_master } = groupConfig
        if (option.id == "-1") {
            if (itemOptions.length && itemOptions.length > product_master.length) {
                selectedProducts = itemOptions
            }
        } else {
            selectedProducts = product_master.some(product => product.id === option.id) ?
                product_master.filter(product => product.id !== option.id)
                : [...product_master, option] as Option[]
        }
        handleUpdateGroup(groupConfig, "product_master", selectedProducts)
    }

    const debouncedItemsFunction = debounce((inputValue: string) => {
            if (inputValue === queryTextRef.current) return;
            queryTextRef.current = inputValue
            pageRef.current = 1
            if (inputValue.length < 1 || inputValue.length > 2) {
                handleLoadItems()
            }
        },
        1000,
        { maxWait: 2000 }
    );

    const debouncedCatalogueFunction = debounce(
        (newInputValue: string) => handleCatalogueAutoCompleteChange(newInputValue)
        ,
        700,
        { maxWait: 2000 }
    );

    const catalogueOptionsList = useMemo(() => {
        const { price_list } = groupConfig
        if (!price_list) return catalogueOptions
        if (catalogueOptions.some(option => option.id === price_list.id)) return catalogueOptions
        return [
            {
                id: price_list.id,
                option: price_list.name
            },
            ...catalogueOptions
        ]
    }, [catalogueOptions, groupConfig.price_list])

    const handleLoadItems = () => {
        const page = pageRef.current
        const filteredItemsByQuery = queryTextRef.current ? itemOptions.filter(item => item.option.includes(queryTextRef.current)) : itemOptions
        const filteredItemsByPage = filteredItemsByQuery.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE)
        disableLoadMoreItemsRef.current = filteredItemsByPage.length < ITEMS_PER_PAGE
        setItemList(prev => uniqBy([...prev, ...filteredItemsByPage], "id"))
    };

    const reset = () => {
        pageRef.current = 1
        queryTextRef.current = ""
        disableLoadMoreItemsRef.current = false
    }

    useEffect(() => {
        setItemList(itemOptions.slice(0, ITEMS_PER_PAGE))
        reset()
    }, [itemOptions])

    return (
        <StyledBox>
            <p className="group-name">{toString(groupName)}</p>
            <Box className="autocomplete-field">
                <p className="label">Catalogue</p>
                <AutocompleteSelect
                    className="singleselect-dropdown"
                    placeholder="Select Catalogue"
                    fieldName={`price_list_group_${groupConfig.business_customer_group_id}`}
                    disableClear
                    clearOnBlur
                    onChange={(_item: React.ChangeEvent, value: Option) => {
                        reset()
                        handleUpdateGroup(groupConfig, "price_list", { id: value.id, name: value.option })
                    }}
                    handleScroll={handleScrollCatalogueDropdown}
                    onBlur={() => handleBlurCatalogueDropdown()}
                    debouncedFunction={debouncedCatalogueFunction}
                    value={groupConfig.price_list?.id || ""}
                    options={catalogueOptionsList}
                    listBoxStyle={{ maxHeight: 310 }}
                />
            </Box>

            <Box className="autocomplete-field">
                <p className="label">Items</p>
                <CustomSearchableMultiSelect
                    dataTestId={`items_group_${groupConfig.business_customer_group_id}`}
                    checkSelectAllText={() => { }}
                    optionList={itemList}
                    placeHolderText="Select Items"
                    changeInputOnClear
                    handleScroll={(event: React.SyntheticEvent) => {
                        if (disableLoadMoreItemsRef.current) return;
                        const checkListboxNode = event.currentTarget;
                        const boxPosition = checkListboxNode.scrollTop + checkListboxNode.clientHeight;
                        if (checkListboxNode.scrollHeight - boxPosition <= 1.30) {
                            pageRef.current++
                            handleLoadItems()
                        }
                    }}
                    isOnBlurFunction={() => reset()}
                    handleClickOnSelectAll={() => { }}
                    debouncedFunction={debouncedItemsFunction}
                    handleSelectOptions={(selectedOptionForList: unknown, option: Option) => {
                        handleSelectItem(option)
                    }}
                    value={itemValue}
                    emptyDataMessage="No Option Found"
                    handleEmptyAutoSelectValue={() => { }}
                    selectedOptionForList={selectedOptionForList}
                />
            </Box>
            <Box data-test-id={`no_of_orders_${groupConfig.business_customer_group_id}`} >
                <p className="label">No. of Order</p>
                <CustomComboBox
                    inputValue={groupConfig.no_of_order}
                    unitValue={groupConfig.order_period_type}
                    onChangeNumberInput={(numberValue) => handleUpdateGroup(groupConfig, "no_of_order", numberValue)}
                    onChangeUnit={(unitValue) => handleUpdateGroup(groupConfig, "order_period_type", unitValue)}
                />
            </Box>
            <Box display="flex" alignItems="flex-end">
                <NumberOfPiecesPerOrder
                    unit={groupConfig.pieces_period_type}
                    items={itemsWithPieces}
                    onDone={(newItems, periodType) => {
                        handleUpdateGroup(
                            groupConfig,
                            "item_pics_configurations_attributes",
                            newItems,
                            "pieces_period_type",
                            periodType
                        )
                    }}
                />
            </Box>
        </StyledBox>
    )
}

export const StyledBox = styled(Box)({
    "& .group-name": {
        gridColumn: "span 2",
        fontSize: 18,
        fontWeight: 600,
        lineHeight: "22px"
    },
    gridColumn: "span 2",
    display: "grid",
    gridTemplateColumns: "0.5fr 0.5fr",
    gap: 24,
    padding: 16,
    border: `solid 1px ${colors().lightborder}`,
    borderRadius: 8
})

export default React.memo(BusinessAccountCustomerGroupConfig)