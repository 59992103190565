/** @format */

import React from "react";
// Customizable Area Start
import EditSpecificationController, { Props } from "./EditSpecificationController.web"
import { withStyles ,Theme} from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles";
import { Typography, Box, Input, Snackbar } from "@material-ui/core";
import Alert, {Color} from '@material-ui/lab/Alert';
import CustomItemList from "../../../components/src/customComponents/CustomItemList.web";
export const configJSON = require("./config");
import { Formik } from 'formik';
import { colors } from "../../../blocks/utilities/src/Colors";
import { CustomSearchableMultiSelect } from "../../../components/src/customComponent/CustomSearchableMultiSelect.web";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";
import { CustomSnackbar } from "../../../components/src/customComponents/CustomSnackbar.web";
// Customizable Area End

// Customizable Area Start
const useStyles: Styles<Theme, {}, string> = () => ({
  specificationEditContainer: {
    padding: "0 32px 0 48px",
    fontFamily:'Montserrat',
  },
 
  '@media only screen and (max-width: 1024px)': {
    editTitle: {
    fontSize: '16px !important',
    color: colors().viewTextColor,
    fontFamily:'Montserrat',
    },
    headingInput: {
      fontFamily: "Montserrat !important",
      color: `${colors().charcoal} !important`,
      fontSize: "13px ",
      fontWeight:600 
    },
    headingInputOption: {
      fontFamily: "Montserrat !important",
      color: `${colors().black} !important`,
      fontSize: "16px !important",
      fontWeight:'600 !important',
      marginBottom: 10,
    },
    cancelBtn: {
      padding: '12.5px 35px !important',
      fontSize: '16px !important',
      fontWeight: '600 !important'
    },
  },
  editTitle: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "24px",
    margin:"40px 0"
  },
  '@media (max-width: 1200px)': {
    headingInputOption: {
      fontFamily: "Montserrat !important",
      color:  `${colors().black} !important`,
      fontSize: "18px !important",
      fontWeight:'600 !important',
      marginBottom: 10,
    },
    editTitle: {
      fontFamily: "Montserrat",
      fontWeight: 600,
      fontSize: "16px",
      margin:"40px 0"
    },
    headingInput: {
      fontFamily: "Montserrat",
      color: colors().charcoal,
      marginBottom: 4,
      fontSize: "13px !important",
      fontWeight:600
    },
  
    headingOptionInput: {
      fontFamily: "Montserrat",
      color:  `${colors().black} !important`,
      marginBottom: 4,
      fontSize: "16px",
      fontWeight:600
    },
  },
  headingInputOption: {
    fontFamily: "Montserrat !important",
    color:  `${colors().black} !important`,
    fontSize: "18px !important",
    fontWeight:600,
    marginBottom: 10,
  },
  headingTitle: {
    fontFamily:'Montserrat',
    fontSize: "18px",
    color: "#000000",
    fontWeight: 600,
    marginBottom: "25px"
  },
  innerContainerItem: {
    fontFamily:'Montserrat',
    display: "flex",
    margin: "20px 0",
    padding: 25,
    borderRadius: 12,
    boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.2)",
  },
  flexSize: {
    fontFamily:'Montserrat',
    flexBasis: "50%",
    width: "50%"
  },
  headingInput: {
    fontFamily:'Montserrat',
    color: "#334155",
    marginBottom: 10,
    fontSize: "18px",
    fontWeight:600
  },
  headingOptionInput: {
    fontFamily: "Montserrat",
    color: colors().black,
    marginBottom: 10,
    fontSize: "18px",
    fontWeight:600
  },
  inputFieldText: {
    fontFamily:'Montserrat',
    width: "90%",
    border: "1px solid #ececec",
    borderRadius: 8,
    padding: "9px 18px",
    outline: "none",
    "&:hover": {
      border: "1px solid #ececec",
    },
    "&:focus": {
      border: "1px solid #ececec",
    },
    "@media only screen and (max-width: 1024px)": {
      fontFamily: "Montserrat",
      height: "44px"
  },
  },
  innerInput: {
    fontFamily:'Montserrat',
    width: "100%",
  },
  innerItem: {
    fontFamily:'Montserrat',
    marginTop: 25,
    width: "90%",
    "& .MuiAutocomplete-root": {
      marginTop: 10,
      maxWidth: "unset !important",
      minWidth: "unset !important",
      "& .MuiInputBase-root": {
        padding: "4px",
        "@media only screen and (max-width: 1024px)": {
            maxHeight: "unset !important",
        }
      }
  }
  },
  errorColor: {
    borderColor: "red !important"
  },
  messageError: {
    color: "red",
    fontSize: "14px",
    marginTop: "4px"
  },
  groupBtnStyle: {
    marginBottom: 100,
    fontWeight:700
  },
  errorMessage: {
    color: "red",
    fontSize: 14,
    marginTop: 2
  },
  mainComp:{
    maxHeight: 200,
    width: '100%',
  }
});
// Customizable Area End

export class EditSpecification extends EditSpecificationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  errorMessage(error: string | undefined, touch: boolean | undefined) {
    if (error) {
      if (touch) {
        return <p className={this.props.classes.errorMessage}>{error}</p>;
      } else return null;
    } else return;
  }

  checkError(error: string | undefined, touch: boolean | undefined) {
    if (error && touch) {
      return true
    }
  }

  render() {
   
    const { itemCountNumber,  firstInputName, showErrorMessage, 
         showSectionError,
      showServiceError, openSnackbar, message,itemsNew,itemsData,severity
    } = this.state
    const { classes } = this.props;
    const inputPlaceholder = `Specification Option ${itemCountNumber}`;
    const firstNameInputClass = showErrorMessage && firstInputName === '' && `${classes.errorColor}`;

    return (
      <>
        <CustomSnackbar
          data-test-id='snackbar-specification'
          open={openSnackbar}
          onClose={this.handleCloseSnackbar}
          errorMessage={message}
          severity={severity as Color}
        />
         <CustomLoader loading={this.state.isLoading} /> 
          <Formik
            data-test-id="formikEditSpecification"
            initialValues={this.state.initialValues}
            onSubmit={() => { }}
            enableReinitialize
          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              setFieldValue,
              getFieldProps
            }) => (
              <form onSubmit={handleSubmit} data-test-id="addUpchargeForm">
                <Box className={classes.specificationEditContainer}>
                  <Typography variant="h2" className={classes.editTitle}>
                    {configJSON.editSpecificationTitle}
                  </Typography>
                  <Box className={classes.innerContainerItem}>
                    <Box className={`${classes.flexSize} ${classes.left}`}>
                      <Box className={classes.innerInput}>
                        <Typography variant="h4" className={classes.headingInput}>
                          {configJSON.specificationFirstInput}
                        </Typography>
                        <Input
                          data-test-id="first-specification-name"
                          type="text"
                          className={`${classes.inputFieldText} ${firstNameInputClass}`}
                          placeholder="Enter Name"
                          disableUnderline
                          value={firstInputName}
                          onChange={this.handleFirstNameChange}
                        />
                        {showErrorMessage && firstInputName === '' && <p className={classes.messageError}>{configJSON.firstNameSpecificationError}</p>}
                      </Box>
                      <Box className={classes.innerItem}>
                        <Typography variant="h4" className={classes.headingInput} style={{marginBottom: 0}}>
                          Section
                        </Typography>
                        <CustomSearchableMultiSelect
                          id="section"
                          name="section"
                          dataTestId="sectionDropdown"
                          checkSelectAllText={() => {}}
                          value={this.state.selectedSections}
                          optionList={this.state.sectionOptions}
                          placeHolderText="Select Section"
                          emptyDataMessage="No Section Found"
                          changeInputOnClear
                          handleScroll={this.handleScrollSectionDropdown}
                          handleClickOnSelectAll={() => {}}
                          handleEmptyAutoSelectValue={() => {}}
                          isOnBlurFunction={() => this.debouncedFunction("", this.handleSectionAutoCompleteChange)}
                          debouncedFunction={(getValue: string) => { this.debouncedFunction(getValue, this.handleSectionAutoCompleteChange) }}
                          handleSelectOptions={(value: { id: string | number, option: string }[], option: { id: string | number, option: string }) => { this.handleSectionMultiSelect(value, option, setFieldValue) }}
                          selectedOptionForList={this.state.selectedSectionsForList}
                          isError={this.checkError(errors.section, touched.section)}
                        />
                        {showSectionError && <p className={classes.messageError}>{configJSON.sectionErrorMessage}</p>}
                      </Box>
                      <Box className={classes.innerItem}>
                        <Typography variant="h4" className={classes.headingInput} style={{marginBottom: 0}}>
                          Product
                        </Typography>
                        <CustomSearchableMultiSelect
                          dataTestId="productDropdowns"
                          id="product"
                          name="product"
                          value={this.state.selectedForAdd}
                          optionList={this.state.productListItemsDropdown}
                          checkSelectAllText={(updatedValue: { id: string | number, option: string }[]) => { this.checkSpecificationSelectAllText(updatedValue); setFieldValue('product', updatedValue) }}
                          handleClickOnSelectAll={() => { this.handleSpecificationClickOnSelectAll(); setFieldValue('product', ["All"]) }}
                          handleScroll={this.handleSpecificationScrollProductDropdown}
                          placeHolderText={configJSON.selectProductText}
                          emptyDataMessage={configJSON.noProductText}
                          handleSelectOptions={(value: { id: string | number, option: string }[], option: { id: string | number, option: string }) => { this.handleSpecificationSelectOptions(value, option, setFieldValue) }}
                          style={{ maxWidth: "100%", marginTop: 12 }}
                          handleEmptyAutoSelectValue={() => { this.handleSpecificationEmptyAutoSelectValue() }}
                          debouncedFunction={(getValue: string) => { this.debouncedFunction(getValue, this.handleSpecAutoCompltProductChange) }}
                          selectedOptionForList={this.state.editSpecSelectedOptionForList}
                          isError={this.checkError(errors.product, touched.product)}
                        />
                        {this.errorMessage(errors.product, touched.product)}
                      </Box>
                      <Box className={classes.innerItem}>
                        <Typography className={`${classes.headingInputOption}`} variant="h2">{configJSON.specificationOption}</Typography>
                        <CustomItemList
                          newItem={itemsNew}
                          items={itemsData}
                          inputPlaceholder={inputPlaceholder}
                          handleChange={this.handleChangeAddInput}
                          handleClick={this.handleAdItemsClick}
                          handleDelete={this.handleDeleteItem}
                        />
                      </Box>
                    </Box>
                    <Box className={`${classes.flexSize} ${classes.right}`}>
                      <Box className={classes.innerInput}>
                        <Typography variant="h4" className={classes.headingInput}>
                          {configJSON.specificationSecondInput}
                        </Typography>
                        <Input
                          dir="rtl"
                          data-test-id="second-specification-name"
                          type="text"
                          className={`${classes.inputFieldText}`}
                          placeholder="Enter Arabic Name"
                          disableUnderline
                          value={this.state.secondInputName}
                          onChange={this.handleSecondNameChange}
                        />
              
                      </Box>
                      <Box className={classes.innerItem}>
                        <Typography variant="h4" className={classes.headingInput} style={{marginBottom: 0}}>
                          Service
                        </Typography>
                        <CustomSearchableMultiSelect
                          id="service"
                          name="service"
                          dataTestId="serviceDropdown"
                          checkSelectAllText={() => {}}
                          value={this.state.selectedServices}
                          optionList={this.state.serviceOptions}
                          placeHolderText="Select Service"
                          handleScroll={this.handleScrollServiceDropdown}
                          handleSelectOptions={(value: { id: string | number, option: string }[], option: { id: string | number, option: string }) => { this.handleSelectServiceOptions(value, option, setFieldValue) }}
                          handleClickOnSelectAll={() => {}}
                          isOnBlurFunction={() => this.debouncedFunction("", this.handleServiceAutoCompleteChange)}
                          debouncedFunction={(getValue: string) => { this.debouncedFunction(getValue, this.handleServiceAutoCompleteChange) }}
                          changeInputOnClear
                          emptyDataMessage="No Service Found"
                          handleEmptyAutoSelectValue={() => { }}
                          selectedOptionForList={this.state.selectedServicesForList}
                          isError={this.checkError(errors.service, touched.service)}
                        />
                        {showServiceError && <p className={classes.messageError}>{configJSON.serviceErrorMessage}</p>}
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <div className={`group-button ${classes.groupBtnStyle}`}>
                  <button onClick={this.handleCancelSpecification} data-test-id="cancel-button-text" className={`cancel ${classes.cancelBtn}`}>Cancel</button>
                  <button data-test-id="add-button-text" onClick={this.handleClickSave}  className={`add ${classes.cancelBtn}`}>Save</button>
                </div>
              </form>
            )}
          </Formik>
      </>
    );
  }
  // Customizable Area End
}
export default withStyles(useStyles)(EditSpecification);

