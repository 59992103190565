import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall } from "../../../components/src/common";
import { getStorageData } from "../../../framework/src/Utilities";
import React from "react";
import { FormikProps } from 'formik';
import { debounce, uniqBy } from "lodash";

interface AccountAttributes {
  id: number;
  first_name: string;
}

export interface DriverDropdown {
  id: string;
  type: string;
  attributes: AccountAttributes;
}

export interface FormValues {
  transfer_id: string;
  date: string;
  shopkeeperName: string;
  opening_cash_date: string;
  station: string;
  opening_cash: string;
  petty_cash: string;
  cash_from_orders: string;
  cash_in: string;
  cash_out: string;
  amount: string;
  variance: string;
  driver_account_id: string;
  store_id: string;
  none: string;
}

export interface StoreManagementDropdown {
  id: string;
  type: string;
  attributes: {
    id: number;
    store_name: string;
    name_translation: string;
  };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleCancel: Function;
  userName: string
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  driverData: DriverDropdown[];
  loading: boolean;
  snackbar: {
    open: boolean,
    severity: "error" | "success" | "info" | "warning" | undefined,
    message: string
  },
  storeListData: StoreManagementDropdown[];
  storePageNo: number;
  autoCompleteSearchValue: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddCashUpController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getTransferApiCallId: string = "";
  getMyStoreCallId: string = "";
  getDriverCallId: string = "";
  createCashUpCallID: string = "";
  getCashFromOrderCallId: string = "";
  disableLoadMoreStore = false;
  itemsPerPage = 10;
  formikRef = React.createRef<FormikProps<FormValues>>();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      driverData: [],
      loading: false,
      snackbar: {
        open: false,
        severity: 'info',
        message: ''
      },
      storeListData: [],
      storePageNo: 1,
      autoCompleteSearchValue: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleResForGetTransferId(from,message)
    this.handleResForGetUserData(from,message)
    this.handleResForGetDriver(from,message)
    this.handleResForCreateCashUp(from,message)
    this.handleResForGetCashFromOrder(from,message)
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getMyStore()
    this.getDriver()
    this.getTransferId()
    this.setUserName()
  }

  setUserName = () => {
    let dataName = this.props.userName
    if (this.formikRef.current) {
      if (this.formikRef.current) {
        this.formikRef.current.setFieldValue('shopkeeperName', dataName);
      }
    }
  }

  createCashUp = async (dataObject: FormValues) => {
    this.setState({ loading: true})
    let headers = {
      token: await getStorageData('token'),
      "Content-Type": configJSON.validationApiContentType,
    };

    let body = {
      "transfer_id": dataObject.transfer_id,
      "driver_account_id": dataObject.driver_account_id,
      "station": dataObject.station,
      "opening_cash_date": dataObject.opening_cash_date,
      "opening_cash": dataObject.opening_cash,
      "petty_cash": dataObject.petty_cash,
      "cash_from_orders": dataObject.cash_from_orders,
      "cash_in": dataObject.cash_in,
      "cash_out": dataObject.cash_out,
      "amount": dataObject.amount,
      "store_id": dataObject.store_id,
      "request_page": "store"
    }

    const requestMessage = apiCall({
      httpBody: body,
      header: headers,
      url: configJSON.createCashUpEndpoint,
      httpMethod: configJSON.exampleAPiMethod,
    });

    this.createCashUpCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTransferId = async () => {
    let headers = {
      token: await getStorageData('token'),
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = apiCall({
      httpBody: {},
      header: headers,
      url: configJSON.getTransferIdEndpoint,
      httpMethod: configJSON.validationApiMethodType,
    });

    this.getTransferApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getDriver = async() => {
    let headers = {
      token: await getStorageData('token'),
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = apiCall({
      httpBody: {},
      header: headers,
      url: configJSON.getDriverEndpoint,
      httpMethod: configJSON.validationApiMethodType,
    });

    this.getDriverCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCashFromOrder = async(id:string) => {
    let headers = {
      token: await getStorageData('token'),
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = apiCall({
      httpBody: {},
      header: headers,
      url: `${configJSON.getCashFromOrderEndpoint}?store_id=${id}`,
      httpMethod: configJSON.validationApiMethodType,
    });

    this.getCashFromOrderCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getMyStore = async() => {
    let headers = {
      token: await getStorageData('token'),
      "Content-Type": configJSON.validationApiContentType,
    };
    let autoCompleteSearch = this.state.autoCompleteSearchValue ? `&filter_by[store_name]=${this.state.autoCompleteSearchValue}` : ""
    const requestMessage = apiCall({
      httpBody: {},
      header: headers,
      url: `${configJSON.myStoreEndpoint}&page_no=${this.state.storePageNo}&per_page=${this.itemsPerPage}${autoCompleteSearch}`,
      httpMethod: configJSON.validationApiMethodType,
    });
    this.disableLoadMoreStore = true;
    this.getMyStoreCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResForCreateCashUp = async (from: string, message: Message) => {
    if (this.createCashUpCallID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.status == 200) {
        this.setState({ loading: false, snackbar: { message: responseJson.message, severity: 'success', open: true } })
        setTimeout(() => {
          this.props.handleCancel()
        }, 2000)
      }
    }
  }

  handleResForGetTransferId = async (from: string, message: Message) => {
    if (this.getTransferApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.transfer_id) {
        if(this.formikRef.current){
          this.formikRef.current.setFieldValue('transfer_id', responseJson.transfer_id);
        }
      }
    }
  }

  handleResForGetUserData = async (from: string, message: Message) => {
    if (this.getMyStoreCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        if (this.formikRef.current && responseJson.data.length === 1 && this.state.autoCompleteSearchValue == "") {
          this.formikRef.current.setFieldValue('store_id', responseJson.data[0].id);
          this.getCashFromOrder(responseJson.data[0].id)
        }

        const listData = responseJson.data
        let storeList = this.state.storePageNo === 1 ? listData : [...this.state.storeListData, ...listData]
        if (this.formikRef.current) {
          let selectedValue = this.formikRef.current.values["store_id"]
          let findData = this.state.storeListData.find((value)=>value.id == selectedValue)
          findData && storeList.unshift(findData)
        }
        this.setState({
          storeListData: uniqBy(storeList,"id") as unknown as StoreManagementDropdown[],
        })
        this.disableLoadMoreStore = listData.length < this.itemsPerPage
      }
    }
  }

  handleResForGetDriver = async (from: string, message: Message) => {
    if (this.getDriverCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        if(this.formikRef.current && responseJson.data.length === 1){
          this.formikRef.current.setFieldValue('driver_account_id', responseJson.data[0].id);
        }
        this.setState({ driverData: responseJson.data })
      }
    }
  }

  handleResForGetCashFromOrder = async (from: string, message: Message) => {
    if (this.getCashFromOrderCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.status == 200) {
        if(this.formikRef.current){
          this.formikRef.current.setFieldValue('cash_from_orders', responseJson.amount);
        }
      }
    }
  }

  subtractNumbers = (num1: string, num2: string) => {
    const number1 = parseFloat(num1);
    const number2 = parseFloat(num2);
    const result = number1 - number2;
    return result.toFixed(2);
  }

  handleChangeDeclareCash = (event: React.ChangeEvent<HTMLInputElement>,handleChange:Function,setFieldValue:Function,formValues:FormValues) => {
    let variance = this.subtractNumbers(formValues.cash_from_orders,event.target.value)
    setFieldValue('variance',variance)
    handleChange(event)
  }

  hanldeStoreChange = (value: string) => {
    this.getCashFromOrder(value)
    if(this.formikRef.current){
      this.formikRef.current.setFieldValue('store_id', value);
    }
  }

  convertToOptionObjForDriver(options: DriverDropdown[]) {
    return options.map(option => ({
      id: option.id,
      option: option.attributes.first_name
    }));
  }

  convertToOptionObjForStore(options: StoreManagementDropdown[]) {
    return options.map(option => ({
      id: option.id,
      option: option.attributes.store_name
    }));
  }

  handleCloseSnackbar = () => {
    this.setState({
      snackbar: {
        open: false,
        severity: 'info',
        message: ''
      }
    })
  }

  handleScrollStoreDropdown = (event: React.SyntheticEvent) => {
    if (this.disableLoadMoreStore) return;
    const checkListboxNode = event.currentTarget;
    const boxPosition = checkListboxNode.scrollTop + checkListboxNode.clientHeight;

    if (checkListboxNode.scrollHeight - boxPosition <= 1.30) {
      this.setState((prevState) => ({ storePageNo: prevState.storePageNo + 1 }), () => {
        this.getMyStore()
      })
    }
  };

  handleAutoCompleteChange = (getValue: string) => {
    if (getValue === this.state.autoCompleteSearchValue) return;
    if (this.formikRef.current) {
      this.formikRef.current.setFieldValue('store_id', "");
    }
    if (getValue.length < 1 || getValue.length > 2) {
      this.setState({
        autoCompleteSearchValue: getValue,
        storePageNo: 1,
      }, () => this.getMyStore());
    }
  }

  debouncedFunction = debounce(
    (newInputValue: string, inputFunction: (inputValue: string) => void) =>
      inputFunction(newInputValue),
    700,
    { maxWait: 2000 }
  );

  // Customizable Area End
}
