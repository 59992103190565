import React from "react";
import { styled, StyledComponentProps } from "@material-ui/core";

interface Props {
  minWidth?: number;
  minWidth1024?: number;
  minWidth1280?: number;
  minWidth1366?: number;
  children: React.ReactNode;
}

interface ContainerProps {
  minWidth?: number;
  minWidth1024?: number;
  minWidth1280?: number;
  minWidth1366?: number;
}

function OrderTable(props: Props) {
  const { minWidth, children, minWidth1024, minWidth1280, minWidth1366 } = props;

  return (
    <MaxWidthContainer>
      <Container minWidth={minWidth} minWidth1024={minWidth1024} minWidth1280={minWidth1280} minWidth1366={minWidth1366}>{children}</Container>
    </MaxWidthContainer>
  );
}

const MaxWidthContainer = styled("div")({
  borderRadius: 12,
  // overflowY: "auto",
  boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
  flex: "1 1 0"
});

const Container = styled('div')((props: StyledComponentProps & ContainerProps) => ({
  minWidth: props.minWidth,
  "@media only screen and (max-width: 1366px)": {
    minWidth: props.minWidth1366,
  },
  "@media only screen and (max-width: 1280px)": {
    minWidth: props.minWidth1280,
  },
  "@media only screen and (max-width: 1024px)": {
    minWidth: props.minWidth1024,
  },
}));

export default OrderTable;
