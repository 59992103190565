import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { IMeta, IRoleData, ISnackbar, RolesList, checkFilterSorting } from "./utils";
import { IFilter } from "../../../components/src/FilterPopover";
import { ISortingData } from "../../../components/src/SortingTableHeader";
import { apiCall } from "../../../components/src/common";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import { PermissionStatus, checkForNewPermissonStatus, customPermissionApiKey, checkIsFilterApplied } from "../../../blocks/utilities/src/CustomBlockHelpers";

const emptyRole: IRoleData = {
  id: "",
  type: "role",
  attributes: {
    active: false,
    gallery_icon: {
      id: 0,
      image: "",
    },
    name: "",
    id: 0,
    role_permissions_count: 0,
    permission_groups: [],
    accounts_count: "",
    accounts: [],
  },
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  anchorEl: null | HTMLElement | SVGSVGElement;
  roleId: number | null;
  rolesList: RolesList;
  snackbar: ISnackbar;
  disableModalVisible: boolean;
  pageSize: number;
  popOverOpened: boolean;
  popOverItemId: string;
  popOverItemStatus: string;
  popOverTop: number;
  popOverLeft: number;
  page: number;
  isGridView: boolean;
  filterAnchor: any;
  filters: IFilter[];
  isFilterApplied: boolean;
  sortingData: ISortingData;
  query: string;
  listingShowModalShow: any;
  listingDataShow: any;
  listingModalTitle: string;
  searchTextRole: string;
  isSuperAdmin?: boolean;
  deactivateModalVisible?: boolean;
  roleOptions: IRoleData[];
  roleDeactivateSuccessDialogOpen: boolean;
  roleDeactivateMoved: any;
  permissionStatus: PermissionStatus;
  meta: IMeta;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RolesPermissions2ControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getRoleListApiId: string;
  getRoleOptionListApiId: string;
  deactivateRoleApiId: string;
  rolesSuggestionApiCallId: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.getRoleListApiId = "";
    this.getRoleOptionListApiId = "";
    this.deactivateRoleApiId = "";
    this.rolesSuggestionApiCallId = "";
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.SearchTextMessage),
      getName(MessageEnum.LayoutDataMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      anchorEl: null,
      // Customizable Area Start
      filterAnchor: false,
      roleId: null,
      rolesList: { data: [] },
      snackbar: {
        open: false,
        message: "",
        severity: "info",
      },
      disableModalVisible: false,
      pageSize: 10,
      popOverOpened: false,
      popOverItemId: "",
      popOverItemStatus: "",
      popOverTop: 0,
      popOverLeft: 0,
      page: 0,
      isGridView: true,
      listingShowModalShow: false,
      listingModalTitle: "",
      listingDataShow: [],
      filters: [
        {
          title: "Permission",
          type: "select",
          options: [
            {
              label: "Maximum To Minimum",
              value: "DESC",
            },
            {
              label: "Minimum To Maximum",
              value: "ASC",
            },
          ],
          value: "",
        },
        {
          title: "Role",
          type: "autocompolete",
          value: "",
          options: [],
        },
      ],
      isFilterApplied: false,
      sortingData: {
        name: "",
        icon_id: "",
        permission_group_ids: "",
        user: "",
      },
      query: "",
      searchTextRole: "",
      roleOptions: [],
      roleDeactivateSuccessDialogOpen: false,
      roleDeactivateMoved: false,
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      },
      meta: {
        current_page: 1,
        next_page: 1,
        pervious_page: 1,
        total_count: 1,
        total_pages: 1,
      },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      this.handleResponse(apiRequestCallId, message);
    }

    this.receiveDataFromLayout(message);
    this.receiveSearchTextData(message)
    // Customizable Area End
  }

  // Customizable Area Start

  receiveSearchTextData = (message: Message) => {
    if (message.id === getName(MessageEnum.SearchTextMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.SearchMessageText)
        );
        if (recievedData) {
          this.handleSearchRole(recievedData.searchText)
        }
    }
  }
  receiveDataFromLayout = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.LayoutMessageData)
        );
        if (recievedData.userContext) {
          this.handleUserChange(recievedData.userContext)
        }
    }
  }

  processMeta = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.setState({
      rolesList: {
        data: responseJson.data 
      },
      meta: responseJson.meta
    });
  };

  async componentDidMount() {
    this.handleStorageFilter()
  }

  handleResponse = (apiRequestCallId: string, message: Message) => {
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (apiRequestCallId && responseJson && !errorReponse) {
      if (responseJson.errors) {
        this.setState({
          snackbar: {
            open: false,
            message: responseJson.errors[0]?.message,
            severity: "error",
          },
        });
        return;
      }
      switch (apiRequestCallId) {
        case this.getRoleListApiId:
          if (responseJson.meta) {
            this.processMeta(message);
          }
          break;
        case this.getRoleOptionListApiId:
          this.setState({ roleOptions: responseJson.data });
          break;
        case this.rolesSuggestionApiCallId:
          const list = responseJson?.filter_names?.map((value: string) => ({
            label: value,
            value,
          })) || [];
          const updatedFilters = this.state.filters.map((item: IFilter) => {
            this.checkRolesforOption(item, list)
            return item;
          });
          this.setState({ filters: updatedFilters });
          break;
        case this.deactivateRoleApiId:
          this.getRoleList();
          if(responseJson?.data?.attributes?.active){
            this.setState({
              snackbar: {
                open: true,
                severity: "success",
                message: configJSON.textRoleActivated
              },
            });
          } else {
            this.setState({roleDeactivateSuccessDialogOpen: true})
          }
          
          break;
        default:
          break;
      }
    } else if (errorReponse) {
      this.setState({
        snackbar: {
          open: false,
          message: errorReponse?.errors[0]?.message,
          severity: "error",
        },
      });
    }
  };

  checkRolesforOption = (item:any, list:any) => {
    if(item.title === "Role") {
      item.options = list;
    }
  }

  handleMenuOpen = (
    event: React.MouseEvent<SVGSVGElement>,
    roleId: number
  ) => {
    this.setState({
      anchorEl: event.currentTarget,
      roleId: roleId,
      popOverItemStatus: this.state.rolesList.data.find(
        (role) => role.attributes.id === roleId
      )?.attributes.active
        ? "Active"
        : "Deactive",
      popOverItemId: roleId.toString(),
    });
  };
  handleMenuClose = () => {
    this.setState({ anchorEl: null, roleId: null });
  };
  createNavigation = () => {
    this.props.navigation.navigate("RolesPermissionsCreate2");
  };
  viewNavigation = () => {
    this.props.navigation.navigate("RolesPermissionsView2", {
      roleId: this.state.roleId,
    });
  };
  editNavigation = () => {
    this.props.navigation.navigate("RolesPermissionsEdit2", {
      roleId: this.state.roleId,
    });
  };
  deActivateHandler = (roleId: string) => {
    this.handleMenuClose();
    this.changeActivateRole("deactivate", roleId);
    this.setState({ disableModalVisible: false, anchorEl: null, roleDeactivateMoved: !!roleId });
  };

  handleGoToListing = () => {
    this.setState({roleDeactivateSuccessDialogOpen: false});
  }

  handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    this.setState({ snackbar: { open: false, message: "", severity: "info" } });
  };

  // API
  getRoleList = () => {
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem("token"),
    };
    const permissionFilter = this.state.filters.find(
      (item) => item.title === "Permission"
    )?.value;
    const roleFilter = this.state.filters.find(
      (item) => item.title === "Role"
    )?.value;
    const searchTextRole = this.state.searchTextRole;

    let apiUrl =
      configJSON.getRoleListUrl +
      "?page_no=" +
      (this.state.page + 1) +
      `&per_page=${this.state.pageSize}` +
      (permissionFilter ? `&filter_by[permission]=${permissionFilter}` : "") +
      (searchTextRole ? `&filter_by[query]=${searchTextRole}` : "") +
      (roleFilter ? `&filter_by[role_name]=${roleFilter}` : "");
      apiUrl += this.state.query;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRoleListApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getRoleListOptions = () => {
    const header: any = {
      token: localStorage.getItem("token"),
      "Content-Type": configJSON.applicationJsonContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRoleOptionListApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRoleListUrl + "?dropdown=true"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  changeActivateRole = (
    path: "deactivate" | "activate",
    otherRoleId: string
  ) => {

    let apiUrl =
      configJSON.deactivateRoleUrl + this.state.popOverItemId + "/" + path;
    if (otherRoleId) apiUrl += "?role_id=" + otherRoleId;
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deactivateRoleApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleFilterAutoCompleteChange = (filterName: any, query: any) => {
    this.state.filters.forEach((item: IFilter) => {
      if (item.title === "Role") item.value = query;
      return item;
    });

    let headers: any = {
      "Content-type": "application/json", 
      token: localStorage.getItem("token")
    }

    let url: any;
    url = configJSON.rolesSuggestionApiEndPoint + `?role_name=${query}`;

    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.getMethodType,
    });

    this.rolesSuggestionApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  handleDeactivateClose = () => {
    this.setState({ disableModalVisible: false, anchorEl: null });
  };

  handleDeactivate = () => {
    this.setState({ disableModalVisible: true, popOverOpened: false });
  };

  handleActivate = () => {
    this.setState({popOverOpened: false})
    this.changeActivateRole("activate", "");
  };

  createData(
    name: string,
    icon: string,
    permissions: any,
    status: string,
    id: string,
    users: string,
    role_permissions_count: number,
    accounts: any
  ) {
    return { name, icon, permissions, status, id, users, accounts, role_permissions_count };
  }

  handleEditRole = (roleId: string) => {
    this.setState({ popOverOpened: false });
    this.props.navigation.navigate("RolesPermissionsEdit2", { roleId });
    window.localStorage.setItem("roleId", roleId);
  };

  handleViewRole = (roleId: string) => {
    this.setState({ popOverOpened: false });
    window.localStorage.setItem("roleId", roleId);
    this.props.navigation.navigate("RolesPermissionsView2", { roleId });
  };
  handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => {
    if (page !== this.state.page) {
      this.setState({ page }, this.getRoleList);
    }
  };
  handleGridView = (isGridView: boolean) => {
    this.setState({ isGridView });
  };

  handleSearchRole = (value: string) => {
    this.setState(
      {
        searchTextRole: value,
      },
      this.getRoleList
    );
  };

  handleFilterChange = (filters: IFilter[]) => {
    const isFilterApplied = checkIsFilterApplied(filters)
    if (isFilterApplied) {
      localStorage.setItem(configJSON.rolesPermissionsFilterStorageKey, JSON.stringify(filters));
    } else {
      localStorage.removeItem(configJSON.rolesPermissionsFilterStorageKey);
    };
    this.setState(
      {
        filters,
        isFilterApplied,
        sortingData: {
          ...this.state.sortingData,
          permission_group_ids: checkFilterSorting(filters, 0),
        },
      },
      this.getRoleList
    );
  };

  handleReturnColorType = () => this.state.isFilterApplied ? "primary" : "inherit";

  handleStorageFilter = () => {
    const token = localStorage.getItem("token");
    const appliedRolesPermissionsFilter = localStorage.getItem(
      configJSON.rolesPermissionsFilterStorageKey
    );
    if (appliedRolesPermissionsFilter) {
      this.setState(
        {
          filters: JSON.parse(appliedRolesPermissionsFilter),
          isFilterApplied: checkIsFilterApplied(
            JSON.parse(appliedRolesPermissionsFilter)
          ),
        },
        () => {
          token && this.getRoleList()
        }
      );
    } else {
      token && this.getRoleList();
    }
  };

  listProps = {
    onClick: () => this.handleGridView(false),
  };
  gridProps = {
    onClick: () => this.handleGridView(true),
  };

  handleQueryChange = (query: string) => {
    this.setState({ query }, () => this.getRoleList());
  };

  sortingProps = {
    onChange: (sortingData: ISortingData) => this.setState({ sortingData }),
    onQueryChange: (query: string) => this.handleQueryChange(query),
    width: "22%",
  };

  handleSetFilterDataOnSortClick = (updatedFilters: IFilter[]) => {
    this.setState({ filters: updatedFilters });
  };

  handleCloseListingModal = () => {
    this.setState({
      listingShowModalShow: false,
    });
  };

  handleOpenListingModal = (modalTitle: string, rowdata: any) => {
    this.setState({
      listingModalTitle: modalTitle,
      listingDataShow: rowdata,
      listingShowModalShow: true,
    });
  };

  handleUserChange = (userContext: IUserContext) => {
    this.setState({ isSuperAdmin: userContext.isSuperAdmin });
    const apiKey = customPermissionApiKey.rolePermission;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    })
  };

  handleActivateDeactivate = () => {
    if(this.state.popOverItemStatus === "Active") { 
      this.setState({ disableModalVisible: true });
      this.getRoleListOptions();
    } else {
      this.changeActivateRole("activate", "");
      this.setState({anchorEl: null})
    }
  }
  // Customizable Area End
}
