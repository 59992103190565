import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  TablePagination,
  Divider,
  CircularProgress,
  styled,Typography,
} from "@material-ui/core";
import {
    MainButtonComponent,
  } from "../../../components/src/customComponents/CustomTags.web";
const IconSortingUp = require("../../../components/src/assets/sort/sort top.png");
const IconSortingNormal = require("../../../components/src/assets/sort/sort.png");
const IconSortingDown = require("../../../components/src/assets/sort/sort down.png");
import ViewCustomerController, {
  Props,
  configJSON,
} from "./ViewCustomerController.web";
import { colors } from "../../../blocks/utilities/src/Colors";
import { renderBaseOnConditions } from "../../../blocks/utilities/src/CustomBlockHelpers";
import {
    TableRow,
    TableCell,
    OrderTable,
    RowContainer,
  } from "../../../components/src/OrderTable/src";
  const Strings = configJSON.strings.B2bCustomerGroupList;
import { LoadingComponent } from "../../../components/src/Loader.web";
// Customizable Area End

export default class ViewCustomerGroup extends ViewCustomerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  handleTableNullValue = (value: string | number | null) => {
    if (value) {
      return value;
    } else {
      return <>---</>;
    }
  };
  // Customizable Area End
  render() {
    // Customizable Area Start

    const {
      page,
      pageSize,
      sortingData,
      isUsersListLoading,
      customerData,
      isLoadingPermission,
      permissionStatus,
     } = this.state;

    const filteredRows = this.getFilteredRows();
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
          <StyledBoxWrapper>
            <Box>
              <div style={webStyle.headerWrapper}>
                <div className={"pageHeaderTitle"} data-test-id='user_account'>
                Customer Group
                </div>
                <div style={webStyle.headerButtonPartAccount}>
                <LoadingComponent
                  isLoading={isLoadingPermission}
                  height={56}
                  width={184}
                  isAllowed={permissionStatus.editPermission}
                  children={
                    <Button
                      data-test-id={"editAccount"}
                      className={"addButton"}
                      onClick={() => this.handleNavigateToEdit()}
                    >
                      {Strings.edit}
                    </Button>
                  }
                />
                </div>
              </div>
              <Box className="customerDetails">
                <Box>
               <Typography className='detailHead'>Description</Typography>
               <Typography className="descStyle">{customerData.attributes.description}</Typography>
               </Box><Divider/>
               <Box className="detailsSection">
               <Box className="subSection">
                <Typography className='detailHead'>Customer Group Name</Typography>
                <Typography className="descStyle">{customerData.attributes.group_name}</Typography>
               </Box>
               <Box className="subSection">
                <Typography className='detailHead'>Company</Typography>
                <Typography className="descStyle">{customerData.attributes.company.name}</Typography>
               </Box>
               </Box>
               <Box className="detailsSection">
               <Box className="subSection">
                <Typography className='detailHead'>Designation</Typography>
                <Typography className="descStyle">{customerData.attributes.designation}</Typography>
               </Box>
               <Box className="subSection">
                <Typography className='detailHead'>No. of Items per Wash</Typography>
                <Typography className="descStyle">{customerData.attributes.no_of_items_per_wash}</Typography>
               </Box>
               </Box>
               <Box>
               <Typography className='detailHead'>No. of Pieces per Order</Typography>
               <Typography className="descStyle">{customerData.attributes.no_of_pieces_per_item}</Typography>
               </Box>
              </Box>
              <Box className={"table-container"}>
            <OrderTable>
              <RowContainer header>
                <TableRow columns={"1fr 1fr 1fr"}>
                  {[
                    {
                      title: Strings.name,
                      sortingKey: "name",
                    },
                    {
                      title: Strings.email,
                      sortingKey: "email",
                    },
                    {
                      title: Strings.phone,
                      sortingKey: "phone",
                    },
                  ]
                    .filter((item: { title: string }) => {
                      return item.title;
                    })
                    .map(
                      ({
                        title,
                        sortingKey,
                      }: {
                        title: string;
                        sortingKey?: string;
                      }) => (
                        <TableCell
                            header
                            sortingData={sortingData}
                            content={title}
                            className='align-item-center'
                            sortingIcons={[
                            IconSortingNormal,
                            IconSortingUp,
                            IconSortingDown,
                            ]}
                            sortingKey={sortingKey}
                            sortingDisabled={false}
                            {...this.sortingProps}
                        />
                      )
                    )}
                    
                </TableRow>
              </RowContainer>
              {renderBaseOnConditions(
                isUsersListLoading,
                <RowContainer>
                  <Box className={"tableBodyLoader"}>
                    <CircularProgress />
                  </Box>
                </RowContainer>,
                <RowContainer>
                  {!isUsersListLoading && filteredRows.length>0&& filteredRows.map((rowData) => {
                      return (
                        <TableRow
                          key={rowData.attributes.id}
                          className={"table-rows"}
                          columns={"1fr 1fr 1fr"}
                        >
                          {[
                            this.handleTableNullValue(rowData.attributes.name),
                            this.handleTableNullValue(rowData.attributes.email),
                            this.handleTableNullValue(rowData.attributes.full_phone_number),
                          ].map((item, index) => {
                            return (
                              <TableCell
                                key={`cell-${index}`}
                                style={{ wordBreak: "break-all" }}
                                content={item as React.ReactNode}
                                className={"f-12"}
                                type={this.getTypeOfOrderTable(index)}
                              />
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </RowContainer>
              )}
              {!isUsersListLoading && 
                  filteredRows.length === 0 && (
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      fontWeight: 601,
                      fontSize: 16,
                    }}
                  >
                    No records found!!
                  </div>
                )}
            </OrderTable>
          </Box>
          <Box className="backPagBox">
          <MainButtonComponent
              data-test-id={"btn-cancel"}
              className={"buttonSecondary"}
              onClick={() => this.goToPreviousPage()}
            >
              {Strings.back}
            </MainButtonComponent>
              {filteredRows.length > 0 && (
                <TablePagination
            onPageChange={(_event, page) => this.handlePageChange(page)}
            count={this.state.total}
            component={"div"}
            data-test-id={"pagination"}
            page={this.state.page}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[5]}
            className={"tablePaginationBox"}
          />
              )}
              </Box>
            </Box>
           
          </StyledBoxWrapper>
          
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  table: {
    minWidth: 700,
  },
  headerWrapper: {
    display: "flex",
    flexWrap: "wrap" as "wrap",
    justifyContent: "space-between",
    marginBottom: 32,
    alignItems: "center",
  },
  headerButtonPartAccount: {
    display: "flex",
  },
};


const StyledBoxWrapper = styled(Box)({
  paddingTop: "25px",
  fontFamily: "Montserrat",
  "& .pageHeaderTitle": {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "29px",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
    },
  },
  "& .filterButtonAccount": {
    position: "relative",
    marginRight: "24px",
    cursor: "pointer",
    background: colors().white,
    height: "56px",
    color: colors().darkliver,
    width: "56px",
    fontSize: "24px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid",
    borderColor: colors().lightborder,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
      width: "44px",
      height: "44px",
    },
  },
  "& .active-border" : {
    borderColor: colors().cyancobaltblue,
  },
  "& .filterActiveIcon": {
    position: "absolute",
    top: "-5px",
    right: "-5px",
    minWidth: "15px",
    background: "#204B9C",
    minHeight: "15px",
    borderRadius: "50%"
  },
  "& .addButton": {
    background: colors().cyancobaltblue,
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: colors().white,
    height: "56px",
    width: "184px",
    borderRadius: "8px",
    textTransform: "unset",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "13px",
      width: "160px",
      height: "44px",
    },
  },
  "& .customerDetails":{
    width:"100%",
    height:"400px",
    boxShadow: "0px 8px 32px 0px #0000000F !important",
    borderRadius:"12px",
    padding:"32px",
    display:'flex',
    flexDirection:"column",
    gap:'32px'
  },
  "& .detailHead":{
   color:"#4D4D4D",
   fontFamily: "Montserrat",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "22px"
  },
  "& .descStyle":{
    color:"#1A1A1A",
    fontFamily: "Montserrat",
     fontSize: "16px",
     fontWeight: 500,
     lineHeight: "22px"
   },
   "& .detailsSection":{
    display:'flex',
    gap:'24px'
   },
   '& .subSection':{
    width:"50%",
    textAlign:"left"
   },
   "& .tablePaginationBox": {
    width: "100%",
    padding: "8px 0",
  },
  "& .backPagBox":{
    display:"flex",
    alignItems:'center'
  }
});

// Customizable Area End