import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  InputLabel,
  Typography,
  Grid,
  Hidden,
  styled
} from "@material-ui/core";

import { renderBaseOnConditions } from "../../../blocks/utilities/src/CustomBlockHelpers";


import CustomerViewController, {
  Props,
  configJSON
} from "./CustomerViewController.web";
import { CustomPaper } from '../../../components/src/customComponents/CustomTags.web';
import { viewProductDetailsWebStyle } from "../../CfPriceList/src/CfViewProductDetails.web";
import { priceListWebStyle } from "../../CfPriceList/src/CfPriceList.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

export default class CfViewProductDetails extends CustomerViewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  setCustomerData(data: any) {
    if (data !== undefined && data !== null && data !== "") {
      return <StyledTypography>{data}</StyledTypography>
    } else {
      return <StyledTypography>--</StyledTypography>
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const isMasterPriceList = this.state.customerData.attributes.customer_profile.data.attributes.price_list.data?.attributes?.is_master_price_list 
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box style={priceListWebStyle.mainWrapper}> 

          <Box style={{ ...priceListWebStyle.contentWrapper }}>
            {renderBaseOnConditions(this.state.permissionStatus.editPermission, <div style={priceListWebStyle.headerWrapper}>
              <PageTitle style={priceListWebStyle.pageHeader}>{configJSON.txtCustomer}</PageTitle>
              <StyledButton
                data-test-id="editBtn"
                onClick={() => {this.handleEditCustomer(this.state.customerData.id)}}
              >
                {configJSON.btnTxtEdit}
              </StyledButton>
            </div>,<></>)}
            {this.state.isLoading ? <Loader loading={this.state.isLoading}/> : <>
            <CustomPaper style={viewProductDetailsWebStyle.paper}>
              <Grid container spacing={3} id="modal-content">
                <Grid item xs={12} sm={12} md={6} data-test-id="txtName">
                  <StyledInputLabel>{configJSON.txtLblName}</StyledInputLabel>
                  {this.setCustomerData(this.state.customerData?.attributes?.full_name)}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>{configJSON.txtLblMobileNumber}</StyledInputLabel>
                  {this.setCustomerData(this.state.customerData?.attributes?.phone_number?.toString())}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>{configJSON.txtLblEmail}</StyledInputLabel>
                  {this.setCustomerData(this.state.customerData?.attributes?.email)}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>{configJSON.txtLblRegion}</StyledInputLabel>
                  {this.setCustomerData(this.state.customerData?.attributes?.customer_profile.data.attributes.region.data?.attributes.name)}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>{configJSON.txtLblGender}</StyledInputLabel>
                  {this.setCustomerData(this.state.customerData?.attributes?.customer_profile?.data?.attributes?.gender_id?.label)}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>{configJSON.txtLblBirthday}</StyledInputLabel>
                  {this.setCustomerData(this.state.customerData?.attributes?.date_of_birth)}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>{configJSON.txtLblProfession}</StyledInputLabel>
                  {this.setCustomerData(this.state.customerData?.attributes?.customer_profile?.data?.attributes?.profession?.data?.attributes?.profession_name)}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>{configJSON.txtLblOrganization}</StyledInputLabel>
                  {this.setCustomerData(this.state.customerData?.attributes?.customer_profile?.data?.attributes?.organization?.data?.attributes?.name)}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>{configJSON.txtLblOrganizationTax}</StyledInputLabel>
                  {this.setCustomerData(this.state.customerData?.attributes?.customer_profile?.data?.attributes?.organisation_tax?.data?.attributes?.tax_number)}
                </Grid>
              </Grid>
            </CustomPaper>
            <CustomPaper>
              <Grid container spacing={3} id="modal-content">
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>{configJSON.txtLblAddress}</StyledInputLabel>
                  {this.setCustomerData(this.state.customerData.attributes.user_addresses.data?.[0]?.attributes?.address)}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>{configJSON.txtLblCity}</StyledInputLabel>
                  {this.setCustomerData(this.state.customerData.attributes.user_addresses.data?.[0]?.attributes?.city?.name_en)}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>{configJSON.txtLblPostalCode}</StyledInputLabel>
                  {this.setCustomerData(this.state.customerData.attributes.user_addresses.data?.[0]?.attributes?.post_code)}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>{configJSON.txtLblId}</StyledInputLabel>
                  {this.setCustomerData(this.state.customerData?.attributes?.customer_profile?.data?.attributes?.customer_id)}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>{configJSON.txtLblBusiness}</StyledInputLabel>
                  {this.setCustomerData(this.state.customerData?.attributes?.customer_profile?.data?.attributes?.business_id?.label)}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>{configJSON.txtLblPriceList}</StyledInputLabel>
                  {this.setCustomerData(this.state.customerData?.attributes?.customer_profile?.data?.attributes?.price_list?.data?.attributes?.name)}
                </Grid>
              {!isMasterPriceList
              &&(
                <>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>{configJSON.txtLblPriceListExpiryDate}</StyledInputLabel>
                  {this.setCustomerData(this.getPriceListExpiryDate(this.state.customerData?.attributes?.customer_profile?.data?.attributes?.price_list_expiry_date))}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>{configJSON.txtLblPriceListRegionView}</StyledInputLabel>
                  {this.setCustomerData(this.state.customerData?.attributes?.customer_profile?.data?.attributes?.store_management?.region?.name)}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>{configJSON.txtLblPriceListAreaView}</StyledInputLabel>
                  {this.setCustomerData(this.state.customerData?.attributes?.customer_profile?.data?.attributes?.store_management?.area?.name)}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>{configJSON.txtLblPriceListStoreView}</StyledInputLabel>
                  {this.setCustomerData(this.state.customerData?.attributes?.customer_profile?.data?.attributes?.store_management?.store_name)}
                </Grid>
                </>
                )}
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>{configJSON.txtLblPaymentType}</StyledInputLabel>
                  {this.setCustomerData(this.state.customerData?.attributes?.customer_profile?.data?.attributes?.payment?.name)}
                </Grid>
                <Hidden xsDown smDown>
                  <Grid item md={6} lg={6} xl={6} >
                  </Grid>
                </Hidden>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>{configJSON.txtLblNote}</StyledInputLabel>
                  {this.setCustomerData(this.state.customerData.attributes.user_addresses.data?.[0]?.attributes?.notes)}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>{configJSON.txtLblPrivateNote}</StyledInputLabel>
                  {this.setCustomerData(this.state.customerData?.attributes?.customer_profile?.data?.attributes?.private_note)}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>{configJSON.txtLblCompensationCredit}</StyledInputLabel>
                  {this.setCustomerData(this.state.customerData?.attributes?.total_compensation_credit)}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <StyledInputLabel>{configJSON.txtLblLoyaltyPoints}</StyledInputLabel>
                  {this.setCustomerData(this.state.customerData?.attributes?.total_reward_points)}
                </Grid>
              </Grid>
            </CustomPaper></>}
            <Box style={{ padding: '25px 0px' }}>
              <StyledButton
                data-test-id="btnBack"
                onClick={() => {this.handleBackToListing()}}
              >
                Back
              </StyledButton>
            </Box>
          </Box>
        
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const PageTitle = styled("div")({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "24px",
  lineHeight: "29px",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "16px"
  },
});

const StyledInputLabel = styled(InputLabel)({
  fontFamily: "Montserrat",
  fontWeight: 600,
  fontSize: "18px",
  lineHeight: "15.6px",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "16px"
  },
})

const StyledButton = styled(Button)({
  width: "184px",
  height: "56px",
  color: 'rgb(255, 255, 255)',
  background: 'rgb(32, 75, 156)',
  backgroundColor: 'rgb(32, 75, 156)',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: "24px",
  borderRadius: "8px",
  marginLeft: "10px",
  textTransform: "unset",
  
  "&.MuiButton-root:hover": {
    backgroundColor: 'rgb(32, 75, 156)',
  },
  "@media only screen and (max-width: 1024px)": {
    width: "126px !important",
    height: "44px !important",
  },
})

const StyledTypography = styled(Typography)({
  fontSize: '14px',
  fontWeight: 500,
  fontFamily: "Montserrat",
  lineHeight: "24px",
  marginTop: "5px",
  "@media only screen and (max-width: 1024px)": {
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: "15.6px",
  },
})
// Customizable Area End
