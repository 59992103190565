// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IFilter } from "../../../components/src/FilterPopover";

import { ISortingData } from "../../../components/src/SortingTableHeader";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";

export interface InviteFriendInterface {
  id: string;
  type: string;
  attributes: {
    id: number;
    discount_amount: number;
    new_customer_discount: number | null;
    allow_customer_referrals: string;
    activated: boolean;
  };
}
export interface MetaData {
  total_pages?: number;
  total_count?: any;
  current_page?: number;
  next_page?: number;
  pervious_page?: number;
}
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}
// Customizable Area End

export interface S {
  // Customizable Area Start
  drawerWidth: number;
  popOverOpened: boolean;
  popOverInviteId: string;
  popOverItemStatus: string;
  popOverTop: number;
  popOverLeft: number;
  list: InviteFriendInterface[];
  page: number;
  pageSize: number;
  isModalOpen: boolean;
  deactivated: boolean;
  filterAnchor: any;
  filters: IFilter[];
  clickedCustomerType: InviteFriendInterface | undefined;
  sortingData: ISortingData;
  query: string;
  searchText: string;
  metaData: MetaData;
  permissionStatus: PermissionStatus;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CustomerTypeController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCallId: string = "";
  updateCallId: string = "";
  tableRefs: HTMLElement | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.LayoutDataMessage),
      getName(MessageEnum.SearchTextMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      list: [],
      page: 1,
      pageSize: 10,
      popOverInviteId: "",
      popOverItemStatus: "",
      isModalOpen: false,
      deactivated: false,
      drawerWidth: 0,
      popOverOpened: false,
      popOverLeft: 0,
      popOverTop: 0,
      filterAnchor: false,
      clickedCustomerType: undefined,
      searchText: "",
      filters: [],
      sortingData: {
        discount_amount: "",
        allow_customer_referrals: "",
        new_customer_discount: "",
        activated: "",
      },
      query: "",
      metaData: {},
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false,
        activatePermission: false
      }
    };
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.receiveLayoutData(message)
    this.receiveSearchText(message)
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getCallId) {
        if (responseJson) {
          this.setState({
            list: responseJson.data,
            metaData: responseJson.meta,
          });
        }
      } else if (apiRequestCallId === this.updateCallId) {
        this.getList();
        if (responseJson && this.state.popOverItemStatus === "Active") {
          this.setState({ deactivated: true });
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleIsActive = () => {
    this.setState({ popOverItemStatus: "Active" });
  };

  handleDrawerWidthChange = (width: number) => {
    this.setState({ drawerWidth: width });
  };

  getList = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem(configJSON.token),
    };

    const searchText = this.state.searchText;

    const listEndPoint =
      configJSON.inviteFriendsApiEndpoint +
        `?page_no=${this.state.page}&per_page=${this.state.pageSize}` +
      (searchText ? `&filter_by[query]=${searchText}` : "") +
      this.state.query;

    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      listEndPoint
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  updateCustomerType = (customerTypeId: string, isActive: boolean) => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: window.localStorage.getItem(configJSON.token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.inviteFriendsApiEndpoint}/${customerTypeId}/${
        isActive ? "deactivate" : "activate"
      }`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handlePageChange = (page: number) => {
    this.setState({ page }, () => this.getList());
  };

  handleSearch = (value: string) => {
    this.setState(
      {
        page: 1,
        searchText: value,
      },
      this.getList
    );
  };

  handleAdd = () => {
    this.props.navigation.navigate("AddInvitefriends");
  };

  handleEdit = (id: string) => {
    this.setState({ popOverOpened: false });
    window.localStorage.setItem("invite_friend_id", id);
    this.props.navigation.navigate(`EditInvitefriends`, {
      id: id,
    });
  };

  handleView = (id: string) => {
    this.setState({ popOverOpened: false });
    window.localStorage.setItem("invite_friend_id", id);
    this.props.navigation.navigate(`ViewInviteFriends`, {
      id: id,
    });
  }

  handleDeactivate = (id: string) => {
    this.setState({
      isModalOpen: true,
      clickedCustomerType: this.state.list.find((item) => item.id == id),
    });
  };

  handleFilterChange = (filters: IFilter[]) => {
    this.setState(
      {
        filters,
        page: 1,
      },
      this.getList
    );
  };

  changeActivateUser = () => {
    this.setState({ popOverOpened: false, isModalOpen: false });
    this.updateCustomerType(
      this.state.popOverInviteId,
      this.state.popOverItemStatus === "Active"
    );
  };

  handleDeactivateClose = () => {
    this.setState({ isModalOpen: false, popOverOpened: false });
  };

  handleDeactivatedClose = () => {
    this.setState({ deactivated: false });
  };

  handleQueryChange = (query: string) => {
    this.setState({ query }, () => this.getList());
  };

  sortingProps = {
    width: "31%",
    onQueryChange: (query: string) => this.handleQueryChange(query),
    onChange: (sortingData: ISortingData) => this.setState({ sortingData }),
  };

  handleGoList = () => {
    this.setState({ deactivated: false });
  };

  handleStatusColumn(rowData: InviteFriendInterface) {
    if(rowData.attributes.activated) {
      return "Active"
    } else {
      return "Deactived"
    }
  }

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.inviteFriendModule;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    })
  };

  receiveSearchText = (message: Message) => {
    if (message.id === getName(MessageEnum.SearchTextMessage)) {
      const recievedData = message.getData(
        getName(MessageEnum.SearchMessageText)
      );
      if(recievedData) {
        this.handleSearch(recievedData.searchText)
      }
    }
  }

  receiveLayoutData = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
      const recievedData = message.getData(
        getName(MessageEnum.LayoutMessageData)
      );
      if(recievedData.userContext) {
        this.handleUserChange(recievedData.userContext)
      }
    }
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getList();
    // Customizable Area End
  }
  // Customizable Area End
}
