import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { isEmail } from "../../../framework/src/Utilities";

// Customizable Area Start
import _ from "lodash";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
import {
  getStorageData,
} from "framework/src/Utilities";
import { apiCall } from "../../../components/src/common";
interface ModalOptions {
  scrollHeight: number ; 
}


interface VatDetailsAttributes {
  id?: number;
  tax_calculator_id?: number;
  tax_rate?: number;
  tax_code?: string;
  created_at?: string;
  updated_at?: string;
  [key: string]: any; // Optional if additional fields are present
}

interface Others {
  [key: string]: any; // Adjust the type if the structure of "others" is known
}

interface Attributes {
  name: string;
  registration_no: string;
  country_id: number;
  tin_no: string;
  number_of_vats: string;
  vat_details_attributes: VatDetailsAttributes[];
  telephone: string;
  activated: boolean;
  address: string;
  email: string;
  others: Others;
  parent_company_id:string|null
}

interface ICompany {
  id?: string;
  type?: "company";
  attributes: {
    parent_company?:{
      data: IMainCompanyList
    },
    activated: boolean;
    id?: number;
    parent_company_id?: string;
    name: string;
    name_arabic?: string;
    vat_number: string;
    vat_number_arabic?: string;
    registration_no: string;
    tin_no: string;
    created_at: string;
    updated_at: string;
    email: string;
    telephone: string;
    address: string;
    others: {
      [key: string]: string | { title: string; isSelected: boolean }[];
    };
    country_id: number;
    number_of_vats: string;
    vat_details_attributes: {
      id?: number;
      tax_calculator_id?: number;
      tax_calculator_name?: string;
      vat_name?: string;
      company_id?: number;
      default_tax?: boolean;
      created_at?: string;
      updated_at?: string;
    }[];
    country_data:{
      id:string | number;
      option:string
    }
  };
}

interface ICountry {
  id: number;
  name: string;
  country_code: string;
  created_at: string;
  updated_at: string;
}
interface ITax {
  id: string;
  type: "taxcalculator";
  attributes: {
    id: number;
    tax_name: string;
  };
}

const initialCompanyInfo: ICompany = {
  attributes: {
    activated: false,
    name: "",
    registration_no: "",
    vat_number: "",
    vat_number_arabic: "",
    tin_no: "",
    created_at: "",
    updated_at: "",
    email: "",
    telephone: "",
    address: "",
    others: {},
    country_id: 0,
    number_of_vats: "1",
    vat_details_attributes: [
      {
        tax_calculator_name: "",
        vat_name: "",
        default_tax: true,
      },
    ],
    country_data:{
      id:'',
      option:''
    },
    parent_company_id:""
  },
};

interface IMainCompanyList {
    "id": string,
    "type": string,
    "attributes": {
        "id": number,
        "name": string,
        "number_of_vats": string
  },
}

interface IAutoCompleteCompany {
  id: string | number;
  option: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  addModalVisible: boolean;
  companyInfo: ICompany;
  initialCompanyInfo: ICompany;
  modalCategory: "text" | "dropdown";
  modalTitle: string;
  modalText: string;
  modalOptions: { title: string; isSelected: boolean }[];
  countries: ICountry[];
  formErrors: { [key: string]: string };
  companyId: string;
  snackBarMessage: string;
  taxList: ITax[];
  isLoading: boolean;
  permissionStatus: PermissionStatus;
  modalError:{
    title:string|null,
    text:string|null,
    option:string|null
  }
  addedFieldError: Record<string, string | null>
  isSubcompanyCheck: boolean;
  isB2bActive: boolean
  selectedMainCompany: { id: string, option: string } | null;
  mainCompanyList: IAutoCompleteCompany[],
  telephonePrefix: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfCompanyInfoController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  authToken: string = "";
  updateCompanyCallId: string = "";
  getCountriesCallId: string = "";
  addCompanyCallId: string = "";
  getCompanyCallId: string = "";
  getTaxListId: string = "";
  addB2BCompanyAPICallId: string = "";
  getMainCompanyListApiCallId: string = "";
  modalOptionRef: ModalOptions & { scrollTo?: ((x: number, y: number) => void) | undefined } | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.LayoutDataMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      addModalVisible: false,
      modalCategory: "text",
      modalTitle: "",
      modalText: "",
      companyInfo: _.cloneDeep(initialCompanyInfo),
      initialCompanyInfo: _.cloneDeep(initialCompanyInfo),
      modalOptions: [{ title: "", isSelected: false }],
      countries: [],
      formErrors: {},
      companyId: "",
      snackBarMessage: "",
      taxList: [],
      isLoading: false,
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      },
      modalError:{
        title:null,
        text:null,
        option:null
      },
      addedFieldError: {},
      isSubcompanyCheck: false,
      isB2bActive: false,
      mainCompanyList: [],
      selectedMainCompany: null,
      telephonePrefix: "966"
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getCountriesCallId) {
        if (responseJson && responseJson.data) {
          let countries = responseJson.data as ICountry[];
          this.setState({ countries });
        }
      } else if (apiRequestCallId === this.getCompanyCallId) {
        this.receiveCompanyInfo(message);
      } else if (apiRequestCallId === this.updateCompanyCallId) {
        this.receiveUpdateCompany(message);
      } else if (apiRequestCallId === this.getTaxListId) {
        this.setState({ taxList: responseJson.data as ITax[] });
      } else if (apiRequestCallId === this.addB2BCompanyAPICallId){
        this.receiveUpdateCompany(message);
      } else if (apiRequestCallId === this.getMainCompanyListApiCallId){
        this.receiveMainCompanyList(message);
      }
    }

    this.receiveDataFromLayout(message)
    // Customizable Area End
  }

  // Customizable Area Start
  receiveDataFromLayout = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.LayoutMessageData)
        );
        if (recievedData.userContext) {
          this.handleUserChange(recievedData.userContext)
        }
    }
  }

  receiveUpdateCompany = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && responseJson.data) {
      if (responseJson.data.type !== "error") {
        if (this.state.companyId)
          this.showMessage("Company Info Updated Successfully");
        else this.showMessage("Company Info Added Successfully");
        setTimeout(() => {
          this.props.navigation.goBack();
        }, 2000);
      } else {
        const formErrors = this.state.formErrors;
        const telephoneError = responseJson.data.attributes.errors.telephone;
        if (telephoneError) {
          formErrors[configJSON.phoneNumber] = 'Telephone '+ telephoneError[0];
        }
        const emailError = responseJson.data.attributes.errors.email;
        if (emailError) {
          formErrors[configJSON.emailAddress] = 'Email ' + emailError[0];
        }
        const nameError = responseJson.data.attributes.errors.name;
        if (nameError) {
          this.getNameErrorAsPerType(nameError)
        }
        const tinError = responseJson.data.attributes.errors.tin_no;
        if (tinError) {
          formErrors[configJSON.tinNo] = 'Tin '+ tinError[0];
        }
        const regError = responseJson.data.attributes.errors.registration_no;
        if (regError) {
          formErrors[configJSON.registrationNumber] = 'Registration ' + regError[0];
        }
        this.setState({ formErrors, isLoading: false });
      }
    }
  };

  getNameErrorAsPerType = (error:string) => {
    const {formErrors,isB2bActive,isSubcompanyCheck} = this.state

    if(isB2bActive){
      if(isSubcompanyCheck){
        formErrors[configJSON.b2bSubCompanyName] = 'Name ' + error[0];
      }else{
        formErrors[configJSON.b2bCompanyName] = 'Name ' + error[0];
      }
    }else{
      formErrors[configJSON.companyName] = 'Name ' + error[0];
    }
  }

  getPhoneSplit = (phone: string) => {
    if(phone){
      const prefix =  phone.slice(0, 3);
      const phoneno = this.state.isB2bActive ? phone.slice(3) : phone;
      return {prefix,phoneno}
    }
  }

  receiveCompanyInfo = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && responseJson.data) {
      const telePhoneNumb = this.getPhoneSplit(responseJson.data.attributes?.telephone)?.phoneno
      const telePrefix = this.getPhoneSplit(responseJson.data.attributes?.telephone)?.prefix

      let companyInfo = {
        ...responseJson.data as ICompany,
        attributes:{
          ...responseJson.data.attributes,
            country_data:{
              id: String(responseJson.data.attributes.country?.id),
              option:responseJson.data.attributes.country?.name
            },
            telephone: telePhoneNumb
        }
      }
      if(this.state.isB2bActive && responseJson.data.attributes?.parent_company_id){
        this.setState({
          isSubcompanyCheck: true,
          selectedMainCompany: {
            id: responseJson.data.attributes.parent_company?.data?.id,
            option: responseJson.data.attributes.parent_company?.data?.attributes.name
          }
        },()=> this.getMainCompanyAPICall())
      }
      this.setState({isLoading:false, companyInfo, initialCompanyInfo: companyInfo,telephonePrefix: telePrefix as string });
    }
  };
  handleScrollCountryDropdown =()=>{

  }
  getCompany = (companyId: string,isB2b?:boolean) => {
    this.setState({isLoading:true})
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem(configJSON.tokenKey),
    };
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCompanyCallId = getDataMsg.messageId;
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodType
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.companyEndPoint + "/" + companyId + `${isB2b ? '?type=b2b_company' : ''} `
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  getCountries = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem(configJSON.tokenKey),
    };
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCountriesCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.countryEndPoint
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodType
    );
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  getTaxList = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem(configJSON.tokenKey),
    };
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getTaxListId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.taxListApi
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  updateCreateCompanyInfo = () => {
    const companyId = this.state.companyId;
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateCompanyCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.companyEndPoint}` + (companyId ? `/${companyId}` : "")
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    const newCompanyInfo = _.cloneDeep(this.state.companyInfo);
    if (!companyId) {
      delete newCompanyInfo.id;
      delete newCompanyInfo.attributes.id;
      delete newCompanyInfo.type;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ data: newCompanyInfo })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      companyId ? configJSON.httpPutMethod : configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateCreateB2BCompanyInfo = () => {
    const newB2bCompanyInfo = this.state.companyInfo;
    const {
      name,registration_no,country_id,tin_no,
      number_of_vats,vat_details_attributes,
      telephone,address,email,others
    } = newB2bCompanyInfo.attributes
    this.setState({isLoading:true})
    const isEditId = this.state.companyId;
    let headers = {
      "Content-type": "application/json", 
      token: this.authToken
    }

    let url = configJSON.companyEndPoint + '?type=b2b_company'
    let methodVal = configJSON.httpPostMethod
    if(isEditId){
      url = configJSON.companyEndPoint + "/" + isEditId+ '?type=b2b_company'
      methodVal = configJSON.httpPutMethod
    }
    let body = {
      "data": {
        "attributes": {
            "name":name,
            "registration_no": registration_no,
            "country_id": country_id,
            "tin_no": tin_no,
            "number_of_vats": number_of_vats,
            "vat_details_attributes": vat_details_attributes,
            "telephone": this.state.telephonePrefix + telephone,
            "activated": true,
            "address": address,
            "email" : email,
            "others":  others,
            parent_company_id:null
        } as Attributes
      } 
    }

    if (this.state.isSubcompanyCheck) {
      body.data.attributes.parent_company_id = this.state.selectedMainCompany?.id as string;
    }

    const getAccount = apiCall({
      header: headers,
      httpBody: body,
      url: url,
      httpMethod: methodVal,
    });

    this.addB2BCompanyAPICallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  };

  // web events

  onCancel = () => {
    this.props.navigation.goBack();
    this.setState({ companyInfo: { ...this.state.initialCompanyInfo } });
  };

  onSave = () => {
    let isOtherFieldValid = this.validateAllProperties(this.state.companyInfo.attributes.others)
    if (this.checkFormErrors() && isOtherFieldValid) {
      this.setState({ isLoading: true });
      if(this.state.isB2bActive){
        this.updateCreateB2BCompanyInfo();
      }else{
        this.updateCreateCompanyInfo();
      }
    }
  };
  onAddButtonClick = () => {
    this.setState({
      addModalVisible: true,
      modalTitle: "",
      modalText: "",
      modalOptions: [{ title: "", isSelected: false }],
    });
  };
  onModalCancelClick = () => {
    this.setState({ 
      addModalVisible: false,
      modalError:{
        option:null,
        text:null,
        title:null
      }
     });
  };
  onModalAddClick = () => {
    if (this.handleValidateModal()) {
      const others =
        this.state.modalCategory === "text"
          ? {
            ...this.state.companyInfo.attributes.others,
            [this.state.modalTitle]: this.state.modalText,
          }
          : {
            ...this.state.companyInfo.attributes.others,
            [this.state.modalTitle]: this.state.modalOptions,
          };
      this.setState({
        modalCategory: "text",
        modalTitle: "",
        modalText: "",
        modalOptions: [{ title: "", isSelected: false }],
        addModalVisible: false,
        companyInfo: {
          ...this.state.companyInfo,
          attributes: {
            ...this.state.companyInfo.attributes,
            others,
          },
        },
      });
    }
  };

  onModalCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      modalCategory: event.target.value as "text" | "dropdown",
      modalError:{
        option:null,
        text:null,
        title:null
      }
    });
  };

  onChangeVatName = (value: string, index: number) => {
    const vat_details_attributes =
      this.state.companyInfo.attributes.vat_details_attributes;
    vat_details_attributes[index].tax_calculator_id = parseInt(value);
    this.setState({
      companyInfo: {
        ...this.state.companyInfo,
        attributes: {
          ...this.state.companyInfo.attributes,
          vat_details_attributes,
        },
      },
    });
  };

  onClickVatDefault = (index: number) => {
    const vat_details_attributes =
      this.state.companyInfo.attributes.vat_details_attributes;
    vat_details_attributes[index].default_tax = true;
    vat_details_attributes.forEach((vatDetail, i) => {
      if (i !== index) vatDetail.default_tax = false;
    });
    this.setState({
      companyInfo: {
        ...this.state.companyInfo,
        attributes: {
          ...this.state.companyInfo.attributes,
          vat_details_attributes,
        },
      },
    });
  };

  async componentDidMount() {
    const authToken = await getStorageData("token");
    if (typeof (authToken) === "string") {
      this.authToken = authToken;
    }
    const companyId = window.location.search.split("=")[1];
    this.getCountries();
    this.getTaxList();
    const isB2b = await getStorageData("accountType");
    if(isB2b === "b2b"){
      this.setState({isB2bActive: true})
    }else{
      this.setState({isB2bActive: false})
    }
    if (companyId) {
      this.getCompany(companyId,isB2b === "b2b");
      this.setState({ companyId });
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if (
      this.state.companyInfo.attributes.number_of_vats !==
      prevState.companyInfo.attributes.number_of_vats
    ) {
      let newVatDetails = [];
      for (
        let i = 0;
        i < parseInt(this.state.companyInfo.attributes.number_of_vats);
        i++
      ) {
        if (this.state.companyInfo.attributes.vat_details_attributes[i]) {
          newVatDetails.push(
            this.state.companyInfo.attributes.vat_details_attributes[i]
          );
        } else {
          newVatDetails.push({
            tax_calculator_name: "",
          });
        }
      }
      const number_of_vats =
        this.state.companyInfo.attributes.number_of_vats || "1";
      if (newVatDetails.length === 0) {
        newVatDetails.push({
          tax_calculator_name: "",
        });
      }

      this.setState({
        companyInfo: {
          ...this.state.companyInfo,
          attributes: {
            ...this.state.companyInfo.attributes,
            number_of_vats,
            vat_details_attributes: newVatDetails,
          },
        },
      });
    }

    if (
      this.state.companyInfo.attributes.vat_details_attributes !==
        prevState.companyInfo.attributes.vat_details_attributes &&
      this.state.companyInfo.attributes.vat_details_attributes.length > 0
    ) {
      const defaultVat =
        this.state.companyInfo.attributes.vat_details_attributes.find(
          (vat) => vat.default_tax
        );

      if (!defaultVat) {
        this.setState({
          companyInfo: {
            ...this.state.companyInfo,
            attributes: {
              ...this.state.companyInfo.attributes,
              vat_details_attributes:
                this.state.companyInfo.attributes.vat_details_attributes.map(
                  (vatDetail, i) => {
                    if (i === 0) vatDetail.default_tax = true;
                    return vatDetail;
                  }
                ),
            },
          },
        });
      }
    }
  }

  onAddOption = () => {
    this.setState(
      {
        modalOptions: [
          ...this.state.modalOptions,
          { title: "", isSelected: false },
        ],
      },
      () => {
        if (this.modalOptionRef) {
          this.modalOptionRef.scrollTo?.(0, this.modalOptionRef.scrollHeight);
        }
      }
    );
  };

  onDeleteOption = (index: number) => {
    const modalOptions = [...this.state.modalOptions];
    modalOptions.splice(index, 1);
    this.setState({ modalOptions });
  };

  checkEmail = (email: string, formErrors: { [key: string]: string }) => {
    if (!email) formErrors[configJSON.emailAddress] = "Email is required";
    else {
      const message = isEmail("", email).message;
      if (message) formErrors[configJSON.emailAddress] = message;
    }
  };

  checkFormErrors = () => {
    const {telephonePrefix, companyInfo, isB2bActive,isSubcompanyCheck,selectedMainCompany } = this.state;
    const { attributes } = companyInfo;
    const {
      name,
      email,
    } = attributes;
    const formErrors: { [key: string]: string } = {};
    this.checkEmail(email, formErrors);
    let nameErrorMessage = configJSON.companyName
    let messageForName = "Company name is required"
    if(isB2bActive){
      if(isSubcompanyCheck){
        nameErrorMessage = configJSON.b2bSubCompanyName
        messageForName = "Sub company name is required"
      }else{
        nameErrorMessage = configJSON.b2bCompanyName
      }
      if (!telephonePrefix.trim())
        formErrors["prefix"] = "Telephone prefix is required";
    }
    if(isSubcompanyCheck){
      if (!selectedMainCompany){
        formErrors[configJSON.b2bMainCompanyName] = "Main compnay is required"
      } else if (this.state.companyId && this.state.companyId === selectedMainCompany.id){
        formErrors[configJSON.b2bMainCompanyName] = "Sub company and main company should not be same"
      }
    }
    if (!name.trim()){
      formErrors[nameErrorMessage] = messageForName
    }

    this.validateBasicFields(formErrors)

    this.setState({ formErrors });
    return Object.keys(formErrors).length === 0;
  };

  validateBasicFields = (formErrors: { [key: string]: string }) => {
    const { companyInfo } = this.state;
    const { address, country_id, telephone, registration_no, tin_no } = companyInfo.attributes;
  
    if (!address.trim()) formErrors[configJSON.address] = "Address is required";
    if (!country_id) formErrors[configJSON.country] = "Country is required";
    if (!telephone.trim()) formErrors[configJSON.phoneNumber] = "Telephone is required";
    if (!registration_no.trim()) formErrors[configJSON.registrationNumber] = "Registration number is required";
    if (!tin_no) formErrors[configJSON.tinNo] = "TIN number is required";
  };

  handleEdit = () => {
    const companyId = this.state.companyId;
    window.localStorage.setItem("companyId", companyId);
    this.props.navigation.history.push(
      "/Settings-General-CompanyListEdit?companyId=" + companyId
    );
  };

  handleBack = () => {
    this.props.navigation.history.goBack();
  };

  showMessage = (message: string) => {
    this.setState({ snackBarMessage: message });
  };

  closeSnackBar = () => {
    this.setState({ snackBarMessage: "" });
  };

  handleValidateDropDown = () => {
    const validateField = (fieldValue:string, errorKey:string, errorMessage:string) => {
      this.setState((prevValue) => ({
        modalError: {
          ...prevValue.modalError,
          [errorKey]: fieldValue.trim() === "" ? errorMessage : null,
        },
      }));
    };
  
    validateField(this.state.modalTitle, "title", "Text is required");
    validateField(this.state.modalOptions[0].title, "option", "Option is required");
  };
  

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.company;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    })
  };

  handleValidateModal = () => {
    const validateField = (fieldValue:string, errorKey:string, errorMessage:string) => {
      this.setState((prevValue) => ({
        modalError: {
          ...prevValue.modalError,
          [errorKey]: fieldValue.trim() === "" ? errorMessage : null,
        },
      }));
      return fieldValue.trim() !== "";
    };
 
    let isValid = true;
  
    if (this.state.modalCategory === "text") {
      isValid = validateField(this.state.modalTitle, "title", "Title is required") && isValid;
      isValid = validateField(this.state.modalText, "text", "Text is required") && isValid;
    } else {
      this.handleValidateDropDown();
      isValid = validateField(this.state.modalTitle, "title", "Title is required") && isValid;
      isValid = validateField(this.state.modalOptions[0].title, "option", "Option is required") && isValid;
    }
  
    return isValid;
  };

  handleDeleteDynamicField = (propertyToDelete:string) => {
    this.setState(prevState => ({
      companyInfo: {
        ...prevState.companyInfo,
        attributes: {
          ...prevState.companyInfo.attributes,
          others: Object.fromEntries(
            Object.entries(prevState.companyInfo.attributes.others)
              .filter(([key]) => key !== propertyToDelete)
          )
        }
      }
    }));
  }

  validateAllProperties = <T extends Record<string, string | { title: string; isSelected: boolean }[]>>(
    objData: T
  ): boolean => {
    const validationResults: Record<string, string | null> = {};
    let isValid = true;
  
    for (const [keyName, value] of Object.entries(objData)) {
      let errorMessage: string | null = null;
  
      if (
        (typeof value === 'string' && value === '') ||
        (Array.isArray(value) && value.every(option => !option.isSelected))
      ) {
        errorMessage = `${keyName} is required`;
      }
  
      validationResults[keyName] = errorMessage;
      if (errorMessage) isValid = false;
    }
  
    this.setState({ addedFieldError: validationResults });
    return isValid;
  };

  validateAddedField = (errorRecord:Record<string,string|null>,keyName:string) => {
    return errorRecord[keyName]
  }

  handleSubCompanyClick = () => {
    this.setState({
      isSubcompanyCheck: !this.state.isSubcompanyCheck
    },()=>{
      if(this.state.mainCompanyList.length === 0){
        this.getMainCompanyAPICall()
      }
    })
  }

  getMainCompanyAPICall = () => {
    let headers = {
      "Content-type": "application/json", 
      token: this.authToken
    }

    let url = configJSON.companyUpdateApi + "?dropdown=true&type=b2b_company"
    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.apiMethodType,
    });

    this.getMainCompanyListApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  receiveMainCompanyList = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && responseJson.data) {
      const b2bMainCompanyListData = responseJson.data.map(
        (item: IMainCompanyList) => ({
          id: item.id,
          option: item.attributes.name,
        })
      );
      this.setState({mainCompanyList:b2bMainCompanyListData})
    }
  }

  handleMainCompanySelection = (value: { id: string, option: string }) => {
    this.setState({selectedMainCompany: value})
  }

  handlePrefixChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newvalue = event.target.value.replace(/[^0-9+]/g, "");
    this.setState({
       telephonePrefix: newvalue
    });
  }

  handleTelephoneChange= (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newvalue = event.target.value.replace(/[^0-9+]/g, "");
    this.setState({
        companyInfo: {
          ...this.state.companyInfo,
          attributes: {
            ...this.state.companyInfo.attributes,
            telephone: newvalue,
          },
        },
    });
  }

  // Customizable Area End
}
