import React, { useEffect, useMemo, useState } from "react";
import { runEngine } from "framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import {
    CustomEnums,
    getCustomEnumName,
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { Dialog, styled } from "@material-ui/core";
import { colors } from "../../../blocks/utilities/src/Colors";
import moment from "moment";
import CustomDateRangePicker from "../CustomDateRangePicker";
import { calendarIcon } from "./assets";


type Props = {
    open: boolean
    onChangeDate: (startDate: string, endDate: string) => void
    onCancel: () => void
    receiptUrl: string
    loading?: boolean
    isStoreSelected: boolean
}

const defaultValues = {start_date: null, end_date: null}

const formatDate = (value: Date) => moment(value).format("YYYY-MM-DD")

const PrintSummaryDialog = ({ open, onChangeDate, onCancel, loading, receiptUrl, isStoreSelected }: Props) => {
    const [dateRange, setDateRange] = useState<{start_date: Date | null, end_date: Date | null}>(defaultValues)

    const isDateRangeSelected = useMemo(() => dateRange.start_date && dateRange.end_date, [dateRange])

    useEffect(() => {
        if (dateRange.start_date && dateRange.end_date) {
            onChangeDate(formatDate(dateRange.start_date), formatDate(dateRange.end_date))
        }
    }, [dateRange])

    useEffect(() => {
        if (!open) setDateRange(defaultValues)
    },[open])

    const previewContent = () => {
        if (!isStoreSelected) return "Please select store"
        if (!isDateRangeSelected) return "Select the Date first"
        if (loading) return "Loading..."
        if (!receiptUrl) return "Unable to fetch the Summary"
        return "Ready to Print"
    }

    const handlePrint = () => {
        if (!receiptUrl || !isDateRangeSelected || loading) return;
        const printMessage = new Message(getCustomEnumName(CustomEnums.CustomActionReducers))
        printMessage.addData(getCustomEnumName(CustomEnums.CustomReducerAction), "PRINT_FILE")
        printMessage.addData(getCustomEnumName(CustomEnums.CustomReducerPayload), {
            format: "pdf",
            data: receiptUrl
        })
        runEngine.sendMessage("Dashboard", printMessage)
    }

    return (
        <StyledDialog open={open} onClose={onCancel}>
            <h3>
                Print Summary
            </h3>
            <div className="range-box">
                <p>Summary Report</p>
                <CustomDateRangePicker 
                    onChange={(values: [Date | null, Date | null]) => setDateRange({start_date: values[0], end_date: values[1]})}
                    adornmentIcon={calendarIcon}
                    dateFormat="dd-MM-yyyy"
                    maxDate={new Date()}
                />
            </div>
            <div className="print-preview">
                {previewContent()}
            </div>
            <div className="actions">
                <button
                    data-test-id='cancel-btn'
                    className="cancel-btn"
                    onClick={() => onCancel()}
                >
                    Cancel
                </button>
                <button
                    className="print-btn"
                    data-test-id="print-btn"
                    onClick={() => handlePrint()}
                >
                    Print
                </button>
            </div>
        </StyledDialog>
    )
}


const StyledDialog = styled(Dialog)({
    "& .MuiDialog-paper": {
        borderRadius: 12,
        padding: 24,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minWidth: 648,
        gap: 16,
        overflowY: "unset",
        minHeight: 380
    },
    "& h3": {
        fontWeight: 600,
        fontSize: 24,
        lineHeight: 1.33
    },
    "& .range-box": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        "& > p": {
            fontWeight: 500,
            lineHeight: "22.4px",
            color: colors().viewTextColor
        },
        "& > div": {
            paddingRight: 8,
            "& > img": {
                right: "unset",
                left: 10
            }
        },
        "& .react-datepicker-wrapper": {
            paddingLeft: 28,
            "& .customDateRangePicker": {
                padding: 10,
                paddingRight: 0,
                minWidth: 190,
                "&:focus": {
                    outline: "none"
                },
            }
        }
    },
    "& .print-preview": {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 19,
        fontWeight: 600,
        color: colors().cyancobaltblue,
        border: `solid 6px ${colors().brightgray}`,
        borderRadius: 12,
        width: "100%",
        minHeight: 171
    },
    "& .actions": {
        "& > button": {
            padding: 12,
            borderRadius: 8,
            width: 186,
            border: 0,
            fontWeight: 700,
            lineHeight: 1.5,
            textTransform: "none",
            cursor: "pointer",
            "@media (max-width: 1024px)": {
                padding: "10px 0"
            },
        },
        "& .cancel-btn": {
            backgroundColor: colors().antiFlashWhite,
            color: colors().slateGray,

        },
        "& .print-btn": {
            backgroundColor: colors().cyancobaltblue,
            color: "white",
            marginLeft: 32,
        }
    }
})

export default PrintSummaryDialog