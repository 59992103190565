/** @format */

import React from "react";
// Customizable Area Start
import { withStyles, Theme } from "@material-ui/core/styles";
import { Paper, Typography, Button, Box ,styled} from "@material-ui/core";
import { Styles } from "@material-ui/styles";
import ViewSpecificationController, { Props } from "./ViewSpecificationController.web"
import PageContainer from "../../../blocks/navigationmenu/src/PageContainer.web";
import { PrefHeading } from "./PreferenceList.web";
export const configJSON = require("./config");
import { colors } from "../../../blocks/utilities/src/Colors";
import {
  renderBaseOnConditions 
} from "../../../blocks/utilities/src/CustomBlockHelpers";
// Customizable Area End

// Customizable Area Start
export const styles: Styles<Theme, {}, string> = () => ({
    viewContainer: {
      padding: "0 32px 0 48px",
      "& h2": {
        fontSize: "24px",
        fontFamily: "Montserrat",
      },
    },
    headingTop: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "40px 0",
        fontFamily: "Montserrat",
    },
    itemData: {
        display: "flex",
        backgroundColor: "white",
        borderRadius: 10,
        margin: "20px 0",
        boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.2)",
        padding: 30,
    },
    options: {
        flexDirection: "column",
        "& h2": {
            marginBottom: "30px",
        },
    },
    buttonStyle: {
        background: colors().cyancobaltblue,
        fontWeight: 600,
        fontSize: "16px !important",
        lineHeight: "24px",
        color: colors().white,
        height: "56px",
        width: '184px !important',
        borderRadius: "8px",
        textTransform: "unset",
        // marginLeft: "20px",
        "@media only screen and (max-width: 1024px)": {
            fontSize: "16px !important",
            width: "128px !important",
            height: "44px !important",
            padding: "10px !important"
        },
    },
    backButtonStyle: {
        width: "128px !important",
    },
    innerItemLeft: {
        flexBasis: "50%",
    },
    innerItemRight: {
      flexBasis: "50%",
    },
    cancelButton: {
        marginBottom: 50,
        marginTop: 40
    },
    optionsStyle: {
        display: "grid",
        gridTemplateColumns: "repeat(2,1fr)"
    },
    innerItem: {
        marginBottom: 45,
        "& P": {
          fontSize: "18px",
          color: "#4D4D4D",
          marginBottom: 8,
          fontWeight:600,
          fontFamily: "Montserrat",
        },

    },
    innerItemSpan: {
      fontSize: 14,
      background: "rgba(32, 75, 156, 0.1)",
      padding: "5px 12px",
      borderRadius: 8,
      color: "#204B9C",
      fontWeight: 500,
      fontFamily: "Montserrat",
      "@media only screen and (max-width: 1024px)": {
        fontSize: "13px !important",
        fontWeight: 500,
     },
    },
    innerItemText: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "22px",
      color: colors().black,
    "@media only screen and (max-width: 1024px)": {
        fontSize: "13px !important",
        fontWeight: 500,
        color:`${colors().viewTextColor} !important`
    },
    },
    chipwrapper: {
      display: "flex",
      gap: "10px",
      flexWrap: "wrap"
    },
    backBStyle: {
        background: colors().cyancobaltblue,
        fontWeight: 600,
        fontSize: "16px !important",
        lineHeight: "24px",
        color: colors().white,
        height: "56px",
        width: '184px !important',
        borderRadius: "8px",
        textTransform: "unset",
        marginLeft: "20px",
        "@media only screen and (max-width: 1024px)": {
            fontSize: "16px !important",
            width: "128px !important",
            height: "44px !important",
            padding: "10px !important"
        },
    },
});

const ViewTextLabel = styled("p")({
    fontWeight: 600,
    fontStyle: "normal",
    lineHeight: "22px",
    color: colors().darkliver,
    fontFamily: "Montserrat",
    fontSize: "18px",
    "@media only screen and (max-width: 1024px)": {
        color:`${colors().viewTextColor} !important`,
        fontSize: "16px !important",
        fontWeight: 600,
    },
});

const ViewSpecificationTextLabel = styled("p")({
    fontWeight: 600,
    fontStyle: "normal",
    lineHeight: "22px",
    color: colors().viewTextColor,
    fontFamily: "Montserrat",
    fontSize: "20px",
    marginBottom:"30px",
    "@media only screen and (max-width: 1024px)": {
        color:`${colors().viewTextColor} !important`,
        fontSize: "16px !important",
        fontWeight: 600,
    },
});
// Customizable Area End

export class ViewSpecification extends ViewSpecificationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    render() {
        const { specificationCurrentItem, permissionStatus } = this.state
        const { classes } = this.props;
        return (
            <>
                <Box className={classes.viewContainer}>
                    <Box className={classes.headingTop}>
                        <PrefHeading>
                            {configJSON.specificationDetails}
                        </PrefHeading>
                        {
                          renderBaseOnConditions(
                            permissionStatus.editPermission,
                            <Button
                              onClick={() => this.handleSpecificationEdit(specificationCurrentItem)}
                              className={classes.buttonStyle}
                              variant="contained"
                              color="primary"
                              data-test-id="button-edit"
                          >
                              {configJSON.editButtonText}
                          </Button>,
                            <></>
                          )
                        }
                    </Box>
                    <Paper className={classes.itemData}>
                        <Box className={classes.innerItemLeft}>
                            <Box className={classes.innerItem}>
                                <ViewTextLabel>
                                    {configJSON.specificationFirstInput}
                                </ViewTextLabel>
                                <Typography component="span" className={classes.innerItemText}>
                                    {specificationCurrentItem?.attributes.name}
                                </Typography>
                            </Box>
                            <Box className={classes.innerItem}>
                                <ViewTextLabel>
                                    {configJSON.viewSection}
                                </ViewTextLabel>
                                <Box className={classes.chipwrapper}>
                                  {specificationCurrentItem?.attributes.sections.data.length == 0 ?
                                    <Typography component="span" className={classes.innerItemText}>
                                      {configJSON.sectionErroDataMessage}
                                    </Typography> : specificationCurrentItem?.attributes.sections.data.map(item => (
                                      <Typography key={item.id} component="span" className={classes.innerItemSpan}>
                                        {item.attributes.section_name}
                                      </Typography>
                                    ))
                                  }
                                </Box>
                            </Box>
                            <Box className={classes.innerItem}>
                                <ViewTextLabel>
                                    {configJSON.viewProduct}
                                </ViewTextLabel>
                                <Box className={classes.chipwrapper}>
                                  {specificationCurrentItem?.attributes.products.data.length == 0 ?
                                    <Typography component="span" className={classes.innerItemText}>
                                      {configJSON.productErroDataMessage}
                                    </Typography> : specificationCurrentItem?.attributes.products.data.map(item => (
                                      <Typography key={item.id} component="span" className={classes.innerItemSpan}>
                                        {item.attributes.product_name}
                                      </Typography>
                                    ))
                                  }
                                </Box>
                            </Box>
                        </Box>
                        <Box className={classes.innerItemRight}>
                            <Box className={classes.innerItem}>
                                <ViewTextLabel>
                                    {configJSON.specificationSecondInput}
                                </ViewTextLabel>
                                <Typography component="span" className={classes.innerItemText}>
                                    {specificationCurrentItem?.attributes.second_name}
                                </Typography>
                            </Box>
                            <Box className={classes.innerItem}>
                                <ViewTextLabel>
                                    {configJSON.viewService}
                                </ViewTextLabel>
                                <Box className={classes.chipwrapper}>
                                  {specificationCurrentItem?.attributes.services.data.length == 0 ?
                                    <Typography component="span" className={classes.innerItemText}>
                                      {configJSON.serviceErroDataMessage}
                                    </Typography> : specificationCurrentItem?.attributes.services.data.map(item => (
                                      <Typography key={item.id} component="span" className={classes.innerItemSpan}>
                                        {item.attributes.name}
                                      </Typography>
                                    ))
                                  }
                                </Box>
                            </Box>
                        </Box>
                    </Paper>
                    <Paper className={`${classes.itemData} ${classes.options}`}>
                        <Box>
                            <ViewSpecificationTextLabel>
                                {configJSON.specificationOptions}
                            </ViewSpecificationTextLabel>
                            <Box className={classes.optionsStyle}>
                                {specificationCurrentItem?.attributes.options?.length == 0 ? <Typography component="span" className={classes.innerItemText}>
                                    {configJSON.optionErroDataMessage}
                                </Typography> : specificationCurrentItem?.attributes.options?.map((item, index) => (
                                    <Box key={index} className={classes.innerItem}>
                                        <ViewTextLabel>
                                            Options {index + 1}
                                        </ViewTextLabel>
                                        <Typography component="span" className={classes.innerItemText}>
                                            {item.data.attributes.label}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Paper>
                    <Box className={classes.cancelButton}>
                        <Button
                            onClick={this.handleCancel}
                            className={`${classes.buttonStyle} ${classes.backButtonStyle}`}
                            variant="contained"
                            color="primary"
                            data-test-id="button-cancel"
                        >
                            {configJSON.backButtonText}
                        </Button>
                    </Box>
                </Box>
            </>
        );
    }
    // Customizable Area End
}
export default withStyles(styles)(ViewSpecification);

