Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfwallet44";
exports.labelBodyText = "cfwallet44 Body";

exports.btnExampleTitle = "CLICK ME";

exports.label_usablePoints = "Usable Points";
exports.label_redeem = "Redeem";
exports.label_congrats = "Congratulations";
exports.label_pointsRedeemed = "Points redeemed";
exports.label_nowYouCanUseCreditAmount = "Now you can use the Credit amount";
exports.label_use = "Use";
// Customizable Area End