import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { ISortingData } from "../../../components/src/SortingTableHeader";
import { apiCall } from "../../../components/src/common";
import {
  navigateTo,
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey,
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
export interface IUser {
  id: string;
  type: string;
  attributes: {
    activated: boolean;
    role_name: string;
    region_names: string[];
    full_name: string;
    full_phone_number: string;
    permission_count: number;
  };
}

interface Company {
    id: number;
    name: string;
  }
  
  interface Account {
    id: number;
    name: string;
    full_phone_number: string;
    email: string;
  }
  
  interface Attributes {
    id: number;
    group_name: string;
    no_of_items_per_wash: number;
    no_of_pieces_per_item: number;
    designation: string;
    vip: boolean;
    description: string;
    accounts_count: number;
    activated: boolean;
    company: Company;
    accounts: Account[];
  }
  
  interface Data {
    id: string;
    type: string;
    attributes: Attributes;
  }
  interface BusinessAccountCustomerResponse {
    data: BusinessAccountCustomer[];
    meta: PaginationMeta;
  }
  
  interface BusinessAccountCustomer {
    id: string;
    type: string;
    attributes: CustomerAttributes;
  }
  
  interface CustomerAttributes {
    id: number;
    activated: boolean;
    name: string;
    full_phone_number: string;
    email: string;
    department_name: string;
    department_id: string;
    cost_center: string;
    sub_cost_center: string;
    address: string;
    postal_code: string;
    notes: string;
    private_notes: string;
    no_of_order: number;
    no_of_pieces: number;
    business_account_id: number;
    employee_id: string;
    sub_company_name: string | null;
    company: Company;
    business_account: BusinessAccount;
    business_customer_group: BusinessCustomerGroup;
    price_list: PriceList;
    city: City;
    gender: Gender;
  }
  
  interface Company {
    id: number;
    name: string;
  }
  
  interface BusinessAccount {
    id: number;
    business_customer: string;
  }
  
  interface BusinessCustomerGroup {
    id: number;
    group_name: string;
  }
  
  interface PriceList {
    id: number;
    name: string;
  }
  
  interface City {
    id: number;
    name_en: string;
  }
  
  interface Gender {
    id: number;
    label: string;
  }
  
  interface PaginationMeta {
    total_pages: number;
    total_count: number;
    current_page: number;
    next_page: number | null;
    pervious_page: number | null;
  }
  
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  page: number;
  pageSize: number;
  total: number;
  sortingData: ISortingData;
  query: string;
  searchTextAccount: string;
  isUsersListLoading: boolean;
  customerData:Data;
  customerGroupData:BusinessAccountCustomerResponse;
  customerQuery:string;
  viewForm:boolean;
  permissionStatus: PermissionStatus;
  isLoadingPermission: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ViewCustomerController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  viewCustomerGroupApiCallId:string="";
  customerGroupDataApiCallId:string="";
  tableRefs: HTMLElement | null = null;


  handleQueryChange = (query: string) => {
    this.setState({ query }, () => this.customerGroupDataApi());
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SearchTextMessage),
      getName(MessageEnum.LayoutDataMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      page: 0,
      viewForm: this.props.navigation.getParam("id") ? true : false,
      pageSize: 5,
      total: 0,
      sortingData: {
        name: "",
        email:"",
        phone:""
      },
      query: "",
      searchTextAccount: "",
      isUsersListLoading: false,
      customerData:{
          id: "",
          type: "",
          attributes: {
              id: 0,
              group_name: "",
              no_of_items_per_wash: 0,
              no_of_pieces_per_item: 0,
              designation: "",
              vip: false,
              description: "",
              accounts_count: 0,
              activated: false,
              company: {
                  id: 0,
                  name: ""
              },
              accounts: []
          }
      },
      customerGroupData:{
          data: [],
          meta: {
              total_pages: 0,
              total_count: 0,
              current_page: 0,
              next_page: null,
              pervious_page: null
          }
      },
      customerQuery:"",
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false,
        activatePermission: false,
      },
      isLoadingPermission: true,
    };

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(apiRequestCallId === this.customerGroupDataApiCallId){
        this.checkResponseData(responseJson)
      }
        if(apiRequestCallId === this.viewCustomerGroupApiCallId){
        this.setState({customerData: responseJson.data})
      }
    }

    this.receiveDataFromLayout(message);
    // Customizable Area End
  }

  // Customizable Area Start

  getFilteredRows = () => {
    const {customerGroupData} = this.state
    return customerGroupData.data
  }
  checkResponseData(responseJson:BusinessAccountCustomerResponse) {
    this.setState({
      isUsersListLoading : false, 
      customerGroupData: responseJson || [],
      total: responseJson?.meta?.total_count || 0
    })
    
  }
  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    if (this.state.viewForm) {
        this.showCustomerDescApi();
        this.customerGroupDataApi()
      }
    // Customizable Area End
  }

  // Customizable Area Start
  handlePageChange = (
    page: number
  ) => {
    this.setState({ page }, () => this.customerGroupDataApi());
  };
  sortingProps = {
    width: "",
    onQueryChange: (query: string) => this.handleQueryChange(`&${query}`),
    onChange: (sortingData: ISortingData) => this.setState({ sortingData }),
  };
  getTypeOfOrderTable = (rowIndex: number) => {
    if (rowIndex === 0) {
      return "left";
    } else if (rowIndex === 2) {
      return "right";
    } else {
      return "middle";
    }
  };
  showCustomerDescApi = () => {
    const groupId = this.props.navigation.getParam("id");
    let headers = {
      "Content-type": "application/json", 
      token: localStorage.getItem(configJSON.token),
    }
    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: configJSON.viewCustomerGroupEndPoint+`/${groupId}`,
      httpMethod: configJSON.validationApiMethodType,
    });
    this.viewCustomerGroupApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }
  customerGroupDataApi = () => {
    const groupId = this.props.navigation.getParam("id");
    let headers = {
      "Content-type": "application/json", 
      token: localStorage.getItem(configJSON.token),
    }
    let url = configJSON.customerGroupDataEndPoint 
    +
    `?per_page=${this.state.pageSize}&page_no=${this.state.page + 1}&name=${this.state.sortingData.name}&phone=${this.state.sortingData.phone}&email=${this.state.sortingData.email}&group_id=${groupId}`
    
    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.validationApiMethodType,
    });
    this.customerGroupDataApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }
  handleNavigateToEdit = () => {
    this.props.navigation.navigate("B2bEditCustomerGroup", {
      id: this.props.navigation.getParam("id"),
    });
  };
  goToPreviousPage= () => {
        navigateTo({ props: this.props, screenName: "B2bCustomerGroup" });
  }

  receiveDataFromLayout = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
      const recievedData = message.getData(
        getName(MessageEnum.LayoutMessageData)
      );
      if (recievedData.userContext) {
        this.handleUserChange(recievedData.userContext);
      }
    }
  };

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.b2bCustomerGroupPermission;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(
      apiKey,
      userData as Array<PermissionGroupArray>
    );
    this.setState({
      permissionStatus: value,
      isLoadingPermission: false,
    });
  };
  // Customizable Area End
}
