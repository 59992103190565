import React from "react";
import {Box, styled} from "@material-ui/core";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {colors} from "../../../blocks/utilities/src/Colors";

type Props = {
    title: string;
    icon: string;
    onNavigate?: () => void;
    navigateIcon?: React.ReactNode,
    className?: string,
    children: React.ReactNode
  }
const CustomCard = React.memo(({ title, icon, onNavigate, navigateIcon = <ChevronRightIcon className="go-to-details"/>, children, className }: Props) =>  
    (
      <CustomCardWrapper className={"custom-card" + (className ? ` ${className}` : "")}>
        <Box className="custom-card-header">
          <Box display="flex" gridGap={8} alignItems="center">
            <img src={icon} alt="" />
            <StyledText>
              {title}
            </StyledText>
          </Box>
          {
            onNavigate &&
            <Box onClick={onNavigate}>
              {navigateIcon}
            </Box>
          }
        </Box>
        {children}
      </CustomCardWrapper>
    )
)
  const CustomCardWrapper = styled(Box)({
    display: "flex",
    flexDirection: "column",
    padding: 12,
    borderRadius: 12,
    border: `solid 1px ${colors().lightborder}`,
    "& .custom-card-header": {
      display: "flex",
      justifyContent: "space-between",
      padding: 8,
      borderBottom: "solid 0.5px #D9D9D9",
      alignItems: "center",
    }
  })

  export const StyledText = styled("p")({
    fontSize: 19,
    fontWeight: 500,
    lineHeight: 1.2
  })

  export default CustomCard