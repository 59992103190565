Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";
exports.httpPutType = "PUT"

exports.categoryApiContentType = "application/json";
exports.categoryAPIEndPoint = "categories/categories";
exports.subCategoryAPIEndPoint = "categories/sub_categories";
exports.getServiceListAPIEndPoint = "bx_block_categories/services";
exports.getSectionListsAPIEndPoint = "bx_block_categories/categories";
exports.getIconsListsAPIEndPoint = "bx_block_categories/gallery_icons/list_all_icons";
exports.addServiceListAPIEndPoint = "bx_block_categories/services";
exports.serviceSuggestionAPIEndPoint = "bx_block_categories/services/service_filter";
exports.upchargeSuggestionAPIEndPoint = "bx_block_section/upcharge_lists/upcharge_list_filter";
exports.dragDropAPIEndPoint =  "bx_block_categories/services/order_numbers";
exports.filtersAPIEndPoint =  "bx_block_section/upcharge_lists";
exports.testing="test"
exports.activateEndPoint = "activate"
exports.edited="edited"
exports.preferenceTitle="Preference";
exports.placeHolderTextSection = "Select Section";
exports.emptySectionDataMessage = "No Section Found";


exports.errorTitle = "Error";
exports.deletes="deletes"
exports.errorAllFieldsAreMandatory = "Please enter a category first";
exports.errorCategory = "Please select a category first";
exports.optionsData = [
  { id:1, label: 'Option 1' },
  { id: 2, label: 'Option 2' },
  { id:3, label: 'Option 3' },
  { id:4, label: 'Option 1' },
  { id: 5, label: 'Option 2' },
  { id:6, label: 'Option 3' },
];
exports.iconListApiEndPoint = "bx_block_categories/gallery_icons";
exports.createPreferenceApiEndPoint = "bx_block_categories/preference_lists";
exports.getAllPreferenceEndPoint = "bx_block_categories/preference_lists";
exports.getAllPreferenceDropdownEndPoint = "bx_block_categories/preference_lists?dropdown=true";
exports.httpUpdateMethodType = "PUT";
exports.updatePreferenceListEndPoint = "bx_block_categories/preference_lists";
exports.defaultIcon =
  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";
exports.snackbarMessage = "Preference Added Successfully !!!";
exports.snackbarSuccessMessage = "Preference Updated Successfully !!!";
exports.token = "token";
exports.service = "Service";
exports.addService = "Add Service";
exports.serviceName = "Service Name";
exports.active = "Active";
exports.online = "Online";
exports.deactivate = "deactivate";
exports.section = "Section";
exports.edit = "Edit";
exports.serviceSecondName = "Service Arabic Name";
exports.serviceShortName = "Short Name";
exports.icon = "Icon";
exports.activeForOrder = "Active for Online Order";
exports.inActiveForOrder = "InActive for online order";
exports.back = "Back";
exports.enterName = "Enter Name";
exports.selectOptions = "Select Section";
exports.pleaseSelectOneIcon = "Please select One Icon";
exports.onlineOrder = "Online Order";
exports.cancel = "Cancel";
exports.add = "Add";
exports.delete = "Delete"
exports.editService = "Edit Service";
exports.threeDotOptions = ["View", "Edit", "Deactivate"];
exports.confirmDeactivate = "Confirm Deactivate";
exports.confirmDeactivateText =
  "Are you sure you want to deactivate the Service? This process cannot be undone";
exports.successFullDeactivateText = "Service has been deactivated successfully";
exports.successFullDeactivateTitle = "Service Deactivated";
exports.backToListing = "Back to Listing";
exports.confirmDeactivate = "Confirm Deactivate";
exports.successFullDeactivateTitle = "Service Deactivated";
exports.confirmDeactivateBtn = "Deactivate";
exports.outlined = "outlined";
exports.submit = "submit";
exports.serviceList = "ServicesList";
exports.alignCenter = "center";
exports.enterSecondName = "Enter Arabic Name";
exports.enterShortName = "Enter Short Name";
exports.selectIcon = "Select Icon";
exports.addUserSuccess = "Service Added Successfully !!!";
exports.success =  "success";
exports.errorMsg = "something went wrong";
exports.section =  "Section";
exports.updatePreferenceListEndPoint = "bx_block_categories/preference_lists"
exports.defaultIcon = "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
exports.snackbarMessage = "Preference Added Successfully !!!"
exports.snackbarSuccessMessage = "Preference Updated Successfully !!!"
exports.token = "token"
exports.noProductText = "No products found!"
exports.noServiceText = "No services found!"
exports.selectProductText = "Select Product"

exports.getUpchargeListEndpoint = "bx_block_section/upcharge_lists"
exports.applicationJsonContentType = "application/json";
exports.getUpchargeDetailEndpoint= "bx_block_section/upcharge_lists/"
exports.deactivateUpchargeEndpoint = "bx_block_section/upcharge_lists/"
exports.textDeactivate = "/deactivate"
exports.textActivate = "/activate"
exports.upchargeDeactivateMessage = "Upcharge Deactivated"
exports.upchargeSuccessMessage = "Upcharge has been deactivated successfully"
exports.verifyUpchargeMessage = "Are you sure you want to deactivate the Upcharge? This process cannot be undone"
exports.putDragAndDropEndpoint = '/bx_block_section/upcharge_lists/order_numbers'
exports.getGalleryIconsListDropDownEndpoint = "/bx_block_categories/gallery_icons/list_all_icons"
exports.getServiceCategoryListDropDownEndpoint = "/bx_block_categories/categories?dropdown=true"
exports.createUpcharge = "/bx_block_section/upcharge_lists"
exports.editUpcharge = "/bx_block_section/upcharge_lists/"
exports.dragAndDropUpchargeEndpoint = "bx_block_section/upcharge_lists/order_numbers"
exports.getServiceListDropDownEndpoint = "bx_block_categories/services?dropdown=true"
exports.getProductListDropDownEndpoint = "bx_block_catalogue/catalogues?dropdown=true"
exports.iconsEndpoint = "bx_block_categories/gallery_icons/list_all_icons";
exports.sectionListEndPoint = "bx_block_categories/categories"
exports.sectionListApiEndPoint = "bx_block_categories/categories"
exports.sectionDropDownListEndPoint = "bx_block_categories/categories?dropdown=true"
exports.deActivateSectionListEndPoint = "bx_block_categories/categories"
exports.popUpMessage = "Section Added Successfully !!!"
exports.updatePopUpMessage = "Section Updated Successfully !!!"
exports.updateSectionSuccess = "Service Updated Successfully !!!"
exports.sectionVerifyMessage = "Are you sure you want to deactivate the Section? This process cannot be undone"
exports.sectionSuccessMessage = "Section has been deactivated successfully"
exports.confirmButtonText = "Back to Listing"
exports.sectionDeactivateMessage = "Section Deactivated"
exports.createSpecificationApiEndPoint = "bx_block_categories/specifications"
exports.serviceListApiEndPoint = "bx_block_categories/services"
exports.serviceListDropdownApiEndPoint = "bx_block_categories/services?dropdown=true"
exports.specificationMessage = "Specification Added Successfully !!!"
exports.productListApiEndPoint = "bx_block_catalogue/catalogues"
exports.productListDropdownApiEndPoint = "bx_block_catalogue/catalogues?dropdown=true"
exports.specificationApiEndPoint = "bx_block_categories/specifications"
exports.getSectionSuggestionApiEndPoint = "bx_block_categories/categories/category_filter"
exports.getSectionDropDown="bx_block_categories/services?dropdown=true"
exports.getCategoriesDropdown='bx_block_categories/categories?dropdown=true&filter_by[section_ids][]='
exports.getSectionDropDownEdit="bx_block_catalogue/catalogues?dropdown=true&page_no=1&filter_by[query]=testa&filter_by[section_ids][]="
exports.specificationTitle = "Product Specification"
exports.buttonAddSpecification = "Add Specification"
exports.buttonImportExport = "Export/Import"
exports.productHeader = "Specification Name"
exports.specificationPara = "Section"
exports.specificationDetails = "Specification Details"
exports.serviceName = "Service"
exports.ProductName = "Product"
exports.viewButtonText="View"
exports.editButtonText="Edit"
exports.deactivateButtonText = "Deactivate"
exports.exportTemplate = "Export Template"
exports.importCsv = "Import CSV"
exports.exportCsv = "Export CSV"
exports.emptyList = "No specification to show"
exports.verifyPreferenceMessage = "Are you sure you want to deactivate the Preference? This process cannot be undone"
exports.deactivatePreferenceMessage = "Preference Deactivated"
exports.preferenceSuccessMessage="Preference has been deactivated successfully"
exports.confirmDeactivate = "Confirm Deactivate"
exports.confirmText = "Deactivate"
exports.deactivateSpecificationMessage = "Product Specification Deactivated"
exports.specificationSuccessMessage = "Product Specification has been deactivated successfully"
exports.verifySpecificationMessage ="Are you sure you want to deactivate the Product Specification? This process cannot be undone?"
exports.editSpecificationTitle = "Edit Product Specification"
exports.specificationFirstInput = "Specification Name"
exports.specificationSecondInput = "Specification Arabic Name"
exports.firstNameSpecificationError = "Please enter your first specification name."
exports.secondNameSpecificationError = "Please enter your second specification name."
exports.sectionErrorMessage = "Please select a section."
exports.productErrorMessage = "Please select a product."
exports.serviceErrorMessage = "Please select a service."
exports.specificationOption = "Specification Options"
exports.sectionDragAndDropApiEndPoint = "bx_block_section/sections/order_numbers"
exports.viewSection ="Section"
exports.viewProduct = "Product"
exports.viewService ="Service"
exports.sectionErroDataMessage = "There is no selection for section."
exports.productErroDataMessage = "There is no selection for product."
exports.serviceErroDataMessage = "There is no selection for service."
exports.specificationOptions = "Specification Options"
exports.optionErroDataMessage = "There is no options to show"
exports.verifySpecificationMessage ="Are you sure you want to deactivate the Product Specification? This process cannot be undone"
exports.typeCategory = "category"
exports.typeError ="error"
exports.popupErrorMessage = "Something went wrong !!!"
exports.severitySuccess = "success"
exports.activeSuccess = "Active for online order"
exports.inActiveText = "InActive for online order"
exports.sectionDragAndDropApiEndPoint = "bx_block_categories/categories/order_numbers"
exports.deActiveText = "deactivate"
exports.activeText = "activate"
exports.updateSpecificationMessage ="Specification Updated Successfully !!!"
exports.typeSpecification = "specification"
exports.UpchargeSectionId = "UpchargeSectionId"
exports.backButtonText ="Back"
exports.editButtonText = "Edit"
exports.saveButton = "Save"
exports.noData = "No records found !!"
exports.modalTitle = "Import File"
exports.upchargeExportApiUrl = "bx_block_section/upcharge_lists/csv_export"
exports.exportimportText = "Export/Import"
exports.exportTemplate = "Export Template"
exports.importCSV = "Import CSV"
exports.exportCSV = "Export CSV"
exports.successExportMsg = "CSV file successfully has been exported"
exports.successExportMsgForExcel = "Excel file successfully has been exported"
exports.successExcelExportMsg = "Excel file successfully has been exported"
exports.specificationSingleDataApiUrl = "/bx_block_categories/specifications"
exports.exportCSVApiUrl = "/bx_block_section/upcharge_lists/excel_export"
exports.importCsvApiurl = "/bx_block_section/upcharge_lists/excel_import"
exports.exportUpchargeTemplateCsvApiurl = "bx_block_section/upcharge_lists/excel_template"
exports.exportSpecificationCSVApiUrl = "/bx_block_categories/specifications/excel_export";
exports.importSpecificationCSVApiUrl = "/bx_block_categories/specifications/excel_import";
exports.exportSpecificationCSVTemplateApiUrl = "/bx_block_categories/specifications/excel_template";
exports.Strings = {
  specificationList: {
    view: "View",
    edit: "Edit",
    deactivate: "Deactivate",
    activate: "Activate",
    noRecordFound: "No Record found",
    specificationName: "Specification Name",
    sections: "Sections",
    services: "Services",
    products: "Products",
    status: "Status",
    deactivated: "Deactivated"
  }
}
exports.ApiUrls = {
  productActivateAPIUrl: "/bx_block_categories/specifications",
  filterProductSugesstionAPIUrl : "bx_block_categories/specifications/specification_filter?product_name="
}
exports.addSvg = "Add SVG"
exports.addMoreService = "More Service"
exports.dropdownText = "Choose Service"
exports.notAvailable = "Service Unavailable"
exports.test = "test"
exports.test2 = "test2"
exports.test3 = "test3"
exports.serviceTracker = "Tracked Service"
exports.orderService = "Order Services"
exports.cancelOrderService = "Cancel Order Service"
exports.testCov = "testcov"
exports.editPreference = "Edit Preference"
exports.MultipleApiCall = "Restrict Api Call"
exports.SectionServiceProduct = "Service And Product For Section"

exports.filterStorageKey = {
  sectionList: "section_filter_value",
  serviceList: "service_filter_value"
}
// Customizable Area End
