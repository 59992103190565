import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  InputLabel,
  Typography,
  Modal,
  Grid,
  Snackbar,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Formik, FormikValues } from "formik";
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Yup from "yup";
import {
  InputField,
  SimpleCustomAutocomplete,
} from "../../../components/src/customComponents/CustomTags.web";

import { addIcon, calenderIcon } from "../../CfPriceList/src/assets";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Alert from "@material-ui/lab/Alert";
import { getOptionLabelByValue, renderBaseOnConditions, safeExtraction, sortStringCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";

import AddCustomerModalController, {
  Props,
  configJSON
} from "./AddCustomerModalController";
import Loader from "../../../components/src/Loader.web";
import CreateCustomerPreference from "../../ProductDescription/src/CreateCustomerPreference.web";

import { onChangeOrganization } from "./utils";
import AutocompleteSelect from "../../../components/src/AutocompleteSelect.web";
import moment from "moment";
import { colors } from "../../../blocks/utilities/src/Colors";
// Customizable Area End

export default class AddCustomerModal extends AddCustomerModalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  checkError(error: any, touch: any) {
    if (error && touch) {
        return true
    } else {
      return false
    }
  }

  finalErrorMessage(error: any, touch: any) {
    if (error) {
        if (touch) {
            return <div style={AddCustomerWebStyle.fieldError}>
                {error}
            </div>
        }
        else {
            return null
        }
    }
    else {
        return;
    }
  }

  checkStoreFieldEmptyError(errors:FormikValues, touched:FormikValues){
    if(errors.price_list_store_id && touched.price_list_store_id){
      return "fieldBorderError"
    }
    else{
      return "fieldBorder"
    }
  }

  checkValue(value:any) {
    if(value) {
      if(value?.option) {
        return value?.option
      }
      return value
    } else {
      return ""
    }
  };

  getSaveButtonText() {
    if(this.state.buttonLoading) {
      return <CircularProgress style={{ width: 16, height: 16, color: "white" }}/>
    } else {
      const { isEdit } = this.props;
      return isEdit ? "Save" : "Create"
    }
  }

  returnCompensationCreditLabel = () => {
    const { totalCompensationCredit } = this.state;

    if (this.props.isEdit && totalCompensationCredit > 0) {
      return (
        <InputLabel style={AddCustomerWebStyle.fieldLabel}>
          {configJSON.txtLblAddMoreCompensationCredit}{" "}
          <Typography style={AddCustomerWebStyle.compensationtxt}>({totalCompensationCredit})</Typography>
        </InputLabel>
      );
    } else {
      return (
        <InputLabel style={AddCustomerWebStyle.fieldLabel}>
          {configJSON.txtLblCompensationCredit}
        </InputLabel>
      );
    }
  };
  // Customizable Area End

 
    // Customizable Area Start
  render() {
    const phoneRegex = /^[0-9+]+$/;

    const validationSchema = Yup.object({
      name: Yup.string()
        .trim()
        .required("Name is required."),
      mobile_number_prefix: Yup.string().matches(phoneRegex, "Invalid"),
      mobile_number: Yup.number()
        .min(1, 'Please enter valid mobile number')
        .required("Mobile number is required."),
      email: Yup.string()
        .trim()
        .notRequired()
        .nullable()
        .email("Invalid email"),
      birthday: Yup.string()
        .notRequired()
        .nullable(),
      profession: Yup.string()
        .notRequired()
        .nullable(),
      organisation: Yup.string()
        .notRequired()
        .nullable(),
      street_address: Yup.string()
        .notRequired()
        .nullable(),
      city: Yup.string()
        .notRequired()
        .nullable(),
      postal_code: Yup.string()
        .notRequired()
        .nullable(),
      id: Yup.string()
        .notRequired()
        .nullable(),
      business: Yup.string()
        .notRequired()
        .nullable(),
      payment_type: Yup.string()
        .notRequired()
        .nullable(),
      notes: Yup.string()
        .notRequired()
        .nullable(),
      private_note: Yup.string()
        .notRequired()
        .nullable(),
      price_list: Yup.string()
        .required("Please select price list"),
      price_list_expiry_date: Yup.string().when("price_list", {
        is: (value: number) => String(value) !== this.state.isMasterPricelistId,
        then: Yup.string().nullable().required("Please select price list expiry date"),
      }),
      price_list_store_id: Yup.string().when("price_list", {
        is: (value: number) => String(value) !== this.state.isMasterPricelistId,
        then: Yup.string().required("Please select store"),
      }),
      compensation_credit: Yup.string()
        .nullable()
        .matches(
          this.props.isEdit ? /^-?\d+(\.\d{1,2})?$/ : /^\d+(\.\d{1,2})?$/,
          this.props.isEdit
            ? 'Must be a valid number with up to 2 decimal places, can be negative' 
            : 'Must be a valid number with up to 2 decimal places (no negative numbers allowed)'
        )
        .test('is-valid-number', 'Must be a valid number', (value) => {
          // Allow empty values
          if (!value) return true;
          // Check if the value can be converted to a number
          return !isNaN(Number(value));
        }),
    });

    // Customizable Area End
    return (
      // Customizable Area Start
      <Modal
        data-test-id='addPriceModal'
        open={this.props.open}
            onClose={() => {this.props.handleClose()}}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        className='MuiModal-root'
        BackdropProps={{ "aria-label": "backdrop-overlay" }}
      >
        <Box sx={addCustomerModalStyle}>
          <Loader loading={this.state.isLoading} />
          <Box sx={modalScrollbar}>
            <Typography style={AddCustomerWebStyle.addPriceHeader}>
              {this.getHeaderText()}
            </Typography>
            <Formik
              data-test-id="addCustomerFormik"
              initialValues={this.state.addPriceInitialvalues}
              validationSchema={validationSchema}
              onSubmit={(values) => this.handelFormikSubmit(values)}
              enableReinitialize
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleSubmit,
                        handleBlur,
                        setFieldValue,
                        getFieldProps
                    }: any) => (
                        <form onSubmit={handleSubmit} data-test-id="addPriceForm">
                            <Grid container spacing={2} id="modal-content">
                    <Grid item xs={6}>
                      <InputLabel style={AddCustomerWebStyle.fieldLabel}>Name*</InputLabel>
                      <InputField
                        error={this.checkError(errors.name, touched.name)}
                        id="name"
                        name="name"
                        fullWidth={true}
                        placeholder="Enter Name"
                        variant="outlined"
                        inputProps={{
                          'data-test-id': 'field-name'
                        }}
                        {...getFieldProps("name")}
                      />
                      {this.finalErrorMessage(errors.name, touched.name)}
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel style={AddCustomerWebStyle.fieldLabel}>Mobile Number*</InputLabel>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <InputField
                            error={this.checkError(errors.mobile_number_prefix, touched.mobile_number_prefix)}
                            id="mobile_number_prefix"
                            name="mobile_number_prefix"
                            fullWidth={true}
                            placeholder="Enter Prefix"
                            variant="outlined"
                            inputProps={{
                              'data-test-id': 'field-prefix'
                            }}
                            {...getFieldProps("mobile_number_prefix")}
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <InputField
                          onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                            e.target.addEventListener(
                            "wheel",
                            function (e: WheelEvent) {
                                e.preventDefault();
                            },
                            { passive: false }
                                );
                            }}
                            type="number"
                            error={this.checkError(errors.mobile_number, touched.mobile_number)}
                            id="mobile_number"
                            name="mobile_number"
                            fullWidth={true}
                            placeholder="Enter Mobile number"
                            variant="outlined"
                            inputProps={{
                              'data-test-id': 'field-phone'
                            }}
                            {...getFieldProps("mobile_number")}
                          />
                        </Grid>
                      </Grid>
                      {this.finalErrorMessage(errors.mobile_number, touched.mobile_number)}
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel style={AddCustomerWebStyle.fieldLabel}>Email ID</InputLabel>
                      <InputField
                        error={this.checkError(errors.email, touched.email)}
                        id="email"
                        name="email"
                        fullWidth={true}
                        placeholder="Enter Email Id"
                        variant="outlined"
                        inputProps={{
                          'data-test-id': 'field-email'
                        }}
                        {...getFieldProps("email")}
                      />
                      {this.finalErrorMessage(errors.email, touched.email)}
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel style={AddCustomerWebStyle.fieldLabel}>
                        {configJSON.txtLblRegion}
                      </InputLabel>
                      <Box style={{height: "10px"}}/>
                      <AutocompleteSelect
                        fieldName='region_id'
                        placeholder='Select Region'
                        options={this.state.regionList}
                        value={values.region_id}
                        isRadioButtonShow={false}
                        onBlur={handleBlur}
                        onChange={(
                          _event: React.ChangeEvent,
                          value: { id: number } | null
                        ) => {
                          this.handleChangeRegion(
                            value,
                            setFieldValue
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel style={AddCustomerWebStyle.fieldLabel}>Gender</InputLabel>
                      <SimpleCustomAutocomplete
                        data-test-id="genderDropdown"
                        id="gender"
                        name="gender"
                        options={this.state.dropdownOptions.gender}
                        getOptionLabel={(option: { option: string }) => option.option}
                        inputValue={getOptionLabelByValue(values.gender, "id", this.state.dropdownOptions.gender, "option")}
                        onChange={(event: unknown, value: { id: string }) => { setFieldValue("gender", safeExtraction(value, "id")) }}
                        clearOnEscape={false}
                        clearOnBlur={false}
                        onBlur={handleBlur}
                        renderInput={(params: object) => (
                          <InputField
                            {...params}
                            error={this.checkError(errors.gender, touched.gender)}
                            variant="outlined"
                            placeholder="Select Gender"
                            onChange={(event: React.ChangeEvent<{ value: string }>) => {
                              this.handleOnInputChange(setFieldValue, "gender", event.target.value)
                            }}
                          />
                        )}

                        ListboxProps={
                          {
                            style: {
                              maxHeight: '150px',
                            }
                          }
                        }
                      />
                      {this.finalErrorMessage(errors.gender, touched.gender)}
                    </Grid>
                                <Grid item xs={6} className="customerBirthdate">
                                  <InputLabel style={AddCustomerWebStyle.fieldLabel}>Birthday</InputLabel>
                                  <DatePicker
                                    data-test-id="birthdatePicker"
                                    id="birthday"
                                    name="birthday"
                                    {...getFieldProps("birthday")}
                                    onChange={(event: any) => {
                                      setFieldValue("birthday", event);
                                    }}
                                    selected={(values.birthday && new Date(values.birthday)) || null}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Select Date"
                                    maxDate={new Date()}
                                    className={ this.getConditionBasedValues(this.checkError(errors.birthday, touched.birthday), "birthdate-picker-errors", "birthdate-picker")}
                                  />
                                  <img src={calenderIcon} alt="calender-icon" style={{position: 'absolute', right: '18px', top: '61px', pointerEvents: 'none'}}/>
                                  {this.finalErrorMessage(errors.birthday, touched.birthday)}
                                </Grid>
                    <Grid item xs={6}>
                      <InputLabel style={AddCustomerWebStyle.fieldLabel}>Profession</InputLabel>
                      <SimpleCustomAutocomplete
                        data-test-id="professionDropdown"
                        id="profession"
                        name="profession"
                        options={this.state.dropdownOptions.profession}
                        getOptionLabel={(option: { option: string }) => option.option}
                        inputValue={values.profession}
                        onChange={(event: unknown, value: unknown) => { setFieldValue("profession", this.checkValue(value)); }}
                        clearOnEscape={false}
                        clearOnBlur={false}
                        onBlur={handleBlur}
                        renderInput={(params: object) => (
                          <InputField
                            {...params}
                            error={this.checkError(errors.profession, touched.profession)}
                            variant="outlined"
                            placeholder="Select Profession"
                            onChange={(event: React.ChangeEvent<{ value: string }>) => {
                              this.handleOnInputChange(
                                setFieldValue,
                                "profession",
                                event.target.value
                              )
                            }}
                          />
                        )}

                        ListboxProps={
                          {
                            style: {
                              maxHeight: '150px',
                            }
                          }
                        }
                      />
                      {this.finalErrorMessage(errors.profession, touched.profession)}
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel style={AddCustomerWebStyle.fieldLabel}>Organisation</InputLabel>
                      <SimpleCustomAutocomplete
                        data-test-id="organisationDropdown"
                        id="organisation"
                        name="organisation"
                        options={this.state.dropdownOptions.organisation}
                        getOptionLabel={(option: { option: string }) => option.option}
                        inputValue={values.organisation || ''}
                        onChange={(event: unknown, value: unknown) => {
                          let updatedValue = this.checkValue(value);
                          onChangeOrganization(
                            this.getFilteredorganisationTaxOptions(
                              updatedValue
                            ),
                            setFieldValue,
                            updatedValue,
                            "organisationTax"
                          );
                        }}
                        clearOnEscape={false}
                        clearOnBlur={false}
                        onBlur={handleBlur}
                        renderInput={(params: object) => (
                          <InputField
                            {...params}
                            error={this.checkError(errors.organisation, touched.organisation)}
                            variant="outlined"
                            placeholder="Select Organisation"
                            onChange={(event: React.ChangeEvent<{ value: string }>) => {
                              onChangeOrganization(
                                this.getFilteredorganisationTaxOptions(
                                  event.target.value
                                ),
                                setFieldValue,
                                event.target.value,
                                "organisationTax"
                              );
                            }}
                          />
                        )}

                        ListboxProps={
                          {
                            style: {
                              maxHeight: '150px',
                            }
                          }
                        }
                      />
                      {this.finalErrorMessage(errors.organisation, touched.organisation)}
                    </Grid>
                                <Grid item xs={6}>
                                  <InputLabel style={AddCustomerWebStyle.fieldLabel}>Organisation Tax</InputLabel>
                                  <SimpleCustomAutocomplete
                            data-test-id="organisationTaxDropdown"
                            id="organisationTax"
                            name="organisationTax"
                            options={
                            this.getFilteredorganisationTaxOptions(values?.organisation)
                            }
                            getOptionLabel={(option: any) => option?.option}
                            inputValue={values?.organisationTax??''}
                            onChange={(event: any, value: any) => { setFieldValue("organisationTax", this.checkValue(value)); }}
                            onInputChange={(event: any, value: any) => {
                                setFieldValue("organisationTax", this.checkValue(value)) }}
                            clearOnEscape={false}
                            clearOnBlur={false}
                            onBlur={handleBlur}
                        disabled={
                          this.getFilteredorganisationTaxOptions(
                            values?.organisation
                          ).length > 0
                        }
                            renderInput={(params: any) => (
                              <InputField
                                {...params}
                                error={this.checkError(errors.organisationTax, touched.organisationTax)}
                                variant="outlined"
                                placeholder="Organisation Tax"
                              />
                            )}
                            
                            ListboxProps={
                              {
                                style:{
                                    maxHeight: '150px',
                                }
                              }
                            }
                          />
                                  {this.finalErrorMessage(errors.organisation, touched.organisation)}
                                </Grid>
                    <Grid item xs={12}>
                      <InputLabel style={AddCustomerWebStyle.fieldLabel}>Address</InputLabel>
                      <InputField
                        error={this.checkError(errors.street_address, touched.street_address)}
                        id="street_address"
                        name="street_address"
                        fullWidth={true}
                        inputProps={{
                          "data-test-id": "field-address"
                        }}
                        placeholder="Apt Number, Street"
                        variant="outlined"
                        {...getFieldProps("street_address")}
                      />
                      {this.finalErrorMessage(errors.street_address, touched.street_address)}
                    </Grid>
                                <Grid item xs={6}>
                                    <InputLabel style={AddCustomerWebStyle.fieldLabel}>City</InputLabel>
                                    <SimpleCustomAutocomplete
                                      clearOnEscape={false}
                                      clearOnBlur={false}
                                      onBlur={handleBlur}
                                      ListboxProps={
                                        {
                                          style: {
                                            maxHeight: '150px',
                                          }
                                        }
                                      }
                                      data-test-id="cityDropdown"
                                      id="city"
                                      name="city"
                                      onChange={(event: unknown, value: { id: string }) => { setFieldValue("city", safeExtraction(value,"id"))}}
                                      inputValue={getOptionLabelByValue(values.city,"id",this.state.dropdownOptions.city,"name")}
                                      renderInput={(params: object) => (
                                        <InputField
                                          {...params}
                                          error={this.checkError(errors.city, touched.city)}
                                          variant="outlined"
                                          placeholder="Select City"
                                          onChange={(event: React.ChangeEvent<{ value: string }>) => {
                                            this.handleOnInputChange(
                                              setFieldValue,
                                              "city",
                                              event.target.value
                                            )
                                          }}
                                        />
                                      )}
                                      options={this.state.dropdownOptions.city}
                                      getOptionLabel={(option: { name: string }) => option.name}
                                    />
                                    {this.finalErrorMessage(errors.city, touched.city)}
                                </Grid>
                    <Grid item xs={6}>
                      <InputLabel style={AddCustomerWebStyle.fieldLabel}>Postal Code</InputLabel>
                      <InputField
                        type="number"
                        error={this.checkError(errors.postal_code, touched.postal_code)}
                        id="postal_code"
                        name="postal_code"
                        fullWidth={true}
                        placeholder="Enter Your Area Code"
                        inputProps={{
                          'data-test-id': 'field-postalCode'
                        }}
                        variant="outlined"
                        {...getFieldProps("postal_code")}
                      />
                      {this.finalErrorMessage(errors.postal_code, touched.postal_code)}
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel style={AddCustomerWebStyle.fieldLabel}>Id</InputLabel>
                      <InputField
                        error={this.checkError(errors.id, touched.id)}
                        id="id"
                        name="id"
                        fullWidth={true}
                        placeholder="Enter Employee"
                        variant="outlined"
                        inputProps={{
                          "data-test-id": "field-employeeId"
                        }}
                        {...getFieldProps("id")}
                      />
                      {this.finalErrorMessage(errors.id, touched.id)}
                    </Grid>
                                <Grid item xs={6}>
                                    <InputLabel style={AddCustomerWebStyle.fieldLabel}>Business</InputLabel>
                                    <SimpleCustomAutocomplete
                                      clearOnEscape={false}
                                      clearOnBlur={false}
                                      onBlur={handleBlur}
                                      ListboxProps={
                                        {
                                          style: {
                                            maxHeight: '150px',
                                          }
                                        }
                                      }

                                      data-test-id="businessDropdown"
                                      id="business"
                                      name="business"
                                      onChange={(event: unknown, value: { id: string }) => { setFieldValue("business", safeExtraction(value,"id")) }}
                                      inputValue={getOptionLabelByValue(values.business, "id", this.state.dropdownOptions.business, "option")}
                                      renderInput={(params: object) => (
                                        <InputField
                                          {...params}
                                          error={this.checkError(errors.business, touched.business)}
                                          variant="outlined"
                                          placeholder="Select Business Category"
                                          onChange={(event: React.ChangeEvent<{ value: string }>) => {
                                            this.handleOnInputChange(
                                              setFieldValue,
                                              "business",
                                              event.target.value
                                            )
                                          }}
                                        />
                                      )}
                                      options={this.state.dropdownOptions.business}
                                      getOptionLabel={(options: { option: string }) => options.option}
                                    />
                                    {this.finalErrorMessage(errors.business, touched.business)}
                                </Grid>
                    <Grid item xs={6}>
                      <InputLabel style={AddCustomerWebStyle.fieldLabel}>Price List</InputLabel>
                      <AutocompleteSelect
                        isDisabled={!this.state.permissionStatus.isHaveLinkPricelistPermission}
                        data-test-id='priceListDropdown'
                        fieldName='price_list'
                        className='simple-selection-box'
                        placeholder='Select price list'
                        handleScroll={this.handleScrollPricelistDropdown.bind(
                          this
                        )}
                        debouncedFunction={(getValue: string) => {
                          this.debouncedFunction(
                            getValue,
                            this.handleAutoCompleteChange
                          );
                        }}
                        onChange={(
                          _blank: unknown,
                          value: { id: number; option: string } | null
                        ) => {
                          this.handleChangeSelectedPriceList(
                            value,
                            setFieldValue
                          );
                        }}
                        value={values.price_list}
                        options={this.state.dropdownOptions.price_list}
                        listBoxStyle={{ maxHeight: 270 }}
                      />
                      {this.finalErrorMessage(errors.price_list, touched.price_list)}
                    </Grid>
                    {renderBaseOnConditions(
                      String(values.price_list) !==
                        this.state.isMasterPricelistId,
                      <Grid item xs={6} className='customerBirthdate'>
                        <InputLabel style={AddCustomerWebStyle.fieldLabel}>
                          {configJSON.txtLblPriceListExp}
                        </InputLabel>
                        <DatePicker
                          data-test-id='pricelist-expiry-date'
                          name='price_list_expiry_date'
                          id='price_list_expiry_date'
                          minDate={new Date()}
                          onChange={(event) => {
                            setFieldValue("price_list_expiry_date", event);
                          }}
                          selected={
                            (values.price_list_expiry_date &&
                              new Date(values.price_list_expiry_date)) ||
                            ""
                          }
                          value={sortStringCondition(
                            values.price_list_expiry_date,
                            moment(values.price_list_expiry_date).format(
                              "DD/MM/YYYY"
                            ),
                            ""
                          )}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode='select'
                          dateFormat='dd/MM/yyyy'
                          placeholderText='Select Date'
                          className={sortStringCondition(
                            this.checkError(
                              errors.price_list_expiry_date,
                              touched.price_list_expiry_date
                            ),
                            "birthdate-picker-errors",
                            "birthdate-picker"
                          )}
                        />
                        <img
                          src={calenderIcon}
                          alt='calender-icon'
                          style={AddCustomerWebStyle.calenderIcon}
                        />
                        {this.finalErrorMessage(
                          errors.price_list_expiry_date,
                          touched.price_list_expiry_date
                        )}
                      </Grid>,
                      <></>
                    )}
                    {renderBaseOnConditions(
                      String(values.price_list) !==
                        this.state.isMasterPricelistId,
                      <Grid item xs={6}>
                        <InputLabel style={AddCustomerWebStyle.fieldLabel}>
                          {configJSON.txtLblPriceListRegion}
                        </InputLabel>
                        <Box style={{ height: "10px" }} />
                        <AutocompleteSelect
                          fieldName='price_list_region_id'
                          placeholder='Select Region'
                          options={this.state.regionList}
                          value={values.price_list_region_id}
                          isRadioButtonShow={false}
                          onChange={(
                            _event: React.ChangeEvent,
                            value: { id: number } | null
                          ) => {
                            this.handleChangePriceListRegion(
                              value,
                              setFieldValue
                            );
                          }}
                        />
                      </Grid>,
                      <></>
                    )}
                    {renderBaseOnConditions(
                      String(values.price_list) !==
                        this.state.isMasterPricelistId,
                      <Grid item xs={6}>
                        <InputLabel style={AddCustomerWebStyle.fieldLabel}>
                          {configJSON.txtLblPriceListArea}
                        </InputLabel>
                        <Box style={{ height: "10px" }} />
                        <AutocompleteSelect
                          fieldName='price_list_area_id'
                          placeholder='Select Area'
                          options={this.state.dropdownOptions.area_list}
                          value={values.price_list_area_id}
                          isRadioButtonShow={false}
                          onChange={(
                            _event: React.ChangeEvent,
                            value: { id: number } | null
                          ) => {
                            this.handleChangePriceListArea(
                              value,
                              setFieldValue
                            );
                          }}
                        />
                      </Grid>,
                      <></>
                    )}
                    {renderBaseOnConditions(
                      String(values.price_list) !==
                        this.state.isMasterPricelistId,
                      <Grid item xs={6}>
                        <InputLabel style={AddCustomerWebStyle.fieldLabel}>
                          {configJSON.txtLblPriceListStore}
                        </InputLabel>
                        <Box style={{ height: "10px" }} />
                        <AutocompleteSelect
                          fieldName='price_list_store_id'
                          placeholder='Select Store'
                          className={this.checkStoreFieldEmptyError(
                            errors,
                            touched
                          )}
                          options={this.state.dropdownOptions.store_list}
                          value={values.price_list_store_id}
                          isRadioButtonShow={false}
                          onChange={(
                            _event: React.ChangeEvent,
                            value: { id: number } | null
                          ) => {
                            this.handleChangePriceListStore(
                              value,
                              setFieldValue
                            );
                          }}
                        />
                        {this.finalErrorMessage(
                          errors.price_list_store_id,
                          touched.price_list_store_id
                        )}
                      </Grid>,
                      <></>
                    )}
                                <Grid item xs={6}>
                                    <InputLabel style={AddCustomerWebStyle.fieldLabel}>Payment Type</InputLabel>
                                    <SimpleCustomAutocomplete
                                      clearOnEscape={false}
                                      clearOnBlur={false}
                                      onBlur={handleBlur}
                                      ListboxProps={
                                        {
                                          style: {
                                            maxHeight: '150px',
                                          }
                                        }
                                      }

                                      data-test-id="paymentTypeDropdown"
                                      id="payment_type"
                                      name="payment_type"
                                      onChange={(event: unknown, value: { id: string }) => { setFieldValue("payment_type", safeExtraction(value,"id")) }}
                                      inputValue={getOptionLabelByValue(values.payment_type, "id", this.state.dropdownOptions.payment_type, "option")}
                                      renderInput={(params: object) => (
                                        <InputField
                                          {...params}
                                          error={this.checkError(errors.payment_type, touched.payment_type)}
                                          variant="outlined"
                                          placeholder="Select Payment Type"
                                          onChange={(event: React.ChangeEvent<{ value: string }>) => {
                                            this.handleOnInputChange(
                                              setFieldValue,
                                              "payment_type",
                                              event.target.value
                                            )
                                          }}
                                        />
                                      )}
                                      options={this.state.dropdownOptions.payment_type}
                                      getOptionLabel={(options: { option: string }) => options.option}
                                    />
                                    {this.finalErrorMessage(errors.payment_type, touched.payment_type)}
                                </Grid>
                    <Grid item xs={6}>
                      <InputLabel style={AddCustomerWebStyle.fieldLabel}>Note</InputLabel>
                      <InputField
                        error={this.checkError(errors.notes, touched.notes)}
                        id="notes"
                        name="notes"
                        fullWidth={true}
                        placeholder="Enter Note"
                        variant="outlined"
                        inputProps={{
                          "data-test-id": "field-note"
                        }}
                        {...getFieldProps("notes")}
                      />
                      {this.finalErrorMessage(errors.notes, touched.notes)}
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel style={AddCustomerWebStyle.fieldLabel}>{configJSON.txtLblPrivateNote}</InputLabel>
                      <InputField
                        error={this.checkError(errors.private_note, touched.private_note)}
                        id="private_note"
                        name="private_note"
                        fullWidth={true}
                        placeholder="Enter Private Note"
                        variant="outlined"
                        inputProps={{
                          "data-test-id": "field-privateNote"
                        }}
                        {...getFieldProps("private_note")}
                      />
                      {this.finalErrorMessage(errors.private_note, touched.private_note)}
                    </Grid>
                    <Grid item xs={6}>
                      {this.returnCompensationCreditLabel()}
                      <InputField
                        error={this.checkError(errors.compensation_credit, touched.compensation_credit)}
                        id="compensation_credit"
                        name="compensation_credit"
                        fullWidth={true}
                        placeholder="Enter Total Compensation Credit"
                        variant="outlined"
                        inputProps={{
                          "data-test-id": "field-compensationCredit"
                        }}
                        {...getFieldProps("compensation_credit")}
                      />
                      {this.finalErrorMessage(errors.compensation_credit, touched.compensation_credit)}
                    </Grid>
                                </Grid>
                                {
                                  !this.props.isEdit &&
                                <Typography data-test-id="open-preference" style={{ marginTop: "20px", display: 'flex', alignItems: 'center', cursor: 'pointer' }} >
                                  <img src={addIcon} alt="plus icon" />
                                  <Typography data-test-id="openModal"
                                    onClick={() => this.openModal(values.price_list)}
                                    style={{ fontSize: '16px', fontWeight: 600, color: '#204B9C', marginLeft: '8px' }}
                                  >
                                    Create Preference
                                  </Typography>
                                </Typography>
                                }
                                <Box style={{ marginTop: '48px', marginBottom: '40px', textAlign: 'center' }}>
                                    <Button
                                        data-test-id="cancelBtn"
                                        onClick={()=>{this.props.handleClose()}}
                                        style={{ ...AddCustomerWebStyle.addButton, textTransform: "unset", backgroundColor: '#F1F5F9', color: '#64748B' }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        data-test-id="submitBtn"
                                        type="submit"
                                        style={{ ...AddCustomerWebStyle.addButton, textTransform: "unset" }}
                                    >
                                      {this.getSaveButtonText()}                                        
                                    </Button>
                                </Box>
                            </form>
                    )}
            </Formik>
            {
              this.state.preferenceModalVisible &&
            <CreateCustomerPreference
                customerId={this.props.customerData?.id} 
                priceId={
                  sortStringCondition(
                    this.state.priceListId !== "",
                    this.state.priceListId,
                    this.state.isMasterPricelistId
                  )
                }
                data-test-id='createPreferenceModal'
                onCreateForCustomer={(data) => this.handleCreatePreferenceForCustomer(data)}
                selectedService={{}}
                preferenceData={undefined}
                handleClose={this.handleClosePreference}
                data={[]}
                headerTitle={"Create Item Preference"}
                saveForFuture={true}
                open={this.state.preferenceModalVisible}
                responseData={{
                  id: "11",
                  type: "preference",
                  attributes: {
                    id: 11,
                    name: "Preference 1",
                    product_second_name: "Product 1",
                    specifications: [],
                    upcharges: [],
                    preferences: [],
                    services: [],
                  },
                }}
                onCreate={(data) => {}}
              />
            }
              <Snackbar
                data-test-id="snackbar1"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                open={this.state.openPriceListSnackbar}
                autoHideDuration={600}
                onClose={() => { this.setState({ openPriceListSnackbar: false }) }}
              >
                <Alert data-test-id="alert-snackbar1" severity={"success"} >
                  {this.state.snackbarMessage}
                </Alert>
              </Snackbar>
              <Snackbar
                data-test-id="snackbar"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                open={this.state.openErrorSnackbar}
                autoHideDuration={2000}
                onClose={() => { this.setState({ openErrorSnackbar: false }) }}
              >
                <Alert data-test-id="alert-snackbar" severity={"error"} >
                  {this.state.snackbarErrorMessage}
                </Alert>
              </Snackbar>
            </Box>
          </Box>
         
        </Modal>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const AddCustomerWebStyle:any = {
  addButton: {
    width: "184px",
    height: "56px",
    color: "#FFFFFF",
    background: "#204B9C",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    borderRadius: "8px",
    marginLeft: 10
  },
  fieldError: {
    color: "red",
    fontSize: "14px",
    margin: "5px 5px 5px 0",
    fontFamily: 'Montserrat'
  },
  fieldLabel: {
    marginBottom: "2px",
    marginTop: '10px',
    fontSize: "16px",
    color: "#334155",
    fontWeight: 600,
    fontFamily: 'Montserrat'
  },
  addPriceHeader: {
    fontWeight: 600,
    marginBottom: '24px',
    color: '#1a1a1a',
    fontSize: '24px',
    textAlign: 'center'
  },
  calenderIcon: {
    position: 'absolute',
    right: '18px',
    top: '60px',
    pointerEvents: 'none'
  },
  compensationtxt: {
    display: "inline-block",
    color: colors().cyancobaltblue,
    fontWeight: 600,
  }
};

const addCustomerModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "660px",
  maxHeight: "92%",
  bgcolor: "#fff",
  borderRadius: 24,
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  overflowY: "hidden",
  "@media (max-width: 768px)": {
    padding: "20px",
    width: "75%",
  },
};

const modalScrollbar = {
  padding: "24px 36px",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "6px",
  },
  "&::-webkit-scrollbar-track": {
    "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#d1d1d1",
    borderRadius: "10px",
  },
};
// Customizable Area End
