import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { MenuProps as AcMenuProps } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/styles";
import { makeApiMessage } from "../../../components/src/common";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
import { IMultiSelectOption } from "../../../components/src/Interface";
import { debounce } from "lodash";
import {
  setStorageData,
  getStorageData,
  removeStorageData
} from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
interface ManagerAccount {
  id: string;
  type: string;
  attributes: {
    id: number;
    first_name: string;
    last_name: string;
    full_name: string;
  };
}

export interface ManagerListDataStructure {
  data: ManagerAccount[];
}

export interface ISection {
  label: string;
  value: string;
}

interface PermissionObj {
  permission_group_ids: Array<number>;
  permission_sub_group_ids: Array<number>;
}

export interface IStore {
  id: string;
  attributes: {
    id: number;
    store_name: string;
  };
}

export enum FormMode {
  Create,
  Edit,
  View,
}

enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH",
}

export interface IForm {
  first_name: string;
  last_name: string;
  country_code: number | string;
  full_phone_number?: string;
  phone_number: string;
  email: string;
  user_type: number | null;
  user_login_id: string | null;
  department_name: string;
  position: string;
  region_ids: number[];
  area_ids: number[];
  direct_manager_name: string | null;
  password?: string;
  status: string;
  role_id: number | null;
  plant_section: string | null;
  region_manager_id: string | null;
  store_management_ids?: number[] | string[];
  permission_group_ids: number[];
  permission_sub_group_ids: number[];
  first_name_arabic?: string;
  last_name_arabic?: string;
}

export interface IRenderView {
  first_name: string;
  last_name: string;
  full_phone_number?: string;
  phone_number: string;
  email: string;
  user_type: number | null;
  user_login_id: string;
  department_name: string;
  position: string;
  direct_manager_name: string;
  password: string;
  status: string;
  role_id: number | null;
  first_name_arabic?: string;
  last_name_arabic?: string;
}
export interface ISubPermissionGroup {
  id: number;
  name: string;
  permitted: boolean;
}
export interface IPermissionGroupItem {
  id: number;
  name: string;
  permission_group_key: string;
  permitted: boolean;
  permission_sub_groups: Array<ISubPermissionGroup>
}

export interface IRoleListItem {
  id: number;
  name: string;
  permission_groups: IPermissionGroupItem[];
}

export interface IRegionListItemAccount {
  option: string;
  id: string;
}

export interface IRegion {
  data: {
    id: number;
    type: string;
    attributes: {
      id: number;
      branch_name: string;
      branch_id: string;
      email: string;
      telephone: string;
      others: {};
      branch_short_name: string;
      activated: boolean;
      created_at: string;
      updated_at: string;
    };
  };
}

export interface IStoreManagement {
  store_id: number;
  store_name: string;
}

export type ValueViewType = string | undefined | null | number;

export type RenderViewValue = string | undefined | null | number;

const ITEM_HEIGHT = 80;
const ITEM_PADDING_TOP = 8;
export const MenuProps: Partial<AcMenuProps> = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};
// Customizable Area End

export interface Props {
  // Customizable Area Start
  classes?: ClassNameMap<string>;
  navigation: {
    navigate: Function,
    getParam: Function,
    goBack: Function
  };
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  plantSections: ISection[] | string[];
  formErrors: { [key: string]: string };
  infoModalVisible: boolean;
  form: IForm;
  roleList: IRoleListItem[];
  regionListAccount: IRegionListItemAccount[];
  initialRegionList: IRegionListItemAccount[];
  areaListAccount: IRegionListItemAccount[];
  initialAreaList: IRegionListItemAccount[];
  isLoading: boolean;
  isLoadingUserDetail: boolean;
  isSubmitting: boolean;
  formMode: FormMode;
  permission_groups: IPermissionGroupItem[];
  dynamicContent: string;
  snackbarOpen: boolean;
  snackbarMessage: string;
  storeList: IRegionListItemAccount[];
  currentUserId: string;
  permissionModalOpen: boolean;
  selectedRoleId: number;
  mainPermissionGroups: IPermissionGroupItem[];
  selectedPermissions: IPermissionGroupItem[];
  permissionStatus: PermissionStatus;
  managerListData: ManagerAccount[];
  selectedRegions: IMultiSelectOption[];
  selectedRegionsFromList: IMultiSelectOption[];
  selectAllRegion: boolean;

  selectedAreas: IMultiSelectOption[];
  selectedAreasFromList: IMultiSelectOption[];
  selectAllArea: boolean;

  onEditSetAreaField : boolean;

  isStoreSelectAll: boolean;
  selectedStore: IRegionListItemAccount[];
  storeSelectedOptionForList: IRegionListItemAccount[];
  userContextRefresh: Function;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AccountCreationController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getRoleListCallId: string = "";
  getStoreListCallId: string = "";
  getRegionListCallIdAccount: string = "";
  getAreaListCallIdAccount: string = "";
  getPasswordCallId: string = "";
  getPermissionsOnRoleId: string = "";
  passwordText: string = "password";
  private formInitialValues: IForm = {
    store_management_ids: [],
    first_name: "",
    last_name: "",
    country_code: "+966",
    full_phone_number: "",
    phone_number: "",
    email: "",
    user_type: null,
    user_login_id: "",
    department_name: "",
    position: "",
    region_ids: [],
    area_ids: [],
    direct_manager_name: null,
    password: "",
    status: "regular",
    role_id: null,
    plant_section: null,
    region_manager_id: null,
    permission_group_ids: [],
    permission_sub_group_ids: []
  };

  public RequestMessage = {
    GetStates: this.buildRequestMessage(Method.GET),
    CreatePaymentTerms: this.buildRequestMessage(Method.POST),
    CreateAccount: this.buildRequestMessage(Method.POST),
    UpdateAccount: this.buildRequestMessage(Method.PUT),
    GetRegionListAccount: this.buildRequestMessage(Method.GET),
    GetAreaListAccount: this.buildRequestMessage(Method.GET),
    GetPassword: this.buildRequestMessage(Method.GET),
    GetRoleList: this.buildRequestMessage(Method.GET),
    GetDynamicContent: this.buildRequestMessage(Method.GET),
    GetUserDetail: this.buildRequestMessage(Method.GET),
    GetStores: this.buildRequestMessage(Method.GET),
    GetPlantSections: this.buildRequestMessage(Method.GET),
    Null: undefined as unknown,
    GetManagerList: this.buildRequestMessage(Method.GET),
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.LayoutDataMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      formErrors: {},
      infoModalVisible: false,
      form: { ...this.formInitialValues },
      selectedRegions: [],
      selectedRegionsFromList: [],
      selectAllRegion: false,
      selectedAreas: [],
      selectedAreasFromList: [],
      selectAllArea: false,
      roleList: [],
      regionListAccount: [],
      initialRegionList: [],
      areaListAccount: [],
      initialAreaList: [],
      isLoading: false,
      isLoadingUserDetail: false,
      isSubmitting: false,
      formMode: FormMode.Create,
      permission_groups: [],
      dynamicContent: "",
      snackbarOpen: false,
      snackbarMessage: "",
      storeList: [],
      currentUserId: "",
      plantSections: [],
      permissionModalOpen: false,
      selectedRoleId: -1,
      mainPermissionGroups: [],
      selectedPermissions: [],
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      },
      managerListData: [],
      onEditSetAreaField: false,

      isStoreSelectAll: false,
      selectedStore: [],
      storeSelectedOptionForList: [],
      userContextRefresh: ()=>{}
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    this.receiveDataFromLayout(message)
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const callID = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (callID) {
      case this.RequestMessage.GetRoleList.messageId:
        this.handleGetRoleListResponse(response);
        break;

      case this.RequestMessage.GetRegionListAccount.messageId:
        this.handleGetRegionListResponseAccount(response);
        break;

      case this.RequestMessage.GetAreaListAccount.messageId:
        this.handleGetAreaListResponseAccount(response);
        break;

      case this.RequestMessage.GetStores.messageId:
        this.handleGetStoreListResponseAccount(response);
        break;

      case this.RequestMessage.GetPassword.messageId:
        this.handleGetPasswordResponse(response);
        break;

      case this.RequestMessage.GetDynamicContent.messageId:
        this.handleGetDynamicContentResponse(response);
        break;

      case this.RequestMessage.GetUserDetail.messageId:
        this.handleGetUserDetailResponse(response);
        break;

      case this.RequestMessage.CreateAccount.messageId:
        this.handleCreateAccountResponse(response);
        break;

      case this.RequestMessage.UpdateAccount.messageId:
        this.handleUpdateAccountResponse(response);
        break;

      case this.RequestMessage.GetPlantSections.messageId:
        this.handlePlantSectionsListResponse(response);
        break;

      case this.getPermissionsOnRoleId:
        this.handlePermissionResponseOnRole(response);
        break;

      case this.RequestMessage.GetManagerList.messageId:
        this.handleManagerListResponse(response);
        break;
    }
    // Customizable Area End
  }

  // Customizable Area Start

  receiveDataFromLayout = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.LayoutMessageData)
        );
        if (recievedData.userContext) {
          this.handleUserChange(recievedData.userContext)
        }
    }
  }

  handlePlantSectionsListResponse = (response: ISection[]) => {
    if (response && Array.isArray(response)) {
      this.setState({ plantSections: response });
    }
  };

  handlePermissionResponseOnRole = (response: {
    status: number,
    data: Array<IPermissionGroupItem>
  }) => {
    if(!response.data) return;
    this.setState({
      mainPermissionGroups: response.data,
      form: {
        ...this.state.form,
        permission_group_ids: 
          this.handleReturnSelectedPermission(response.data).permission_group_ids,
        permission_sub_group_ids: 
          this.handleReturnSelectedPermission(response.data).permission_sub_group_ids,
      },
    }, () => this.handlePermissionViewArray());
  };

  getPlantSection(): ISection[] {
    const { plantSections } = this.state;
    if (Array.isArray(plantSections) && plantSections?.length > 0) {
      const isString = typeof plantSections[0] === "string";
      if (isString) {
        const array = plantSections as unknown[] || []
        return array.map((item) => ({
          label: item as string,
          value: item as string,
        })) as ISection[];
      }
    }
    return (plantSections || []) as ISection[];
  }

  handleGetDynamicContentResponse = (response: {
    data: {
      id: string;
      type: string;
      attributes: {
        icon: string;
        id: number;
        key: string;
        module_name: string;
        value: string;
      };
    }[];
  }) => {
    if (response && response.data) {
      const dynamicContent = response.data.map((item) => {
        if (item.attributes.key === "account_approval_text") {
          this.setState({
            dynamicContent: item.attributes.value,
          });
        }
      });

      this.setState({
        isLoading: false,
      });
    }
  };

  private handleGetRoleListResponse(response: {
    data: {
      id: string;
      type: string;
      attributes: { id: number; name: string; permission_groups: Array<IPermissionGroupItem> };
    }[];
  }) {
    if (response && response.data) {
      const roleArray = response.data.map(
        (item: {
          attributes: {
            id: number;
            name: string;
            permission_groups: Array<IPermissionGroupItem>;
          };
        }) => ({
          id: item.attributes.id,
          name: item.attributes.name,
          permission_groups: item.attributes.permission_groups,
        })
      );

      this.setState({
        isLoading: false,
        roleList: roleArray || [],
      });
    }
  }

  private handleGetRegionListResponseAccount(response: {
    data: {
      type: string;
      id: string;
      attributes: { branch_name: string; id: number };
    }[];
  }) {
    if (response && response.data) {
      const optionArray = response.data.map(
        (item: {
          attributes: {
            branch_name: string;
            id: number;
          };
        }) => ({
          option: item.attributes.branch_name,
          id: item.attributes.id.toString(),
        })
      );

      this.setState({
        isLoading: false,
        regionListAccount: optionArray || [],
        initialRegionList: optionArray || []
      });
    }
  }

  private handleGetAreaListResponseAccount(response: {
    data: {
      type: string;
      id: string;
      attributes: { area_name: string; id: number };
    }[];
  }) {
    if (response && response.data) {
      const optionArray = response.data.map(
        (item: {
          attributes: {
            area_name: string;
            id: number;
          };
        }) => ({
          option: item.attributes.area_name,
          id: item.attributes.id.toString(),
        })
      );

      let selectedArea = optionArray.filter(area => this.state.form.area_ids.includes(Number(area.id)))

      this.setState({
        isLoading: false,
        areaListAccount: optionArray || [],
        initialAreaList: optionArray || [],
      }, () => 
        this.state.onEditSetAreaField && 
          this.setState({
          selectedAreas: selectedArea,
          selectedAreasFromList: selectedArea,
          selectAllArea: selectedArea.length === this.state.areaListAccount.length,
          onEditSetAreaField: false
        }));
    }
  }

  handleGetStoreListResponseAccount(response: { data: IStore[] }) {
    this.setState({
      storeList: response.data.map((item) => ({id: item.id, option: item.attributes.store_name})),
    });
  }

  private handleGetPasswordResponse(response: {
    data: {
      id: string;
      type: string;
      attributes: {
        password: string;
      };
    };
  }) {
    if (response) {
      const {
        data: {
          attributes: { password },
        },
      } = response;

      this.setState({
        form: {
          ...this.state.form,
          password,
        },
        isLoading: false,
      });
    }
  }

  private handleGetUserDetailResponse(response: {
    data: {
      id: string;
      type: string;
      attributes: {
        activated: boolean;
        country_code: string;
        email: string;
        first_name: string;
        first_name_arabic: string;
        last_name_arabic: string;
        role: {
          id: number;
          name: string;
          created_at: string;
          updated_at: string;
        };
        full_phone_number: string;
        last_name: string;
        phone_number: string;
        type: string;
        created_at: string;
        updated_at: string;
        device_id: null;
        unique_auth_id: string;
        status: string;
        user_type: null;
        express_delivery_enable: boolean;
        employee_proifle: {
          data: {
            id: string;
            type: string;
            attributes: {
              id: number;
              department_name: string;
              position: string;
              direct_manager_name: string;
              region: IRegion;
              region_ids: number[]
              area_ids: number[]
              plant_section: string | null;
              region_manager_id: string | null;
              store_managements: IStoreManagement[];
              store_management_ids: number[];
              user_login_id: string;
              location: string;
              employment_type: null;
              profile_logo: null;
              background_cover_photo: null;
            };
          };
        };
        permission_groups: IPermissionGroupItem[];
      };
      relationships: {
        customer_profile: {
          data: null;
        };
      };
    };
  }) {
    if (response) {
      const {
        data: {
          attributes: {
            first_name,
            last_name,
            country_code,
            full_phone_number,
            phone_number,
            email,
            user_type,
            status,
            role,
            employee_proifle,
            permission_groups,
            first_name_arabic,
            last_name_arabic
          },
        },
      } = response;

      const region_ids = employee_proifle.data.attributes.region_ids;

      let selectedRegion = this.state.regionListAccount.filter(region => region_ids.includes(Number(region.id)))

      let selectedStore = employee_proifle.data.attributes.store_managements.map((item) => ({id: String(item.store_id), option: item.store_name}))

      const userDetailFormdata = {
        first_name,
        last_name,
        country_code: "+" + country_code,
        full_phone_number,
        phone_number,
        email,
        user_type,
        user_login_id: employee_proifle.data.attributes.user_login_id,
        department_name: employee_proifle.data.attributes.department_name,
        position: employee_proifle.data.attributes.position,
        region_manager_id: employee_proifle.data.attributes.region_manager_id,
        region_ids: employee_proifle.data.attributes.region_ids,
        area_ids: employee_proifle.data.attributes.area_ids,
        plant_section: employee_proifle.data.attributes.plant_section,
        direct_manager_name:
          employee_proifle.data.attributes.direct_manager_name,
        password: "",
        status,
        role_id: role.id,  
        permission_group_ids: 
          this.handleReturnSelectedPermission(permission_groups).permission_group_ids,
        permission_sub_group_ids: 
          this.handleReturnSelectedPermission(permission_groups).permission_sub_group_ids,
        first_name_arabic,
        last_name_arabic
      };

      this.setState({
        storeSelectedOptionForList:selectedStore,
        selectedStore:selectedStore,
        form: userDetailFormdata,
        currentUserId: response.data.id,
        selectedRoleId: userDetailFormdata.role_id,
        //isLoading: false,
        isLoadingUserDetail: false,
        mainPermissionGroups: permission_groups,
        selectedRegions: selectedRegion,
        selectedRegionsFromList: selectedRegion,
        selectAllRegion: selectedRegion.length === this.state.regionListAccount.length,
        onEditSetAreaField: true
      }, () => { 
        this.handlePermissionViewArray();
        this.getAreaListCallAccount();
        this.getStoreListCall()
       });
    }
  }

  private handleCreateAccountResponse(response: {
    data: { id: string };
    full_messages: string[];
  }) {
    if (response && response.data) {
      this.setState({
        isSubmitting: false,
        infoModalVisible: true,
      });
    } else {
      this.setState({
        isSubmitting: false,
        snackbarOpen: true,
        snackbarMessage: response && response.full_messages[0],
      });
    }
  }

  private handleUpdateAccountResponse(response: {
    data: { id: string };
    full_messages: string[];
  }) {
    this.state.userContextRefresh()
    if (response && response.data) {
      this.setState({
        isSubmitting: false,
      });
      this.props.navigation.navigate("Account");
    } else {
      this.setState({
        isSubmitting: false,
        snackbarOpen: true,
        snackbarMessage: response && response.full_messages[0],
      });
    }
  }

  async componentDidMount() {
    super.componentDidMount();

    this.getRoleListCall();
    this.getRegionListCallAccount();
    this.getPasswordCall();
    this.getDynamicContentCall();
    this.getPlantSectionListCall();
    this.getManagerList()

    const path = window.location.pathname;
    const userId = this.props.navigation.getParam("id");
    this.processPath(path, userId as string);

    this.setStorageValue()
  }

  setStorageValue = async () => {

    const formDetails = await getStorageData("account_form");
    const regionDetails = await getStorageData("region")
    if(formDetails){
        this.setState({
          form:{...JSON.parse(formDetails)},
        },
      ()=>removeStorageData("account_form"))
    }
    if(regionDetails){
      const stateValue = JSON.parse(regionDetails)
      this.setState({
        selectAllRegion: stateValue.selectAllRegion,
        selectedRegions: stateValue.selectedRegions,
        selectedRegionsFromList: stateValue.selectedRegionsFromList,
        selectAllArea: stateValue.selectAllArea,
        selectedAreas: stateValue.selectedAreas,
        selectedAreasFromList: stateValue.selectedAreasFromList
      }, () => {
        removeStorageData("region")
        this.getAreaListCallAccount()
      })
    }
  }

  processPath = (path: string, userId: string) => {
    if (path === `/Settings-AccountEdit/${userId}`) {
      this.setState({ formMode: FormMode.Edit });
      this.getUserDetailCall(userId);
    } else if (path === `/Settings-AccountView/${userId}`) {
      this.setState({ formMode: FormMode.View });
      this.getUserDetailCall(userId);
    }
  };

  validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(/^[\w.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/);
  };

  handleInFoModalClose = () => {
    this.setState({ infoModalVisible: false });
    this.setState({ form: this.formInitialValues });
    this.props.navigation.navigate("Account");
  };

  handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarOpen: false });
  };

  handleAddRoleClick = () => {
    const regionData = {
      selectAllRegion :  this.state.selectAllRegion,
      selectedRegions : this.state.selectedRegions,
      selectedRegionsFromList : this.state.selectedRegionsFromList,
      selectAllArea: this.state.selectAllArea,
      selectedAreas: this.state.selectedAreas,
      selectedAreasFromList: this.state.selectedAreasFromList
    }
    setStorageData("region",JSON.stringify(regionData))
    setStorageData("account_form", JSON.stringify(this.state.form));
    this.props.navigation.navigate("RolesPermissionsCreate2");
  };

  handleSetRoleList = (roleArray: IRoleListItem[]) => {
    this.setState({
      roleList: roleArray,
    });
  };

  handleSetPermissionGroups = (permissionArray: IPermissionGroupItem[]) => {
    this.setState({
      permission_groups: permissionArray,
    });
  };

  handleRoleId = (roleId: number) => {
    this.setState({
      form: {
        ...this.state.form,
        role_id: roleId,
      },
    });
  };

  onFormElementChange = (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }> | {target:{value:unknown}},
    property: string
  ) => {
    this.setState(
      {
        form: {
          ...this.state.form,
          [property]: event.target.value,
        },
      },
      () => {
        this.getPermissionsOnRoleBase();
        this.setState({
          selectedRoleId: this.state.form.role_id as number,
          form: {
            ...this.state.form,
            full_phone_number:
              this.state.form.country_code + this.state.form.phone_number
          },
        });
      }
    );
  };

  handleEditRolePermissionChange = (
    event: { target: { checked: boolean } },
    clickedItemId: number
  ) => {
    const newPermissionGroup = this.state.permission_groups.map(
      (permission) => {
        if (permission.id === clickedItemId) {
          return {
            ...permission,
            permitted: event.target.checked,
          };
        }
        return permission;
      }
    );
    this.setState({ permission_groups: newPermissionGroup });
  };

  handleCancelClick = () => {
    this.props.navigation.goBack();
  };

  handleEditAccountClick = (userId: string) => {
    this.setState({ formMode: FormMode.Edit });
    this.props.navigation.navigate("AccountEdit", {
      id: userId,
    });
  };

  isValid() {
    const formErrors: { [key: string]: string } = {};

    const roleName = this.state.roleList.find(
      (role) => role.id === this.state.form.role_id
    )?.name;

    const isPlantUser = roleName === "Plant User"
    const isEdit = this.state.formMode === FormMode.Edit
    const isRegionManager = roleName === "Region Manager"
    const isStoreKeeper = roleName === "Store Keeper"

    Object.entries(this.state.form).forEach(([key, value]) => {
      const notRequiredFields = [
        "area_ids",
        "store_management_ids",
        "first_name_arabic",
        "last_name_arabic",
      ];
      if (notRequiredFields.includes(key)) {
        return;
      }
      let fieldValidation = [
        (key === "password" && isEdit),
        (!isRegionManager && key === 'region_manager_id'),
        (!isPlantUser && key === 'plant_section'),
        (!isStoreKeeper && key === 'store_management_ids'),
        ["permission_group_ids", "full_phone_number"].includes(key)
      ]

      if (fieldValidation.includes(true)) return false;

      fieldValidation = [
        (!value && value !== 0),
        [undefined, "", "null"].includes(value),
        value?.length === 0
      ]

      if(key.includes("region_ids")) {
        this.regionFieldIsRequired(key,formErrors)
      } else if(key.includes("email") && !this.validateEmail(value)) {
        formErrors.email = configJSON.emailValidText;
      } else if (
        fieldValidation.includes(true)
      ) {
        formErrors[key] = configJSON.fieldRequiredValidText;
      }
    });
    if (Object.keys(formErrors).length > 0) {
      this.setState({ formErrors, isSubmitting: false },()=>this.checkStoreValidation(isStoreKeeper));
      return false;
    }

    return true
  }

  checkStoreValidation = (isStoreKeeper: boolean) => {
    if (isStoreKeeper && this.state.storeSelectedOptionForList.length == 0) {
      this.setState({ formErrors: { ...this.state.formErrors, store_management_ids: configJSON.fieldRequiredValidText } })
    }
  }

  regionFieldIsRequired = (key: string, formErrors: { [key: string]: string }) => {
    let isFieldRequired = false

    if(key.includes("region_ids")) {
      if(this.state.selectedRegionsFromList.length === 0) {
        formErrors.region_ids = configJSON.fieldRequiredValidText;
      }
    }
    return formErrors
  }

  handleOnSubmit = () => {
    const {form, isSubmitting } = this.state;
    if (!form || isSubmitting) return ;
    if (!this.isValid()) return;
    
    const regionIds = this.state.selectedRegionsFromList.filter(region=> region.id !== "-1").map(item => Number(item.id))
    form.region_ids = regionIds;

    const areaIds = this.state.selectedAreasFromList.filter(region=> region.id !== "-1").map(item => Number(item.id))
    form.area_ids = areaIds;

    const storeIds = this.state.storeSelectedOptionForList.filter(store=> store.id !== "-1").map(item => Number(item.id))
    form.store_management_ids = storeIds;
    
    if (window.location.pathname === `/Settings-AccountEdit/${this.state.currentUserId}`) {
      this.updateAccountCall(form, this.props.navigation.getParam("id") as string );
    } else {
      this.createAccountCall(form);
    }
  };

  fillFormErrors = () => {
    this.setState({
      formErrors: {
        first_name: "error",
        last_name: "error",
        country_code: "error",
        phone_number: "error",
        email: "error",
        user_type: "error",
        user_login_id: "error",
        department_name: "error",
        position: "error",
        region_id: "error",
        direct_manager_name: "error",
        status: "error",
        role_id: "error",
      },
    });
  };

  changeFormMode = (formMode: FormMode) => {
    this.setState({
      formMode,
    });
  };

  createAccountCall = (form: IForm) => {
    this.setState({ isSubmitting: true, formErrors: {} });

    this.RequestMessage.CreateAccount.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({...form,user_type:Number(form.user_type)})
    );

    runEngine.sendMessage(
      this.RequestMessage.CreateAccount.id,
      this.RequestMessage.CreateAccount
    );
  };

  updateAccountCall = (form: IForm, user_id: string) => {
    this.setState({ isSubmitting: true, formErrors: {} });
    
    if(this.state.formMode === FormMode.Edit) {
      delete form.password
    }
    this.RequestMessage.UpdateAccount.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateAccountEndPoint}/${user_id}`
    );

    this.RequestMessage.UpdateAccount.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(form)
    );

    runEngine.sendMessage(
      this.RequestMessage.UpdateAccount.id,
      this.RequestMessage.UpdateAccount
    );
  };

  getRoleListCall = () => {
    this.setState({ isLoading: true });

    this.RequestMessage.GetRoleList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.roleListApiEndPoint
    );

    this.getRoleListCallId = this.RequestMessage.GetRoleList.messageId;

    runEngine.sendMessage(
      this.RequestMessage.GetRoleList.id,
      this.RequestMessage.GetRoleList
    );
  };

  getStoreListCall = () => {
    const area_ids = this.state.selectedAreasFromList.filter(area=> area.id !== "-1").map(item => Number(item.id)).join(",");

    this.RequestMessage.GetStores.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.storeManagementApi + `?dropdown=true&allow_access=true&area_ids=${area_ids}`
    );

    this.getStoreListCallId = this.RequestMessage.GetStores.messageId;

    runEngine.sendMessage(
      this.RequestMessage.GetStores.id,
      this.RequestMessage.GetStores
    );
  };

  getRegionListCallAccount = () => {
    this.setState({ isLoading: true });

    this.RequestMessage.GetRegionListAccount.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.regionListApiEndPoint
    );

    this.getRegionListCallIdAccount = this.RequestMessage.GetRegionListAccount.messageId;

    runEngine.sendMessage(
      this.RequestMessage.GetRegionListAccount.id,
      this.RequestMessage.GetRegionListAccount
    );
  };

  getAreaListCallAccount = () => {
    const region_ids = this.state.selectedRegionsFromList.filter(region=> region.id !== "-1").map(item => Number(item.id)).join(",");

    this.RequestMessage.GetAreaListAccount.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.areaListDropDownApiEndPoint + `&region_ids=${region_ids}`
    );

    this.getAreaListCallIdAccount = this.RequestMessage.GetAreaListAccount.messageId;

    runEngine.sendMessage(
      this.RequestMessage.GetAreaListAccount.id,
      this.RequestMessage.GetAreaListAccount
    );
  };

  getPasswordCall = () => {
    this.setState({ isLoading: true });

    this.RequestMessage.GetPassword.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPasswordEndPoint
    );

    runEngine.sendMessage(
      this.RequestMessage.GetPassword.id,
      this.RequestMessage.GetPassword
    );
  };

  getDynamicContentCall = () => {
    this.setState({ isLoading: true });

    this.RequestMessage.GetDynamicContent.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dynamicContentApiEndPoint
    );

    runEngine.sendMessage(
      this.RequestMessage.GetDynamicContent.id,
      this.RequestMessage.GetDynamicContent
    );
  };

  getUserDetailCall = (user_id: string) => {
    this.setState({ isLoadingUserDetail: true });

    this.RequestMessage.GetUserDetail.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userDetailEndPoint}/${user_id}`
    );

    runEngine.sendMessage(
      this.RequestMessage.GetUserDetail.id,
      this.RequestMessage.GetUserDetail
    );
  };

  private buildRequestMessage(method: Method): Message {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.employeesApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMessage),
      configJSON.APIBaseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      this.getHeaderMessage()
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method.toString()
    );

    return requestMessage;
  }

  getHeaderMessage() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: window.localStorage.getItem(configJSON.token),
    };

    return JSON.stringify(header);
  }

  getPlantSectionListCall = () => {
    this.setState({ isLoading: true });

    this.RequestMessage.GetPlantSections.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.plantSectionsApiEndPoint
    );

    runEngine.sendMessage(
      this.RequestMessage.GetPlantSections.id,
      this.RequestMessage.GetPlantSections
    );
  };

  handleOpenPermissionModal = () => {
    this.setState({
      permissionModalOpen: true
    });
  };

  handleClosePermissionModal = () => {
    this.setState({
      permissionModalOpen: false
    });
  };

  handleChangePermission = (
    changePermisson: PermissionObj,
    returnedPermissions: IPermissionGroupItem[]
  ) => { 
    this.handleClosePermissionModal();
    this.setState({
      form: {
        ...this.state.form,
        permission_sub_group_ids:
          this.removeDuplicatesNummerFromArray(changePermisson.permission_sub_group_ids),
        permission_group_ids:
          this.removeDuplicatesNummerFromArray(changePermisson.permission_group_ids)
      },
      mainPermissionGroups: returnedPermissions
    }, () => this.handlePermissionViewArray());
  };

  handleRemoveDuplicates = (array: Array<IPermissionGroupItem>) => {
    return array.filter((item, index) => array.indexOf(item) === index);
  };

  handlePermissionViewArray = () => {
    const { form, mainPermissionGroups } = this.state;
    const tempPermissionList: Array<IPermissionGroupItem> = [];
    mainPermissionGroups.forEach((parentItem) => {
      parentItem.permission_sub_groups.forEach((subItem) => {
        if (form.permission_sub_group_ids.includes(subItem.id)) {
          tempPermissionList.push(parentItem)
        }
      });
    });
    this.setState({
      selectedPermissions: this.handleRemoveDuplicates(tempPermissionList)
    })
  };

  handleReturnSelectedPermission = (permissionGroups: Array<IPermissionGroupItem>) => {
    let permission_group_ids: Array<number> = [];
    let permission_sub_group_ids: Array<number> = [];

    permissionGroups.forEach((parentItem) => {
      parentItem.permission_sub_groups.forEach((subItem) => {
        if (subItem.permitted) {
          permission_group_ids.push(parentItem.id);
          permission_sub_group_ids.push(subItem.id);
        }
      });
    });

    const outputObj = {
      permission_group_ids:
        this.removeDuplicatesNummerFromArray(permission_group_ids),
      permission_sub_group_ids:
        this.removeDuplicatesNummerFromArray(permission_sub_group_ids)
    }
    return outputObj;

  };

  removeDuplicatesNummerFromArray = (array: Array<number>) => {
    return array.filter((item, index) => array.indexOf(item) === index);
  };

  getPermissionsOnRoleBase = () => {
    const { form, mainPermissionGroups, selectedRoleId} = this.state;
    if(form.role_id !== null) {
      if(form.role_id == selectedRoleId) {
        return mainPermissionGroups;
      } else {
        const apiUrl = configJSON.permissionOnRoleEndpoint;

        let message = makeApiMessage({
          url: apiUrl + `?id=${form.role_id}`,
          method: "GET",
        });
        this.getPermissionsOnRoleId = message.messageId;
        runEngine.sendMessage(message.id, message);
      }
    } else {
      return mainPermissionGroups;
    }
  };

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.accountPermission;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value,
      userContextRefresh: userContext.refreshUser
    })
  };

  getManagerList = () => {
    this.setState({ isLoading: true });

    this.RequestMessage.GetManagerList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.managerListEndPoint}`
    );

    runEngine.sendMessage(
      this.RequestMessage.GetManagerList.id,
      this.RequestMessage.GetManagerList
    );
  };

  handleManagerListResponse = (response: ManagerListDataStructure) => {
    if (response && Array.isArray(response.data)) {
      this.setState({ managerListData: response.data });
    }
  };

  convertToIOptionsForManager(options:ManagerAccount[]) {
    return options.map(option => ({
      id: option.id,
      label: `${option.attributes.first_name} ${option.attributes.last_name}`,
    }));
  }

  handleSelectRegionOptions = (option: { id: string, option: string }) => {
    const {regionListAccount} = this.state
    const {selectedRegionsFromList, selectAllRegion} = this.state
    if (option.id === "-1") {
        this.setState({
            ...selectAllRegion ? {
              selectAllRegion: false,
              selectedRegions: [],
              selectedRegionsFromList: [],
              areaListAccount: [],
              initialAreaList: []
            } : {

              selectAllRegion: true,
              selectedRegions: [{ id: "-1", option: "Select All" }],
              selectedRegionsFromList: [{ id: "-1", option: "Select All" }, ...regionListAccount]
            }
        } as S, this.handleRegionChangeEffect)
    } else {
      const isValueContained = selectedRegionsFromList.some((item) => item.id == option.id)
      const currentSelectedRegions = selectedRegionsFromList.filter((item) => item.id !== "-1")
      const newItems = isValueContained ? currentSelectedRegions.filter((item) => item.id != option.id) : [...currentSelectedRegions, option]
      this.setState({
        selectAllRegion: false,
        selectedRegions: newItems,
        selectedRegionsFromList: newItems
      }, this.handleRegionChangeEffect)
    }

    this.setState({
      selectAllArea: false,
      selectedAreas: [],
      selectedAreasFromList: [],
      isStoreSelectAll: false,
      selectedStore: [],
      storeSelectedOptionForList: [],
      storeList: [],areaListAccount: []
    })
  };

  handleRegionChangeEffect = () => this.state.selectedRegionsFromList.length && (this.getAreaListCallAccount());

  handleAreaChangeEffect = () => this.state.selectedAreasFromList.length && (this.getStoreListCall());

  handleSelectAreaOptions = (option: { id: string, option: string }) => {
    const {areaListAccount} = this.state
    const {selectedAreasFromList, selectAllArea} = this.state
    if (option.id === "-1") {
        this.setState({
            ...selectAllArea ? {
              selectAllArea: false,
              selectedAreas: [],
              selectedAreasFromList: [],
            } : {

              selectAllArea: true,
              selectedAreas: [{ id: "-1", option: "Select All" }],
              selectedAreasFromList: [{ id: "-1", option: "Select All" }, ...areaListAccount]
            }
        }, this.handleAreaChangeEffect)
    } else {
      const isValueContained = selectedAreasFromList.some((item) => item.id == option.id)
      const currentSelectedAreas = selectedAreasFromList.filter((item) => item.id !== "-1")
      const newItems = isValueContained ? currentSelectedAreas.filter((item) => item.id != option.id) : [...currentSelectedAreas, option]
      this.setState({
        selectAllArea: false,
        selectedAreas: newItems,
        selectedAreasFromList: newItems
      }, this.handleAreaChangeEffect)
    }

    this.setState({
      isStoreSelectAll: false,
      selectedStore: [],
      storeSelectedOptionForList: [],
      storeList: []
    })
  };

  handleSearchRegionList = (getValue: string) => {
    if(getValue === "") {
      this.setState({regionListAccount: this.state.initialRegionList})
    } else {
      const result = this.state.regionListAccount.filter((region) => region.option.toLowerCase().includes(getValue.toLowerCase()))
      this.setState({regionListAccount: result})
    }
  }

  handleSearchAreaList = (getValue: string) => {
    if(getValue === "") {
      this.setState({areaListAccount: this.state.initialAreaList})
    } else {
      const result = this.state.areaListAccount.filter((area) => area.option.toLowerCase().includes(getValue.toLowerCase()))
      this.setState({areaListAccount: result})
    }
  }
  

  debouncedFunction = debounce(
    (newInputValue: string, inputFunction: (inputValue: string) => void) => inputFunction(newInputValue),
    700,
    { maxWait: 2000 }
  );

  handleStoreMultiSelect = (value: { id: string, option: string }[], option: { id: string, option: string }) => {
    let updateStateData = {} as {isStoreSelectAll:boolean,selectedStore:IRegionListItemAccount[],storeSelectedOptionForList:IRegionListItemAccount[]}
    if (option.id == "-1") {
      updateStateData = this.state.isStoreSelectAll ?
        { ...updateStateData, isStoreSelectAll: false, selectedStore: [], storeSelectedOptionForList: [] }
        :
        {
          ...updateStateData,
          isStoreSelectAll: true,
          selectedStore: [{ id: "All", option: "Select All" }],
          storeSelectedOptionForList: [{ id: "-1", option: "Select All" }, ...this.state.storeList]
        };

    } else {
      const isValueContained = value.some((item) => item.id == option.id)
      updateStateData.isStoreSelectAll = false
      value = value.filter((item) => item.id !== "-1")
      const newItems = isValueContained ? value.filter((item) => item.id != option.id) : [...value, option]
      updateStateData.selectedStore = newItems
      updateStateData.storeSelectedOptionForList = newItems
    }
    this.setState(updateStateData)
  };

  // Customizable Area End
}
