import React, { useCallback, useMemo, useState, useEffect, useRef} from "react";
import { styled } from "@material-ui/core";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, TooltipProps } from 'recharts';
import { colors } from '../../../blocks/utilities/src/Colors';
import * as assets from "./assets";
import { LoadingChart } from "../CustomCharts";

interface Props {
    title: string
    subTitle?: string
    description: string
    chartData: Array<{ name: string;[key: string]: number | string }>
    barItems: Array<{
        label: string
        dataKey?: string
        color?: string
        tooltipValue?: string
    }>
    wrapperClassName?: string
    isScrollable?: boolean,
    isLoading?: boolean
}

const defaultColors = [colors().darkblue, colors().dividerColor]
const defaultDataKeys = ["uv", "pv"]

const CustomColumnChart = (props: Props) => {
    const { title, subTitle, description, chartData, barItems, wrapperClassName, isScrollable, isLoading = false } = props
    const [columnSize, setColumnSize] = useState(90)
    const containerRef = useRef<HTMLDivElement>(null)
    const [tooltipPos, setTooltipPos] = useState({left:0, top:0, transform: "translate(-50%, -100%)"})
    
    useEffect(() => {
        const handleMouseMove = (event: MouseEvent) => {
            setTooltipPos({
                left: event.clientX,
                top: event.clientY,
                transform: event.clientX + 100 > document.body.clientWidth ? "translate(-100%, -100%)" : "translate(-50%, -100%)"
            })
        }

        document.body.addEventListener("mousemove", handleMouseMove)

        return () => {
            document.body.removeEventListener("mousemove", handleMouseMove)
        }
    },[])

    const customYAxisTicks = useMemo(() => {
        if (!isScrollable) return []
        const dataKeys = barItems.map(item => item.dataKey) as string[]
        const maxValue = Math.max(
            ...dataKeys.map(dataKey => Math.max(
                ...chartData.map(item => item[dataKey] as number)
                )
            )
        )
        const maxYAxisValue = getMaxYAxisValue(maxValue);
        const interval = maxYAxisValue / 4;
        const ticks = [];
        for (let i = 0; i < 5; i++) {
            ticks.push(0 + i * interval);
        }
        return ticks;
    },[isScrollable, chartData])

    const Container = useCallback(({children}: {children: React.ReactElement}) => isScrollable ? (
        <CustomContainer>
            <div className="custom-wrapper"> 
                <CustomYAxis yAxisTicks={customYAxisTicks} />
                {children}
            </div>
        </CustomContainer>
    ): (
        <ResponsiveContainer width="100%" height={200}>
            {children}
        </ResponsiveContainer>
    ),[isScrollable, customYAxisTicks])

    const barChartItems = React.useMemo(() => barItems.map((item, index) => ({
        ...item, dataKey: item.dataKey || defaultDataKeys[index],
        color: item.color || defaultColors[index]
    }))
        , [barItems])

    const customTooltip = React.useCallback(({ active, payload, label }: TooltipProps<string, string>) =>
        active && payload?.length ? (
            <TooltipWrapper 
                style={
                    isScrollable ? 
                    {
                        position: "fixed", 
                        zIndex: 100, 
                        whiteSpace: "nowrap",
                        ...tooltipPos,
                    } : undefined
                }
            >
                <div className='header'>{label}</div>
                {
                    barChartItems.map(item => <div className="value" key={item.label}>{item.label} -  {label != "Orders" && label != "Pieces" && 'SAR'} {payload[0].payload[item.dataKey]}</div>)
                }
            </TooltipWrapper>
        ) : null
        , [barChartItems, tooltipPos, isScrollable])

    useEffect(() => {
        let resizeTimeout: NodeJS.Timeout;
        const handleResize = () => {
            clearTimeout(resizeTimeout);
    
            resizeTimeout = setTimeout(() => {
            if (containerRef.current) {
                const { width } = containerRef.current.getBoundingClientRect();
                if (width > 360) return setColumnSize((width - 60) / 4 )
                setColumnSize(width < 300 ? 80 : 90)
            }
            }, 500);
        };
    
        handleResize();
    
        const resizeObserver = new ResizeObserver(handleResize);
    
        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }
    
        return () => {
            resizeObserver.disconnect();
            clearTimeout(resizeTimeout);
        };
    }, []);

    if(isLoading) {
        return <LoadingChart/>
    }

    return (
        <StyledWrapper className={wrapperClassName} ref={containerRef}>
            <div className="title">
                <img src={assets.barChartIcon} alt="bar-chart-icon" />
                <p>
                    {title}
                    {
                        subTitle && (
                            <span className="sub-title">
                                ({subTitle})
                            </span>
                        )
                    }
                </p>
            </div>
            <p className="description">
                {description}
            </p>
            <Container>
                <BarChart 
                    data={chartData} 
                    barCategoryGap="32%" 
                    margin={{ top: 10, right: 5, left: 0, bottom: 0 }} 
                    width={isScrollable ? chartData.length * columnSize : undefined}
                    height={200}
                >
                    <CartesianGrid syncWithTicks vertical={false} strokeDasharray="1 0" stroke={colors().columbiaBlue} />
                    <XAxis
                        tickLine={false}
                        axisLine={false}
                        tickMargin={5}
                        tick={{ fontSize: 12, fill: colors().darkjunglegreen }}
                        dataKey="name"
                    />
                    <YAxis 
                        axisLine={false} 
                        tickLine={{ stroke: colors().columbiaBlue }} 
                        tick={{ fontSize: 12, fill: colors().darkliver }} allowDecimals={false} 
                        tickFormatter={formatBigNumber}
                        ticks={customYAxisTicks.length ? customYAxisTicks : undefined}
                        hide={isScrollable} 
                    />
                    <Tooltip
                        cursor={false}
                        content={customTooltip}
                        wrapperStyle={isScrollable ? {
                            position: "fixed",
                            zIndex: 11
                        } : undefined}
                    />
                    {
                        barChartItems.map((item) => (
                            <Bar
                                key={item.label}
                                fill={item.color}
                                radius={[2, 2, 0, 0]}
                                dataKey={item.dataKey}
                            />
                        ))
                    }
                </BarChart>
            </Container>
            <div className="legends-container">
                {
                    barChartItems.map((item) => (
                        <div className="legend-item" key={item.label}>
                            <div style={{ background: item.color }} />
                            <span>{item.label}</span>
                        </div>
                    ))
                }
            </div>
        </StyledWrapper>

    )
}

const CustomYAxis = ({ yAxisTicks }: { yAxisTicks: number[] }): JSX.Element => {
    const reversedArray = yAxisTicks.slice(0).reverse()

    return (
        <div 
            className="custom-y-axis-wrapper" 
        >
            {
                reversedArray.map(tick => <p key={tick}>{formatBigNumber(tick)}</p>)
            }
        </div>
    )
}

const suffixes = ['', 'K', 'M', 'B', 'T', 'Q'];
export const formatBigNumber = (value: number) => {
    if (value === 0) {
        return '0';
    }
    const suffixIndex = Math.min(suffixes.length - 1, Math.floor(Math.log10(value) / 3));
    let formattedValue: string;

    if (suffixIndex === 0) {
        formattedValue = value.toFixed(0);
    } else {
        const scaledValue = value / Math.pow(10, suffixIndex * 3);
        formattedValue = scaledValue.toFixed(scaledValue % 1 === 0 ? 0 : 1);
    }

    return `${formattedValue}${suffixes[suffixIndex]}`;
}

export const getYAxisValues = (step: number, highestDataValue: number) => {
    if (step < 2 || highestDataValue <= 0) return undefined;
    const maxYAxisValue = getMaxYAxisValue(highestDataValue);
    const interval = maxYAxisValue / 4;
    const ticks = [];
    for (let i = 1; i < step; i++) {
      const rawValue = 0 + i * interval
      ticks.unshift({
        raw: rawValue,
        formatted: formatBigNumber(rawValue)
      });
    }
    return ticks;
}

export const getMaxYAxisValue = (range: number) => {
    const exponent = Math.floor(Math.log10(range));
    const fraction = range / Math.pow(10, exponent);
    let niceFraction = 10;
    if (fraction <= 1) {
        niceFraction = 1;
    } else if (fraction <= 2) {
        niceFraction = 2;
    } else if (fraction <= 4) {
        niceFraction = 4;
    }
    const niceValue = niceFraction * Math.pow(10, exponent)
    return Math.max(niceValue, 100);
}


const TooltipWrapper = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    justifyContent: "center",
    alignItems: "center",
    background: "white",
    borderRadius: 8,
    transition: "all 0.5s ease",
    "-webkit-transition": "all 0.5s ease",
    "& .header": {
        padding: "8px",
        borderBottom: `1px solid ${colors().lightgray}`,
        background: colors().antiFlashWhite,
        fontSize: "13px",
        fontWeight: 500,
        fontFamily: "Montserrat",
        borderRadius: "8px 8px 0 0",
        display: "flex",
        justifyContent: "center",
        width: "100%"
    },
    "& .value": {
        fontFamily: "Montserrat",
        fontSize: '12px',
        fontWeight: 400,
        color: colors().darkjunglegreen,
        padding: "0 5px",
        "&:last-of-type": {
            borderRadius: "0 0 8px 8px",
            paddingBottom: 10
        }
    }
})

const StyledWrapper = styled("div")({
    overflow: "hidden",
    borderRadius: 12,
    border: `solid 1px ${colors().lightborder}`,
    display: "flex",
    flexDirection: "column",
    gap: 12,
    padding: 12,
    "& .title": {
        fontSize: 19,
        fontweight: 500,
        lineHeight: "22px",
        display: "flex",
        gap: 8,
        alignItems: "center",
        borderBottom: `solid 1px ${colors().lightborder}`,
        padding: "8px 12px"
    },
    "& .description": {
        fontSize: 12,
        lineHeight: 1.5,
        paragraph: 8
    },
    "& .recharts-responsive-container": {
        marginLeft: -20
    },
    "& .legends-container": {
        display: "flex",
        gap: 8,
        alignItems: "center",
        padding: "0 4px",
        marginBottom: 6,
    },
    "& .legend-item": {
        display: "flex",
        gap: 8,
        alignItems: "center",
        "& > div": {
            width: 12,
            height: 12,
            flexShrink: 0,
            borderRadius: 4
        },
        "& > span": {
            fontSize: 12,
            lineHeight: 1.5,
            color: colors().darkjunglegreen
        }
    }
})

const CustomContainer = styled("div")({
    overflow: "auto", 
    paddingBottom: 10,
    marginTop: 5,
    "&::-webkit-scrollbar": {
        width: 3,
        height: 3
    },
    "&::-webkit-scrollbar-track": {
        borderRadius: 10,
        background: "#f1f1f1"
    },
    "&::-webkit-scrollbar-thumb" :{
        borderRadius: 10,
        background: "#888",
    },
    "& .custom-wrapper": {
        display: "grid",
        gridTemplateColumns: "auto auto", 
        gap: 3
    },
    "& .custom-y-axis-wrapper": {
        background: "white", 
        position: "sticky", 
        left: 0, 
        zIndex: 10, 
        display: "flex", 
        flexDirection: "column", 
        fontSize: 12, 
        height: "calc(100% - 25px)", 
        marginTop: 2, 
        alignItems: "end",
        justifyContent: "space-between",
        color: colors().darkliver
    },
    "& .legends-container": {
        marginBottom: 16
    }
})

export default CustomColumnChart