import React from "react"
import {TableContainer, Table, TableBody, TableHead, TableCell, TableRow, TableFooter, styled} from "@material-ui/core"
import {colors} from "../../../blocks/utilities/src/Colors";

type SectionSale = {
    section: string;
    pieces: number;
    orders: number;
    total_price: string;
}

type Props = {
    tableData: SectionSale[]
    total?: Array<number | string>
}

const SECTION_SALES_COLUMNS = [
    {
      label: "Category",
      binding: "section"
    },
    {
      label: "Orders",
      binding: "orders"
    },
    {
      label: "Pieces",
      binding: "pieces"
    },
    {
      label: "Amount",
      binding: "total_price"
    },
  ] as Array<{label: string; binding: keyof SectionSale}>

const SectionSalesTable = ({tableData, total} : Props) => (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {
              SECTION_SALES_COLUMNS.map(column => (
                <TableCell key={column.label}>
                  <StyledLabel fontSize={15} fontColor={colors().coolgray}>{column.label}</StyledLabel>
                </TableCell>
              ))
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            (tableData.length || total) ? tableData.map((rowItem, index) => (
              <TableRow key={index}>
                {
                  SECTION_SALES_COLUMNS.map(column => <TableCell key={column.label}>
                    <StyledValue>{rowItem[column.binding]}</StyledValue>
                  </TableCell>)
                }
              </TableRow>
            ))
            :
            <TableCell colSpan={4}>
              <p className="no-data-message">No Data</p>
            </TableCell>
          }
        </TableBody>
        {total ?
          <TableFooter>
            <TableRow>
              <TableCell>
                <p>Total</p>
              </TableCell>
              {
                total.map((totalColumn, index) => <TableCell key={index}>
                  <p>{totalColumn}</p>
                </TableCell>)
              }
            </TableRow>
          </TableFooter> : null
        }
      </Table>
    </TableContainer>
)

export const StyledValue = styled("p")({
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "22.4px",
    color: colors().darkjunglegreen
})

export const StyledLabel = styled("p")(({ fontColor, fontSize }: { fontColor?: string, fontSize?: number }) => ({
    fontSize: fontSize || 14,
    fontWeight: 400,
    lineHeight: "22px",
    wordBreak: "break-word",
    whiteSpace: "nowrap",
    color: fontColor || colors().darkliver
}))

export default React.memo(SectionSalesTable)