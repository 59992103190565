import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start
import { deamond, loyaltyIcon } from "./assets";
import { styled, Box } from "@material-ui/core";
// Customizable Area End

import Cfwallet44Controller, {
  Props,
  configJSON,
} from "./Cfwallet44Controller";

export default class Cfwallet44 extends Cfwallet44Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderUsablePoints() {
    const { currency, totalAmount, points, redeemPointsHandler } = this.props;

    return (
      <div className={"usablePointsContainer"}>
        <div className={"flexCenterBetween"}>
          <div className={"usablePoints"}>{configJSON.label_usablePoints}</div>
          <div className={"iconPointContainer"}>
            <img src={loyaltyIcon} />
            <div className={"points"}>{points}</div>
          </div>
        </div>
        <div className={"pointsMessage"}>
          {`You can use ${currency} ${totalAmount} for your points`}
        </div>
        <div className={"redeemBtnWrap"}>
          <div
            className={"redeemBtn"}
            data-test-id='redeem'
            onClick={() => redeemPointsHandler && redeemPointsHandler()}
          >
            {configJSON.label_redeem}
          </div>
        </div>
        <div className={"diamond"}>
          <img src={deamond} />
        </div>
      </div>
    );
  }

  renderAfterRedeem() {
    const { points, totalAmount, usePointsHandler } = this.props;

    return (
      <div className={"afterRedeemContainer"}>
        <div className={"congrats"}>{configJSON.label_congrats}</div>
        <div className={"afterRedeemMessagesContainer"}>
          <div className={"pointsRedeemMessage"}>
            {points + " " + configJSON.label_pointsRedeemed}
          </div>
          <div> {configJSON.label_nowYouCanUseCreditAmount}</div>
        </div>
        <div className={"totalAmountContainer"}>SAR {totalAmount}</div>
        <div
          data-test-id={"btn-use-points"}
          className={"redeemBtn"}
          onClick={() => usePointsHandler && usePointsHandler()}
        >
          {configJSON.label_use}
        </div>
      </div>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <StyledWrapper>
        {this.props.isRenderUsablePoints
          ? this.renderUsablePoints()
          : this.renderAfterRedeem()}
      </StyledWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledWrapper = styled(Box)({
  "& .usablePointsContainer": {
    width: "296px",
    flexShrink: 0,
    borderRadius: "8px",
    background: "#EC4E20",
    padding: "16px",
    display: "flex",
    flexDirection: "column",
  },
  "& .flexCenterBetween": {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .usablePoints": {
    color: "#FFF",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 600,
  },
  "& .iconPointContainer": {
    display: "flex",
    gap: 8,
  },
  "& .points": {
    color: "#FFF",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontWeight: 700,
  },
  "& .pointsMessage": {
    color: "#FFF",
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 400,
    marginTop: 12,
    marginBottom: 24,
  },
  "& .redeemBtnWrap": {
    display: "flex",
    justifyContent: "center",
  },
  "& .redeemBtn": {
    display: "flex",
    padding: 16,
    justifyContent: "center",
    alignItems: "center",
    color: "#204B9C",
    fontSize: 16,
    fontFamily: "Montserrat",
    fontWeight: 600,
    cursor: "pointer",
    borderRadius: 8,
    background: "#FFF",
    width: "100%",
  },
  "& .diamond": {
    width: "92px",
    height: "86px",
    flexShrink: 0,
    opacity: 0.1,
    position: "absolute",
  },
  "& .afterRedeemContainer": {
    width: "296px",
    flexShrink: 0,
    borderRadius: "8px",
    background: "#EC4E20",
    padding: "24px",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
  },
  "& .congrats": {
    color: "#FFF",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 600,
    marginBottom: 8,
  },
  "& .afterRedeemMessagesContainer": {
    color: "#FFF",
    fontFamily: "Montserrat",
    fontSize: "13px",
    fontWeight: 500,
    marginBottom: 12,
  },
  "& .pointsRedeemMessage": {
    marginBottom: 12,
  },
  "& .totalAmountContainer": {
    color: "#FFF",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 24,
  },
  "@media (max-width: 1200px)": {
    "& .usablePointsContainer": {
      width: "296px",
      flexShrink: 0,
      borderRadius: "8px",
      background: "#EC4E20",
      padding: "16px 30px",
      display: "flex",
      flexDirection: "column",
    },
    "& .pointsMessage": {
      color: "#FFF",
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 400,
    },
    "& .redeemBtn": {
      display: "flex",
      padding: "6px 16px",
      justifyContent: "center",
      alignItems: "center",
      color: "#204B9C",
      fontSize: 14,
      fontFamily: "Montserrat",
      fontWeight: 600,
      cursor: "pointer",
      borderRadius: 8,
      background: "#FFF",
      width: "100%",
    },
  },
});
// Customizable Area End
