// Customizable Area Start
import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import PageContainer from "../../../blocks/navigationmenu/src/PageContainer.web";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";
import {
  Box, styled
} from "@material-ui/core";
export const configJSON = require("./config");

import CustomerTypeAddController, {
  FormMode,
  FormInteface,
  Props,
} from "./CustomerTypeAddController.web";
import DropdownMenu from "../../../components/src/DropdownMenu.web";
import { sortCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";
import { CommonIcomSearchComponent } from "../../../components/src/customComponents/CommonIcomSearchComponent";
// Customizable Area End

// Customizable Area Start
const StyledWrapper = styled('div')({
  "& .errorInput": {
    borderColor: 'red',
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: 'red'
    }
  },
  "& .paddingRightClass": {
    paddingRight: "32px"
  },
  "& .pageTitle": {
    "fontSize": "24px",
    "fontWeight": 600,
    "margin": "32px 0 10px 0",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
      "marginTop": "16px",
    },
  },
  "& .pageContainer": {
    "fontFamily": "Montserrat, sans-serif",
    "background": "white",
    "& .MuiSelect-select.Mui-disabled": {
      "color": "#7e7e7e"
    }
  },
  "& .formContainer": {
    "boxShadow": "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    "padding": "32px",
    "borderRadius": "12px",
    "position": "relative",
    "& .MuiFormLabel-root": {
      "fontFamily": "Montserrat, sans-serif",
      "fontStyle": "normal",
      "fontWeight": 600,
      "fontSize": "16px",
      "letterSpacing": "-0.3px",
      "color": "#1a1a1a"
    },
    "@media only screen and (max-width: 1024px)": {
      padding: "16px"
    }
  },
  "& .buttonCustomerType": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height:"56px",
    "borderRadius": "8px",
    "fontSize": "16px",
    "fontWeight": 700,
    "cursor": "pointer",
    "width": "128px",
    "@media only screen and (max-width: 1024px)": {
      height: "44px"
    }
  },
  "& .btn-cancel": {
    "backgroundColor": "#F1F5F9",
    "color": "#64748B",
    "marginRight": "32px"
  },
  "& .btn-submit": {
    "backgroundColor": "#204B9C",
    "color": "white"
  },
  "& .inputLabel": {
    marginBottom: "8px", 
    marginTop: "24px",
    fontWeight: 600,
    fontSize: "16px",
    fontFamily: "Montserrat !important",
    color: "#1A1A1A",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "13px"
    }
  },
  "& .inputField": {
    fontFamily: "Montserrat !important",
    width: "100%",
    height: "56px",
    marginBottom: "0px",
    "@media only screen and (max-width: 1024px)": {
      height: "44px"
    },
    "& .MuiInputBase-input": {
      "@media only screen and (max-width: 1024px)": {
        fontSize: "12px",
        padding: "14.9px 14px"
      }
    }
  },
});
// Customizable Area End

export class CustomerTypeAdd extends CustomerTypeAddController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  handleInputChange(event: React.ChangeEvent<HTMLInputElement>, type: any, key: any)  {
    let inputValue = event.target.value;
    if(type === 'number') {
      if (/^[0-9\b.]+$/.test(inputValue) || inputValue === '') {
        this.onFormElementChange({ ...event, target: { ...event.target, value: inputValue } }, key);
      }
    }
    else{
      this.onFormElementChange({ ...event, target: { ...event.target, value: inputValue } }, key);
    }
  };
  renderTextField(key: keyof FormInteface, placeholder: string, type: string) {
    const { form, formErrors } = this.state;
    return (
      <TextField
        data-test-id={key}
        className={sortCondition(formErrors[key] as unknown as boolean,'inputField errorInput','inputField') as string}
        value={form[key]}
        color='primary'
        variant='outlined'
        name={key}
        placeholder={placeholder}
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => this.handleInputChange(ev, type, key)}
      />
    );
  }

  renderInputTextField(
    key: keyof FormInteface,
    label: string,
    placeholder: string,
    leftOne: boolean,
    type: string
  ) {
    const { formErrors } = this.state;
  
    return (
      <>
        <Grid item xs={12} sm={6} className={leftOne ? 'paddingRightClass' : ""}>
          <FormControl style={{ width: "100%" }}>
            <label className="inputLabel">{label}</label>
            {this.renderTextField(key, placeholder, type)}
            {formErrors[key] && (
              <Typography className='errorMsg2' variant='caption' style={{ color: "red" }}>
                {formErrors[key]}
              </Typography>
            )}
          </FormControl>
        </Grid>
      </>
    );
  }

  renderTitle = () => {
    return <div className={'pageTitle'}>{this.state.formMode === FormMode.Create ? configJSON.addCustomerTypeLabel : configJSON.editCustomerTypeLabel}</div>;
  };

  // Customizable Area End

  render() {
    // Customizable Area Start

    const { isSubmitting, formMode } = this.state;
    let buttonText;

    if (isSubmitting) {
      buttonText = <CircularProgress size={16} color='inherit' />;
    } else if (formMode === FormMode.Edit) {
      buttonText = configJSON.saveText;
    } else {
      buttonText = configJSON.add;
    }

    return (
      <>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={this.state.snackbarOpen}
          autoHideDuration={4000}
          onClose={this.handleSnackbarClose}
        >
          <Alert severity={sortCondition(this.state.alertServerity === "success","success","error") as "success" | "error"}>{this.state.snackbarMessage}</Alert>
        </Snackbar>
        <>
          <CustomLoader loading={this.state.isLoading} />
          <StyledWrapper>
            <div className={'pageContainer'}>
            <Grid
              container
              direction='row'
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {this.renderTitle()}
            </Grid>
            <Grid container direction='column' className={'formContainer'}>
              <Grid container direction='row'>
                {this.renderInputTextField("customer_type_name", "Customer Class/Type Name", "Enter Class/Type Name", true, 'text')}
                <Grid item xs={12} sm={6} style={{ paddingRight: "32px" }}>

                  <FormControl style={{ width: "100%" }}>
                    <label className="inputLabel">Customer Type icon</label>
                    <Box data-test-id="icon-test">
                        <CommonIcomSearchComponent
                              value={[]}
                              optionList={this.state.iconList}
                              debouncedFunction={() => { } }
                              handleScroll={this.handleUpchargeProductScroll}
                              handleSelectOptions={this.handleSelectImage} 
                              selectedItem={this.state.selectedImage}     
                               />
                    </Box>
                  </FormControl>

                </Grid>

              </Grid>
              <Grid container direction='row'>
                {this.renderInputTextField("minimum_points", "Minimum Points", "Enter Number", true, 'number')}
                {this.renderInputTextField("maximum_points", "Maximum Points", "Enter Number", true, 'number')}
                {this.renderInputTextField("min_number_of_orders", "Minimum Number of Orders", "Enter Number", true, 'number')}
                {this.renderInputTextField("max_number_of_orders", "Maximum Number of Orders", "Enter Number", true, 'number')}
              </Grid>
            </Grid>

            <Grid container direction='row' style={{ marginTop: 48 }}>
              <div
                data-test-id={"cancelBtn"}
                onClick={this.handleCancelClick}
                className={'buttonCustomerType btn-cancel'}

              >
                {configJSON.cancelText}
              </div>

              <div
                data-test-id={"submitBtn"}
                onClick={this.handleOnSubmit}
                className={'buttonCustomerType btn-submit'}

              >
                {buttonText}
              </div>
            </Grid>
            </div>
          </StyledWrapper>
        </>
      </>
    );
    // Customizable Area End
  }
}

export default CustomerTypeAdd;
