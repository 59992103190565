import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
  Snackbar,
  styled
} from "@material-ui/core";


import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { Random, TimeDuration } from "./SevicesTypes"
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";

import CostumInput from "../../../components/src/customComponent/cutomInput.web"
import { CustomSearchableMultiSelect } from "../../../components/src/customComponent/CustomSearchableMultiSelect.web";



import EditServicesController, { Props, configData } from "./EditServicesController.web";

import { editstyles, styleOverride } from "./EditServicesStyle";
import { CheckBoxTrue, CheckBoxFalse } from "./assets"


import { Formik } from "formik";

import Alert from '@material-ui/lab/Alert';
import AutocompleteSelect from "../../../components/src/AutocompleteSelect.web";



const theme = createTheme(styleOverride);

// Customizable Area End




class AddServices extends EditServicesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  checkIsError(errors: string | undefined, touched: boolean | undefined) {
    if(errors && touched) {
      return true
    }
  }

  render() {

    return (
      //Merge Engine DefaultContainer
      <ThemeProvider theme={theme}>
        <>
          <CustomLoader loading={this.state.loading || this.state.addBtnLoading}/>
          <EditServicesWrapper className={`mainContainer`}>
            <Box className="titleContainer">
              <Typography
                className="titleContainerText"
              >
                {configData.editService}
              </Typography>

            </Box>

            <Formik
              enableReinitialize
              validateOnBlur={true}
              initialValues={{
                ...this.state.initialValues
              }}
              onSubmit={this.updateService}
              validationSchema={this.FormSchema}
              data-test-id="formikEditServices"
            >

              {({
                errors,
                values,
                handleSubmit,
                setFieldValue,
                handleChange,
                touched,
                resetForm,
                getFieldProps
              }) => {



                return (

                  <form onSubmit={handleSubmit}>


                    <Box className="flex">
                      <Card className="cardBox">

                        <CardContent >

                          <Grid container spacing={6} >
                          
                            <Grid item xs={5} >

                              <Typography className="inputLabel">{configData.serviceName}</Typography>
                              <CostumInput

                                placeholder={configData.enterName}
                                value={values.serviceName}
                                name="serviceName"
                                onChange={handleChange}
                                inputError={ touched.serviceName &&
                                  errors.serviceName}

                              />

                              {
                                touched.serviceName &&
                                errors.serviceName &&
                                <Typography style={{ color: theme.palette.error.main }}>{errors.serviceName}</Typography>
                              }


                            </Grid>


                            <Grid item xs={5}>

                              <Typography className="inputLabel" >{configData.serviceSecondName}</Typography>
                              <CostumInput
                                inputDir="rtl"
                                placeholder={configData.enterSecondName}
                                value={values.serviceSecondaryName}
                                name="serviceSecondaryName"
                                onChange={handleChange}
                                inputError={ touched.serviceSecondaryName &&
                                  errors.serviceSecondaryName}

                              />
                              {
                                touched.serviceSecondaryName &&
                                errors.serviceSecondaryName &&
                                <Typography style={{ color: theme.palette.error.main }}>{errors.serviceSecondaryName}</Typography>
                              } 

                            </Grid>
                          </Grid>

                          <Grid container spacing={6} >
                          <Grid item xs={5}>

<Typography className="inputLabel" >
  {configData.serviceShortName}
</Typography>
<CostumInput
  data-test-id="shortName"
  name="shortName"
  value={values?.shortName}
  onChange={handleChange}
  placeholder={configData.enterShortName}
  inputError={touched.shortName && errors.shortName}
/>

{
  touched.shortName &&
  errors.shortName &&
  <Typography style={{ color: theme.palette.error.main }}>{errors.shortName}</Typography>
}

</Grid>

                            <Grid item xs={5} >
                              <Typography className="inputLabel" data-test-id="section">{configData.section}</Typography>
                              <CustomSearchableMultiSelect
                                id="section"
                                name="section"
                                dataTestId="sectionDropdown"
                                checkSelectAllText={() => {}}
                                value={this.state.selectedOptions}
                                optionList={this.state.sectionLists}
                                placeHolderText="Select Section"
                                emptyDataMessage="No Section Found"
                                changeInputOnClear
                                handleClickOnSelectAll={() => {}}
                                debouncedFunction={(getValue: string) => { this.debouncedFunction(getValue, this.handleSectionAutoCompleteChange) }}
                                handleSelectOptions={(value: { id: string, option: string }[], option: { id: string, option: string }) => { this.handleSectionMultiSelect(value, option, setFieldValue) }}
                                isOnBlurFunction={() => this.debouncedFunction("", this.handleSectionAutoCompleteChange)}
                                handleScroll={this.handleScrollSectionDropdown}
                                handleEmptyAutoSelectValue={() => {}}
                                selectedOptionForList={this.state.selectedSectionsForList}
                                isError={Boolean(errors.selectedSections && touched.selectedSections)}
                            />
                            {touched.selectedSections && errors.selectedSections && (
                              <Typography className="error">{errors.selectedSections}</Typography>
                            )}
                             </Grid>
                            <Grid item xs={5} style={{paddingTop: "0"}}>

                              <Typography className="inputLabel">{configData.icon}</Typography>
                              <AutocompleteSelect
                                listBoxStyle={{maxHeight: 260}}
                                disableClear={true}
                                placeholder="Select icon"
                                fieldName="icon"
                                noOptionText="No icon found"
                                options={this.state.iconList}
                                value={values.selectedIcon}
                                onBlur={() => this.debouncedFunction(this.state.selectedIcon?.option || "", this.handleIconAutoCompleteChange)}
                                onChange={(_event: React.ChangeEvent, value: { id: string; option: string; image:string }) => this.handleSelectIcon(value, setFieldValue)}
                                handleScroll={this.handleScrollIconDropdown}
                                debouncedFunction={(getValue: string) => { this.debouncedFunction(getValue, this.handleIconAutoCompleteChange) }}
                                error={Boolean(touched.selectedIcon && errors.selectedIcon)}
                               />
                              {touched.selectedIcon && errors.selectedIcon && <Typography className="error">{configData.pleaseSelectOneIcon}</Typography>}


                            </Grid>
                          </Grid>
                          <Grid container spacing={6}>

                            <Grid item xs={3} alignItems={configData.alignCenter} className="onelineOrder">
                            <Box style={{ display: 'flex', gap: '10px' }}>
                                <Box data-name={Random.Online} data-test-id="checkbox" onClick={() => setFieldValue('onlineOrder', !values.onlineOrder)}>
                                  {values.onlineOrder ? <img src={CheckBoxTrue} alt="checkboxFalse" /> : <img src={CheckBoxFalse} alt="unchecked" />}
                                </Box>

                                <Typography className="onlineOrderText" style={values.onlineOrder ? { color: '#204B9C', fontWeight:500 } : {}}>{configData.onlineOrder}</Typography>
                              </Box>
                            </Grid>

                          </Grid>

                        </CardContent>
                      </Card>
                      <Box>

                        <Button
                          className={`cancelBtn button-text`}
                          variant={configData.outlined}
                          onClick={() => this.handleRedirect()}
                          data-test-id="cancel"
                        >

                          {configData.cancel}

                        </Button>
                        <Button
                          data-test-id="save"
                          className={`addBtn button-text`}
                          type={configData.submit}
                          variant={configData.outlined}
                          disabled={this.state.addBtnLoading}
                        >

                          {configData.saveButton}
                        </Button>
                      </Box>
                    </Box>
                  </form>
                )
              }}
            </Formik>

            <Snackbar anchorOrigin={{
              vertical: Random.Top,
              horizontal: Random.Center
            }}
              autoHideDuration={TimeDuration.duration}
              open={this.state.snackbar.open}
              onClose={this.handleCloseSnackbar}

            >
              <Alert severity={this.state.snackbar.severity}>{this.state.snackbar.message}</Alert>
            </Snackbar>

          </EditServicesWrapper>
        </>


      </ThemeProvider >
      //Merge Engine End DefaultContainer
    )
  }
    // Customizable Area End
}
// Customizable Area Start

const EditServicesWrapper = styled("div")(({theme}) => ({
  padding:"0 32px",
  "& .customCheckbox": {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 8px',
    gap: '7px',
    backgroundColor: 'rgba(32, 75, 156, 0.1)',
    borderRadius: '16px',
    color: "#204B9C",
  
  },
  "& .secondName": {
    height: '60px',
    borderRadius: '8px',
    display: "flex",
    justifyContent: "center",
    paddingLeft: '8px'
  },
  "& .cancelBtn": {
    backgroundColor: '#F1F5F9',
    color: '#64748B',
    width: '126px',
    height: '56px',
    textTransform: 'none',
    border: "0",
    "@media (max-width: 768px)": {
      padding: "10px 0",
    },
    "@media (max-width: 1024px)": {
      height: "44px",
      width: '128px',
    },
  },
  "& .addBtn": {
    backgroundColor: "#204B9C",
    color: "#ffff",
    width: "128px",
    height: "56px",
    marginLeft: "32px",
    textTransform: "none",
    border: "0",
    "&:hover": {
      backgroundColor: "#204B9C",
      color: "#ffff"
    },
    "@media (max-width: 1024px)": {
      height: "44px",
      width: '128px',
    },
    "@media (max-width: 768px)": {
      padding: "10px 0",
    },
  },
  "& .servicesBtn": {
    display: "flex",
    minHeight: "60px",
    justifyContent: "center",
    borderRadius: "8px",
    padding: "10px",
    flexWrap: "wrap",
    boxSizing: 'border-box'
  },
  "& .flexContainer": {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px"
  },
 "& .enterName": {
    height: "60px",
    borderRadius: '8px',
    display: "flex",
    justifyContent: "center",
    paddingLeft: "8px"
  },
  inputLabel: {
    fontWeight: 600,
    fontSize: "16px",
    color: "#334155",
    "@media (max-width: 1366px)": {
      fontSize: '16px',
     },
   "@media (max-width: 1024px)": {
      fontSize: '14px',
    },
  },
  "& .button-text": {
    fontSize: "16px",
    fontWeight: 700,
    borderRadius: '8px'
  },
  "& .onlineOrderText": {
    color: "334155",
    minWidth:'300px',
    "@media (max-width: 1366px)": {
      fontSize: '16px',
     },
   "@media (max-width: 1024px)": {
      fontSize: '14px',
    },
  },
 "& .dropDown" :{
      maxWidth:'498px',
      overflowY: 'auto'
     
     
  },
  "& .MuiAutocomplete-root": {
    maxWidth: "unset",
    minWidth: "unset",
    "& .MuiInputBase-root": {
      maxHeight: "unset",
      minHeight: "56px",
      padding: "4px",
      "@media only screen and (max-width: 1024px)": {
        minHeight: "44px",
      },
    },
    "& .MuiOutlinedInput-root.Mui-focused fieldset": {
        borderColor: "rgb(236, 236, 236)"
    },
  },
  "& .cardBox":{
    minHeight: "320px",
    borderRadius: "12px",
    border:0,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)"
  },
  "& .titleContainer": {
    display: "flex",
    borderColor: "1px solid red",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px 0 0 10px",
    padding: 0,
  },
  "& .titleContainerText": {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight:'29.26px',
    margin: "46px 0 20px 0",
    "@media (max-width: 1366px)": {
      fontSize: '16px',
      fontWeight: 600,
    },
  },
  "& .mainContainer": {
    margin:'32px 0', 
    width: '100%',
    padding:'10px 49px',
  },
  "& .select": {
    border: '1px solid #ECECEC',
    '&:focus': {
      border: '1px solid #ECECEC',
      borderBottom: '0',
    },
  },
  "& .flex": {
      display: "flex",
      flexDirection: "column",
      gap: "2rem",
    },
   
    "& .underline": {
      borderBottom: '0',
    },
    "& .onelineOrder":{
      display: 'flex'
  },
  "& .parentContainer":{
    width: '100%',
    padding:'10px 49px',
},
  "& .MuiTypography-root.error": {
    color: theme.palette.error.main
  }
}))

export default withStyles(editstyles)(AddServices);
// Customizable Area End









































