// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
export const configJSON = require("./config");
import {
  navigateTo
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { apiCall } from "../../../components/src/common";
import { BusinesCustomerDetails } from "./B2BCustomerCreationController.web";

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: {
    getParam: Function
  };
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean
  customerId: string
  customerDetails: BusinesCustomerDetails
  // Customizable Area End
}

interface SS {
  id: string;
}

export default class B2BCustomerViewController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  authToken: string = "";
  getCustomerDetailsApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      customerId: '',
      customerDetails: {
        id: "",
        type: "",
        attributes: {
          id: 0,
          activated: false,
          name: "",
          full_phone_number: "",
          email: null,
          department_name: null,
          department_id: null,
          cost_center: null,
          sub_cost_center: null,
          address: null,
          postal_code: null,
          notes: null,
          private_notes: null,
          no_of_order: null,
          no_of_pieces: null,
          business_account_id: 0,
          employee_id: "",
          company: {
            id: 0,
            name: "",
          },
          price_list: {
            id: 0,
            name: "",
          },
          city: {
            id: 0,
            name_en: "",
          },
          gender: {
            id: 0,
            label: "",
          },
          "business_account": {
            "id": 0,
            "business_customer": ""
          },
          "business_customer_group": {
            "id": 0,
            "group_name": ""
          },
        },
      }
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    this.getCustomerDetailsApiCallResponse(apiRequestCallId, responseJson);
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const authToken = localStorage.getItem("token");
    if (typeof (authToken) === "string") {
      this.authToken = authToken;
    }
    const customerId = this.props.navigation.getParam('navigationBarTitleText')
    this.setState({ customerId:customerId },()=> this.getCustomerDetailAPI())
    // Customizable Area End
  }
  // Customizable Area Start

  getCustomerDetailAPI = () => {
    this.setState({isLoading: true})
    let headers = {
      "Content-type": "application/json", 
      token: this.authToken
    }
    const url = configJSON.B2BCustomerAPIEndpoint + "/" + this.state.customerId

    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.validationApiMethodType,
    });

    this.getCustomerDetailsApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getCustomerDetailsApiCallResponse = (apiRequestCallId: string,responseJson:{data:BusinesCustomerDetails}) => {
    if(apiRequestCallId === this.getCustomerDetailsApiCallId){
      const customerData = responseJson.data
      this.setState({customerDetails:customerData,isLoading:false})
    }
  }

  handleBackToListing = () => {
    navigateTo({
      props: this.props,
      screenName: "B2BCustomerList",
    })
  }

  handleEditCustomer = (customerId:string) => {
    navigateTo({
      id: String(customerId),
      props: this.props,
      screenName: "B2BCustomerEdit",
    });
  }

  // Customizable Area End
}