// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Color } from '@material-ui/lab/Alert';
import { PreferenceListData } from "./PreferenceListController.web";
import { concat, uniqBy, debounce } from "lodash";
import utils from "../src/util";

type Option = {
  id: string;
  option: string;
  image?: string;
}
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedOption: PreferenceListData | null,
  isOpen: boolean,
  isChecked: boolean,
  preferenceListData: PreferenceListData[],
  defaultIcon: string
  section_first_name: string
  section_second_name: string
  showError: boolean
  showPreferenceError: boolean
  openPopUp: boolean
  message: string
  preferenceId: string | number
  errorMessage:string
  showErrorRes:boolean
  severity: Color | undefined
  allIcons: Option[],
  selectedIcon: Option | null,
  isLoading: boolean
  iconPage: number;
  iconAutoCompleteValue: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;

  // Customizable Area End
}
// Customizable Area Start
const options = configJSON.optionsData
// Customizable Area End

// Customizable Area Start
export default class AddProductSectionController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  itemsPerPage = 10;
  getPreferenceCallId: string = "";
  createSectionCallId: string = "";
  GetIconListCallId: string = "";
  disableLoadMoreIcon = false;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      selectedOption: null,
      isOpen: false,
      isChecked: false,
      preferenceListData: [],
      defaultIcon: configJSON.defaultIcon,
      section_first_name: "",
      section_second_name: "",
      showError: false,
      showPreferenceError: false,
      openPopUp: false,
      message: "",
      preferenceId: "",
      errorMessage:"",
      showErrorRes:false,
      severity: undefined,
      allIcons: [],
      isLoading: false,
      selectedIcon: null,
      iconPage: 1,
      iconAutoCompleteValue: '',
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount(): any {
    // Customizable Area Start
    this.getPreferenceList()
    this.getIconList();
    // Customizable Area End
  }
  // Customizable Area Start
  getIconList = () => {
    const headers = {
      "Content-Type": configJSON.categoryApiContentType,
      token: window.localStorage.getItem(configJSON.token)
    };

    const GetIconList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.GetIconListCallId = GetIconList.messageId;
    const searchQuery = this.state.iconAutoCompleteValue ? `&filter_by[query]=${this.state.iconAutoCompleteValue}` : ''

    GetIconList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.iconsEndpoint}?page_no=${this.state.iconPage}&per_page=${this.itemsPerPage}${searchQuery}`
    );

    GetIconList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    GetIconList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(GetIconList.id, GetIconList);
  };

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };
  cancelChange = () => {
    this.props.navigation.history.push("/Products-SectionList")
}
  handleOptionSelect = (option: PreferenceListData) => {
    this.setState({
      preferenceId: option.id,
      selectedOption: option,
      isOpen: false,
      showPreferenceError: false
    });

  };
  handleCheckboxChange = () => {
    this.setState(prevState => ({
      isChecked: !prevState.isChecked
    }));
  };
  handleSectionFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      section_first_name: event.target.value,
      showError: false,
      isOpen:false
    });
  };
  handleClosePopUp =()=> {
    this.setState({
      openPopUp:false
    })
  }
  handleSecondNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      section_second_name: event.target.value,
      showError: false,
      isOpen:false
    });
  };

  checkFormErrors = () => {
    const { section_first_name, selectedOption } = this.state;
    return selectedOption !== null && section_first_name !== '' 
  }

  handleAddClick = () => {
    const { section_first_name, selectedOption } = this.state;
    if (section_first_name === '') {
      this.setState({ showError: true });
    } else {
      this.setState({ showError: false });
    }
    if (selectedOption === null) {
      this.setState({ showPreferenceError: true });
    } else {
      this.setState({ showPreferenceError: false });
    }
    if (this.checkFormErrors()) {
      this.setState({isLoading: true})
      this.createSectionList()
     
    }
  };
  getPreferenceList = () => {
    const headers = {
      "Content-Type": configJSON.categoryApiContentType,
      token: window.localStorage.getItem(configJSON.token)
    };

    const preferenceDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPreferenceCallId = preferenceDataMessage.messageId;

    preferenceDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllPreferenceDropdownEndPoint
    );

    preferenceDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    preferenceDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(preferenceDataMessage.id, preferenceDataMessage);
  }
  createSectionList = () => {

    let headers = {
      "Content-Type": configJSON.categoryApiContentType,
      token: window.localStorage.getItem(configJSON.token)
    };
    let httpBody = {}
    httpBody = {
      data: {
        attributes: {
          section_name: this.state.section_first_name,
          section_second_name: this.state.section_second_name,
          preference_id: this.state.preferenceId,
          online: this.state.isChecked,
          icon_id: Number(this.state.selectedIcon?.id)
        }
      }
    }
    const createMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createSectionCallId = createMessage.messageId;

    createMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sectionListApiEndPoint
    );

    createMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    createMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    createMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );

    runEngine.sendMessage(createMessage.id, createMessage);

  }
  handleResponseForGetPreference = (from: string, message: Message) => {
    if (this.getPreferenceCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse) {
        this.setState({
          preferenceListData: apiResponse.data
        })

      }
    }
  }
  handleResponseCreateSection = (from: string, message: Message) => {
    if (this.createSectionCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse.data.type !== "error") {
        this.setState({
          openPopUp: true,
          message: configJSON.popUpMessage,
          severity:configJSON.severitySuccess
  
        })
        setTimeout(()=> {
          this.props.navigation.history.push("/Products-SectionList")
        },1000)

      }else if(apiResponse.data.type === configJSON.typeError) {
        this.setState({
          isLoading: false,
          showErrorRes:true,
          errorMessage:apiResponse.data.attributes.errors.name[0]
        })
      }else {
        this.setState({
          isLoading: false,
          openPopUp: true,
          message:apiResponse?.errors[0]?.message,
          severity:configJSON.typeError
  
        })
      }
    }
  }
  handleGetIconListResponse(from: string, message: Message) {
    if (this.GetIconListCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    
      const {parsedDropdownIconss}  = utils.parsedAllIcon(response)
      let newIcons = this.state.iconPage === 1 ? parsedDropdownIconss : concat(this.state.allIcons, parsedDropdownIconss)
      this.state.selectedIcon && newIcons.unshift(this.state.selectedIcon)
      this.setState({
          allIcons: uniqBy(newIcons, "id"),
      })
      this.disableLoadMoreIcon = parsedDropdownIconss.length < this.itemsPerPage
    }
  }
  selectIcon = (newIcon: Option) => {
    this.setState({
        selectedIcon: newIcon,
    });
  }

  handleScrollIconDropdown = (event: React.SyntheticEvent) => {
    if (this.disableLoadMoreIcon) return;
    const checkListboxNode = event.currentTarget;
    const boxPosition = checkListboxNode.scrollTop + checkListboxNode.clientHeight;

    if (checkListboxNode.scrollHeight - boxPosition <= 1.30) {
      this.setState((prevState) => ({ iconPage: prevState.iconPage + 1 }), () => {
        this.getIconList()
      })
    }
  };
debouncedFunction = debounce(
    (newInputValue: string) => this.handleIconAutoCompleteChange(newInputValue),
    700,
    { maxWait: 2000 }
);
handleIconAutoCompleteChange = (getValue: string) => {
    if (getValue === this.state.iconAutoCompleteValue) return;
    this.setState({ iconAutoCompleteValue: getValue, iconPage: 1 }, () => {
        if (getValue.length < 1 || getValue.length > 2) {
        this.getIconList()
        }
    });
}


  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleResponseForGetPreference(from, message)
    this.handleResponseCreateSection(from,message)
    this.handleGetIconListResponse(from,message);
    // Customizable Area End
  }
  // Customizable Area End


}
// Customizable Area End
