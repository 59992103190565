import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toString } from "lodash";
import { ISortingData } from "../../../components/src/SortingTableHeader";
import { checkFilterSorting } from "../../RolesPermissions2/src/utils";
import { IFilter } from "../../../components/src/FilterPopover";
import { apiCall } from "../../../components/src/common";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  checkIsFilterApplied,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
import {
  removeStorageData
} from "framework/src/Utilities";

export interface IUser {
  id: string;
  type: string;
  attributes: {
    activated: boolean;
    role_name: string;
    region_names: string[];
    full_name: string;
    full_phone_number: string;
    permission_count: number;
  };
}

const emptyUser: IUser = {
  id: "",
  type: "",
  attributes: {
    activated: false,
    role_name: "",
    region_names: [],
    full_name: "",
    full_phone_number: "",
    permission_count: 0
  }
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  drawerWidth: number;
  popOverOpened: boolean;
  popOverItemId: string;
  popOverItemStatus: string;
  popOverTop: number;
  popOverLeft: number;
  hoveredButton: string;
  users: IUser[];
  page: number;
  pageSize: number;
  total: number;
  disableModalVisible: boolean;
  deactivatedAccount: boolean;
  filterAnchor: any;
  filters: any;
  clickedAccount: IUser | undefined;
  sortingData: ISortingData;
  query: string;
  searchTextAccount: string;
  currentFilter: string;
  permissionStatus: PermissionStatus;
  isUsersListLoading: boolean;
  assignedRegionIds: string;
  isAppliedFilter: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AccountListController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getUsersCallId: string = "";
  updateUserCallId: string = "";
  accountSuggestionApiCallId: string = "";
  tableRefs: HTMLElement | null = null;


  handleQueryChange = (query: string) => {
    this.setState({ query }, () => this.getUsers());
  };

  sortingProps = {
    onQueryChange: (query: string) => this.handleQueryChange(query),
    width: "18%",
    onChange: (sortingData: ISortingData) => this.setState({ sortingData }),
  };

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),

      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SearchTextMessage),
      getName(MessageEnum.LayoutDataMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      drawerWidth: 0,
      popOverOpened: false,
      popOverLeft: 0,
      popOverTop: 0,
      hoveredButton: "",
      users: [],
      page: 0,
      pageSize: 10,
      total: 0,
      popOverItemId: "",
      popOverItemStatus: "",
      disableModalVisible: false,
      deactivatedAccount: false,
      filterAnchor: false,
      clickedAccount: undefined,
      filters: [
        {
          title: "Name",
          type: "autocompolete",
          value: "",
          options: [],
        },
        {
          title: "Role",
          type: "autocompolete",
          value: "",
          options: [],
        },
        {
          title: "Permission",
          type: "select",
          options: [
            {
              label: "Maximum To Minimum",
              value: "DESC",
            },
            {
              label: "Minimum To Maximum",
              value: "ASC",
            },
          ],
          value: "",
        },
        {
          title: "Region/Business Area",
          type: "autocompolete",
          value: "",
          options: [],
        },
      ],
      sortingData: {
        name: "",
        role: "",
        permission: "",
        region_name: "",
      },
      query: "",
      searchTextAccount: "",
      currentFilter: '',
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false,
        activatePermission: false
      },
      isUsersListLoading: false,
      assignedRegionIds: '',
      isAppliedFilter: false
    };

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.getUsersCallId) {
        this.checkResponseData(responseJson, message)
      } else if (apiRequestCallId === this.updateUserCallId) {
        this.getUsers();
        this.checkDeactivation(responseJson);
      } 
      if (apiRequestCallId === this.accountSuggestionApiCallId) {
        this.chechResponse(responseJson)
      }
    }
    this.receiveSearchTextData(message)
    this.receiveDataFromLayout(message)
    // Customizable Area End
  }

  // Customizable Area Start

  receiveSearchTextData = (message: Message) => {
    if (message.id === getName(MessageEnum.SearchTextMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.SearchMessageText)
        );
        if (recievedData) {
          this.handleSearchAccount(recievedData.searchText)
        }
    }
  }
  receiveDataFromLayout = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.LayoutMessageData)
        );
        if (recievedData.userContext) {
          this.handleUserChange(recievedData.userContext)
        }
    }
  }
  getFilteredRows = () => {
    const {users} = this.state
    return users.map(this.mapUserCallback)
  }

  mapUserCallback = (user: IUser) => {
    const { full_name, role_name, region_names, permission_count,  activated} = user.attributes
    const region =  region_names.join(", ")
    const status = activated ? "Active" : "Deactivated";

    return this.createData(
      full_name,
      role_name,
      toString(permission_count),
      region,
      status,
      user.id
    );
  }

  createData(
    name: string,
    role: string,
    permission: string,
    region: string,
    status: string,
    userId: string
  ) {
    return { name, role, permission, region, status, userId };
  }

  chechResponse(responseJson: any) {
    if (responseJson) {
      const list = this.convertResponseToList(responseJson);
      const updatedFilters = this.state.filters.map((item: IFilter) => {
        this.checkAccountDetails(item, list)
        return item;
      });
      this.setState({ filters: updatedFilters });
    }
  }

  convertResponseToList(responseJson:any) {
    if(responseJson) {
      let newData = responseJson?.filter_names?.map((value: string) => ({
        label: value,
        value,
      }))
      return newData
    }
    return []
  }

  checkAccountDetails(item:any, list:any) {
    if (item.title === this.state.currentFilter){
      item.options = list
    }
  }

  checkDeactivation(responseJson:any) {
    if(responseJson?.data) {
      if(this.state.popOverItemStatus === "Active") {
        this.setState({ deactivatedAccount: true });
      }
    }
  }

  checkResponseData(responseJson:any, message: any) {
    this.setState({
      isUsersListLoading : false, 
      users: responseJson?.data || [],
      total: responseJson?.meta?.total_count || 0
    })
    
  }

  handleDrawerWidthChange = (width: number) => {
    this.setState({ drawerWidth: width });
  };

  getUsers = () => {
    this.setState({isUsersListLoading: true})
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem(configJSON.token),
    };
    const nameFilter = this.state.filters.find((item:any) => item.title === "Name")?.value;
    const roleFilter = this.state.filters.find((item:any) => item.title === "Role")?.value;
    const permissionFilter = this.state.filters.find((item:any) => item.title === "Permission")?.value;
    const areaFilter = this.state.filters.find((item:any) => item.title === "Region/Business Area")
      ?.value;
    const searchTextAccount = this.state.searchTextAccount;

    const accountListApiEndPoint =
      configJSON.accountListApi +
      `?region_ids=${this.state.assignedRegionIds}&page_no=` +
      (this.state.page + 1) +
      (nameFilter ? `&filter_by[name]=${nameFilter}` : "") +
      (roleFilter ? `&filter_by[role_name]=${roleFilter}` : "") +
      (permissionFilter ? `&filter_by[permissions]=${permissionFilter}` : "") +
      (areaFilter ? `&filter_by[region_name]=${areaFilter}` : "") +
      (searchTextAccount ? `&filter_by[query]=${searchTextAccount}` : "") +
      (permissionFilter ? "" :  this.state.query);

    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getUsersCallId = getDataMsg.messageId;

    getDataMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), accountListApiEndPoint);
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  updateUser = (userId: string, isActive: boolean) => {
    const header = {
      "Content-Type": configJSON.requestType,
      token: window.localStorage.getItem(configJSON.token),
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateUserCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.employeesApiEndPoint}/${userId}/${isActive ? "activate" : "deactivate"}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleStorageFilter();
    // Customizable Area End
  }

  // Customizable Area Start
  handleStorageFilter = () => {
    const applied_account_filter = localStorage.getItem("account_filter_value");
    if(applied_account_filter) {
      this.setState({
        filters: JSON.parse(applied_account_filter),
        isAppliedFilter: checkIsFilterApplied(JSON.parse(applied_account_filter))
      });
    };
  };

  handleReturnColorType = () => {
    const { isAppliedFilter } = this.state;
    return isAppliedFilter ? "primary" : "inherit";
  };

  processMeta = (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    let newUsers = Array<IUser>(responseJson.meta.total_count);
    for (let i = 0; i < responseJson.meta.total_count; i++) {
      if (
        i >= (responseJson.meta.current_page - 1) * 10 &&
        i < responseJson.meta.current_page * 10
      ) {
        newUsers[i] = responseJson.data[i - (responseJson.meta.current_page - 1) * 10];
      } else {
        if (!this.state.users[i] || !this.state.users[i].id) newUsers[i] = { ...emptyUser };
      }
    }
    this.setState({ users: newUsers});
  };

  handlePageChange = (
    page: number
  ) => {
    this.setState({ page }, () => this.getUsers());
  };

  handleSearchAccount = (value: string) => {	
    this.setState(	
      {	
        page: 0,
        searchTextAccount: value,	
      },	
      this.getUsers	
    );
  };

  handleAddUser = () => {
    removeStorageData("account_form")
    removeStorageData("region")
    this.props.navigation.navigate("AccountCreation");
  };

  handleEditUser = (userId: string) => {
    this.setState({ popOverOpened: false });
    this.props.navigation.navigate("AccountEdit", {
      id: userId,
    });
  };

  handleViewUser = (userId: string) => {
    this.setState({ popOverOpened: false });
    this.props.navigation.navigate("AccountView", {
      id: userId,
    });
    
  };

  handleDeactivate = (clickedAccountId: string) => {
    this.setState({
      disableModalVisible: true,
      clickedAccount: this.state.users.find((item) => item?.id === clickedAccountId),
    });
  };

  handleFilterChangeAccount = (filters: any) => {
    // set filter applied value set
    if (checkIsFilterApplied(filters)) {
      localStorage.setItem("account_filter_value", JSON.stringify(filters));
    } else {
      localStorage.removeItem("account_filter_value");
    }

    this.setState(
      {
        filters,
        page: 0,
        isAppliedFilter: checkIsFilterApplied(filters),
        sortingData: filters[2].value === "" ?
          {
            ...this.state.sortingData,
            permission: checkFilterSorting(filters, 2)
          }
          : {
            name: "",
            role: "",
            permission: checkFilterSorting(filters, 2),
            region_name: ""
          }
      },
      this.getUsers
      );
  };

  changeActivateUser = () => {
    this.setState({ popOverOpened: false, disableModalVisible: false });
    this.updateUser(this.state.popOverItemId, this.state.popOverItemStatus !== "Active");
  };

  handleDeactivateClose = () => {
    this.setState({ disableModalVisible: false, popOverOpened: false });
  };

  handleDeactivatedAccountClose = () => {
    this.setState({ deactivatedAccount: false });
  };

  handleGoList = () => {
    this.setState({ deactivatedAccount: false });
  };

  handleSetAccountFilterDataOnSortClick = (updatedFilters: any) => {
    this.setState({ filters: updatedFilters });
  }

  handleFilterAutoCompleteChange = (filterName: any, query: any) => {
    this.setState({currentFilter: filterName})
    this.state.filters.forEach((item: IFilter) => {
      if (item.title === filterName) item.value = query;
      return item;
    });

    let headers: any = {
      "Content-type": "application/json", 
      token: window.localStorage.getItem(configJSON.token),
    }

    let url: any;
    url = configJSON.accountSuggestionApiEndPoint +
      (filterName === "Name" ? `?account_name=${query}` : '') +
      (filterName === "Role" ? `?role_name=${query}` : '') +
      (filterName === "Region/Business Area" ? `?region_name=${query}` : '');

    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.validationApiMethodType,
    });

    this.accountSuggestionApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  handleUserChange = (userContext: IUserContext) => {
    let assignedRegion = "";
    if (userContext.user) {
      assignedRegion = userContext.user.attributes.employee_proifle.data.attributes.region_ids.join(',')
    }
    const apiKey = customPermissionApiKey.accountPermission;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value,
      assignedRegionIds: assignedRegion
    },()=>this.getUsers())
  };
  // Customizable Area End
}
