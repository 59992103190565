import React from "react";
import { styled, Button, Box, Dialog } from "@material-ui/core";
interface ICheckboxProps {
  checked?: boolean;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLImageElement> | undefined;
  dataTestId?: string
}

export const StyledCheckBox = ({
  checked,
  onClick,
  style,
  dataTestId,
  ...rest
}: ICheckboxProps) => {
  return (
    <img
    data-test-id={dataTestId}
      style={{
        cursor: "pointer",
        ...style,
      }}
      onClick={onClick}
      src={checked ? require("./check_true.svg") : require("./check_false.svg")}
      {...rest}
    />
  );
};

const CommonButton = styled(Button)({
  padding: 16,
  fontSize: 16,
  minWidth: 126,
  fontWeight: 700,
  borderRadius: 8,
  lineHeight: "24px",
  fontFamily: "Montserrat",
  textTransform: "capitalize",
  boxShadow: "none !important",
});
CommonButton.defaultProps = {
  variant: "contained",
};

export const SubmitButton = styled(CommonButton)({
  backgroundColor: "#204B9C !important",
  color: "#FFF",
});
SubmitButton.defaultProps = {
  color: "primary",
};

export const CancelButton = styled(CommonButton)({
  backgroundColor: "#F1F5F9 !important",
  color: "rgba(100, 116, 139, 1)",
});
CancelButton.defaultProps = {
  color: "default",
};

export const ButtonContainer = styled(Box)({
  gap: 24,
  paddingTop: 32,
  display: "flex",
});

export const RoundedDialog = styled(Dialog)({
  "& .MuiPaper-rounded": {
    borderRadius: 24,
  },
  "& .MuiDialog-paperScrollPaper": {
    maxHeight: "unset",
    minWidth: 574,
  },
  "& .MuiDialogContent-root": {
    padding: 40,
    textAlign: "center",
  },
  "& .mainIcon": {
    paddingBottom: 48,
    paddingTop: 32 - 8,
  },
  "& h3": {
    fontSize: 24,
    fontWeight: 600,
    color: "#1A1A1A",
    paddingBottom: 16,
    fontFamily: "Montserrat",
  },
  "& p": {
    fontSize: 20,
    fontWeight: 400,
    color: "#4D4D4D",
    lineHeight: "30px",
    fontFamily: "Montserrat",
  },
});
