import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toString, isUndefined, cloneDeep, uniq, isNumber } from "lodash";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { HardwarePermissionStatus, navigateTo, customPermissionApiKey, checkForHardwarePermissonStatus } from "../../utilities/src/CustomBlockHelpers";
import qzTray from "qz-tray";
import FileUpdater from "../../../components/src/customComponents/FileUpdater";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
import { IUserContext } from "../../navigationmenu/src/PageContainerController.web";
import { makeApiMessage } from "../../../components/src/common";
import { Conveyor, Option, SPArmSetting } from "./types";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  openSharedFolder: boolean;
  snackbarMessage: string;
  permissionStatus: HardwarePermissionStatus | null
  loading: boolean
  loadingUserData: boolean
  disableAddConveyor: boolean
  conveyors: Conveyor[]
  sections: Option[]
  plants: Array<Option & { regionId: number }>
  stores: Option[]
  selectedPlant: string
  editItem: Conveyor | null
  setSharedFolderFunc: Function | null
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfMetalProgettiSolutionIntegration2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fileUpdater: FileUpdater = FileUpdater.getInstance();

  getPlantListCallId = "";
  getStoreListCallId = "";
  getSectionListCallId = "";

  settingListCallId = "";
  saveSettingCallId = "";
  getUserDataCallId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.LayoutDataMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      openSharedFolder: false,
      snackbarMessage: "",
      permissionStatus: null,
      loading: false,
      loadingUserData: false,
      disableAddConveyor: true,
      conveyors: [],
      sections: [],
      plants: [],
      stores: [],
      selectedPlant: "",
      editItem: null,
      setSharedFolderFunc: null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    this.receiveDataFromLayout(message)
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.getPlantListCallId:
          this.setState({
            plants: (responseJson?.data || [])
              .map((item: { id: string; attributes: { name: string; region: { id: number } } }) =>
                ({ id: item.id, option: item.attributes.name, regionId: item.attributes.region.id }))
          })
          break;
        case this.getStoreListCallId:
          this.setState({
            stores: (responseJson?.data || [])
              .map((item: { id: string; attributes: { store_name: string } }) => ({ id: item.id, option: item.attributes.store_name }))
          })
          break;
        case this.getSectionListCallId:
          this.setState({
            sections: (responseJson?.data || [])
              .map((item: { id: string; attributes: { name: string } }) => ({ id: item.id, option: item.attributes.name }))
          })
          break;
        case this.settingListCallId:
          this.setState({
            conveyors: this.mapSettingList(responseJson),
            disableAddConveyor: false,
            editItem: null,
            loading: false
          })
          break;
        case this.saveSettingCallId:
          if (responseJson?.data) {
            this.getUserData()
            this.getSettingList()
          }
          break;
        case this.getUserDataCallId:
          this.setState({ loadingUserData: false })
          if (responseJson?.data) {
            this.fileUpdater.startListening(responseJson.data.attributes?.employee_proifle?.data.attributes.share_folders)
          }
          break;
        default:
          break;
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    super.componentDidMount()

  }

  receiveDataFromLayout = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.LayoutMessageData)
        );
        if (recievedData.userContext) {
            this.handleUserChange(recievedData.userContext)
        }
    }
  }
  
  fetchLists = (regionIds: string) => {
    const lists = [
      {
        callId: "getPlantListCallId",
        endPoint: configJSON.plantListApi + `?region_ids=${regionIds}`
      },
      {
        callId: "getSectionListCallId",
        endPoint: configJSON.sectionListApi
      }
    ] as const
    lists.forEach(item => {
      const message = makeApiMessage({
        url: item.endPoint,
        method: "GET"
      })
      this[item.callId] = message.messageId
      this.send(message)
    })
  }

  getUserData = () => {
    this.setState({ loadingUserData: true })
    const apiMessage = makeApiMessage({
      url: configJSON.employeeApi,
      method: "GET"
    })
    this.getUserDataCallId = apiMessage.messageId
    this.send(apiMessage)
  }

  getSettingList = () => {
    this.setState({ loading: true })
    const message = makeApiMessage({
      url: configJSON.metalProgettiSettingApi + `?plant_id=${this.state.selectedPlant}`,
      method: "GET"
    })
    this.settingListCallId = message.messageId
    this.send(message)
  }

  getStoreList = (regionId?: string | number) => {
    const message = makeApiMessage({
      url: configJSON.storeListApi + `&region_id=${toString(regionId)}`,
      method: "GET"
    })
    this.getStoreListCallId = message.messageId
    this.send(message)
  }

  mapSettingList = (responseJson?: { data: Array<{ id: string; attributes: Conveyor }> }) =>
    (responseJson?.data || [])
      .map((item: { id: string; attributes: Conveyor }) => {
        const pf_dest_configurations_attributes = item.attributes.pf_dest_configurations?.map(
          item => ({ id: item.id, route_arm_number: item.route_arm_number, route_store_ids: item.route_store_ids })
        ) || []
        const sp_arms_settings_attributes = item.attributes.sp_arms_settings?.map(
          item => ({ id: item.id, sp_arm_number: item.sp_arm_number, sp_arm_hf: item.sp_arm_hf, sp_arm_sp: item.sp_arm_sp, sp_arm_fh: item.sp_arm_fh })
        ) || []
        return ({
          ...item.attributes,
          id: item.id,
          category_ids: this.getSelectedIds(item.attributes.categories),
          sub_order_section_ids: this.getSelectedIds(item.attributes.sub_order_sections),
          store_management_ids: this.getSelectedIds(item.attributes.store_managements),
          pf_dest_configurations_attributes,
          sp_arms_settings_attributes,
          new_sp_arm_setting: { sp_arm_number: 0 },
          route_arm_number: pf_dest_configurations_attributes[0]?.route_arm_number || null
        })
      })


  handleCloseSnackbar = () => this.setState({ snackbarMessage: "" })

  handleRedirect = () => navigateTo({ id: "", props: this.props, screenName: "AdvancedSearch" })

  handleSubmit = async () => {
    const { txtInputValue, setSharedFolderFunc } = this.state
    try {
      if (txtInputValue) {
        if (!qzTray.websocket.isActive()) {
          await qzTray.websocket.connect()
        }
        await qzTray.file.list(txtInputValue, { shared: true, sandbox: false })
        setSharedFolderFunc?.("share_folder", txtInputValue)
      } else {
        setSharedFolderFunc?.("share_folder", "")
      }
      this.setState({ openSharedFolder: false, setSharedFolderFunc: null, txtInputValue: "" })
    } catch (error) {
      this.setState({ snackbarMessage: "Unable to verify specified location" })
    }
  }

  handleUserChange = (context: IUserContext) => {
    const apiKey = customPermissionApiKey.hardwarePermission;
    const userData = context.user?.attributes.permission_groups;
    const value = checkForHardwarePermissonStatus(apiKey, userData as unknown as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value,
    });
    const regionIds = toString(context.user?.attributes.employee_proifle.data.attributes.region_ids.join(","))
    this.fetchLists(regionIds)
  }

  toggleSharedFolderDialog = (openPayload?: { setFieldValue?: Function, currentValue: string }) =>
    this.setState(({
      openSharedFolder: Boolean(openPayload),
      setSharedFolderFunc: openPayload?.setFieldValue || null,
      txtInputValue: toString(openPayload?.currentValue)
    }), () => runEngine.debugLog("DIALOG DATA >>> ", { openPayload, txtInput: this.state.txtInputValue }))

  handleChangePlant = (plantId: string, regionId: number) => {
    this.setState({ selectedPlant: plantId, disableAddConveyor: false }, () => {
      this.getSettingList()
      this.getStoreList(regionId)
    })
  }

  handleAddConveyor = () => {
    const conveyorInitialData: Conveyor = {
      share_folder: "",
      arms: 13,
      slot_space: null,
      slot_hf: false,
      slot_sp: false,
      slot_fh: false,
      fr_exit_hf: false,
      fr_exit_sp: false,
      fr_exit_fh: false,
      fr_exit_number: null,
      sub_order_hf: false,
      sub_order_sp: false,
      sub_order_fh: false,
      plant_id: Number(this.state.selectedPlant),
      category_ids: [],
      store_management_ids: [],
      sub_order_section_ids: [],
      route_arm_number: null,
      pf_dest_configurations_attributes: [],
      sp_arms_settings_attributes: [],
      new_sp_arm_setting: { sp_arm_number: 0 }
    }
    this.setState((prev) => ({
      conveyors: [...prev.conveyors, conveyorInitialData],
      editItem: conveyorInitialData,
      disableAddConveyor: true
    }), () => {
      document.getElementById("scroll-pos")?.scrollIntoView?.({ block: "start", inline: "nearest", behavior: "smooth" })
    })
  }

  editConveyor = (conveyorItem: Conveyor | null) => this.setState({ editItem: conveyorItem })

  handleCancelConveyorSetting = (resetForm: Function) => {
    const { editItem } = this.state
    if (isUndefined(editItem?.id)) {
      this.setState((prev) => ({
        conveyors: prev.conveyors.filter(item => item !== editItem),
        editItem: null,
        disableAddConveyor: false
      }))
    }
    else {
      resetForm()
      this.setState({ editItem: null })
    }
  }

  handleSaveConveyorSetting = (values: Conveyor) => {
    const { editItem } = this.state
    const endPoint = configJSON.metalProgettiSettingApi + (isUndefined(editItem?.id) ? "" : `/${editItem?.id}`)
    const postData = cloneDeep(values)
    delete postData.id
    delete postData.plant
    delete postData.categories
    delete postData.sub_order_sections
    delete postData.store_managements
    delete postData.route_arm_number
    delete postData.new_sp_arm_setting
    delete postData.pf_dest_configurations
    postData.pf_dest_configurations_attributes = postData
      .pf_dest_configurations_attributes
      .filter(item => isNumber(item.id) || item.route_store_ids.length)
      .map(item => item.route_store_ids.length ? item : ({ ...item, _destroy: true }))

    if (postData.sp_arms_settings?.length) {
      postData.sp_arms_settings.forEach(
        setting => {
          const newSetting = postData.sp_arms_settings_attributes.find(item => item.sp_arm_number === setting.sp_arm_number)
          if (newSetting) {
            newSetting.id = setting.id
          } else {
            postData.sp_arms_settings_attributes.push({ id: setting.id, sp_arm_number: setting.sp_arm_number, _destroy: true })
          }
        }
      )
    }
    delete postData.sp_arms_settings

    const message = makeApiMessage({
      url: endPoint,
      method: isUndefined(editItem?.id) ? "POST" : "PUT",
      body: JSON.stringify({
        data: postData
      })
    })
    this.saveSettingCallId = message.messageId
    this.send(message)
  }

  isDisableButtonAdd = () => this.state.disableAddConveyor || Boolean(this.state.editItem)

  getNumberOptions = (armCount: number | null) => {
    if (!armCount) return []
    return Array(armCount).fill(0).map((element, index) => toString(index + 1))
  }

  getSpArmNumberOptions = (armCount: number | null, spArmData: Conveyor["sp_arms_settings_attributes"]) => {
    const selectedArmNumbers = spArmData.map(item => toString(item.sp_arm_number))
    return !armCount ? [] : Array(armCount).fill(0)
      .map((element, index) => toString((index + 1)))
      .filter((option) => !selectedArmNumbers.includes(option))
  }


  getSelectedIds = (selectedList?: Array<{ id: number }>) => selectedList?.map(item => item.id) || []

  updateArms = (armValue: number | null, setFieldValue: Function, currentValue: Conveyor) => {
    setFieldValue("arms", armValue)
    if (!armValue) {
      setFieldValue("fr_exit_number", null)
      setFieldValue("sp_arm_number", null)
      setFieldValue("sp_arms_settings_attributes", [])
    } else {
      setFieldValue("sp_arms_settings_attributes", currentValue.sp_arms_settings_attributes.filter(item => item.sp_arm_number <= armValue))
      if (currentValue.fr_exit_number && currentValue.fr_exit_number > armValue) {
        setFieldValue("fr_exit_number", null)
      }
      if (currentValue.route_arm_number && currentValue.route_arm_number > armValue) {
        setFieldValue("route_arm_number", currentValue.pf_dest_configurations_attributes[0]?.route_arm_number || null)
      }
    }
    setFieldValue(
      "pf_dest_configurations_attributes",
      currentValue.pf_dest_configurations_attributes.map(item => ({
        ...item,
        route_store_ids: (!armValue || item.route_arm_number > armValue) ? [] : item.route_store_ids
      }
      ))
    )
    setFieldValue("new_sp_arm_setting", { sp_arm_number: 0 })
  }

  addSpArm = (formValues: Conveyor, setFieldValue: Function) => {
    const { sp_arms_settings_attributes, new_sp_arm_setting } = formValues
    setFieldValue("sp_arms_settings_attributes", [...sp_arms_settings_attributes, new_sp_arm_setting])
    setFieldValue("new_sp_arm_setting", { sp_arm_number: 0 })
  }

  removeSpArm = (spArmData: SPArmSetting[], itemToRemove: SPArmSetting, setFieldValue: Function) => {
    setFieldValue("sp_arms_settings_attributes", spArmData.filter(item => item !== itemToRemove))
  }

  updateFieldValue = (fieldName: string, newValue: unknown, setFieldValue: Function) => setFieldValue(fieldName, newValue)

  getCategoryValues = (categoryIds?: number[]) => this.state.sections.filter(section => categoryIds?.includes(Number(section.id)))

  getSelectedCategoriesForList = (selectedCategories: Option[]) => {
    const isSelectedAll = selectedCategories?.length && selectedCategories.length === this.state.sections.length
    return isSelectedAll ? [{ id: "-1", option: "Select All" }, ...selectedCategories] : selectedCategories
  }

  getStoreValues = (storeIds?: number[], optionsList?: Option[]) => (optionsList || this.state.stores).filter(section => storeIds?.includes(Number(section.id)))


  getSelectedStoresForList = (selectedStores: Option[], optionsList?: Option[]) => {
    const availableOptions = optionsList || this.state.stores
    const isSelectedAll = selectedStores?.length && selectedStores.length === availableOptions.length
    return isSelectedAll ? [{ id: "-1", option: "Select All" }, ...selectedStores] : selectedStores
  }

  handleSelectSection = (item: Option, currentValue: number[], setFieldValue: Function, fieldName: string) => {
    const isSelectedAll = currentValue.length && currentValue.length === this.state.sections.length
    if (item.id === "-1") {
      setFieldValue(fieldName, isSelectedAll ? [] : this.state.sections.map(section => Number(section.id)))
    } else {
      const sectionId = Number(item.id)
      setFieldValue(fieldName,
        currentValue.includes(sectionId) ?
          currentValue.filter(selected => selected !== sectionId)
          : [...currentValue, sectionId]
      )
    }
  }

  handleSelectStore = (item: Option, currentValue: number[], pfDestConfigs: Conveyor["pf_dest_configurations_attributes"], setFieldValue: Function) => {
    const isSelectedAll = currentValue.length > 0 && currentValue.length === this.state.stores.length
    let newValue: Array<number>
    if (item.id === "-1") {
      newValue = isSelectedAll ? [] : this.state.stores.map(store => Number(store.id))
      isSelectedAll && setFieldValue("pf_dest_configurations_attributes", pfDestConfigs.map(item => ({ ...item, route_store_ids: [] })))
    } else {
      const storeId = Number(item.id)
      newValue = currentValue.includes(storeId) ?
        currentValue.filter(selected => selected !== storeId)
        : [...currentValue, storeId]
      currentValue.includes(storeId) && setFieldValue(
        "pf_dest_configurations_attributes",
        pfDestConfigs.map(item => ({ ...item, route_store_ids: item.route_store_ids.filter(routeStoreId => routeStoreId !== storeId) }))
      )
    }
    setFieldValue("store_management_ids", newValue)
  }

  getRouteStores = (allStores: Option[], currentRouteStoreIds: number[], pfDestConfigs: Conveyor["pf_dest_configurations_attributes"]) => {
    const allRouteStoreIds = uniq(pfDestConfigs.flatMap(item => item.route_store_ids))
    const unavailableStoreIds = allRouteStoreIds.filter(storeId => !currentRouteStoreIds.includes(storeId))
    return allStores.filter(store => !unavailableStoreIds.includes(Number(store.id))
    )
  }

  handleSelectRouteStore = (
    item: Option,
    optionsList: Option[],
    formValues: Conveyor,
    setFieldValue: Function,
    currentPfDestConfig?: { id?: number; route_arm_number: number; route_store_ids: number[]; _destroy?: true }
  ) => {
    const { route_arm_number, pf_dest_configurations_attributes } = formValues
    if (!isNumber(route_arm_number)) return;
    const currentValue = currentPfDestConfig?.route_store_ids || []
    const isSelectedAll = currentValue.length && currentValue.length === optionsList.length
    let newValue: number[];
    if (item.id === "-1") {
      newValue = isSelectedAll ? [] : optionsList.map(store => Number(store.id))
    } else {
      const storeId = Number(item.id)
      newValue = currentValue.includes(storeId) ?
        currentValue.filter(selected => selected !== storeId)
        : [...currentValue, storeId]
    }

    const newPfDestConfigs = currentPfDestConfig ?
      pf_dest_configurations_attributes.map(item => item !== currentPfDestConfig ? item : { ...currentPfDestConfig, route_store_ids: newValue })
      : [...pf_dest_configurations_attributes, { route_arm_number, route_store_ids: newValue }]
    setFieldValue("pf_dest_configurations_attributes", newPfDestConfigs)
  }
  // Customizable Area End
}
