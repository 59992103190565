/** @format */

import React from "react";
// Customizable Area Start
import AddSpecificationController, { Props } from "./AddSpecificationController.web"
import { withStyles } from "@material-ui/core/styles";
import { Box, Input, Snackbar,styled } from "@material-ui/core";
import Alert, {Color} from '@material-ui/lab/Alert';
import CustomItemList from "../../../components/src/customComponents/CustomItemList.web";
import { Formik, FormikErrors, FormikTouched } from 'formik';
import Loader from "../../../components/src/Loader.web";
import { CustomSearchableMultiSelect } from "../../../components/src/customComponent/CustomSearchableMultiSelect.web";
import { configJSON } from "./AddPreferenceController.web";
import { colors } from "../../../blocks/utilities/src/Colors";
import { BottomContainer } from "./AddPreference.web";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";

// Customizable Area End

// Customizable Area Start
const useStyles = () => ({
    specificationContainer: {
        fontFamily: "Roboto-Medium",
    },
    title: {
        fontWeight: 600,
        lineHeight: "30px",
        color: colors().black,
        fontSize: "24px",
        "@media only screen and (max-width: 1024px)": {
            fontWeight: 600,
            fontSize: "16px",
            color: colors().black,
        },
    },
    titleHeading: {
        marginBottom: "4px",
        display: "block",
        fontSize: 18,
        lineHeight: "24px",
        fontWeight: 600,
        paddingBottom: 4,
        color: colors().black,
        "@media only screen and (max-width: 1024px)": {
            fontSize: "16px",
        },
    },
    innerContainer: {
        display: "flex",
        margin: "32px 0",
        padding: 25,
        borderRadius: 12,
        boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.2)",
    },
    flexWidthLeft: {
        flexBasis: "50%",
        "@media only screen and (max-width: 1024px)": {
            width: "50%"
        },
    },
    flexWidthRight: {
        flexBasis: "50%",
        "@media only screen and (max-width: 1024px)": {
            width: "100%"
        },
    },
   labelSpace:{ marginBottom: '4px'},
    heading: {
        display: "block",
        fontSize: 16,
        lineHeight: "24px",
        fontWeight: 600,
        color: colors().charcoal,
        "@media only screen and (max-width: 1024px)": {
            fontSize: "13px",
        },
    },
    inputField: {
        width: "90%",
        border: "1px solid #ececec",
        borderRadius: 8,
        padding: "9px 18px",
        outline: "none",
        "&:hover": {
            border: "1px solid #ececec",
        },
        "&:focus": {
            border: "1px solid #ececec",
        },
        "@media only screen and (max-width: 1024px)": {
            opacity: 1,
            color: colors().darkliver,
            fontSize: "12px",
            fontFamily: "Montserrat",
            height: "44px"
        },
    },
    innerInputItem: {
        width: "100%",
        "@media only screen and (max-width: 1024px)": {
            fontSize: "50%",
        },
    },

    innerItem: {
        marginTop: 25,
        width: "90%",
        "& .MuiAutocomplete-root": {
            maxWidth: "unset !important",
            minWidth: "unset !important",
            "& .MuiInputBase-root": {
                padding: "4px",
                "@media only screen and (max-width: 1024px)": {
                    maxHeight: "unset !important",
                }
            }
        }
    },
    error: {
        borderColor: "red !important"
    },
    errorMessage: {
        color: "red",
        fontSize: "14px",
        marginTop: "4px"
    },
    groupBtn: {
        marginBottom: 100
    },
    mainComp:{
        maxHeight: 200,
        width: '100%',
    }
});
const StylesMainBox = styled(Box)({
    marginTop:"25px",
})
// Customizable Area End

// Customizable Area Start
export class AddSpecification extends AddSpecificationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    checkError(error: string | string[] | FormikErrors<{}>[] | undefined, touch: boolean | FormikTouched<{}>[] | undefined) {
        if (error && touch) {
          return true
        }
      }

    errorMessage(error: string | string[] | FormikErrors<{}>[] | undefined, touch: boolean | FormikTouched<{}>[] | undefined) {
        if (error) {
            if (touch) {
                return <p className={this.props.classes.errorMessage}>{error}</p>;
            } else return null;
        } else return;
    }

    render() {
        const { sectionOptions, itemCount, serviceOptions, specificationFirstName,
            specificationSecondName, showInputError, productOptions,
            selectedSectionOPtions, selectedProductOPtions, selectedServiceOPtions, showSectionOptionError, showProdutOptionError,
            showServiceOptionError, openSnackbar, message, severity
        } = this.state
        const { classes } = this.props;
        const inputPlaceholder = `Specification Option ${itemCount}`;
        const firstNameInputClass = showInputError && specificationFirstName === '' && `${classes.error}`;
        const SectionDropdownClass = showSectionOptionError && selectedSectionOPtions.length === 0 && `${classes.error}`;
        const productDropdownClass = showProdutOptionError && selectedProductOPtions.length == 0 && `${classes.error}`;
        const serviceDropdownClass = showServiceOptionError && selectedServiceOPtions.length == 0 && `${classes.error}`;
        return ( 
            <>
             <StylesMainBox>
             <CustomLoader loading={this.state.addBtnLoading}/>
                <Snackbar data-test-id="snackbar-specification" anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }} open={openSnackbar} autoHideDuration={3000} onClose={this.handleCloseSnackbar} >
                    <Alert data-test-id="alert-snackbar" severity={severity as Color} >
                        {message}
                    </Alert>
                </Snackbar>
                    <Formik
                        data-test-id="formikAddSpecification"
                        initialValues={this.state.initialValues}
                        onSubmit={() => { }}
                        enableReinitialize
                    >
                        {({ values, errors, touched, handleSubmit, getFieldProps, setFieldValue }) => (
                            <form onSubmit={handleSubmit} data-test-id="addUpchargeForm">
                                <Box className={classes.specificationContainer}>
                                    
                                    <p className={classes.title}>
                                        Add Product Specification
                                    </p>
                                    <Box className={classes.innerContainer}>
                                        <Box className={`${classes.flexWidthLeft} ${classes.left}`}>
                                            <Box className={classes.innerInputItem}>
                                                <p className={`${classes.heading} ${classes.labelSpace}`}>
                                                    Specification Name
                                                </p>
                                                <Input
                                                    data-test-id="first-specification-name"
                                                    type="text"
                                                    className={`${classes.inputField} ${firstNameInputClass}`}
                                                    placeholder="Enter Name"
                                                    disableUnderline
                                                    value={specificationFirstName}
                                                    onChange={this.handleSpecificationFirstNameChange}
                                                />
                                                {this.state.specificationError.specificationFirstNameError && <p className={classes.errorMessage}>{this.state.specificationError.specificationFirstNameError}</p>}
                                            </Box>
                                            <Box className={classes.innerItem}>
                                                <p className={`${classes.heading} ${classes.labelSpace}`} >
                                                    Section  
                                                </p>
                                                <CustomSearchableMultiSelect
                                                    id="section"
                                                    name="section"
                                                    dataTestId="sectionDropdown"
                                                    checkSelectAllText={() => {}}
                                                    value={this.state.selectedSections}
                                                    optionList={this.state.sectionOptions}
                                                    placeHolderText="Select Section"
                                                    changeInputOnClear
                                                    handleScroll={this.handleScrollSectionDropdown}
                                                    handleClickOnSelectAll={() => {}}
                                                    isOnBlurFunction={() => this.debouncedFunction("", this.handleSectionAutoCompleteChange)}
                                                    debouncedFunction={(getValue: string) => { this.debouncedFunction(getValue, this.handleSectionAutoCompleteChange) }}
                                                    handleSelectOptions={(value: { id: string | number, option: string }[], option: { id: string | number, option: string }) => { this.handleSelectSectionOptions(value, option, setFieldValue) }}
                                                    emptyDataMessage="No Section Found"
                                                    handleEmptyAutoSelectValue={() => {}}
                                                    selectedOptionForList={this.state.selectedSectionsForList}
                                                    isError={this.checkError(errors.section, touched.section)}
                                                />
                                                {this.errorMessage(errors.section, touched.section)}
                                            </Box>
                                            <Box className={classes.innerItem}>
                                                <p className={`${classes.heading} ${classes.labelSpace}`} >
                                                    Product  
                                                </p>
                                                <CustomSearchableMultiSelect
                                                    id="product"
                                                    name="product"
                                                    dataTestId="productDropdowns"
                                                    checkSelectAllText={(updatedValue: { id: string | number, option: string }[]) => { this.checkSpecificationSelectAllText(updatedValue); setFieldValue('product', updatedValue) }}
                                                    value={this.state.selectedForAdd}
                                                    optionList={this.state.productListItemsDropdown}
                                                    placeHolderText={configJSON.selectProductText}
                                                    changeInputOnClear
                                                    handleScroll={this.handleSpecificationScrollProductDropdown}
                                                    isOnBlurFunction={() => this.debouncedFunction("", this.handleSpecAutoCompltProductChange)}
                                                    handleClickOnSelectAll={() => { this.handleSpecificationClickOnSelectAll(); setFieldValue('product', ["All"]) }}
                                                    debouncedFunction={(getValue: string) => { this.debouncedFunction(getValue, this.handleSpecAutoCompltProductChange) }}
                                                    handleSelectOptions={(value: { id: string | number, option: string }[], option: { id: string | number, option: string }) => { this.handleSpecificationSelectOptions(value, option, setFieldValue) }}
                                                    emptyDataMessage={configJSON.noProductText}
                                                    handleEmptyAutoSelectValue={() => { this.handleSpecificationEmptyAutoSelectValue() }}
                                                    // style={{ maxWidth: "100%", marginTop: 12 }}
                                                    selectedOptionForList={this.state.addSpecSelectedOptionForList}
                                                    isError={this.checkError(errors.product, touched.product)}
                                                />
                                                {this.errorMessage(errors.product, touched.product)}
                                            </Box>
                                            <Box className={classes.innerItem}>
                                                <p className={classes.titleHeading}>Specification Options</p>
                                                <CustomItemList
                                                    newItem={this.state.newItem}
                                                    items={this.state.items}
                                                    inputPlaceholder={inputPlaceholder}
                                                    handleChange={this.handleAddInputChange}
                                                    handleClick={this.handleClickAdItems}
                                                    handleDelete={this.handleDeleteItemList}
                                                />
                                            </Box>
                                        </Box>
                                        <Box className={`${classes.flexWidthRight} ${classes.right}`}>
                                            <Box className={classes.innerInputItem}>
                                                <p className={`${classes.heading} ${classes.labelSpace}`}>
                                                    Specification Arabic Name
                                                </p>
                                                <Input
                                                    dir="rtl"
                                                    data-test-id="second-specification-name"
                                                    type="text"
                                                    className={`${classes.inputField}`}
                                                    placeholder="Enter Arabic Name"
                                                    disableUnderline
                                                    value={this.state.specificationSecondName}
                                                    onChange={this.handleSpecificationSecondNameChange}
                                                />
                                                {this.state.specificationError.specificationSecondNameError && <p className={classes.errorMessage}>{this.state.specificationError.specificationSecondNameError}</p>}
                                            </Box>
                                            <Box className={classes.innerItem}>
                                                <p className={`${classes.heading} ${classes.labelSpace}`} >
                                                    Service
                                                </p>
                                                <CustomSearchableMultiSelect
                                                    id="service"
                                                    name="service"
                                                    dataTestId="serviceDropdown"
                                                    checkSelectAllText={() => {}}
                                                    value={this.state.selectedServices}
                                                    optionList={this.state.serviceOptions}
                                                    placeHolderText="Select Service"
                                                    handleScroll={this.handleScrollServiceDropdown}
                                                    changeInputOnClear
                                                    handleClickOnSelectAll={() => {}}
                                                    isOnBlurFunction={() => this.debouncedFunction("", this.handleServiceAutoCompleteChange)}
                                                    debouncedFunction={(getValue: string) => { this.debouncedFunction(getValue, this.handleServiceAutoCompleteChange) }}
                                                    handleSelectOptions={(value: { id: string | number, option: string }[], option: { id: string | number, option: string }) => { this.handleSelectServiceOptions(value, option, setFieldValue) }}
                                                    emptyDataMessage="No Service Found"
                                                    handleEmptyAutoSelectValue={() => { }}
                                                    selectedOptionForList={this.state.selectedServicesForList}
                                                    isError={this.checkError(errors.service, touched.service)}
                                                />
                                                {this.errorMessage(errors.service, touched.service)}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <BottomContainer className={`group-button ${classes.groupBtn}`}>
                                    <button onClick={this.handleCancelButton} data-test-id="group-cancel-button" className="cancel">Cancel</button>
                                    <button data-test-id="group-add-button" type="submit" onClick={() => { this.handleClickAdd() }} className="add">Add</button>
                                </BottomContainer>
                            </form>
                        )}
                    </Formik>
            </StylesMainBox>
            </>
        );
    }
    // Customizable Area End
}
export default withStyles(useStyles)(AddSpecification);

// Customizable Area End
