// Customizable Area Start
import React, { FunctionComponent } from "react";
import { TableCell, Box, styled, StyledComponentProps } from "@material-ui/core";
import { colors } from "../../blocks/utilities/src/Colors";

export type SortingType = "" | "asc" | "desc";
export interface ISortingData {
  [key: string]: SortingType;
}

interface SortingTableCellProps {
  content: React.ReactNode;
  width: string;
  width1024?: string;
  width1280?: string;
  width1366?: string;
  type?: "left" | "middle" | "right" | "action" | "icon";
  iconSrc?: string;
  style?: any;
  tableCellStyle?: any;
  wrap?: boolean;
  isElementStyle?: boolean;
}

const SortingTableCell: FunctionComponent<SortingTableCellProps> = ({
  content,
  width,
  width1024 = "",
  width1280 = "",
  width1366 = "",
  type = "middle",
  iconSrc,
  tableCellStyle = {},
  style = {},
  wrap,
  isElementStyle = false
}) => {
  const typeStyle =
    type === "left"
      ? 'sortingTableLeftCell'
      : type === "right"
        ? 'sortingTableRightCell'
        : type === "middle"
          ? 'sortingTableMiddleCell'
          : '';
  return (
    <TableCell
      style={
        type === "action"
          ? webStyle.sortingTableActionCellContainer
          : webStyle.sortingTableCellContainer
      }
      align='left'
    >
      {type === "icon" ? (
        <IconDiv
        width1024={width1024}
        width1280={width1280}
        width1366={width1366}
          width={width}
          tableCellStyle={tableCellStyle}
        >
          <img style={webStyle.cellIcon} src={iconSrc} />
        </IconDiv>
      ) : (
        <TableCellBoxWrapper>
          <TableDiv
            width1024={width1024}
            width1280={width1280}
            width1366={width1366}
            width={width}
            wrap={wrap}
            style={isElementStyle ? style : {}}
            tableCellStyle={tableCellStyle}
            classStyles={!isElementStyle? style: {}}
            className={typeStyle}
          >
            {typeof content === "string"
              ? content.trim() ?? "---"
              : content ?? "---"}
          </TableDiv>
        </TableCellBoxWrapper>
      )}
    </TableCell>
  );
};

const webStyle = {
  sortingTableCellContainer: {
    border: "hidden",
    padding: "0px",
  },
  sortingTableActionCellContainer: {
    paddingTop: "4px",
    border: "hidden",
    paddingBottom: "4px",
  },
  sortingTableLeftCell: {
    fontSize: "16px",
    paddingBottom: "10px",
    fontWeight: 600,
    width: "100%",
    paddingTop: "10px",
    marginLeft: "12px",
    borderBottom: "1px solid #ECECEC",
    borderLeft: "1px solid #ECECEC",
    borderBottomLeftRadius: "12px",
    borderTopLeftRadius: "12px",
    borderTop: "1px solid #ECECEC",
    overflow: "hidden",
    paddingLeft: "12px",
    marginBottom: "0px",
    marginTop: "0px",
    textOverflow: "ellipsis",
    paddingRight: "12px",
    whiteSpace: "nowrap",
    minHeight: "45px",

  },
  sortingTableRightCell: {
    fontSize: "16px",
    width: "100%",
    paddingTop: "10px",
    paddingBottom: "10px",
    borderTop: "1px solid #ECECEC",
    fontWeight: 600,
    marginTop: "0px",
    borderRight: "1px solid #ECECEC",
    borderBottomRightRadius: "12px",
    borderBottom: "1px solid #ECECEC",
    marginRight: "12px",
    marginBottom: "0px",
    borderTopRightRadius: "12px",
    whiteSpace: "nowrap",
    minHeight: "45px"
  },
  sortingTableMiddleCell: {
    paddingBottom: "10px",
    fontWeight: 600,
    width: "100%",
    paddingTop: "10px",
    borderTop: "1px solid #ECECEC",
    fontSize: "16px",
    marginBottom: "0px",
    borderBottom: "1px solid #ECECEC",
    textOverflow: "ellipsis",
    overflow: "hidden",
    marginTop: "0px",
    maxWidth: "18vw",
    paddingRight: "12px",
    whiteSpace: "nowrap",
    minHeight: "45px"
  },
  cellIcon: {
    width: "32px",
    height: "32px",
  },
};

const TableCellBoxWrapper = styled(Box)({
  "& .sortingTableLeftCell": {
    fontSize: "16px",
    padding: "10px 12px",
    fontWeight: 600,
    width: "100%",
    marginLeft: "12px",
    borderBottomLeftRadius: "12px",
    borderTopLeftRadius: "12px",
    overflow: "hidden",
    marginBottom: "0px",
    marginTop: "0px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    minHeight: "45px",
    border: "1px solid",
    borderColor: colors().lightborder,
    borderRight: "none",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "12px",
      fontWeight: 500,
      color: colors().viewTextColor,
      padding: "7px 8px",
      boxSizing: "border-box",
      borderRight: "none",
    },
  },
  "& .sortingTableRightCell": {
    fontSize: "16px",
    width: "100%",
    padding: "10px 0px",
    fontWeight: 600,
    marginTop: "0px",
    borderBottomRightRadius: "12px",
    marginRight: "12px",
    marginBottom: "0px",
    borderTopRightRadius: "12px",
    whiteSpace: "nowrap",
    minHeight: "45px",
    border: "1px solid",
    borderColor: colors().lightborder,
    borderLeft: "none",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "12px",
      fontWeight: 400,
      padding: "7px 0px",
      color: colors().viewTextColor,
      boxSizing: "border-box",
      borderLeft: "none",
    },
  },
  "& .sortingTableMiddleCell": {
    padding:"10px 12px 10px 0",
    fontWeight: 600,
    width: "100%",
    fontSize: "16px",
    marginBottom: "0px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    marginTop: "0px",
    whiteSpace: "nowrap",
    minHeight: "45px",
    border: "1px solid",
    borderColor: colors().lightborder,
    borderLeft: "none",
    borderRight: "none",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "12px",
      fontWeight: 500,
      color: colors().viewTextColor,
      padding:"7px 8px 7px 0",
      boxSizing: "border-box",
      borderLeft: "none",
      borderRight: "none",
    },
  },
});

interface RowProps {
  width: string;
  tableCellStyle?: { [property: string]: string };
  styles?: { [property: string]: string };
  wrap?: boolean;
  width1024?: string;
  width1280?: string;
  width1366?: string;
  classStyles?:  { [property: string]: string };
}

const IconDiv = styled('div')((props: StyledComponentProps & RowProps) => ({
  ...webStyle.sortingTableMiddleCell,
  whiteSpace: "nowrap",
  paddingTop: "6px",
  paddingBottom: "5px",
  maxWidth: props.width,
  ...props.tableCellStyle,
  "@media only screen and (max-width: 1024px)": {
    maxWidth: props.width1024
  },
  "@media only screen and (max-width: 1280px)": {
    maxWidth: props.width1280
  },
  "@media only screen and (max-width: 1366px)": {
    maxWidth: props.width1366
  }
}));

const TableDiv = styled('div')((props: StyledComponentProps & RowProps) => ({
  maxWidth: props.width,
  ...props.tableCellStyle,
  ...props.styles,
  ...props.classStyles,
  textWrap: props.wrap ? "wrap" : "nowrap",
  "@media only screen and (max-width: 1024px)": {
    maxWidth: props.width1024
  },
  "@media only screen and (max-width: 1280px)": {
    maxWidth: props.width1280
  },
  "@media only screen and (max-width: 1366px)": {
    maxWidth: props.width1366
  }
}));

export default SortingTableCell;

// Customizable Area End
