import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  IconButton,
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableCell,
  TableBody,
  Table,
  styled
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DragHandleIcon from '@material-ui/icons/DragHandle';
import { DragDropContext, Droppable, Draggable, DroppableProvided, DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import ConfirmationModal from "../../../components/src/customComponents/ConfirmationModal.web";
import { 
  CustomFormControl,
  CustomPopover, 
} from '../../../components/src/customComponents/CustomTags.web';
import ExportImportPopover from "../../../components/src/customComponents/ExportImportButton.web";
import ProductListingFilters from '../../../components/src/customComponents/ProductListingFilters.web';
import { filterIcon, activeFilterIcon } from './assets';
import { ExpandLess, ExpandMore } from "@material-ui/icons";

import CfProductListController, {
  Props, configJSON,
} from "./CfProductListController";
import SortingTableHeader from "../../../components/src/SortingTableHeader2";
import AddPriceListModal from "./AddPricelistModal.web";
import { CustomSnackbar } from "../../../components/src/customComponents/CustomSnackbar.web";
import AutocompleteSelect from "../../../components/src/AutocompleteSelect.web";
import { renderBaseOnConditions, sortStringCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";
import { TableRowsSkeleton } from "../../../components/src/customComponents/TableRowsSkeleton.web";
import { LoadingComponent } from "../../../components/src/Loader.web";
const Strings = configJSON.Strings;

interface IIcon {
    data: {
      id: string;
      type: string;
      attributes: {
        id: number;
        image: string;
        name: string;
        second_name: string;
        image_type: string;
        name_translation: string;
      };
    }
}

type IListShow = {
  id: number;
  name: string,
}

type IListShowArray = Array<IListShow>

type IServiceList =  {
  id: number;
  price: string;
  name: string;
}
type IServiceListArray = Array<IServiceList>

interface CatalogueService {
  id: string;
  type: string;
  attributes: {
    id: number;
    price: string;
    catalogue_variant_id: number;
    default: boolean;
    currency: string;
    service: {
      data: {
        id: string;
        type: string;
        attributes: {
          id: number;
          name: string;
          second_name: string;
          short_name: string;
          icon_id: number;
          active: boolean;
          online_order: boolean;
          order_number: number;
          icon: {
            data: {
              id: string;
              type: string;
              attributes: {
                id: number;
                image: string;
                name: string;
                second_name: string;
                image_type: string;
                name_translation: string;
              };
            };
          };
          name_translation: string;
        };
      };
    };
  };
}
interface CatalogueVariant {
  id: string;
  type: string;
  attributes: {
    id: number;
    pieces: number;
    active: boolean;
    created_at: string;
    updated_at: string;
    catalogue: {
      id: string;
      type: string;
      attributes: {
        id: number;
        product_name: string;
        product_second_name: string;
        name: string;
      };
    };
    name: string;
    image: {
      id: number;
      image: string;
    };
    measurement: {
      id: number;
      value: string;
    };
    product_currency_id: string;
    catalogue_variants_services: {
      data: CatalogueService[];
    };
  };
}
interface PreferenceListDropdown {
  id: string;
  type: string;
  attributes: {
    id: number;
    preference_first_name: string;
    icon_id: number;
    gallery_icon: {
      id: number;
      image: string;
      name_translation: string;
    };
    name: string;
  };
}
interface IDrag {
  id: string;
  type: string;
  attributes: {
  id: number;
  product_name: string;
  product_second_name: string;
  created_at: string;
  sections: [{
    id: number;
    name: string;
  }];
  services: [{
    id: number;
    price: string;
    sqm: string;
    sqm_price: string;
    weight: string;
    weight_price: string;
    name: string;
  }];
  name: string;
  catalogue_variants: [
    {
        "id": number,
        "price_list_id": null,
        "active": boolean,
        "measurement_type": string[]
    }
 ]
}
}
// Customizable Area End

export default class CfPriceList extends CfProductListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  checkDisabled(isActive:boolean) {
    if(isActive !== false){
      return 1
    }
    else{
      return 0.6
    }
  }

  checkSections(data: IListShowArray, rowId: string) {
    let sections: string[] = [];
    if (data?.length !== 0) {
      data?.map((item: IListShow, index: number) => {
        return sections.push(item?.name)
      })
      return sections.length > 4 ?
        <span data-test-id="sectionNames" style={{ position: 'relative' }} onMouseEnter={() => { this.handleSectionPopupOpen(rowId) }} onMouseLeave={() => { this.handleSectionPopupClose() }}>
          {sections.slice(0, 4).join(", ").substring(0 ,25)}...
          <div style={{ ...priceListWebStyle.sectionPopup, display: `${rowId === this.state.sectionPopupId ? "" : "none"}` }}>
            {sections.join(", ")}
          </div>
        </span> : sections.join(", ").substring(0 ,25)
    }
    else {
      return "--"
    }
  }

  handleServiceListShow (data: IServiceListArray, rowId: string, rowData: IDrag) {
    let serviceStringArray: string[] = [];
    if (data?.length !== 0) {
      data.forEach((item: IServiceList) => {
        let serviceString = "";
        serviceString = `${item.name} (${this.generateStrSARMeasue(item,rowData.attributes.catalogue_variants[0].measurement_type)})`;
        serviceStringArray.push(serviceString);
      });
      return serviceStringArray.length > 2 ?
        <span 
          data-test-id={`services-price-${rowId}`}
          onMouseEnter={() => { this.handleServicePopupOpen(rowId) }}
          onMouseLeave={() => { this.handleServicePopupClose() }}
        >
          {serviceStringArray.slice(0).join(", ").substring(0, 110)}...
          <div
            data-test-id="allServiceList"
            style={{ 
              ...priceListWebStyle.servicePopup, 
            display: `${rowId === this.state.servicePopupId ? "" : "none"}` 
          }}
          >
            {serviceStringArray.join(", ").substring(0 ,25)}
          </div>
        </span> : serviceStringArray.join(", ").substring(0)
    } else {
      return "---"
    }
  }

  emptyRows() {
    if((this.state.pageSize - this.state.dragItem?.length) > 0) {
      return <TableRow
      style={{
        height: 53 * (this.state.pageSize - this.state.dragItem?.length),
      }}
    >
      <TableCell colSpan={6} />
    </TableRow>
    }
  }

  checkActivation() {
    return <Box
      data-test-id='activateButton'
      sx={priceListWebStyle.popoverButton}
      onClick={() => {
        this.handleProductDeactivationModalOpen()
      }}
    >
       Deactivate
    </Box>
  }

  checkDeActivation() {
    return <Box
      data-test-id='activateButton'
      sx={priceListWebStyle.popoverButton}
      onClick={() => {
        this.handleConfirmProductActivateModalOpen()
      }}
    >
       Activate
    </Box>
  }

  getRenderVars() {
    const sortingOpen = Boolean(this.state.openSortingFilterAction);
    const productSortingId = sortingOpen ? "simple-popover" : undefined;
    const openPopoverAction = Boolean(this.state.openProductImpExtPopover);
    const popOverId = openPopoverAction ? "simple-popover" : undefined;
    const {
      snackbarSeverity,
      errorSnackbarOpen,
      errorMessage,
      permissionStatus,
      priceListPermissionStatus
    } = this.state
    return {
      sortingOpen,
      productSortingId,
      openPopoverAction,
      popOverId,
      snackbarSeverity,
      errorSnackbarOpen,
      errorMessage,
      permissionStatus,
      priceListPermissionStatus
    }
  }

  getExpandIcons() {
    const { openProductImpExtPopover } = this.state
    return <>
      {Strings.productList.exportimport} {openProductImpExtPopover ? <ExpandLess /> : <ExpandMore />}
    </>
  }
  getconfirmDeactivationModalOpen = () => {
    if(this.state.confirmDeactivationModalOpen){
    return (
      <ConfirmationModal
          data-test-id="confirmModal1"
          open={this.state.confirmDeactivationModalOpen}
          handleClose={() => { this.handleProductDeactivationModalClose() }}
          handleConfirm={() => { this.handleConfirmProductDeactivateModalOpen() }}
          title='Confirm Deactivate'
          message={{
            id: this.state?.getProductDataForDeactivate?.id,
            title: this.state?.getProductDataForDeactivate?.attributes?.product_name
          }}
          confirmText='Deactivate'
          dynamic={true}
        />      
    )  
    }
        
    
  }
  getproductDeactivatedModalOpen = () => {
    if(this.state.productDeactivatedModalOpen){
    return (
      <ConfirmationModal
              data-test-id="confirmModal2"
              open={this.state.productDeactivatedModalOpen}
              handleClose={() => { this.handleConfirmProductDeactivateModalClose() }}
              handleConfirm={() => { this.handleGoList() }}
              title={"Product Deactivated"}
              message={{
                id: this.state?.getProductDataForDeactivate?.id,
                title: this.state?.getProductDataForDeactivate?.attributes?.product_name
              }}
              confirmText={"Back to Listing"}
              displayItem={true}
            />
    )
  }
  }

  // Customizable Area End

  render() {
    // Customizable Area Start    
    const {
      sortingOpen,
      productSortingId,
      openPopoverAction, 
      popOverId, 
      snackbarSeverity, 
      errorSnackbarOpen, 
      errorMessage,
      permissionStatus,
      priceListPermissionStatus
    } = this.getRenderVars()    
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box>
      <>
          <Box style={{ ...priceListWebStyle.contentWrapper }} className="pageWrapper">
            <Box
              sx={{
                ...priceListWebStyle.headerWrapper,
                flexWrap: { xs: 'wrap', lg: 'Wrap' }
              }}
            >
              <StyledPageHeader>Products</StyledPageHeader>
              <div style={priceListWebStyle.buttons}>
                <StyledAlignment>
                  <CustomFormControl variant="outlined" style={{ ...priceListWebStyle.formControl}}>
                    <StyledAutocompleteSelect
                      fieldName="price_list"
                      handleScroll={this.handleScrollPricelistDropdown.bind(this)}
                      debouncedFunction={(getValue: string) => { 
                        this.handleAutoCompleteChange(getValue) 
                      }}
                      placeholder="Select price list"
                      onChange={(_: React.ChangeEvent<HTMLInputElement>, value: {id: number, option: string}) => {
                        if (value !== null) {
                          this.handleFilterByPriceList(value);
                        }
                      }}
                      options={this.state.dropdownOptions.priceList}
                      value={this.state.selectedPriceList}
                    />
                  </CustomFormControl>
                </StyledAlignment>
                <Box sx={{...priceListWebStyle.headerFilterButton, minWidth:"500px !important"}}>
                  {this.state.openSortingFilterAction !== null ?
                    <StyledFilterIcon style={priceListWebStyle.filterActive} data-test-id="filterIcon" aria-describedby={productSortingId}>
                      <img src={activeFilterIcon} style={priceListWebStyle.activeFilter} aria-describedby={productSortingId} alt='active filter icon' />
                      {renderBaseOnConditions(
                        this.isFilterApplied,
                        <Box className="filter-count"></Box>,
                        <></>
                      )}
                    </StyledFilterIcon>
                    :
                    <StyledFilterIcon
                      data-test-id='filterIcon'
                      aria-describedby={productSortingId}
                      onClick={(
                        event: React.MouseEvent<HTMLElement, MouseEvent>
                      ) => {
                        this.handleOpenFilter(event);
                      }}
                      className={sortStringCondition(this.isFilterApplied , "activeIcon", "")}
                    >
                      <img src={filterIcon} alt='filter icon' />
                      {renderBaseOnConditions(
                        this.isFilterApplied,
                        <Box className='filter-count'></Box>,
                        <></>
                      )}
                    </StyledFilterIcon>
                  }
            
                  <LoadingComponent
                    isLoading={this.state.isLoadingPermission}
                    height={44}
                    width={160}
                    disableParentWidth
                    isAllowed={this.state.permissionStatus.exportPermission}
                    children={
                      <StyledExportButton
                        data-test-id={configJSON.testIDExportEle}
                        id={"IconButton"}
                        aria-describedby={popOverId}
                        onClick={(event) => { this.setState({ openProductImpExtPopover: event.currentTarget }) }}
                        className="export-btn"
                      >
                        {this.getExpandIcons()}
                      </StyledExportButton>
                    }
                  />
                  <ExportImportPopover      
                    popOverId={popOverId}
                    openAction={openPopoverAction}
                    popoverOpen={this.state.openProductImpExtPopover}
                    popoverClose={() => this.handleCloseImpExpPopover()}
                    exportTemplateButtonClick={() => this.handleExportTemplate()}
                    importCsvButtonClick={() => this.handleOpenImportModal()}
                    exportCsvButtonClick={() => this.handleExportCsvFile()}
                  />

                  <LoadingComponent
                    isLoading={this.state.isLoadingPermission}
                    height={44}
                    width={160}
                    disableParentWidth
                    isAllowed={this.state.permissionStatus.createPermission}
                    children={
                      <StyledButton
                        data-test-id='pricelistBtn'
                        onClick={this.handlePriceModalOpen}
                        style={{ textTransform: "unset" }}
                      >
                        Add Price List
                      </StyledButton>
                    }
                  />

                  <LoadingComponent
                    isLoading={this.state.isLoadingPermission}
                    height={44}
                    width={160}
                    disableParentWidth
                    isAllowed={this.state.permissionStatus.createPermission}
                    children={
                      <StyledButton
                        data-test-id='addAccount'
                        onClick={this.handleAddUser}
                        style={{ textTransform: "unset" }}
                      >
                        Add Product
                      </StyledButton>
                    }
                  />
                    
                </Box>
              </div>
            </Box>
            <Box sx={priceListWebStyle.productTableContainer} className="pageWrapper">
            <TableContainer component={Paper} style={{overflow: "visible", flex: "1 1 0", overflowY: "auto"}}>
              <TableStyle>
              <DragDropContext onDragEnd={this.onDragEnd} data-test-id="tblDragDrop">
                <Droppable droppableId="droppable" >
                  {(provided: DroppableProvided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      <Table style={priceListWebStyle.table} aria-label='customized table'>
                        <TableHead className="stickyHeader">
                          <TableRow style={{ background: "#204B9C", color: "#FFFFFF", paddingTop: "5px", borderRadius: "5px" }}>
                            <TableCell
                              style={{
                                ...priceListWebStyle.tableHeader,
                                ...priceListWebStyle.actionHeader,
                                ...priceListWebStyle.tableHeaderLeft,
                                width: '5%'
                              }}
                              align='right'
                            />
                            <SortingTableHeader
                              minHeightType={true}
                              titleContainerClassName="headerTxt"
                              sortingData={this.state.sortingData}
                              title='Product Name'
                              sortingKey='name'
                              width="25%"
                              {...this.sortingProps}
                            />
                            <SortingTableHeader
                              minHeightType={true}
                              titleContainerClassName="headerTxt"
                              sortingData={this.state.sortingData}
                              title='Measurement Unit'
                              sortingKey='name'
                              width="30%"
                              {...this.sortingProps}
                            />
                            <SortingTableHeader
                              minHeightType={true}
                              titleContainerClassName="headerTxt"
                              sortingData={this.state.sortingData}
                              title='Service (Price Per Unit)'
                              sortingKey='service'
                              width="35%"
                              {...this.sortingProps}
                            />
                            <SortingTableHeader
                              minHeightType={true}
                              sortingDisabled
                              titleContainerClassName="headerTxt"
                              sortingData={this.state.sortingData}
                              title='Section'
                              sortingKey='sections'
                              width="30%"
                              {...this.sortingProps}
                            />
                            <TableCell
                              style={{
                                ...priceListWebStyle.tableHeader,
                                ...priceListWebStyle.actionHeader,
                                ...priceListWebStyle.tableHeaderRight,
                                width: '7%'
                              }}
                              align='right'
                            />
                          </TableRow>
                        </TableHead>
                          <TableBody data-test-id="tblBody">
                          {this.state.isLoading && <>{Array(10)
                      .fill(1)
                      .map((_, index) => (
                      
                          <TableRow className="tableRow" data-test-id={index}>
                          {[...Array(1)].map((_, cellIndex) => (
                             <TableRowsSkeleton key={cellIndex} />
                            ))}

                           
                          </TableRow>
                        
                      ))}</> }
                            {!this.state.isLoading && this.state.dragItem?.length !== 0 ? this.state.dragItem?.map((rowData: IDrag, index: number) => (
                              <Draggable
                                key={rowData.id}
                                draggableId={rowData.id}
                                index={index}
                                isDragDisabled={Boolean(this.state.isDragableDisabled)}
                              >
                                {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                                  <TableRow
                                    data-test-id={"tableTR"}
                                    key={rowData.id}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}

                                  >
                                    <TableCell
                                      style={{ ...priceListWebStyle.actionCellContainer, width: "5%" }}
                                      align='right'
                                      key={rowData.id}
                                      data-test-id={`rowDraggable-${index}`}
                                      {...provided.dragHandleProps}
                                    >
                                        <StyledIconButton>
                                          <DragHandleIcon />
                                        </StyledIconButton>
                                    </TableCell>

                                    <TableCell data-test-id={"tableTD"} 
                                    style={{ ...priceListWebStyle.cellContainer, width: "25%", 
                                    opacity: this.checkDisabled(rowData?.attributes?.catalogue_variants[0]?.active)                                  }} 
                                    scope='row'>
                                      <StyledTableCell className="border-left">
                                        {rowData?.attributes?.product_name}
                                      </StyledTableCell>
                                    </TableCell>

                                    <TableCell
                                      style={{
                                        ...priceListWebStyle.cellContainer,
                                        width: "30%",
                                        opacity: this.checkDisabled(rowData?.attributes?.catalogue_variants[0]?.active)
                                      }}
                                      align='left'
                                    >
                                      <StyledTableCell
                                        data-test-id={`measurementUnit-${rowData?.id}`}
                                        onMouseEnter={() => { this.hoverToGetServiceRowId(rowData?.id) }}
                                        onMouseLeave={() => { this.removeServiceRowId(0) }}
                                      >
                                        {
                                          rowData?.attributes?.catalogue_variants[0].measurement_type.map(item => item).join(',') || "---"
                                        }
                                      </StyledTableCell>
                                    </TableCell>

                                    <TableCell
                                      style={{
                                        ...priceListWebStyle.cellContainer,
                                        width: "35%",
                                        opacity: this.checkDisabled(rowData?.attributes?.catalogue_variants[0]?.active)
                                      }}
                                      align='left'
                                    >
                                      <StyledTableCell
                                        style={{padding:"0 5px"}}
                                        data-test-id={`service-${rowData?.id}`}
                                        onMouseEnter={() => { this.hoverToGetServiceRowId(rowData?.id) }}
                                        onMouseLeave={() => { this.removeServiceRowId(0) }}
                                      >
                                        {this.handleServiceListShow(rowData.attributes.services, rowData?.id,rowData)}
                                      </StyledTableCell>
                                    </TableCell>
                                    <TableCell
                                      data-test-id={`section-${rowData?.id}`}
                                      style={{
                                        ...priceListWebStyle.cellContainer, width: "30%",
                                        opacity: this.checkDisabled(rowData?.attributes?.catalogue_variants[0]?.active)
                                      }}
                                      align='left'
                                      onMouseEnter={() => { this.hoverToGetRowId(rowData?.id) }}
                                      onMouseLeave={() => { this.removeRowId(0) }}
                                    >
                                      <StyledTableCell className="border-right">
                                        {this.checkSections(rowData?.attributes?.sections, rowData?.id)}
                                      </StyledTableCell>
                                    </TableCell>
                                    <TableCell style={{ ...priceListWebStyle.actionCellContainer, width: "5%" }} align='right'>
                                    <OptionsWrapper>
                                        <IconButton 
                                          data-test-id={`moreButton-${rowData?.id}`}
                                          id={"IconButton" + index}
                                          aria-describedby={popOverId}
                                          onClick={(event) => { this.setState({ openAction: event.currentTarget }); this.setState({ viewProfileId: rowData?.id, getProductDataForDeactivate: rowData }) }}
                                        >
                                         <MoreVertIcon />
                                        </IconButton>
                                     </OptionsWrapper>
                                    </TableCell>
                                                                      </TableRow>
                                )}
                              </Draggable>
                            )) :
                              <TableRow>
                                <TableCell style={priceListWebStyle.nodataCellContainer} align='left' colSpan={5}>
                                  <div style={{ ...priceListWebStyle.noDataCell, textAlign: 'center' }}>
                                    No product were found
                                  </div>
                                </TableCell>
                              </TableRow>
                            }
                            {provided.placeholder}
                            {this.state.isLoading && this.emptyRows()}
                          </TableBody>
                      </Table>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              </TableStyle>
            </TableContainer>
            </Box>
            <TablePagination
              data-test-id='pagination'
              rowsPerPageOptions={[100]}
              component='div'
              count={this.state.productPagination?.total_count}
              rowsPerPage={this.state.pageSize}
              page={this.state.page}
              onPageChange={(event, page) => { this.handlePageChange(page) }}
              style={{overflow: 'visible'}}
            />
            {this.getconfirmDeactivationModalOpen()}
            {this.getproductDeactivatedModalOpen()}
            
            <CustomPopover
              id={popOverId}
              data-test-id="actionPopup"
              open={Boolean(this.state.openAction)}
              anchorEl={this.state.openAction}
              onClose={() => this.handleCloseActionList()}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {
                renderBaseOnConditions(
                  (permissionStatus.viewPermission ||
                    permissionStatus.editPermission ||
                    permissionStatus.deactivatePermission ||
                    permissionStatus.activatePermission as boolean
                  ),
                    <div style={priceListWebStyle.popoverContainer}>
                    {
                      renderBaseOnConditions(
                        permissionStatus.viewPermission,
                        <Box
                          data-test-id='viewButton'
                          onClick={() => this.handleViewProductDetails()}
                          sx={priceListWebStyle.popoverButton}
                        >
                          View
                        </Box>,
                        <></>
                      )
                    }
                    {
                      renderBaseOnConditions(
                        this.state.permissionStatus.editPermission,
                        <Box
                          data-test-id='editButton'
                          onClick={() => this.handleEditProductDetails(this.state.getProductDataForDeactivate.id)}
                          sx={priceListWebStyle.popoverButton}
                        >
                          Edit
                        </Box>,
                        <></>
                      )
                    }
                    {
                      renderBaseOnConditions(
                        this.state.permissionStatus.deactivatePermission &&
                        this.state.getProductDataForDeactivate?.attributes?.catalogue_variants[0]?.active,
                        this.checkActivation(),
                        <>{!permissionStatus.viewPermission &&
                          !permissionStatus.editPermission &&
                          permissionStatus.activatePermission &&
                          this.state.getProductDataForDeactivate?.attributes?.catalogue_variants[0]?.active
                        && !permissionStatus.deactivatePermission && <PopoverContainer1>
                        <div className="noPermissions">
                          No Permissions
                        </div>
                      </PopoverContainer1>}
                      </>
                      )
                    }
                    {
                      renderBaseOnConditions(
                        this.state.permissionStatus.activatePermission as boolean &&
                        !this.state.getProductDataForDeactivate?.attributes?.catalogue_variants[0]?.active,
                        this.checkDeActivation(),
                        <>{!permissionStatus.viewPermission &&
                          !permissionStatus.editPermission &&
                          !permissionStatus.activatePermission &&
                          !this.state.getProductDataForDeactivate?.attributes?.catalogue_variants[0]?.active
                        && permissionStatus.deactivatePermission && <PopoverContainer1>
                        <div className="noPermissions">
                          No Permissions
                        </div>
                      </PopoverContainer1>}
                      </>
                      )
                    }
                  </div>,
                  <div style={priceListWebStyle.popoverContainer}>
                    <div style={priceListWebStyle.noPermissions}>
                      No Permissions
                    </div>
                  </div>
                )
              }
            </CustomPopover>
            {sortingOpen && <ProductListingFilters
              data-test-id="productFilter"
              productSortingId={productSortingId}
              sortingOpen={sortingOpen}
              sortingClose={(closeValue: boolean) => { this.handleFilterPopupClose(closeValue) }}
              anchor={this.state.openSortingFilterAction}
              selectedFilter={this.state.selectedFilter}
              handleSelectedFilter={(value: number, nameValue: string) => this.handleFilterChange(value, nameValue)}
              priceRangeValue={this.state.priceRangeValue}
              handleApplyFilter={this.handleApplyFilterChange}
              subFilterMenuList={this.state.dropdownOptions}
              handleClearFitler={() => this.handleClearFitler()}
              nameFilter={this.state.nameFilter}
              setName={this.setName}
              priceFilter={this.state.priceFilter}
              setPrice={this.setPrice}
              sectionFilter={this.state.sectionFilter}
              setSection={this.setSection}
              productTypeFilter={this.state.productTypeFilter}
              setProductType={this.setProductType}
              preferenceFilter={this.state.preferenceFilter}
              setPreference={this.setPreference}
              optionsList={this.state.optionsList}
            />}
            <AddPriceListModal
              open={this.state.openAddPriceListModal}
              onClose={this.closeAddPriceListModal}
              navigation={undefined}
              id={""}
              classes={undefined}
            />  
          </Box>
      </>
      <CustomSnackbar          
        open={errorSnackbarOpen}
        errorMessage={errorMessage}
        severity={snackbarSeverity}
        onClose={this.handleSnackbarClose.bind(this)}
      />
    </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const priceListWebStyle:any = {
  table: {
    tableLayout: "fixed",
    // minWidth: 700,
    // width: 'calc(100% - 74px)'
  },
  filter: {
    minWidth: '56px',
    height: '56px',
    border: '1px solid #ececec',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px',
    cursor: 'pointer'
  },
  filterActive: {
    minWidth: '56px',
    height: '56px',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px',
    cursor: 'pointer'
  },
  activeFilter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  mainWrapper: {
    marginTop: "32px",
    fontFamily: "Montserrat",
  },
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    margin: '30px 0px' ,
    alignItems: "center",
  },
  addButton: {
    background: "#204B9C",
    fontWeight: 600,
    fontFamily: 'Montserrat',
    fontSize: "13px",
    lineHeight: "24px",
    color: "#FFFFFF",
    height: "44px",
    width: "160px",
    borderRadius: "8px",
    margin: '5px'
  },
  contentWrapper: {
    // marginRight: 32,
    // marginTop: 56,
  },
  mainContentWrapper: {
    "@media (max-width: 1000px)": {
      width: 'calc(100% - 150px)'
    }
  },
  tableHeader: {
    background: "#204B9C",
    color: "FFFFFF",
    width: "31%",
    fontSize: "18px",
    fontWeight: 600,
  },
  tableHeaderLeft: {
    borderTopLeftRadius: "12px",
  },
  tableHeaderRight: {
    borderTopRightRadius: "12px",
  },
  actionHeader: {
    width: "10px",
  },
  noDataCell: {
    fontWeight: 600,
    fontSize: "16px",
    width: "98%",
    paddingTop: "20px",
    paddingBottom: "20px",
    border: "1px solid #ECECEC",
    margin: "12px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    borderRadius: '8px'
  },
  cellContainer: {
    padding: 0,
    border: "hidden",
    width: '33%',
    height: '100%'
  },
  nodataCellContainer: {
    padding: "0px",
    border: "hidden",
    width: '100%'
  },
  actionCellContainer: {
    border: "hidden",
    paddingTop: "4px",
    paddingBottom: "4px",
    margin: "auto"
  },
  middleHeaderCell: {
    paddingLeft: "0px",
  },
  popoverButton: {
    cursor: "pointer",
    width: "118px",
    height: "33px",
    paddingLeft: "24px",
    backgroundColor: "#FFFFFF",
    color: "#4D4D4D",
    fontSize: '14px',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    "&:hover": {
      backgroundColor: "#204B9C",
      color: "#FFFFFF",
    },
  },
  popoverContainer: {
    paddingTop: "16px",
    paddingBottom: "16px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    borderRadius: "8px",
    fontSize: '12px',
  },
  noPermissions: {
    padding: "0 8px"
  },
  sortingPopoverContainer: {
    paddingTop: "16px",
    paddingBottom: "16px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    borderRadius: "8px",
  },
  formControl: {
    width: 'auto',
    marginTop: 0,
    minWidth: '250px',
    border: '1px solid #ECECEC',
    borderRadius: '8px'
  },
  sortingStyle1: {
    cursor: 'pointer'
  },
  sortingStyle2: {
    cursor: 'pointer',
    transform: 'scaleX(-1)'
  },
  sectionPopup: {
    position: 'absolute',
    backgroundColor: '#fff',
    borderRadius: '8px',
    padding: '6px',
    right: "-100px",
    top: '25px',
    width: '400px',
    height: 'auto',
    boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.3), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)',
    whiteSpace: 'break-spaces',
    zIndex: 1
  },
  servicePopup: {
    position: 'absolute',
    backgroundColor: '#fff',
    borderRadius: '8px',
    padding: '10px',
    right: "0",
    left: "0",
    top: '25px',
    height: 'auto',
    boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.3), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)',
    whiteSpace: 'break-spaces',
    zIndex: 1
  },
  radioIcon: {
    display: "inline-block",
    borderRadius: "51%",
    width: 20,
    height: 20,
    border: "1px solid #64748C",
  },
  fieldError: {
    color: "red",
    fontSize: "14px",
    margin: "5px 5px 5px 0",
    fontFamily: 'Montserrat'
  },
  productDetails: {
    marginTop: 5,
    display: 'flex',
    gap: 10,
    "@media (max-width: 600px)": {
      flexWrap: 'wrap'
    }
  },

  radioCheckedIcon: {
    display: "inline-block",
    width: 20,
    borderRadius: "51%",
    height: 20,
    border: "6px solid #204B9D",
    color: "#204b9c",
  },
  headerFilterButton: {
    marginTop: '5px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: { xs: 'end', lg: 'unset' },
    alignItems: 'center',
    width: { xs: 'calc(100% - 74px)', lg: 'unset' }
  },
  productTableContainer: {
    // width: {xs: 'calc(100% - 74px)', lg: 'unset'}
  },
  buttons: {
    display: "flex", 
    justifyContent: "space-between", 
    alignItems: "center", 
    width: "100%"
  },
  alignment: { 
    minWidth: '368px',
    marginTop: '5px', 
    display: 'flex',
    alignItems: 'center' 
    }
};

export const StyledTableCell = styled("div")({
  display:"flex",
  alignItems:"center",
  height:"90px",
  fontSize: "16px",
  fontWeight: 600,
  fontFamily: 'Montserrat',
  color: 'var(--Tertiary, #1A1A1A)',
  width: "100%",
  paddingTop: "20px",
  paddingBottom: "20px",
  borderTop: "1px solid #ECECEC",
  borderBottom: "1px solid #ECECEC",
  marginTop: "12px",
  marginBottom: "12px",
  textOverflow: "ellipsis",
  paddingLeft: "10px",
  "&.border-left": {
    borderLeft: "1px solid #ECECEC",
    borderRadius: "12px 0 0 12px"
  },
  "&.border-right": {
    borderRight: "1px solid #ECECEC",
    borderRadius: "0 12px 12px 0"
  },
  "@media only screen and (max-width: 1024px)": {
    fontSize: "12px",
    fontWeight: 400,
  },
  "& .iconOptions":{
    border:"1px solid red"
  }
  })

const StyledAlignment = styled("div")({
  minWidth: '250px',
  marginTop: '5px', 
  display: 'flex',
  alignItems: 'center',
  "@media only screen and (max-width: 1024px)": {
    minWidth: '250px',
  }
})

const StyledFilterIcon = styled(Box)({
  position: "relative",
  minWidth: '56px',
  height: '56px',
  border: '1px solid #ececec',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '10px',
  cursor: 'pointer',
  "&.activeIcon":{
    border: '1px solid #204B9C',
  },
  "& .filter-count": {
    position: "absolute",
    top: "-5px",
    right: "-5px",
    minHeight: "15px",
    minWidth: "15px",
    background: "#204B9C",
    borderRadius: "50%",
    color: "#ffffff"
  }
})

export const StyledPageHeader = styled("div")({
  marginTop: '5px',
  fontStyle: "normal",
  fontFamily: 'Montserrat',
  fontWeight: 600,
  fontSize: "24px",
  lineHeight: "29px",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "16px"
  },
})

export const TableStyle = styled("div")({
  "& .MuiTableRow-root": {
    display: "flex",
  },
  "& .MuiTableRow-root.tableRow": {
    display: "table-row",
  },
  "& .headerTxt":{
    paddingLeft:"15px"
  }
})

export const StyledIconButton = styled(IconButton)({
  display:"flex",
  justifyContent:"center",
  alignItems:"center",
  padding: 0,
  "@media only screen and (max-width: 1024px)": {
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    width:"15px",
   },
})

const StyledBox = styled(Box)({
  margin: '0 10px',
  "@media only screen and (max-width: 1024px)": {
    margin: '0 2px'
  },
});


const StyledExportButton = styled(Button)({
  fontSize: "16px",
  fontWeight: 600,
  maxWidth: "184px",
  width: "184px",
  height: "56px",
  borderRadius: "8px",
  lineHeight: "24px",
  margin: '5px',
  textTransform: "unset",
  background: "#204B9C",
  color: "#FFFFFF",
  "&:hover": {
    background: "#204B9C !important",
    color: "#FFFFFF !important",
  },
  "&.Mui-disabled": {
    backgroundColor: "rgba(32, 75, 156, 0.5) !important",
    color: "#FFFFFF",
    opacity: 0.7
  },
  "@media (max-width: 1024px)": {
    width: '150px',
    height: '44px',
    fontSize: "13px",
    padding:"10px 0",
    margin: '2px',
  },
});

const StyledButton = styled(Button)({
  fontSize: "16px",
  fontWeight: 600,
  width: "184px",
  height: "56px",
  borderRadius: "8px",
  lineHeight: "24px",
  margin: '5px',
  textTransform: "unset",
  background: "#204B9C",
  color: "#FFFFFF",
  "&:hover": {
    background: "#204B9C !important",
    color: "#FFFFFF !important",
  },
  "&.Mui-disabled": {
    backgroundColor: "rgba(32, 75, 156, 0.5) !important",
    color: "#FFFFFF",
    opacity: 0.7
  },
  "@media (max-width: 1024px)": {
    width: '128px',
    fontSize: "13px",
    height: '44px',
    padding:"10px 0",
    margin: '2px',
  },
});

export const OptionsWrapper= styled("div")({
  "@media only screen and (max-width: 1024px)": {
   display:"flex",
   justifyContent:"center",
   alignItems:"center",
   width:"25px",
  },
})

const StyledAutocompleteSelect = styled(AutocompleteSelect)({
  "& .MuiInputBase-input":{
    fontWeight: 500,
    fontFamily: 'Montserrat',
    fontSize: "16px",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "13px"
     },
  }
})


const PopoverContainer1 = styled("div")({
  borderRadius: "8px",
  fontFamily: "Montserrat",
  fontWeight: 500,
  fontSize: "12px",
  "& .noPermissions": {
    padding: "0 8px",
  }
})
// Customizable Area End
