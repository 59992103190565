// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  IconButton,
  styled,
  TablePagination,
  Popover,
  Typography,
  Divider,
} from "@material-ui/core";
import {
  renderBaseOnConditions,
  sortStringCondition,
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import {
  FilterList as FilterIcon,
  MoreVert as MoreVertIcon,
} from "@material-ui/icons";
import B2BCustomerListController, {
  IB2BCompany,
  ISubCompantAttribute,
  Props,
} from "./B2BCompanytListController.web";
import { colors } from "../../utilities/src/Colors";
import { TableRowsSkeleton } from "../../../components/src/customComponents/TableRowsSkeleton.web";
import {
  TableRow,
  TableCell,
  OrderTable,
  RowContainer,
} from "../../../components/src/OrderTable/src";
import BusinessAccountModal from "./BusinessAccountsModal.web";
const IconSortingUp = require("../../../components/src/assets/sort/sort top.png");
const IconSortingNormal = require("../../../components/src/assets/sort/sort.png");
const IconSortingDown = require("../../../components/src/assets/sort/sort down.png");
import {
  FilterBox,
} from "../../../components/src/customComponents/CustomTags.web";
import FilterPopover from "../../../components/src/FilterPopover";

// Customizable Area End

export class B2BCompanyList extends B2BCustomerListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderPaginationForSubCompnay = (customerList: ISubCompantAttribute[]) => {
    if (customerList.length > 0) {
      return (
        <TablePagination
          data-test-id='subCompanyListPagination'
          rowsPerPageOptions={[10]}
          component='div'
          count={this.state.subCompanyListPagination?.total_count}
          rowsPerPage={this.state.subPageSize}
          page={this.state.subPage}
          onPageChange={(event, page) => {
            this.handleSubPageChange(page);
          }}
        />
      );
    }
  };
  

  renderPagination = (customerList: IB2BCompany[]) => {
    if (customerList.length > 0) {
      return (
        <TablePagination
          data-test-id='companyListPagination'
          rowsPerPageOptions={[10]}
          component='div'
          count={this.state.companyListPagination?.total_count}
          rowsPerPage={this.state.pageSize}
          page={this.state.page}
          onPageChange={(event, page) => {
            this.handlePageChange(page);
          }}
        />
      );
    }
  };

  getPopupOverMenu() {
    const { popOverTop, popOverLeft, popOverOpened } = this.state;
    if (this.state.popOverOpened) {
      return (
        <StylePopover
          data-test-id='popoverTest'
          style={{ boxShadow: "none" }}
          anchorReference='anchorPosition'
          open={popOverOpened}
          onClose={() => {
            this.handlePopoverMenuClose();
          }}
          anchorPosition={{
            top: popOverTop + 40,
            left: popOverLeft - 80,
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div className='popoverContainer'>
            <Box
              onClick={() => this.handleViewCompany(this.state.popOverItemId)}
              data-test-id='viewButton'
              className='popoverButton'
            >
              View
            </Box>
            <Box
              onClick={() => this.handleEditCompany(this.state.popOverItemId)}
              data-test-id='editButton'
              className='popoverButton'
            >
              Edit
            </Box>
            <Box
              onClick={() =>
                this.handleOpenBusinessAccModal(this.state.popOverItemId)
              }
              data-test-id='businessAccountButton'
              className='popoverButton'
            >
              Business Accounts
            </Box>
          </div>
        </StylePopover>
      );
    }
  }

  renderHeader = () => {
    return (
      <TableRow columns={"1.5fr 1fr 1.5fr 1.5fr 1.5fr 0.5fr"}>
        {[
          {
            title: "B2B Company Name",
            sortingKey: "name",
          },
          {
            title: "Regn. No.",
            sortingKey: "registration_no",
          },
          {
            title: "Email ID",
            sortingKey: "email",
          },
          {
            title: "Telephone",
            sortingKey: "telephone",
          },
          {
            title: "Country",
            sortingKey: "country_name",
          },
          {
            title: "",
            sortingKey: "",
          },
        ]
          .filter((item: { title: string }) => {
            return item.title;
          })
          .map(
            ({ title, sortingKey }: { title: string; sortingKey?: string }) => (
              <TableCell
                header
                key={title}
                content={title}
                className={"f-16 align-item-center"}
                sortingKey={sortingKey}
                sortingIcons={[
                  IconSortingNormal,
                  IconSortingUp,
                  IconSortingDown,
                ]}
                sortingDisabled={!Boolean(sortingKey)}
                {...this.getSortingProps()}
              />
            )
          )}
      </TableRow>
    );
  };

  subTableRender = (data: IB2BCompany) => {
    const isExpanded = !!this.state.expandedRows[data.id];
    const subRows = isExpanded ? this.state.expandedRows[data.id] : [];
    return (
      <>
        {isExpanded && (
          <Box style={{ padding: "10px" }}>
            <StyleSubTypography>Sub Companies</StyleSubTypography>

            {renderBaseOnConditions(
              !this.state.isSubCompanyListLoading,

              renderBaseOnConditions(
                (subRows as ISubCompantAttribute[])?.length > 0,
                (subRows as ISubCompantAttribute[]).map((subRow: any) => (
                  <>
                    <TableRow
                      data-test-id='subtableData'
                      style={{ alignItems: "center",marginBottom:0 }}
                      className='sub-row'
                      columns='1.5fr 1fr 1.5fr 1.5fr 1.5fr 0.5fr'
                    >
                      {[
                        this.handleTableNullValue(subRow.attributes.name),
                        this.handleTableNullValue(
                          subRow.attributes.registration_no
                        ),
                        this.handleTableNullValue(subRow.attributes.email),
                        this.handleTableNullValue(subRow.attributes.telephone),
                        this.handleTableNullValue(
                          subRow.attributes.country.name
                        ),

                        <IconButton
                          data-test-id={`submoreButton_${subRow.id}`}
                          id={"IconButton" + subRow.id}
                          onClick={(event) => {
                            event.stopPropagation();
                            const position =
                              event.currentTarget.getBoundingClientRect();
                            this.handleMoreMenu(subRow, position);
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>,
                      ].map((item, index) => (
                        <StyleDivSub>{item}</StyleDivSub>
                      ))}
                    </TableRow>
                    <Divider style={{ marginBottom: "25px" }} />
                  </>
                )),
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontWeight: 600,
                    fontSize: 16,
                  }}
                >
                  No records found!!
                </div>
              ),

              <>
                {Array(1)
                  .fill(1)
                  .map((_, index) => (
                    <RowContainer>
                      <TableRow
                        key={index}
                        className='table-rows'
                        columns='1fr 1fr 1fr 1fr 1fr 1fr'
                      >
                        {[...Array(6)].map((_, cellIndex) => (
                          <TableRowsSkeleton key={cellIndex} />
                        ))}
                      </TableRow>
                    </RowContainer>
                  ))}
              </>
            )}
          </Box>
        )}
        {this.renderPaginationForSubCompnay(subRows as ISubCompantAttribute[])}
      </>
    );
  };

  renderTabelBody = () => {
    return (
      <Box
        className={"table-container"}
        data-test-id={"table-container-scrolldiv"}
      >
        <OrderTable>
          <RowContainer header>{this.renderHeader()}</RowContainer>
          {renderBaseOnConditions(
            this.state.isCompanyListLoading,
            <RowContainer>
              <>
                {Array(5)
                  .fill(1)
                  .map((_, index) => (
                    <RowContainer>
                      <TableRow
                        key={index}
                        className='table-rows'
                        columns='1fr 1fr 1fr 1fr 1fr 1fr'
                      >
                        {[...Array(6)].map((_, cellIndex) => (
                          <TableRowsSkeleton key={cellIndex} />
                        ))}
                      </TableRow>
                    </RowContainer>
                  ))}
              </>
            </RowContainer>,
            <>
              {renderBaseOnConditions(
                this.state.companies.length > 0,
                <RowContainer>
                  {this.state.companies.map((data) => {
                    const isExpanded = !!this.state.expandedRows[data.id];
                    return (
                      <div
                        style={
                          isExpanded
                            ? {
                                padding: 10,
                                boxShadow:
                                  "rgba(0, 0, 0, 0.09) 0px 4px 8px, rgba(0, 0, 0, 0.09) 0px 8px 32px",
                                borderRadius: "12px",
                                marginBottom: "15px",
                              }
                            : {}
                        }
                      >
                        <TableRow
                          style={{cursor: "pointer"}}
                          key={data.id}
                          className='table-rows'
                          data-test-id='tableData'
                          columns='1.5fr 1fr 1.5fr 1.5fr 1.5fr 0.5fr'
                          onClick={() => this.toggleRow(data.id)}
                        >
                          {[
                            this.handleTableNullValue(data.attributes.name),
                            this.handleTableNullValue(
                              data.attributes.registration_no
                            ),
                            this.handleTableNullValue(data.attributes.email),
                            this.handleTableNullValue(
                              data.attributes.telephone
                            ),
                            this.handleTableNullValue(
                              data.attributes.country.name
                            ),
                            <IconButton
                              style={{padding: "2px"}}
                              data-test-id={`moreButton_${data.id}`}
                              id={"IconButton" + data.id}
                              onClick={(event) => {
                                event.stopPropagation();
                                const position =
                                  event.currentTarget.getBoundingClientRect();
                                this.handleMoreMenu(data, position);
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>,
                          ].map((item, index) => (
                            <TableCell
                              key={`cell-${index}`}
                              style={{
                                color: "#1A1A1A",
                                wordBreak: "break-all",
                                backgroundColor: isExpanded ? "#F1F5F9" : "",
                              }}
                              content={item as React.ReactNode}
                              className='f-12'
                              type={this.getTypeOfOrderTable(index)}
                            />
                          ))}
                        </TableRow>

                        {this.subTableRender(data)}
                      </div>
                    );
                  })}
                </RowContainer>,
                <RowContainer>
                  <Box className={"w-100"}>
                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      No records found!!
                    </div>
                  </Box>
                </RowContainer>
              )}
            </>
          )}
        </OrderTable>
      </Box>
    );
  };

  handleTableNullValue = (value: string | number | null) => {
    if (value) {
      return <span>{value}</span>;
    } else {
      return <>---</>;
    }
  };

  renderFilter = () => {
    return (
      <Box className={"topCustomerRightBox"}>
        <FilterBox
          data-test-id={"filterBtn"}
          className={sortStringCondition(
            this.state.isAppliedFilter,
            "active-border",
            ""
          )}
          onClick={(event) => {
            this.onFilterClickHandler(event);
          }}
        >
          <FilterIcon color={this.handleReturnColorType()} />
          {renderBaseOnConditions(
            this.state.isAppliedFilter,
            <div className={"filterActiveIcon"} />,
            <></>
          )}
        </FilterBox>
        <FilterPopover
          onClose={this.handleClosePopOver}
          anchor={this.state.filterAnchor as HTMLDivElement}
          onFilterChange={this.handleFilterChange}
          onAutoCompleteChange={this.debouncedCustomerGroupSuggestion}
          filters={this.state.filters}
        />
    </Box>
    )
  }

  render() {
    return (
      <>
        <MainStyleWrapper>
          <Box className="pageWrapper">
            <Box>
              <div className={"headerWrapper"}>
                <div className={"pageHeader"}>B2B Company</div>
                <div className={"headerButtonPartAccount"}>
                  {this.renderFilter()}
                  <Button
                    data-test-id='add-customer'
                    onClick={() => this.handleAddCompany()}
                    className={"addButton"}
                  >
                    Add Company
                  </Button>
                </div>
              </div>
            </Box>
            <Box style={{flex: "1 1 0", overflowY: "auto"}}>{this.renderTabelBody()}</Box>
            <Box>{this.renderPagination(this.state.companies)}</Box>
          </Box>
          {this.getPopupOverMenu()}
          {this.state.isBusAccModalOpen && (
            <BusinessAccountModal
              navigation=''
              id=''
              isModalOpen={this.state.isBusAccModalOpen}
              onCloseModal={this.closeBusinessAccModal}
              companyId={this.state.companyId}
            />
          )}
        </MainStyleWrapper>
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start

const MainStyleWrapper = styled(Box)({
  fontFamily: "Montserrat",
  "& .filterButtonAccount": {
    position: "relative",
    marginRight: "24px",
    cursor: "pointer",
    background: "#FFFFFF",
    height: "56px",
    color: "#4d4d4d",
    width: "56px",
    fontSize: "24px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #ECECEC",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
      width: "44px",
      height: "44px",
      padding: "9px",
    },
  },
  "& .active-border": {
    border: "1px solid #204B9C",
  },
  "& .filterActiveIcon": {
    position: "absolute",
    top: "-5px",
    right: "-5px",
    minWidth: "15px",
    background: "#204B9C",
    minHeight: "15px",
    borderRadius: "50%",
  },
  "& .headerWrapper": {
    display: "flex",
    flexWrap: "wrap" as "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 32,
    marginTop: 25,
    flexwrap: "wrap" as "wrap",
  },
  "& .pageHeader": {
    fontSize: "24px",
    fontWeight: 600,
    fontStyle: "normal",
    lineHeight: "29px",
    color: colors().black,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
      fontWeight: 600,
      color: colors().black,
    },
  },
  "& .headerButtonPartAccount": {
    display: "flex",
    gap: "20px"
  },
  "& .table": {
    minWidth: 700,
  },
  "& .loadercell": {
    textAlign: "center",
  },
  "& .loadercir": {
    width: "44px",
    height: "none",
  },
  "& .customerAddButton": {
    textAlign: "center",
  },
  "& .noDataCell": {
    fontWeight: 600,
    fontSize: "16px",
    width: "98%",
    paddingTop: "20px",
    paddingBottom: "20px",
    border: "1px solid #ECECEC",
    margin: "12px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    borderRadius: "8px",
  },
  "& .customerTableRow": {
    background: "#204B9C",
    color: "#FFFFFF",
  },
  "& .addButton": {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    background: "#204B9C",
    color: "#FFFFFF",
    width: "184px",
    height: "56px",
    borderRadius: "8px",
    textTransform: "unset" as "unset",
    "&:hover": {
      background: "#204B9C",
      color: "#FFFFFF",
    },
    "@media only screen and (max-width: 1024px)": {
      fontSize: "13px",
      width: "160px",
      height: "44px",
      padding: "10px 20px",
    },
  },
});

const StylePopover = styled(Popover)({
  "& .popoverButton": {
    width: "200px",
    height: "33px",
    paddingTop: "8px",
    paddingLeft: "24px",
    backgroundColor: "#FFFFFF",
    color: "#4D4D4D",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#204B9C",
      color: "#FFFFFF",
    },
  },
  "& .popoverContainer": {
    paddingTop: "16px",
    paddingBottom: "16px",
    boxShadow:
      "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    borderRadius: "8px",
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: "Montserrat",
  },
});

const StyleSubTypography = styled(Typography)({
  fontSize: "16px",
  fontWeight: 600,
  fontFamily: "Montserrat",
  color: "#204B9C",
  marginBottom: "30px",
  "@media only screen and (max-width: 1024px)": {
    fontSize: 13,
    fontWeight: 600,
  },
});

const StyleDivSub = styled("div")({
  fontSize: "14px",
  fontWeight: 400,
  fontFamily: "Montserrat",
  "@media only screen and (max-width: 1280px)": {
    fontSize: 12,
    fontWeight: 400,
    wordBreak: "break-all",
    width: "140px"
  },
  "@media only screen and (max-width: 1024px)": {
    fontSize: 12,
    fontWeight: 400,
    wordBreak: "break-all",
    width: "100px"
  },
});

export default B2BCompanyList;
// Customizable Area End
