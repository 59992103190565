Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfsalesdashboard3";
exports.labelBodyText = "cfsalesdashboard3 Body";

exports.btnExampleTitle = "CLICK ME";

//api
exports.sectionSaleEndPoint = "bx_block_cfsalesdashboard3/section_sales_dashboards";
exports.salesEndPoint = "bx_block_dashboard/sales_dashboards"
//section-sale-endppoint
exports.saleByServiceEndPoint ="bx_block_cfsalesdashboard3/section_sales_dashboards/service_wise_orders"
exports.saleBySectionEndPoint ="bx_block_cfsalesdashboard3/section_sales_dashboards/section_wise_orders"
exports.serviceWiseSectionLaundry = "bx_block_cfsalesdashboard3/section_sales_dashboards/service_wise_section_orders?service_name=laundry"
exports.serviceWiseSectionDryClealing = "bx_block_cfsalesdashboard3/section_sales_dashboards/service_wise_section_orders?service_name=Dry Cleaning"
exports.serviceWiseSectionPressing = "bx_block_cfsalesdashboard3/section_sales_dashboards/service_wise_section_orders?service_name=pressing"
exports.saleSumaryEndPoint = "bx_block_cfsalesdashboard3/section_sales_dashboards/section_sales_summary"
exports.vatSubtotalEndPoint = "bx_block_cfsalesdashboard3/section_sales_dashboards/section_vat_and_subtotal"

//sale enppoint
exports.saleOrdersWiseEndPoint = "bx_block_cfsalesdashboard3/sales_dashboards/date_wise_orders_data"
exports.clothesOrderEndpoint = "bx_block_cfsalesdashboard3/sales_dashboards/clothes_orders"
exports.carpetOrdersEndPoint = "bx_block_cfsalesdashboard3/sales_dashboards/carpets_orders"
exports.totalOrdersEndPoint = "bx_block_cfsalesdashboard3/sales_dashboards/total_orders_data"
exports.salesSummaryApi = "bx_block_store_management/store_managements/generate_sales_summary_pdf"

exports.saleDashboard = "Sale Dashboard"
exports.sectiondashboard = "section"
exports.region = "Region:"
exports.store = "Stores:"
exports.period = "Period:"
exports.exportAll = "Export all"
exports.date = "Date:"
exports.orders = "Orders"
exports.pcs = "Pcs"
exports.amount = "Amount"
exports.amountTotalRes = "Amount Total Response Res"
exports.test = "test"
exports.saleTest = "sale test"
exports.totalChartDesc = "X axis - Payment Mode, Y axis - Amount (in SAR)"
exports.printSummary = "Print Summary"
exports.groups = "Groups:"
exports.SetCountForOrderAndPeices = "Set Count For Order And Peices:"
// Customizable Area End