import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  InputLabel,
  Paper,
  Grid,
  TextField,
  Snackbar,
  styled
} from "@material-ui/core";

import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { CommonIcomSearchComponent } from "../../../components/src/customComponents/CommonIcomSearchComponent";
import DropdownRadio from "../../../components/src/customComponents/DropdownRadio.web";
import Alert from '@material-ui/lab/Alert';
import { CustomSearchableMultiSelect } from "../../../components/src/customComponent/CustomSearchableMultiSelect.web"
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";

import { Formik,Form } from 'formik';
import * as Yup from 'yup';

import AddUpchargeController, {
  IUpchargeInitialValue1,
  IOptionList,
  Props,
  IUpchargeInitialValue,
} from "./AddUpchargeController";
import MultiSelectDropdownWeb from "../../../components/src/customComponents/MultiSelectDropdown.web";
import { configJSON } from "./AddPreferenceController.web";
import { colors } from "../../../blocks/utilities/src/Colors";

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat",
  },
});
const validationSchema = Yup.object().shape({
  upcharge_name: Yup.string().required("Upcharge name is required."),
  price: Yup.number().typeError('Price must be a number').required("Price is required.").min(0),
  type: Yup.string().required("Type is required."),
});
// Customizable Area End

export default class AddUpcharge extends AddUpchargeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  checkError(error: string | string[] | undefined, touch: boolean | undefined) {
    if (error && touch) {
      return true
    }
  }

  finalErrorMessage(error: string | undefined, touch: boolean | undefined) {
    if (error) {
      if (touch) {
        return <div style={webStyle.fieldError}>
          {error}
        </div>
      }
      else {
        return null
      }
    }
    else {
      return;
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box>
        <CustomLoader loading={this.state.isLoading || this.state.isSaveLoading}/>
        <ThemeProvider theme={theme}>
          <Snackbar data-test-id="snackbar-open" anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }} open={this.state.snackbar.open} autoHideDuration={1000} onClose={this.handleCloseSnackbar} >
            {this.state.snackbar?.error ? <Alert data-test-id="snackbar-alert" severity="error" >
              {this.state.snackbar.message}
            </Alert> : <Alert data-test-id="snackbar-alert" severity="success" >
              {this.state.snackbar.message}
            </Alert>}
          </Snackbar>
            <StylesMainBox>
              <div style={webStyle.headerWrapper}>
                <StylesPageHeader>{this.state.isEdit ? "Edit Upcharge" : "Add Upcharge"}</StylesPageHeader>
              </div>
              <Formik
                data-test-id="formikAddUpcharge"
                initialValues={this.state.addUpchargeInitialvalues}
                validationSchema={validationSchema}
                onSubmit={(values: IUpchargeInitialValue) => {
                  this.handleSubmit(values)
                }}
                enableReinitialize
              >
                {({
                  values,
                  errors,
                  touched,
                  handleSubmit,
                  setFieldValue,
                  getFieldProps
                }) => (
                  <Form  data-test-id="addUpchargeForm">
                    <CustomPaper>
                      <Grid container spacing={3} id="modal-content">
                        <Grid item xs={12} md={6}>
                          <SyleInputLable>Upcharge Name</SyleInputLable>
                          <InputField
                            data-test-id="field-upchange-name"
                            error={this.checkError(errors.upcharge_name, touched.upcharge_name)}
                            id="upcharge_name"
                            fullWidth={true}
                            placeholder="Enter Name"
                            variant="outlined"
                            {...getFieldProps('upcharge_name')}
                            name="upcharge_name"
                          />
                          {this.finalErrorMessage(errors.upcharge_name, touched.upcharge_name)}
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <SyleInputLable>Upcharge Arabic Name</SyleInputLable>
                          <InputField
                            dir="rtl"
                            data-test-id="field-upchange-second-name"
                            error={this.checkError(errors.upcharge_second_name, touched.upcharge_second_name)}
                            id="upcharge_second_name"
                            fullWidth={true}
                            placeholder="Enter Arabic Name"
                            variant="outlined"
                            {...getFieldProps('upcharge_second_name')}
                            name="upcharge_second_name"
                          />
                          {this.finalErrorMessage(errors.upcharge_second_name, touched.upcharge_second_name)}
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <SyleInputLable style={{marginBottom:'12px'}}>Icon</SyleInputLable>
                          
                          <CommonIcomSearchComponent
                            value={[]}
                            optionList={this.state.dropdownOptions.icon}
                            debouncedFunction={() => { }}
                            handleScroll={this.handleUpchargeProductScroll}
                            handleSelectOptions={(value: { id: string | number,option:string,image:string }) => { setFieldValue("icon", value.id); this.setState({selectedIcon:value}) }}
                            selectedItem={this.state.selectedIcon}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <SyleInputLable style={{ marginBottom: '12px' }}>Section</SyleInputLable>
                          <CustomSearchableMultiSelect
                            id="section"
                            name="section"
                            dataTestId="sectionDropdown"
                            checkSelectAllText={() => { }}
                            value={this.state.sectionSelectedData}
                            optionList={this.state.dropdownOptions.section}
                            placeHolderText={configJSON.placeHolderTextSection}
                            changeInputOnClear
                            handleScroll={this.handleUpchargeProductScroll}
                            handleClickOnSelectAll={() => { }}
                            debouncedFunction={(getValue: string) => { this.debouncedFunction(getValue) }}
                            handleSelectOptions={(value: { id: string | number, option: string }[], option: { id: string | number, option: string }) => { this.handleSelectSectionOptions(value, option, setFieldValue) }}
                            emptyDataMessage={configJSON.emptySectionDataMessage}
                            handleEmptyAutoSelectValue={() => { }}
                            selectedOptionForList={this.state.selectedSectionsForList}
                            isError={this.checkError(errors.section, touched.section)}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <SyleInputLable>Service</SyleInputLable>
                          <CustomSearchableMultiSelect
                            name="service"
                            id="service"
                            dataTestId="serviceDropdown"
                            checkSelectAllText={() => { }}
                            changeInputOnClear
                            value={this.state.serviceSelectedData}
                            optionList={this.state.dropdownOptions.service}
                            placeHolderText={"Select Service"}
                            handleScroll={this.handleUpchargeProductScroll}
                            handleClickOnSelectAll={() => { }}
                            debouncedFunction={(getValue: string) => { this.debouncedFunction(getValue)}}
                            emptyDataMessage={configJSON.noServiceText}
                            handleSelectOptions={(value: { id: string | number, option: string }[], option: { id: string | number, option: string }) => { this.handleSelectServiceOptions(value, option, setFieldValue) }}
                            style={{ maxWidth: "100%", marginTop: 12 }}
                            handleEmptyAutoSelectValue={() => { }}
                            selectedOptionForList={this.state.selectedServicesForList}
                            isError={this.checkError(errors.service, touched.service)}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <SyleInputLable>Product</SyleInputLable>
                          <CustomSearchableMultiSelect
                            name="product"
                            id="product"
                            dataTestId="productDropdowns"
                            checkSelectAllText={(updatedValue: { id: string | number, option: string }[]) => { this.checkSelectAllText(updatedValue); setFieldValue('product', updatedValue) }}
                            value={this.state.selectUpchargeProductForAdd}
                            optionList={this.state.upchargeProductListDropdown}
                            placeHolderText={configJSON.selectProductText}
                            handleScroll={this.handleUpchargeProductScroll}
                            handleClickOnSelectAll={() => { this.handleUpchargeClickOnSelectAll(); setFieldValue('product', ["All"]) }}
                            debouncedFunction={(getValue: string) => { this.debouncedFunction(getValue) }}
                            emptyDataMessage={configJSON.noProductText}
                            handleSelectOptions={(value: { id: string | number, option: string }[], option: { id: string | number, option: string }) => { this.handleUpchargeSelectOptions(value, option, setFieldValue) }}
                            style={{ maxWidth: "100%", marginTop: 12 }}
                            handleEmptyAutoSelectValue={() => { this.handleUpchargeEmptyAutoSelectValue() }}
                            selectedOptionForList={this.state.upchargeSelectedOptionForList}
                          />
                        </Grid>

                        <Grid item xs={12} md={2}>
                          <SyleInputLable>Type</SyleInputLable>
                          <DropdownRadio
                            data-test-id="currencyType"
                            fieldLabel={'Type'}
                            fieldName={'type'}
                            isError={errors.type && touched.type}
                            options={this.state.dropdownOptions.type}
                            placeholder={"Type"}
                            errorMessage={errors.type}
                            withIcon={false}
                            setFieldValue={setFieldValue}
                            {...getFieldProps('type')}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <SyleInputLable>Price</SyleInputLable>
                          <InputField
                           onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                                e.target.addEventListener(
                                "wheel",
                                function (e: WheelEvent) {
                                    e.preventDefault();
                                },
                                { passive: false }
                                );
                            }}
                            inputProps={{
                              "data-test-id": "field-price",
                              }}
                            error={this.checkError(errors.price, touched.price)}
                            id="price"
                            type='number'
                            fullWidth={true}
                            placeholder="Enter Price"
                            variant="outlined"
                            {...getFieldProps('price')}
                            name="price"
                          />
                          {this.finalErrorMessage(errors.price, touched.price)}
                        </Grid>
                      </Grid>
                    </CustomPaper>
                    <Box display={'flex'} mt={2} style={{ marginTop: '48px' }}>
                      <SecondaryButtonBox>
                        <Button
                          data-test-id="btnCancel"
                          className="secondaryButtonStyle"
                          onClick={() => this.handleBackTotListing()}
                        >
                          Cancel
                        </Button>
                      </SecondaryButtonBox>
                      <PrimaryButtonBox>
                        <Button
                          data-test-id="submitBtn"
                          type="submit"
                          className="primaryButtonStyle"
                          disabled={this.state.isSaveLoading}
                        >
                         {this.state.isEdit ? "Save" : "Add"}
                        </Button>
                      </PrimaryButtonBox>
                    </Box>
                  </Form>
                )}
              </Formik>
            </StylesMainBox>
        </ThemeProvider>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  table: {
    minWidth: 700,
  },
  mainWrapper: {
    marginTop: "32px",
    fontFamily: "Montserrat",
  },
  pageHeader: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "29px",
  },
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 32,
    alignItems: "center",
  },
  button: {
    background: "#204B9C",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
    height: "56px",
    width: "128px",
    borderRadius: "8px",
    marginRight: 32,
    textTransform: "unset" as 'unset'
  },
  buttonSecondary: {
    backgroundColor: '#F1F5F9',
    color: '#64748B'
  },
  contentWrapper: {
    marginRight: 32,
    marginTop: 56,
  },
  fieldError: {
    color: "red",
    fontSize: "14px",
    margin: "5px 5px 5px 0",
    fontFamily: 'Montserrat'
  },
  fieldLabel: {
    marginBottom: "2px",
    color: "#334155",
    fontSize: "16px",
    fontWeight: 600,
  },
  fieldSet: {
    padding: "12px 8px"
  }
};

const InputField = withStyles({
  root: {
    width: '100%',
    paddingTop: '10px',
    borderRadius: "8px",
    "& .MuiOutlinedInput-root": {
      fontWeight: 400,
      border: '1px solid #ECECEC',
      fontSize: "14px",
      borderRadius: "8px",
      "& fieldset": {
        fontSize: "14px",
        borderRadius: "8px",
        borderWidth: 1,
        fontWeight: 400,
      },
    },
    "& ::placeholder": {
      opacity: 1,
      color: "#4D4D4D",
      fontSize: '14px',
      "@media only screen and (max-width: 1024px)": {
        opacity: 1,
        color: colors().darkliver,
        fontSize: "12px",
        fontFamily: "Montserrat",
    },
    },
    "@media only screen and (max-width: 1024px)": {
      '& .MuiInputBase-input': {
        fontSize: "12px",
        padding:'14.9px 14px'
      },
    },
  }
})(TextField);

const CustomPaper = withStyles({
  root: {
    borderRadius: '12px',
    background: '#fff',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.03), 0px 5px 32px rgba(0, 0, 0, 0.06)',
    padding: '32px'
  }
})(Paper);

const StylesPageHeader= styled("div")({
  fontSize: "24px",
  lineHeight: "30px",
  fontWeight: 600,
  fontStyle: "normal",
  fontFamily: "Montserrat",
  color: colors().black,
  "@media only screen and (max-width: 1024px)": {
    fontSize: "16px",
    fontWeight: 600,
    color: colors().viewTextColor,
  },
})

const SyleInputLable = styled(InputLabel)({
  fontFamily: "Montserrat, sans-serif !important",
  marginBottom: "2px",
  color: colors().charcoal,
  fontSize: "16px !important",
  fontWeight: 600,
  "@media only screen and (max-width: 1024px)": {
    fontSize: "13px !important",
    fontWeight: 600,
    color: colors().charcoal,
  }, 
})

const SecondaryButtonBox = styled(Box)({
  '& .secondaryButtonStyle': {
    width: "126px",
    height: "56px",
    border: "none",
    fontSize: '16px',
    borderRadius: '8px',
    fontWeight: 700,
    cursor: 'pointer',
    textTransform: "none" as 'none',
    backgroundColor: colors().antiFlashWhite,
    color: colors().slateGray,
    "@media only screen and (max-width: 1024px)": {
      width: 128,
      height: 44,
    }
  },
});

const PrimaryButtonBox = styled(Box)({
  marginLeft: '32px',
  '& .primaryButtonStyle': {
    width: "126px",
    height: "56px",
    border: "none",
    fontSize: '16px',
    borderRadius: '8px',
    fontWeight: 700,
    cursor: 'pointer',
    textTransform: "none" as 'none',
    backgroundColor: colors().cyancobaltblue,
    color: colors().background,
    "@media only screen and (max-width: 1024px)": {
      width: 128,
      height: 44,
    }
  },
});

const StylesMainBox = styled(Box)({
  marginTop: '32px',
  "& form": {
    padding: "0 0 32px 0"
  }
});

// Customizable Area End
