// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { SpecificationData } from "./SpecificationListController.web";
import { apiCall } from "../../../components/src/common";
import { navigateTo } from "../../utilities/src/CustomBlockHelpers";
import { IProductItems } from "../../CfPriceList/src/PriceListViewController.web";
import { ClassNameMap } from "@material-ui/styles";
import { debounce, uniqBy } from "lodash";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes: ClassNameMap
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  specificationCurrentItem: SpecificationData,
  optionsProduct: []
  sectionOptionsData: Options[]
  serviceOptionsData: Options[]
  isSectionOpen: boolean
  selectedOptionsSection: number[]
  isServiceOpen: boolean
  selectedOptionService: number[]
  selectedOptionsProduct: number[]
  isProductOpen: boolean
  itemsNew: string
  itemsData: Items[]
  itemCountNumber: number
  itemsId: {
    label: string,
    _destroy: boolean | undefined
  }[]
  firstInputName: string
  secondInputName: string
  showErrorMessage: boolean
  showSectionError: boolean
  showProductError: boolean
  showServiceError: boolean
  openSnackbar: boolean
  message: string
  severity: string
  currentItemId: number;
  isSectionSelectAll: boolean;
  isServiceSelectAll: boolean;
  serviceSelectedData: Options[];
  isProductSelectAll: boolean;
  productSelectedData: Options[];
  isLoading: boolean;
  initialValues: {
    section: string | number[],
    service: string | number[],
    product: string | number[]
  };
  productOptions: { id: string | number, option: string }[];
  sectionOptions: { id: number, option: string }[];
  serviceOptions: { id: number, option: string }[];
  selectedForAdd: { id: string | number, option: string }[];
  editSpecSelectedOptionForList: { id: string | number, option: string }[];
  autoCopmpleteValue: string;
  isSelectedAll: boolean;
  productListItemsDropdown: IProductItems[];
  currentProductData: IProductItems[];
  productNextPage: number;
  sectionNextPage: number;
  serviceNextPage: number;
  selectedProductWhileEdit: { id: string | number, option: string }[];
  searchQuery: boolean;
  sectionAutoCopmpleteValue: string;
  serviceAutoCopmpleteValue: string;
  selectedSections: { id: string | number, option: string }[];
  selectedServices: { id: string | number, option: string }[];
  selectedSectionsForList: { id: string | number, option: string }[];
  selectedServicesForList: { id: string | number, option: string }[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;

  // Customizable Area End
}
// Customizable Area Start
interface Options {
  id: number
  label: string
  active?: boolean
}
interface Items {
  _destroy?: boolean;
  isNewAdded?: boolean;
  data: {
    attributes: {
      id?: string;
      label: string;
      _destroy?: boolean
    }
  }
}

export const specificationIntialVal = {
  id: 0,
  attributes: {
    name: "",
    second_name: "",
    active: false,
    options: [],
    sections: {
      data: []
    },
    services: {
      data: []
    },
    products: {
      data: []
    }
  }
}
// Customizable Area End

export default class EditSpecificationController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  itemsPerPage = 10;
  updateSpecificationListCallId: string = "";
  sectionListItemCallId: string = "";
  serviceListItemCallId: string = "";
  ProductListItemCallId: string = "";
  getSpecificationEditDataAPICallId: string = "";
  disableLoadMoreSection = false;
  disableLoadMoreService = false;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      specificationCurrentItem: specificationIntialVal,
      optionsProduct: [],
      sectionOptionsData: [],
      serviceOptionsData: [],
      isSectionOpen: false,
      selectedOptionsSection: [],
      isServiceOpen: false,
      selectedOptionService: [],
      selectedOptionsProduct: [],
      isProductOpen: false,
      itemsNew: "",
      itemsData: [],
      itemCountNumber: 1,
      itemsId: [],
      firstInputName: "",
      secondInputName: "",
      showErrorMessage: false,
      showSectionError: false,
      showProductError: false,
      showServiceError: false,
      openSnackbar: false,
      message: "",
      severity: "",
      currentItemId: 0,
      isSectionSelectAll: false,
      isServiceSelectAll: false,
      serviceSelectedData: [],
      isProductSelectAll: false,
      productSelectedData: [],
      isLoading: false,
      initialValues: {
        section: [],
        service: [],
        product: []
      },
      productOptions: [],
      sectionOptions: [],
      serviceOptions: [],
      autoCopmpleteValue: '',
      productListItemsDropdown: [],
      selectedForAdd: [],
      editSpecSelectedOptionForList: [],
      productNextPage: 1,
      sectionNextPage: 1,
      serviceNextPage: 1,
      isSelectedAll: false,
      searchQuery: false,
      currentProductData: [],
      selectedProductWhileEdit: [],
      sectionAutoCopmpleteValue: '',
      serviceAutoCopmpleteValue: '',
      selectedSections: [],
      selectedServices: [],
      selectedSectionsForList: [],
      selectedServicesForList: [],
    
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();

    // Customizable Area Start
    const paramId = this.props.navigation.getParam("navigationBarTitleText");
    if (paramId) {
      this.getSpecificationEditData(paramId);
    } else {
      this.getProductDropDown("")
    }

    this.getSectionDropDown()
    this.getItemsIds()
    // Customizable Area End
  }
  // Customizable Area Start
  componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.itemsData !== this.state.itemsData) {
      this.getItemsIds()
    }
  };

  getSpecificationEditData = (urlId?: string) => {
    this.setState({isLoading:true})
    let apiUrl = configJSON.specificationSingleDataApiUrl + `/${urlId}`;
    let headers = {
      token: localStorage.getItem(configJSON.token),
    };
    const getAccount = apiCall({
      httpBody: {},
      header: headers,
      url: apiUrl,
      httpMethod: configJSON.httpGetType,
    });
    this.getSpecificationEditDataAPICallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  };

  handleGetSecificationDetailResp = (message: Message) => {
    const ApiCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (this.getSpecificationEditDataAPICallId === ApiCallId) {
      if (apiResponse) {
        this.setState({
          specificationCurrentItem: apiResponse.data,
          selectedProductWhileEdit: apiResponse.data.attributes.products.data,
          selectedForAdd: apiResponse.data.attributes.products.data.map((item: IProductItems) => { return { id: item.id, option: item.attributes.product_name } }),
          editSpecSelectedOptionForList: apiResponse.data.attributes.products.data.map((item: IProductItems) => { return { id: item.id, option: item.attributes.product_name } })
        }, () => {this.setState({isLoading:false}); this.setEditValueData(); this.getProductDropDown("") })
      }
    }
  }

  setEditValueData = () => {
    const { id, attributes } = this.state.specificationCurrentItem
    const selectedSections = attributes.sections.data.map(item => ({id: item.attributes.id, option: item.attributes.section_name}))
    const selectedServices = attributes.services.data.map(item => ({id: item.attributes.id, option: item.attributes.name}))
    this.setState({
      firstInputName: attributes.name,
      secondInputName: attributes.second_name,
      selectedOptionService: attributes.services.data.map(item => item.attributes.id),
      serviceSelectedData: attributes.services.data.map(item => { return { id: item.attributes.id, label: item.attributes.name, option: item.attributes.name } }),
      selectedOptionsProduct: attributes.products.data.map(item => item.attributes.id),
      productSelectedData: attributes.products.data.map(item => { return { id: item.attributes.id, label: item.attributes.product_name, option: item.attributes.product_name } }),
      selectedSections,
      selectedSectionsForList: selectedSections,
      selectedServices,
      selectedServicesForList: selectedServices,
      itemsData: attributes.options,
      itemCountNumber: attributes.options.length + 1,
      currentItemId: id
    }, () => {
      this.getServiceDropDown()
      this.getProductDropDown("")
    })
    this.setState(() => ({
      initialValues: {
        section: attributes.sections.data.map(item => item.attributes.id),
        service: attributes.services.data.map(item => item.attributes.id),
        product: attributes.products.data.map(item => item.attributes.id),
      }
    }))
  }
  handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      firstInputName: event.target.value,
      isSectionOpen: false,
      isServiceOpen: false,
      isProductOpen: false
    });
  };
  handleSecondNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      secondInputName: event.target.value,
      isSectionOpen: false,
      isServiceOpen: false,
      isProductOpen: false
    });
  };
  handleCancelSpecification = () => {
    navigateTo({
      props: this.props,
      screenName: "SpecificationList",
    });
  }
  handleChangeAddInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ itemsNew: event.target.value });
  };
  handleAdItemsClick = () => {
    const { itemsNew } = this.state;
    if (itemsNew.trim() !== "") {
      const newItemWithId = {
        isNewAdded: true,
        data: {
          attributes: {
            label: itemsNew,
            _destroy: false,
          }
        }
      };
      this.setState((prevState) => ({
        itemsNew: "",
        itemsData: [...prevState.itemsData, newItemWithId],
        itemCountNumber: prevState.itemCountNumber + 1,
      }));

    }
  };

  handleDeleteItem = (itemId?: string, index?: number) => {
    const { itemsData, itemCountNumber } = this.state;
    let updatedItems;
    let updatedCounter = itemCountNumber

    if (itemId) {
      updatedItems = itemsData.map((item) => {
        if (item.data.attributes.id === itemId) {
          return { ...item, _destroy: true };
        }
        return item;
      });
    } else {
      updatedItems = itemsData.filter((item, indexes) => indexes !== index);
    }
    if (updatedCounter > 0) {
      updatedCounter -= 1;
    }
    this.setState({ itemsData: updatedItems, itemCountNumber: updatedCounter });
  };
  getItemsIds = () => {
    const { itemsData } = this.state;
    this.setState({
      itemsId: itemsData.
        filter(item => item._destroy || item.isNewAdded)
        .map(item => {
          return {
            label: item.data.attributes.label,
            id: item.data.attributes.id,
            _destroy: item._destroy
          }
        })
    })
  }

  checkFormErrors = () => {
    const { firstInputName } = this.state;
    return firstInputName !== ''
  }

  handleClickSave = () => {
    const { firstInputName } = this.state;
    if (firstInputName === '') {
      this.setState({ showErrorMessage: true });
    } else {
      this.setState({ showErrorMessage: false });
    }
    if (this.checkFormErrors()) {
      this.setState({ isLoading: true })
      this.updateSpecificationList()
    }
  };
  handleCloseSnackbar = () => {
    this.setState({ openSnackbar: false });
  }
  getSectionDropDown = () => {
    const headers = {
      "Content-Type": configJSON.categoryApiContentType,
      token: window.localStorage.getItem(configJSON.token)
    };

    const sectionListDataMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sectionListItemCallId = sectionListDataMsg.messageId;
    this.disableLoadMoreSection = true;
    const searchQuery = this.state.sectionAutoCopmpleteValue ? `&filter_by[query]=${this.state.sectionAutoCopmpleteValue}` : ''

    sectionListDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.sectionDropDownListEndPoint}&page_no=${this.state.sectionNextPage}${searchQuery}&per_page=${this.itemsPerPage}`
    );

    sectionListDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    sectionListDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(sectionListDataMsg.id, sectionListDataMsg);
  }
  getProductDropDown = (searchQuery: string) => {
    this.setState({ searchQuery: searchQuery.length < 1 ? true : false });
    const headers = {
      "Content-Type": configJSON.categoryApiContentType,
      token: window.localStorage.getItem(configJSON.token)
    };

    const productListDataMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.ProductListItemCallId = productListDataMsg.messageId;
    const sectionSelectedIds = this.state.selectedSections.filter(item => item.id !== "-1").map((item) => item.id)
    const filterBySectionIds = !this.state.isSectionSelectAll ? `&filter_by[section_ids][]=${sectionSelectedIds}` : '';
    const searchQueryString = this.state.autoCopmpleteValue ? `&filter_by[query]=${this.state.autoCopmpleteValue}` : ''
    

    productListDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.productListDropdownApiEndPoint}${searchQueryString}${filterBySectionIds}&page_no=${this.state.productNextPage}`
    );

    productListDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    productListDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(productListDataMsg.id, productListDataMsg);
  }
  getServiceDropDown = () => {
    const headers = {
      "Content-Type": configJSON.categoryApiContentType,
      token: window.localStorage.getItem(configJSON.token)
    };

    const serviceDataMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.serviceListItemCallId = serviceDataMsg.messageId;
    this.disableLoadMoreService = true;
    const sectionSelectedIds = this.state.selectedSections.filter(item => item.id !== "-1").map((selectedSection) => selectedSection.id)
    const apiEndPoint = !this.state.isSectionSelectAll ?
      configJSON.getServiceListDropDownEndpoint + `&filter_by[section_ids][]=${sectionSelectedIds}` :
      configJSON.getServiceListDropDownEndpoint
    const searchQuery = this.state.serviceAutoCopmpleteValue ? `&filter_by[query]=${this.state.serviceAutoCopmpleteValue}` : ''

    serviceDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${apiEndPoint}${searchQuery}&page_no=${this.state.serviceNextPage}&per_page=${this.itemsPerPage}`
    );

    serviceDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    serviceDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(serviceDataMsg.id, serviceDataMsg);
  }
  updateSpecificationList = () => {

    let headers = {
      "Content-Type": configJSON.categoryApiContentType,
      token: window.localStorage.getItem(configJSON.token)
    };
    let httpBody = {}
    httpBody = {
      data: {
        attributes: {
          name: this.state.firstInputName,
          second_name: this.state.secondInputName,
          options_attributes: this.state.itemsId,
          section_ids: this.state.isSectionSelectAll ? ['All'] : this.state.selectedSections.map(item => item.id),
          service_ids: this.state.isServiceSelectAll ? ['All'] : this.state.selectedServices.map((item) => item.id),
          product_ids: this.state.isSelectedAll ? ['All'] : this.state.selectedForAdd.map((item) => Number(item.id)),
        }
      }

    }
    const updateSpecificationDataMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateSpecificationListCallId = updateSpecificationDataMsg.messageId;

    updateSpecificationDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createSpecificationApiEndPoint}/${this.state.currentItemId}`
    );

    updateSpecificationDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    updateSpecificationDataMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    updateSpecificationDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpUpdateMethodType
    );

    runEngine.sendMessage(updateSpecificationDataMsg.id, updateSpecificationDataMsg);

  }
  handleResponseForGetSection = (from: string, message: Message) => {
    if (this.sectionListItemCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse) {
        const receivedSectionOptions = apiResponse.data?.map((item: { id: string; attributes: { section_name: string; active: boolean; }; }) => 
        ({
            id: Number(item.id),
            label: item.attributes.section_name,
            active: item.attributes.active,
            option: item.attributes.section_name,
        })) || []
        const currentSelectedSections = this.state.selectedSectionsForList.filter(item => item.id !== "-1")
        this.setState({
          sectionOptions: uniqBy([...(this.state.sectionNextPage === 1 ? currentSelectedSections : this.state.sectionOptions), ...receivedSectionOptions],"id"),
          selectedSectionsForList: this.state.isSectionSelectAll ? uniqBy([
            ...this.state.selectedSectionsForList,
            ...receivedSectionOptions
          ], "id") : this.state.selectedSectionsForList,
        })
        this.disableLoadMoreSection = receivedSectionOptions.length < this.itemsPerPage
      }
    }
  }
  handleResponseForGetProduct = (from: string, message: Message) => {
    if (this.ProductListItemCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse) {
        if (this.state.productListItemsDropdown.length === 0) {
          let responseOptionList = [...this.state.selectedProductWhileEdit, ...apiResponse.data.filter((item: IProductItems) => {
            return this.handleProductListResponseEditSpec(item)
          })];

          const existingData = this.state.productListItemsDropdown;
          this.setState({ productListItemsDropdown: this.handleProductListResAsPerQuery(existingData, responseOptionList), currentProductData: responseOptionList }, () => {
            this.state.isSelectedAll && this.setState({
              editSpecSelectedOptionForList: [...this.state.editSpecSelectedOptionForList, ...responseOptionList.map((item: IProductItems) => { return { id: item.id, option: item.attributes.product_name } })],
            })
          })
        } else {
          let responseOptionList = apiResponse.data.filter((item: IProductItems) => {
            return this.handleProductListResponseEditSpec(item)
          });

          const existingData = this.state.productListItemsDropdown;
          this.setState({ productListItemsDropdown: this.handleProductListResAsPerQuery(existingData, responseOptionList), currentProductData: responseOptionList }, () => {
            this.state.isSelectedAll && this.setState({
              editSpecSelectedOptionForList: [...this.state.editSpecSelectedOptionForList, ...responseOptionList.map((item: IProductItems) => { return { id: item.id, option: item.attributes.product_name } })],
            })
          })
        }
      }
    }
  }

  handleProductListResponseEditSpec(item: IProductItems) {
    if (!this.state.selectedProductWhileEdit.some((innerItem: { id: string | number, option: string }) => innerItem.id === item.id)) {
      return item
    }
  }

  handleProductListResAsPerQuery(existingData: IProductItems[], responseOptionList: IProductItems[]) {
    if (this.state.searchQuery) {
      return existingData.concat(responseOptionList)
    }
    return responseOptionList
  }

  handleResponseForService = (from: string, message: Message) => {
    if (this.serviceListItemCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse) {
        const receivedServices = apiResponse.data?.map((item: { id: string; attributes: { name: string; active: boolean; }; }) => (
          {
            id: Number(item.id),
            label: item.attributes.name,
            active: item.attributes.active,
            option: item.attributes.name,
          }
        )) || []
        const currentSelectedServices = this.state.selectedServicesForList.filter(item => item.id !== "-1")
        this.setState({
          serviceOptions: uniqBy([...(this.state.serviceNextPage === 1 ? currentSelectedServices : this.state.serviceOptions), ...receivedServices], "id"),
          selectedServicesForList: this.state.isServiceSelectAll ?
            uniqBy([...this.state.selectedServicesForList, ...receivedServices], "id")
            : this.state.selectedServicesForList
        })
        this.disableLoadMoreService = receivedServices.length < this.itemsPerPage
      }
    }
  }
  handleResponseForUpdateSpecificationList = (from: string, message: Message) => {
    if (this.updateSpecificationListCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.data) {
        this.setState({
          message: configJSON.updateSpecificationMessage,
          openSnackbar: true,
          severity: configJSON.severitySuccess,
        }, () => {
          setTimeout(() => {
            this.setState({ isLoading: false})
            navigateTo({
            props: this.props,
            screenName: "SpecificationList",
          })
        }, 1000)
          
        })
      } else {
        this.setState({
          isLoading: false,
          message: `${Object.keys(apiResponse.errors[0])[0]} ${Object.values(apiResponse.errors[0])[0]}`,
          openSnackbar: true,
          severity: configJSON.typeError

        })
      }
    }
  }

  handleSpecificationClickOnSelectAll = () => {
    this.setState({ isSelectedAll: true, selectedForAdd: [] })
  };

  checkSpecificationSelectAllText = (getValue: { id: string | number, option: string }[]) => {
    this.setState({ selectedForAdd: getValue })
  };

  handleSectionAutoCompleteChange = (getValue: string) => {
    if (getValue === this.state.sectionAutoCopmpleteValue) return;
    this.setState({ sectionAutoCopmpleteValue: getValue, sectionNextPage: 1 }, () => {
      if (getValue.length < 1 || getValue.length > 2) {
        this.getSectionDropDown()
      }
    });
  }

  handleServiceAutoCompleteChange = (getValue: string) => {
    if (getValue === this.state.serviceAutoCopmpleteValue) return;
    this.setState({ serviceAutoCopmpleteValue: getValue, serviceNextPage: 1 });
    if (getValue.length < 1 || getValue.length > 2) {
      if (getValue.length < 1 || getValue.length > 2) {
        this.getServiceDropDown()
      }
    }
  }

  handleSpecAutoCompltProductChange = (getValue: string) => {
    this.setState({ autoCopmpleteValue: getValue, productNextPage: 1 });
    if (getValue.length < 1 || getValue.length > 2) {
      this.getProductDropDown(getValue)
    }
  }

  debouncedFunction = debounce(
    (newInputValue: string, inputFunction: (inputValue: string) => void) => inputFunction(newInputValue),
    700,
    { maxWait: 2000 }
  );

  handleSpecificationEmptyAutoSelectValue = () => {
    this.setState({ autoCopmpleteValue: "" })
  };

  handleScrollSectionDropdown = (event: React.SyntheticEvent) => {
    if (this.disableLoadMoreSection) return;
    const checkListboxNode = event.currentTarget;
    const boxPosition = checkListboxNode.scrollTop + checkListboxNode.clientHeight;

    if (checkListboxNode.scrollHeight - boxPosition <= 1.30) {
      this.setState((prevState) => ({ sectionNextPage: prevState.sectionNextPage + 1 }), () => {
        this.getSectionDropDown()
      })
    }
  };
  handleScrollServiceDropdown = (event: React.SyntheticEvent) => {
    if (this.disableLoadMoreService) return;
    const checkListboxNode = event.currentTarget;
    const boxPosition = checkListboxNode.scrollTop + checkListboxNode.clientHeight;

    if (checkListboxNode.scrollHeight - boxPosition <= 1.30) {
      this.setState((prevState) => ({ serviceNextPage: prevState.serviceNextPage + 1 }), () => {
        this.getServiceDropDown()
      })
    }
  };

  handleSpecificationScrollProductDropdown = (event: React.SyntheticEvent) => {
    const listboxNode = event.currentTarget;
    const position = listboxNode.scrollTop + listboxNode.clientHeight;

    if (listboxNode.scrollHeight - position <= 1.30 && this.state.currentProductData.length !== 0) {
      this.setState({ productNextPage: this.state.productNextPage + 1 }, () => {
        this.getProductDropDown("")
      })
    }
  };

  handleSectionMultiSelect = (value: { id: string | number, option: string }[], option: { id: string | number, option: string }, setFieldValue: Function) => {
    let updateStateData: Partial<S> = {
      isServiceSelectAll: false,
      isProductSelectAll: false,
      selectedServices: [],
      selectedServicesForList: [],
      serviceOptions: [],
      selectedForAdd: [],
      editSpecSelectedOptionForList: [],
      productListItemsDropdown: [],
      serviceNextPage: 1,
      productNextPage: 1
    }
    let newFieldValue: Array<string | number> = []
    if (option.id == "-1") {
      updateStateData = this.state.isSectionSelectAll ?
        { 
          ...updateStateData, isSectionSelectAll: false, 
          selectedSections: [], 
          selectedSectionsForList: [] 
        }
        :
        {
          ...updateStateData,
          isSectionSelectAll: true,
          selectedSections: [{ id: "-1", option: "Select All" }],
          selectedSectionsForList: [{ id: "-1", option: "Select All" }, ...this.state.sectionOptions]
        };
      newFieldValue = this.state.isSectionSelectAll ? ["All"] : []

    } else {
      const isValueIncluded = value.some((item) => item.id == option.id)
      updateStateData.isSectionSelectAll = false
      value = value.filter((item) => item.id !== "-1")
      const newItems = isValueIncluded ? value.filter((item) => item.id != option.id) : [...value, option]
      updateStateData.selectedSections = newItems
      updateStateData.selectedSectionsForList = newItems;
      if(newItems?.length === this.state.sectionOptions?.length) {
        updateStateData = {
          ...updateStateData,
          isSectionSelectAll: true,
          selectedSections: [{ id: "-1", option: "Select All" }],
          selectedSectionsForList: [{ id: "-1", option: "Select All" }, ...this.state.sectionOptions]
        };
      }
      newFieldValue = newItems.map((item) => item.id)
    }
    this.setState(updateStateData as Pick<S, keyof S>, () => {
      setFieldValue("section", newFieldValue)
      this.getServiceDropDown()
      this.getProductDropDown("")
    })
  };

  handleSelectServiceOptions = (value: { id: string | number, option: string }[], option: { id: string | number, option: string }, setFieldValue: Function) => {
    let updateStateData: Partial<S> = {}
    let newFieldValue: Array<string | number> = []
    if (option.id == "-1") {
      updateStateData = this.state.isServiceSelectAll ?
        {
          ...updateStateData,
          isServiceSelectAll: false,
          selectedServices: [],
          selectedServicesForList: []
        }
        :
        {
          ...updateStateData,
          isServiceSelectAll: true,
          selectedServices: [{ id: "-1", option: "Select All" }],
          selectedServicesForList: [{ id: "-1", option: "Select All" }, ...this.state.serviceOptions]
        }
      newFieldValue = this.state.isSectionSelectAll ? ["All"] : []
    } else {
      const isValueIncluded = value.some((item) => item.id == option.id)
      updateStateData.isServiceSelectAll = false
      value = value.filter((item) => item.id !== "-1")
      const newItems = isValueIncluded ? value.filter((item) => item.id != option.id) : [...value, option]
      updateStateData.selectedServices = newItems
      updateStateData.selectedServicesForList = newItems
      if(newItems?.length === this.state.serviceOptions?.length) {
        updateStateData = {
          ...updateStateData,
          isServiceSelectAll: true,
          selectedServices: [{ id: "-1", option: "Select All" }],
          selectedServicesForList: [{ id: "-1", option: "Select All" }, ...this.state.serviceOptions]
        };
      }
      newFieldValue = newItems.map((item) => item.id)
    }
    this.setState(updateStateData as Pick<S, keyof S>, () => setFieldValue("service", newFieldValue))
  };

  handleSpecificationSelectOptions = (value: { id: string | number, option: string }[], option: { id: string | number, option: string }, setFieldValue: Function) => {
    if (option.id == "-1") {
      if (this.state.isSelectedAll) {
        this.setState({ isSelectedAll: false, selectedForAdd: [], editSpecSelectedOptionForList: [] });
      } else {
        this.setState({
          isSelectedAll: true,
          editSpecSelectedOptionForList: [{ id: "-1", option: "Select All" }, ...this.state.productListItemsDropdown.map((item) => { return { id: item.id, option: item.attributes.product_name } })],
          selectedForAdd: [{ id: "-1", option: "Select All" }],
        });
        setFieldValue('product', ["All"])
      }
      return;
    }

    const isContained = value.some((item) => item.id == option.id)

    if (isContained) {
      value = value.filter((item) => item.id !== "-1")
      this.setState({ selectedForAdd: value.filter((item) => item.id != option.id), editSpecSelectedOptionForList: value.filter((item) => item.id != option.id), isSelectedAll: false }, () => setFieldValue('product', value.filter((item) => item.id != option.id).map((item) => item.id)))
    } else {
      value = value.filter((item) => item.id !== "-1")
      this.setState({ selectedForAdd: [...value, option], editSpecSelectedOptionForList: [...value, option], isSelectedAll: false }, () => setFieldValue('product', [...value, option].map((item) => item.id)))
    }
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleResponseForGetSection(from, message)
    this.handleResponseForGetProduct(from, message)
    this.handleResponseForService(from, message)
    this.handleResponseForUpdateSpecificationList(from, message)
    this.handleGetSecificationDetailResp(message)
    // Customizable Area End
  }
}
