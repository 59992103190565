import React from "react";
import { toString } from "lodash";
import { styled, Box, TextField, Select, MenuItem } from "@material-ui/core"
import { colors } from "../../blocks/utilities/src/Colors"


type Props = {
    inputValue: number | null;
    unitValue: string;
    onChangeNumberInput: (numberData: number | null) => void
    onChangeUnit: (newUnit: "month" | "year") => void
}

const CustomComboBox = (props: Props) => {
    const { onChangeNumberInput, onChangeUnit, inputValue, unitValue } = props
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let { value } = event.target;
        value = value.replace(/[^\d]/g, "");
        onChangeNumberInput(parseInt(value) || null);
    };

    return (
        <StyledWrapper>
            <StyledTextField
                fullWidth
                placeholder="Enter Number"
                variant="outlined"
                value={toString(inputValue)}
                onChange={handleChange}

            />
            <Select
                className="unit-select"
                value={unitValue}
                onChange={(event) => onChangeUnit(event.target.value as "month" | "year")}
                disableUnderline
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "right",
                    },
                    PaperProps: {
                        style: {
                            marginTop: 12,
                            boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
                            borderRadius: 8,
                            padding: 0
                        }
                    },
                    getContentAnchorEl: null,
                }}
            >
                <StyledMenuItem value="month">Month</StyledMenuItem>
                <StyledMenuItem value="year">Year</StyledMenuItem>
            </Select>
        </StyledWrapper>
    )
}

const StyledWrapper = styled(Box)({
    position: "relative",
    "& .unit-select": {
        paddingTop: 10,
        position: "absolute",
        right: 9,
        height: "100%",
        fontFamily: "Montserrat",
        fontWeight: 400,
        "& .MuiSelect-select:focus": {
            background: "transparent"
        },
        "& .MuiSelect-icon": {
            top: "calc(50% - 8px)"
        }
    }
})

const StyledTextField = styled(TextField)({
    width: "100%",
    paddingTop: 6,
    borderRadius: 8,
    fontFamily: "Montserrat",
    "& .MuiOutlinedInput-root": {
        fontWeight: 500,
        fontSize: 16,
        fontFamily: "Montserrat",
        borderRadius: 8,
        "@media only screen and (max-width: 1024px)": {
            fontSize: 13,
        },
        "& fieldset": {
            borderColor: colors().lightborder,
            fontSize: "14px",
            borderRadius: "8px",
            borderWidth: 1,
            fontWeight: 400,
            fontFamily: "Montserrat",
        },
    },
    "& ::placeholder": {
        opacity: 1,
        color: colors().darkliver,
        fontSize: 14,
        fontFamily: "Montserrat",
        fontWeight: 400,
        "@media only screen and (max-width: 1024px)": {
            fontSize: 12,
        },
    },
    "@media only screen and (max-width: 1024px)": {
        '& .MuiInputBase-input': {
            fontSize: 12,
        },
    },
},
)

const StyledMenuItem = styled(MenuItem)({
    fontFamily: "Montserrat",
    fontWeight: 400,
})

export default CustomComboBox