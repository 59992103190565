import React from "react";

// Customizable Area Start
import {
  Box,
  styled,
  TextField,
  Select,
  Chip,
  InputAdornment,
  Typography,
  Snackbar,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import TransferAmountController, {
  Props,
  TransferReqIntialValues,
} from "./TransferAmountController.web";
import { colors } from "../../utilities/src/Colors";
import AutocompleteSelect from "../../../components/src/AutocompleteSelect.web";
import { sortCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";
import {
  Formik,
  FormikValues,
  FormikProps,
  FormikErrors,
  FormikTouched,
} from "formik";
import * as Yup from "yup";
export const configJSON = require("./config");

const validationSchema = Yup.object({
  amount: Yup.number()
    .typeError("Amount must be a valid number")
    .positive("Amount must be a positive number")
    .min(1, "Amount must be greater than 0")
    .required("Amount is required")
    .test(
      "not-special-characters",
      'Amount cannot be "-"',
      (value) => value !== "-"
    ),
  driver: Yup.string().required("Please select driver"),
  store: Yup.string().required("Please select store"),
});
import { ErrorLabel } from "../../promocodes/src/PromoCodeCreate.web";
// Customizable Area End

class TransferAmount extends TransferAmountController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <StyledWrapper>
          <Box className='headerWrapper'>
            <h2 className='PageHeading'>Transfer Amount</h2>
          </Box>
          <Box className='mainbox'>
            <Formik
              data-test-id='addPriceFormik'
              initialValues={this.state.addTransfReqtIntialVal}
              onSubmit={(values: TransferReqIntialValues) => {
                this.handleSubmit(values);
              }}
              validationSchema={validationSchema}
              enableReinitialize
            >
              {({
                setFieldValue,
                getFieldProps,
                handleSubmit,
                errors,
                touched,
                values
              }: FormikProps<FormikValues>) => (
                <form onSubmit={handleSubmit}>
                  <WhiteContainer>
                    <GridContainer>
                      <div>
                        <TextLabel>Transaction ID</TextLabel>
                        <StyledReadOnlyTextField
                          disabled
                          fullWidth
                          variant='outlined'
                          inputProps={{
                            readOnly: true,
                          }}
                          value={this.state.transferId}
                        />
                      </div>
                      <div>
                        <TextLabel>Store</TextLabel>
                        <StyledAutoComplete
                          error={sortCondition(Boolean(errors.store),true,false) as boolean}
                          data-test-id="storeDropdown"
                          fieldName='store'
                          placeholder={"Select Store"}
                          options={this.state.storeList}
                          value={values.store}
                          isRadioButtonShow={true}
                          onChange={(
                            _event: React.ChangeEvent,
                            value: { id: string }
                          ) => {
                            if(!value) return;
                            setFieldValue("store", value.id)
                          }}
                        />
                        <ErrorLabel>{errors.store}</ErrorLabel>
                      </div>
                      <div>
                        <TextLabel>Amount</TextLabel>
                        <StyledTextField
                          fullWidth
                          error={this.checkError(
                            errors.amount as FormikErrors<FormikValues>,
                            touched.amount as FormikTouched<FormikValues>
                          )}
                          variant='outlined'
                          inputProps={{
                            "data-test-id": "field-amount",
                          }}
                          placeholder='Type the amount'
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <EndSARTypography>SAR</EndSARTypography>
                              </InputAdornment>
                            ),
                          }}
                          {...getFieldProps("amount")}
                        />
                        <ErrorLabel>{errors.amount}</ErrorLabel>
                      </div>
                      <div>
                        <TextLabel>Driver</TextLabel>
                        <StyledAutoComplete
                          error={sortCondition(Boolean(errors.driver),true,false) as boolean}
                          data-test-id="driverDropdown"
                          fieldName='driver'
                          placeholder={"Select Driver"}
                          options={this.state.driverList}
                          value={values.driver}
                          isRadioButtonShow={true}
                          onChange={(
                            _event: React.ChangeEvent,
                            value: { id: string }
                          ) => {
                            if(!value) return;
                            setFieldValue("driver", value.id)
                          }}
                        />
                        <ErrorLabel>{errors.driver}</ErrorLabel>
                      </div>
                    </GridContainer>
                    <ReasonGridContainer>
                      <div>
                        <TextLabel>Reason</TextLabel>
                        <StyledTextField
                          fullWidth
                          variant='outlined'
                          inputProps={{
                            "data-test-id": "field-promoCode",
                          }}
                          placeholder='Type the reason of transaction'
                          {...getFieldProps("reason")}
                        />
                      </div>
                    </ReasonGridContainer>
                  </WhiteContainer>

                  <BottomContainer>
                    <button
                      onClick={() => this.handleRedirect()}
                      data-test-id='btn-cancel'
                      className='cancel'
                    >
                      Cancel
                    </button>
                    <button
                      type='submit'
                      data-test-id='btn-submit'
                      className='add'
                      disabled={this.state.isLoading}
                    >
                      Submit
                    </button>
                  </BottomContainer>
                </form>
              )}
            </Formik>
          </Box>
          <Snackbar
            anchorOrigin={{
              vertical: configJSON.top,
              horizontal: configJSON.center,
            }}
            autoHideDuration={2000}
            open={this.state.snackbar.open}
            onClose={this.handleCloseSnackbar}
          >
            <Alert severity={this.state.snackbar.severity}>
              {this.state.snackbar.message}
            </Alert>
          </Snackbar>
        </StyledWrapper>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledWrapper = styled("div")({
  "& .headerWrapper": {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginBottom: 12,
    alignItems: "center",
  },
  "& .PageHeading": {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: colors().darkjunglegreen,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
    },
  },
  "& .mainbox": {
    paddingBottom: "50px",
  },
});

export const WhiteContainer = styled("div")({
  borderRadius: 12,
  background: "#FFF",
  padding: "24px 32px",
  boxShadow:
    "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
});

const GridContainer = styled("div")({
  display: "grid",
  gridGap: 32,
  gridTemplateColumns: "1fr 1fr",
});

const ReasonGridContainer = styled("div")({
  display: "grid",
  gridGap: 32,
  gridTemplateColumns: "1fr",
  marginTop: "25px",
});

const TextLabel = styled("label")({
  display: "block",
  fontSize: 16,
  lineHeight: "24px",
  fontWeight: 600,
  paddingBottom: 4,
  color: "var(--neutrals-cool-gray-700, #334155)",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "13px",
  },
});

export const StyledTextField = styled(TextField)({
  marginTop: "10px",
  border: "1px solid var(--border, #ECECEC)",
  "& .MuiOutlinedInput-root":{
    borderRadius: 8
  },
  "@media only screen and (max-width: 1024px)": {
    "& .MuiInputBase-input": {
      fontSize: "12px",
      padding: "14.9px 14px",
    },
  },
});

const StyledAutoComplete = styled(AutocompleteSelect)({
  borderRadius: 8,
  border: "1px solid var(--border, #ECECEC)",
  marginTop: "10px",
  "@media only screen and (max-width: 1024px)": {
    "& .MuiInputBase-input": {
      fontSize: "12px"
    },
    "& .MuiOutlinedInput-root":{
      padding:"4px"
    }
  },
});

const StyledReadOnlyTextField = styled(TextField)({
  borderRadius: 8,
  border: "1px solid var(--border, #ECECEC)",
  "& .MuiOutlinedInput-root":{
    borderRadius: 8
  },
  marginTop: "10px",
  "& .MuiInputBase-input": {
    background: colors().disableColor,
    color: colors().slateGray,
  },
  "@media only screen and (max-width: 1024px)": {
    "& .MuiInputBase-input": {
      fontSize: "12px",
      padding: "14.9px 14px",
      background: colors().disableColor,
      color: colors().slateGray,
    },
  },
});

export const StyledSelect = styled(Select)({
  borderRadius: 8,
  border: "1px solid var(--border, #ECECEC)",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "12px",
    height: "48px",
  },
});

const StyledChip = styled(Chip)({
  "&.MuiChip-root": {
    marginBottom: "2px",
    color: "#204B9C",
    textTransform: "capitalize",
    backgroundColor: "#204B9C1A",
  },
});

const PlaceholderText = styled("div")({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "22px",
  fontFamily: "Montserrat",
  color: "var(--neutrals-dark-gray, #4D4D4D)",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "12px",
  },
});

export const EndSARTypography = styled(Typography)({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "22px",
  color: colors().darkliver,
  "@media only screen and (max-width: 1024px)": {
    fontSize: "12px",
  },
});

const BottomContainer = styled("div")({
  gap: 32,
  display: "flex",
  paddingTop: 48,
  paddingBottom: 50,
  "& > button": {
    fontSize: 16,
    padding: 16,
    minWidth: 126,
    border: "none",
    fontWeight: 700,
    borderRadius: 8,
    cursor: "pointer",
    lineHeight: "24px",
    alignItems: "center",
    display: "inline-flex",
    justifyContent: "center",
    fontFamily: "Montserrat",
    "&:disabled": {
      opacity: 0.5,
    },
  },
  "& > .add": {
    color: "white",
    background: "var(--primary, #204B9C)",
    "@media only screen and (max-width: 1024px)": {
      width: "126px",
      height: "44px",
    },
  },
  "& > .cancel": {
    color: "#64748B",
    background: "var(--primary, #F1F5F9)",
    "@media only screen and (max-width: 1024px)": {
      width: "126px",
      height: "44px",
    },
  },
});

export default TransferAmount;
// Customizable Area End
