import React from "react";
// Customizable Area Start
import {
  Box,
  Modal,
  Typography,
  styled,
  Accordion, 
  AccordionSummary, 
  AccordionDetails,
  Button,
  FormControlLabel,
  Checkbox,
  Snackbar
} from "@material-ui/core";
import {
  createTheme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import PriceListViewController, { CatalogueVariantService, configJSON } from "./PriceListViewController.web";

const Strings = configJSON.Strings.priceCodeListView;

import { style } from "./PriceListStyles";
import { CollapseIcon, deleteIcon, plusIcon } from "./assets";
import { StyledTextField } from "../../../components/src/customComponents/CustomTags.web";
import { CustomSearchableMultiSelect } from "../../../components/src/customComponent/CustomSearchableMultiSelect.web"
import { StyledMenuItem } from "../../promocodes/src/PromoCodeCreate.web";
import { StyledCheckBox } from "../../../components/src/CustomTheme.web";
import { Alert } from "@material-ui/lab";
import {
  renderBaseOnConditions
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";
const theme = createTheme({});

const modalStyle = {
  position: "absolute",
  top: "49%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: '1200px',
  height: "auto",
  bgcolor: "#fff",
  borderRadius: 12,
  boxShadow: 24,
  padding: "8px 2px 8px 8px",
  "@media (max-width: 1200px)": {
    padding: "20px",
    width: "75%"
  }
};
// Customizable Area End

class PriceListView extends PriceListViewController {
  // Customizable Area Start
  serviceWithPrice(classes: { productPrices: string; serviceIcon: string; productSeparator: string; }, services: CatalogueVariantService[], measurments: string[]) {
    return <>
      {services.map((item, index: number) => {
        let price: string | number = 0;
        if(Number(item.sqm_price) && measurments.includes("SQM")) {
          price = (Number(item.sqm_price) / Number(item.sqm)).toFixed(2);
        } else if(Number(item.weight_price) && measurments.includes("Weight")) {
          price = (Number(item.weight_price) / Number(item.weight)).toFixed(2);
        }else if(Number(item.price)) {
          price = Number(item.price);
        } 
        return (<>
          <Typography className={classes.productPrices}>
            <span><img src={item.icon} className={classes.serviceIcon} alt="service-icon"/></span>
            <span>SAR</span>&nbsp;
            <span>{price}</span>
          </Typography>
          {services.length - 1 !== index && <span className={classes.productSeparator}>|</span>}
        </>)
      })}
    </>
  }

  editableServiceWithPrice(classes: { productPrices: string; serviceIcon: string; productPricesInput: string; productSeparator: string | undefined; }, services: CatalogueVariantService[], productId: number) {
    return <>
      {services.map((item, index) => {
        return (<>
          <Typography className={classes.productPrices}>
          <span><img src={item.icon} className={classes.serviceIcon} alt="service-icon"/></span>
            <StyledTextField
              fullWidth
              inputProps={{ "data-test-id": `service-price-${item.id}` }}
              value={item.price}
              variant='outlined'
              className={classes.productPricesInput}
              placeholder={Strings.discountPlaceHolder}
              error={this.handleCheckValueIsNotEmpty(item.price)}
              onChange={(event) => {
                if (
                  /^[0-9\b.]+$/.test(event.target.value) ||
                  event.target.value === ""
                ) {
                  this.handleServiceChange(item.id, productId, event.target.value);
                  this.handleProductIdForUpdateService(productId);
                }
              }}
            />
          </Typography>
          {services.length - 1 !== index && <span className={classes.productSeparator}>|</span>}
        </>)
      })}
    </>
  }

  handleSelectAllMenu(isChecked: boolean, callBackSelectall: Function) {
    return (<StyledMenuItem
      value={"selectAll"}
      key={"select-item-select-all-option"}
      onClick={() => { callBackSelectall(isChecked) }}
      data-test-id={"field-select-all-products"}
    >
      <StyledCheckBox checked={isChecked} />
      Select All
    </StyledMenuItem>);
  }

  handleDeactivateProductsCondition(checkCondition: boolean) {
    if (checkCondition) {
      return this.handleDeactivateProducts()
    }
    return this.handleDeactivateClick()
  }

  handlePricelistSaveCondition(checkCondition: boolean) {
    if (checkCondition) {
      return this.handlePriceListSave()
    }
    return this.handlePriceListEdit()
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const classes = this.props.classes;
    const { expanded, viewPriceListModal, permissionStatus } = this.state;
    const { 
      name,
      price_list_type,
      price_list_to_copy,
      tax_type,
      sections,
      add_by
     } = this.state.viewPriceListItem.attributes;
    // Customizable Area End

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Modal
          data-test-id="viewPriceListModal"
          open={viewPriceListModal}
          onClose={this.handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <CustomLoader loading={this.state.isLoading}/>
            <Box className={classes.PriceModalWrapper}>
              <Typography className={classes.viewPriceHeader}>
                {Strings.pageTitle}
              </Typography>
              <ShadowBox>
                <GridContainer>
                  <Box>
                    <HeadingText>{Strings.priceListName}</HeadingText>
                    <TextValue>{name}</TextValue>
                  </Box>
                  <Box>
                    <HeadingText>{Strings.priceListType}</HeadingText>
                    <TextValue>{price_list_type}</TextValue>
                  </Box>
                  <Box>
                    <HeadingText>{Strings.priceListCopy}</HeadingText>
                    <TextValue>{price_list_to_copy}</TextValue>
                  </Box>
                  <Box>
                    <HeadingText>{Strings.taxType}</HeadingText>
                    <TextValue>{tax_type.replace(/_/g, " ")}</TextValue>
                  </Box>
                  <Box>
                    <HeadingText>{Strings.section}</HeadingText>
                    <div className={classes.TagContainer}>
                      {
                        sections.data.length > 0 ? sections.data.map(
                          (item) => <TagItem key={item.id}>{item.attributes.section_name}</TagItem>
                        )
                        :
                        <TextValue>{Strings.noSection}</TextValue>
                      }
                    </div>
                  </Box>
                  <Box>
                    <HeadingText>{Strings.addedBy}</HeadingText>
                    <TextValue>{add_by}</TextValue>
                  </Box>
                </GridContainer>
              </ShadowBox>
              <Accordion
                data-test-id="accordian"
                expanded={expanded} 
                className={`${classes.Accordion} ${!expanded && classes.boxShadowNone}`}
                onChange={this.handleAccordianChange}
              >
                <AccordionSummary
                  className={`${classes.AccordionSummary} ${!expanded && classes.CloseAccordionSummary}`}
                  aria-controls="panel-content"
                  id="panel-header"
                >
                  <div className={classes.SummaryInner}>
                    <Typography className={classes.ProductHeading}>
                      {Strings.product}
                    </Typography>
                    <img 
                      className={expanded ? classes.CollapseIcon : classes.RotateCollapseIcon} 
                      src={CollapseIcon} 
                      alt="CollapseIcon"
                    />
                  </div>
                </AccordionSummary>
                <AccordionDetails className={classes.AccordionDetails}>
                  <ProductGridContainer>
                    {
                      this.state.priceListProductList.length > 0 && <>{this.state.priceListProductList.map((item) => {
                        return(
                          <StyledBox>
                            {this.state.isDeactivate && <Box component="span">
                              <FormControlLabel
                                checked={this.state.selectedProductForDeactivate.includes(item.id)}
                                control={
                                  <Checkbox
                                    data-test-id={`products-checkbox-${item.id}`}
                                    onClick={() => {this.handleQueueForDeactivation(item.id)}}
                                  />
                                }
                                label={''}
                              />
                            </Box>}
                            <div className={classes.ProductItem} key={item.id} data-test-id={`products-${item.id}`}>
                              <div className={classes.ProductImg}>
                                <img src={item.image} alt="ProductIcon" />
                              </div>
                              <Typography className={classes.ProductName}>{item.name}</Typography>
                              {this.state.isPriceListEdit ? this.editableServiceWithPrice(classes, item.catalogue_variants_services, item.id) : this.serviceWithPrice(classes, item.catalogue_variants_services, item.measurement_type)}
                            </div>
                            <Box component="span" className={classes.delete_icon} data-test-id={`delete-product-${item.id}`} onClick={() => { this.deleteProducts(item.id) }}>
                              <img src={deleteIcon} alt="delete_icon" />
                            </Box>
                          </StyledBox>
                          )
                        }
                      )}
                      </>
                    }
                    {!this.state.isPriceListEdit && <Box className={classes.addProductButton}>
                      {this.state.isAddProductClicked ?
                        <CustomSearchableMultiSelect
                          dataTestId="productDropdowns"
                          checkSelectAllText={(updatedValue: { id: string | number, option: string }[]) => { this.checkSelectAllText(updatedValue) }}
                          isOnBlurFunction={() => { this.handleAddNewProduct() }}
                          value={this.state.selectedForAdd}
                          optionList={this.state.productListItemsDropdown}
                          placeHolderText={"Select Product"}
                          handleScroll={this.handleScrollProductDropdown}
                          handleClickOnSelectAll={() => { this.handleClickOnSelectAll() }}
                          debouncedFunction={(getValue: string) => { this.debouncedFunction(getValue) }}
                          handleSelectOptions={(value: { id: string | number, option: string }[], option: { id: string | number, option: string }) => { this.handleSelectOptions(value, option) }}
                          emptyDataMessage={configJSON.noProductMessage}
                          handleEmptyAutoSelectValue={() => { this.handleEmptyAutoSelectValue() }}
                          selectedOptionForList={this.state.selectedOptionForList}
                        />
                        : <Button
                          data-test-id="addProductButton"
                          className={`${classes.addButton}`}
                          onClick={this.handleAddProductButtonClick}
                        >
                          <img src={plusIcon} alt="plus-icon" />&nbsp;&nbsp;{Strings.addProduct}
                        </Button>}
                      </Box>}
                  </ProductGridContainer>
                </AccordionDetails>
              </Accordion>
              <Box className={classes.actionButtons}>
                <Button
                  data-test-id="cancelModal"
                  className={classes.CancelBtn}
                  onClick={this.handleCloseModal}
                >
                  {Strings.cancel}
                </Button>
                {
                  renderBaseOnConditions(
                    permissionStatus.deactivatePermission,
                    <Button
                      data-test-id="deactivateModal"
                      className={`${classes.CancelBtn} ${this.state.isDeactivate && classes.containedButton}`}
                      onClick={() => {this.handleDeactivateProductsCondition(this.state.isDeactivate)}}
                      disabled={this.state.isDeactivate && this.state.selectedProductForDeactivate.length === 0}
                    >
                      {Strings.deactivate}
                    </Button>,
                    <></>
                  )
                }
                {
                  renderBaseOnConditions(
                    !this.state.isDeactivate,
                      renderBaseOnConditions(
                        permissionStatus.editPermission,
                        <Button
                          data-test-id="editButton"
                          className={`${classes.addButton} ${classes.mt_32}`}
                          onClick={() => { this.handlePricelistSaveCondition(this.state.isPriceListEdit) }}
                        >
                          {this.state.isPriceListEdit ? Strings.save : Strings.edit}
                        </Button>,
                        <></>
                      ),
                    <></>
                  )
                }
              </Box>
            </Box>
            <Snackbar
              open={this.state.isSnackbarOpen}
              autoHideDuration={2000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              onClose={() => { this.handleCloseSnackbar() }}
            >
              <Alert
                onClose={() => { this.handleCloseSnackbar() }}
                severity={"success"}
              >
                {this.state.snackbarMessage}
              </Alert>
            </Snackbar>
          </Box>
        </Modal>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const ShadowBox = styled(Box)({
  width: "100%",
  padding: 32,
  background: "#FFF",
  marginBottom: 32,
  borderRadius: 12,
  boxShadow:
    "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
});
const ProductGridContainer = styled(Box)({
  width: "100%",
  padding: "16px 22px 16px 16px",
  maxHeight: "340px",
  overflow: "auto",
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gridRowGap: "12px",
  gridColumnGap: "24px",
  "@media (max-width: 600px)": {
    gridTemplateColumns: "1fr",
  }
});
const GridContainer = styled(Box) ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gridRowGap: "24px",
  gridColumnGap: "32px",
  "@media (max-width: 600px)": {
    gridTemplateColumns: "1fr",
  }
});
const HeadingText = styled(Typography) ({
  fontSize: 18,
  paddingBottom: 12,
  fontWeight: 600,
  lineHeight: "22px",
  color: "var(--dark-grey, #4D4D4D)",
  fontFamily: "Montserrat",
});
const TagItem = styled(Typography) ({
  fontSize: 14,
  fontWeight: 600,
  color: "#204B9C",
  padding: "4px 8px",
  borderRadius: "8px",
  lineHeight: "18px",
  backgroundColor: "rgba(32, 75, 156, 0.1)",
  fontFamily: "Montserrat",
});
const TextValue = styled(Typography) ({
  fontSize: 14,
  fontWeight: 500,
  lineHeight: "22px",
  color: "var(--basic-black, #000)",
  fontFamily: "Montserrat",
});
export default withStyles(style)(PriceListView);

export const SelectAllButton = styled(Button) ({
  fontSize: "10px",
  fontWeight: 500,
  textTransform: "capitalize",
  padding: "2px",
  position: "absolute",
  right: 15,
  top: 15,
  zIndex: 99999,
  height: 'auto'
});

const StyledBox = styled(Box) ({
  display: 'flex',
  gap:"15px",
  alignItems: 'center',
});

// Customizable Area End

