// Customizable Area Start
import React from "react";
import BusinessAccountsModalController, {
  Props,
  configJSON,
} from "./BusinessAccountsModalController.web";
import {
  styled,
  Box,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import { MainButtonComponent } from "../../../components/src/customComponents/CustomTags.web";
import {
  TableRow,
  TableCell,
  OrderTable,
  RowContainer,
} from "../../../components/src/OrderTable/src";
import { renderBaseOnConditions } from "../../../blocks/utilities/src/CustomBlockHelpers";
const IconSortingUp = require("../../../components/src/assets/sort/sort top.png");
const IconSortingNormal = require("../../../components/src/assets/sort/sort.png");
const IconSortingDown = require("../../../components/src/assets/sort/sort down.png");
// Customizable Area End

// Customizable Area Start
const Strings = configJSON.strings.BusinessAccountModal;

const staticObj = {
  company: "Saudi Industries Co.",
  subcompany: "Exploration Division",
  businessCustomer: "CUS-445673",
  businessAccountNo: "BA-N001-EXPL",
  period: "20/11/2024 to 20/11/2025",
  orders: "1,00,000",
  pieces: "2,00,000",
};
const arrays = Array(11).fill(staticObj);
// Customizable Area End

export default class BusinessAccountModal extends BusinessAccountsModalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  returnValueWithHandleNull = (value: null | string) => {
    if (value) {
      return <Typography className='table-data'>{value}</Typography>;
    } else {
      return <Typography className='table-data'>---</Typography>;
    }
  };

  handleReturnPeriod = (startDate: string | null, endDate: string | null) => {
    if (startDate && endDate) {
      return (
        <Box className='period-wrapper'>
          <Box className='start-wrapper'>
            <Typography className='table-data'>{startDate}</Typography>
            <Typography className='table-data'>to</Typography>
          </Box>
          <Typography className='table-data'>{endDate}</Typography>
        </Box>
      );
    } else {
      return <Typography>---</Typography>;
    }
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { isModalOpen } = this.props;
    const { dataLoading, businessAccountList } = this.state;
    console.log("businessAccountList::", businessAccountList)
    // Customizable Area End
    return (
      // Customizable Area Start
      <BusinessAccountDialog open={isModalOpen} maxWidth={"lg"}>
        <DialogContent>
          <Typography className={"dialog-heading"}>
            {Strings.businessAccounts}
          </Typography>
          <Box
            className={"table-container"}
            data-test-id={"table-container-scroll-div"}
          >
            <OrderTable>
              <RowContainer header>
                <TableRow columns={"1fr 1fr 1fr 1fr 1fr 1fr 1fr"}>
                  {[
                    {
                      title: Strings.tableString_company,
                      sortingKey: "company",
                    },
                    {
                      title: Strings.tableString_subCompany,
                      sortingKey: "sub_company",
                    },
                    {
                      title: Strings.tableString_businessCustomer,
                      sortingKey: "business_customer",
                    },
                    {
                      title: Strings.tableString_businessAccountNo,
                      sortingKey: "business_customer",
                    },
                    {
                      title: Strings.tableString_period,
                      sortingKey: "business_start_date",
                    },
                    {
                      title: Strings.tableString_noOfTotalOrders,
                      sortingKey: "business_customer",
                    },
                    {
                      title: Strings.tableString_noOfTotalOPieces,
                      sortingKey: "business_customer",
                    },
                  ]
                    .filter((item: { title: string }) => {
                      return item.title;
                    })
                    .map(
                      ({
                        title,
                        sortingKey,
                      }: {
                        title: string;
                        sortingKey?: string;
                      }) => (
                        <TableCell
                          header
                          key={title}
                          content={title}
                          className={"f-16 align-item-center"}
                          sortingKey={sortingKey}
                          sortingIcons={[
                            IconSortingNormal,
                            IconSortingUp,
                            IconSortingDown,
                          ]}
                          sortingDisabled={!Boolean(sortingKey)}
                          {...this.getSortingProps()}
                        />
                      )
                    )}
                </TableRow>
              </RowContainer>
              {renderBaseOnConditions(
                dataLoading,
                <RowContainer>
                  <Box className={"tableBodyLoader"}>
                    <CircularProgress />
                  </Box>
                </RowContainer>,
                <>
                  {renderBaseOnConditions(
                    businessAccountList.length > 0,
                    <RowContainer>
                      {businessAccountList.map((item) => {
                        return (
                          <TableRow
                            key={item.id}
                            className={"table-rows"}
                            columns={"1fr 1fr 1fr 1fr 1fr 1fr 1fr"}
                          >
                            {[
                              this.returnValueWithHandleNull(
                                item.attributes.company.name
                              ),
                              this.returnValueWithHandleNull(
                                item.attributes.sub_company_name
                              ),
                              this.returnValueWithHandleNull(
                                item.attributes.business_customer
                              ),
                              this.returnValueWithHandleNull(
                                item.attributes.business_customer
                              ), // show business customer id
                              this.handleReturnPeriod(
                                item.attributes.business_start_date,
                                item.attributes.business_end_date
                              ),
                              this.returnValueWithHandleNull(
                                item.attributes.sub_company_name
                              ), // show pieces
                              this.returnValueWithHandleNull(
                                item.attributes.sub_company_name
                              ), // show orders
                            ].map((cellData, index) => {
                              return (
                                <TableCell
                                  key={`cell-${index}`}
                                  style={{ wordBreak: "break-all" }}
                                  content={cellData as React.ReactNode}
                                  className={"f-12"}
                                  type={this.getTypeOfOrderTable(index)}
                                />
                              );
                            })}
                          </TableRow>
                        );
                      })}
                    </RowContainer>,
                    <RowContainer>
                      <Box className={"w-100"}>
                        <Typography className={"noDataFound"}>
                          {Strings.tableString_noAccountsFound}
                        </Typography>
                      </Box>
                    </RowContainer>
                  )}
                </>
              )}
            </OrderTable>
          </Box>
          <DialogActions className={"dialogActionWrapper"}>
            <MainButtonComponent
              data-test-id={"btn-cancel"}
              className={"buttonSecondary"}
              onClick={this.props.onCloseModal}
            >
              {Strings.cancel}
            </MainButtonComponent>
          </DialogActions>
        </DialogContent>
      </BusinessAccountDialog>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const BusinessAccountDialog = styled(Dialog)({
  "& .MuiDialog-paperScrollPaper": {
    width: "100%",
    borderRadius: "24px",
    "@media only screen and (max-width: 1024px)": {
      maxWidth: "792px",
    },
  },
  "& .MuiDialogContent-root": {
    padding: "24px",
    display: "flex",
    flexDirection: "column",
  },
  "& .dialog-heading": {
    fontFamily: "Montserrat",
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: 600,
    letterSpacing: "-0.5%",
    textAlign: "center" as const,
    margin: "0 0 24px 0",
  },
  "& .dialogActionWrapper": {
    margin: "24px 0 0 0",
    justifyContent: "center",
  },
  "& .tableBodyLoader": {
    textAlign: "center",
    paddingBottom: 15,
  },
  "& .w-100": {
    width: "100%",
  },
  "& .noDataFound": {
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    textAlign: "center",
    padding: "8px 0 12px 0",
  },
  "& .period-wrapper": {
    display: "flex",
    flexDirection: "column",
  },
  "& .start-wrapper": {
    display: "flex",
    gap: "10px",
  },
  "& .table-data": {
    fontFamily: "Montserrat",
    fontSize: "13px !important",
    lineHeight: "16px",
    fontWeight: 500,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "12px",
    lineHeight: "15px",
    },
  },
});
// Customizable Area End
