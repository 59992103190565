import React from "react";
import {
  // Customizable Area Start
  Box,
  Modal,
  Table,
  Paper,
  Button,
  styled,
  Checkbox,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  Tooltip, 
  withStyles
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import PrintModalController, { Props } from "./PrintModalController";
import SortingTableCell from "../../../components/src/SortingTableCell";
import SortingTableHeader from "../../../components/src/SortingTableHeader";
import SortingTableCellWrap from "../../../components/src/SortingTableCellWrap";

// Customizable Area End

export default class PrintModal extends PrintModalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  showCatalogueName = (fullName: string) => {
      if (fullName.length >= 10) {
        return (
          <CatalogueTooltip
            title={<p className='catalogue-name'>{fullName}</p>}
            arrow
          >
            <span className='catalogue-name'>{fullName.slice(0, 11)}...</span>
          </CatalogueTooltip>
        );
      } else {
        return <p className='catalogue-name'>{fullName}</p>;
      }
    };
  

  renderPrint = () => {
    return (
      <TableContainer component={Paper}>
        <Table style={webStyle.tableArea} aria-label='customized table'>
          <TableHead>
            <TableRow style={{ background: "#204B9C", color: "#FFFFFF" }}>
              {this.getPrintColsName().map((item) => {
                return (
                  <SortingTableHeader
                    sortingData={{}}
                    title={item.title}
                    sortingKey=''
                    sortingDisabled
                    type={item.type as "left" | "middle" | "right"}
                    width={item.width}
                  />
                );
              })}
            </TableRow>
          </TableHead>
          {this.props.order.order_items.length > 0 && (
            <TableBody>
              <TableRow>
                <TableCell colSpan={3} />
                <TableCell colSpan={3}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginLeft: "-15px",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      onClick={() => this.onSelectAllCheckbox()}
                      checked={
                        this.state.selectedItems.length ===
                        this.props.order.order_items.reduce(
                          (paccumulator, currentValue) => paccumulator + currentValue.attributes.tag_numbers.length,
                          0
                        )
                      }
                      data-test-id={"selectAll-checkbox"}
                    />
                    <div style={{ fontWeight: 600 }}>Select All</div>
                  </div>
                </TableCell>
              </TableRow>
              {this.props.order.order_items.reduce((paccumulator: any, rowData, index) => {
                return [
                  ...paccumulator,
                  ...rowData.attributes.tag_numbers.map((tagNum) => {
                    return (
                      <TableRow key={index + "TableRow"}>
                        <SortingTableCell
                          width='22vw'
                          type='left'
                          content={rowData.attributes.catalogue.product_name}
                        />
                        <SortingTableCell width='22vw' content={tagNum} />
                        <SortingTableCell
                          width='22vw'
                          content={rowData.attributes.notes}
                        />
                        <SortingTableCell
                          width='22vw'
                          type='right'
                          style={{
                            marginTop: 4,
                            marginBottom: 4,
                          }}
                          content={
                            <div>
                              <div
                                style={{
                                  marginTop: "-10px",
                                  marginBottom: "-13px",
                                }}
                              >
                                <Checkbox
                                  checked={this.state.selectedItems.includes(
                                    rowData.id + "-" + tagNum
                                  )}
                                  data-test-id={"select-item"}
                                  onClick={() =>
                                    this.onSelectCheckbox(rowData.id, tagNum)
                                  }
                                />
                              </div>
                            </div>
                          }
                        />
                      </TableRow>
                    );
                  }),
                ];
              }, [])}
            </TableBody>
          )}
        </Table>
        {this.props.order.order_items.length === 0 && (
          <div
            style={{
              fontSize: 16,
              width: "100%",
              textAlign: "center",
              fontWeight: 602,
            }}
          >
            No records found!!
          </div>
        )}
      </TableContainer>
    );
  };

  renderInfo = () => {
    return (
      <TableContainer component={Paper}>
        <Table style={webStyle.tableArea} aria-label='customized table'>
          <TableHead>
            <TableRow style={{ background: "#204B9C", color: "#FFFFFF" }}>
              <SortingTableHeader
                sortingData={{}}
                title={"Item"}
                sortingKey=''
                sortingDisabled
                type={"left"}
                width={"22vw"}
              />
              <SortingTableHeader
                sortingData={{}}
                title={"Service"}
                sortingKey=''
                width={"22vw"}
                sortingDisabled
              />
              <SortingTableHeader
                sortingData={{}}
                title={"No. of Items"}
                sortingKey=''
                width={"22vw"}
                sortingDisabled
              />
              <SortingTableHeader
                sortingData={{}}
                title={"No. of Pieces"}
                sortingKey=''
                width={"22vw"}
                sortingDisabled
              />
              <SortingTableHeader
                sortingData={{}}
                title={"No. of Tags"}
                sortingKey=''
                width={"22vw"}
                sortingDisabled
              />
              <SortingTableHeader
                sortingData={{}}
                title={"Tags No"}
                sortingKey=''
                width={"22vw"}
                sortingDisabled
              />
            </TableRow>
          </TableHead>
          {this.props.order.order_items.length > 0 && (
            <StyledTableBody>
              {this.props.order.order_items.map((rowData, index) => (
                <TableRow key={index + "TableRow"}>
                  <SortingTableCellWrap
                    width='22vw'
                    type='left'
                    content={this.showCatalogueName(rowData.attributes.catalogue.product_name)}
                  />
                  <SortingTableCellWrap
                    width='22vw'
                    content={
                      <div
                        style={{
                          display: "flex",
                          gap: "8px",
                          alignItems: "center",
                        }}
                      >
                        <img
                          style={{ width: 20, height: 20 }}
                          src={
                            rowData.attributes.service.attributes.icon.data
                              .attributes.image
                          }
                        />
                        <div>{rowData.attributes.service.attributes.name}</div>
                      </div>
                    }
                  />
                  <SortingTableCellWrap
                    width='22vw'
                    content={rowData.attributes.quantity}
                  />
                  <SortingTableCellWrap
                    width='22vw'
                    content={rowData.attributes.no_of_pieces}
                  />
                  <SortingTableCellWrap
                    width='22vw'
                    content={rowData.attributes.no_of_tags}
                  />
                  <SortingTableCellWrap
                    width='22vw'
                    content={rowData.attributes.tag_numbers.join(", ")}
                    type='right'
                  />
                </TableRow>
              ))}
            </StyledTableBody>
          )}
        </Table>
        {this.props.order.order_items.length === 0 && (
          <div
            style={{
              fontSize: 16,
              width: "100%",
              textAlign: "center",
              fontWeight: 602,
            }}
          >
            No records found!!
          </div>
        )}
      </TableContainer>
    );
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <Modal
        data-test-id='addPriceModal'
        open={this.props.open}
        onClose={() => {
          this.props.onClose && this.props.onClose();
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        className='MuiModal-root'
      >
        <>
          <Box sx={modalStyle}>
            <div
              style={{
                borderRadius: "12px",
                border: "1px solid #DEDEDE",
                width: "100%",
                marginBottom: 24,
              }}
            >
              <div
                style={{
                  width: "100%",
                  borderBottom: "1px solid #DEDEDE",
                  padding: 16,
                  fontWeight: 600,
                  fontFamily: "Montserrat",
                  fontSize: "20px",
                }}
              >
                Order # {this.props.order.order_number}
              </div>
              <div
                style={{
                  display: "flex",
                  padding: 16,
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ maxWidth: "220px" }}>
                  <div
                    style={{
                      marginBottom: 12,
                      fontWeight: 600,
                      fontFamily: "Montserrat",
                      fontSize: 16,
                    }}
                  >
                    Created
                  </div>
                  <div style={{ fontSize: 14 }}>
                    <div>
                      {this.getCreatedDate()}
                    </div>
                    <div>
                      By{" "}
                      {this.props.order.order_created_by.first_name +
                        this.props.order.order_created_by.last_name}
                    </div>
                    <div>{this.props.order.store_name}</div>
                  </div>
                </div>
                <div style={{ paddingRight: 124 }}>
                  <div
                    style={{
                      marginBottom: 12,
                      fontWeight: 600,
                      fontFamily: "Montserrat",
                      fontSize: 16,
                    }}
                  >
                    Payment
                  </div>
                  <div>
                    SAR {this.props.order.total}{" "}
                    <span style={{ fontWeight: 600 }}>
                      {this.props.order.paid_at ? "PAID" : "UNPAID"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {!this.props.infoView ? this.renderPrint() : this.renderInfo()}
            {this.props.infoView ? (
              <>
                <TableHead style={{ marginTop: "8px" }}>
                  <TableRow style={{ background: "orange", color: "#FFFFFF" }}>
                    <SortingTableHeader
                      fullRadius
                      backgroundColor='#EC4E20'
                      sortingData={{}}
                      title={"Total"}
                      sortingKey=''
                      sortingDisabled
                      type={"left"}
                      width={"22vw"}
                    />
                    <SortingTableHeader
                      fullRadius
                      backgroundColor='#EC4E20'
                      sortingData={{}}
                      title={""}
                      sortingKey=''
                      width={"22vw"}
                      sortingDisabled
                    />
                    <SortingTableHeader
                      fullRadius
                      backgroundColor='#EC4E20'
                      sortingData={{}}
                      title={this.props.order.no_of_items}
                      sortingKey=''
                      width={"22vw"}
                      sortingDisabled
                    />
                    <SortingTableHeader
                      fullRadius
                      backgroundColor='#EC4E20'
                      sortingData={{}}
                      title={this.props.order.no_of_pieces}
                      sortingKey=''
                      width={"22vw"}
                      sortingDisabled
                    />
                    <SortingTableHeader
                      fullRadius
                      backgroundColor='#EC4E20'
                      sortingData={{}}
                      title={this.props.order.no_of_pieces}
                      sortingKey=''
                      width={"22vw"}
                      sortingDisabled
                    />
                    <SortingTableHeader
                      fullRadius
                      backgroundColor='#EC4E20'
                      sortingData={{}}
                      title={""}
                      sortingKey=''
                      width={"22vw"}
                      sortingDisabled
                      type='right'
                    />
                  </TableRow>
                </TableHead>

                <div>
                  <Button
                    data-test-id='btn-print-modal-cancel'
                    onClick={() => this.props.onClose && this.props.onClose()}
                    style={webStyle.cancelButton}
                  >
                    Cancel
                  </Button>
                </div>
              </>
            ) : (
              <div style={{ display: "flex", gap: 24 }}>
                <Button
                  data-test-id='btn-print-modal-cancel'
                  onClick={() => this.props.onClose && this.props.onClose()}
                  style={webStyle.cancelButton}
                >
                  Cancel
                </Button>
                <Button style={webStyle.saveButton}>Print</Button>
              </div>
            )}
          </Box>
        </>
      </Modal>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const modalStyle = {
  padding: "25px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "880px",
  maxHeight: "92%",
  bgcolor: "#fff",
  borderRadius: 24,
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  overflowY: "hidden",
  "@media (max-width: 768px)": {
    padding: "20px",
    width: "75%",
  },
};

export const webStyle = {
  tableArea: {
    minWidth: 700,
  },
  cancelButton: {
    color: "rgba(100, 116, 139, 1)",
    backgroundColor: "#F1F5F9",
    fontWeight: 700,
    fontSize: 16,
    fontFamily: "Montserrat",
    textTransform: "none",
    paddingLeft: 44,
    paddingRight: 44,
    marginTop: 32,
  } as React.CSSProperties,
  saveButton: {
    color: "white",
    backgroundColor: "#204B9C",
    fontWeight: 700,
    fontSize: 16,
    fontFamily: "Montserrat",
    textTransform: "none",
    paddingLeft: 44,
    paddingRight: 44,
    marginTop: 32,
  } as React.CSSProperties,
};

const StyledTableBody = styled(TableBody)({
  "& > tr > td": {
    height: 60,
    "&:last-child > div": {
      width: "unset !important",
    },
  },
});

export const CatalogueTooltip = withStyles({
  tooltip: {
    fontSize: "12px",
    color: "black",
    backgroundColor: "white",
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 8px 32px 0px, rgba(0, 0, 0, 0.03) 0px 4px 8px 0px",
  },
  arrow: {
    color: "white",
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 8px 32px 0px, rgba(0, 0, 0, 0.03) 0px 4px 8px 0px",
  },
})(Tooltip);

// Customizable Area End
