// Customizable Area Start
import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Loader from "../../../components/src/Loader.web";
import {
  Box,
  styled
} from "@material-ui/core";
export const configJSON = require("./config");

import AddInviteFriendsController, {
  FormInteface,
  FormMode,
  Props,
} from "./AddInviteFriendsController.web";
import DropdownMenu from "../../../components/src/DropdownMenu.web";
import { colors } from "../../utilities/src/Colors";
import { MainGridView } from "./ViewInviteFriends.web";
// Customizable Area End

// Customizable Area Start
export const StylesGridWrapper = styled(Grid)({
  marginBottom: "20px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});
export const PageTitle = styled('div')({
  fontStyle: "normal",
  fontWeight: 600,
  fontFamily: "Montserrat",
  fontSize: 24,
  marginTop: 32,
  color:colors().black,
  "@media only screen and (max-width: 1024px)": {
    fontStyle: "normal",
    fontFamily: "Montserrat",
    color:colors().viewTextColor,
    fontWeight: 600,
    fontSize: "16px",
  },
})
const StyledFormLable = styled('p')({
  fontFamily: "Montserrat",
  fontSize: "16px",
  fontStyle: "normal",
  color:colors().viewTextColor,
  marginBottom: "8px",
  fontWeight: 600,
  lineHeight: "20px",
  marginTop: "24px",
  "@media only screen and (max-width: 1024px)": {
    fontStyle: "normal",
    fontFamily: "Montserrat",
    color:colors().charcoal,
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "20px",
  },
});

const StyledSAR = styled('span')({
  paddingRight: "12",
  fontSize: "1rem",
  fontWeight: 500, 
  "@media only screen and (max-width: 1024px)": {
    fontWeight: 500,
    fontSize: "14px",
  },
});


export const StyledTextField = styled(TextField)({
  borderRadius: 8,
  border: "1px solid var(--border, #ECECEC)",
  width: "90%",
  "@media only screen and (max-width: 1024px)": {
    '& .MuiInputBase-input': {
      fontSize: '12px',
      padding:'14.9px 14px'
    },
  },
});

const SecondaryButtonBox = styled('div')({
    width: "126px",
    height: "56px",
    border: "none",
    fontSize: '16px',
    borderRadius: '8px',
    fontWeight: 700,
    cursor: 'pointer',
    textTransform: "none" as 'none',
    backgroundColor: colors().antiFlashWhite,
    color: colors().slateGray,
    padding:"16px 0",
    marginRight: "32px",
    textAlign:"center",
    "@media only screen and (max-width: 1024px)": {
      width: 128,
      height: 44,
      padding:"12px 0",
    }
});

const PrimaryButtonBox = styled('div')({
    width: "128px",
    height: "56px",
    border: "none",
    fontSize: '16px',
    borderRadius: '8px',
    fontWeight: 700,
    cursor: 'pointer',
    textTransform: "none" as 'none',
    backgroundColor: colors().cyancobaltblue,
    color: colors().background,
    padding:"16px 0",
    textAlign:"center",
    "@media only screen and (max-width: 1024px)": {
      width: 128,
      height: 44,
      padding:"12px 0",
    }
});
const StyledMainBox = styled(Box)({
 paddingBottom:"25px",
  "@media only screen and (max-width: 1024px)": {
    paddingBottom:"0px",
  }
});
// Customizable Area End

export class AddInviteFriends extends AddInviteFriendsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  handleInputChange(event: React.ChangeEvent<HTMLInputElement>, type: any, key: any)  {
    let inputValue = event.target.value;
    this.onFormElementChangeFn({ ...event, target: { ...event.target, value: inputValue } }, key);
  }
  renderTextInput(key: keyof FormInteface, placeholder: string, type: string) {
    const { form, formErrors } = this.state;
    return (
      <StyledTextField
      type={type}
      InputProps={{
        startAdornment: (
          <StyledSAR>
            SAR
          </StyledSAR>
        ),
      }}
        data-test-id={key}
        className={formErrors[key] ? 'errorInput' : "withoutError"}
        value={form[key]}
        color='primary'
        variant='outlined'
        name={key}
        placeholder={placeholder}
        onChange={(ev:React.ChangeEvent<HTMLInputElement>) => this.handleInputChange(ev, type, key)}
      />
    );
  }

  renderInputText(
    key: keyof FormInteface,
    label: string,
    placeholder: string,
    leftOne: boolean,
    type: string
  ) {
    const { formErrors } = this.state;

    return (
      <>
        <Grid item xs={12} sm={6} className={leftOne ? 'paddingRightClass' : ""}>
          <FormControl style={{ width: "100%" }}>
            <StyledFormLable>{label}</StyledFormLable>
            {this.renderTextInput(key, placeholder, type)}
            {formErrors[key] && (
              <Typography className='errorMsg2' variant='caption' style={{ color: "red" }}>
                {formErrors[key]}
              </Typography>
            )}
          </FormControl>
        </Grid>
      </>
    );
  }

  renderPageTitle = (formMode: any) => {
    return <PageTitle>{(formMode === FormMode.Create ? '' : 'Edit ') + configJSON.title}</PageTitle>;
  };

  // Customizable Area End

  render() {
    // Customizable Area Start

    const { isSubmitting, formMode } = this.state;
    let buttonText;

    if (isSubmitting) {
      buttonText = <CircularProgress size={16} color='inherit' />;
    } else {
      buttonText = configJSON.save;
    }

    return (
      <>
        <Loader loading={this.state.isLoading} />
          <Grid direction='column' className={'pageContainer'}>
          <StyledMainBox>
            <StylesGridWrapper
              direction='row'
            >
              {this.renderPageTitle(formMode)}
            </StylesGridWrapper>
            <MainGridView container direction='column' className={'formContainer'}>
              <Grid container direction='row'>
                <Grid item xs={12} sm={6} style={{paddingRight: "32px"}}>
                  <FormControl style={{ width: "100%" }}>
                    <StyledFormLable>{configJSON.selectAllow}</StyledFormLable>
                    <Box>
                      <DropdownMenu
                        ignoreWidth={true}
                        width="95%"
                        placeholder="Select Option"
                        data-test-id="allow_customer_referrals"
                        items={this.state.customerReferralDropdownOptions}
                        onSelect={(e) => this.handleSelect(e.id)}
                        errorProp={this.state.formErrors['allow_customer_referrals'] ? true : false}
                        selectedItem={this.state.customerReferralDropdownOptions.find((x: any) => x.id == this.state.form.allow_customer_referrals)}
                      />
                    </Box>
                  </FormControl>
                  {this.state.formErrors['allow_customer_referrals'] && <Typography className='errorMsg2' variant='caption' style={{ color: "red" }}>{configJSON.pleaseSelectOneOption}</Typography>}
                </Grid>

              </Grid>
              <Grid container direction='row'>
                {this.renderInputText("discount_amount", configJSON.inputExistingCredit, "Enter Number", true, 'number')}
                {this.renderInputText("new_customer_discount", configJSON.inputNewCredit, "Enter Number", true, 'number')}
              </Grid>
            </MainGridView>

            <Grid container direction='row' style={{ marginTop: 48 }}>
              <SecondaryButtonBox
                data-test-id={"cancelBtn"}
                onClick={this.handleCancelClickFn}
              >
                {configJSON.cancel}
              </SecondaryButtonBox>

              <PrimaryButtonBox
                data-test-id={"btn-submit"}
                onClick={this.handleOnSubmit}                
              >
                {buttonText}
              </PrimaryButtonBox>
            </Grid>
          </StyledMainBox>
          </Grid>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackbarOpen}
            autoHideDuration={4000}
            onClose={this.handleSnackbarCloseFn}
          >
            <Alert severity={this.state.alertServerity === "success" ? "success" : "error"}>{this.state.snackbarMessage}</Alert>
          </Snackbar>
      </>
    );
    // Customizable Area End
  }
}

export default AddInviteFriends;
