import React from "react";
// Customizable Area Start
import { Button, Box, Grid,styled } from "@material-ui/core";

import CfCompanyInfoController, { Props, configJSON } from "./CfCompanyInfoController";
import { renderBaseOnConditions } from "../../../blocks/utilities/src/CustomBlockHelpers";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";
import { StyledCancelButton } from "./CfCompanyInfo.web";


export default class CompanyView extends CfCompanyInfoController {
  constructor(props: Props) {
    super(props);
  }

  renderText = (title: string, value: string | undefined) => {
    return (
      <Grid item xs={12} md={6} >
        {title && (
          <>
            <div className={'textHeader'}>{title.charAt(0).toUpperCase() + title.slice(1)}</div>
            <div className={'textContent'}>{value || "--"}</div>
          </>
        )}
      </Grid>
    );
  };

  renderSelect = (
    title: string,
    selectOptions: { text: string; value: string }[] | string[],
    value: string | undefined
  ) => {
    let fixedSelectOptions: { text: string; value: string }[] = [];
    if (typeof selectOptions[0] === "string") {
      selectOptions = selectOptions as string[];
      fixedSelectOptions = selectOptions.map((option: string) => ({ text: option, value: option }));
    } else {
      fixedSelectOptions = selectOptions as { text: string; value: string }[];
    }

    return (
      <Grid item xs={12} md={6}>
        {title && (
          <>
            <div className={'textHeader'}>{title.charAt(0).toUpperCase() + title.slice(1)}</div>
            <div className={'textContent'}>
              {fixedSelectOptions.find((option) => option.value === value)?.text || "--"}
            </div>
          </>
        )}</Grid>
    );
  };

  renderOthersColumn = (
    columnKey: string,
    columnValue: string | { title: string; isSelected: boolean }[]
  ) =>
    Array.isArray(columnValue)
      ? this.renderSelect(
          columnKey,
          columnValue.map((item) => item.title),
          columnValue.find((item) => item.isSelected)?.title
        )
      : this.renderText(columnKey, columnValue);

  renderOtherFields = () => {
    const otherFieldKeys = Object.keys(this.state.companyInfo.attributes.others);
    const returnElement = [];

    for (let i = 0; i < otherFieldKeys.length; i += 2) {
      const key1 = otherFieldKeys[i];
      const key2 = otherFieldKeys[i + 1];
      const value1 = this.state.companyInfo.attributes.others[key1];
      const value2 = this.state.companyInfo.attributes.others[key2];
      returnElement.push(
        <>
          {this.renderOthersColumn(key1, value1)}
          {key2 ? this.renderOthersColumn(key2, value2) : this.renderText("", "")}
        </>
      );
    }

    return returnElement;
  };
  renderVatViewDetails = () => {
    const returnVal = [];
    const renderVatViewInput = (index: number) => {
      if (index >= 0) {
        return this.renderText(
          configJSON.vatName + " " + (index + 1),
          this.state.companyInfo.attributes.vat_details_attributes[index].tax_calculator_name
        );
      } else {
        return this.renderText("", "");
      }
    };
    const renderSingleViewRow = (index: number) => (
      <div className={'rowContainer'}>
        {renderVatViewInput(index)}
        {renderVatViewInput(-1)}
      </div>
    );

    const renderViewRow = (index: number) => (
      <div className={'rowContainer'}>
        {renderVatViewInput(index)}
        {renderVatViewInput(index + 1)}
      </div>
    );

    if (this.state.companyInfo.attributes.vat_details_attributes.length === 2)
      returnVal.push(renderSingleViewRow(1));
    else if (this.state.companyInfo.attributes.vat_details_attributes.length === 3)
      returnVal.push(renderViewRow(1));
    else if (this.state.companyInfo.attributes.vat_details_attributes.length === 4) {
      returnVal.push(renderViewRow(1));
      returnVal.push(renderSingleViewRow(3));
    }

    return returnVal;
  };

  render() {
    return (
      <WebStyledBox className={'mainWrapper'}>
        <CustomLoader loading={this.state.isLoading} />
          <div className={'mainContainer'}>
            <div className={'headerWrapper'}>
              <div className={'pageHeader'}>{configJSON.companyHeader}</div>
             {renderBaseOnConditions(this.state.permissionStatus.editPermission, <Button
                data-test-id='addCompany'
                onClick={this.handleEdit}
                style={{ textTransform: "unset" }}
                className="addButton"
              >
                {configJSON.editCompany}
              </Button>,<></>)}
            </div>
            <Grid container style={{margin:'15px 0px'}}  spacing={2} className={'formContainer'}>
                {this.renderText(configJSON.companyName, this.state.companyInfo.attributes.name)}
                {renderBaseOnConditions(
                  !this.state.isB2bActive,
                  this.renderText(configJSON.companyNameArabic, this.state.companyInfo.attributes.name_arabic),
                  this.state.companyInfo.attributes.parent_company ? this.renderText(configJSON.B2BSubCompanyView, this.state.companyInfo.attributes.parent_company.data.attributes.name): ""
                )}
                {this.renderText(
                  configJSON.registrationNumber,
                  this.state.companyInfo.attributes.registration_no
                )}
                {this.renderSelect(
                  "Country",
                  this.state.countries.map((country) => ({
                    text: country.name,
                    value: country.id + "",
                  })),
                  this.state.companyInfo.attributes.country_id + ""
                )}
                {this.renderText(configJSON.tinNo, this.state.companyInfo.attributes.tin_no)}
                {this.renderText("Telephone", this.state.companyInfo.attributes.telephone)}
                {this.renderText("E-Mail Address", this.state.companyInfo.attributes.email)}
                {this.renderText("Address", this.state.companyInfo.attributes.address)}
                {renderBaseOnConditions(
                  !this.state.isB2bActive,
                  this.renderText("VAT Number", this.state.companyInfo.attributes.vat_number),
                  <></>
                )}
                 {renderBaseOnConditions(
                  !this.state.isB2bActive,
                  this.renderText("VAT Number (Arabic)", this.state.companyInfo.attributes.vat_number_arabic),
                  <></>
                )}
              {this.renderOtherFields()}
            </Grid>

            <div className={'formContainer'}>
              <div style={{ marginBottom: "20px" }} className="vatHeader">
                {configJSON.vatDetails}
              </div>
              <Grid container spacing={4} >
                {this.renderSelect(
                  configJSON.numberOfVat,
                  ["1", "2", "3", "4"],
                  this.state.companyInfo.attributes.number_of_vats
                )}
                {this.renderText(
                  configJSON.vatName + " 1",
                  this.state.companyInfo.attributes.vat_details_attributes[0].tax_calculator_name
                )}
                {this.renderVatViewDetails()}
              </Grid>
            </div>
            <div style={{ display: "flex", gap: "32px", marginTop: "22px", marginBottom: "26px",paddingBottom:'50px' }}>
              <StyledCancelButton
                data-test-id='backButton'
                onClick={this.handleBack}
                size='medium'
                className="saveButton"
                color='secondary'
              >
                {configJSON.back}
              </StyledCancelButton>
            </div>
          </div>
          
      </WebStyledBox>
    );
  }
}

const WebStyledBox = styled(Box)({
  '& .mainContainer': {
    marginTop: 32,
    fontFamily: 'Montserrat',
  },
  '& .formContainer': {
    padding: 32,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)',
    borderRadius: '12px',
  },
  '& .vatHeader': {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '24px',
    alignItems: 'center',
    letterSpacing: '-0.3px',
    color: '#1A1A1A',
    "@media only screen and (max-width: 1024px)": {
      fontSize: '16px',
    }
  },
  '& .rowContainer': {
    display: 'flex',
    gap: '32px',
    marginBottom: '32px',
    paddingLeft:'16px'
  },
  '& .columnContainer': {
    width: '100%',
  },
  '& .addButton': {
    background: '#204B9C',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#FFFFFF',
    height: '56px',
    width: '184px',
    borderRadius: '8px',
    "@media only screen and (max-width: 1024px)": {
      height: '44px',
    }
  },
  '& .saveButton': {
    backgroundColor: '#204B9C',
    width: '128px',
    height: '56px',
    "@media only screen and (max-width: 1024px)": {
      height: '44px',
    }
  },
  '& .pageHeader': {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '29px',
    "@media only screen and (max-width: 1024px)": {
      fontSize: '16px',
    }
  },
  '& .headerWrapper': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '& .mainWrapper': {
    marginTop: '32px',
    fontFamily: 'Montserrat',
  },
  '& .textContent': {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '22px',
    color: '#1A1A1A',
    marginTop: '12px',
    "@media only screen and (max-width: 1024px)": {
      fontSize: '13px',
    }
  },
  '& .textHeader': {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '22px',
    color: '#4D4D4D',
    "@media only screen and (max-width: 1024px)": {
      fontSize: '16px',
    }
    
  },
});
// Customizable Area End
