import React from "react";
// Customizable Area Start
import { Box, Typography, Button, IconButton, styled } from '@material-ui/core'
import FilterIcon from '@material-ui/icons/FilterListRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import StoreList from "./StoreList.web";
import FilterPopover, { IFilter } from "../../../components/src/FilterPopover";
import CustomBarGraph from "../../../components/src/CustomBarGraph";
import CustomHBarGraph from "../../../components/src/CustomHBarGraph";
import { formatNumberWithCommas, renderBaseOnConditions, sortStringCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";
import { getStoreNameDisplay } from "../../cfsalesdashboard3/src/utils";
import TrucatedTextWithTooltip from "../../../components/src/commonComponents/TruncatedTextWithTooltip.web";
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderBanner = (title: string, bannerTxt: string) => {
    return (
      <Box className='containerStatsBox'>
        <Box>
          <Typography className='lable-16-600'>{title}</Typography>
        </Box>

        <Box className='bannerBox'>
          <TrucatedTextWithTooltip
            charCount={26}
            maxWidth="120px"
            classNames='lable-16-600'
            text={bannerTxt}
          />
        </Box>

      </Box>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { overAllSale, saleRevenue, cashInOutData, viewAllStoreFlag, storeRevenue, paymentModeRevenue } = this.state;
    return (
      <StyledWrapper>
        {!viewAllStoreFlag && <Box>

          <Box className="headingContainer">
            <Box>
              <Typography gutterBottom className="lable-16-700 darkColor">{"Overall cash & revenue"}</Typography>
              <Typography data-test-id="period" gutterBottom className="lable-16-700 darkColor">
                {"Period : "}
                <span className="lable-16-400 darkColor">
                {this.handleDateFilterParam().startDateText} _ {this.handleDateFilterParam().endDateText}
                </span>
              </Typography>
            </Box>

            <Box className="actionBtnContainer">
              <Box>
                <div data-test-id="filterButton" onClick={(event) => { this.handleFilterOpen(event) }}>
                  <IconButton className={sortStringCondition(
                    this.state.isAppliedFilter,
                    "filterBtn active-border",
                    "filterBtn"
                  )}>
                    <FilterIcon color={this.handleReturnColor()} />
                    {renderBaseOnConditions(
                      this.state.isAppliedFilter,
                      <div className="filterActiveIcon"></div>,
                      <></>
                    )}
                  </IconButton>
                </div>
                {
                  this.state.filterAnchor && (
                    <FilterPopover
                      data-test-id="filterPopover"
                      onClose={() => { this.handleCloseFilterPopover() }}
                      anchor={this.state.filterAnchor}
                      onFilterChange={this.handleFilterChangeAccount}
                      filters={this.state.filters}
                    />
                  )
                }
                
              </Box>
              <Button data-test-id="transferAmtBtn" onClick={() => this.handleNavigation("TransferAmount")} className="addTranferBtn" variant="contained" startIcon={<AddRoundedIcon />}>
                Transfer Amount
              </Button>
            </Box>
          </Box>

          <Box className='section2'>
            {[
              { title: "Overall Sales", value: `SAR ${formatNumberWithCommas(overAllSale)}` },
              { title: "Sale revenue", value: `SAR ${formatNumberWithCommas(saleRevenue.total_revenue)}` },
              { title: "Expected cash", value: `SAR ${formatNumberWithCommas(cashInOutData.cash_in)}` },
              { title: "Cash Out", value: `SAR ${formatNumberWithCommas(cashInOutData.cash_out)}` },
            ].map((banner, index) => (
              <React.Fragment key={index}>
                {this.renderBanner(banner.title, banner.value)}
                {index === 1 && <Box className='dividerVerticalLine' />}
              </React.Fragment>
            ))}
          </Box>

          <Box className='section3'>
            <Box className='revenueConatainer'>
              <Typography className='lable-16-700'>Sales revenue</Typography>

              <Box className='listingDetailsCont'>
                <Box className='listContainer'>
                  <Box>
                    <Typography className='lable-13-600'>Laundry</Typography>
                    <Typography className='lable-13-500'>{this.getSelectedFilterLabel()}({formatNumberWithCommas(saleRevenue.laundry_revenue.orders)})</Typography>
                  </Box>
                  <Box className='bannerBox-sm'>
                    <TrucatedTextWithTooltip
                      charCount={26}
                      maxWidth="100px"
                      classNames='lable-13-600'
                      text={`SAR ${formatNumberWithCommas(saleRevenue.laundry_revenue.total)}`}
                    />
                  </Box>
                </Box>

                <Box className='listContainer'>
                  <Box>
                    <Typography className='lable-13-600'>Home cleaning</Typography>
                    <Typography className='lable-13-500'>{this.getSelectedFilterLabel()}({formatNumberWithCommas(saleRevenue.home_cleaning_revenue.orders)})</Typography>
                  </Box>
                  <Box className='bannerBox-sm'>
                    <Typography className='lable-13-600'>{`SAR ${formatNumberWithCommas(saleRevenue.home_cleaning_revenue.total)}`}</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <CustomBarGraph BarData={storeRevenue.map(item => ({ label: item.store_name, value: Number(item.amount) }))} Height={"198px"} customSubHeader={getStoreNameDisplay(this.state.storeId)} />

            <CustomHBarGraph BarData={
              paymentModeRevenue.map(item => ({ label: item.payment_method_name, value: item.total_amount, quantity: item.total_quantity }))
            } Height={"198px"} />

          </Box>

          <Box className="section4-table">
            <Box className="tableHeadingContainer">
              <Typography className="lable-16-700">{"Store list"}</Typography>
              <Box className="actionBtnContainer">
                <Button onClick={this.handleExportCsv} variant="contained" className="exportAllBtn">Export all</Button>
                <Button onClick={() => this.handleStoreViewAllPage(true)} data-test-id="storeViewAllBtn" variant="contained" className="viewAllBtn">View All</Button>
              </Box>
            </Box>

            <StoreList id={""} navigation={""} exportCsvFlag={this.state.exportCsvFlag} listLimit={3} onlyTable={true} goBack={() => this.handleStoreViewAllPage(false)} filterData={this.handleDateFilterParam().dateFilterQuery} filterExport={this.handleDateFilterParam().dateFilterQuery.replace("&", '?')} refeshStoreList={this.state.refershStoreList} />

          </Box>

        </Box>}

        {viewAllStoreFlag && <StoreList receivedData={this.state.receivedDataSaved} id={""} navigation={""} goBack={(filter:IFilter[]) => this.handleStoreViewAllPage(false,filter)} filterValue={this.state.filters[0].value as string} />}
      </StyledWrapper>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledWrapper = styled(Box)({
  "& .darkColor":{
    color:"#0F172A"
  },
  "& .exportAllBtn": {
    backgroundColor: "#E2E8F0",
    boxShadow:"none",
    textTransform: "none",
    height: "44px",
    fontSize: "16px",
    fontWeight: 600,
    borderRadius: "8px",
    color: "#4D4D4D"
  },
  "& .viewAllBtn": {
    boxShadow:"none",
    backgroundColor: "#204B9C",
    textTransform: "none",
    height: "44px",
    fontSize: "16px",
    fontWeight: 700,
    borderRadius: "8px",
    color: "#fff"
  },
  "& .tableHeadingContainer": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  "& .section4-table": {
    marginTop: "20px"
  },
  "& .relative": {
    position: "relative"
  },
  "& .columnBarContainerBody": {
    width: "100%",
    padding: "0 10px",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "flex-end"
  },
  "& .columnBarBase": {
    position: "absolute",
    width: "100%",
    bottom: "9px"
  },
  "& .mw40": {
    minWidth: "30px"
  },
  "& .columnBar": {
    width: "8px",
    borderRadius: "4px 4px 0px 0px"
  },
  "& .columnGraphContainer": {
    display: "flex",
    flexDirection: "column",
    gap: "18px",
    marginTop: "20px"
  },
  "& .columnLimitContainer": {
    display: "flex",
    alignItems: "center",
    gap: "10px"
  },
  "& .columnLimitLine": {
    borderRadius: "1px",
    width: "100%",
    height: "1px",
    background: "#CBD5E1"
  },
  "& .graphLegendsContainer": {
    marginTop: "20px",
    display: "flex",
    gap: "10px"
  },
  "& .graphLegendsBody": {
    display: "flex",
    gap: "5px",
    alignItems: "center"
  },
  "& .LegendIcon": {
    width: "12px",
    height: "12px",
    borderRadius: "4px"
  },
  "& .barContainer": {
    display: "flex",
    flexDirection: "column",
    gap: "10px"
  },
  "& .paymentOptionContainer": {
    padding: "8px 16px",
    boxSizing: "border-box",
    boxShadow: "0px 2px 8px 0px #00000014",
    width: "504px"
  },
  "& .paymentSummaryHeaderContainer": {

  },
  "& .barsWrapper": {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    marginTop: "20px"
  },
  "& .barLabel": {
    display: "flex",
    gap: "10px",
    alignSelf: "end"
  },
  "& .barBody": {
    borderRadius: "10px",
    height: "3px",
    width: "100%",
    backgroundColor: "#E2E8F0"
  },
  "& .barDynamic": {
    borderRadius: "10px",
    height: "3px"
  },

  "& .headingContainer": {
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  "& .lable-16-700": {
    fontSize: "16px",
    fontFamily: "Montserrat",
    fontWeight: 700
  },
  "& .lable-16-600": {
    fontSize: "16px",
    fontFamily: "Montserrat",
    fontWeight: 600
  },
  "& .lable-16-400": {
    fontSize: "16px",
    fontFamily: "Montserrat",
    fontWeight: 400
  },
  "& .lable-13-600": {
    fontSize: "13px",
    fontFamily: "Montserrat",
    fontWeight: 600
  },
  "& .lable-13-500": {
    fontSize: "13px",
    fontFamily: "Montserrat",
    fontWeight: 500
  },
  "& .lable-14-400": {
    fontSize: "14px",
    fontFamily: "Montserrat",
    fontWeight: 400
  },
  "& .lable-11-500": {
    fontSize: "11px",
    fontFamily: "Montserrat",
    fontWeight: 500
  },
  "& .lable-12-400": {
    fontSize: "12px",
    fontFamily: "Montserrat",
    fontWeight: 400
  },
  "& .actionBtnContainer": {
    display: "flex",
    gap: "24px"
  },
  "& .addTranferBtn": {
    backgroundColor: "#204B9C",
    boxShadow:"none",
    textTransform: "none",
    height: "44px",
    fontSize: "16px",
    fontWeight: 700,
    borderRadius: "8px",
    color: "#fff",
    "& .MuiButton-iconSizeMedium > :first-child": {
      fontSize: "24px"
    }
  },
  "& .filterBtn": {
    position: "relative",
    height: "44px",
    borderRadius: "8px",
    backgroundColor: "#fff",
    border: "1px solid #ECECEC",
    "& .MuiSvgIcon-root": {
      color: "black"
    },
    "&.active-border": {
      border: "1px solid #204B9C",
    },
    "& .filterActiveIcon": {
      position: "absolute",
      top: "-5px",
      right: "-5px",
      minWidth: "15px",
      background: "#204B9C",
      minHeight: "15px",
      borderRadius: "50%"
    }
  },
  "& .section2": {
    marginTop: "20px",
    display: "flex",
    justifyContent: "space-between"
  },
  "& .containerStatsBox": {
    width: "302px",
    height: "120px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "0px 2px 8px 0px #00000014",
    overflow: "hidden",
    padding: "20px 15px",
    boxSizing: "border-box"
  },
  "& .graphContainer": {
    position: "relative"
  },
  "& .pieHoleText": {
    position: "absolute",
    top: "60px",
    right: "50px"
  },
  "& .bannerBox": {
    width: "133px",
    height: "51px",
    background: "#E9EDF5",
    display: "grid",
    borderRadius: "4px",
    placeItems: "center"
  },
  "& .bannerBox-sm": {
    width: "auto",
    minHeight: "28px",
    padding: "0 10px",
    borderRadius: "4px",
    background: "#E9EDF5",
    display: "grid",
    placeItems: "center"
  },
  "& .dividerVerticalLine": {
    background: "#E2E8F0",
    width: "1px"
  },
  "& .section3": {
    marginTop: "20px",
    padding: "16px",
    boxSizing: "border-box",
    boxShadow: "0px 2px 8px 0px #00000014",
    minheight: "348px",
    height:"auto",
    width: "100%",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "space-between"
  },
  "& .revenueConatainer": {
    padding: "16px",
    width: "15%",
    minWidth: "240px",
    height: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column"
  },
  "& .listingDetailsCont": {
    marginTop: "50px",
    display: "flex",
    gap: "40px",
    flexDirection: "column",
  },
  "& .listContainer": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  "& .avgStoreRvConatainer": {
    width: "40%",
    overflow: "hidden"
  },
  "& .coolGrey": {
    color: "#64748B"
  },
})
// Customizable Area End
