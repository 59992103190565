import React from 'react'
// Customizable Area Start
import * as Yup from "yup";
import { MenuProps } from "@material-ui/core";
import { ISearchResult } from './AdvancedSearchController';
import { OrderPermissionStatus } from '../../../blocks/utilities/src/CustomBlockHelpers';
const configJSON = require("./config");
export const getGridColumnSpan = (isSpan: boolean | undefined) => {
  return isSpan ? { gridColumnStart: 5, gridColumnEnd: 7 } : {};
};

export const getOrderType = (selectedSearchType: string | undefined, FieldArray: React.ComponentType<React.HTMLAttributes<HTMLDivElement>>, FieldArrayCustomer: React.ComponentType<React.HTMLAttributes<HTMLDivElement>>) => {
  return selectedSearchType === "Orders" ? FieldArray : FieldArrayCustomer;
};

export const checkBusinessLabel = (label: string) => {
  switch (label) {
    case "in_store":
      return "All Orders";
    case "cleaning":
      return "Awaiting Clean";
    case "ready":
      return "Awaiting Collection";
    case "coming_to_store":
      return "Coming to Store";
    default:
      return label
  }
};

export const checkOrderBusinessLabel = (label: string) => {
  switch (label) {
    case "in_store":
      return "In Store";
    case "cleaning":
      return "Cleaning";
    case "ready":
      return "Ready";
    case "coming_to_store":
      return "Coming to Store";
    case "send_to_deadstock":
      return "Send to Deadstock"
    case "delivered": 
      return "Delivered"
    case "cancelled":
       return "Cancelled"
    case "request_to_driver":
       return "Request to Driver"
    default:
      return label
  }
};

export const checkValues = (
  value: Date | [Date | null, Date | null] | null | undefined | any
) => {
  return value ? value : null;
};

export const getComponentByCondition = (condition: boolean, componentA: React.ReactNode, componentB: React.ReactNode) =>{
  return condition ? componentA : componentB
}

export interface IRenderCustomTag {
  tabId: number;
  handleTabChange: Function;
}

export const validationSchema = Yup.object({
  paid_date_range: Yup.array().of(Yup.date()).when(["paid", "paid_date"], {
    is: (paid, paid_date) => paid !== undefined && paid_date === undefined,
    then: Yup.array().of(Yup.date()).required(configJSON.requiredField)
  }),
  placed_date_range: Yup.array().of(Yup.date()).when(["placed", "placed_date"], {
    is: (placed, placed_date) => placed !== undefined && placed_date === undefined,
    then: Yup.array().required(configJSON.requiredField)
  }),
  paid_date: Yup.date().when("paid", {
    is: value => value !== undefined,
    then: Yup.date().required(configJSON.requiredField)
  }),
  placed_date: Yup.date().when("placed", {
    is: value => value !== undefined,
    then: Yup.date().required(configJSON.requiredField)
  }),
});

export const CustomMenuProps: Partial<MenuProps> = {
  PaperProps: {
    style: { maxHeight: 250 },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

export interface CreditSearchProps {
  creditNoteOrder?: ISearchResult;
  creditNotePresent?: boolean;
  isCreditNoteDialogOpen: boolean;
  creditNoteReceipt: string;
  hidden: boolean;
  addNote: string;
  isConfirmationChecked: boolean;
  creditLoader: boolean;
  openCrediNoteModalLoader: boolean;
  renderCreditOrderNumber: Function;
  handleAddNote: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  hanldeClickPrintCreditNote: Function;
  handleConfirmationCheckBox: () => void;
  toggleCreditNoteDialog: Function;
  createCreditNoteApiCall: Function;
};

export interface CreditNoteButtonProps {
  toggleCreditNoteDialog: Function;
  item: ISearchResult;
  permissionStatus: OrderPermissionStatus;
}

// Customizable Area End
