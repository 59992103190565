// Customizable Area Start
import React from "react";
import ViewBusinessAccountController, {
  BusinessAccount,
  Props,
} from "./ViewBusinessAccountController";
import {
  styled,
  Box,
  Chip
} from "@material-ui/core";
import { toString } from "lodash";
import { colors } from "../../utilities/src/Colors";
import { conditionalString, renderBaseOnConditions, sortStringCondition } from "../../utilities/src/CustomBlockHelpers";
import { phoneIcon, regionIcon } from "./assets";
import NumberOfPiecesPerOrder from "../../../components/src/customComponents/NumberOfPiecesPerOrder.web";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";
// Customizable Area End

// Customizable Area Start
type BusinessAccountField = {
  label: string
  fieldName: keyof BusinessAccount
}
const ACCOUNT_FIELDS: Array<BusinessAccountField> = [
  { label: "Company Name", fieldName: "company_name" },
  { label: "Sub-Company Name", fieldName: "sub_company_name" },
  { label: "Business Customer", fieldName: "business_customer" },
  { label: "Business Account No.", fieldName: "business_account_no" },
  { label: "Business Address", fieldName: "business_address" },
  { label: "Contact Person", fieldName: "contract_person" },
  { label: "Cost Center", fieldName: "cost_center" },
  { label: "Sub-Cost Center", fieldName: "sub_cost_center" },
  { label: "Email ID", fieldName: "email" },
  { label: "Phone Number", fieldName: "phone_no" },
  { label: "Purchase Order", fieldName: "purchase_order" },
  { label: "Sales Order", fieldName: "sales_order" },
  { label: "Business Start Date", fieldName: "business_start_date" },
  { label: "Business End Date", fieldName: "business_end_date" },
]

const SETTING_FIELDS: Array<BusinessAccountField> = [
  { label: "Region", fieldName: "regions" },
  { label: "Area", fieldName: "areas" },
  { label: "Store", fieldName: "stores" },
  { label: "Auto Renewal", fieldName: "auto_renewal" },
  { label: "Available Times", fieldName: "available_time" },
  { label: "Available Days", fieldName: "available_days" }
]
// Customizable Area End

export default class ViewBusinessAccount extends ViewBusinessAccountController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderGroup = (group: BusinessAccount["groupConfigs"][number]) => {
    return (
      <StyledBox>
        <p className="box-title">{group.business_customer_group.group_name}</p>
        <div className="box-content">
          {this.renderField("Price List", toString(group.price_list?.name))}
          <div className="fieldContainer">
            <div className="fieldLabel">Items</div>
            <div className="fieldValue">
              <NumberOfPiecesPerOrder
                items={group.item_pics_configurations}
                unit={group.pieces_period_type}
                onDone={() => { }}
                isView
              />
            </div>
          </div>
        </div>
        <div className="box-content">
          {this.renderField(`No. of Order (per ${group.order_period_type})`, group.no_of_order)}
          {this.renderField(
            `No. of Pieces (per ${group.pieces_period_type})`,
            group.item_pics_configurations
              .reduce((accumulator, currentItem) => accumulator + (currentItem.no_of_pieces || 0), 0)
              .toLocaleString("en-US")
          )}
        </div>
      </StyledBox>
    )
  }

  renderList = (listData: Array<string>) => (
    <Box display="flex" gridGap={5} flexWrap="wrap">
      {
        listData.map((item) => (
          <StyledChip key={item} label={item} size='small' />
        ))
      }
    </Box>
  )

  renderField = (title: string, value: string | string[]) => {
    return (
      <div className="fieldContainer">
        <div className="fieldLabel">{title.charAt(0).toUpperCase() + title.slice(1)}</div>
        <div className="fieldValue">{Array.isArray(value) ? this.renderList(value) : sortStringCondition(Boolean(value), value, "--")}</div>
      </div>
    );
  };

  parseFieldAndRender = (title: string, fieldName: keyof BusinessAccount) => {
    const { accountData } = this.state
    const value = accountData[fieldName]

    return this.renderField(title, value as string | string[]);
  }

  renderPaper = (attributes: Array<BusinessAccountField>, content: React.ReactNode = null) => (
    <Box className="view-container">
      {
        attributes.map(field => (
          <React.Fragment key={field.fieldName}>
            {this.parseFieldAndRender(field.label, field.fieldName)}
          </React.Fragment>
        ))
      }
      {content}
    </Box>
  )
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { accountData, loading } = this.state
    // Customizable Area End
    return (
      // Customizable Area Start
      <StyledWrapper>
        <CustomLoader loading={loading} />
        <div className="header-wrapper">
          <h3 className="page-title">Business Account</h3>
          {renderBaseOnConditions(this.state.permissionStatus.editPermission,
            <button
            type="button"
            className='action-btn'
            onClick={this.goToEdit}
          >
            Edit
          </button>,<></>
          )}
        </div>
        {this.renderPaper(
          ACCOUNT_FIELDS,
          renderBaseOnConditions(
            Boolean(accountData.contract_manager_name),
            <div className="contract-manager-box">
              <p>Contract Manager</p>
              <div className="contract-manager-first-col">
                <div className="contract-manager-avatar">
                  {accountData.contract_manager_name.charAt(0)}
                </div>
                <div className="contract-manager-info">
                  <p>{accountData.contract_manager_name}</p>
                  <p>{accountData.contract_manager_email}</p>
                </div>
              </div>
              <div className="contract-manager-info">
                <div><img src={phoneIcon} />{accountData.contract_manager_phone_no}</div>
                <div><img src={regionIcon} />{accountData.contract_manager_regions}</div>
              </div>
            </div>,
            undefined
          )
        )}


        {this.renderPaper(SETTING_FIELDS)}

        {this.renderPaper(
          [
            { label: "Price List", fieldName: "catalogue" },
            { label: "No. of groups", fieldName: "no_of_groups" },
            { label: "No. of Items per Order", fieldName: "no_of_items_per_order" },
            { label: "No. of Pieces per Order", fieldName: "no_of_pieces_per_order" },
            { 
              label: "No. of Items (Contract)" + conditionalString(accountData.order_period_type_for_contract, ` - per ${accountData.order_period_type_for_contract}`, ""), 
              fieldName: "total_no_of_order_for_contract"
            },
            { 
              label: "No. of Pieces (Contract)" + conditionalString(accountData.pieces_period_type_for_contract, ` - per ${accountData.pieces_period_type_for_contract}`, ""), 
              fieldName: "total_no_of_pieces_for_contract" 
            },
          ],
          renderBaseOnConditions(
            this.state.accountData.groupConfigs.length > 0,
            <Box className="group-list">
              {this.state.accountData.groupConfigs.map(groupConfig => (
                <React.Fragment key={groupConfig.id}>
                  {this.renderGroup(groupConfig)}
                </React.Fragment>
              ))}
            </Box>,
            null
          ))
        }


        <button
          type="button"
          className='action-btn back-btn'
          data-test-id="back-btn"
          onClick={this.goBack}
        >
          Back
        </button>
      </StyledWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledWrapper = styled(Box)({
  paddingBottom: 24,
  "& .header-wrapper": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 10,
    flexWrap: "wrap",
    marginBottom: 32,
  },
  "& .page-title": {
    fontSize: 24,
    lineHeight: 2,
    fontWeight: 600,
    color: colors().viewTextColor
  },
  "& .view-container": {
    display: "grid",
    gridTemplateColumns: "0.5fr 0.5fr",
    gap: 32,
    marginBottom: 32,
    padding: "32px 24px",
    boxShadow: "0px 2px 8px 0px #00000014",
    borderRadius: "12px",
    "& .fieldContainer": {
      padding: "0 8px"
    },
    "& .fieldLabel": {
      "fontFamily": "Montserrat, sans-serif",
      fontSize: 18,
      fontWeight: 600,
      color: colors().darkliver,
      "@media only screen and (max-width: 1024px)": {
        fontSize: "13px !important"
      },
    },
    "& .fieldValue": {
      marginTop: 12,
      fontSize: 16,
      fontWeight: 500,
      color: colors().viewTextColor,
      "@media only screen and (max-width: 1024px)": {
        fontSize: "12px !important"
      },
    },
    "& .contract-manager-box": {
      backgroundColor: colors().ghostwhite,
      gridColumn: "span 2",
      padding: 16,
      display: "grid",
      gap: 24,
      borderRadius: 8,
      gridTemplateColumns: "0.5fr 0.5fr",
      "& > p": {
        gridColumn: "span 2",
        fontSize: 18,
        color: colors().darkliver,
        fontWeight: 600
      },
      "& .contract-manager-first-col": {
        display: "flex",
        gap: 16,
      },
      "& .contract-manager-avatar": {
        width: 52,
        height: 52,
        borderRadius: 100,
        textTransform: "uppercase",
        background: colors().cardHeaderUpcoming,
        border: `solid 1px ${colors().brightgray}`,
        fontSize: 14,
        fontWeight: 700,
        display: "grid",
        placeItems: "center",
        color: colors().lightPurple,
        flexShrink: 0
      },
      "& .contract-manager-info": {
        display: "flex",
        flexDirection: "column",
        gap: 8,
        fontWeight: 500,
        lineHeight: "22.4px",
        wordBreak: "break-all",
        "& > div": {
          display: "flex",
          gap: 8,
          alignItems: "center"
        },
      }
    }
  },
  "& .group-list": {
    gridColumn: "span 2",
    display: "flex",
    flexDirection: "column",
    gap: 24
  },
  "& .action-btn": {
    border: 0,
    padding: "16px 34px",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: 700,
    cursor: "pointer",
    backgroundColor: colors().cyancobaltblue,
    color: "white",
    textAlign: "center",
    "&.back-btn": {
      backgroundColor: colors().antiFlashWhite,
      color: colors().slateGray,
    },
    "@media only screen and (max-width: 1024px)": {
      "padding": "12px 34px",
    },
  }
});

const StyledBox = styled(Box)({
  borderRadius: 8,
  background: colors().ghostwhite,
  display: "flex",
  flexDirection: "column",
  gap: 24,
  padding: 16,
  "& .box-title": {
    fontSize: 18,
    fontWeight: 600,
    color: colors().darkliver
  },
  "& .box-content": {
    display: "grid",
    gridTemplateColumns: "0.5fr 0.5fr",
    gap: 24,
    padding: "0 8px",
  }
})

const StyledChip = styled(Chip)({
  "&.MuiChip-root": {
    marginBottom: "2px",
    color: "#204B9C",
    textTransform: "capitalize",
    backgroundColor: "#204B9C1A",
  },
});
// Customizable Area End