// Customizable Area Start
import React from "react";
import EditSectionController, { Props } from "./EditSectionController.web"
import "./AddProduct.css"
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Snackbar, Checkbox,styled } from "@material-ui/core";
import Alert, { Color } from '@material-ui/lab/Alert';
import { openCircle,filledCircle } from "./assets";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";
import AutocompleteSelect from "../../../components/src/AutocompleteSelect.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

class EditSection extends EditSectionController {
    constructor(props: Props) {
      super(props);
      // Customizable Area Start
      // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
      // Customizable Area Start
        const { selectedValue, severity, popUpMessage, isShowPopUp, showErrorRes, isDropDownOpen, errorMessage, isOnline, showInputError, showRadioSelectError, preferenceDetail, first_input, second_input, selectedIcon } = this.state;
        const showErrorClass = showErrorRes && 'error'
        const firstInput = showInputError && first_input === '' || showErrorClass
        const dropDownClass = showRadioSelectError && selectedValue === null && 'error';
        return (
            <>
                <CustomLoader loading={this.state.isLoading} />
                <EditProductWrapper>
                <div className="product-container">
                    <Snackbar data-test-id="snackbar-show" anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }} open={isShowPopUp} autoHideDuration={4000} onClose={this.handleClosePopUp} >
                        <Alert data-test-id="snackbar-alert" severity={severity as Color} >
                            {popUpMessage}
                        </Alert>
                    </Snackbar>
                    <h2 data-test-id="header-title" className="header-title">Edit Section</h2>
                    <div className="add-product-container">
                        <div className="left-section">
                            <div data-test-id="first-input-box" >
                                <h4 className="heading-text">Section Name</h4>
                                <input data-test-id="first-input" value={first_input} onChange={this.handleFirstInput} type="text" className={`input-field ${firstInput}`} placeholder="Enter Name" />
                                {showInputError && first_input === '' && <p className="error-message">Please enter your first name.</p>}
                                {showErrorRes && <p className="error-message">Name {errorMessage}</p>}
                            </div>
                            <div className="custom-dropdown">
                                <h4 className="heading-text">Preference</h4>
                                <div
                                    data-test-id="header-dropdown"
                                    className={`dropdown-header ${dropDownClass}`}
                                    onClick={this.toggleButton}
                                >
                                    <span>
                                        {selectedValue && (
                                            <img
                                                className="image-change-option"
                                                src={selectedValue.attributes?.gallery_icon === null ? this.state.defaultIcon : selectedValue.attributes?.gallery_icon?.image}
                                                alt={selectedValue.attributes?.preference_first_name}
                                            />
                                        )}
                                        {selectedValue ? selectedValue.attributes?.preference_first_name : "Select Preference"}
                                    </span>
                                    {isDropDownOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                </div>
                                {isDropDownOpen && (
                                    <ul data-test-id="dropdown-ul" className="dropdown-options">
                                        {preferenceDetail.map((option) => (
                                            <li data-test-id={`list-item-${option.id}`} key={option.id} onClick={() => this.handleDropDownValue(option)}>
                                                <img
                                                    style={{ width: "20px", height: "20px" }}
                                                    src={selectedValue === option ? openCircle : filledCircle}
                                                    alt={option.attributes.preference_first_name}
                                                    defaultChecked={selectedValue === option.attributes.preference_first_name as null | string}
                                                />
                                                <img className="image-change-option" src={option.attributes.gallery_icon === null ? this.state.defaultIcon : option.attributes.gallery_icon?.image} alt="icon" />

                                                <label>{option.attributes.preference_first_name}</label>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                                {showRadioSelectError && <p className="error-message">Please select a preference.</p>}
                            </div>

                            <div style={{paddingBottom: "24px"}}>

                  <FormControl style={{width: "100%"}}>
                    <FormLabel style={{ marginBottom: "8px", color: "black"}}>Icon</FormLabel>
                    <AutocompleteSelect
                        data-test-id='iconDropdown'
                        placeholder="Select icon"
                        fieldName="icon"
                        noOptionText="No icon found"
                        options={this.state.allIconsList}
                        value={selectedIcon?.id || ""}
                        disableClear
                        handleScroll={this.handleScrollIconDropdown}
                        onChange={(_event: React.ChangeEvent, value: { id: string; option: string; image:string }) => this.selectIcon(value)}
                        onBlur={() => this.debouncedFunction(this.state.selectedIcon?.option || "")}
                        debouncedFunction={(getValue: string) => { this.debouncedFunction(getValue) }}
                        listBoxStyle={{maxHeight: 260}}
                    />
                  </FormControl>

                </div>
                <div className="checkbox-container" style={{width: 'fit-content'}}>
                                <Checkbox
                              data-test-id='checkbox-select'
                              onClick={() => {
                                this.handleCheckboxChange()
                              }}
                              checked={
                                isOnline
                              }
                            />
                            Online Order
                            </div>
                        
                        </div>
                        <div className="right-section">
                            <div >
                                <h4 className="heading-text">Section Arabic Name</h4>
                                <input dir="rtl" data-test-id="input-second" type="text" value={second_input} onChange={this.handleSecondInput} className={`input-field`} placeholder="Enter Arabic Name" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="group-btn">
                    <button data-test-id="cancel-handle" onClick={this.goToListing} className="cancel">Cancel</button>
                    <button data-test-id="save-handle" onClick={this.handleSaveUpdate} className="add">Save</button>
                </div>
               </EditProductWrapper>
            </>
        );
      // Customizable Area End
    }
}

// Customizable Area Start
export default EditSection;

const EditProductWrapper = styled("div")({
    "& .header-title": {
        "@media (max-width: 1024px)": {
            fontSize: '16px',
            fontWeight: 500,
          },
        "@media (max-width: 1366px)": {
            fontSize: '18px',
            fontWeight: 600,
          },
    },
    "& .heading-text": {
        "@media (max-width: 1024px)": {
            fontSize: '14px',
            fontWeight:500
          },
        "@media (max-width: 1366px)": {
            fontSize: '16px',
          },
        
    },
    "& .cancel": {
        "@media (max-width: 1366px)": {
            height: "46px",
            width: '100px',
            fontSize: "14px",
           fontWeight: 600,
          },
          "@media (max-width: 1024px)": {
            fontSize: "16px",
           fontWeight: 500,
          },
          "@media (max-width: 768px)": {
            width: '80px',
          },
    },
    "& .add": {
        "@media (max-width: 1366px)": {
            height: "46px",
            width: '100px',
            fontSize: "14px",
           fontWeight: 600,
          },
          "@media (max-width: 1024px)": {
            fontSize: "16px",
             fontWeight: 500,
          },
          "@media (max-width: 768px)": {
            width: '80px',
          },
    },
    "& .custom-dropdown": {
        minWidth:"350px"
    },
    "& .MuiAutocomplete-root": {
        width: "50%",
        minWidth: 350,
        "& .MuiAutocomplete-endAdornment": {
            right: 18
        }
    }
})
// Customizable Area End
