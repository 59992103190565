import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  styled,
  TextField,
  Tabs,
  Tab,
  Dialog,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CustomSnackbar } from "../../../components/src/customComponents/CustomSnackbar.web";
import { colors } from "../../utilities/src/Colors";
import { trim, toString, sortBy, cloneDeep } from "lodash";
import { renderBaseOnConditions } from "../../../blocks/utilities/src/CustomBlockHelpers";
import CustomSelect from "../../../components/src/customComponents/CustomSelect.web";
import { CustomMultiSelect } from "../../../components/src/customComponent/CustomMultiSelect.web";
import { CustomSearchableMultiSelect } from "../../../components/src/customComponent/CustomSearchableMultiSelect.web";
import { PresentationCheckboxes } from "../../../components/src/customComponents/PresentationCheckboxes.web";
import CustomAccordion from "../../../components/src/customComponents/CustomAccordion.web";
import AutocompleteSelect from "../../../components/src/AutocompleteSelect.web";
import { Formik } from "formik";
import { editIcon, cancelIcon, saveIcon } from "./assets";
import { Conveyor } from "./types";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";
// Customizable Area End

import CfMetalProgettiSolutionIntegration2Controller, {
  Props,
  configJSON,
} from "./CfMetalProgettiSolutionIntegration2Controller";

export default class CfMetalProgettiSolutionIntegration2 extends CfMetalProgettiSolutionIntegration2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderRouteArmStats = (pfDestConfigs: Conveyor["pf_dest_configurations_attributes"]) => {
    return (
      <>
        <p>
          - {
            sortBy(pfDestConfigs, "route_arm_number")
              .map(item => `${item.route_arm_number} (${item.route_store_ids.length} Stores)`)
              .join(", ")
          }
        </p>
        <p>
          - Total: {pfDestConfigs.reduce((accummulator, current) => accummulator + current.route_store_ids.length, 0)} Stores
        </p>
      </>
    )
  }
  renderSharedFolder = () => this.state.openSharedFolder ? (
    <StyledDialog open onClose={() => this.toggleSharedFolderDialog()}>
      <p className="note">
        Please enter the desired location for the shared folder below.<br />
        Ensure that it is properly configured on your QZ Tray application for accessibility.<br />
        Before saving, the location will be validated, so please make sure QZ Tray is running.
      </p>
      <Box className="input-container" marginBottom={3}>
        <div>
          <p className="label">Custom Shared Folder Location</p>
          <StyledTextField
            data-test-id="shared-folder-input"
            variant="outlined"
            value={this.state.txtInputValue}
            onChange={(event) => this.setInputValue(trim(event.target.value))}
          />
        </div>
      </Box>

      <Box className="actions">
        <Button
          data-test-id='cancel-btn'
          variant="outlined"
          className="cancel-btn"
          onClick={() => this.toggleSharedFolderDialog()}
        >
          <span>Cancel</span>
        </Button>
        {renderBaseOnConditions(
          this.state.permissionStatus?.metalProgettiActionPermission as boolean,
          <Button
            className="save-btn"
            data-test-id="save-btn"
            onClick={() => this.handleSubmit()}
          >
            <span>Save</span>
          </Button>
          , <></>
        )}
      </Box>
      {
        this.state.snackbarMessage && (
          <CustomSnackbar
            data-test-id='metal-progetti-snackbar'
            open
            onClose={this.handleCloseSnackbar}
            errorMessage={this.state.snackbarMessage}
            severity="error"
            autoHideDuration={2000}
          />
        )
      }
    </StyledDialog>
  ) : null

  renderSpArmList = (formValues: Conveyor, setFieldValue: Function, isDisabled: boolean) => {
    const { sp_arms_settings_attributes, arms } = formValues
    const new_sp_arm_setting = !isDisabled ? formValues.new_sp_arm_setting : {sp_arm_number: 0}

    return (
      <Box display="flex" flexDirection="column" gridGap={10}>
        {
          sp_arms_settings_attributes.map((item, index) => (
            <div className="two-columns" key={`${item.sp_arm_number}__${index}`} data-test-id={`sp_arm_${index}`}>
              <Box display="flex" gridGap={10}>
                <CustomSelect
                  className={!isDisabled ? "hide-icon" : undefined}
                  disabled
                  options={[toString(item.sp_arm_number)]}
                  placeholder=""
                  value={toString(item.sp_arm_number)}
                  onChange={() => { }}
                />
                {
                  renderBaseOnConditions(
                    isDisabled,
                    null,
                    <Box flexShrink={0}>
                      <StyledButton
                        type="button"
                        data-test-id={`delete-btn-${index}`}
                        className="delete-btn"
                        onClick={() => this.removeSpArm(sp_arms_settings_attributes, item, setFieldValue)}
                      ><DeleteOutlineIcon /></StyledButton>
                    </Box>
                  )
                }
              </Box>
              <PresentationCheckboxes
                value={{ hf: Boolean(item.sp_arm_hf), sp: Boolean(item.sp_arm_sp), fh: Boolean(item.sp_arm_fh) }}
                fieldNames={{ hf: "sp_arm_hf", sp: "sp_arm_sp", fh: "sp_arm_fh" }}
                onChange={(fieldName, fieldValue) => {
                  const clonedSetting = cloneDeep(sp_arms_settings_attributes)
                  clonedSetting[index][fieldName as "sp_arm_hf" | "sp_arm_sp" | "sp_arm_fh"] = fieldValue
                  setFieldValue("sp_arms_settings_attributes", clonedSetting)
                }}
                disabled={isDisabled}
              />
            </div>
          ))
        }
        {
          renderBaseOnConditions(
            Boolean(arms && arms <= sp_arms_settings_attributes.length)
            || Boolean(isDisabled && sp_arms_settings_attributes.length),
            null,
            <div className="two-columns" data-test-id="new-sp-arm">
              <Box display="flex" gridGap={10}>
                <CustomSelect
                  data-test-id="sp-arm-number-select"
                  disabled={isDisabled}
                  options={this.getSpArmNumberOptions(arms, sp_arms_settings_attributes)}
                  placeholder=""
                  value={toString(new_sp_arm_setting?.sp_arm_number)}
                  onChange={(event) => setFieldValue("new_sp_arm_setting", { ...new_sp_arm_setting, sp_arm_number: Number(event.target.value) || 0 })}
                />
                {
                  renderBaseOnConditions(
                    isDisabled,
                    null,
                    <Box flexShrink={0}>
                      <StyledButton
                        data-test-id="add-sp-arm-btn"
                        type="button"
                        disabled={!new_sp_arm_setting?.sp_arm_number}
                        onClick={() => this.addSpArm(formValues, setFieldValue)}
                        className={!new_sp_arm_setting?.sp_arm_number ? "disabled-btn" : undefined}
                      ><ControlPointIcon /></StyledButton>
                    </Box>
                  )
                }
              </Box>
              {
                renderBaseOnConditions(
                  Boolean(new_sp_arm_setting?.sp_arm_number) || isDisabled,
                  <PresentationCheckboxes
                    value={{ hf: Boolean(new_sp_arm_setting?.sp_arm_hf), sp: Boolean(new_sp_arm_setting?.sp_arm_sp), fh: Boolean(new_sp_arm_setting?.sp_arm_fh) }}
                    fieldNames={{ hf: "sp_arm_hf", sp: "sp_arm_sp", fh: "sp_arm_fh" }}
                    onChange={(fieldName, fieldValue) => {
                      if (new_sp_arm_setting) {
                        const newSpArmSetting = { ...new_sp_arm_setting, [fieldName]: fieldValue }
                        setFieldValue("new_sp_arm_setting", newSpArmSetting)
                      }
                    }}
                    disabled={isDisabled}
                  />,
                  <div />
                )
              }

            </div>
          )
        }
      </Box>
    )
  }

  renderConveyor = (conveyorData: Conveyor, conveyorIndex: number) => {
    const { editItem, conveyors, permissionStatus } = this.state
    const isDisabled = conveyorData !== editItem

    return (
      <StyledPaper
        key={conveyorIndex}
        marginBottom={3}
      >
        <Formik initialValues={conveyorData} onSubmit={this.handleSaveConveyorSetting} enableReinitialize>
          {({ values, handleSubmit, resetForm, setFieldValue }) => {
            const selectedCategories = this.getCategoryValues(values.category_ids)
            const selectedSubOrderCategories = this.getCategoryValues(values.sub_order_section_ids)
            const selectedStores = this.getStoreValues(values.store_management_ids)
            const currentPfDestConfig = values.pf_dest_configurations_attributes.find(item => item.route_arm_number === values.route_arm_number)
            const currentRouteStoreIds = currentPfDestConfig?.route_store_ids || []
            const routeStores = this.getRouteStores(selectedStores, currentRouteStoreIds, values.pf_dest_configurations_attributes)
            const filteredRoutes = values.pf_dest_configurations_attributes.filter(item => item.route_store_ids.length)

            const selectedRouteStores = this.getStoreValues(currentRouteStoreIds, routeStores)

            return (
              <form onSubmit={handleSubmit}>
                <CustomAccordion
                  isCollapsible={isDisabled}
                  title={<p>Conveyor {conveyorIndex + 1}</p>}
                  headerActions={
                    renderBaseOnConditions(
                      Boolean(permissionStatus?.metalProgettiActionPermission),
                      <div className="add-arms">
                        <StyledAutocomplete
                          className="add-arms-input"
                          options={Array(101).fill(0).map((element, index) => index.toString())}
                          forcePopupIcon
                          disabled={isDisabled}
                          value={toString(values.arms)}
                          onChange={(event, value) => this.updateArms(Number(value) || null, setFieldValue, values)}
                          disableClearable
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              type="number"
                              margin="none"
                              variant="outlined"
                              placeholder="Add Arms"
                            />
                          )}
                        />
                        {
                          renderBaseOnConditions(!editItem,
                            <button data-test-id={`edit-conveyor-${conveyorIndex}`} onClick={() => this.editConveyor(conveyorData)}>
                              <img src={editIcon} alt="" />
                            </button>,
                            isDisabled ? <></> :
                              <>
                                <button
                                  data-test-id={`cancel-conveyor-${conveyorIndex}`}
                                  className="cancel-btn"
                                  onClick={() => this.handleCancelConveyorSetting(resetForm)}
                                >
                                  <img src={cancelIcon} alt="" />
                                </button>
                                <button
                                  data-test-id={`save-conveyor-${conveyorIndex}`}
                                  type="submit"
                                >
                                  <img src={saveIcon} alt="" />
                                </button>
                              </>

                          )
                        }
                      </div>,
                      null
                    )
                  }
                >
                  <Box className="form-body">
                    <div className="two-columns">
                      <div>
                        <p className="label">Stores</p>
                        <CustomMultiSelect
                          dataTestId="storesDropdown"
                          checkSelectAllText={() => { }}
                          value={selectedStores}
                          optionList={this.state.stores}
                          placeHolderText="Select Stores"
                          changeInputOnClear
                          handleScroll={() => { }}
                          handleClickOnSelectAll={() => { }}
                          debouncedFunction={() => { }}
                          handleSelectOptions={
                            (selectedOptionForList: unknown, option: { id: string; option: string }) =>
                              this.handleSelectStore(option, values.store_management_ids, values.pf_dest_configurations_attributes, setFieldValue)
                          }
                          emptyDataMessage="No Store Found"
                          handleEmptyAutoSelectValue={() => { }}
                          disabled={isDisabled}
                          selectedOptionForList={this.getSelectedStoresForList(selectedStores)}
                        />
                      </div>

                      <div>
                        <p className="label">Sections</p>
                        <CustomSearchableMultiSelect
                          disabled={isDisabled}
                          id="category_ids"
                          name="category_ids"
                          dataTestId="sectionDropdown"
                          checkSelectAllText={() => { }}
                          value={selectedCategories}
                          optionList={this.state.sections}
                          placeHolderText="Select Sections"
                          changeInputOnClear
                          handleScroll={() => { }}
                          handleClickOnSelectAll={() => { }}
                          debouncedFunction={() => { }}
                          handleSelectOptions={(selectedOptionForList: unknown, option: { id: string; option: string }) =>
                            this.handleSelectSection(option, values.category_ids, setFieldValue, "category_ids")
                          }
                          emptyDataMessage="No Section Found"
                          handleEmptyAutoSelectValue={() => { }}
                          selectedOptionForList={this.getSelectedCategoriesForList(selectedCategories)}
                        />
                      </div>
                    </div>

                    <div>
                      <p className="label">Sub Order</p>
                      <div className="two-columns">
                        <CustomSearchableMultiSelect
                          disabled={isDisabled}
                          id="sub_order_section_ids"
                          name="sub_order_section_ids"
                          dataTestId="subOrderDropdown"
                          checkSelectAllText={() => { }}
                          value={selectedSubOrderCategories}
                          optionList={this.state.sections}
                          placeHolderText="Select Section"
                          changeInputOnClear
                          handleScroll={() => { }}
                          handleClickOnSelectAll={() => { }}
                          debouncedFunction={() => { }}
                          handleSelectOptions={(selectedOptionForList: unknown, option: { id: string; option: string }) =>
                            this.handleSelectSection(option, values.sub_order_section_ids, setFieldValue, "sub_order_section_ids")
                          }
                          emptyDataMessage="No Section Found"
                          handleEmptyAutoSelectValue={() => { }}
                          selectedOptionForList={this.getSelectedCategoriesForList(selectedSubOrderCategories)}
                        />
                        <PresentationCheckboxes
                          value={{ hf: values.sub_order_hf, sp: values.sub_order_sp, fh: values.sub_order_fh }}
                          fieldNames={{ hf: "sub_order_hf", sp: "sub_order_sp", fh: "sub_order_fh" }}
                          onChange={setFieldValue}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>

                    <div>
                      <p className="label">SP Arm Number</p>
                      {this.renderSpArmList(values, setFieldValue, isDisabled)}
                    </div>

                    <div>
                      <p className="label">FR Exit Number</p>
                      <div className="two-columns">
                        <CustomSelect
                          data-test-id="fr-exit-number-select"
                          disabled={isDisabled}
                          options={this.getNumberOptions(values.arms)}
                          placeholder=""
                          value={toString(values.fr_exit_number)}
                          onChange={(event) => this.updateFieldValue("fr_exit_number", Number(event.target.value) || null, setFieldValue)}
                        />
                        <PresentationCheckboxes
                          value={{ hf: values.fr_exit_hf, sp: values.fr_exit_sp, fh: values.fr_exit_fh }}
                          fieldNames={{ hf: "fr_exit_hf", sp: "fr_exit_sp", fh: "fr_exit_fh" }}
                          onChange={setFieldValue}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>

                    <div>
                      <p className="label">Perc / Slot Space</p>
                      <div className="two-columns">
                        <CustomSelect
                          disabled={isDisabled}
                          value={values.slot_space || "..."}
                          options={["...", "50%", "100%", "150%", "200%", "250%", "300%"]}
                          onChange={(event) => this.updateFieldValue("slot_space", event.target.value !== "..." ? event.target.value : null, setFieldValue)}
                        />
                        <PresentationCheckboxes
                          value={{ hf: values.slot_hf, sp: values.slot_sp, fh: values.slot_fh }}
                          fieldNames={{ hf: "slot_hf", sp: "slot_sp", fh: "slot_fh" }}
                          onChange={setFieldValue}
                          disabled={isDisabled}
                        />
                      </div>
                    </div>

                    <div>
                      <p className="label">Route Arm Number</p>
                      <div className="two-columns">
                        <div>
                          <CustomSelect
                            data-test-id="route-arm-number-select"
                            disabled={isDisabled}
                            options={this.getNumberOptions(values.arms)}
                            placeholder=""
                            value={toString(values.route_arm_number)}
                            onChange={(event) => this.updateFieldValue("route_arm_number", Number(event.target.value) || null, setFieldValue)}
                          />
                        </div>
                        <CustomMultiSelect
                          dataTestId="routeStoresDropdown"
                          checkSelectAllText={() => { }}
                          value={selectedRouteStores}
                          optionList={values.route_arm_number ? routeStores : []}
                          placeHolderText="Select Stores"
                          changeInputOnClear
                          handleScroll={() => { }}
                          handleClickOnSelectAll={() => { }}
                          debouncedFunction={() => { }}
                          handleSelectOptions={
                            (selectedOptionForList: unknown, option: { id: string; option: string }) =>
                              this.handleSelectRouteStore(option, routeStores, values, setFieldValue, currentPfDestConfig)
                          }
                          emptyDataMessage="No Store Found"
                          handleEmptyAutoSelectValue={() => { }}
                          disabled={isDisabled}
                          selectedOptionForList={this.getSelectedStoresForList(selectedRouteStores, routeStores)}
                        />
                      </div>

                    </div>

                    {
                      renderBaseOnConditions(
                        filteredRoutes.length > 0,
                        <div>
                          <p className="label">Configured Route Arms:</p>
                          {this.renderRouteArmStats(values.pf_dest_configurations_attributes.filter(item => item.route_store_ids.length))}
                        </div>,
                        <></>
                      )
                    }

                    <div>
                      <p className="label">Shared Folder Location</p>
                      <div className="two-columns">
                        <Box display="flex" gridGap={10}>
                          <StyledTextField variant="outlined" value={values.share_folder} disabled />
                          {
                            renderBaseOnConditions(
                              isDisabled,
                              null,
                              <Box paddingTop="10px">
                                <StyledButton
                                  data-test-id={`shared-folder-${conveyorIndex}`}
                                  type="button"
                                  onClick={() => this.toggleSharedFolderDialog({ setFieldValue, currentValue: toString(values.share_folder) })}
                                >
                                  <FolderOutlinedIcon />
                                </StyledButton>
                              </Box>
                            )
                          }
                        </Box>
                        <div />
                      </div>
                    </div>


                  </Box>
                </CustomAccordion>

              </form>
            )
          }
          }
        </Formik >
        {
          conveyorIndex === conveyors.length - 1 && (
            <Box id="scroll-pos" position="absolute" height={56} top={-56} />
          )
        }
      </StyledPaper >
    )
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Box>
          <StyledWrapper>
            <CustomLoader loading={this.state.loading || this.state.loadingUserData} />
            <Box className="page-header">
              <StyledAppBar>
                <Tabs
                  value={0}
                >
                  <Tab
                    value={0}
                    data-test-id={configJSON.metalProgettiTabTestId}
                    label={configJSON.metalProgetti}
                    className={"tab-item active"}
                  />
                </Tabs>
              </StyledAppBar>

              {
                renderBaseOnConditions(
                  Boolean(this.state.permissionStatus?.metalProgettiAccessPermission),
                  <Box className="plant-actions">
                    <AutocompleteSelect
                      className="plant-dropdown"
                      placeholder="Select Plant"
                      fieldName="plant"
                      disableClear
                      onChange={(_item: React.ChangeEvent, value: { id: string; option: string; regionId: number }) => {
                        this.handleChangePlant(value.id, value.regionId)
                      }}
                      handleScroll={() => { }}
                      onBlur={() => { }}
                      debouncedFunction={() => { }}
                      value={this.state.selectedPlant}
                      options={this.state.plants}
                      listBoxStyle={{ maxHeight: 310 }}
                    />
                    {
                      renderBaseOnConditions(
                        Boolean(this.state.permissionStatus?.metalProgettiActionPermission),
                        <button
                          data-test-id="add-conveyor-btn"
                          onClick={this.handleAddConveyor}
                          disabled={this.isDisableButtonAdd()}
                          className={this.isDisableButtonAdd() ? "disabled" : undefined}
                        >
                          Add Conveyor
                        </button>,
                        null
                      )
                    }
                  </Box>,
                  null
                )
              }

            </Box>

            {this.state.conveyors.map(this.renderConveyor)}

            {this.renderSharedFolder()}

          </StyledWrapper>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const StyledWrapper = styled(Box)({
  padding: "24px 0",
  "& .page-header": {
    display: "flex",
    gap: 60,
    position: "sticky",
    top: 0,
    zIndex: 1,
    background: "white",
    paddingBottom: 12,
    "& .plant-actions": {
      display: "flex",
      gap: 24,
      "& .plant-dropdown": {
        minWidth: 230,
      },
      "& > button": {
        padding: 16,
        borderRadius: 8,
        width: 150,
        flexShrink: 0,
        border: 0,
        fontWeight: 700,
        fontSize: 16,
        lineHeight: 1.5,
        textTransform: "none",
        cursor: "pointer",
        backgroundColor: colors().cyancobaltblue,
        color: "white",
        "@media (max-width: 1024px)": {
          padding: "10px 0"
        },
        "&.disabled": {
          opacity: 0.5
        }
      }
    }
  },
  "& .header-title": {
    fontSize: 24,
    fontWeight: 600
  },
  "& .paper": {
    borderRadius: 12,
    padding: 24,
    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
    display: "grid",
    gridTemplateColumns: "0.5fr 0.5fr",
    gap: 24,
  },
  "& .label": {
    marginBottom: 4,
    fontWeight: 600,
    lineHeight: 1.5
  },
  "& .MuiInputBase-root": {
    maxWidth: "unset",
    width: "100%"
  },
  "& .shared-folder-btn": {
    border: 0,
    background: colors().cyancobaltblue,
    color: "white",
    borderRadius: 8,
    flexShrink: 0,
    padding: 16,
    width: 150,
    fontWeight: 600,
    fontSize: 16
  },
})

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    borderRadius: 12,
    padding: 24,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minWidth: 675,
    gap: 16,
  },
  "& h3": {
    fontWeight: 600,
    fontSize: 24,
    lineHeight: 1.33
  },
  "& .note": {
    borderRadius: 4,
    borderLeft: `4px solid ${colors().cyancobaltblue}`,
    background: "rgba(32, 75, 156, 0.10)",
    padding: "12px 16px",
    fontSize: 14,
    lineHeight: "22px",
    color: colors().darkliver,
  },
  "& .input-container": {
    width: "100%"
  },
  "& .label": {
    marginBottom: 4,
    fontWeight: 600,
    lineHeight: 1.5
  },
  "& .actions": {
    paddingBottom: 20,
    "& > button": {
      padding: 16,
      borderRadius: 8,
      width: 126,
      border: 0,
      fontWeight: 700,
      lineHeight: 1.5,
      textTransform: "none",
      cursor: "pointer",
      "@media (max-width: 1024px)": {
        padding: "10px 0"
      },
    },
    "& .cancel-btn": {
      backgroundColor: colors().antiFlashWhite,
      color: colors().slateGray,

    },
    "& .save-btn": {
      backgroundColor: colors().cyancobaltblue,
      color: "white",
      marginLeft: 32,
    }
  }
})

const StyledPaper = styled(Box)({
  position: "relative",
  "& .MuiAccordion-root": {
    borderRadius: 12,
    padding: "24px 12px",
    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
  },
  "& .MuiAccordionSummary-root": {
    alignItems: "center",
    marginBottom: 24,
    "&.Mui-focusVisible": {
      background: "white"
    }
  },
  "& .accordion-header": {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& > p": {
      fontWeight: 600,
      fontSize: 23,
      lineHeight: 1.2
    },
    "& .add-arms": {
      display: "flex",
      gap: 16,
      "& .MuiSelect-root": {
        paddingTop: 10,
        paddingBottom: 10
      },
      "& > button": {
        border: 0,
        width: 44,
        background: colors().cyancobaltblue,
        borderRadius: 8,
        flexShrink: 0,
        cursor: "pointer",
        "&.cancel-btn": {
          background: colors().antiFlashWhite
        }
      },
    },
  },
  "& .form-body": {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: 24,
    "& .two-columns": {
      display: "grid",
      gridTemplateColumns: "0.5fr 0.5fr",
      gap: 24
    },
    "& .label": {
      marginBottom: 4,
      fontWeight: 600,
      lineHeight: 1.5,
      color: colors().charcoal
    },
    "& .hide-icon .MuiSelect-icon": {
      visibility: "hidden"
    },
    "& .MuiOutlinedInput-root": {
      fontFamily: "Montserrat",
      "& input::placeholder": {
        fontSize: 14,
        opacity: 1,
        color: colors().darkliver
      },
      "& .MuiIconButton-label": {
        color: colors().darkjunglegreen
      }
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: colors().lightborder,
    },
    "& .MuiInputBase-root.Mui-disabled": {
      color: "unset"
    },
    "& .MuiChip-root.Mui-disabled": {
      opacity: 1
    }
  }
})
export const StyledTextField = styled(TextField)({
  width: "100%",
  paddingTop: 10,
  borderRadius: 8,
  fontFamily: "Montserrat",
  "& .MuiOutlinedInput-root": {
    fontWeight: 400,
    fontSize: 14,
    fontFamily: "Montserrat",
    borderRadius: 8,
    "@media only screen and (max-width: 1024px)": {
      fontSize: 13,
    },
    "& fieldset": {
      fontSize: "14px",
      borderRadius: "8px",
      borderWidth: 1,
      fontWeight: 400,
      fontFamily: "Montserrat",
    },
  },
  "& ::placeholder": {
    opacity: 1,
    color: colors().darkliver,
    fontSize: 14,
    fontFamily: "Montserrat",
    "@media only screen and (max-width: 1024px)": {
      fontSize: 12,
    },
  },
  "@media only screen and (max-width: 1024px)": {
    '& .MuiInputBase-input': {
      fontSize: 12,
    },
  },
},
)

const StyledAppBar = styled(Box)({
  flex: 1,
  color: colors().darkliver,
  backgroundColor: colors().white,
  borderBottom: `1px solid ${colors().lightborder}`,
  boxShadow: "none",
  marginBottom: 8,
  marginTop: -8,
  "& .MuiTabs-root": {
    height: "100%",
  },
  "& .MuiTab-root": {
    textTransform: "none",
    maxWidth: "unset",
    fontSize: 16,
    minWidth: "fit-content",
    fontWeight: 400,
    "@media only screen and (max-width: 1024px)": {
      fontSize: 14,
    },
  },
  "& .MuiTabs-flexContainer": {
    gap: 24,
    height: "100%"
  },
  "& .Mui-selected": {
    color: colors().cyancobaltblue,
    fontWeight: 700,
    borderBottom: `2px solid ${colors().cyancobaltblue}`,
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "transparent",
  },
  "& .MuiPaper-elevation": {
    boxShadow: "none",
  }
});

const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiInputBase-root": {
    borderRadius: 8,
    paddingTop: 3,
    paddingBottom: 3,
    width: 200,
    "&.Mui-disabled": {
      color: "unset"
    },
    "& .MuiInputBase-input": {
      fontFamily: "Montserrat",
      "&::placeholder": {
        color: colors().darkjunglegreen,
        opacity: 1
      }
    },
    "& .MuiIconButton-label": {
      color: "black"
    },
    "@media only screen and (max-width: 1024px)": {
      '& .MuiInputBase-input': {
        fontSize: 12,
      },
    },
  },
});

const StyledButton = styled("button")({
  borderRadius: 8,
  width: 60,
  height: "100%",
  border: 0,
  fontWeight: 700,
  lineHeight: 1.5,
  textTransform: "none",
  cursor: "pointer",
  backgroundColor: colors().cyancobaltblue,
  color: "white",
  fontSize: 14,
  "&.delete-btn": {
    backgroundColor: colors().antiFlashWhite,
    color: colors().slateGray,
  },
  "&.disabled-btn": {
    opacity: 0.5
  }
})

// Customizable Area End
