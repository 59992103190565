import React from "react";
// Customizable Area Start
import {
  Grid,
  Button,
  Snackbar,
  Typography,
  InputLabel,
  Paper,
  Box,
  styled,
  CircularProgress
} from "@material-ui/core";
import { Formik, FormikValues } from "formik";
import * as Yup from "yup";
import {
  InputField,
  SimpleCustomSelect,
  SimpleCustomMenuItem,
  SimpleCustomAutocomplete,
  CustomPaper,
} from "../../../components/src/customComponents/CustomTags.web";

import Alert from "@material-ui/lab/Alert";

import PageContainer from "../../../../packages/blocks/navigationmenu/src/PageContainer.web";

import { darkPlusIcon, calenderIcon } from "./assets";

import CustomerCreationController from "./CustomerCreationController.web";
import DatePicker from "react-datepicker";
import { AddCustomerWebStyle } from "./AddCustomerModal.web";
import { priceListWebStyle } from "../../CfPriceList/src/CfPriceList.web";
import CreateCustomerPreference from "../../ProductDescription/src/CreateCustomerPreference.web";
// Customizable Area End
import { onChangeOrganization } from "./utils";
import { withStyles } from "@material-ui/core/styles";
import { CSSProperties } from "@material-ui/styles";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";
import AutocompleteSelect from "../../../components/src/AutocompleteSelect.web";
import Loader from "../../../components/src/Loader.web";
import moment from "moment";
import { renderBaseOnConditions, sortStringCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";
import { colors } from "../../../blocks/utilities/src/Colors";
// Customizable Area Start

export const configJSON = require("./config");

// Customizable Area End

class CustomerCreation extends CustomerCreationController {

  // Customizable Area Start
  checkError(error: any, touch: any) {
    if (error && touch) {
      return true
    } else {
      return false
    }
  }

  finalErrorMessage(error: any, touch: any) {
    if (error) {
      if (touch) {
        return <div style={AddCustomerWebStyle.fieldError}>
          {error}
        </div>
      }
      else {
        return null
      }
    }
    else {
      return;
    }
  }

  checkRegionFieldEmptyError(errors:FormikValues, touched:FormikValues){
    if(errors.region_id && touched.region_id){
      return "fieldBorderError"
    }
    else{
      return "fieldBorder"
    }
  }

  checkStoreFieldEmptyError(errors:FormikValues, touched:FormikValues){
    if(errors.pricelist_store_id && touched.pricelist_store_id){
      return "fieldBorderError"
    }
    else{
      return "fieldBorder"
    }
  }

  headerText() {
    if (this.isEdit) {
      return configJSON.txtEditCustomer;
    }
    return configJSON.txtCreateCustomer;
  }

  preferenceButton(priceId: any) {
    const { classes } = this.props;
    if (!this.isEdit) {
      return (
        <div className={classes.createPreferenceButton}>
          <Typography
            data-test-id='open-preference'
            className={classes.preferenceBtn}
          >
            <img src={darkPlusIcon} alt='plus icon' />
            <Typography
              data-test-id='openModal'
              onClick={() => {
                this.openModal(priceId);
              }}
              className={classes.preferenceText}
            >
              {configJSON.btnTxtPreference}
            </Typography>
          </Typography>
        </div>
      );
    }
    return <></>;
  }

  buttonText() {
    if(this.state.buttonLoading) {
      return <CircularProgress style={{ width: 16, height: 16, color: "white" }}/>
    } else {
      if (this.isEdit) {
        return configJSON.btnTxtSave;
      }
      return configJSON.btnTxtAdd;
    }
  }

  customerAddEdit(values: any) {
    if (this.isEdit) {
      return this.handleEditCustomer(values);
    }
    return this.handleAddNewCustomer(values);
  };

  returnCompensationCreditLabel = () => {
    const { totalCompensationCredit } = this.state;

    if (this.isEdit && totalCompensationCredit > 0) {
      return (
        <StyledInputLabel>
          {configJSON.txtLblAddMoreCompensationCredit}{" "}
          <Typography className="compensationtxt">({totalCompensationCredit})</Typography>
        </StyledInputLabel>
      );
    } else {
      return (
        <StyledInputLabel>
          {configJSON.txtLblCompensationCredit}
        </StyledInputLabel>
      );
    }
  };

  returnRegexForValidationCredit = () => {
    return this.isEdit ? /^-?\d+(\.\d{1,2})?$/ : /^\d+(\.\d{1,2})?$/
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const phoneRegex: any = '^[0-9+]+$';
    const customerValidationSchema = Yup.object({
      name: Yup.string()
        .trim()
        .required(configJSON.nameRequired),
      mobile_number_prefix: Yup.string()
        .matches(phoneRegex, configJSON.invalidText),
      mobile_number: Yup.string()
        .trim()
        .required(configJSON.mobileRequired),
      email: Yup.string()
        .trim()
        .notRequired()
        .nullable()
        .email("Invalid email"),
      price_list: Yup.string()
        .required("Please select price list"),
      price_list_expiry_date: Yup.string().when("price_list", {
        is: (value: number) => String(value) !== this.state.isMasterPricelistId,
        then: Yup.string().nullable().required("Please select price list expiry date"),
      }),
      pricelist_store_id: Yup.string().when("price_list", {
        is: (value: number) => String(value) !== this.state.isMasterPricelistId,
        then: Yup.string().required("Please select store"),
      }),
      compensation_credit: Yup.string()
        .nullable()
        .matches(
          this.returnRegexForValidationCredit(),
          sortStringCondition(
            this.isEdit,
            'Must be a valid number with up to 2 decimal places, can be negative',
            'Must be a valid number with up to 2 decimal places (no negative numbers allowed)'
          )
        )
        .test('is-valid-number', 'Must be a valid number', (value) => {
          // Allow empty values
          if (!value) return true;
          // Check if the value can be converted to a number
          return !isNaN(Number(value));
        }),
    });

    const ITEM_HEIGHT = 40;
    const ITEM_PADDING_TOP = 8;
    const MenuProps: any = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 200,
        },
      },
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left",
      },
      getContentAnchorEl: null,
    };
    const { classes } = this.props;

    return (
      <Box>
        <CustomLoader loading={this.state.isLoading} />
       
          <Box style={{ ...priceListWebStyle.contentWrapper }}>
            <div style={priceListWebStyle.headerWrapper}>
              <PageTitle>
                {this.headerText()}
              </PageTitle>
            </div>
            <Formik
              data-test-id="customerCreationFormik"
              initialValues={this.state.customerInitialValues}
              validationSchema={customerValidationSchema}
              onSubmit={(values) => { this.customerAddEdit(values) }}
              enableReinitialize
            >
              {({
                values,
                errors,
                touched,
                handleSubmit,
                handleBlur,
                setFieldValue,
                getFieldProps
              }: any) => (
                <form onSubmit={handleSubmit} data-test-id="addPriceForm">
                  <CustomPaper>
                    <Grid container direction='column'>
                      <Grid container spacing={2} id="modal-content">
                        <Grid item xs={12} sm={8} md={6}>
                          <StyledInputLabel data-test-id="lblTxtName">{configJSON.txtLblName}*</StyledInputLabel>
                          <InputField
                            inputProps={{
                              'data-test-id': 'field-name'
                            }}
                            data-test-id="txtName"
                            error={this.checkError(errors.name, touched.name)}
                            id="name"
                            name="name"
                            fullWidth={true}
                            placeholder="Enter Name"
                            variant="outlined"
                            {...getFieldProps("name")}
                          />
                          {this.finalErrorMessage(errors.name, touched.name)}
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                          <StyledInputLabel>{configJSON.txtLblMobileNumber}*</StyledInputLabel>
                          <Grid container spacing={2}>
                            <Grid item xs={4} sm={3} md={3} lg={2}>
                              <InputField
                                inputProps={{
                                  'data-test-id': 'field-prefix'
                                }}
                                error={this.checkError(errors.mobile_number_prefix, touched.mobile_number_prefix)}
                                id="mobile_number_prefix"
                                name="mobile_number_prefix"
                                fullWidth={true}
                                placeholder="Enter Prefix"
                                variant="outlined"
                                {...getFieldProps("mobile_number_prefix")}
                              />
                            </Grid>
                            <Grid item xs={8} sm={9} md={9} lg={10}>
                              <InputField
                               onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                                e.target.addEventListener(
                                "wheel",
                                function (e: WheelEvent) {
                                    e.preventDefault();
                                },
                                { passive: false }
                                    );
                                }}
                                inputProps={{
                                  'data-test-id': 'field-mobile'
                                }}
                                type="number"
                                error={this.checkError(errors.mobile_number, touched.mobile_number)}
                                id="mobile_number"
                                name="mobile_number"
                                fullWidth={true}
                                placeholder="Enter Mobile number"
                                variant="outlined"
                                {...getFieldProps("mobile_number")}
                              />
                            </Grid>
                          </Grid>
                          {this.finalErrorMessage(errors.mobile_number, touched.mobile_number)}
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                          <StyledInputLabel>{configJSON.txtLblEmail}</StyledInputLabel>
                          <InputField
                            inputProps={{
                              'data-test-id': 'field-email'
                            }}
                            error={this.checkError(errors.email, touched.email)}
                            id="email"
                            name="email"
                            fullWidth={true}
                            placeholder="Enter Email Id"
                            variant="outlined"
                            {...getFieldProps("email")}
                          />
                          {this.finalErrorMessage(errors.email, touched.email)}
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                          <StyledInputLabel>
                            {configJSON.txtLblRegion}
                          </StyledInputLabel>
                          <Box style={{height: "10px"}}/>
                          <AutocompleteSelect
                            fieldName="region_id"
                            className={this.checkRegionFieldEmptyError(errors, touched)}
                            placeholder="Select region"
                            options={this.state.regionList}
                            value={values.region_id}
                            isRadioButtonShow={false}
                            onChange={(_event: React.ChangeEvent, value: { id: number }) => {
                              setFieldValue("region_id", value.id)
                            }}
                          />
                          {this.finalErrorMessage(errors.region_id, touched.region_id)}
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                          <StyledInputLabel>{configJSON.txtLblGender}</StyledInputLabel>
                          <SimpleCustomSelect 
                            data-test-id="genderDropdown"
                            id="gender"
                            name="gender"
                            variant="outlined"
                            fullWidth
                            displayEmpty
                            className={sortStringCondition(
                              this.checkError(errors.gender, touched.gender),
                              `simple-selection-box simple-selection-box-error ${classes.dropdownTextbox}`,
                              `simple-selection-box ${classes.dropdownTextbox}`
                            )}
                            {...getFieldProps("gender")}
                            error={this.checkError(errors.gender, touched.gender)}
                            MenuProps={MenuProps}
                          >
                            <SimpleCustomMenuItem selected value="" disabled className={classes.dropdownDefaultValue}>
                              Select Gender
                            </SimpleCustomMenuItem>
                            {this.state.dropdownOptions?.gender?.map((gender: any) => {
                              return <SimpleCustomMenuItem key={gender.id} value={gender.id}>{gender.option}</SimpleCustomMenuItem>
                            })}
                          </SimpleCustomSelect>
                          {this.finalErrorMessage(errors.gender, touched.gender)}
                        </Grid>
                        <Grid item xs={12} sm={8} md={6} className='customerBirthdate'>
                          <StyledInputLabel>{configJSON.txtLblBirthday}</StyledInputLabel>
                          <DatePicker
                            data-test-id="birthdatePicker"
                            name="birthday"
                            id="birthday"
                            {...getFieldProps("birthday")}
                            onChange={(event: any) => {
                              setFieldValue("birthday", event);
                            }}
                            selected={(values.birthday && new Date(values.birthday)) || null}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            dateFormat="dd/MM/yyyy"
                            maxDate={new Date()}
                            placeholderText="Select Date"
                            className={
                              sortStringCondition(
                                this.checkError(
                                  errors.birthday,
                                  touched.birthday
                                ),
                                "birthdate-picker-errors",
                                "birthdate-picker"
                              )
                            }
                          />
                          <img src={calenderIcon} alt="calender-icon" className={classes.calenderIcon} />
                          {this.finalErrorMessage(errors.birthday, touched.birthday)}
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                          <StyledInputLabel>{configJSON.txtLblProfession}</StyledInputLabel>
                          <SimpleCustomAutocomplete
                            data-test-id="professionDropdown"
                            name="profession"
                            id="profession"
                            getOptionLabel={(option: any) => option.option}
                            options={this.state.dropdownOptions.profession}
                            inputValue={values.profession}
                            onBlur={handleBlur}
                            onChange={(event: any, value: any) => { setFieldValue("profession", value?.option || value); }}
                            onInputChange={(event: any, value: any) => { setFieldValue("profession", value?.option || value); }}
                            clearOnBlur={false}
                            clearOnEscape={false}
                            renderInput={(params: any) => (
                              <InputField
                                {...params}
                                error={this.checkError(errors.profession, touched.profession)}
                                placeholder="Select Profession"
                                variant="outlined"
                              />
                            )}
                            ListboxProps={
                              {
                                style: {
                                  maxHeight: '150px',
                                }
                              }
                            }
                          />
                          {this.finalErrorMessage(errors.profession, touched.profession)}
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                          <StyledInputLabel>{configJSON.txtLblOrganization}</StyledInputLabel>
                          <SimpleCustomAutocomplete
                            data-test-id="organisationDropdown"
                            name="organisation"
                            id="organisation"
                            options={this.state.dropdownOptions.organisation}
                            getOptionLabel={(option: any) => option.option}
                            inputValue={values.organisation}
                            onBlur={handleBlur}
                            onChange={(_: any, value: any) => {
                              let updatedValue = value?.option || value || "";
                              onChangeOrganization(
                                this.getFilteredOrganizationTaxOptions(
                                  updatedValue
                                ),
                                setFieldValue,
                                updatedValue,
                                "organizationTax"
                              );
                            }}
                            onInputChange={(_: any, value: any) => {
                              let updatedValue = value?.option || value || "";
                              onChangeOrganization(
                                this.getFilteredOrganizationTaxOptions(
                                  updatedValue
                                ),
                                setFieldValue,
                                updatedValue,
                                "organizationTax"
                              );
                            }}
                            clearOnBlur={false}
                            clearOnEscape={false}
                            renderInput={(params: any) => (
                              <InputField
                                {...params}
                                error={this.checkError(errors.organisation, touched.organisation)}
                                placeholder="Select Organization"
                                variant="outlined"
                              />
                            )}
                            ListboxProps={
                              {
                                style: {
                                  maxHeight: '150px',
                                }
                              }
                            }
                          />
                          {this.finalErrorMessage(errors.organisation, touched.organisation)}
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                          <StyledInputLabel>{configJSON.txtLblOrganizationTax}</StyledInputLabel>
                          <SimpleCustomAutocomplete
                            data-test-id="organizationTaxDropdown"
                            id="organizationTax"
                            name="organizationTax"
                            options={this.getFilteredOrganizationTaxOptions(values.organisation)}
                            getOptionLabel={(option: any) => option?.option}
                            inputValue={values.organizationTax}
                            onBlur={handleBlur}
                            onChange={(_: any, value: any) => setFieldValue("organizationTax", value?.option || value || '')}
                            onInputChange={(_: any, value: any) => {
                              setFieldValue("organizationTax", value?.option || value || '')
                            }}
                            clearOnBlur={false}
                            clearOnEscape={false}
                            disabled={
                              this.getFilteredOrganizationTaxOptions(
                                values?.organisation
                              ).length > 0
                            }
                            renderInput={(params: any) => (
                              <InputField
                                {...params}
                                readonly
                                error={this.checkError(errors?.organizationTax, touched?.organizationTax)}
                                placeholder="Select Organization Tax"
                                variant="outlined"
                              />
                            )}
                            ListboxProps={
                              {
                                style: {
                                  maxHeight: '150px',
                                }
                              }
                            }
                          />
                          {this.finalErrorMessage(errors.organizationTax, touched.organizationTax)}
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                          <StyledInputLabel>{configJSON.txtLblAddress}</StyledInputLabel>
                          <InputField
                            inputProps={{
                              'data-test-id': 'field-address'
                            }}
                            error={this.checkError(errors.street_address, touched.street_address)}
                            id="street_address"
                            name="street_address"
                            fullWidth={true}
                            placeholder="Apt Number, Street"
                            variant="outlined"
                            {...getFieldProps("street_address")}
                          />
                          {this.finalErrorMessage(errors.street_address, touched.street_address)}
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                          <StyledInputLabel>City</StyledInputLabel>
                          <SimpleCustomSelect
                            data-test-id="cityDropdown"
                            id="city"
                            name="city"
                            variant="outlined"
                            fullWidth
                            displayEmpty
                            className={
                              sortStringCondition(
                                this.checkError(errors.city, touched.city),
                                `simple-selection-box simple-selection-box-error ${classes.dropdownTextbox}`,
                                `simple-selection-box ${classes.dropdownTextbox}`
                              )
                            }
                            {...getFieldProps("city")}
                            error={this.checkError(errors.city, touched.city)}
                            MenuProps={MenuProps}
                          >
                            <SimpleCustomMenuItem selected value="" disabled className={classes.dropdownDefaultValue} >
                              Select City
                            </SimpleCustomMenuItem>
                            {this.state.dropdownOptions?.city?.map((city: any) => {
                              return <SimpleCustomMenuItem key={city.id} value={city.id}>{city.name}</SimpleCustomMenuItem>
                            })}
                          </SimpleCustomSelect>
                          {this.finalErrorMessage(errors.city, touched.city)}
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                          <StyledInputLabel>{configJSON.txtLblPostalCode}</StyledInputLabel>
                          <InputField
                            inputProps={{
                              'data-test-id': 'field-postalCode'
                            }}
                            type="number"
                            error={this.checkError(errors.postal_code, touched.postal_code)}
                            id="postal_code"
                            name="postal_code"
                            fullWidth={true}
                            placeholder="Enter Your Area Code"
                            variant="outlined"
                            {...getFieldProps("postal_code")}
                          />
                          {this.finalErrorMessage(errors.postal_code, touched.postal_code)}
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                          <StyledInputLabel>{configJSON.txtLblId}</StyledInputLabel>
                          <InputField
                            inputProps={{
                              'data-test-id': 'field-employee'
                            }}
                            error={this.checkError(errors.id, touched.id)}
                            id="id"
                            name="id"
                            fullWidth={true}
                            placeholder="Enter Employee"
                            variant="outlined"
                            {...getFieldProps("id")}
                          />
                          {this.finalErrorMessage(errors.id, touched.id)}
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                          <StyledInputLabel>{configJSON.txtLblBusiness}</StyledInputLabel>
                          <SimpleCustomSelect
                            data-test-id="businessDropdown"
                            id="business"
                            name="business"
                            variant="outlined"
                            fullWidth
                            displayEmpty
                            className={
                              sortStringCondition(
                                this.checkError(errors.city, touched.city),
                                `simple-selection-box simple-selection-box-error ${classes.dropdownTextbox}`,
                                `simple-selection-box ${classes.dropdownTextbox}`
                              )
                            }
                            {...getFieldProps("business")}
                            error={this.checkError(errors.business, touched.business)}
                            MenuProps={MenuProps}
                          >
                            <SimpleCustomMenuItem selected value="" disabled className={classes.dropdownDefaultValue} >
                              Select Business Category
                            </SimpleCustomMenuItem>
                            {this.state.dropdownOptions?.business?.map((business: any) => {
                              return <SimpleCustomMenuItem key={business.id} value={business.id}>{business.option}</SimpleCustomMenuItem>
                            })}
                          </SimpleCustomSelect>
                          {this.finalErrorMessage(errors.business, touched.business)}
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                          <StyledInputLabel>
                            {configJSON.txtLblPriceList}
                          </StyledInputLabel>
                          <AutocompleteSelect
                            isDisabled={!this.state.permissionStatus.isHaveLinkPricelistPermission}
                            data-test-id='priceListDropdown'
                            fieldName='price_list'
                            className='simple-selection-box'
                            placeholder='Select price list'
                            handleScroll={this.handleScrollPricelistDropdown.bind(
                              this
                            )}
                            debouncedFunction={(getValue: string) => {
                              this.debouncedFunction(
                                getValue,
                                this.handleAutoCompleteChange
                              );
                            }}
                            onChange={(
                              _blank: unknown,
                              value: { id: number; option: string } | null
                            ) => {
                              this.handleChangeSelectedPriceList(
                                value,
                                setFieldValue
                              );
                            }}
                            value={values.price_list}
                            options={this.state.dropdownOptions.price_list}
                            listBoxStyle={{ maxHeight: 270 }}
                          />
                          {this.finalErrorMessage(errors.price_list, touched.price_list)}
                        </Grid>
                        {renderBaseOnConditions(
                          String(values.price_list) !== this.state.isMasterPricelistId,
                          <Grid
                            item
                            xs={12}
                            sm={8}
                            md={6}
                            className='customerBirthdate'
                          >
                            <StyledInputLabel>
                              {configJSON.txtLblPriceListExp}
                            </StyledInputLabel>
                            <DatePicker
                              data-test-id='pricelist-expiry-date'
                              name='price_list_expiry_date'
                              id='price_list_expiry_date'
                              minDate={new Date()}
                              onChange={(event) => {
                                setFieldValue("price_list_expiry_date", event);
                              }}
                              selected={
                                (values.price_list_expiry_date &&
                                  new Date(values.price_list_expiry_date)) ||
                                ""
                              }
                              value={
                                sortStringCondition(
                                  values.price_list_expiry_date,
                                  moment(
                                    values.price_list_expiry_date
                                  ).format("DD/MM/YYYY"),
                                  ""
                                )
                              }
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode='select'
                              dateFormat='dd/MM/yyyy'
                              placeholderText='Select Date'
                              className={
                                sortStringCondition(
                                  this.checkError(
                                    errors.price_list_expiry_date,
                                    touched.price_list_expiry_date
                                  ),
                                  "birthdate-picker-errors",
                                  "birthdate-picker"
                                )
                              }
                            />
                            <img
                              src={calenderIcon}
                              alt='calender-icon'
                              className={classes.calenderIcon}
                            />
                            {this.finalErrorMessage(
                              errors.price_list_expiry_date,
                              touched.price_list_expiry_date
                            )}
                          </Grid>,
                          <></>
                        )}
                        {renderBaseOnConditions(
                          String(values.price_list) !== this.state.isMasterPricelistId,
                          <Grid item xs={12} sm={8} md={6}>
                            <StyledInputLabel>
                              {configJSON.txtLblPriceListRegion}
                            </StyledInputLabel>
                            <Box style={{ height: "10px" }} />
                            <AutocompleteSelect
                              fieldName='pricelist_region_id'
                              placeholder='Select Region'
                              options={this.state.regionList}
                              value={values.pricelist_region_id}
                              isRadioButtonShow={false}
                              onChange={(
                                _event: React.ChangeEvent,
                                value: { id: number } | null
                              ) => {
                                this.handleChangePriceListRegion(
                                  value,
                                  setFieldValue
                                );
                              }}
                            />
                          </Grid>,
                          <></>
                        )}
                        {renderBaseOnConditions(
                          String(values.price_list) !== this.state.isMasterPricelistId,
                          <Grid item xs={12} sm={8} md={6}>
                            <StyledInputLabel>
                              {configJSON.txtLblPriceListArea}
                            </StyledInputLabel>
                            <Box style={{ height: "10px" }} />
                            <AutocompleteSelect
                              fieldName='pricelist_area_id'
                              placeholder='Select Area'
                              options={this.state.dropdownOptions.area_list}
                              value={values.pricelist_area_id}
                              isRadioButtonShow={false}
                              onChange={(
                                _event: React.ChangeEvent,
                                value: { id: number } | null
                              ) => {
                                this.handleChangePriceListArea(
                                  value,
                                  setFieldValue
                                );
                              }}
                            />
                          </Grid>,
                          <></>
                        )}
                        {renderBaseOnConditions(
                          String(values.price_list) !== this.state.isMasterPricelistId,
                          <Grid item xs={12} sm={8} md={6}>
                            <StyledInputLabel>
                              {configJSON.txtLblPriceListStore}
                            </StyledInputLabel>
                            <Box style={{ height: "10px" }} />
                            <AutocompleteSelect
                              fieldName='pricelist_store_id'
                              placeholder='Select Store'
                              className={this.checkStoreFieldEmptyError(
                                errors,
                                touched
                              )}
                              options={this.state.dropdownOptions.store_list}
                              value={values.pricelist_store_id}
                              isRadioButtonShow={false}
                              onChange={(
                                _event: React.ChangeEvent,
                                value: { id: number } | null
                              ) => {
                                this.handleChangePriceListStore(
                                  value,
                                  setFieldValue
                                );
                              }}
                            />
                            {this.finalErrorMessage(
                              errors.pricelist_store_id,
                              touched.pricelist_store_id
                            )}
                          </Grid>,
                          <></>
                        )}
                        <Grid item xs={12} sm={8} md={6}>
                          <StyledInputLabel>{configJSON.txtLblPaymentType}</StyledInputLabel>
                          <SimpleCustomSelect
                            data-test-id="paymentTypeDropdown"
                            id="payment_type"
                            name="payment_type"
                            variant="outlined"
                            fullWidth
                            displayEmpty
                            className={`${this.checkError(errors.payment_type, touched.payment_type) ? 'simple-selection-box simple-selection-box-error' : 'simple-selection-box'}`}
                            style={{ borderRadius: '8px' }}
                            {...getFieldProps("payment_type")}
                            error={this.checkError(errors.payment_type, touched.payment_type)}
                            MenuProps={MenuProps}
                          >
                            <SimpleCustomMenuItem selected value="" disabled className={classes.dropdownDefaultValue} >
                              Select Payment Type
                            </SimpleCustomMenuItem>
                            {this.state.dropdownOptions?.payment_type?.map((payment_type: any) => {
                              return <SimpleCustomMenuItem value={payment_type.id}>{payment_type.option}</SimpleCustomMenuItem>
                            })}
                          </SimpleCustomSelect>
                          {this.finalErrorMessage(errors.payment_type, touched.payment_type)}
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                          <StyledInputLabel>{configJSON.txtLblNote}</StyledInputLabel>
                          <InputField
                            inputProps={{
                              'data-test-id': 'field-notes'
                            }}
                            error={this.checkError(errors.notes, touched.notes)}
                            id="notes"
                            name="notes"
                            fullWidth={true}
                            placeholder="Enter Note"
                            variant="outlined"
                            {...getFieldProps("notes")}
                          />
                          {this.finalErrorMessage(errors.notes, touched.notes)}
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                          <StyledInputLabel>{configJSON.txtLblPrivateNote}</StyledInputLabel>
                          <InputField
                            inputProps={{
                              'data-test-id': 'field-privateNotes'
                            }}
                            error={this.checkError(errors.private_note, touched.private_note)}
                            id="private_note"
                            name="private_note"
                            fullWidth={true}
                            placeholder="Enter Private Note"
                            variant="outlined"
                            {...getFieldProps("private_note")}
                          />
                          {this.finalErrorMessage(errors.private_note, touched.private_note)}
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                          {this.returnCompensationCreditLabel()}
                          <InputField
                            inputProps={{
                              'data-test-id': 'field-compensationCredit'
                            }}
                            error={this.checkError(errors.compensation_credit, touched.compensation_credit)}
                            id="compensation_credit"
                            name="compensation_credit"
                            fullWidth={true}
                            placeholder="Enter Total Compensation Credit"
                            variant="outlined"
                            {...getFieldProps("compensation_credit")}
                          />
                          {this.finalErrorMessage(errors.compensation_credit, touched.compensation_credit)}
                        </Grid>
                      </Grid>
                      {this.preferenceButton(values.price_list)}

                    </Grid>
                  </CustomPaper>
                  <Box className={classes.actionButtonSection}>
                    <StyledButton
                      data-test-id="cancelBtn"
                      style={{ ...AddCustomerWebStyle.addButton }}
                      className={`${classes.cancleButton}`}
                      onClick={() => { this.props.navigation.navigate("PeopleManagement2") }}
                    >
                      {configJSON.btnTxtCancel}
                    </StyledButton>
                    <StyledButton
                      data-test-id='btn-submit'
                      type="submit"
                      style={{ ...AddCustomerWebStyle.addButton }}
                      className={`${classes.addButton}`}
                    >
                      {this.buttonText()}
                    </StyledButton>
                  </Box>
                </form>
              )}
            </Formik>
            <Snackbar
              data-test-id='errorMsg'
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={this.state.snackbarOpen}
              autoHideDuration={4000}
              onClose={this.handleSnackbarClose}
            >
              <Alert severity='error'>{this.state.snackbarMessage}</Alert>
            </Snackbar>
            {this.state.preferenceModalVisible && <CreateCustomerPreference
              data-test-id='createPreferenceModal'
              onCreateForCustomer={(data) => this.handleCreatePreferenceForCustomer(data)}
              preferenceData={undefined}
              selectedService={{}}
              handleClose={this.handleClosePreference}
              data={[]}
              headerTitle={"Create Item Preference"}
              saveForFuture={true}
              open={this.state.preferenceModalVisible}
              responseData={{
                id: "11",
                type: "preference",
                attributes: {
                  id: 11,
                  name: "Preference 1",
                  product_second_name: "Product 1",
                  specifications: [],
                  upcharges: [],
                  services: [],
                  preferences: [],
                },
              }}
              onCreate={(data) => { }}
              priceId={
                sortStringCondition(
                  this.state.priceListId !== "",
                  this.state.priceListId,
                  this.state.isMasterPricelistId
                )
              }
            />}
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={this.state.openPriceListSnackbar}
              autoHideDuration={4000}
              onClose={this.handleSnackbarClose}
            >
              <Alert severity='success'>{this.state.snackbarMessage}</Alert>
            </Snackbar>
          </Box>
       
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

export const styles: Record<string, CSSProperties> = {
  preferenceBtn: {
    marginTop: "20px",
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(32, 75, 156, 0.10)',
    padding: "10px 16px",
    borderRadius: "8px",
    width: 'fit-content',
    cursor: 'pointer',
  },
  actionButtons: {
    marginBottom: 10
  },
  actionButtonSection: {
    marginTop: '48px',
    marginBottom: '40px',
    display: 'flex',
    flexWrap: 'wrap-reverse'
  },
  cancleButton: {
    textTransform: "unset",
    backgroundColor: '#F1F5F9 !important',
    color: '#64748B !important',
    marginBottom: 10
  },
  addButton: {
    textTransform: "unset",
    marginBottom: 10
  },
  createPreferenceButton: {
    width: "max-content"
  },
  preferenceText: {
    fontSize: "16px",
    fontWeight: 600,
    color: "#4D4D4D",
    marginLeft: "8px",
  },
  dropdownTextbox: {
    borderRadius: 8
  },
  dropdownDefaultValue: {
    display: 'none'
  },
  calenderIcon: {
    position: 'absolute',
    right: '18px',
    top: '50px',
    pointerEvents: 'none'
  }
}

const PageTitle = styled("div")({
  fontFamily: "Montserrat",
  fontWeight: 600,
  fontStyle: "normal",
  lineHeight: "29px",
  fontSize: "24px",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "16px"
  },
});

const StyledInputLabel = styled(InputLabel)({
  fontSize: "16px",
  fontFamily: "Montserrat",
  lineHeight: "15.6px",
  fontWeight: 600,
  "@media only screen and (max-width: 1024px)": {
    fontSize: "13px"
  },
  "& .compensationtxt": {
    display: "inline-block",
    color: colors().cyancobaltblue,
    fontWeight: 600,
  }
})

const StyledButton = styled(Button)({
  height: "56px",
  width: "184px",
  background: 'rgb(32, 75, 156)',
  backgroundColor: 'rgb(32, 75, 156)',
  color: 'rgb(255, 255, 255)',
  fontWeight: 600,
  fontSize: '16px',
  borderRadius: "8px",
  lineHeight: "24px",
  marginLeft: "10px",
  "@media only screen and (max-width: 1024px)": {
    height: "44px !important",
    width: "126px !important",
  },
})
export default  withStyles(styles)(CustomerCreation)

// Customizable Area End