// Customizable Area Start
import invert from "invert-color";

interface IColors {
  [field: string]: string;
}

/* get colors */
export const colors = (idDark = false) => {
  const lightColors: IColors = {
    background: "#FFFFFF",
    primary: "#512DA8",
    text: "#121212",
    error: "#D32F2F",
    black: "#000",
    white: "#fff",
    darkliver: "#4d4d4d",
    cyancobaltblue: "#204B9C",
    darkjunglegreen: "#0F172A",
    charcoal: "#334155",
    brightgray: "#E9EDF5",
    coolgray: "#475569",
    lightborder: "#ECECEC",
    viewTextColor: "#1A1A1A",
    antiFlashWhite: "#F1F5F9",
    slateGray: "#64748B",
    activeGreen: "#059668",
    columbiaBlue: "#CBD5E1",
    orange: "#EC4E20",
    ghostwhite: "#f8fafc",
    Cultured: "#F7F7F7",
    lightsilver: "#D9D9D9",
    statusRed: '#DC2626',
    disableColor: '#F8F8F8',
    dividerColor: '#98ACD1',
    pendingColor: "#D97706",
    silverChalice: "#B2ACAA",
    cardHeaderUpcoming: "#F0E5FF",
    mainCardUpcoming: "#F6F0FF",
    cardHeaderRecieved: "#EDECB9",
    mainCardRecieved: "#F7F6C0",
    cardHeaderLoadArea: "#CAEAF4",
    mainCardLoadArea: "#D8F1F9",
    cardHeaderDispatched: "#F3D9B5",
    mainCardDispatched: "#FAE1BF",
    cardHeaderMetal: "#C4DCE5",
    mainCardMetal: "#D3E4EA",
    lightlavender: "#F6F0FF",
    lavenderblue: "#E0D9F5",
    lightblue: "#E3F2FD",
    paleblue: "#D8F1F9",
    paleblue2: "#D3E4EA",
    purple: "#E1CCFF",
    blueborder: "#BAC7E0",
    lightyellow: "#F7F6C0",
    peach: "#FAE1BF",
    dueTodayColor: "#059669",
    dueTomorrowColor: "#F59E0B",
    overdueColor: "#DC2626",
    lightgray: "#E2E8F0",
    darkblue: "#4D6FB0",
    carnationPink: "#FF92A5",
    lightPurple: "#A666FF"
  };

  const darkColors: IColors = {};
  for (const color in lightColors) {
    darkColors[color] = invert(lightColors[color]);
  }
  return idDark ? darkColors : lightColors;
};
// Customizable Area End