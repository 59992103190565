// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { IFilter } from "../../../components/src/FilterPopover";

import { ISortingData } from "../../../components/src/SortingTableHeader";
import { getFiterList, makeApiMessage } from "../../../components/src/common";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  checkIsFilterApplied,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";


interface Icon {
  id: number;
  name: string;
  second_name: string;
  image_type: string;
  image: string | null;
}
interface PageMeta {
  next_page: number;
  previous_page: number;
  total_pages: number;
  total_count: number;
  current_page: number
}
interface CustomerTypeAttributes {
  customer_type_name: string;
  minimum_points: number;
  maximum_points: number | null;
  min_number_of_orders: number;
  max_number_of_orders: number | null;
  active: boolean;
  icon: Icon;
  no_of_users: number;
}

interface CustomerType {
  id: string;
  type: string;
  attributes: CustomerTypeAttributes;
}

export interface CustomerTypeInterface {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    maximum_points: string,
    minimum_points: string,
    customer_type_name: string,
    min_number_of_orders: string,
    max_number_of_orders: string,
    active: boolean;
    icon: Icon;
    no_of_users: any;
  };
}

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}
// Customizable Area End

export interface S {
  // Customizable Area Start
  drawerWidth: number;
  popOverOpened: boolean;
  popOverItemId: string;
  popOverItemStatus: string;
  popOverTop: number;
  popOverLeft: number;
  customerTypesList: CustomerTypeInterface[];
  page: number;
  pageSize: number;
  isModalOpen: boolean;
  deactivated: boolean;
  filterAnchor: any;
  filters: IFilter[];
  clickedCustomerType: CustomerTypeInterface | undefined;
  sortingData: ISortingData;
  query: string;
  searchText: string;
  suggestionFieldTitle: string;
  isSuperAdmin?: boolean;
  meta: PageMeta;
  permissionStatus: PermissionStatus;
  isAppliedFilter: boolean;
  isLoadingList: boolean;
  isLoadingPermission: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CustomerTypeController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCallId: string = "";
  filterSuggestionApiCallId: string = "";
  updateCallId: string = "";
  tableRefs: HTMLElement | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.LayoutDataMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SearchTextMessage),
      getName(MessageEnum.SessionSaveMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      customerTypesList: [],
      page: 1,
      pageSize: 10,
      popOverItemId: "",
      popOverItemStatus: "",
      isModalOpen: false,
      deactivated: false,
      drawerWidth: 0,
      popOverOpened: false,
      popOverLeft: 0,
      popOverTop: 0,
      filterAnchor: false,
      clickedCustomerType: undefined,
      searchText: "",
      filters: [
        {
          title: "Customer Type",
          type: "autocompolete",
          value: "",
          apiKey: "customer_type",
          options: [],
        }, 
      ],
      sortingData: {
        customer_type_name: "",
        minimum_points: "",
        maximum_points: "",
        min_number_of_orders: "",
        max_number_of_orders: "",
        no_of_users: "",
      },
      query: "",
      suggestionFieldTitle: "",
      meta: configJSON.initialMetaData,
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      },
      isAppliedFilter: false,
      isLoadingList: true,
      isLoadingPermission: true
    };
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.extractDataFromLayout(message);
    this.receiveSearchText(message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.getCallId) {
          const meta = responseJson?.meta || configJSON.initialMetaData
          this.setState({ 
            customerTypesList: responseJson?.data || [],
            meta: {
              next_page: meta.next_page,
              previous_page: meta.previous_page,
              total_pages: meta.total_pages,
              total_count: meta.total_count,
              current_page: meta.current_page
            },
            isLoadingList: false
          });
      } else if (apiRequestCallId === this.updateCallId) {
        this.getCustomerTypesList();
        if (responseJson && this.state.popOverItemStatus === "Active") {
          this.setState({ deactivated: true });
        }
      } else if (apiRequestCallId === this.filterSuggestionApiCallId) {
        if(responseJson) {
          const updatedFilters = getFiterList(responseJson, this.state.filters, this.state.suggestionFieldTitle);
          this.setState({ filters: updatedFilters });
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  receiveSearchText = (message: Message) => {
    if (message.id === getName(MessageEnum.SearchTextMessage)) {
      const recievedData = message.getData(
          getName(MessageEnum.SearchMessageText)
      );
      if (recievedData) {
        this.handleSearch(recievedData.searchText)
      }
    }
  };

  extractDataFromLayout = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.LayoutMessageData)
        );
        if (recievedData.userContext) {
            this.handleUserChange(recievedData.userContext)
        }
    }
  }

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.customerType;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value,
      isLoadingPermission: false
    })
  };

  handleIsActive = () => {
    this.setState({ popOverItemStatus: "Active" });
  };

  handleDrawerWidthChange = (width: number) => {
    this.setState({ drawerWidth: width });
  };

  getCustomerTypesList = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem(configJSON.token),
    };

    const nameFilter = this.state.filters.find((item) => item.title === "Customer Type")?.value;
    const searchText = this.state.searchText;

    const listEndPoint =
      configJSON.customerTypeEndPoint +
      `?page_no=${this.state.page}&per_page=${this.state.pageSize}` +
      (nameFilter ? `&filter_by[customer_type_name]=${nameFilter}` : "") +
      (searchText ? `&filter_by[query]=${searchText}` : "") +
      this.state.query;

    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCallId = getDataMsg.messageId;

    getDataMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), listEndPoint);
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  updateCustomerType = (customerTypeId: string, isActive: boolean) => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: window.localStorage.getItem(configJSON.token),
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.customerTypeEndPoint}/${customerTypeId}/${isActive ? "deactivate" : "activate"}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handlePageChange = ( page: number) => {
    this.setState({ page }, () => this.getCustomerTypesList());
  };

  handleSearch = (value: string) => {	
    this.setState(	
      {
        page: 1,	
        searchText: value,	
      },	
      this.getCustomerTypesList	
    );
  };	

  handleAdd = () => {
    this.props.navigation.navigate("CustomerTypeAdd");
  };

  handleEdit = (id: string) => {
    this.setState({ popOverOpened: false });
    this.props.navigation.navigate("CustomerTypeEdit", {id: id});
  };

  handleView = (id: string) => {
    this.setState({ popOverOpened: false });
    this.props.navigation.navigate(`CustomerTypeView`,{id: id});
    
  };

  handleDeactivate = (id: string) => {
    this.setState({
      isModalOpen: true,
      clickedCustomerType: this.state.customerTypesList.find((item) => item.id == id),
    });
  };

  handleToggleFilter = (event?: React.MouseEvent<HTMLDivElement>) => 
    this.setState({
      filterAnchor: event?.currentTarget || null,
    });

  handleFilterChange = (filters: IFilter[]) => {
    // set filter applied value set
    if (checkIsFilterApplied(filters)) {
      localStorage.setItem("customer_type_filter_value", JSON.stringify(filters));
    } else {
      localStorage.removeItem("customer_type_filter_value");
    };

    this.setState(
      {
        filters,
        isAppliedFilter: checkIsFilterApplied(filters),
        page: 1,
      },
      this.getCustomerTypesList
    );
  };

  handleFilterAutocompleteChange = (title: string, value: string) => {
    this.setState({ suggestionFieldTitle: title });
    this.handleCustomerTypeAutoComplete(title, value);
  }

  handleClearFilter = () => {
    let updated = this.state.filters.map((item: IFilter) => {
      item.value = ""; 
      item.options=[];
      return item;
    });
    this.setState({ filters: updated});
  }

  changeActivateUser = () => {
    this.setState({ popOverOpened: false, isModalOpen: false });
    this.updateCustomerType(this.state.popOverItemId, this.state.popOverItemStatus === "Active");
  };

  handleDeactivateClose = () => {
    this.setState({ isModalOpen: false, popOverOpened: false });
  };

  handleDeactivatedClose = () => {
    this.setState({ deactivated: false });
  };

  handleQueryChange = (query: string) => {
    this.setState({ query }, () => this.getCustomerTypesList());
  };

  sortingProps = {
    width: "18%",
    onQueryChange: (query: string) => this.handleQueryChange(query),
    onChange: (sortingData: ISortingData) => this.setState({ sortingData }),
  };

  handleGoList = () => {
    this.setState({ deactivated: false });
  };

  handleCustomerTypeAutoComplete = (title: string, value: string) => {
    this.state.filters.forEach((item: IFilter) => {
      if (item.title === title) item.value = value;
      else item.value = "";
      return item;
    });

    let requestMessage = makeApiMessage({
      url: configJSON.customerTypeSuggestionEndPoint + value,
      method: "GET",
    });
    this.filterSuggestionApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleStorageFilter();
    // Customizable Area End
  }

  handleReturnColorType = () => {
    const { isAppliedFilter } = this.state;
    return isAppliedFilter ? "primary" : "inherit";
  };

  handleStorageFilter = () => {
    const applied_customer_type_filter = localStorage.getItem("customer_type_filter_value");
    if (applied_customer_type_filter) {
      this.setState(
        {
          filters: JSON.parse(applied_customer_type_filter),
          isAppliedFilter: checkIsFilterApplied(
            JSON.parse(applied_customer_type_filter)
          ),
        },
        () => this.getCustomerTypesList()
      );
    } else {
      this.getCustomerTypesList();
    }
  };
  // Customizable Area End
}
