import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { apiCall } from "../../../components/src/common";
import { handleMomentDateFormat } from "../../cfcashier/src/utils";
import moment from "moment";
import { IFilter } from "../../../components/src/FilterPopover";
import html2pdf from "html2pdf.js";
import { handleDisplayRegion } from "./utils";
import { customPermissionApiKey, DashboardPermissionStatus, checkForDashboardPermissonStatus, checkIsFilterApplied } from "../../utilities/src/CustomBlockHelpers";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
export const configJSON = require("./config");

interface DiscountData {
  order_count: number | string;
  amount: string | number;
}

interface CreditData {
  order_count: number;
  credit_amount: string;
}
interface StoreWiseData {
  orders_count: number;
  amount: string;
}

interface StoreData {
  store_name: string;
  general_discount: StoreWiseData
  compensation_credit: StoreWiseData
  loyalty_credit: StoreWiseData
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  filters: IFilter[];
  storeId: string;
  groupId: string;
  areaId: string;
  regionId: string;
  generalDiscountData: DiscountData;
  loyaltyCreditData: CreditData;
  compensationCreditData: CreditData;
  storewiseDiscountData: StoreData[];
  filterAnchor: HTMLDivElement | undefined;
  regionName: string;
  storeNames: string;
  groupNames: string;
  isOpenExportModal: boolean;
  permissionStatus: DashboardPermissionStatus | null,
  storeWiseDiscountMetaInfo: {
    storeListPageNo: number,
    storeItemsPerPage: number,
    totalCount: number
  },
  isAppliedFilter: boolean;
  getGeneralDiscountLoading: boolean;
  getLoyaltyCreditLoading: boolean;
  getCompensationCreditLoading: boolean;
  getStorewiseDiscountLoading: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class DiscountCreditSectionController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getGeneralDiscountCallId: string = "getGeneralDiscountCallId";
  getLoyaltyCreditCallId: string = "getLoyaltyCreditCallId";
  getCompensationCreditCallId: string = "getCompensationCreditCallId";
  getStorewiseDiscountCallId: string = "getStorewiseDiscountCallId";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.BroadcastNavbarDataMessage),
      getName(MessageEnum.LayoutDataMessage)
    ];
    this.state = {
      storewiseDiscountData: [],
      filters: [
        {
          title: "Date",
          value: "today",
          type: "dateselect",
          datevalue: { from: "", to: "" },
          options: [
            {
              label: "Today",
              value: "today",
            },
            {
              label: "Last 7 days",
              value: "last7",
            },
            {
              label: "Last 30 days",
              value: "last30",
            },
            {
              label: "Specific Dates",
              value: "specific",
            },
          ]
        }
      ],
      storeId: "",
      areaId: "",
      groupId: "",
      groupNames: "",
      regionId: "",
      generalDiscountData: {
        order_count: 0,
        amount: "0.00"
      },
      loyaltyCreditData: {
        order_count: 0,
        credit_amount: "0.00"
      },
      compensationCreditData: {
        order_count: 0,
        credit_amount: "0.00"
      },
      filterAnchor: undefined,
      storeNames: "",
      regionName: "",
      isOpenExportModal: false,
      permissionStatus: null,
      storeWiseDiscountMetaInfo: {
        storeListPageNo: 1,
        storeItemsPerPage: 20,
        totalCount: 0
      },
      isAppliedFilter: false,
      getGeneralDiscountLoading: true,
      getLoyaltyCreditLoading: true,
      getCompensationCreditLoading: true,
      getStorewiseDiscountLoading: true
    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.receiveDataFromLayout(message);
    this.getBroadcastedData(message)
    this.handleResForGeneralDiscount(from, message)
    this.handleResForLoyaltyCredit(message)
    this.handleResForCompensationCredit(message)
    this.handleResForStorewiseDiscount(from, message)
    // Customizable Area End
  }

  // Customizable Area Start
  getOverallData = async () => {
    this.setState({getGeneralDiscountLoading:true, getLoyaltyCreditLoading: true, getCompensationCreditLoading: true})
    const dateFilterQuery = this.handleDateFilterParam().dateFilterQuery
    let headers = {
      token: await getStorageData('token'),
      "Content-Type": configJSON.dashboarContentType,
    };
    const {groupId,areaId,storeId} = this.state
    const groupParam = groupId  ? `&group_ids=${groupId}` : ""
    const areaParam = areaId  ? `&area_ids=${areaId}` : ""
    const storeParam = storeId ? `&store_ids=${storeId}` : ""
    const newParam = groupParam + areaParam + storeParam
    const requests = [
      {
        apiCallId: "getGeneralDiscountCallId",
        endPoint: configJSON.getGeneralDiscountEndpoint + "?region_ids="
      },
      {
        apiCallId: "getLoyaltyCreditCallId",
        endPoint: configJSON.getLoyaltyCreditEndPoint + "&region_ids="
      },
      {
        apiCallId: "getCompensationCreditCallId",
        endPoint: configJSON.getCompensationCreditEndPoint + "&region_ids="
      }
    ] as const

    requests.forEach(
      request => {
        const requestMessage = apiCall({
          httpBody: {},
          header: headers,
          url: `${request.endPoint}${this.state.regionId}${newParam}${dateFilterQuery}`,
          httpMethod: configJSON.dashboarApiMethodType,
        });
        this[request.apiCallId] = requestMessage.messageId
        this.send(requestMessage)
      }
    )
  };

  handleResForGeneralDiscount = async (from: string, message: Message) => {
    if (this.getGeneralDiscountCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.status == 200) {
        this.setState({ generalDiscountData: responseJson.data, getGeneralDiscountLoading: false })
      }
    }
  }

  handleResForLoyaltyCredit = async (message: Message) => {
    if (this.getLoyaltyCreditCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.status == 200) {
        this.setState({ loyaltyCreditData: responseJson.data, getLoyaltyCreditLoading: false })
      }
    }
  }

  handleResForCompensationCredit = async (message: Message) => {
    if (this.getCompensationCreditCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.status == 200) {
        this.setState({ compensationCreditData: responseJson.data, getCompensationCreditLoading: false })
      }
    }
  }

  getStorewiseDiscount = async () => {
    this.setState({getStorewiseDiscountLoading:true})
    const dateFilterQuery = this.handleDateFilterParam().dateFilterQuery
    let headers = {
      token: await getStorageData('token'),
      "Content-Type": configJSON.dashboarContentType,
    };

    const {groupId,areaId,storeId} = this.state
    const groupParam = groupId  ? `&group_ids=${groupId}` : ""
    const areaParam = areaId  ? `&area_ids=${areaId}` : ""
    const storeParam = storeId ? `&store_ids=${storeId}` : ""
    const newParam = groupParam + areaParam + storeParam

    const requestMessage = apiCall({
      httpBody: {},
      header: headers,
      url: `${configJSON.getStorewiseDiscountEndpoint}?region_ids=${this.state.regionId}${newParam}${dateFilterQuery}&page_no=${this.state.storeWiseDiscountMetaInfo.storeListPageNo}&per_page=${this.state.storeWiseDiscountMetaInfo.storeItemsPerPage}`,
      httpMethod: configJSON.dashboarApiMethodType,
    });

    this.getStorewiseDiscountCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResForStorewiseDiscount = async (from: string, message: Message) => {
    if (this.getStorewiseDiscountCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.status == 200) {
        const storeDiscountList = this.state.storewiseDiscountData.concat(responseJson.data)
        const metadata = { 
          ...this.state.storeWiseDiscountMetaInfo,
          totalCount: responseJson.meta?.total_count
        };
        this.setState({ storewiseDiscountData:  storeDiscountList, storeWiseDiscountMetaInfo: metadata, getStorewiseDiscountLoading: false})
      }
    }
  }

  receiveDataFromLayout = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.LayoutMessageData)
        );
        if (recievedData.userContext) {
          this.handleUserChange(recievedData.userContext)
        }
    }
  }
  
  getBroadcastedData = (message: Message) => {
    if (message.id === getName(MessageEnum.BroadcastNavbarDataMessage)) {
      const recievedData = message.getData(
        getName(MessageEnum.BroadcastNavbarData)
      );
      if (recievedData.regionMultiId) {
        this.setState({areaId:recievedData.areaId,  storeId: recievedData.storeId,groupId: recievedData.groupId,groupNames: recievedData.groupNames,regionId: recievedData.regionMultiId, regionName: handleDisplayRegion(recievedData.regionMultiId, recievedData.regionNames, recievedData.regionSelectAll).textDisplayed, storeNames: recievedData.storeNames, storewiseDiscountData: [], storeWiseDiscountMetaInfo: { ...this.state.storeWiseDiscountMetaInfo, storeListPageNo: 1, totalCount: 0} }, () => {
          this.handleStorageFilter()
        })
      }
    }
  }

  handleExportConfirm = async (methodType: string) => {
    if (methodType === "pdf") {
      const wrapperPrintElement = document.getElementById("print-wrapper") as HTMLElement
      
      await html2pdf(wrapperPrintElement, {
        jsPDF: {
          format: [1440, 1440],
          unit: "px"
        },
        filename: "DiscountCredit-dashboard.pdf",
        margin: 24,
        pagebreak: { mode: ['css'] },
      })
      this.setState({ isOpenExportModal: false })
    }
  }

  handleDateFilterParam = () => {
    const today = moment().format('YYYY-MM-DD');
    let comomStartDate = moment().subtract(6, 'days').format('YYYY-MM-DD');
    const dateFilter = this.state.filters.find((item) => item?.title === 'Date')?.value;
    let commonEndDate = today;
    let startDate, endDate;
    switch (dateFilter) {
      case 'last_7_days':
        endDate = commonEndDate;
        startDate = comomStartDate;
        break;
      case 'today':
        startDate = today;
        endDate = today;
        break;
      case 'specific':
        const dateFilter = this.state.filters.find((item) => item?.title === 'Date')?.datevalue;
        if (dateFilter?.from && dateFilter?.to) {
          endDate = moment(dateFilter.to).format('YYYY-MM-DD');
          startDate = moment(dateFilter.from).format('YYYY-MM-DD');
        }
        break;
      case 'last_30_days':
        startDate = moment().subtract(29, 'days').format('YYYY-MM-DD');
        endDate = today;
        break;
      default:
        startDate = today;
        endDate = commonEndDate;
    }
    const startDateText = startDate ? handleMomentDateFormat(startDate, 'DD-MM-YYYY') : moment().subtract(6, 'days').format('DD-MM-YYYY')
    const endDateText = endDate ? handleMomentDateFormat(endDate, 'DD-MM-YYYY') : moment().format('DD-MM-YYYY')

    const dateFilterQuery = startDate && endDate
      ? `&date_type=specific_dates&start_date=${startDate}&end_date=${endDate}`
      : '';

    return { startDateText, dateFilterQuery, endDateText }
  };

  handleFilterOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    this.setState({ filterAnchor: event.currentTarget })
  }

  handleCloseFilterPopover = () => {
    this.setState({ filterAnchor: undefined })
  }

  handleFilterChangeAccount = (filters: IFilter[]) => {
    if (checkIsFilterApplied(filters)) {
      localStorage.setItem("filter_value", JSON.stringify(filters));
    } else {
      localStorage.removeItem("filter_value");
    };
    this.setState({ filters, storewiseDiscountData: [], storeWiseDiscountMetaInfo: { ...this.state.storeWiseDiscountMetaInfo, storeListPageNo: 1, totalCount: 0 }, isAppliedFilter: checkIsFilterApplied(filters) }, () => {
      this.getOverallData()
      this.getStorewiseDiscount()
    });
  };

  handleExportModalStats = () => {
    this.setState({ isOpenExportModal: !this.state.isOpenExportModal })
  }

  handleUserChange = (context: IUserContext) => {
    const apiKey = customPermissionApiKey.dashboardPermission;
    const userData = context.user?.attributes.permission_groups;
    const value = checkForDashboardPermissonStatus(apiKey, userData as unknown as Array<PermissionGroupArray>);
    this.setState({
        permissionStatus: value
    });
  }

  fetchMoreData = () => {
    if(this.state.storeWiseDiscountMetaInfo.totalCount !== this.state.storewiseDiscountData.length) {

      this.setState({
        storeWiseDiscountMetaInfo: {
          ...this.state.storeWiseDiscountMetaInfo,
          storeListPageNo: this.state.storeWiseDiscountMetaInfo.storeListPageNo + 1
        }
      }, () => {
        this.getStorewiseDiscount()
      })
    }
    
  }

  handleReturnColorType = () => {
    const { isAppliedFilter } = this.state;
    return isAppliedFilter ? "primary" : "inherit";
  };

  handleStorageFilter = () => {
    const applied_profession_filter = localStorage.getItem("filter_value");
    if (applied_profession_filter) {
      this.setState(
        {
          filters: JSON.parse(applied_profession_filter),
          isAppliedFilter: checkIsFilterApplied(
            JSON.parse(applied_profession_filter)
          ),
        },
        () => {
          this.getOverallData()
          this.getStorewiseDiscount()
        }
      );
    } else {
      this.getOverallData()
      this.getStorewiseDiscount()
    }
  };

  isLoading = () => {
    const {getGeneralDiscountLoading, getCompensationCreditLoading, getLoyaltyCreditLoading, getStorewiseDiscountLoading} = this.state
    return (
      getGeneralDiscountLoading 
      || getLoyaltyCreditLoading 
      || getCompensationCreditLoading 
      || getStorewiseDiscountLoading 
    )
  }
  // Customizable Area End
}