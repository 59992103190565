import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { ISortingData } from "../../../components/src/SortingTableHeader";
import { IFilter } from "../../../components/src/FilterPopover";
import { IExpiryDate } from "./AddLoyaltyController.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey,
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";

export interface ISection {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  admin_user_id: null | string;
  rank: number;
  light_icon: {
    url: null | string;
  };
  light_icon_active: {
    url: null | string;
  };
  light_icon_inactive: {
    url: null | string;
  };
  dark_icon: {
    url: null | string;
  };
  dark_icon_active: {
    url: null | string;
  };
  dark_icon_inactive: {
    url: null | string;
  };
  identifier: null | string;
  section_second_name: string;
  preference_id: number;
  active: boolean;
  online: boolean;
  attributes:{
    id:number | string;
    section_name:string;
  };
}

export interface SectionItem {
  id: string;
  type: string;
  attributes: {
    id: number;
    section_name: string;
    name: string;
  };
}

export interface ILoyalty {
  id: string;
  type: "reward";
  attributes: {
    spend_amount?: number;
    earned_points?: number;
    redeemed_points?: number;
    received_amount?: number;
    min_redeemed_points?: number;
    active: boolean;
    expiry_date: string;
    all_section_enabled: boolean;
    sections: ISection[];
  };
}

export const configJSON = require("./config");

export interface Props {
  navigation: {
    navigate: (routeName: string) => void,
    goBack: () => void,
    history: {
      push: (path: string) => void;
    };
  };
  id: string | number;
}

export interface S {
  drawerWidth: number;
  popOverOpened: boolean;
  popOverItemId: string;
  popOverItemStatus: string;
  popOverTop: number;
  popOverLeft: number;
  hoveredButton: string;
  loyalties: ILoyalty[];
  page: number;
  pageSize: number;
  sortingData: ISortingData;
  query: string;
  filterAnchor: { currentTarget: {} } | undefined | HTMLDivElement;
  filters: IFilter[];
  isFilterApplied: boolean;
  sectionList: { attributes: { section_name: string; id: number } }[];
  expiryDateList: IExpiryDate[];
  searchText: string;
  disableDialogVisible?: boolean;
  disableDialogId: string;
  permissionStatus: PermissionStatus;
  snackBarMessage: string;
  snackBarMessageType: "error" | "success" | "info" | "warning" | undefined;
  isLoadingPermission: boolean;
  isLoading: boolean
}

export interface SS {
  id: string | number;
}

const emptyLoyalty: ILoyalty = {
  id: "",
  type: "reward",
  attributes: {
    spend_amount: 0,
    earned_points: 0,
    redeemed_points: 0,
    received_amount: 0,
    min_redeemed_points: 0,
    active: false,
    expiry_date: "",
    all_section_enabled: false,
    sections: [],
  },
};
// Customizable Area End

export default class LoyaltyListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getLoyaltysCallId: string = "";
  sectionsCallId: string = "";
  expiriesCallId: string = "";
  updateLoyaltyCallId: string = "";
  tableRefs: HTMLElement | null = null;

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),

      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.LayoutDataMessage),
      getName(MessageEnum.SearchTextMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      isLoadingPermission: true,
      snackBarMessage: "",
      snackBarMessageType:"success",
      disableDialogId: "",
      popOverOpened: false,
      drawerWidth: 0,
      popOverItemStatus: "",
      popOverLeft: 0,
      hoveredButton: "",
      loyalties: [],
      popOverTop: 0,
      page: 0,
      popOverItemId: "",
      pageSize: 10,
      sortingData: {
        number: "",
        sections: "",
        expiry_date: "",
      },
      query: "",
      filters: [
        {
          title: "Section",
          value: "",
          type: "multipleSelect",
          options: [],
        },
        {
          title: "Expiry Date",
          value: "",
          type: "autocompolete",
          options: [],
        },
        {
          title: "Status",
          type: "select",
          value: "",
          options: [
            {
              value: "active",
              label: "Active",
            },
            {
              value: "passive",
              label: "Deactivated",
            },
          ],
        },
      ],
      filterAnchor: undefined,
      isFilterApplied: false,
      sectionList: [],
      expiryDateList: [],
      searchText: "",
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      },
      isLoading: false
    };
  }

  processMeta = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let newLoyaltys = Array<ILoyalty>(responseJson.meta.total_count);
    for (let indx = 0; indx < responseJson.meta.total_count; indx++) {
      if (
        indx >= (responseJson.meta.current_page - 1) * 10 &&
        indx < responseJson.meta.current_page * 10
      ) {
        newLoyaltys[indx] =
          responseJson.data[indx - (responseJson.meta.current_page - 1) * 10];
      } else {
        if (!this.state.loyalties[indx] || !this.state.loyalties[indx].id)
          newLoyaltys[indx] = { ...emptyLoyalty };
      }
    }
    this.setState({ loyalties: newLoyaltys });
  };

  receiveLoyaltyList = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.setState({isLoading:false})
    if (responseJson) {
      if (responseJson.meta) {
        this.processMeta(message);
      } else {
        let areas = responseJson.data as ILoyalty[];
        areas = areas.filter((elem) => elem.attributes);
        this.setState({ loyalties: areas });
      }
    }
  };

  receiveSectionList = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson) {
      this.setState({ sectionList: responseJson.data });
      const existingFilter = this.state.filters.find(
        (item) => item.title === "Section"
      );
      if (!existingFilter) return;
      existingFilter.options = responseJson.data.map((item: SectionItem) => {
        return {
          value: item.id,
          label: item.attributes.section_name,
        };
      });
      this.setState({ filters: [...this.state.filters] });
    }
  };

  receiveExpireList = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson) {
      this.setState({ sectionList: responseJson.data });
      const newFilters = [...this.state.filters];
      let filterIndex = newFilters.findIndex(
        (item) => item.title === "Expiry Date"
      );
      if (filterIndex >= 0) {
        newFilters[filterIndex] = {
          ...newFilters[filterIndex],
          options: responseJson.data.map((item: IExpiryDate) => ({
            label: item.attributes.expiry_date,
            value: item.id,
          })),
        };
        this.setState({ filters: newFilters });
      }
    }
  };

  async receive(from: string, message: Message) {
    this.retrieveLayoutData(message);
    this.gatherSearchTextData(message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId === this.getLoyaltysCallId) {
        this.receiveLoyaltyList(message);
      } else if (apiRequestCallId === this.updateLoyaltyCallId) {
        this.getLoyaltys();
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        const errors = responseJson.data?.attributes?.errors?.sections;

        if (errors && errors.length > 0) {
          this.setState({
            snackBarMessage: errors[0],
            snackBarMessageType: "error"
          })
        }
      } else if (apiRequestCallId === this.sectionsCallId) {
        this.receiveSectionList(message);
      } else if (apiRequestCallId === this.expiriesCallId) {
        this.receiveExpireList(message);
      }
    }
  }

  createData(
    number: string,
    section: string,
    expiryDate: string,
    status: string,
    selid: string
  ) {
    return { number, section, expiryDate, status, selid };
  }

  gatherSearchTextData = (message: Message) => {
    if (message.id === getName(MessageEnum.SearchTextMessage)) {
      const recievedData = message.getData(
          getName(MessageEnum.SearchMessageText)
      );
      if (recievedData) {
          this.handleSearchTextChange(recievedData.searchText)
      }
    }
  };

  retrieveLayoutData = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.LayoutMessageData)
        );
        if (recievedData.userContext) {
            this.handleUserChange(recievedData.userContext)
        }
    }
  }

  getExpiryList = async () => {
    const header = {
      token: await getStorageData(configJSON.tokenKey),
      "Content-Type": configJSON.contentType,
    };
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.expiriesCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.expiryListApi
    );

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  getLoyaltys = async () => {
    this.setState({isLoading:true})
    const header = {
      "Content-Type": configJSON.contentType,
      token: await getStorageData(configJSON.tokenKey),
    };
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getLoyaltysCallId = getDataMsg.messageId;

    let sectionQuery: string = "";
    const sectionFilter = this.state.filters.find(
      (item) => item.title === "Section"
    );
    if (sectionFilter && sectionFilter.value && sectionFilter.value.length) {
      sectionQuery = (sectionFilter.value as string[]).reduce(
        (param: string, varc: string) => param + "&filter_by[section_ids][]=" + varc,
        ""
      );
    }
    let statusQuery: string = "";
   const statusFilter = this.state.filters.find(
      (item) => item.title === "Status"
    );
    if (statusFilter && statusFilter.value) {
      statusQuery = "&filter_by[status]=" + statusFilter.value;
    }

    let expiryQuery: string = "";
    const expiryFilter = this.state.filters.find(
      (item) => item.title === "Expiry Date"
    );
    if (expiryFilter && expiryFilter.value) {
      expiryQuery = "&filter_by[expiry_date]=" + expiryFilter.value;
    }

    let searchTextQuery = this.state.searchText
      ? "&filter_by[query]=" + this.state.searchText
      : "";

    let url =
      configJSON.loyaltyListApi +
      "?page_no=" +
      (this.state.page + 1) +
      statusQuery +
      expiryQuery +
      sectionQuery +
      searchTextQuery +
      this.state.query;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  getSectionList = async () => {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const headerSection = {
      "Content-Type": configJSON.contentType,
      token:await getStorageData(configJSON.tokenKey),
    };
    this.sectionsCallId = getDataMsg.messageId;

    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sectionListApi
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerSection)
    );

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  updateLoyalty = async (loyaltyId: string, isActive: boolean) => {
    const header = {
      "Content-Type": configJSON.contentType,
      token: await getStorageData(configJSON.tokenKey),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateLoyaltyCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loyaltyListApi + "/" + loyaltyId
    );
    const datas = {
      active: isActive,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ data:datas })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async componentDidMount() {
    super.componentDidMount();

    this.handleStorageFilter()
  }

  handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => {
    if (page !== this.state.page) {
      this.setState({ page }, () => this.getLoyaltys());
    }
  };

  handleAddLoyalty = () => {
    this.props.navigation.navigate("AddLoyalty");
  };

  handleEditLoyalty = (loyaltyId: string) => {
    this.setState({ popOverOpened: false });
    this.props.navigation.history.push("Marketing-LoyaltyList-EditLoyalty?loyaltyId=" + loyaltyId);
    window.localStorage.setItem("loyaltyId", loyaltyId);
  };

  handleViewLoyalty = (loyaltyId: string) => {
    this.setState({ popOverOpened: false });
    this.props.navigation.history.push("LoyaltyView?loyaltyId=" + loyaltyId);
    window.localStorage.setItem("loyaltyId", loyaltyId);
  };

  handleQueryChange = (query: string) => {
    this.setState({ query }, () => this.getLoyaltys());
  };

  sortingProps = {
    onChange: (sortingData: ISortingData) => this.setState({ sortingData }),
    onQueryChange: (query: string) => this.handleQueryChange(query),
    width: "30%",
  };

  checkIsFilterApplied = (filters: IFilter[]) => filters.some(filterItem => filterItem.value?.length)

  handleFilterChange = (filters: IFilter[]) => {
    const isFilterApplied = this.checkIsFilterApplied(filters)
    if (isFilterApplied) {
      localStorage.setItem(configJSON.loyaltySystemFilterStorageKey, JSON.stringify(filters));
    } else {
      localStorage.removeItem(configJSON.loyaltySystemFilterStorageKey);
    };
    this.setState({ filters, isFilterApplied }, this.getLoyaltys);
  };

  handleStatusChange = (varid: string, active: boolean) => {
  
    const loyalty = this.state.loyalties.find(
      (item) => item.id === varid
    ) as ILoyalty;
    const isAllSection = Boolean(loyalty.attributes.all_section_enabled);

    Boolean(isAllSection && !active)
      ? this.setState({ disableDialogVisible: true, disableDialogId: varid })
      : this.updateLoyalty(varid, active);
  
  };

  handleSearchTextChange = (searchText: string) => {
    this.setState({ searchText, page: 0 }, () => this.getLoyaltys());
  };

  handleDisableDialogClose = () => {
    this.setState({ disableDialogVisible: false });
  };

  handleDisableDialogConfirm = () => {
    this.updateLoyalty(this.state.disableDialogId, false);
    this.handleDisableDialogClose();
  }

  handleFilterPopverOpen = (event: { currentTarget: {} }) => {
    this.setState({
      filterAnchor: event.currentTarget as HTMLDivElement ,
    });

    if(this.state.sectionList.length === 0) {
      this.getSectionList();
      this.getExpiryList();
    }
  }

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.loyaltySystem;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value,
      isLoadingPermission: false
    })
  };


  closeSnackBar = () => {
    this.setState({ snackBarMessage: "",snackBarMessageType:undefined });
  };

  handleReturnColorType = () => this.state.isFilterApplied ? "primary" : "inherit";

  handleStorageFilter = () => {
    const appliedLoyaltySystemFilter = localStorage.getItem(
      configJSON.loyaltySystemFilterStorageKey
    );
    if (appliedLoyaltySystemFilter) {
      const filters = JSON.parse(appliedLoyaltySystemFilter)
      this.setState(
        {
          filters,
          isFilterApplied: this.checkIsFilterApplied(filters),
        },
        this.getLoyaltys
      );
    } else {
      this.getLoyaltys()
    }
  };

  // Customizable Area End
}
