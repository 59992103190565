// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IFilter } from "../../../components/src/FilterPopover";
import { makeApiMessage } from "../../../components/src/common";
import { CustomEnums, getCustomEnumName, navigateTo, randomNumberGenerator } from "../../utilities/src/CustomBlockHelpers";
import ImportExportWebAdapter from "../../adapters/src/ImportExportWebAdapter";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey,
  checkIsFilterApplied
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  preferenceList: PreferenceListData[],
  permissionStatus: PermissionStatus;
  openModalId: number | null;
  defaultIcon: string
  currentPage: number
  open: boolean,
  isLoading: boolean;
  deactivated: boolean
  verifyMessage: string
  deActivateMessage: string
  preferenceSuccessMessage: string
  confirmButtonText: string;
  setLoaded: number;
  uploadedFile: File | null;
  deActivatedItem: { id : number | string, title: string};
  openAction: EventTarget & HTMLButtonElement | null;
  filterAnchor: HTMLDivElement | boolean | undefined;
  filters: IFilter[];
  isFilterApplied: boolean;
  textValue: string;
  optionSorting: {
    value: string
  };
  serviceSorting: {
    value: string
  };
  iconSorting: {
    value: string
  },
  errorSnackbarOpen: boolean;
  snakcbarSeverity: "error" | "warning" | "info" | "success";
  errorMessage: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string | number;

  // Customizable Area End
}
// Customizable Area Start
export interface PreferenceListData {
  id: number
  attributes: {
    preference_first_name: string,
    preference_second_name: string,
    active: boolean,
    gallery_icon: {
      image: string
    } | null
  }
}

// Customizable Area End

// Customizable Area Start
export default class PreferenceListController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAllPreferenceCallId: string = "";
  deactivateCallId: string = "";
  adapter: ImportExportWebAdapter;
  importPreferencesApiId: string = "";
  exportPreferencesApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getCustomEnumName(CustomEnums.ImportExportPopupDoneMessage),
      getCustomEnumName(CustomEnums.ImportExportPopupFileUploadMessage),
      getCustomEnumName(CustomEnums.ImportExportClearFileMessage),
      getCustomEnumName(CustomEnums.ImportExportPopupCloseButtonClicked),
      getCustomEnumName(CustomEnums.ImportExportPopupClose),
      getName(MessageEnum.LayoutDataMessage),
      getName(MessageEnum.SearchTextMessage)
    ];
    this.adapter = new ImportExportWebAdapter();

    this.state = {
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false,
        activatePermission: false
      },
      isLoading: false,
      preferenceList: [],
      openModalId: null,
      defaultIcon: configJSON.defaultIcon,
      currentPage: 1,
      open: false,
      deactivated: false,
      verifyMessage: configJSON.verifyPreferenceMessage,
      deActivateMessage: configJSON.deactivatePreferenceMessage,
      preferenceSuccessMessage: configJSON.preferenceSuccessMessage,
      confirmButtonText: configJSON.confirmButtonText,
      setLoaded: 0,
      errorSnackbarOpen: false,
      snakcbarSeverity: 'error',
      errorMessage: '',
      uploadedFile: null,
      deActivatedItem: {
        id: "",
        title: ""
      },
      openAction: null,
      textValue: "",
      optionSorting: {
        value: ""
      },
      serviceSorting: {
        value: ""
      },
      iconSorting: {
        value: ""
      },
      filterAnchor: false,
      filters: [
        {
          title: "Status",
          type: "select",
          options: [
            {
              label: "Activate",
              value: "active",
            },
            {
              label: "Deactivated",
              value: "inactive",
            },
          ],
          value: "",
        },

      ],
      isFilterApplied: false

    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount(): any {
    // Customizable Area Start
    this.handleStorageFilter()
    // Customizable Area End
  }
  // Customizable Area Start

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.preferenceListCreation;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);

    this.setState({
      permissionStatus: value
    })
  };

  handleInputChangeDebounced = () => {
    this.getAllPreferenceList(this.state.optionSorting.value, this.state.serviceSorting.value, this.state.textValue,this.state.iconSorting.value)
  }

  handleFilterAnchor = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    this.setState({ filterAnchor: event.currentTarget });
  }

  handlePopoverClose = () => {
    this.setState({ filterAnchor: undefined });
  }

  iconBtnClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    this.setState({ openAction: event.currentTarget })
  }

  totalPageHandler = (totalItems: number, itemsPerPage: number) => {
    return Math.ceil(totalItems / itemsPerPage)
  }

  displayDataHandler = (itemsPerPage: number) => {
    return this.state.preferenceList?.slice((this.state.currentPage - 1) * itemsPerPage, this.state.currentPage * itemsPerPage);
  }

  handleSortingByOption = () => {
    let response;
    let params;
    if (this.state.optionSorting.value === "") {
      response = { value: "optionUp" };
    } else if (this.state.optionSorting.value === "optionUp") {
      response = { value: "optionDown" };
    } else {
      response = { value: "" };
    }
    this.setState({
      optionSorting: response,
      serviceSorting:{
        value:""
      },
      iconSorting:{
        value:""
      }

    });
   
    if (response.value === "optionUp") {
      params = "asc";
    } else if (response.value === "optionDown") {
      params = "dsc";
    } else {
      params = "";
    }
    this.getAllPreferenceList(params,"",this.state.textValue)
  };
  handleSortingByService = () => {

    let response;
    let params;
    if (this.state.serviceSorting.value === "") {
      response = { value: "serviceUp" };
    } else if (this.state.serviceSorting.value === "serviceUp") {
      response = { value: "serviceDown" };
    } else {
      response = { value: "" };
    }
    this.setState({
      serviceSorting: response,
      optionSorting:{
        value:""
      },
      iconSorting:{
        value:""
      },
    });

    if (response.value === "serviceUp") {
      params = "asc";
    } else if (response.value === "serviceDown") {
      params = "dsc";
    } else {
      params = "";
    }
    this.getAllPreferenceList("",params,this.state.textValue)
  };
  handleSortingByIcon = () => {

    let response;
    let params;
    if (this.state.iconSorting.value === "") {
      response = { value: "iconUp" };
    } else if (this.state.iconSorting.value === "iconUp") {
      response = { value: "iconDown" };
    } else {
      response = { value: "" };
    }
    this.setState({
      iconSorting: response,
      optionSorting:{
        value:""
      },
      serviceSorting:{
        value:""
      },
    });

    if (response.value === "iconUp") {
      params = "asc";
    } else if (response.value === "iconDown") {
      params = "dsc";
    } else {
      params = "";
    }
    this.getAllPreferenceList("","",this.state.textValue,params)
  };
  handleClick = (modalId: number) => {
    this.setState((prevState) => ({
      openModalId: prevState.openModalId === modalId ? null : modalId,
    }));
  };
  goToNextPage = () => {
    this.setState((prevState) => ({
      currentPage: prevState.currentPage + 1,
    }));
  };
  handleCloseConfirmModal = () => {
    this.setState({
      open: false
    })
  }
  handleDelete = () => {
    this.handleActivateDeactivate(this.state.deActivatedItem.id, false)
    this.setState({
      open: false,
      deactivated: true
    })

  };
  onChangeValue = (value: string) => {
    this.setState({
      textValue: value
    }, () => {
      this.handleInputChangeDebounced()
    })
  }
  handleOpenModal = (item: PreferenceListData) => {
    if(item?.attributes?.active) {
      const result = {
        id: item.id,
        title: item.attributes.preference_first_name
      };
      this.setState({
        deActivatedItem: result,
        open: true
      })
    } else {
      // activate preference api call
      this.handleActivateDeactivate(item.id, true)
    }
  }
  handleGoBack = () => {
    this.setState({
      deactivated: false
    })
  };
  goToPreviousPage = () => {
    this.setState((prevState) => ({
      currentPage: prevState.currentPage - 1,
    }));
  };
  handleFilterChange = (filters: IFilter[]) => {
    const isFilterApplied = checkIsFilterApplied(filters)
    if (isFilterApplied) {
      localStorage.setItem("preference_filter_value", JSON.stringify(filters));
    } else {
      localStorage.removeItem("preference_filter_value");
    };
    this.setState({ filters,  isFilterApplied }, ()=> {
      this.getAllPreferenceList(this.state.optionSorting.value,this.state.serviceSorting.value,this.state.textValue,this.state.iconSorting.value)
    });
  };
  handleAddPreference = () => {
    this.props.navigation.history.push("/Products-PreferenceListAdd")
  }
  handleView = (item: PreferenceListData) => {
    this.props.navigation.history.push(`Products-PreferenceListView/${item.attributes.preference_first_name}`, { state: item })

  }
  handleEdit = (item: PreferenceListData) => {
    this.props.navigation.history.push(`Products-PreferenceListEdit/${item.attributes.preference_first_name}`, { state: item })
  }
  getAllPreferenceList = (firstvalue?: string, secondValue?: string, query?: string, icon?: string) => {
   this.setState({isLoading:true})
    const headers = {
      "Content-Type": configJSON.categoryApiContentType,
      token: window.localStorage.getItem(configJSON.token)
    };
    const status = this.state.filters.find((item) => item.title === "Status")?.value;

    const apiUrl = configJSON.getAllPreferenceEndPoint + "?" +
      (status ? `filter_by[status]=${status}` : "") + (firstvalue ? `&preference_first_name=${firstvalue}` : "") + (secondValue ? `&preference_second_name=${secondValue}` : "")
      + (query ? `&filter_by[query]=${query}` : "") + (icon ? `&icon_id=${icon}` : "")

    const preferenceListDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllPreferenceCallId = preferenceListDataMessage.messageId;

    preferenceListDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );

    preferenceListDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    preferenceListDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(preferenceListDataMessage.id, preferenceListDataMessage);
  }
  handleActivateDeactivate = (item_id: string | number, activate: boolean) => {

    let headers = {
      "Content-Type": configJSON.categoryApiContentType,
      token: window.localStorage.getItem(configJSON.token)
    };
    let httpBody = {}

    const activateDeactivateMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deactivateCallId = activateDeactivateMessage.messageId;

    const activeOrDeactiveText = activate ? configJSON.activeText : configJSON.deActiveText ;

    activateDeactivateMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updatePreferenceListEndPoint}/${item_id}/${activeOrDeactiveText}`
    );

    activateDeactivateMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    activateDeactivateMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    activateDeactivateMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpUpdateMethodType
    );

    runEngine.sendMessage(activateDeactivateMessage.id, activateDeactivateMessage);

  }
  handleResponseForGetAllPreference = (from: string, message: Message) => {
    if (this.getAllPreferenceCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse) {
        this.setState({
          preferenceList: apiResponse.data,
          isLoading: false
        })

      }
    }
  }
  handleResponseForDeactivate = (from: string, message: Message) => {
    if (this.deactivateCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse) {
        const updatedFeedData = this.state.preferenceList.map((object: PreferenceListData) => {

          if (object.id == apiResponse.data.id) {
            object.attributes.active = !object.attributes.active;
          }
          return object;
        })
        this.setState({
          preferenceList: updatedFeedData,
          openModalId: null,

        })
      }

    }
  }
  
  handleResponseForImportCSV = (from: string, message: Message) => {
    if (this.importPreferencesApiId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse) {
        const message = new Message(getCustomEnumName(CustomEnums.ImportExportAPIResponse))
        message.addData('APIresponse', apiResponse)
        runEngine.sendMessage(message.id, message);
        if (apiResponse?.message) {
          this.setState({ snakcbarSeverity: 'success', errorSnackbarOpen: true, errorMessage: apiResponse.message })
          navigateTo({ props: this.props, screenName: "PreferenceList" })
          this.getAllPreferenceList("", "", "", "")
        }
      }

    }
  }

  handleSnackbarClose = () => {
    this.setState({errorSnackbarOpen: false})
  }

  handleImportFile = () => {
    const apiUrl = configJSON.importPreferenceCsvApiurl;
    
    const formData = new FormData();
    formData.append('data[file]', this.state.uploadedFile as File);

    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: configJSON.httpPostType,
      body: formData,
      isHeader: true
    });

    this.importPreferencesApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);    
  }

  handleCloseActionPreferenceList = () => {
    this.setState({ openAction: null })
  }

  handlePreferenceExportTemplate = () => {
    this.setState({ openAction: null })
  }

  handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ setLoaded: 0 })
    const file = event.target.files;

    let randomNumber = randomNumberGenerator(1, 9);
    const delay = randomNumber * 25;
    const uploadInterval = setInterval(() => {
      this.setState({
        setLoaded: updatePreferencesLoadingTime(this.state.setLoaded)
      }, () => {
        const message = new Message(getCustomEnumName(CustomEnums.ImportExportPopupFileMessage))
        message.addData('returnValue', { setLoaded: this.state.setLoaded, file: file && file[0] })
        runEngine.sendMessage(message.id, message)
      })

    }, delay);

    // for adding 20 percent every time
    function updatePreferencesLoadingTime(prevLoaded: number) {
      if (prevLoaded >= 100) {
        clearInterval(uploadInterval);
        return 100;
      }
      return prevLoaded + 20
    }

    const checkPreferenceFile = file && file[0];
    this.setState({ uploadedFile: checkPreferenceFile as File})
  }

  handleOpenPreferenceImportModal = () => {
    this.setState({ openAction: null });
    let message = new Message(getCustomEnumName(CustomEnums.ImportExportPopupMeassage))
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props,
    )
    this.send(message)
  };

  handleImportExportModalClose = () => {
    const message = new Message(getCustomEnumName(CustomEnums.ImportExportPopupClose))
    message.addData('ParentpageRoute', 'PreferenceList')
    runEngine.sendMessage(message.id, message)
  };

  receiveSearchTextData = (message: Message) => {
    if (message.id === getName(MessageEnum.SearchTextMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.SearchMessageText)
        );
        if (recievedData) {
          this.onChangeValue(recievedData.searchText)
        }
    }
  }
  
  gatherLayoutData = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.LayoutMessageData)
        );
        if (recievedData.userContext) {
          this.handleUserChange(recievedData.userContext)
        }
    }
  }

  handlePreferenceExportCsv = () => {
    this.setState({ openAction: null });

    const apiUrl = configJSON.exportPreferenceCSVApiUrl;

    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: configJSON.httpGetType,
    });

    this.exportPreferencesApiId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);  
  };

  exportCSVfileRes = (apiRequestCallId:string, responseJson: { data: { url: string }}) => {
    if(apiRequestCallId === this.exportPreferencesApiId) {
      if (responseJson) {
        location.href = responseJson.data.url
      }
    }
  };

  handleReturnColorType = () => this.state.isFilterApplied ? "primary" : "inherit"

  handleStorageFilter = () => {
    const applied_preference_filter = localStorage.getItem("preference_filter_value");
    if (applied_preference_filter) {
      this.setState(
        {
          filters: JSON.parse(applied_preference_filter),
          isFilterApplied: checkIsFilterApplied(
            JSON.parse(applied_preference_filter)
          ),
        },
        () => this.getAllPreferenceList("", "", "", "")
      );
    } else {
      this.getAllPreferenceList("", "", "", "")
    }
  };

  // Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.receiveSearchTextData(message)
    this.gatherLayoutData(message)
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.handleResponseForGetAllPreference(from, message)
    this.handleResponseForDeactivate(from, message)

    if (from === getCustomEnumName(CustomEnums.ImportExportPopupFileUploadMessage)) {
      this.handleFileUpload(message.properties.fileEvent)
    };

    if (from === getCustomEnumName(CustomEnums.ImportExportPopupCloseButtonClicked)) {
      this.handleImportExportModalClose();
    };

    if (from === getCustomEnumName(CustomEnums.ImportExportPopupDoneMessage)) {
      this.handleImportFile();
    };

    if (from === getCustomEnumName(CustomEnums.ImportExportClearFileMessage)) {
      this.setState({ 
        uploadedFile: message.properties.uploadedFile,
        setLoaded: message.properties.setLoaded 
      });
    };

    this.exportCSVfileRes(apiRequestCallId, responseJson);
    // Customizable Area End
  }

}
// Customizable Area End
