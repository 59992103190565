import React, { useCallback } from "react";
import { InputField } from "./customComponents/CustomTags.web"
import { StyledCheckBox } from "./CustomTheme.web";
import { makeStyles } from '@material-ui/core/styles';
import { SelectAllButton } from "../../blocks/CfPriceList/src/PriceListView.web";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core/styles";
import { IProductItems } from "../../blocks/CfPriceList/src/PriceListViewController.web";
import {styled, Box, TextField, TextFieldProps,Popper,PaperProps,PopperProps,Paper ,FormControlLabel, Radio, } from "@material-ui/core";
import { colors } from "../../blocks/utilities/src/Colors";
import {isEmpty} from "lodash"
const autoSeletStyle = {
    radioIcon: {
      display: "inline-block",
      borderRadius: "51%",
      width: 20,
      height: 20,
      border: "1px solid #64748C",
    },
    radioCheckedIcon: {
      display: "inline-block",
      width: 20,
      borderRadius: "51%",
      height: 20,
      border: "6px solid #204B9D",
      color: "#204b9c",
    },
    inputField: { 
      paddingTop: "0px",
        }
  };
interface Props {
    dataTestId: string,
    checkSelectAllText: Function,
    value: { id: string | number, option: string }[],
    selectedOptionForList?: { id: string | number, option: string }[],
    optionList: Array<IProductItems | { id: string | number, option: string }>,
    placeHolderText: string,
    debouncedFunction: Function,
    handleScroll: Function,
    handleClickOnSelectAll: Function,
    handleEmptyAutoSelectValue: Function,
    handleSelectOptions: Function,
    emptyDataMessage: string,
    isOnBlurFunction?: Function,
    id?: string,
    name?: string,
    style?: Object,
    isError?: boolean,
    customPaper?: boolean,
    customPopper?: boolean
    changeInputOnClear?: boolean
    listBoxHeight?:string;
    isRadioButtonShow?: boolean;
    isSelectAll?: boolean;
}

const useStyles = makeStyles(() => ({
    newProductDropdown: {
        minHeight: 56,
        minWidth: 300,
        maxWidth: "100%"
    },
    menuItems: {
        display: 'flex',
        alignItems: 'center',
        width: '100%'
    },
    mainComp:{
        maxHeight: 200,
        width: '100%',
    }
}));

const PageConatinerSearchableMultiSelect = ({
    dataTestId = 'searchableMultiselect',
    checkSelectAllText,
    value,
    selectedOptionForList,
    optionList,
    placeHolderText = "",
    debouncedFunction,
    handleScroll,
    handleClickOnSelectAll,
    handleEmptyAutoSelectValue,
    handleSelectOptions,
    emptyDataMessage = "No Data Found",
    isOnBlurFunction,
    id = "productAutoSelect",
    name = "productAutoSelect",
    style = {},
    isError,
    customPaper,
    customPopper,
    changeInputOnClear,
    listBoxHeight="200",
    isRadioButtonShow,
    isSelectAll
}: Props) => {
    const classes = useStyles();
    const handleChangeInputOnClear = (event: unknown, value: unknown, reason: string) => {
        if (reason === "clear") {
            debouncedFunction("")
        }
    }

    let optionArray = [];
    if (optionList.length > 1) {
      optionArray = [{ id: "-1", option: "Select All" },
      ...optionList.map((item) => ({ id: item.id, option: "attributes" in item ? item.attributes.product_name : item.option }))];
    } else {
      optionArray = optionList.map((item) => ({ id: item.id, option: "attributes" in item ? item.attributes.product_name : item.option }));
    }

    return (
        <SimpleCustomAutocomplete
            disableClearable 
           data-test-id={dataTestId}
            label={'productAutoSelect'}
            id={id}
            name={name}
            multiple
            noOptionsText={emptyDataMessage}
            value={value}
            options={optionArray}
            disableCloseOnSelect
            getOptionLabel={(option: { id: string | number, option: string }) => option.option}
            onChange={changeInputOnClear ? handleChangeInputOnClear : undefined}
            renderOption={(option: { id: string, option: string }, props: Object) => (
                <>
                  {isRadioButtonShow ? (
                    <Box
                    component={"li"}
                    {...props}
                    id={`products-${option.id}`}
                    data-test-id={`products-${option.id}`}
                    onClick={() => {
                        handleSelectOptions(selectedOptionForList, option)
                    }}
                >
                    <Radio
                      checked={selectedOptionForList?.map((item) => item.id).includes(option.id)}
                      icon={<span style={autoSeletStyle.radioIcon} />}
                      checkedIcon={<span style={autoSeletStyle.radioCheckedIcon} />}
                    />
                    <span>{option.option}</span>
                  </Box>  
            ) :
                    <Box
                        component={"li"}
                        {...props}
                        id={`products-${option.id}`}
                        data-test-id={`products-${option.id}`}
                        className={classes.menuItems}
                        onClick={() => {
                            handleSelectOptions(selectedOptionForList, option)
                        }}
                    >
                        <StyledCheckBox checked={selectedOptionForList?.map((item) => item.id).includes(option.id)} />&nbsp;<span>{option.option}</span>
                    </Box>}
                </>
            )}
            renderInput={(params:JSX.IntrinsicAttributes & TextFieldProps) => {
                const displayValues = value.length > 2 
                    ? `${value.slice(0, 2).map(item => item.option).join(', ')}...`
                    : value.map(item => item.option).join(', ');
                return (
                    <TextFieldComp
                        {...params}
                        style={{ paddingTop: "0px" }}
                        data-test-id="autoCompleteInput"
                        fullWidth={true}
                        placeholder={isEmpty(value) && !isSelectAll ? placeHolderText : ""}
                        variant='outlined'
                        className={`${isError && 'isError'}`}
                        onChange={(e) => { debouncedFunction(e.target.value) }}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <span style={{color:"#4d4d4d",textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",fontWeight:500}}>
                                    {isSelectAll ? "Select All" : displayValues}
                                </span>
                            )
                        }}
                    />
                );
            }}
            ListboxProps={{
                onScroll: handleScroll,
                style: {
                    maxHeight: listBoxHeight,
                }
            }}
            PaperComponent={useCallback((props: PaperProps) => {
                return (<Paper
                            {...props}
                            className={classes.mainComp}
                        />)
            },[])}
            PopperComponent={useCallback((props: PopperProps) => {
                return (<Popper {...props}
                    placement="bottom"
                />)
            },[])}
        />
    );
}

const SimpleCustomAutocomplete: any = withStyles({
    root: {
        width: "230px",
        "@media only screen and (max-width: 1024px)": {
            width: "200px",
        },
        "& .MuiInputBase-root": {
            borderRadius: "8px",
            minHeight: "56px",
            flexWrap: "nowrap",
            "@media only screen and (max-width: 1024px)": {
                maxHeight: '44px',
                alignContent: 'center',
                fontSize: "12px",
                height: 'auto',
                padding: "9.5px 4px",
                minHeight: "40px",
                paddingLeft:"20px !important"
            },
        },
        "& .MuiChip-deleteIcon": {
            display: 'none'
        },
        "& .MuiChip-root": {
            color: "#204B9C",
            backgroundColor: "#204B9C1A",
        }
    },
})(Autocomplete);

const TextFieldComp = withStyles({
    root: {
        "&.isError .MuiOutlinedInput-notchedOutline": {
            borderColor: 'red'
        },
        "@media only screen and (max-width: 1024px)": {
            '& .MuiInputBase-input': {
                fontSize: '12px',
                padding: '6.5px 14px !important'
            },
        },
    },
})(TextField)

const LabelOptions = styled("p")({
    fontWight:500,
    fontSize:"16px",
    "@media only screen and (max-width: 1024px)": {
      fontSize:"14px",
    }
  })

export default PageConatinerSearchableMultiSelect