import React from "react";

// Customizable Area Start

// Customizable Area End

// Customizable Area Start
import PageContainerController, {
  Props,
  configJSON,
} from "./PageContainerController.web";
// Customizable Area End

export class PageContainer extends PageContainerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <></>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default PageContainer;
// Customizable Area End
