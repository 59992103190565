import React, { useEffect } from 'react';
import {  createTheme,ThemeProvider,makeStyles,Theme,createStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Box, Typography} from '@material-ui/core'


const checkBoxTrue = require('../check_true.svg');
const checkBoxFalse = require("../check_false.svg")
import CustomInput from '../customComponents/CostumInput.web';
import { FilterCustomAutocomplete, InputField } from '../customComponents/CustomTags.web';





interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
    className?:any;
    style?:any
  }

  function a11yProps(index: any) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }



  const useStyles = makeStyles((theme:Theme)=>createStyles({
    paper: {
      border: '1px solid',
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
    },
 
      activeMenuItem: {
        backgroundColor: "#204B9C",
        color: "#ffffff"
      },
      selectedTab: {
        // Add your custom styles here
        // For example:
        backgroundColor: '#204B9C',
        color: '#fff',
      },
      indicator: {
        width: 0,
      },
      tabLabel: {
        justifyContent: 'flex-start',
        textAlign: 'left',
        textTransform:'none',
        fontSize:'14px',
        fontWeight: 500,
        fontFamily:'Montserrat',
        color:'rgba(0, 0, 0, 1)',
        opacity:1,
      },
      tabWrapper: {
        // Your custom styles for the tab wrapper
        // For example:
        alignItems: 'flex-start',
        
      },
      boxRoot: {
        padding: 0,
      },
      flexBox:{
         display: "flex",
      gap: "10px",
      flexDirection: "column",
      marginRight: "10px",
      padding: "0 18px",
      },
      inputHeading: {
        color: "rgba(0, 0, 0, 1)",
        fontSize: "14px",
        fontWeight: 600,
      },
      selectedChildrenTab:{
        backgroundColor:'rgba(32, 75, 156, 1) ', 
        color:'#fff',padding:'13px 0 13px 17px',
           height:"47.8px",
             display:'flex',
                        alignItems:'center',
                        fontSize:'14px',
                        paddingLeft:'30px',
                        fontWeight:500,
      },
      unSelectedChilderenTab:{
      // padding:'13px 0 13px 17px'
                       display:'flex',
                        alignItems:'center',
                        fontSize:'14px',
                        paddingLeft:'30px',
                         height:"47.8px",
                         fontWeight:500,
      },
      tabPanelChild:{
        display:'flex',
        flexDirection:'column', 
       
      },
      checkboxContainer:{
        display:'flex', 
        gap:'10px'
      },
     checkboxTypography:{
      fontWeight:500, 
      fontSize:'14px', 
      fontFamily:'Montserrat'
     }
}),
  )





const theme =  createTheme({
    overrides: {
        MuiTabs: {
          root: {
            '& .Mui-selected': {
              // Add your custom styles here
              // For example:
              backgroundColor: '#204B9C',
              color: '#fff',
              width:'207px'
            },
          },
        },
        MuiTab: {
          wrapper: {
          opacity:1,
            textAlign: 'left',
            alignItems: 'flex-start',
            marginLeft: '1.1rem',
          // Add any additional styles for the wrapper class
         
          },
        },
      },



})





function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
        style={{paddingTop:'10px', flexGrow:2}}
        
      >
        {value === index && (
          <>
          {children}
          </>
        )}
      </div>
    );
  }

  const status =  [
    {option:'Activate', selected:false, id:'1' },
    {option:'Deactivate', selected:false, id:'2'}
  ]

  const orders = [

     {option:'Online', id: '1'},
     {option:'Offline', id:'2'}
  ]



  interface PropTypes {
       getSelectedValue: Function;
       selctedTabs :any, 
       sections: any,
       updateSections:any,
       getCurrentTabValue?:any
       setServiceName: any;
       optionsList: any;
  }



const VerticalServiceTabs = ({ getSelectedValue, selctedTabs, sections, updateSections, getCurrentTabValue, setServiceName, optionsList }: PropTypes) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [section, setSectionList] = React.useState()



     const [selectedStatus, setSelectedStatus]  = React.useState<null | string>(null)   
     const [selectedOrder, setSelectedOrder] =  React.useState<null | string> (null);


   




  
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
   
      setValue(newValue);
    
      getCurrentTabValue(newValue)
        
      

    };
  

    const handleStatus =  (item:any)=>{

    
       setSelectedStatus(item?.id);
       const updatedFilter =  {
         ...selctedTabs, status:{key:'filter_by[status]',filterId:'status', value: item.option.toLowerCase()}
        }
 
       getSelectedValue(updatedFilter)
  }


  const handleOrder = (item:any)=>{
    
   
      const updatedData =  {
        ...selctedTabs,
        order:{key:'filter_by[order]',filterId:'order', value: item.option.toLowerCase()}
      }
      
     
     getSelectedValue(updatedData)
     setSelectedOrder(item.id);
  }






  const onInputChange = (value: any) => {
   
    
    const updatedData =  {
      ...selctedTabs,
         serviceName: {
            key: 'filter_by[service_name]',
            filterId: 'service',
            value: value,
          },
    }


   
  getSelectedValue(updatedData)
  };


  const sectionOptionChange =  (item:any)=>{
    const selectedSections : string[] =  selctedTabs.section?.value || []
    const newSelectedSections: string[] = item.checked ? 
      selectedSections.filter(selectedSection => selectedSection !== item.id)
      : [...selectedSections, item.id]
    const updatedData =  {
      ...selctedTabs,
      section:{key:'filter_by[section_ids][]', filterId: 'section', value: newSelectedSections}
    }
    // setFilters(updatedData)
getSelectedValue(updatedData)
updateSections(item.id);
  
  }

  const tabs  = [
    {key:0, title:'Service Name'},
    {key:1, title:'Section'},
    {key:2, title:'Status'},
    {key:3, title:'Order'}
]

    return (
        <ThemeProvider theme={theme}>
      <div style={{display:'flex', width:'100%', gap:'30px'}}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          classes={{ indicator: classes.indicator }}
         
        >
         {tabs.map((item)=>(
          <Tab  
            style={{ minWidth: 0 }} 
            className={classes.tabLabel} 
           label={item.title} 
           {...a11yProps(item.key)}
          />
          ))}
          </Tabs>
        <TabPanel  value={value} index={0}>
           <Box className={classes.flexBox}>
              <Box className={classes.inputHeading}>Service </Box>
              <FilterCustomAutocomplete
                data-test-id="autoCompleteDropdown"
                id="service_name"
                name="service_name"
                options={optionsList?.map((option: any) => option.label) || []}
                inputValue={selctedTabs?.serviceName?.value || ""}
                value={selctedTabs?.serviceName?.value || ""}
                onChange={(_: any, value: any) => { onInputChange(value);setServiceName(value); }}
                onInputChange={(_: any, value: any) => { onInputChange(value);setServiceName(value); }}
                clearOnBlur={false}
                clearOnEscape={false}
                renderInput={(params: any) => (
                    <InputField
                        {...params}
                        variant="outlined"
                        placeholder="Type and select"
                        InputProps={{...params.InputProps,endAdornment:(<></>)}}
                        value={selctedTabs?.serviceName?.value}
                    />
                )}
                ListboxProps={
                    {
                        style: {
                            maxHeight: '150px',
                        }
                    }
                }
            />
           </Box>
        </TabPanel>
        <TabPanel  className={classes.boxRoot}  value={value} index={1} >
            

          <Box style={{display:'flex',flexDirection:'column', gap:'2rem', maxHeight:'150px', overflowY:'scroll'}}>

          {sections.map((item:any)=>(
          <Box className={classes.checkboxContainer} key={item.id}>
           <Box onClick={()=> sectionOptionChange(item)} data-test-id={item.title}>
           {item.checked ? <img src={checkBoxTrue} alt='checkBoxTrue'/>: 
           <img src={checkBoxFalse} alt='checkBoxFalse'/>}
           </Box>
           <Typography 
            style={{color:`${item.checked ?  
            'rgba(32, 75, 156, 1)' : 
            '#000'}`}}
             className={classes.checkboxTypography}
            >
            {item.title}
            </Typography>
            </Box>
           ))}    
          </Box>                
        </TabPanel>

        <TabPanel value={value} index={2}>
          <Box className={classes.tabPanelChild}>
              {status.map((item)=>(
                  <Box data-test-id="status" key={item.id} onClick={()=> handleStatus(item)}  
                     className={selctedTabs?.status?.value === item.option.toLowerCase() ? 
                      classes.selectedChildrenTab
                      : classes.unSelectedChilderenTab}>

                    {item.option}
                  </Box>
            ))}
           </Box>
          </TabPanel>
        
        <TabPanel value={value} index={3}>
         <Box className={classes.tabPanelChild}>
          {orders.map((item)=>(
         <Box key={item.id} onClick={()=> handleOrder(item)}  
         className={selctedTabs?.order?.value === item.option.toLowerCase() ? 
         classes.selectedChildrenTab: 
         classes.unSelectedChilderenTab} >
        {item.option}
        </Box>
       ))}
       </Box>
       </TabPanel>
       </div>
      </ThemeProvider>
    );
  }


  export default VerticalServiceTabs ;