import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
export const configJSON = require("./config");
import {omit} from "lodash"
// Customizable Area End

// Customizable Area Start
export enum FormMode {
  Create,
  Edit,
}

enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
}

export interface FormInteface {
  discount_amount: string | null;
  new_customer_discount: string | null;
  allow_customer_referrals?: string | null | number;
}

// Customizable Area End

export interface Props {
  // Customizable Area Start
  classes?: any;
  navigation: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  formErrors: { [key: string]: string };
  form: FormInteface;
  isLoading: boolean;
  isSubmitting: boolean;
  formMode: FormMode;
  snackbarOpen: boolean;
  alertServerity: string;
  snackbarMessage: string;
  customerReferralDropdownOptions: any,
  dropdownOptions: any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddInviteFriendsController extends BlockComponent<
                 Props,
                 S,
                 SS
               > {
                 // Customizable Area Start
                 private formInitialValues: FormInteface = {
                   discount_amount: null,
                   new_customer_discount: null,
                   allow_customer_referrals: null,
                 };

                 public RequestMessage = {
                   CreateInviteFriends: this.buildRequestMessage(Method.POST),
                   UpdateInviteFriends: this.buildRequestMessage(Method.PUT),
                   GetInviteFriendsDetail: this.buildRequestMessage(Method.GET),
                   Null: undefined as any,
                 };
                 // Customizable Area End

                 constructor(props: Props) {
                   super(props);
                   this.receive = this.receive.bind(this);

                   this.subScribedMessages = [
                     // Customizable Area Start
                     getName(MessageEnum.RestAPIResponceMessage),
                     // Customizable Area End
                   ];

                   this.state = {
                     // Customizable Area Start
                     formErrors: {},
                     form: { ...this.formInitialValues },
                     isLoading: false,
                     isSubmitting: false,
                     formMode: FormMode.Create,
                     snackbarOpen: false,
                     alertServerity: "",
                     snackbarMessage: "",
                     customerReferralDropdownOptions: [
                       {
                         id: 1,
                         item:
                           "Give One Time Credit to Existing and New Customer",
                          title: configJSON.inviteFriendTextValue
                       },
                     ],
                     dropdownOptions: [],

                     // Customizable Area End
                   };

                   runEngine.attachBuildingBlock(
                     this as IBlock,
                     this.subScribedMessages
                   );

                   // Customizable Area Start
                   // Customizable Area End
                 }

                 async receive(from: string, message: Message) {
                   runEngine.debugLog("Message Recived", message);

                   // Customizable Area Start
                   if (
                     getName(MessageEnum.RestAPIResponceMessage) !== message.id
                   ) {
                     return;
                   }

                   const callID = message.getData(
                     getName(MessageEnum.RestAPIResponceDataMessage)
                   );
                   const response = message.getData(
                     getName(MessageEnum.RestAPIResponceSuccessMessage)
                   );

                   switch (callID) {
                     case this.RequestMessage.GetInviteFriendsDetail.messageId:
                       this.handleGetDetailResponse(response);
                       break;

                     case this.RequestMessage.CreateInviteFriends.messageId:
                       this.handleCreateResponseFn(response);
                       break;

                     case this.RequestMessage.UpdateInviteFriends.messageId:
                       this.handleCreateResponseFn(response);
                       break;
                   }
                   // Customizable Area End
                 }

                 // Customizable Area Start

                 private handleGetDetailResponse(response: { data: any }) {
                   if (response) {
                     const tempData = {
                       discount_amount:
                         response.data.attributes.discount_amount,
                       new_customer_discount:
                         response.data.attributes.new_customer_discount,
                       allow_customer_referrals: 1,
                     };
                     this.setState({
                       form: tempData,
                       isLoading: false,
                     });
                   }
                 }

                 private handleCreateResponseFn(response: {
                   data: {
                     type: string;
                     attributes: any;
                     id: string;
                   };
                   full_messages: string[];
                   activated: string[];
                 }) {
                   if (
                     response &&
                     response.data &&
                     response.data.type !== "error" &&
                     response.data.id
                   ) {
                     this.setState({
                       isSubmitting: false,
                       snackbarOpen: true,
                       alertServerity: "success",
                       snackbarMessage: `Invite Codes/Referrals has been ${
                         this.state.formMode === FormMode.Edit
                           ? "updated"
                           : "added"
                       } successfully`,
                     });
                     setTimeout(
                       () => this.props.navigation.navigate("Invitefriends"),
                       1000
                     );
                   } else if (response.data?.attributes?.errors) {
                     let errors = response.data?.attributes?.errors;
                     const firstProperty = Object.keys(errors)[0]; // Get the first property name
                     const firstValue = errors[firstProperty];
                     this.setState({
                       isLoading: false,
                       isSubmitting: false,
                       alertServerity: "error",
                       snackbarOpen: true,
                       snackbarMessage: firstValue,
                     });
                   } else {
                     this.setState({
                       isLoading: false,
                       isSubmitting: false,
                       snackbarOpen: true,
                       alertServerity: "error",
                       snackbarMessage: response && response?.activated[0]
                     });
                   }
                 }

                 handleSnackbarCloseFn = (
                   event?: React.SyntheticEvent,
                   reason?: string
                 ) => {
                   if (reason === "clickaway") {
                     return;
                   }
                   this.setState({ snackbarOpen: false });
                 };

                 onFormElementChangeFn = (
                   event: React.ChangeEvent<{
                     name?: string;
                     value: unknown;
                   }>,
                   property: string
                 ) => {
                   this.setState({
                     form: {
                       ...this.state.form,
                       [property]: event.target.value,
                     },
                   });
                 };

                 handleCancelClickFn = () => this.props.navigation.goBack();

                 checkEntriesFn = (formErrors: { [key: string]: string }) => {
                   const newFormErrors = { ...formErrors };
                   Object.entries(this.state.form).forEach(([key, value]) => {
                    if (key === "discount_amount" && value <= 0){
                      newFormErrors[key] = configJSON.fieldValidText;
                    }
                    if (key === "new_customer_discount" && value <= 0){
                      newFormErrors[key] = configJSON.fieldValidText;
                    }
                     if (
                       !value ||
                       value === undefined ||
                       value === "" ||
                       value === "null" ||
                       value.length === 0
                     ) {
                       newFormErrors[key] = configJSON.fieldRequiredValidText;
                     }
                   });
                   return newFormErrors;
                 };

                 handleOnSubmit = () => {
                   let formErrors: { [key: string]: string } = {};
                   if (!this.state.form || this.state.isSubmitting) {
                     return;
                   }

                   formErrors = this.checkEntriesFn(formErrors);
                   if (Object.keys(formErrors).length > 0) {
                     this.setState({ formErrors, isSubmitting: false });
                     return;
                   }

                   const { form } = this.state;
                   this.setState({ isLoading: true });
                   if (this.state.formMode === FormMode.Edit) {
                     this.updateCallFn(
                       form,
                       window.localStorage.getItem("invite_friend_id") as string
                     );
                   } else {
                     this.createCallFn(form);
                   }
                 };

                 createCallFn = (form: FormInteface) => {
                   this.setState({ isSubmitting: true, formErrors: {} });
                   delete form.allow_customer_referrals;
                   this.RequestMessage.CreateInviteFriends.addData(
                     getName(MessageEnum.RestAPIRequestBodyMessage),
                     JSON.stringify({
                       data: { attributes: { ...form, force_create: true } },
                     })
                   );

                   runEngine.sendMessage(
                     this.RequestMessage.CreateInviteFriends.id,
                     this.RequestMessage.CreateInviteFriends
                   );
                 };

                 updateCallFn = (
                   form: FormInteface,
                   invite_friend_id: string
                 ) => {
                   this.setState({ isSubmitting: true, formErrors: {} });
                   const formAttr = omit(form, "allow_customer_referrals")
                   this.RequestMessage.UpdateInviteFriends.addData(
                     getName(MessageEnum.RestAPIResponceEndPointMessage),
                     `${configJSON.inviteFriendsApiEndpoint}/${invite_friend_id}`
                   );

                   this.RequestMessage.UpdateInviteFriends.addData(
                     getName(MessageEnum.RestAPIRequestBodyMessage),
                     JSON.stringify({ data: { attributes: formAttr } })
                   );

                   runEngine.sendMessage(
                     this.RequestMessage.UpdateInviteFriends.id,
                     this.RequestMessage.UpdateInviteFriends
                   );
                 };

                 getDetailCall = (invite_friend_id: string) => {
                   this.setState({ isLoading: true });

                   this.RequestMessage.GetInviteFriendsDetail.addData(
                     getName(MessageEnum.RestAPIResponceEndPointMessage),
                     `${configJSON.inviteFriendsApiEndpoint}/${invite_friend_id}`
                   );

                   runEngine.sendMessage(
                     this.RequestMessage.GetInviteFriendsDetail.id,
                     this.RequestMessage.GetInviteFriendsDetail
                   );
                 };

                 private buildRequestMessage(method: Method): Message {
                   const requestMessage = new Message(
                     getName(MessageEnum.RestAPIRequestMessage)
                   );

                   requestMessage.addData(
                     getName(MessageEnum.RestAPIResponceEndPointMessage),
                     configJSON.inviteFriendsApiEndpoint
                   );
                   requestMessage.addData(
                     getName(MessageEnum.RestAPIRequestMessage),
                     configJSON.APIBaseURL
                   );
                   requestMessage.addData(
                     getName(MessageEnum.RestAPIRequestHeaderMessage),
                     this.getHeaderMessage()
                   );
                   requestMessage.addData(
                     getName(MessageEnum.RestAPIRequestMethodMessage),
                     method.toString()
                   );

                   return requestMessage;
                 }

                 handleSelect = (id: string) => {
                   this.setState({
                     form: {
                       ...this.state.form,
                       allow_customer_referrals: id,
                     },
                   });
                 };
                 getHeaderMessage() {
                   const header = {
                     "Content-Type": configJSON.validationApiContentType,
                     token: window.localStorage.getItem(configJSON.token),
                   };

                   return JSON.stringify(header);
                 }

                 async componentDidMount() {
                   super.componentDidMount();
                   let id = this.props.navigation.getParam("id");
                   this.setState({
                     formMode: id ? FormMode.Edit : FormMode.Create,
                   });
                   if (id) this.getDetailCall(id);
                 }
                 // Customizable Area End
               }
