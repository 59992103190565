import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Paper,
  Table,
  Popover,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TableContainer,
  TablePagination,
  styled,
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { renderBaseOnConditions, sortStringCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";
import { withStyles } from "@material-ui/core/styles";
import FilterPopover from "../../../components/src/FilterPopover";
import SortingTableCell from "../../../components/src/SortingTableCell";
import SortingTableHeader from "../../../components/src/SortingTableHeader2";
import ConfirmationModal from "../../../components/src/customComponents/ConfirmationModal.web";
import {
  FilterList as FilterIcon,
  MoreVert as MoreVertIcon,
  ExpandLess,
  ExpandMore
} from "@material-ui/icons";
import { CSSProperties } from "@material-ui/styles";

const labels = require("./config");
const textLabels = labels.CustomerListLabels;
// Customizable Area End

import PeopleManagement2Controller, {
  ICustomerAttributes,
  ICustomerData,
  IOrganization,
  IProfession,
  Props,
  configJSON,
} from "./PeopleManagement2Controller";
import ExportImportPopover from "../../../components/src/customComponents/ExportImportButton.web";
import { CustomSnackbar } from "../../../components/src/customComponents/CustomSnackbar.web";
import { colors } from "../../utilities/src/Colors";

class PeopleManagement2 extends PeopleManagement2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  checkOpacity(activated: string | boolean) {
    if(activated) {
      return 1
    }
    return 0.6
  }

  professionData = (profession: IProfession | null) => {
    if(profession && profession.data && profession.data.attributes && profession.data.attributes.profession_name) {
      return profession.data.attributes.profession_name
    }
    return "--"
  }

  organizationData = (organization: IOrganization) => {
    if(organization && organization.data && organization.data.attributes && organization.data.attributes.name) {
      return organization.data.attributes.name
    }
    return "--"
  }

  businessData = (business_id: {id: number, label: string, module_name: string}) => {
    if(business_id && business_id.label) {
      return business_id.label
    }
    return "--"
  }

  customerTypeData = (customer_type: string | null) => {
    if(customer_type) {
      return customer_type
    }
    return "--"
  }

  renderPagination = (customerList: ICustomerData[]) => {
    if (customerList.length > 0) {
     return <TablePagination
        data-test-id='customerListPagination'
        rowsPerPageOptions={[10]}
        component='div'
        count={this.state.customerListPagination?.total_count}
        rowsPerPage={this.state.pageSize}
        page={this.state.page}
        onPageChange={(event, page) => { this.handlePageChange(page) }}
      />
    }
  }

  setEmptyRows = (emptyRows: number) => {
    if (emptyRows > 0) {
      return <TableRow style={{ height: 53 * emptyRows }}>
        <TableCell colSpan={6} />
      </TableRow>
    }
  }

  getPopupOverMenu() {
    const {
      popOverTop,
      popOverLeft,
      popOverOpened,
      rowData
    } = this.state;
    const { classes } = this.props;
    if(this.state.popOverOpened) {
      return (
        <Popover
          data-test-id={configJSON.popoverTest}
          className={classes.popOverMenuStyle}
          anchorReference='anchorPosition'
          open={popOverOpened}
          onClose={() => { this.handlePopoverMenuClose() }}
          anchorPosition={{
            top: popOverTop + 40,
            left: popOverLeft - 80,
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {
            renderBaseOnConditions(
              (this.state.permissionStatus.viewPermission ||
                this.state.permissionStatus.editPermission ||
                this.state.permissionStatus.activatePermission ||
                this.state.permissionStatus.deactivatePermission), 
          <div className={classes.popoverContainer}>
            {renderBaseOnConditions(this.state.permissionStatus.viewPermission,<Box data-test-id='viewButton' className={classes.popoverButton} onClick={() => {this.handleViewCustomerDetails(rowData.id)}}>
              {textLabels.actions.view}
            </Box>,<></>)}
            {renderBaseOnConditions(this.state.permissionStatus.editPermission,<Box data-test-id='editButton' className={classes.popoverButton} onClick={() => {this.handleEditCustomerDetails(rowData.id)}}>
              {textLabels.actions.edit}
            </Box>,<></>)}
                  {
                      renderBaseOnConditions(
                        this.state.permissionStatus.deactivatePermission && this.state.popOverItemStatus as boolean,
                        <Box
                        data-test-id='activateButton'
                        className={classes.popoverButton}
                        onClick={() => {
                          this.handleDeactivateModalOpen()
                        }}
                        >
                          Deactivate
                        </Box>,
                         <>{!this.state.permissionStatus.viewPermission &&
                          !this.state.permissionStatus.editPermission &&
                          this.state.permissionStatus.activatePermission &&
                          this.state.popOverItemStatus
                        && !this.state.permissionStatus.deactivatePermission && <PopoverContainer1>
                        <div className="noPermissions">
                          No Permissions
                        </div>
                      </PopoverContainer1>}</>
                      )
                    }
                    {
                      renderBaseOnConditions(
                        this.state.permissionStatus.activatePermission as boolean && !this.state.popOverItemStatus,
                        <Box
                        data-test-id='activateButton'
                        className={classes.popoverButton}
                        onClick={() => {
                          this.activeDeactiveCustomer()
                        }}
                        >
                          Activate
                        </Box>,
                        <>{!this.state.permissionStatus.viewPermission &&
                          !this.state.permissionStatus.editPermission &&
                          !this.state.permissionStatus.activatePermission &&
                          !this.state.popOverItemStatus
                        && this.state.permissionStatus.deactivatePermission && <PopoverContainer1>
                        <div className="noPermissions">
                          No Permissions
                        </div>
                      </PopoverContainer1>}
                      </>
                      )
                    }
            </div>,
              <div className="popoverContainer">
                <div className="noPermissions">
                  No Permissions
                </div>
              </div>
            )
          }
        </Popover>
      );
    }
  }

  getDeactivateSuccessModal() {
    const { customerDeactivateModalOpen, rowData } = this.state;
    if (customerDeactivateModalOpen) {
      return (
        <ConfirmationModal
          displayItem={true}
          open={customerDeactivateModalOpen}
          handleClose={() => { this.handleConfirmProductDeactivateModalClose() }}
          handleConfirm={() => { this.handleGoList() }}
          title={textLabels.labels.customerDeactivated}
          confirmText={textLabels.actions.backToListing}
          key={"account-deactivate-modal"}
          message={{
            id: rowData.id,
            title: rowData.attributes.full_name,
          }}
        />
      );
    }
  }

  getDeactivateModal() {
    const { confirmDeactivationModalOpen, rowData } = this.state;
    if (confirmDeactivationModalOpen) {
      return (
        <ConfirmationModal
          dynamic={true}
          confirmText={this.state.isDeactivationStarted ? <CircularProgress
            disableShrink
            style={{
              color: colors().white,
              animationDuration: '550ms',
            }}
            size={20}
            thickness={4}
            value={100}
          />: 'Deactivate'}
          key={"deactivate-modal"}
          open={confirmDeactivationModalOpen}
          handleClose={() => { this.handleDeactivateModalClose() }}
          handleConfirm={() => { this.activeDeactiveCustomer() }}
          title={textLabels.labels.confirmDeactivate}
          message={{
            id: rowData.id,
            title: rowData.attributes.full_name,
          }}
        />
      );
    }
  }
  renderTableHeader() {
    const { classes } = this.props;
    return (
      <TableRow className={classes.customerTableRow} style={{flex: "1 1 0"}}>
        <SortingTableHeader
          data-test-id="idSortingHeader"
          sortingData={this.state.sortingData}
          title={textLabels.tableHeader.id}
          sortingKey='id'
          {...this.sortingProps}
          type='left'
          sortingDisabled
        />
        <SortingTableHeader
          data-test-id="nameSortingHeader"
          sortingData={this.state.sortingData}
          title={textLabels.tableHeader.name}
          sortingKey='name'
          {...this.sortingProps}
          type='middle'
        />
        <SortingTableHeader
          data-test-id="phoneSortingHeader"
          sortingData={this.state.sortingData}
          title={"Phone"}
          sortingKey='phone_number'
          {...this.sortingProps}
          type='middle'
        />
        <SortingTableHeader
          data-test-id="genderSortingHeader"
          sortingData={this.state.sortingData}
          title={"Gender"}
          sortingKey='gender'
          {...this.sortingProps}
          type='middle'
        />
        <SortingTableHeader
          sortingData={this.state.sortingData}
          title={textLabels.tableHeader.organization}
          sortingKey='organisation_name'
          {...this.sortingProps}
        />
        <SortingTableHeader
          sortingData={this.state.sortingData}
          title={textLabels.tableHeader.city}
          sortingKey='city'
          {...this.sortingProps}
        />
        <SortingTableHeader
          sortingData={this.state.sortingData}
          title={textLabels.tableHeader.profession}
          sortingKey='profession'
          {...this.sortingProps}
        />

        <SortingTableHeader
          sortingData={this.state.sortingData}
          title={textLabels.tableHeader.customerType}
          sortingKey='customer_type'
          {...this.sortingProps}
        />

        <SortingTableHeader
          sortingData={this.state.sortingData}
          title={textLabels.tableHeader.businessCategory}
          sortingKey='business'
          {...this.sortingProps}
        />
        <SortingTableHeader
          sortingData={this.state.sortingData}
          title=''
          type='action'
          sortingKey=''
          {...this.sortingProps}
          sortingDisabled
        />
      </TableRow>
    )
  }

  getNameEn = (item: ICustomerAttributes) => {
    return item.customer_profile && item.customer_profile.data && item.customer_profile.data.attributes 
    && item.customer_profile.data.attributes.city?.name_en || "--"
  }

  // Customizable Area End

  render() {
    const { classes } = this.props;

    const openCustomerListPopoverAction = Boolean(this.state.openCustomerListAction);
    const popOverId = openCustomerListPopoverAction ? "simple-popover" : undefined;

    return (
      // Customizable Area Start
      <MainStyleWrapper>
        <Box className="pageWrapper">
            <div className={classes.headerWrapper}>
              <div className={classes.pageHeader}>{configJSON.txtCustomers}</div>
              <div className={classes.headerButtonPartAccount}>
                <div
                  data-test-id="filterIcon"
                  onClick={(event) => {this.handleFilterClose(event)}}
                  className={sortStringCondition(
                    this.state.isAppliedFilter,
                    "filterButtonAccount active-border",
                    "filterButtonAccount"
                  )}
                >
                  <FilterIcon color={this.handleReturnColorType()} />
                  {renderBaseOnConditions(
                    this.state.isAppliedFilter,
                    <div className="filterActiveIcon"></div>,
                    <></>
                  )}
                </div>
                <FilterPopover
                  data-test-id="filterPopover"
                  onClose={() => { this.handleCloseFilterPopover() }}
                  anchor={this.state.filterAnchor}
                  onFilterChange={this.handleFilterChangeAccount}
                  onAutoCompleteChange={(title: string, value: string) => {this.handleFilterAutoCompleteChange(title, value)}}
                  filters={this.state.filters}
                />
                {renderBaseOnConditions(this.state.permissionStatus.exportPermission as boolean, 
                  <StyledBox component={'div'}>
                  <Button
                    data-test-id={`import-export-btn`}
                    id={"IconButton"}
                    aria-describedby={popOverId}
                    onClick={(event) => { this.setState({ openCustomerListAction: event.currentTarget }) }}
                    className={classes.addButton}
                  >
                    {configJSON.exportimportText} {this.state.openCustomerListAction ? <ExpandLess /> : <ExpandMore />}
                  </Button>
                </StyledBox>
                  ,<></>)}
                <ExportImportPopover
                  popOverId={popOverId}
                  openAction={openCustomerListPopoverAction}
                  popoverOpen={this.state.openCustomerListAction}
                  popoverClose={() => { this.handleCustomerListCloseActionList() }}
                  exportTemplateButtonClick={() => this.handleCustomerListExportTemplate()}
                  importCsvButtonClick={() => this.handleCustomerListOpenImportModal()}
                  exportCsvButtonClick={() => this.handleCustomerListExportCsv()}
                />
               {renderBaseOnConditions(this.state.permissionStatus.createPermission, <Button
                  data-test-id='addAccount'
                  onClick={() =>
                    this.props.navigation.navigate("CustomerCreation")
                  }
                  className={classes.addButton}
                >
                  {textLabels.actions.addCustomer}
                </Button>,<></>)}
              </div>
            </div>
            <TableContainer component={Paper} className="flexItem">
              <Table className={classes.table} aria-label='customized table'>
                <TableHead className="stickyHeader">
                  {this.renderTableHeader()}
                </TableHead>
                {this.state.isLoading ? 
                <TableBody data-test-id="tableData"> 
                  <TableCell colSpan={10} className={classes.loadercell} style={{textAlign:"center"}}>
                  <CircularProgress className={classes.loadercir}/> 
                  </TableCell>
                </TableBody> :
                  <TableBody data-test-id="tableData">
                    {this.state.customerList.length > 0 && <>
                      {this.state.customerList.map((item: ICustomerData, index: number) => {
                        const { full_name, customer_profile, activated, user_addresses, phone_number }: ICustomerAttributes = item.attributes;
                        const { customer_id, organization, city, profession, business_id, customer_type, gender_id } = customer_profile && customer_profile.data && customer_profile.data.attributes ? customer_profile.data.attributes : { 
                          organization: {
                            data: {
                              id: "",
                              type: "",
                              attributes: {
                                id: 0,
                                name: "",
                                tax_number: "",
                                active: false
                              }
                            }
                          },
                          profession: {
                            data: {
                              id: "",
                              type: "",
                              attributes: {
                                id: 0,
                                profession_unique_id: "",
                                profession_name: "",
                                active: false
                              }
                            }
                          }, business_id: {
                            id: 0,
                            label: "",
                            module_name: ""
                          }, city: {
                            id: 0,
                            name_en: '',
                            name_ar: ''
                          }, customer_type: "", customer_id: "",
                          gender_id:{id:"", label:""}
                        };
                        return (
                          <TableRow key={index + "TableRow"}>
                            <SortingTableCell
                              width='22vw'
                              type='left'
                              content={customer_id || "--"}
                              width1024="100px"
                              style={{ opacity: this.checkOpacity(activated) }}
                            />
                            <SortingTableCell
                              width='22vw'
                              type='middle'
                              width1024="150px"
                              content={full_name || "--"}
                              style={{ opacity: this.checkOpacity(activated) }}
                            />
                              <SortingTableCell
                              width='22vw'
                              type='middle'
                              width1024="150px"
                              content={phone_number || "--"}
                              style={{ opacity: this.checkOpacity(activated) }}
                            />
                              <SortingTableCell
                              width='22vw'
                              type='middle'
                              width1024="150px"
                              content={gender_id?.label || "--"}
                              style={{ opacity: this.checkOpacity(activated) }}
                            />
                            <SortingTableCell
                              width='22vw'
                              type='middle'
                              width1024="150px"
                              content={this.organizationData(organization)}
                              style={{ opacity: this.checkOpacity(activated) }}
                            />
                            <SortingTableCell
                              width='22vw'
                              type='middle'
                              width1024="100px"
                              content={this.getNameEn(item.attributes)}
                              style={{ opacity: this.checkOpacity(activated) }}
                            />
                            <SortingTableCell
                              width='22vw'
                              type='middle'
                              width1024="150px"
                              content={this.professionData(profession)}
                              style={{ opacity: this.checkOpacity(activated) }}
                            />
                            <SortingTableCell
                              width='22vw'
                              type='middle'
                              width1024="100px"
                              content={this.customerTypeData(customer_type)}
                              style={{ opacity: this.checkOpacity(activated) }}
                            />
                            <SortingTableCell
                              width='22vw'
                              type='right'
                              width1024="100px"
                              content={this.businessData(business_id)}
                              style={{ opacity: this.checkOpacity(activated) }}
                            />
                            <SortingTableCell
                              data-test-id={`threeDotIcon_${index}`}
                              width='22vw'
                              width1024="100px"
                              type='action'
                              content={
                                <IconButton
                                  data-test-id={`moreButton_${index}`}
                                  id={"IconButton" + index}
                                  onClick={(event) => {
                                    const position = event.currentTarget.getBoundingClientRect();
                                    this.handleMoreMenu(item, position)
                                  }}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                              }
                            />
                          </TableRow>
                        );
                      })}
                    </> 
                    }
                  </TableBody>
                }
              </Table>

                {this.state.customerList.length === 0 && !this.state.isLoading && <div className={`${classes.customerAddButton} ${classes.noDataCell}`}>
                    {textLabels.labels.noRecordsFound}
                  </div>}

            </TableContainer>
              {this.renderPagination(this.state.customerList)}
          </Box>

          <CustomSnackbar
            open={this.state.errorSnackbarOpen}
            onClose={this.handleCustomerListSnackbarClose}
            errorMessage={this.state.errorMessage}
            severity={this.state.snakcbarSeverity}
          />
          {this.getPopupOverMenu()}

        {this.getDeactivateModal()}
        {this.getDeactivateSuccessModal()}
      </MainStyleWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const styles: Record<string, CSSProperties> = {
  table: {
    minWidth: 700,
  },
  pageHeader: {
    fontSize: "24px",
    fontWeight: 600,
    fontStyle: "normal",
    lineHeight: "29px",
    color: colors().black,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
      fontWeight: 600,
      color: colors().black,
    },
  },
  headerWrapper: {
    display: "flex",
    flexWrap: 'wrap' as 'wrap',
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 32,
    marginTop: 25,
    flexwrap: "wrap" as 'wrap'
  },
  addButton: {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    background: "#204B9C",
    color: "#FFFFFF",
    width: "184px",
    height: "56px",
    borderRadius: "8px",
    textTransform: "unset" as "unset",
    "&:hover": {
      background: "#204B9C",
      color: "#FFFFFF",
    },
    "@media only screen and (max-width: 1024px)": {
      fontSize: "13px",
      width: "160px",
      height: "44px",
      padding: "10px 20px",
    },
  },
  loadercell: {
    textAlign: "center"
  },
  loadercir: {
    width:"44px",
    height:"none"
  },
  headerButtonPartAccount: {
    display: "flex",
  },
  popoverButton: {
    width: "118px",
    height: "33px",
    paddingTop: "8px",
    paddingLeft: "24px",
    backgroundColor: "#FFFFFF",
    color: "#4D4D4D",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#204B9C",
      color: "#FFFFFF",
    },
  },
  popoverContainer: {
    paddingTop: "16px",
    paddingBottom: "16px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    borderRadius: "8px",
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: "Montserrat",
  },
  popOverMenuStyle: {
    boxShadow: "none"
  },
  customerTableRow: {
    background: "#204B9C",
    color: "#FFFFFF"
  },
  customerAddButton: {
    textAlign: 'center'
  },
  noDataCell: {
    fontWeight: 600,
    fontSize: "16px",
    width: "98%",
    paddingTop: "20px",
    paddingBottom: "20px",
    border: "1px solid #ECECEC",
    margin: "12px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    borderRadius: '8px'
  },
}

const StyledBox = styled(Box)({
  margin: '0 10px'
});

const PopoverContainer1 = styled("div")({
  borderRadius: "8px",
  fontFamily: "Montserrat",
  fontWeight: 500,
  fontSize: "12px",
  "& .noPermissions": {
    padding: "0 8px",
  }
});

const MainStyleWrapper = styled(Box)({
  fontFamily: "Montserrat",
  "& .filterButtonAccount": {
    position: "relative",
    marginRight: "24px",
    cursor: "pointer",
    background: "#FFFFFF",
    height: "56px",
    color: "#4d4d4d",
    width: "56px",
    fontSize: "24px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #ECECEC",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
      width: "44px",
      height: "44px",
      padding: "9px",
    },
  },
  "& .active-border": {
    border: "1px solid #204B9C",
  },
  "& .filterActiveIcon": {
    position: "absolute",
    top: "-5px",
    right: "-5px",
    minWidth: "15px",
    background: "#204B9C",
    minHeight: "15px",
    borderRadius: "50%"
  },
});

export default  withStyles(styles)(PeopleManagement2)
// Customizable Area End
