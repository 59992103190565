import React from "react";
// Customizable Area Start
import {
  Box,
  styled,
  Snackbar,
  InputAdornment,
  Radio,
  Grid
} from "@material-ui/core";


import AddLoyaltyController, {
  Props,
  configJSON,
} from "./AddLoyaltyController.web";
import Alert from "@material-ui/lab/Alert";
import CustomSwitch from "../../../components/src/CustomSwitch.web";
import { SaveAndCancelButton, HeaderText, DefultBox, SelectFieldCustom, InputLabel, CustomStyledTextField, SelectMenuItem } from "../../../components/src/customComponents/CustomTags.web";
import { CustomSearchableMultiSelect } from "../../../components/src/customComponent/CustomSearchableMultiSelect.web";

export default class AddLoyalty extends AddLoyaltyController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        <Box component="div" sx={{...webStyle.mainContainerLoyalty, width: {xs: 'calc(100% - 100px)', md: 'unset'}}}>
          <HeaderText style={webStyle.header}>{configJSON.createLoyalty}</HeaderText>
          <DefultBox style={webStyle.warningContainer}>
            {configJSON.warningMessage}
          </DefultBox>
          <div style={webStyle.formContainerLoyalty}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <ActiveButton>
                {this.state.loyalty.attributes.active
                  ? configJSON.active
                  : configJSON.deactivated}
              </ActiveButton>
              <CustomSwitch
                data-test-id='customswitch'
                checked={this.state.loyalty.attributes.active}
                onChange={this.handleCheckedChange}
              />
            </div>
            <div
              style={{
                background: "#ECECEC",
                height: "1px",
                marginTop: 24,
                marginBottom: 24,
              }}
            />

            <Grid container style={{ display: "flex"}}>
              <CustomGrid item xs={12} sm={8} md={6}>
              <InputLabel>
                {configJSON.section}
              </InputLabel>
                <CustomSearchableMultiSelect
                  id="section"
                  name="section"
                  dataTestId="sectionDropdown"
                  checkSelectAllText={() => {}}
                  value={this.state.selectedSections}
                  optionList={this.state.sectionList}
                  placeHolderText={configJSON.placeHolderTextSection}
                  changeInputOnClear
                  handleScroll={this.handleScrollSectionDropdown}
                  handleClickOnSelectAll={() => {}}
                  isOnBlurFunction={() => this.debouncedFunction("")}
                  debouncedFunction={(getValue: string) => { this.debouncedFunction(getValue) }}
                  handleSelectOptions={(value: { id: string, option: string }[], option: { id: string, option: string }) => this.handleSectionMultiSelect(option)}
                  emptyDataMessage={configJSON.emptySectionDataMessage}
                  handleEmptyAutoSelectValue={() => {}}
                  selectedOptionForList={this.state.selectedSectionsForList}
                  isError={Boolean(this.state.formErrors["sections"])}
                />
                {this.state.formErrors["sections"] && (
                  <div style={webStyle.errorText}>
                    {this.state.formErrors["sections"]}
                  </div>
                )}
              </CustomGrid>
            </Grid>

            <Grid container spacing={2} style={{ display: "flex", marginTop: "32px"}}>
              <Grid item xs={12} sm={8} md={3}>
                <InputLabel>
                  {configJSON.spendAmount}
                </InputLabel>
                <CustomStyledTextField
                  // error={this.checkError(errors.price, touched.price)}
                  data-test-id='spend_amount'
                  fullWidth={true}
                  placeholder={"0.00"}
                  variant='outlined'
                  onKeyPress={this.numericKeyPress}
                  error={!!this.state.formErrors["spend_amount"]}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='start'>
                        <CustomSpan
                        >
                          SAR
                        </CustomSpan>
                      </InputAdornment>
                    ),
                    onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                      this.numericInputChange(event, "spend_amount"),
                    value: this.state.loyalty.attributes.spend_amount,
                  }}
                />
                {this.state.formErrors["spend_amount"] && (
                  <div style={webStyle.errorText}>{this.state.formErrors["spend_amount"]}</div>
                )}
              </Grid>
              
              <Grid item xs={12} sm={8} md={3}>
                <InputLabel>
                  {configJSON.earnedPoint}
                </InputLabel>
                <CustomStyledTextField
                  data-test-id='earned_points'
                  onKeyPress={this.numericKeyPress}
                  // error={this.checkError(errors.price, touched.price)}
                  fullWidth={true}
                  placeholder={"0"}
                  variant='outlined'
                  InputProps={{
                    value: this.state.loyalty.attributes.earned_points,
                    onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                      this.numericInputChange(event, "earned_points"),
                    endAdornment: (
                      <InputAdornment position='start'>
                        <CustomSpan
                        >
                          Points
                        </CustomSpan>
                      </InputAdornment>
                    ),
                  }}
                  error={!!this.state.formErrors["earned_points"]}
                />
                {this.state.formErrors["earned_points"] && (<div style={webStyle.errorText}>{this.state.formErrors["earned_points"]}</div>)}
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ display: "flex",  marginTop: "32px" }}>
              <Grid item xs={12} sm={8} md={3}>
                <InputLabel>
                  {configJSON.usedPoint}
                </InputLabel>
                <CustomStyledTextField
                  data-test-id='redeemed_points'
                  fullWidth={true}
                  placeholder={"0"}
                  variant='outlined'
                  onKeyPress={this.numericKeyPress}
                  error={!!this.state.formErrors["redeemed_points"]}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='start'>
                        <CustomSpan
                        >
                          Points
                        </CustomSpan>
                      </InputAdornment>
                    ),
                    value: this.state.loyalty.attributes.redeemed_points,
                    onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                      this.numericInputChange(event, "redeemed_points"),
                  }}
                />
                {this.state.formErrors["redeemed_points"] && (
                  <div style={webStyle.errorText}>{this.state.formErrors["redeemed_points"]}</div>
                )}
              </Grid>
              <Grid item xs={12} sm={8} md={3}>
                <InputLabel>
                  {configJSON.creditAmount}
                </InputLabel>
                <CustomStyledTextField
                  data-test-id='received_amount'
                  fullWidth={true}
                  placeholder={"0.00"}
                  variant='outlined'
                  onKeyPress={this.numericKeyPress}
                  InputProps={{
                    onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                      this.numericInputChange(event, "received_amount"),
                    value: this.state.loyalty.attributes.received_amount,
                    endAdornment: (
                      <InputAdornment position='start'>
                        <CustomSpan
                        >
                          SAR
                        </CustomSpan>
                      </InputAdornment>
                    ),
                  }}
                  error={!!this.state.formErrors["received_amount"]}
                />
                {this.state.formErrors["received_amount"] && (<div style={webStyle.errorText}>{this.state.formErrors["received_amount"]}</div>
                )}
              </Grid>
            </Grid>

            <div
              style={{
                background: "#ECECEC",
                height: "1px",
                marginTop: 24,
                marginBottom: 24,
              }}
            />
            <Grid container
              spacing={2}
              style={{ display: "flex", gap: "24px", marginTop: "32px" }}
            >
              <Grid item xs={12} sm={8} md={4}>
                <InputLabel>
                  {configJSON.minimumPointsToRedeem}
                </InputLabel>
                <CustomStyledTextField
                data-test-id='min_redeemed_points'
                  error={!!this.state.formErrors["min_redeemed_points"]}
                  fullWidth={true}
                  onKeyPress={this.numericKeyPress}
                  placeholder={"0"}
                  variant='outlined'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='start'>
                        <span
                          style={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: 500,
                          }}
                        >
                          Points
                        </span>
                      </InputAdornment>
                    ),
                    value: this.state.loyalty.attributes.min_redeemed_points,
                    onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                      this.numericInputChange(event, "min_redeemed_points"),
                  }}
                />
                {this.state.formErrors["min_redeemed_points"] && (<div style={webStyle.errorText}>
                    {this.state.formErrors["min_redeemed_points"]}</div>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={2}
              style={{ display: "flex", gap: "24px", marginTop: "32px" }}
            >
              <Grid item xs={12} sm={8} md={4}>
                <InputLabel>
                  {configJSON.expiryDate}
                </InputLabel>
                <SelectFieldCustom
                  key={"ExpiryDate"}
                  data-test-id={"ExpiryDate"}
                  value={this.state.loyalty.attributes.expiry_date}
                  renderValue={(value) => (value as string) || "Select Period"}
                  onChange={this.handleExpiryDateChange}
                  placeholder={"Select Period"}
                  displayEmpty
                  variant='outlined'
                  error={!!this.state.formErrors["expiry_date"]}
                >
                  {this.state.expiryDateList.map((option) => (
                    <SelectMenuItem value={option.attributes.expiry_date} key={option.id}>
                      <Radio
                        checked={
                          this.state.loyalty.attributes.expiry_date ===
                          option.attributes.expiry_date
                        }
                      />
                      {option.attributes.expiry_date}
                    </SelectMenuItem>
                  ))}
                </SelectFieldCustom>
                {this.state.formErrors["expiry_date"] && (
                  <div style={webStyle.errorText}>
                    {this.state.formErrors["expiry_date"]}
                  </div>
                )}
              </Grid>
            </Grid>

            <div style={webStyle.rowContainer} />
          </div>
          <div
            style={{
              display: "flex",
              gap: "32px",
              marginBottom: "32px",
              padding:'32 0 40 0'
            }}
          >
            <SaveAndCancelButton
              data-test-id='CancelButton'
              variant='contained'
              onClick={this.onCancel}
              size='medium'
              style={{ ...webStyle.cancelButton, textTransform: "none" }}
              disabled={!!this.state.snackBarMessage}
            >
              {configJSON.cancel}
            </SaveAndCancelButton>
            <SaveAndCancelButton
              data-test-id='SaveButton'
              variant='contained'
              onClick={this.onSave}
              size='medium'
              style={{ ...webStyle.saveButton, textTransform: "none" }}
              color='secondary'
              disabled={!!this.state.snackBarMessage}
            >
              {configJSON.submit}
            </SaveAndCancelButton>
          </div>
        </Box>
       
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          data-test-id='snackbar'
          open={!!this.state.snackBarMessage}
          autoHideDuration={4000}
          onClose={this.closeSnackBar}
        >
          <Alert severity='success'>{this.state.snackBarMessage}</Alert>
        </Snackbar>
      </>
    );
  }
}

const CustomGrid = styled(Grid)({
  "& .MuiAutocomplete-root": {
    maxWidth: "unset !important",
    minWidth: "unset !important",
    minHeight: "unset",
    "& .MuiInputBase-root": {
      padding: '4px',
      "@media only screen and (max-width: 1024px)": {
          maxHeight: "unset !important",
      }
    }
  }
})

const CustomSpan = styled("span")({
  color: "#000",
  fontSize: "14px",
  fontWeight: 500,
  "@media only screen and (max-width: 1024px)": {
    fontSize: "13px",
  },
})

const ActiveButton=styled("div")({
  ontWeight: 600,
  fontSize: "18px",
  lineHeight: "20px",
  alignItems: "center",
  letterSpacing: "-0.3px",
  color: "#334155",
  fontFamily: "Montserrat !important",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "16px",
  },
})
const webStyle = {
  mainContainerLoyalty: {
    fontFamily: "Montserrat",
    margin: 0,
  },
  formContainerLoyalty: {
    marginTop: 32,
    // height: "500px",
    boxShadow:
      "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    borderRadius: "12px",
    padding: 32,
  },
  inputField: {
    width: "100%",
    height: "56px",
    fontFamily: "Montserrat !important",
    marginTop: "4px",
    marginBottom: "30px",
  },
  rowContainer: {
    display: "flex",
    gap: "32px",
  },

  addButton: {
    marginTop: "8px",
    fontSize: "16px",
    color: "rgba(77, 77, 77, 1)",
    backgroundColor: "#F1F5F9",
    fontWeight: 700,
  },
  cancelButton: {
    backgroundColor: "#F1F5F9",
    fontSize: "16px",
    width: "128px",
    fontWeight: 700,
    color: "#64748B",
  },
  saveButton: {
    backgroundColor: "#204B9C",
    width: "128px",
    fontSize: "16px",
    fontWeight: 700,
  },

  errorText: {
    marginTop: "4px",
    color: "red",
    fontSize: "12px",
  },

  header: {
    fontFamily: "Montserrat",
    fontWeight: 600,
  },

  warningContainer: {
    padding: "16px",
    fontWeight: 500,
    marginTop: "32px",
    width: "100%",
    borderRadius: "4px",
    fontFamily: "Montserrat",
    borderLeft: "4px solid  #204B9C",
    background: "rgba(32, 75, 156, 0.10)",
  },
  selectedSection: {
    margin: "8px",
    borderRadius: "16px",
    fontFamily: "Montserrat",
    background: "rgba(32, 75, 156, 0.10)",
    color: "#204B9C",
    fontSize: "12px",
    fontWeight: 500,
    padding: "8px",
  },
};
// Customizable Area End
