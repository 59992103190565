Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfcashier";
exports.labelBodyText = "cfcashier Body";

exports.btnExampleTitle = "CLICK ME";

exports.OutGoingTransaction = {
  Strings: {
    transactionID: "Transaction ID",
    store: "Store",
    date: "Date",
    from: "From",
    driver: "Driver",
    amount: "Amount",
    reasons: "Reasons",
    variance: "Variance",
    status: "Status"
  },
};

exports.IncomingTransaction = {
  Strings: {
    transactionID: "Transaction ID",
    storeTxt: "Store",
    storeID: "Store ID",
    storePos: "Station (POS)",
    date: "Date",
    storeKeeper: "Storekeeper",
    to: "To",
    driver: "Driver",
    declaredAmount: "Declared amount",
    confirmAmount: "Confirm amount",
    variance: "Variance"
  },
};

exports.Tabs = {
  Strings: {
    overview: "Overview",
    incomingTransaction: "Incoming transaction",
    outgoingTransaction: "Outgoing transaction",
  },
};
exports.transferRequestsListApi = "bx_block_cfcashier/transfer_requests"
exports.generateTransferIdApi = "bx_block_cfcashier/transfer_requests/get_transfer_id"
exports.getStoreDropdownApi = "bx_block_store_management/store_managements?dropdown=true"
exports.getDriverDropdownApi = "account_block/employees/employee_list?filter_by[role_name]=Driver&dropdown=true&allow_access=true"
exports.editConfirmAmountApi = "bx_block_cfcashier/transfer_requests/update_status"
exports.top = "top";
exports.center = "center";
exports.stringsListPage_noRecordsFound = "No Records Found";
exports.tabTypeID = "tabStoreKeeper"
exports.createCashUpEndpoint = "bx_block_cfcashier/transfer_requests"
exports.getTransferIdEndpoint = "bx_block_cfcashier/transfer_requests/get_transfer_id"
exports.getUserDetailsEndpoint = "account_block/employees"
exports.getDriverEndpoint= "account_block/employees/employee_list?filter_by[role_name]=Driver&dropdown=true&allow_access=true"
exports.getTransactionEndPoint = "bx_block_cfcashier/transfer_requests"
exports.patchMethod = "PATCH"
exports.updateStatusEndPoint = "bx_block_cfcashier/transfer_requests/update_status"
exports.getCashFromOrderEndpoint= "bx_block_cfcashier/transfer_requests/cash_from_orders"
exports.getActiveLogAndNotesEndpoint = "bx_block_comments/comments"
exports.creteNotesEndpoint = "bx_block_comments/comments"

exports.btnSubmit = "Submit"
exports.AddCashupFocus = "Focuse Add Cashup"
exports.incomingTableHeader= [
  { "label": "Transaction ID", "sortingProp": "transfer_id" },
  { "label": "Store", "sortingProp": "to_store" },
  { "label": "Store ID", "sortingProp": "from_store_id" },
  { "label": "Date", "sortingProp": "date" },
  { "label": "Cashier", "sortingProp": "cashier_to" },
  { "label": "To", "sortingProp": "to_store" },
  { "label": "Driver", "sortingProp": "driver" },
  { "label": "Sent amount", "sortingProp": "amount" },
  { "label": "Confirm amount", "sortingProp": "confirm_amount" },
  { "label": "Variance", "sortingProp": "variance" },
  { "label": "Reason", "sortingProp": "reason" }
]
exports.roles = {
  cashier: "Cashier",
  storeKeeper: "Store Keeper",
}

exports.TransactionDetails = {
  Strings: {
    transactionID: "Transaction ID",
    created: "Created",
    from: "From",
    to: "To",
    assignedBy: "Assigned By",
    amount: "Amount",
    reason: "Reason",
    variance: "Variance",
    outgoingTitle: "Outgoing transaction details",
    incomingTitle: "Incoming transaction details",
    incomingPaymentTitle: "Incoming Payment details",
    outgoingCashupTitle: "Cash up details",
    store: 'Store',
    cashier: 'Cashier'
  },
};
exports.exportAllEndpoint = "bx_block_data_import_export/export/export_new_transfer_requests";
exports.putMethod = "PUT";
exports.testgen = "testGen"
exports.permissionMessage = "Currently , You don't have permission to access this. Please contact Administrator.";

exports.outgoingId = "outgoing-transaction"
exports.myStoreEndpoint = "bx_block_store_management/store_managements?dropdown=true&allow_access=true"
// Customizable Area End