// Customizable Area Start
import React from "react";
import ViewSectionController, { Props } from "./ViewSectionController.web"
import "./PreferenceList.css"
import PageContainer from "../../../../packages/blocks/navigationmenu/src/PageContainer.web";
export const configJSON = require("./config");
import { styled } from "@material-ui/core";
import { renderBaseOnConditions } from "../../../blocks/utilities/src/CustomBlockHelpers";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

class ViewSection extends ViewSectionController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
      // Customizable Area Start
        const { viewData, iconDefault, permissionStatus } = this.state
        return (
            <>
              <ViewSectionWrapper>
                <div className="preference-list-container p-0 m-0">
                    <div className="preference-top">
                        <h2>Section</h2>
                        {
                          renderBaseOnConditions(
                            permissionStatus.editPermission,
                            <button
                              onClick={() => this.handleSectionEdit(viewData)}
                              data-test-id="edit-button"
                            >
                              Edit
                            </button>,
                            <></>
                          )
                        }
                    </div>
                    <div className="container-table">
                        {viewData?.attributes && <div className="view-detail">
                            <div className="view-detail-inner">
                                <div className="first-preference">
                                    <p>Section Name</p>
                                    <span>{viewData.attributes.section_name}</span>
                                </div>
                                <div>
                                    <p className="second-preference">Preference</p>
                                    <div className="view-preference">
                                        <img src={viewData.attributes.preference.data === null ? iconDefault : viewData.attributes.preference.data.attributes.gallery_icon?.image} />
                                        <span>{viewData.attributes.preference.data.attributes.preference_first_name}</span>
                                    </div>
                                </div>
                                <div>
                                    {viewData.attributes.online ? <div className="dots-section">
                                        <span className="dots-green dots-section-inner"></span>
                                        <span>{configJSON.activeSuccess}</span>
                                    </div> : <div className="dots-section">
                                        <span className="dots-inactive dots-section-inner"></span>
                                        <span>{configJSON.inActiveText}</span>
                                    </div>}
                                </div>

                            </div>
                            <div className="view-detail-inner">
                                <div className="first-preference">
                                    <p>Section Arabic Name</p>
                                    <span>{viewData.attributes.section_second_name}</span>
                                </div>
                                <div>
                                    <p className="second-preference">Icon</p>
                                    {
                                      renderBaseOnConditions(
                                        viewData.attributes.icon?.data !== null,
                                    <div className="view-preference">
                                      <img
                                        style={{width: '24px', marginRight: '24px'}}
                                        src={viewData.attributes.icon?.data?.attributes.image || ""}
                                        alt={"---"}
                                      />
                                      {viewData.attributes.icon?.data?.attributes.name || '--'}
                                    </div>,
                                    <p>---</p>
                                    )}
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                    <div className="back-button preference-top">
                        <button onClick={this.handleCancel} data-test-id="cancel-button">Back</button>
                    </div>
                </div>
              </ViewSectionWrapper>
            </>
        );
      // Customizable Area End
    }
}

// Customizable Area Start
export default ViewSection;
const ViewSectionWrapper = styled("div")({
  "& .p-0": {
    padding: 0
  },
  "& .m-0": {
    margin: 0
  },
  "& .preference-top button": {
    height: "56px",
    width: '184px',
    padding:"16px",
    "@media (max-width: 1024px)": {
      height: "44px",
      width: '128px',
    },
  },
  "& .preference-top h2": {
    "@media (max-width: 1024px)": {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight:"19.2px",
    },
    fontSize: '24px',
    fontWeight: 600,
    lineHeight:"29.26px",
  },
  "& .filterButton": {
    "@media (max-width: 1366px)": {
     padding:"10px"
    },
  },
  "& .first-preference p" :{
    "@media (max-width: 1366px)": {
        fontSize: '16px',
      },
      "@media (max-width: 1024px)": {
        fontSize: '14px',
      },
  },
  "& .first-preference span" :{
    "@media (max-width: 1366px)": {
        fontSize: '14px',
        fontWeight:"normal"
      },
  },
  "& p.second-preference":{
    "@media (max-width: 1366px)": {
        fontSize: '16px',
      },
      "@media (max-width: 1024px)": {
        fontSize: '14px',
      },
  },
  "& .view-preference": {
    "@media (max-width: 1366px)": {
        fontSize: '14px',
        fontWeight:"normal"
      },
  },
  "& .back-button button": {
    height: "56px",
    width: '184px',
    padding:"16px",
    "@media (max-width: 1024px)": {
      height: "44px",
      width: '128px',
    },
  }
})
// Customizable Area End
