Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "UserGroups2";
exports.labelBodyText = "UserGroups2 Body";
exports.viewCustomerGroupEndPoint = "bx_block_account_groups/business_customer_groups"
exports.btnExampleTitle = "CLICK ME";
exports.token = "token";

exports.customerGroupListEndPoint =
  "bx_block_account_groups/business_customer_groups";
exports.customerListEndPoint =
  "bx_block_account_groups/business_account_customers";
exports.companyDropdonListEndPoint =
  "bx_block_company/companies?type=b2b_company&dropdown=true";
exports.customerDropdownListEndPoint =
  "/bx_block_account_groups/business_account_customers?dropdown=true";
exports.createCustomerEndPoint =
  "bx_block_account_groups/business_customer_groups";
exports.customerGroupDataEndPoint ="bx_block_account_groups/business_account_customers";
exports.groupFilterSuggestionEndPoint =
  "bx_block_account_groups/business_customer_groups/business_customer_groups_suggestion";

exports.strings = {
  B2bCustomerGroupList: {
    customerGroups: "Customer Groups",
    addCustomerGroup: "Add Customer Group",
    editCustomerGroup: "Edit Customer Group",
    tabelString_noAccountDataFound: "No Data Found",
    customers: "Customers",
    customerGroup: "Customer Group:",
    search: "Search",
    tableString_customers: "Customers",
    tableString_emailId: "Email ID",
    tableString_mobileNo: "Mobile No.",
    noCustomersFound: "No Customers Found",
    showAll: "Show All",
    name: "Customers",
    email: "Email ID",
    phone: "Mobile No.",
    cancel: "Cancel",
    view: "View",
    edit: "Edit",
    deactivate: "Deactivate",
    activate: "Activate",
    confirmButtonText: "Back to Listing",
    groupDeactivateMessage: "Customer Group Deactivated",
    confirmDeactivate: "Confirm Deactivate",
    back: "Back",
    noPermissions: "No Permissions",
  },
  B2bAddCustomerGroup: {
    addCustomerGroup: "Add Customer Group",
    editCustomerGroup: "Edit Customer Group",
    customerGroupName: "Customer Group Name",
    enterCustomerGroupName: "Enter Customer Group Name",
    company: "Company",
    designation: "Designation",
    noItemPerWash: "No. of Items per Wash",
    noPieacePerOrder: "No. of Pieces per Order",
    vipPrivileges: "VIP Privileges",
    selectCompany: "Select Company",
    enterDesignation: "Enter Designation",
    enterItemsPerWash: "Enter Item Per Wash",
    emterPieacePerWash: "Enter Pieces Per Order",
    cancel: "Cancel",
    save: "Save",
    add: "Add",
    groupDescription: "Group Description",
    typeToSearchCustomer: "Type to Search Customer",
    noCustomerFound: "No Customer Found",
  },
};
// Customizable Area End