import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  InputLabel,
  Typography,
  Chip,
  Grid,
  Paper,
  styled
} from "@material-ui/core";

import { withStyles, createTheme, ThemeProvider } from "@material-ui/core/styles";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";

import ViewUpchargeController, {
  Props,
  configJSON
} from "./ViewUpchargeController.web";
import Loader from "../../../components/src/Loader.web";
import { CustomSnackbar } from "../../../components/src/customComponents/CustomSnackbar.web";
import { colors } from "../../../blocks/utilities/src/Colors";
import { OrgHeading } from "./UpchargeList.web";
import { renderBaseOnConditions } from "../../../blocks/utilities/src/CustomBlockHelpers";

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat",
  },
});
// Customizable Area End

export default class ViewUpcharge extends ViewUpchargeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getFieldData(data: string | undefined) {
    if (data !== undefined && data !== null) {
      return <ValueText>{data}</ValueText>
    } else {
      return <Typography style={webStyle.fieldData}>--</Typography>
    }
  }

  getMultiFieldData(data: string[] | undefined) {
    if (data !== undefined && data !== null && data.length > 0) {
      return (<Typography style={webStyle.fieldData}>
        <div style={webStyle.chips}>
          {data.map((item: string) => <StyledChip key={item} label={item} />)}
        </div>
      </Typography>)
    } else {
      return <Typography style={webStyle.fieldData}>--</Typography>
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { permissionStatus } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box>
            <div className="upcharge-view-container">
              <CustomLoader loading={this.state.isLoading}/>
              <StyledBox>
                <div style={webStyle.headerWrapper}>
                  <OrgHeading>Upcharge</OrgHeading>
                  <PrimaryButtonBox>
                    {
                      renderBaseOnConditions(
                        permissionStatus.editPermission,
                        <Button
                          className="editButtonStyle"
                          data-test-id="btnEdit"
                          onClick={() => this.handleRedirect()}
                        >
                          Edit
                        </Button>,
                        <></>
                      )
                    }
                  </PrimaryButtonBox>
                </div>
                <CustomPaper style={webStyle.paper}>
                  <Grid container spacing={3} id="modal-content">
                    <Grid item xs={12} md={6}>
                      <StylesLableText>Upcharge Name</StylesLableText>
                      {this.getFieldData(this.state?.upchargeDetail?.upcharge_name)}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <StylesLableText>Upcharge Arabic Name</StylesLableText>
                      {this.getFieldData(this.state?.upchargeDetail?.upcharge_second_name)}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <StylesLableText>Icon</StylesLableText>
                      <MainBoxIcon>
                        {
                          renderBaseOnConditions(
                          this.state?.upchargeDetail?.icon !== null,
                          <>
                        <MainImg src={this.state?.upchargeDetail?.icon?.image ? this.state?.upchargeDetail?.icon?.image : configJSON.defaultIcon} />
                        <ValueText>{this.state?.upchargeDetail?.icon?.name}</ValueText>
                          </>,
                          <p>---</p>     
                          )
                        }
                      </MainBoxIcon>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <StylesLableText>Section</StylesLableText>
                      {this.getMultiFieldData(this.state?.upchargeDetail?.section)}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <StylesLableText>Service</StylesLableText>
                      {this.getMultiFieldData(this.state?.upchargeDetail?.service)}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <StylesLableText>Product</StylesLableText>
                      {this.getMultiFieldData(this.state?.upchargeDetail?.product)}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <StylesLableText>Price</StylesLableText>
                      <ValueText>
                        {this.state?.upchargeDetail?.type === "SAR"
                          ? `SAR ${this.state?.upchargeDetail?.price ?? ''}`
                          : `${this.state?.upchargeDetail?.price ?? ''}%`}
                      </ValueText>
                    </Grid>
                  </Grid>
                </CustomPaper>
                <Box style={{ marginTop: '48px', marginBottom: '40px' }}>
                <BackButtonBox>
                  <Button
                    data-test-id="btnBack"
                    onClick={() => this.handleBackToUpchargeList()}
                    className="backButtonStyle"
                  >
                    Back
                  </Button>
                </BackButtonBox>
                </Box>
              </StyledBox>
            </div>
            <CustomSnackbar
              open={this.state.errorSnackbarOpen}
              onClose={() => {this.handleSnackbarClose()}}
              errorMessage={this.state.errorMessage}
              severity="error"
            />
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  table: {
    minWidth: 700,
  },
  mainWrapper: {
    marginTop: "32px",
    fontFamily: "Montserrat",
  },
  pageHeader: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "29px",
  },
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 32,
    alignItems: "center",
  },
  button: {
    background: "#204B9C",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
    height: "56px",
    width: "128px",
    borderRadius: "8px",
    marginLeft: "10px"
  },
  contentWrapper: {
    marginRight: 32,
    marginTop: 56,
  },
  fieldError: {
    color: "red",
    fontSize: "14px",
    margin: "5px 5px 5px 0",
    fontFamily: 'Montserrat'
  },
  fieldLabel: {
    marginBottom: "6px",
    color: "#4d4d4d",
    fontSize: "18px",
    fontWeight: 600
  },
  fieldSet: {
    padding: "12px 8px"
  },
  chips: {
    display: 'inline',
    alignItems: 'center',
    height: '38px',
    marginTop: '12px'
  },
  chip: {
    margin: "2px 8px 2px 0",
    backgroundColor: 'rgba(32, 75, 156, 0.1)',
    color: '#204B9C',
    height: '26px',
    borderRadius: '8px',
    textTransform: "capitalize",
    fontSize:"24px"
  },
  fieldData: {
    fontSize: '14px',
    fontWeight: 500,
  },
  paper: {
    marginBottom: '32px',
    marginRight: '16px'
  }
};
const CustomPaper = withStyles({
  root: {
    borderRadius: '12px',
    background: '#fff',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.03), 0px 5px 32px rgba(0, 0, 0, 0.06)',
    padding: '32px',
  }
})(Paper);

const PrimaryButtonBox = styled(Box)({
  '& .editButtonStyle': {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    height: "56px",
    width: '184px',
    borderRadius: "8px",
    marginLeft: "10px",
    backgroundColor: colors().cyancobaltblue,
    textTransform: "capitalize",
    color: colors().background,
    "@media only screen and (max-width: 1024px)": {
      width: 128,
      height: 44,
    }
  },
});

const StylesLableText = styled("div")({
  fontSize: 18,
  fontWeight: 600,
  paddingBottom: 12,
  lineHeight: "22px",
  color: "var(--dark-grey, #4D4D4D)",
  "@media only screen and (max-width: 1024px)": {
    fontSize: 16,
    fontWeight: 600,
    paddingBottom: 12,
    lineHeight: "22px",
    color: colors().viewTextColor ,
  },
});


export const ValueText = styled("div")({
  fontSize: 14,
  fontWeight: 500,
  lineHeight: "22px",
  color: "var(--basic-black, #000)",
  "@media only screen and (max-width: 1024px)": {
    fontSize: 13,
    fontWeight: 500,
    paddingBottom: 12,
    lineHeight: "22px",
    color: colors().viewTextColor ,
  },
});

const MainBoxIcon = styled("div")({
  display: "flex",
  alignItems: "center"
});

const MainImg = styled("img")({
  height:"24px",
  marginRight:"10px",
  "@media only screen and (max-width: 1024px)": {
    marginBottom:"10px"
  },
});

const StyledChip = styled(Chip)({
  margin: "2px 8px 2px 0",
  backgroundColor: 'rgba(32, 75, 156, 0.1)',
  color: '#204B9C',
  height: '26px',
  borderRadius: '8px',
  textTransform: "capitalize",
  "@media only screen and (max-width: 1024px)": {
    fontSize: 13,
    fontWeight: 500,  },
});

const BackButtonBox = styled(Box)({
  '& .backButtonStyle': {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    height: "56px",
    width: '128px',
    borderRadius: "8px",
    marginLeft: "10px",
    backgroundColor: colors().cyancobaltblue,
    textTransform: "capitalize",
    color: colors().background,
    "@media only screen and (max-width: 1024px)": {
      width: 128,
      height: 44,
    }
  },
});

const StyledBox = styled(Box)({
  marginTop: "25px"
});



// Customizable Area End
