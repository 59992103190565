// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  viewData: ViewData,
  iconDefault: string,
  permissionStatus: PermissionStatus;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string | number;

  // Customizable Area End
}
// Customizable Area Start
interface ViewData {
  attributes: {
    id: number,
    section_name: string,
    section_second_name: string,
    preference_id: number,
    online:boolean,
    preference:{
      data:{
        attributes:{
          preference_first_name: string
          gallery_icon:{
            image:string
          } | null
        }
      }
    }
    icon: { data: { attributes: { image: string, name: string}}}
  }

}

// Customizable Area End

// Customizable Area Start
export default class ViewPreferenceController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  preferenceDataCallId: string = "";
  getSectionByIdCallId: string = "";
  sectionId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.LayoutDataMessage),
    ];

    this.state = {
      viewData: {} as ViewData,
      iconDefault: configJSON.defaultIcon,
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      }
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount(): any {
    // Customizable Area Start
    this.sectionId = this.props.navigation.getParam('id');
    this.getSectionById();
    // Customizable Area End
  }
  // Customizable Area Start
  async receive(from: string, message: Message) {
    this.receiveDataFromLayout(message);
    this.handleResponseForGetSectionById(from, message)
  }

  handleResponseForGetSectionById = (from: string, message: Message) => {
    if (this.getSectionByIdCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        const apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiResponse) {
          this.setState({
            viewData: apiResponse.data,
          })
        }
    }
  }

  receiveDataFromLayout = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.LayoutMessageData)
        );
        if (recievedData.userContext) {
          this.handleUserChange(recievedData.userContext)
        }
    }
  }
  handleCancel = () => {
    this.props.navigation.history.push("/Products-SectionList")
  }
  handleSectionEdit = (item:ViewData) => {
    this.props.navigation.history.push(`/Products-SectionListEdit/${item?.attributes.id}`, { state: item })
}

  getSectionById = () => {
    const headers = {
        "Content-Type": configJSON.categoryApiContentType,
        token: window.localStorage.getItem(configJSON.token)
    };

    const apiUrl =
        configJSON.sectionListEndPoint + `/${this.sectionId}`;
    const sectionByIDDataMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSectionByIdCallId = sectionByIDDataMessage.messageId;

    sectionByIDDataMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        apiUrl
    );

    sectionByIDDataMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
    );

    sectionByIDDataMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetType
    );

    runEngine.sendMessage(sectionByIDDataMessage.id, sectionByIDDataMessage);
}

handleUserChange = (userContext: IUserContext) => {
  const apiKey = customPermissionApiKey.sectionNewPermissions;
  const userData = userContext.user?.attributes.permission_groups;
  const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
  this.setState({
    permissionStatus: value
  })
};

  // Customizable Area End

}
// Customizable Area End
