Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
const urlConfig = require("../../../framework/src/config");

exports.blank = "----";
exports.dots = "..."
exports.customerTypeEndPoint = "bx_block_account_groups/customer_types";
exports.APIBaseURL = urlConfig.baseURL;
exports.validationApiContentType = "application/json";
exports.token = "token";
exports.storeManagementApi = "bx_block_store_management/store_managements";
exports.storeGroupApi = "bx_block_store_management/store_groups";
exports.storeList = "bx_block_store_management/store_managements?dropdown=true&token=";
exports.apiContentType = "application/json";
exports.plantDetailEndPoint = "bx_block_plants/plants";
exports.plantEndPoint = "bx_block_plants/plants";
exports.reasonListApi = "bx_block_store_management/store_managements/close_store_reasons";
exports.getStoreSuggestionEndPoint = "bx_block_store_management/store_managements/store_filter?store_name=";
exports.getAreaSuggestionEndPoint = "bx_block_store_management/store_managements/store_filter?area_name=";
exports.getRegionSuggestionEndPoint = "bx_block_store_management/store_managements/store_filter?region_name=";
exports.storeSuggestionApi = "bx_block_store_management/store_managements/initialize";
exports.getSectionListApiEndPoint = '/bx_block_categories/categories';
exports.popoverTest = "popoverTest";
exports.getSectionListApiMethod = 'GET';
exports.areaListApiEndPoint = "bx_block_regions/areas?dropdown=true";
exports.serviceOfferListApiEndPoint = "bx_block_drop_down/drop_down?module_name=Service Offer";
exports.fieldRequiredValidText = "This field is required";
exports.companyEndPoint = "bx_block_company/companies?dropdown=true";
exports.priceListEndPoint = "bx_block_price_list/price_lists?dropdown=true&price_list_type=Store";
exports.storeKeeperEndPoint= "account_block/employees/employee_list?filter_by[role_name]=Store Keeper";
exports.regionListApiEndPoint = "bx_block_regions/regions";
exports.add = "Add";
exports.testing00 = "testing00";
exports.checkStoreKeeper= "bx_block_store_management/store_managements/check_storekeeper";
exports.releaseStoreKeepers= "bx_block_store_management/store_managements/update_storekeepers";
exports.selectPlantLinked = "Select Plant Linked";
exports.addText = "Add";
exports.saveText = "Save";
exports.errorText = "Error in saving";
exports.deleteText = "Delete";
exports.plantLinked = "Plant Linked";
exports.saveStoreGroup = "bx_block_store_management/store_groups";
exports.detailStoreGroup = "bx_block_store_management/store_groups";
exports.right = "right";
exports.bottom = "bottom";
exports.top = "top";
exports.subtitle="subTitle";
exports.subTitle1="subTitle1";
exports.title1="title1";
exports.addressFields = ["sub_cr_number", "street_name", "additional_street_name", "building", "plot", "city", "city_subdivision", "postal_code", "country_sub_entity"]

// import export constants
exports.importExport = "Export/Import"
exports.exportCSVTemplateApiUrl = "bx_block_data_import_export/export/export_template_store_management";
exports.exportCSVApiUrl = "bx_block_data_import_export/export/export_store_management";
exports.importCSVApiUrl = "bx_block_data_import_export/export/import_store_management";
exports.changeSingleStoreB2bSetting = 
  "bx_block_store_management/store_managements/update_b2b_order_status?id=";
exports.changeMultipleStoreB2bSetting = 
  "bx_block_store_management/store_managements/update_store_availability"

exports.storeGrpEndpoint = "bx_block_store_management/store_groups"
exports.addressArabicTxt = "Address (Arabic)"
exports.selectStoreKeeperTxt = "Select Store Keeper"
exports.noData = "No record found !!"
exports.filterRegionEndpoing = "bx_block_store_management/store_groups/store_group_filter?region_name="
exports.selectAtleastTwoStoreMsg = "Please select atleast 2 stores"
exports.test="test"

exports.storeListString = {
  update: "Update",
  availableForB2b: "Available for B2B",
  availableForB2c: "Available for B2C",
}
// Customizable Area End