import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  InputLabel,
  Typography,
  Modal,
  Grid,
  MenuItem,
  FormControlLabel,
  Snackbar,
  styled
} from "@material-ui/core";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toString } from "lodash";
import Alert from '@material-ui/lab/Alert';
import AddPriceListModalController, { IFormValue, configJSON } from "./AddPricelistModalController.web";
import { 
  InputField, 
  CustomFormControl, 
  CustomMenuItem, 
  MultiSelectionBox,
} from '../../../components/src/customComponents/CustomTags.web';
import { colors } from "../../../blocks/utilities/src/Colors";
import RadioDropdownWeb, {StyledRadio} from "../../../components/src/customComponents/RadioDropdown.web";
import AutocompleteSelect from "../../../components/src/AutocompleteSelect.web";
import { CustomSearchableMultiSelect } from "../../../components/src/customComponent/CustomSearchableMultiSelect.web";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";


const String = configJSON.Strings.addPriceList;
const modalStyle: any = {
  maxHeight: "calc(100vh - 126px)",
  position: "absolute",
  top: "49%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: '788px',
  height: "70vh",
  bgcolor: "#fff",
  borderRadius: 12,
  boxShadow: 24,
  padding: "24px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  overflowY: "auto",
  "@media (max-width: 768px)": {
      padding: "20px",
      width: "75%"
  }
};
// Customizable Area End

class AddPriceListModal extends AddPriceListModalController {
  // Customizable Area Start
  checkError(error: string, touch: boolean) {
    if (error && touch) {
        return true
    }
  }

  finalErrorMessage(error: string, touch: boolean) {
    if (error) {
      if (touch) {
        return <div data-test-id="nameError" style={priceListWebStyle.fieldError}>
          {error}
        </div>
      }
      else {
        return null
      }
    }
    else {
      return;
    }
  }

  checkAccountError() {
    if (this.state.accountError !== "") {
      return <div style={{ fontSize: '14px', color: 'red', marginTop: '5px' }}>{this.state.accountError}</div>
    }
  }

  checkPriceError() {
    return <div style={{ fontSize: '14px', color: 'red', marginTop: '5px' }}>{this.state.priceListMinusValueMessage}</div>
  }

  checkPriceListEmptyError(errors:any, touched:any){
    if(errors.price_list_to_copy && touched.price_list_to_copy){
      return "fieldBorderError"
    }
    else{
      return "fieldBorder"
    }
  }

  checkFieldError(errors: string, touched:boolean){
    if(errors && touched){
      return "fieldBorderError"
    }
    else{
      return "fieldBorder"
    }
  }

  priceListTypeDropdownList(stateValue: string) {
    return this.state.dropdownOptions?.priceListType?.map((option: {id: number, option: string, value: string}, index: number) => {
      return (
        <CustomMenuItem 
          key={index} 
          value={option.value}
        >
          <FormControlLabel 
            checked={stateValue === option.value} 
            control={<StyledRadio />} 
            label={""} 
            name="option" />
          {option.option}
        </CustomMenuItem>
      )
    })
  }

  TaxTypeDropdownList(stateValue: string) {
    return this.state.dropdownOptions?.taxType?.map((option: {id: number, option: string, value: string | number}, index: number) => {
      return (
        <CustomMenuItem 
          key={index} 
          value={option.value}
        >
          <FormControlLabel
            checked={stateValue === option.value} 
            control={<StyledRadio />} 
            label={""} 
            name="option" 
          />
          {option.option}
        </CustomMenuItem>
      )
    })
  }

  checkIsError(errors: string, touched: boolean) {
    if(errors && touched) {
      return true
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start 
    const { open, onClose } = this.props;

    const validationSchema = Yup.object({
      name: Yup.string().trim().required("Name is required."),
      priceListType: Yup.string().trim().required("Price list type is required."),
      product_currency_id: Yup.string().required("Product is required."),
      price_list_to_copy: Yup.string().required("Please select price list to copy").nullable(),
      taxType: Yup.string().required("Please select tax type"),
      section: Yup.string().required("Please select section"),
      product: Yup.string().required("Please select product"),
      added_by: Yup.number().positive().min(0)
    });
    const ITEM_HEIGHT = 80;
    const ITEM_PADDING_TOP = 8;
    const MenuProps: any = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 200,
        },
      },
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left"
      },
      getContentAnchorEl: null
    };
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <Modal  //modal for add price
          data-test-id={configJSON.addPriceModalTestId}
          open={open}
          BackdropProps={{ onClick: (event) => event.stopPropagation() }}
          disableEscapeKeyDown={true}
          onClose={this.handlePriceModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <AddModel>
         <CustomLoader loading={this.state.isSaveLoading}/>
          <Box sx={modalStyle} className="modalStyle">
            <Typography style={priceListWebStyle.addPriceHeader} className="addPriceHeader">
              {String.addPriceListText}
            </Typography>
            <Formik
              data-test-id="addPriceFormik"
              initialValues={this.state.addPriceInitialvalues}
              validationSchema={validationSchema}
              onSubmit={(values: IFormValue) => { this.handleAddNewPriceList(values) }}
              enableReinitialize
            >
              {({
                values,
                errors,
                touched,
                handleSubmit,
                handleBlur,
                setFieldValue,
                getFieldProps
              }: any) => (
                <form onSubmit={handleSubmit} data-test-id="addPriceForm">
                  <Grid container spacing={2} id="modal-content">
                    <Grid item xs={12} sm={6}>
                      <InputLabel style={priceListWebStyle.fieldLabel} className="fieldLabel">{String.name}</InputLabel>
                      <InputFieldStyle
                        data-test-id="addPriceListName"
                        error={this.checkError(errors.name, touched.name)}
                        id="name"
                        name="name"
                        fullWidth={true}
                        placeholder="Enter Name"
                        variant="outlined"
                        {...getFieldProps('name')}
                      />
                      {this.finalErrorMessage(errors.name, touched.name)}
                      {this.checkAccountError()}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputLabel style={priceListWebStyle.fieldLabel} className="fieldLabel">{String.price_list_type}</InputLabel>
                      <CustomFormControl variant="outlined" style={priceListWebStyle.formControl}>
                        <MultiSelectionBox
                          data-test-id="priceListTypeDropdown"
                          labelId={"priceListType"}
                          id={'priceListType'}
                          name={'priceListType'}
                          fullWidth
                          displayEmpty
                          defaultValue={""}
                          className={this.checkFieldError(errors.priceListType, touched.priceListType)}
                          inputProps={{ disableUnderline:true }}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setFieldValue('priceListType', event.target.value);
                            this.handlePriceListTypeDropdown(event.target.value)
                            this.handleChangeSelectedPriceList(null, setFieldValue)
                          }}
                          onBlur={handleBlur}
                          MenuProps={MenuProps}
                          value={values.priceListType}
                        >
                          <MenuItem style={{ color: '#4d4d4d', display: 'none' }} value="">{"Select Price List Type"}</MenuItem>
                          {this.priceListTypeDropdownList(this.state.selectedPriceListType as string)}
                        </MultiSelectionBox>
                      </CustomFormControl>
                      {this.finalErrorMessage(errors.priceListType, touched.priceListType)}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputLabel style={priceListWebStyle.fieldLabel} className="fieldLabel">{String.price_list_to_copy}</InputLabel>
                      <CustomFormControl variant="outlined" style={priceListWebStyle.formControl}>
                        <AutocompleteSelect 
                          data-test-id="priceListCopyDropdown"
                          fieldName="price_list_to_copy"
                          placeholder="Select price list"
                          handleScroll={this.handleScrollPricelistDropdown.bind(this)}
                          debouncedFunction={(getValue: string) => { 
                            this.debouncedFunction(getValue, this.handleAutoCompleteChange)
                          }}
                          className={this.checkPriceListEmptyError(errors, touched)}
                          onChange={(_blank: React.ChangeEvent<HTMLInputElement>, value: {id: number, option: string} | null) => {
                            this.handleChangeSelectedPriceList(value, setFieldValue)
                          }}
                          onBlur={(event: React.MouseEvent) => {
                            if (this.state.selectedPriceListType) {
                              handleBlur(event);
                              this.handleAutoCompleteChange(toString(this.state.selectedPriceListToCopy?.option))
                            }
                          }}
                          value={values.price_list_to_copy}
                          options={this.state.selectedPriceListType != "" ? this.state.dropdownOptions?.priceListToCopy as [] : []}
                          listBoxStyle={{maxHeight: 270}}
                        />
                      </CustomFormControl>
                      {this.finalErrorMessage(errors.price_list_to_copy, touched.price_list_to_copy)}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputLabel style={priceListWebStyle.fieldLabel} className="fieldLabel">{String.tax_type}</InputLabel>
                      <CustomFormControl variant="outlined" style={priceListWebStyle.formControl}>
                        <MultiSelectionBox
                          data-test-id="taxTypeDropdown"
                          labelId={"taxType"}
                          id={'taxType'}
                          name={'taxType'}
                          fullWidth
                          displayEmpty
                          defaultValue={""}
                          className={this.checkFieldError(errors.taxType, touched.taxType)}
                          inputProps={{ disableUnderline:true }}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => { 
                            setFieldValue('taxType', event.target.value);
                            this.handleTaxTypeDropdown(event.target.value);
                          }}
                          onBlur={handleBlur}
                          MenuProps={MenuProps}
                          value={values.taxType}
                        >
                          <MenuItem style={{ color: '#4d4d4d', display: 'none' }} value="">{"Select Tax Type"}</MenuItem>
                          {this.TaxTypeDropdownList(this.state.selectedTaxType as string)}
                        </MultiSelectionBox>
                      </CustomFormControl>
                      {this.finalErrorMessage(errors.taxType, touched.taxType)}
                    </Grid>
                    {Boolean(this.state.selectedPriceListToCopy)  && 
                    <>
                      <Grid item xs={12} sm={6}>
                        <InputLabel style={priceListWebStyle.fieldLabel} className="fieldLabel">{String.section}</InputLabel>
                        <CustomSearchableMultiSelect
                              id="section"
                              name="section"
                              dataTestId="sectionDropdown"
                              checkSelectAllText={() => { }}
                              value={this.state.selectedSections}
                              optionList={this.state.sectionOptions}
                              placeHolderText="Select Section"
                              changeInputOnClear
                              handleScroll={this.handleScrollSectionDropdown}
                              handleClickOnSelectAll={() => { }}
                              isOnBlurFunction={() => this.debouncedFunctionSection("", this.handleSectionAutoCompleteChange)}
                              debouncedFunction={(getValue: string) => { this.debouncedFunctionSection(getValue, this.handleSectionAutoCompleteChange) }}
                              handleSelectOptions={(value: { id: string | number, option: string }[], option: { id: string | number, option: string }) => { this.handleSelectSectionOptions(value, option, setFieldValue) }}
                              emptyDataMessage="No Section Found"
                              handleEmptyAutoSelectValue={() => { }}
                              selectedOptionForList={this.state.selectedSectionsForList}
                              isError={this.checkError(errors.section, touched.section)}
                              style={{ maxWidth: "100%", marginTop: 12, minWidth:"250px" }}
                            />
                      </Grid>
                    </>
                    }
                    {this.state.selectedSections.length > 0 && 
                    <Grid item xs={12} sm={6}>
                      <InputLabel style={priceListWebStyle.fieldLabel} className="fieldLabel">{String.product}</InputLabel>
                        <CustomSearchableMultiSelect
                          id="product"
                          name="product"
                          dataTestId="productDropdowns"
                          checkSelectAllText={(updatedValue: { id: string | number, option: string }[]) => { this.checkPricelistSelectAllText(updatedValue); setFieldValue('product', updatedValue) }}
                          value={this.state.selectPricelistProductForAdd}
                          optionList={this.state.pricelistProductList}
                          placeHolderText={String.selectProductText}
                          handleScroll={this.handlePricelistScrollProductDropdown}
                          handleClickOnSelectAll={() => { this.handlePricelistClickOnSelectAll(); setFieldValue('product', ["All"]) }}
                          debouncedFunction={(getValue: string) => { this.debouncedFunction(getValue, this.handleAutoCompleteProductChange) }}
                          handleSelectOptions={(value: { id: string | number, option: string }[], option: { id: string | number, option: string }) => { this.handlePricelistSelectOptions(value, option, setFieldValue) }}
                          emptyDataMessage={String.noProductText}
                          handleEmptyAutoSelectValue={() => { this.handlePricelistEmptyAutoSelectValue() }}
                          style={{ maxWidth: "100%", marginTop: 12, minWidth:"250px" }}
                          selectedOptionForList={this.state.addPricelistSelectedOptionForList}
                        />
                        {this.finalErrorMessage(errors.product, touched.product)}
                    </Grid>
                    }
                    <Grid item xs={4} sm={2} style={{width: "13%"}}>
                      <InputLabel style={priceListWebStyle.fieldLabel} className="fieldLabel">Type</InputLabel>
                      <RadioDropdownWeb
                        data-test-id="currencyDropdown"
                        fieldLabel={'Product'}
                        fieldName={'product_currency_id'}
                        isError={this.checkIsError(errors.product_currency_id, touched.product_currency_id)}
                        options={this.state.dropdownOptions?.currency}
                        placeholder={"Product"}
                        errorMessage={errors.product_currency_id}
                        withIcon={false}
                        setFieldValue={setFieldValue}
                        style={{ minWidth: '50px' }}
                        {...getFieldProps('product_currency_id')}
                      />
                    </Grid>
                    <Grid item xs={8} sm={4} style={{width: "37%"}}>
                      <InputLabel style={priceListWebStyle.fieldLabel} className="fieldLabel">{String.addBy}</InputLabel>
                      <InputFieldStyle
                        type="number"
                        inputProps={{
                          "data-test-id": "price-amount",
                          }}
                        onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                          e.target.addEventListener(
                            "wheel",
                            function (e: WheelEvent) {
                              e.preventDefault();
                            },
                            { passive: false }
                          );
                        }}
                        error={this.checkError(errors.added_by, touched.added_by)}
                        id="added_by"
                        name="added_by"
                        fullWidth={true}
                        placeholder="Amount or %"
                        variant="outlined"
                        value={values.added_by}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue("added_by", event.target.value);
                          this.setState({
                            priceListMinusValueError: false,
                            priceListMinusValueMessage: ""
                          })
                        }}
                      />
                      {this.finalErrorMessage(errors.added_by, touched.added_by)}
                      {this.state.priceListMinusValueError && this.checkPriceError()}
                    </Grid>
                  </Grid>
                  <Box style={{ marginTop: '30px', marginBottom: '20px', textAlign: 'center' }}>
                    <Button
                      data-test-id="cancelBtn"
                      className="cancelBtn"
                      onClick={() => {
                        this.handlePriceModalClose();
                        setFieldValue({
                          name: '',
                          price_list_to_copy: '',
                          product: [],
                          section: [],
                          taxType: "",
                          priceListType: "",
                          product_currency_id: "SAR",
                          added_by: ''
                        }); 
                      }}
                      style={{ ...priceListWebStyle.addButton, backgroundColor: '#F1F5F9', color: '#64748B', width: '128px' }}
                    >
                      {String.cancel}
                    </Button>
                    <Button
                     className="cancelBtn"
                      data-test-id="btn-submit"
                      type="submit"
                      style={{ ...priceListWebStyle.addButton, width: '128px' }}
                    >
                      {String.add}
                    </Button>
                  </Box>
                  <Modal
                    data-test-id="mobilePriceModal"
                    open={this.state.mobileConfirmModal}
                    onClose={() => {
                      this.handleCloseMobileModal()
                      setFieldValue("priceListType", "");
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={{...modalStyle, width: "450px", height: "auto"}} >
                      <h3 style={priceListWebStyle.mobileModalHeadText}>
                        {String.conformDeactiveText}
                      </h3>
                      <Typography style={priceListWebStyle.mobileModalText}>
                        {this.state.mobileDeactiveMsg}
                      </Typography>
                      <Box>
                        <Button
                          data-test-id="mobileDeactiveCancel"
                          onClick={() => {
                            this.handleCloseMobileModal()
                            setFieldValue("priceListType", ""); 
                          }}
                          style={{...priceListWebStyle.addButton, background: "#f1f5f9", color: "#64748b"}}
                        >
                          {String.cancel}
                        </Button>
                        <Button
                          data-test-id="mobileDeactive"
                          onClick={this.confirmDeactiveMobilePriceList} 
                          style={priceListWebStyle.addButton}
                        >
                          {String.deactive}
                        </Button>
                      </Box>
                    </Box>
                  </Modal>
                </form>
              )}
            </Formik>
          </Box>
          </AddModel>
        </Modal>
        <Snackbar 
          data-test-id="snackbar" 
          anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
          }} 
          open={this.state.openPriceListSnackbar} 
          autoHideDuration={1700} 
          onClose={() => { this.setState({ openPriceListSnackbar: false }) }} >
          <Alert data-test-id="alert-snackbar" severity={"success"} >
            {String.pricelistAddedSuccess}
          </Alert>
        </Snackbar>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const priceListWebStyle:any = {
  addButton: {
    background: "#204B9C",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    textTransform: "unset" as const,
    color: "#FFFFFF",
    height: "56px",
    width: "184px",
    borderRadius: "8px",
    marginLeft: 10
  },
  emptyPriceError: {
    color: "red", 
    whiteSpace: "wrap", 
    fontSize: "15px",
    fontWeight: 600,
  },
  mobileModalText: {
    margin: "32px 0",
    color: "#000",
    fontSize: "16px",
    lineHeight: "20px",
    fontFamily: 'Montserrat',
    textAlign: "center"
  },
  mobileModalHeadText: {
    color: "#000",
    fontSize: "24px",
    fontFamily: 'Montserrat'
  },
  fieldLabel: {
    marginBottom: "2px",
    marginTop: '10px',
    color: "#334155",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: 'Montserrat'
  },
  fieldError: {
    color: "red",
    fontSize: "14px",
    margin: "5px 5px 5px 0",
    fontFamily: 'Montserrat'
  },
  addPriceHeader: {
    fontFamily: 'Montserrat',
    color: '#1a1a1a',
    fontWeight: 600,
    marginBottom: '15px',
    fontSize: '24px',
    "@media (max-width: 768px)": {
      fontFamily: 'Montserrat',
      color: colors().viewTextColor,
      fontWeight:600,
      fontSize: '19px !important',
    }
  },
  formControl: {
    width: '100%',
    border: '1px solid #ECECEC',
    borderRadius: '8px',
    marginTop: '10px'
  }
};
const InputFieldStyle = styled(InputField)({
  "@media only screen and (max-width: 1024px)": {
  maxHeight: '44px !important',
  }
})

const AddModel = styled('span')({
  '& .addPriceHeader': {
    fontFamily: 'Montserrat',
    color: colors().viewTextColor,
  },

  "@media only screen and (max-width: 1024px)": {
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding:'6px',
        },
    '& .addPriceHeader': {
      fontFamily: 'Montserrat',
      color: colors().viewTextColor,
      fontWeight: 600,
      fontSize: '19px!important',
    },

    '& .fieldLabel': {
      color:colors().charcoal,
      fontSize: '13px !important',
      fontWeight:600,
    },

    '& .modalStyle':{
      maxWidth:'600px',
    },
    '& .MuiOutlinedInput-root': {
      maxHeight: '46px !important',
    },
    '& .cancelBtn': {
      fontSize: '16px',
      height: '44px !important'
    }
  }
});
export default AddPriceListModal;
// Customizable Area End



