// Customizable Area Start
import React from "react";
import AddProductSectionController, { Props } from "./AddProductSectionController"
import "./AddProduct.css"
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Snackbar, Checkbox ,styled} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { openCircle, filledCircle } from "./assets";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";
import AutocompleteSelect from "../../../components/src/AutocompleteSelect.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

class AddProductSection extends AddProductSectionController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
      // Customizable Area Start
        const { selectedOption, selectedIcon, isOpen, isChecked, showError, showErrorRes, showPreferenceError, severity, preferenceListData, section_first_name, section_second_name} = this.state;
        const showErrorClass = showErrorRes && 'error'
        const sectionFirst = showError && section_first_name === '' || showErrorClass;
        const dropdownClass = showPreferenceError && selectedOption === null && 'error';
        return (
            <>
                  <AddProductWrapper>
                <div className="product-container">
                    <CustomLoader loading={this.state.isLoading}/>
                    <Snackbar data-test-id="snackbar" anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }} open={this.state.openPopUp} autoHideDuration={4000} onClose={this.handleClosePopUp} >
                        <Alert data-test-id="alert-snackbar" severity={severity} >
                            {this.state.message}
                        </Alert>
                    </Snackbar>
                    <h2 className="header-title">Add Section</h2>
                    <div className="add-product-container">
                        <div className="left-section">
                            <div >
                                <h4 className="heading-text">Section Name</h4>
                                <input data-test-id="section-first-value" value={section_first_name} onChange={this.handleSectionFirstNameChange} type="text" className={`input-field ${sectionFirst}`} placeholder="Enter Name" />
                                {showError && section_first_name === '' && <p className="error-message">Please enter your first name.</p>}
                                {showErrorRes && <p className="error-message">Name {this.state.errorMessage}</p>}
                            </div>
                            <div className="custom-dropdown">
                                <h4 className="heading-text">Preference</h4>
                                <div
                                    data-test-id="toggle-dropdown"
                                    className={`dropdown-header ${dropdownClass}`}
                                    onClick={this.toggleDropdown}
                                >
                                    <span>
                                        {selectedOption && (
                                            <img
                                                className="image-change-option"
                                                src={selectedOption.attributes.gallery_icon === null ? this.state.defaultIcon : selectedOption.attributes.gallery_icon?.image}
                                                alt={selectedOption.attributes.preference_first_name}
                                            />
                                        )}
                                        {selectedOption ? selectedOption.attributes.preference_first_name : "Select Preference"}
                                    </span>
                                    {isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                </div>
                                {isOpen && (
                                    <ul className="dropdown-options">
                                        {preferenceListData.map((option) => (
                                            <li data-test-id={`dropdown-list-${option.id}`} key={option.id} onClick={() => this.handleOptionSelect(option)}>
                                                <img
                                                    style={{ width: "20px", height: "20px" }}
                                                    src={selectedOption === option ? openCircle : filledCircle}
                                                    alt={option.attributes.preference_first_name}
                                                />
                                                <img className="image-change-option" src={option.attributes.gallery_icon === null ? this.state.defaultIcon : option.attributes.gallery_icon?.image} alt="icon" />

                                                <label>{option.attributes.preference_first_name}</label>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                                {showPreferenceError && <p className="error-message">Please select a preference.</p>}
                            </div>

                            <div style={{paddingBottom: "24px"}}>

                  <FormControl style={{width: "100%"}}>
                    <FormLabel style={{ marginBottom: "8px", color: "black"}}>Icon</FormLabel>
                    <AutocompleteSelect
                        data-test-id='iconDropdown'
                        placeholder="Select icon"
                        fieldName="icon"
                        noOptionText="No icon found"
                        options={this.state.allIcons}
                        value={selectedIcon?.id || ""}
                        disableClear
                        listBoxStyle={{maxHeight: 260}}
                        onChange={(_event: React.ChangeEvent, value: { id: string; option: string; image:string }) => this.selectIcon(value)}
                        handleScroll={this.handleScrollIconDropdown}
                        onBlur={() => this.debouncedFunction(this.state.selectedIcon?.option || "")}
                        debouncedFunction={(getValue: string) => { this.debouncedFunction(getValue) }}
                    />
                  </FormControl>

                </div>

                            <div className="checkbox-container" style={{width: 'fit-content'}}>
                                <Checkbox
                              data-test-id='checkbox-select'
                              onClick={() => {
                                this.handleCheckboxChange()
                              }}
                              checked={
                                isChecked
                              }
                            />
                            Online Order
                            </div>
                        </div>
                        <div className="right-section">
                            <div >
                                <h4 className="heading-text">Section Arabic Name</h4>
                                <input dir="rtl" data-test-id="section-second-name" type="text" value={section_second_name} onChange={this.handleSecondNameChange} className={`input-field`} placeholder="Enter Arabic Name" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="group-btn" style={{ padding: "0 0 32px 0"}}>
                    <button data-test-id="cancel-button" onClick={this.cancelChange} className="cancel">Cancel</button>
                    <button data-test-id="add-button" onClick={this.handleAddClick} className="add">Add</button>
                </div>
              </AddProductWrapper>
            </>
        );
      // Customizable Area End
    }
}

// Customizable Area Start
export default AddProductSection;

const AddProductWrapper = styled("div")({
    "& .header-title": {
        fontSize: '24px',
        fontWeight: 600,
        lineHeight: '29.26px',
        "@media (max-width: 1024px)": {
            fontSize: '16px',
            fontWeight: 600,
            lineHeight:"19.2px",
          },
    },
    "& .add": {
        width: '128px',
        height: '56px',
        padding:"16px",
        "@media (max-width: 1024px)": {
            width: '128px',
            height: '44px',
            padding:"10px 0"
          },
    },
    "& .heading-text": {
        fontWeight: 600,
        fontSize: "16px",
        color: "#334155",
        marginBottom:0,
       "@media (max-width: 1024px)": {
          fontSize: '13px',
        },
    },
    "& .cancel": {
        width: '128px',
        height: '56px',
        padding:"16px",
        "@media (max-width: 1024px)": {
            width: '128px',
            height: '44px',
            padding:"10px 0"
          },
          "@media (max-width: 768px)": {
            width: '80px',
          },
    },
    "& .add-product-container": {
        
    },
    "& .dropdown-header": {
        minWidth:"350px"
    },
    "& .dropdown-options":{
        height:"fit-content !important",
        maxHeight:'300px',
        overflowY:"scroll",
        minWidth:"350px !important"
    },
    "& .MuiAutocomplete-root": {
        width: "50%",
        minWidth: 350,
        "& .MuiAutocomplete-endAdornment": {
            right: 18
        }
    }
})
// Customizable Area End
