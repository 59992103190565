// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
export const configJSON = require("./config");
import { makeApiMessage } from "../../../components/src/common";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import React from "react";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey,
  navigateTo,
  sortCondition,
  CustomEnums,
  getCustomEnumName
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
// Customizable Area End

// Customizable Area Start

export interface ITransactionDetails {
  attributes: {
    transfer_id: string;
    id: number;
    driver_account_id: number;
    transfer_from_type: string;
    transfer_to_id: number;
    account_id: number;
    transfer_from_id: number;
    transfer_to_type: string;
    status: string;
    driver_confirmed_at: string | null;
    driver_status: string;
    created_at: string;
    region_id: number;
    station: string | null;
    opening_cash_date: string | null;
    reason: string;
    created_by: {
      last_name: string;
      id: number;
      full_name: string;
      first_name: string;
    };
    driver: {
      first_name: string;
      last_name: string;
      id: number;
      full_name: string;
      email: string;
    };
    transfer_to: {
      id: number;
      store_name: string;
      store_id: string;
      store_address: string;
      name: string;
    };
    transfer_from: {
      id: number;
      first_name: string;
      last_name: string;
      full_name: string;
      store_name: string;
      store_id: string;
      store_address: string;
    };
    received_by: {
      last_name: string;
      id: number;
      first_name: string;
      full_name: string;
    };
    is_cashier: boolean;
    request_page: string;
    confirm_amount: string;
    variance: string;
    opening_cash: string;
    cash_from_orders: string;
    currency: string;
    amount: string;
    declare_cash: string;
    petty_cash: string;
    cash_in: string;
    cash_out: string;
    invoice_pdf: string | null;
  };
  type: string;
  id: number;
}
interface Account {
  id: number;
  first_name: string;
  last_name: string;
  full_name: string;
  employee_profile_pic: string;
}

interface Attributes {
  id: number;
  account_id: number;
  commentable_id: number;
  commentable_type: string;
  comment: string;
  comment_type:string;
  created_at: string;
  updated_at: string;
  account: Account;
  file: string | null;
}

interface Comment {
  id: string;
  type: string;
  attributes: Attributes;
}
interface ErrorResponse  {
  errors: {
      message: string;
  }[];
};


interface DataResponse {
  data: Comment[];
}

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: {
    getParam: Function;
  };
  id: string;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  transactionDetails: ITransactionDetails | null
  isAmtEnable: boolean
  editConfirmAmt: string;
  activeLogAndNoted: Comment[];
  attachmentFile: File | null | undefined;
  comment: string;
  notesError:{
    comment:string|null;
  }
  commentPage: number;
  responseCommentList: Comment[],
  currentCommentData: Comment[];
  permissionStatus: PermissionStatus;
  // Customizable Area End
}

export interface SS { }

export default class OutGoingTransactionDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTransactionDetailsMessageId: string = "";
  editConfrimAmtMessageId: string = "";
  fileInputRef: React.RefObject<HTMLInputElement>;
  getActiveLogAndNoteMessageId: string = "";
  createNoteMessageId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.LayoutDataMessage),
    ];
    this.state = {
      transactionDetails: null,
      isAmtEnable: false,
      editConfirmAmt: "",
      activeLogAndNoted: [],
      attachmentFile: null,
      comment: "",
      commentPage: 1,
      responseCommentList: [],
      currentCommentData: [],
      notesError:{
        comment:null,
      },
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      }
    };

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.fileInputRef = React.createRef();
    // Customizable Area End
  }

  async receive(_from: string, message: Message) {
    // Customizable Area Start
    this.receiveDataFromLayout(message)
    const requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.handleTransactionDetailsRes(requestId, responseJson);
    this.handleEditConfrimAmtRes(requestId, responseJson)
    this.handleActiveLogAndNoteResponse(requestId, responseJson)
    this.onNoteCreation(requestId, responseJson)
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const paramId = this.props.navigation.getParam("navigationBarTitleText");
    if (paramId) {
      this.getTransactionDetails(paramId);
      this.getActiveLogAndNotes(paramId);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  receiveDataFromLayout = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.LayoutMessageData)
        );
        if (recievedData.userContext) {
          this.handleUserChange(recievedData.userContext)
        }
    }
  }
  
  getTransactionDetails = (paramId: string) => {
    const apiUrl = `${configJSON.transferRequestsListApi}/${paramId}`;
    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: "GET",
    });
    this.getTransactionDetailsMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getActiveLogAndNotes = (paramId: string) => {
    const logAndNoteApiUrl = `${configJSON.getActiveLogAndNotesEndpoint}?commentable_id=${paramId}&commentable_type=BxBlockCfcashier::TransferRequest&page=${this.state.commentPage}&per=10`
    const requestMessage = makeApiMessage({
      url: logAndNoteApiUrl,
      method: "GET",
    });
    this.getActiveLogAndNoteMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onNoteCreation = (requestId: string,
    response: DataResponse) => {
    if (requestId === this.createNoteMessageId) {
      this.setState({
        comment: "",
        commentPage:1,
        activeLogAndNoted:[],
        responseCommentList: [],
        currentCommentData: [],
        attachmentFile: null,
        notesError:{
          comment:null
        }
      });
      const paramId = this.props.navigation.getParam("navigationBarTitleText");
        this.getActiveLogAndNotes(paramId);
    }
  }

  handleActiveLogAndNoteResponse(
    requestId: string,
    response: DataResponse 
  ) {
    if (requestId === this.getActiveLogAndNoteMessageId && response.data) {

      const commentWholeData = [...this.state.responseCommentList, ...response?.data]


      this.setState({
        activeLogAndNoted: commentWholeData,
        responseCommentList: commentWholeData,
        currentCommentData: response.data
      });
    }
  }

  handleValidateNotesForm = () => {
    const error:{notesError?:string,comment?:string} = {}
    if(this.state.comment.length===0){
      error.notesError="Comment is Required"
      this.setState({
        notesError:{
          comment:"Comment is Required"
        }
      })
    }
    if (Object.keys(error).length === 0) {
      return true
    }else {
      return false
    }
  }
  handleCreateNote = () => {    
    const apiUrl = configJSON.creteNotesEndpoint;
    const paramId = this.props.navigation.getParam("navigationBarTitleText");

    const formData = new FormData();
    formData.append('comment[commentable_type]', "BxBlockCfcashier::TransferRequest");
    formData.append('comment[commentable_id]', paramId);
    formData.append('comment[comment]', this.state.comment);
    formData.append('comment[comment_type]','notes');
    if(this.handleValidateNotesForm()){

    const requestMessage = makeApiMessage({
      url: apiUrl,
      method: "POST",
      body: formData,
      isHeader: true
    });

    this.createNoteMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const paramId = this.props.navigation.getParam("navigationBarTitleText");
    const target = event.target as HTMLElement;
    if (target.scrollHeight - target.scrollTop === target.clientHeight && this.state.currentCommentData.length !== 0) {
      this.setState({ commentPage: this.state.commentPage + 1 },
        () => this.getActiveLogAndNotes(paramId)

      )
    }
  };

  imageChangeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const input = event?.target as HTMLInputElement;

    if (!input?.files?.[0]) {
      alert("No image upload.")
      return;
    }

    const reader = new FileReader()
    reader.onloadend = () => {
      this.setState({ attachmentFile: input?.files?.[0] })
      this.setState((prevState)=>({
        notesError:{
          ...prevState.notesError,
          attachmentFile:"success"
        }
      }))
    }
    reader.readAsDataURL(input.files[0])

  }

  handleGetNote = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target
    this.setState({
      comment: value
    })
    if(value.length>0){
      this.setState({
        notesError:{
          comment:null
        }
      })
    }
    else{
      this.setState({
        notesError:{
          comment:"Comment is Required"
        }
      })
    }
  }

  handleTransactionDetailsRes(
    requestId: string,
    response: { data: ITransactionDetails }
  ) {
    if (requestId === this.getTransactionDetailsMessageId) {
      this.setState({
        transactionDetails: response.data,
        editConfirmAmt: response.data?.attributes?.amount
      });
    }
  }

  handleUserChange = (userContext: IUserContext) => {
    const pageType = this.props.navigation.getParam("pageType");
    const apiKey = sortCondition(pageType === "cashier",customPermissionApiKey.cashierPermission,customPermissionApiKey.cashupPermission);
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey as string, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value,
    })
  };

  handleBack = () => {
    const { transactionDetails } = this.state
    const pageType = this.props.navigation.getParam("pageType");
    const details = transactionDetails?.attributes;
    let isCashier = details?.request_page === "cashier";

    if (pageType === "cashier") {
      if (isCashier) {
        navigateTo({
          props: this.props,
          screenName: "OutGoingTransaction",
        });
      } else {
        navigateTo({
          props: this.props,
          screenName: "IncomingTransaction",
        });
      }
    } else {
      if (isCashier) {
        navigateTo({
          id: String("redirected"),
          props: this.props,
          screenName: "StoreKeeperWithTab",
        });
      } else {
        navigateTo({
          props: this.props,
          screenName: "StoreKeeper",
        });
      }
    }
  }

  handleEditAmtEnable = () => {
    this.setState({ isAmtEnable: true })
  }

  handlEditAmt = (type: string) => {
    const details = this.state.transactionDetails?.attributes;
    const pageType = this.props.navigation.getParam("pageType");
    let isCashier = details?.request_page === "cashier";
    const isValid = (pageType === "cashier" && !isCashier) || (pageType === "cashup" && isCashier)
    let body
    if (isValid) {
      body = {
        id: this.state.transactionDetails?.id,
        confirm_amount: this.state.editConfirmAmt,
        status: "completed"
      };
    } else {
      body = {
        amount: this.state.editConfirmAmt,
      };
    }
    const message = makeApiMessage({
      url: isValid ? `${configJSON.editConfirmAmountApi}` : `${configJSON.transferRequestsListApi}/${this.state.transactionDetails?.id}`,
      method: isValid ? "PATCH" : "PUT",
      body: JSON.stringify(body),
    });

    this.editConfrimAmtMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!isNaN(Number(value))) {
      this.setState({
        editConfirmAmt: value,
      });
    }
  };

  handleEditConfrimAmtRes(
    requestId: string,
    response: { status: number; message: string }
  ) {
    if (requestId === this.editConfrimAmtMessageId) {
      const paramId = this.props.navigation.getParam("navigationBarTitleText");
      this.getActiveLogAndNotes(paramId);
      this.setState({ 
        isAmtEnable: false,        
        commentPage:1,
        activeLogAndNoted:[],
        responseCommentList: [],
        currentCommentData: [], },
        () => this.getTransactionDetails(String(this.state.transactionDetails?.id)))
        this.getActiveLogAndNotes(paramId);
    }
  }

  getTitleAsPerRole = () => {
    const Strings = configJSON.TransactionDetails.Strings;
    const details = this.state.transactionDetails?.attributes;
    const pageType = this.props.navigation.getParam("pageType");
    let dataFormType = details?.request_page;
    if (pageType === "cashier") {
      if (dataFormType === "store") {
        return Strings.incomingTitle;
      } else {
        return Strings.outgoingTitle;
      }
    } else {
      if (dataFormType === "cashier") {
        return Strings.incomingPaymentTitle;
      } else {
        return Strings.outgoingCashupTitle;
      }
    }
  };

  handleGetComment = (comment:string) => {
      return comment.split(",")
  }

  handlePrintInvoice = () => {
    const invoice_pdf = this.state.transactionDetails?.attributes?.invoice_pdf
    if (!invoice_pdf) return;
    const printMessage = new Message(getCustomEnumName(CustomEnums.CustomActionReducers))
    printMessage.addData(getCustomEnumName(CustomEnums.CustomReducerAction), "PRINT_FILE")
    printMessage.addData(getCustomEnumName(CustomEnums.CustomReducerPayload), {
      format: "pdf",
      data: invoice_pdf
    })
    this.send(printMessage)
  }

  printIconClasses = () => "print-icon" + (this.state.transactionDetails?.attributes.invoice_pdf ? "" : "print-disabled ")
  // Customizable Area End
}
