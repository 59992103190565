// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableContainer,
  styled,
  TablePagination,
  Popover,
  Typography
} from "@material-ui/core";
import { renderBaseOnConditions, sortStringCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";
import FilterPopover from "../../../components/src/FilterPopover";
import SortingTableCell from "../../../components/src/SortingTableCell";
import SortingTableHeader from "../../../components/src/SortingTableHeader2";
import ExportImportPopover from "../../../components/src/customComponents/ExportImportButton.web";
import {
  FilterList as FilterIcon,
  MoreVert as MoreVertIcon,
  ExpandLess,
  ExpandMore,
} from "@material-ui/icons";
import B2BCustomerListController, {
  IB2BCustomerData,
  Props
} from "./B2BCustomerListController.web";
import { colors } from "../../utilities/src/Colors";
import { TableRowsSkeleton } from "../../../components/src/customComponents/TableRowsSkeleton.web";
import { LoadingComponent } from "../../../components/src/Loader.web";
import ConfirmationModal from "../../../components/src/customComponents/ConfirmationModal.web";
import { CustomSnackbar } from "../../../components/src/customComponents/CustomSnackbar.web";

// Customizable Area End

export class B2BCustomerList extends B2BCustomerListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderTableHeader() {
    return (
      <TableRow className={"customerTableRow"}>
        <SortingTableHeader
          data-test-id='nameSortingHeader'
          sortingData={this.state.sortingData}
          title={"Name"}
          sortingKey='name'
          {...this.sortingProps}
          type='left'
        />
        <SortingTableHeader
          data-test-id='emailSortingHeader'
          sortingData={this.state.sortingData}
          title={"Email ID"}
          sortingKey='email'
          {...this.sortingProps}
          type='middle'
        />
        <SortingTableHeader
          data-test-id='mobileSortingHeader'
          sortingData={this.state.sortingData}
          title={"Mobile No."}
          sortingKey='phone'
          {...this.sortingProps}
          type='middle'
        />
        <SortingTableHeader
          data-test-id='companySortingHeader'
          sortingData={this.state.sortingData}
          title={"Company"}
          sortingKey='company'
          {...this.sortingProps}
          type='middle'
        />
        <SortingTableHeader
          data-test-id='citySortingHeader'
          sortingData={this.state.sortingData}
          title={"City"}
          sortingKey='city'
          {...this.sortingProps}
        />
        <SortingTableHeader
          data-test-id='departmentSortingHeader'
          sortingData={this.state.sortingData}
          title={"Department Name"}
          sortingKey='department_name'
          {...this.sortingProps}
        />
         <SortingTableHeader
          sortingData={this.state.sortingData}
          title=''
          type='action'
          sortingKey=''
          {...this.sortingProps}
          sortingDisabled
        />
      </TableRow>
    );
  }

  renderPagination = (customerList: IB2BCustomerData[]) => {
    if (customerList.length > 0) {
     return <TablePagination
        data-test-id='customerListPagination'
        rowsPerPageOptions={[10]}
        component='div'
        count={this.state.customerListPagination?.total_count}
        rowsPerPage={this.state.pageSize}
        page={this.state.page}
        onPageChange={(event, page) => { this.handlePageChange(page) }}
      />
    }
  }

  getPopupOverMenu() {
    const {
      popOverTop,
      popOverLeft,
      popOverOpened,
      rowData,
      permissionStatus
    } = this.state;
    if(this.state.popOverOpened) {
      return (
        <StylePopover
          data-test-id="popoverTest"
          style={{ boxShadow: "none"}}
          anchorReference='anchorPosition'
          open={popOverOpened}
          onClose={() => { this.handlePopoverMenuClose() }}
          anchorPosition={{
            top: popOverTop + 40,
            left: popOverLeft - 80,
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {renderBaseOnConditions(
            permissionStatus.viewPermission ||
              permissionStatus.editPermission ||
              permissionStatus.activatePermission ||
              permissionStatus.deactivatePermission,
            <div className='popoverContainer'>
              {renderBaseOnConditions(
                permissionStatus.viewPermission,
                <Box
                  data-test-id='viewButton'
                  className='popoverButton'
                  onClick={() => {
                    this.handleViewCustomerDetails(rowData.id);
                  }}
                >
                  View
                </Box>,
                <></>
              )}
              {renderBaseOnConditions(
                permissionStatus.editPermission,
                <Box
                  data-test-id='editButton'
                  className='popoverButton'
                  onClick={() => {
                    this.handleEditCustomerDetails(rowData.id);
                  }}
                >
                  Edit
                </Box>,
                <></>
              )}
              {renderBaseOnConditions(
                permissionStatus.deactivatePermission &&
                  rowData.attributes.activated,
                <Box
                  className={"popoverButton"}
                  data-test-id={"deactivate-menu"}
                  onClick={() => this.handleActivateDeactivate()}
                >
                  {"Deactivate"}
                </Box>,
                <></>
              )}
              {renderBaseOnConditions(
                (permissionStatus.activatePermission as boolean) &&
                  !rowData.attributes.activated,
                <Box
                  className={"popoverButton"}
                  data-test-id={"activate-menu"}
                  onClick={() => this.handleActivateDeactivate()}
                >
                  {"Activate"}
                </Box>,
                <></>
              )}
            </div>,
            <div className='popoverContainer'>
              <Typography className={"noPermissions"}>
                No Permissions
              </Typography>
            </div>
          )}
        </StylePopover>
      );
    }
  }

  renderDeactivateModal = () => {
    const { rowData, openDeactiveModel } = this.state;

    return (
      <ConfirmationModal
        data-test-id={"deactiveModel"}
        open={openDeactiveModel}
        handleClose={this.handleCloseConfirmModal}
        handleConfirm={this.handleDeactivate}
        title={"Confirm Deactivate"}
        message={{
          id: rowData.id,
          title: rowData.attributes.name,
        }}
        confirmText={"Deactivate"}
        dynamic={true}
      />
    );
  };

  renderConfirmDeactivateModal = () => {
    const { rowData, confirmDeactiveModalOpen } = this.state;

    return (
      <ConfirmationModal
        data-test-id={"confirmDeactiveModel"}
        open={confirmDeactiveModalOpen}
        handleClose={this.handleCloseConfirmModal}
        handleConfirm={this.handleGoBackToListing}
        title={"Customer Deactivated"}
        message={{
          id: rowData.id,
          title: rowData.attributes.name,
        }}
        confirmText={"Back to Listing"}
        displayItem={true}
      />
    );
  };

  renderCustomSnackBar = () => {
    const { snackBarOpen, snackBarMessage, severity } = this.state;

    return (
      <CustomSnackbar
        data-test-id={"custom-snackbar"}
        open={snackBarOpen}
        onClose={() => this.handleSnackbarClose()}
        autoHideDuration={1000}
        errorMessage={snackBarMessage}
        severity={severity}
        hideIcon={true}
      />
    );
  };

  render() {
    const {
      isLoadingPermission,
      permissionStatus,
      openDeactiveModel,
      confirmDeactiveModalOpen,
      snackBarOpen
    } = this.state;
    const openCustomerListPopoverAction = Boolean(
      this.state.openCustomerListAction
    );
    const popOverId = openCustomerListPopoverAction
      ? "simple-popover"
      : undefined;
    return (
      <>
        <MainStyleWrapper>
          <Box>
            <Box>
              <div className={"headerWrapper"}>
                <div className={"pageHeader"}>Customers</div>
                <div className={"headerButtonPartAccount"}>
                  <div
                    data-test-id='filterIcon'
                    onClick={(event) => {
                      this.handleFilterClose(event);
                    }}
                    className={sortStringCondition(
                      this.state.isAppliedFilter,
                      "filterButtonAccount active-border",
                      "filterButtonAccount"
                    )}
                  >
                    <FilterIcon color={this.handleReturnColorType()} />
                    {renderBaseOnConditions(
                  this.state.isAppliedFilter,
                  <div className="filterActiveIcon"></div>,
                  <></>
                )}
                  </div>
                  <FilterPopover
                    data-test-id='filterPopover'
                    onClose={() => {
                      this.handleCloseFilterPopover();
                    }}
                    onAutoCompleteChange={this.handleB2BFilterAutocompleteChange}
                    onClearFilter={this.handleClearFilter}
                    anchor={this.state.filterAnchor}
                    onFilterChange={this.handleFilterChangeAccount}
                    filters={this.state.filters}
                  />
                  <LoadingComponent
                    isLoading={isLoadingPermission}
                    customstyle={{ marginRight: "8px" }}
                    height={56}
                    width={184}
                    isAllowed={permissionStatus.exportPermission as boolean}
                    children={
                      <StyledBox component={"div"}>
                        <Button
                          data-test-id={`import-export-btn`}
                          id={"IconButton"}
                          aria-describedby={popOverId}
                          onClick={(event) => {
                            this.setState({
                              openCustomerListAction: event.currentTarget,
                            });
                          }}
                          className={"addButton"}
                        >
                          Export/Import
                          {this.state.openCustomerListAction ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </Button>
                      </StyledBox>
                    }
                  />
                  <ExportImportPopover
                    popOverId={popOverId}
                    openAction={openCustomerListPopoverAction}
                    popoverOpen={this.state.openCustomerListAction}
                    popoverClose={() => {
                      this.handleCustomerListCloseActionList();
                    }}
                    exportTemplateButtonClick={() =>
                      this.handleCustomerListExportTemplate()
                    }
                    importCsvButtonClick={() =>
                      this.handleCustomerListOpenImportModal()
                    }
                    exportCsvButtonClick={() =>
                      this.handleCustomerListExportCsv()
                    }
                  />
                  <LoadingComponent
                    isLoading={isLoadingPermission}
                    height={56}
                    width={184}
                    isAllowed={permissionStatus.createPermission}
                    children={
                      <Button
                        data-test-id='add-customer'
                        onClick={() => this.handleNavigateAddCustomer()}
                        className={"addButton"}
                      >
                        Add Customer
                      </Button>
                    }
                  />
                </div>
              </div>
            </Box>
            <Box>
              <TableContainer component={Paper}>
                <Table className={"table"} aria-label='customized table'>
                  <TableHead>{this.renderTableHeader()}</TableHead>
                  {this.state.isLoading ? (
                    <>{Array(5)
                      .fill(1)
                      .map((_, index) => (
                        <TableBody key={index} data-test-id='tableData'>
                          <TableRow>
                          {[...Array(6)].map((_, cellIndex) => (
                             <TableRowsSkeleton key={cellIndex} />
                            ))}   
                          </TableRow>
                        </TableBody>
                      ))}</>
                  ) : (
                    <TableBody data-test-id='tableData'>
                      {this.state.customerList.length > 0 && (
                        <>
                          {this.state.customerList.map(
                            (item: IB2BCustomerData, index: number) => {
                              return (
                                <TableRow key={index + "TableRow"}>
                                  <SortingTableCell
                                    width='22vw'
                                    type='left'
                                    content={item.attributes.name}
                                    width1024='100px'
                                    style={{ opacity: this.checkOpacity(item.attributes.activated) }}
                                  />
                                  <SortingTableCell
                                    width='22vw'
                                    type='middle'
                                    width1024='150px'
                                    content={item.attributes.email}
                                    style={{ opacity: this.checkOpacity(item.attributes.activated) }}
                                  />
                                  <SortingTableCell
                                    width='22vw'
                                    type='middle'
                                    width1024='150px'
                                    content={item.attributes.full_phone_number}
                                    style={{ opacity: this.checkOpacity(item.attributes.activated) }}
                                  />
                                  <SortingTableCell
                                    width='22vw'
                                    type='middle'
                                    width1024='150px'
                                    content={item.attributes.company.name}
                                    style={{ opacity: this.checkOpacity(item.attributes.activated) }}
                                  />
                                  <SortingTableCell
                                    width='22vw'
                                    type='middle'
                                    width1024='150px'
                                    content={item.attributes.city ? item.attributes.city.name_en : "--"}
                                    style={{ opacity: this.checkOpacity(item.attributes.activated) }}
                                  />
                                  <SortingTableCell
                                    width='22vw'
                                    type='right'
                                    width1024='100px'
                                    content={item.attributes.department_name}
                                    style={{ opacity: this.checkOpacity(item.attributes.activated) }}
                                  />
                                  <SortingTableCell
                                    data-test-id={`threeDotIcon_${index}`}
                                    width='22vw'
                                    width1024='100px'
                                    type='action'
                                    content={
                                      <IconButton
                                        data-test-id={`moreButton_${index}`}
                                        id={"IconButton" + index}
                                        onClick={(event) => {
                                          const position = event.currentTarget.getBoundingClientRect();
                                          this.handleMoreMenu(item, position)
                                        }}
                                      >
                                        <MoreVertIcon />
                                      </IconButton>
                                    }
                                  />
                                </TableRow>
                              );
                            }
                          )}
                        </>
                      )}
                    </TableBody>
                  )}
                </Table>

                {this.state.customerList.length === 0 && !this.state.isLoading &&
                  !this.state.isLoading && (
                    <div className={`customerAddButton noDataCell`}>
                      No Record Found
                    </div>
                  )}
              </TableContainer>
            </Box>
            <Box>
              {this.renderPagination(this.state.customerList)}
          </Box>
          </Box>
          {this.getPopupOverMenu()}
          {openDeactiveModel && this.renderDeactivateModal()}
          {confirmDeactiveModalOpen && this.renderConfirmDeactivateModal()}
          {snackBarOpen && this.renderCustomSnackBar()}
        </MainStyleWrapper>
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start

const StyledBox = styled(Box)({
  margin: "0 10px",
});

const PopoverContainer1 = styled("div")({
  borderRadius: "8px",
  fontFamily: "Montserrat",
  fontWeight: 500,
  fontSize: "12px",
  "& .noPermissions": {
    padding: "0 8px",
  },
});

const MainStyleWrapper = styled(Box)({
  fontFamily: "Montserrat",
  "& .filterButtonAccount": {
    position: "relative",
    marginRight: "24px",
    cursor: "pointer",
    background: "#FFFFFF",
    height: "56px",
    color: "#4d4d4d",
    width: "56px",
    minWidth: "56px",
    fontSize: "24px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #ECECEC",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
      width: "44px",
      minWidth: "44px",
      height: "44px",
      padding: "9px",
    },
  },
  "& .active-border": {
    border: "1px solid #204B9C",
  },
  "& .filterActiveIcon": {
    position: "absolute",
    top: "-5px",
    right: "-5px",
    minWidth: "15px",
    background: "#204B9C",
    minHeight: "15px",
    borderRadius: "50%",
  },
  "& .headerWrapper": {
    display: "flex",
    flexWrap: "wrap" as "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 32,
    marginTop: 25,
    flexwrap: "wrap" as "wrap",
  },
  "& .pageHeader": {
    fontSize: "24px",
    fontWeight: 600,
    fontStyle: "normal",
    lineHeight: "29px",
    color: colors().black,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
      fontWeight: 600,
      color: colors().black,
    },
  },
  "& .headerButtonPartAccount": {
    display: "flex",
  },
  "& .table": {
    minWidth: 700,
  },
  "& .deactivate": {
    opacity: 0.7
  },
  "& .loadercell": {
    textAlign: "center",
  },
  "& .loadercir": {
    width: "44px",
    height: "none",
  },
  "& .customerAddButton": {
    textAlign: "center",
  },
  "& .noDataCell": {
    fontWeight: 600,
    fontSize: "16px",
    width: "98%",
    paddingTop: "20px",
    paddingBottom: "20px",
    border: "1px solid #ECECEC",
    margin: "12px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    borderRadius: "8px",
  },
  "& .customerTableRow": {
    background: "#204B9C",
    color: "#FFFFFF",
  },
  "& .addButton": {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    background: "#204B9C",
    color: "#FFFFFF",
    width: "184px",
    height: "56px",
    borderRadius: "8px",
    textTransform: "unset" as "unset",
    "&:hover": {
      background: "#204B9C",
      color: "#FFFFFF",
    },
    "@media only screen and (max-width: 1024px)": {
      fontSize: "13px",
      width: "160px",
      height: "44px",
      padding: "10px 20px",
    },
  },
});

const StylePopover = styled(Popover)({
  "& .popoverButton": {
    width: "118px",
    height: "33px",
    paddingTop: "8px",
    paddingLeft: "24px",
    backgroundColor: "#FFFFFF",
    color: "#4D4D4D",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#204B9C",
      color: "#FFFFFF",
    },
  },
  "& .popoverContainer": {
    paddingTop: "16px",
    paddingBottom: "16px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    borderRadius: "8px",
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: "Montserrat",
  },
  "& .noPermissions": {
    padding: "0 8px",
    fontSize: "15px",
    fontWeight: 500,
    fontFamily: "Montserrat",
  },
})

export default B2BCustomerList;
// Customizable Area End
