// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ISortingData } from "../../../components/src/OrderTable/src/TableCell";
import StorageProvider from "../../../framework/src/StorageProvider";
import { apiCall,handleLogout } from "../../../components/src/common";
import {
  navigateTo,
} from "../../../blocks/utilities/src/CustomBlockHelpers";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");

interface IMeta {
  next_page: number;
  pervious_page: number|null;
  total_pages: number;
  total_count: number;
  current_page: number;
}

interface Section {
  id: number;
  name: string;
}

export interface Service {
  id: number;
  price: string;
  sqm: number | null;
  sqm_price: string;
  weight: number | null;
  weight_price: string;
  name: string;
}

interface CatalogueVariant {
  id: number;
  price_list_id: number;
  active: boolean;
  measurement_type: string[];
}

interface ProductType {
  id: number;
  value: string;
}

interface Attributes {
  id: number;
  product_name: string;
  product_second_name: string;
  order_number: number;
  created_at: string;
  request_type: string;
  approx_weight: number;
  sections: Section[];
  services: Service[];
  name: string;
  catalogue_variants: CatalogueVariant[];
  product_type: ProductType;
}

export interface Product {
  id: string;
  type: string;
  attributes: Attributes;
}

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: {
    navigate: Function
  };
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isAppliedFilter: boolean;
  sortingData: ISortingData;
  openActionPopover: (EventTarget & HTMLButtonElement) | null;
  page: number;
  selectedProductId: string;
  rowPerPage: number;
  meta: IMeta;
  isLoadingPermission: boolean;
  openImportExportPopover: (EventTarget & HTMLButtonElement) | null;
  isLoading: boolean;
  dragItem: Product[];
  productListPagination: IMeta
  isDragableDisabled: boolean;
  isB2bActive: boolean;
  pageSize: number;
  errorSnackbarOpen: boolean;
  snakcbarSeverity: "error" | "warning" | "info" | "success";
  errorMessage: string;
  query: string;
  searchQuery: string
  sectionPopupId: number | string;
  sectionPopup: boolean;
  servicePopup: boolean;
  servicePopupId: number | string;
  selectedPriceList: string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class B2bMasterProductController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  authToken: string = ""
  getB2BProductListApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.AccountTypeInfoMessage),
      getName(MessageEnum.SearchTextMessage)
    ];
    // Customizable Area End

    // Customizable Area Start
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      isB2bActive: false,
      isDragableDisabled: false,
      dragItem: [],
      query: "",
      selectedProductId: "",
      isLoading: false,
      isAppliedFilter: false,
      openActionPopover: null,
      sortingData: {
        name: "",
        service: "",
        sections: "",
        approx_weight: "",
        type: "",
      },
      page: 0,
      rowPerPage: 10,
      meta: {
        next_page: 1,
        pervious_page: 1,
        total_pages: 1,
        total_count: 1,
        current_page: 1,
      },
      isLoadingPermission: true,
      openImportExportPopover: null,
      pageSize: 10,
      errorSnackbarOpen: false,
      snakcbarSeverity: 'error',
      errorMessage: '',
      productListPagination: {
        "total_pages": 0,
        "total_count": 0,
        "current_page": 0,
        "next_page": 0,
        "pervious_page": null
      },
      searchQuery:"",
      sectionPopupId: 0,
      sectionPopup: false,
      servicePopup: false,
      servicePopupId: 0,
      selectedPriceList: ""
    };
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const authToken = await StorageProvider.get("token");
    if (typeof (authToken) === "string") {
      this.authToken = authToken;
    }
    this.getB2bProductList()
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.receiveHeaerSearchText(message)
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    this.getProductListApiCallResponse(apiRequestCallId, responseJson);
    // Customizable Area End
  }

  // Customizable Area Start
  receiveHeaerSearchText = (message: Message) => {
    if (message.id === getName(MessageEnum.SearchTextMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.SearchMessageText)
        );
        if (recievedData) {
          this.onChangeValue(recievedData.searchText)
        }
    }
  }

  onChangeValue = (value: string) => {
    this.setState({searchQuery: value,page: 0},()=>this.getB2bProductList())
  }

  getSortingProps() {
    return {
      sortingData: this.state.sortingData,
      onQueryChange: (query: string) => this.handleQueryChange(query),
      onChange: (sortingData: ISortingData) => this.setState({ sortingData }),
    };
  }

  handleQueryChange = (query: string) => {
    this.setState({ query }, () => this.getB2bProductList());
  };


  handleReturnColorType = () => {
    const { isAppliedFilter } = this.state;
    return isAppliedFilter ? "primary" : "inherit";
  };

  handleCloseActionPopver = () => {
    this.setState({ openActionPopover: null });
  };

  handleOpenActionPopver = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    productId: string,
    priceListId: string | number
  ) => {
    this.setState({
      openActionPopover: event.currentTarget,
      selectedProductId: productId,
      selectedPriceList: String(priceListId)
    });
  };

  openImportExportPopover = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    this.setState({
      openImportExportPopover: event.currentTarget,
    });
  };

  handleCloseImportExportPopver = () => {
    this.setState({ openImportExportPopover: null });
  };

  onDragEnd = () => {};

  checkDisabled(isActive: boolean) {
    if (isActive !== false) {
      return 1;
    } else {
      return 0.6;
    }
  }

  checkGetResponse(responseJson: { errors?: { message: string } }) {
    if (responseJson && !responseJson.errors) {
      return true
    }
    else {
      handleLogout(this.props.navigation, responseJson && responseJson.errors);
      return false
    }
  }

  getB2bProductList = () => {
    this.setState({ isLoading: true });

    let headers = {
      "Content-type": "application/json", 
      token: this.authToken
    }

    let url;
 
    url = configJSON.getB2bMasterProductListingEndPoint +
      (`&page_no=${this.state.page + 1}&per_page=${this.state.pageSize}`) +
      (this.state.query ? `${this.state.query}` : '') +
      (this.state.searchQuery ? `&filter_by[query]=${this.state.searchQuery}` : '');

    const getAccount = apiCall({
      header: headers,
      httpBody: {},
      url: url,
      httpMethod: configJSON.validationApiMethodType,
    });

    this.getB2BProductListApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getProductListApiCallResponse = (apiRequestCallId: string, responseJson: { data: Product[], status: number,meta: IMeta, message?: string, errors?: { message: string } }) => {
    if (apiRequestCallId === this.getB2BProductListApiCallId) {
      if(responseJson?.status == 500) {
        this.setState({
          isLoading: false,
          errorSnackbarOpen: true,
          errorMessage: "Internal server error",
          snakcbarSeverity: 'error',
          isLoadingPermission: false 
        })
      } 
      else {
      if (this.checkGetResponse(responseJson) && !responseJson.message) {
        this.setState({isLoadingPermission: false, isLoading: false, dragItem: responseJson.data , productListPagination: responseJson.meta});
      } else {
        this.setState({isLoadingPermission: false, isLoading: false })
      }
      }
    }
  }  

  handleAddB2BProduct = () => {
    navigateTo({
      props: this.props,
      screenName: "CfAddProduct",
    })
  }

  handleEditProductMaster = () => {
    this.props.navigation.navigate("CfEditProduct", { productId: this.state.selectedProductId })
  }

  handleViewProductMaster = () => {
    localStorage.setItem("selectedPricelistId", this.state.selectedPriceList);
    this.props.navigation.navigate("B2bMasterProductView", {productId: this.state.selectedProductId})
  }

  handleSectionPopupOpen = (checkid: string | number) => {
    if(this.state.sectionPopupId === checkid) {
      this.setState({sectionPopup: true})
    }
  }

  handleSectionPopupClose = () => {
    this.setState({sectionPopup: false})
  }

  handlePageChange = (page: number) => {
    this.setState({ page }, () => { this.getB2bProductList() })
  }

  generateStrSARMeasue = (service: any,measurementTypes: any) => {
    const strings = []
    let str = "";
    if (measurementTypes.includes("SQM")) {
      str += `SAR ${parseFloat(service.sqm_price)} - ${parseFloat(service.sqm)} SQM, `;
    }
    if (measurementTypes.includes("Weight")) {
      str += `SAR ${parseFloat(service.weight_price)} - ${parseFloat(service.weight)} Kg, `;
    }
    if (measurementTypes.includes("Quantity")) {
      str += `SAR ${parseFloat(service.price)} - 1 QTY, `;
    }
    str = str.slice(0, -2);
    strings.push(str);
    return strings;
  }

  hoverToGetRowId = (getRowId: string | number) => {
    this.setState({ sectionPopupId: getRowId })
  }

  removeRowId = (getRemoveId: string | number) => {
    this.setState({ sectionPopupId: getRemoveId })
  }

  // Customizable Area End
}
