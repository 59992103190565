import React from "react";
// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { apiCall, handleLogout, updateMultiSelectData } from "../../../components/src/common";
import { IProductItems } from "./PriceListViewController.web";
import { debounce, toString, uniqBy, concat } from "lodash";
export const configJSON = require("./config");
// Customizable Area End


// Customizable Area Start
export interface DropdownOption {
  id: string,
  type: string,
  attributes: {
    id: 35,
    name: string,
    price_list_type: string,
    is_master_price_list: boolean
  }
}
export interface IFormValue {
  added_by: string,
  name: string,
  priceListType: string,
  price_list_to_copy: string | number,
  product: string[],
  product_currency_id: string,
  section: number[],
  taxType: number | string
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  classes: any;
  open: boolean;
  onClose: () => void;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  addPriceInitialvalues: {
    name: string,
    price_list_to_copy: string | number,
    priceListType: string,
    taxType: number | string,
    section: number[],
    product: string[],
    product_currency_id: string,
    added_by: string,
  };
  isLoading: boolean;
  isSaveLoading: boolean;
  accountError: string;
  selectedPriceListToCopy: {id: number, option: string} | null;
  dropdownOptions: {
    priceListToCopy: {id: number, option: string}[],
    productsList: [],
    currency: {id: string, option: string}[],
    priceListType: {
      id: number, 
      option: string,
      value: string
    }[],
    taxType: {
      id: number, 
      option: string,
      value: number
    }[],
    section: [],
    preference: [],
    productNameOptionList: [],
  };
  sectionSelectedData: {id: number, option: string}[];
  isSectionSelectAll: boolean;
  productSelectedData: [];
  isSelectAll: boolean;
  addPriceModal: boolean;
  mobileConfirmModal: boolean;
  forceCreateMobilePriceList: boolean;
  openPriceListSnackbar: boolean;
  mobileDeactiveMsg: string;
  selectedPriceListType: string | number;
  selectedTaxType: string | number;
  priceListMinusValueError: boolean;
  priceListMinusValueMessage: string;
  pricelistDropPage: number;
  autoCopmpleteValue: string;
  selectPricelistProductForAdd: { id: string | number, option: string }[];
  addPricelistSelectedOptionForList: { id: string | number, option: string }[];
  pricelistProductList: IProductItems[];
  isSelectedAll: boolean;
  productNextPage: number;
  currentProductData: IProductItems[];
  searchQuery: boolean;
  selectedProductWhileEdit: {id: string | number, option: string}[];
  selectedSections: { id: string | number, option: string }[];
  selectedSectionsForList: { id: string | number, option: string }[];
  sectionOptions: { id: number, label: string, option: string, active?: boolean }[];
  sectionAutoCopmpleteValue: string;
  sectionNextPage: number;
  // Customizable Area End
}

export interface SS {}

export default class AddPriceListModalController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  itemsPerPage = 10;
  disableLoadMorePriceList = false;
  getPriceListApiCallId: string = "";
  addPriceListOptionApiCallId: string = "";
  getSectionListAsPerPriceListApiCallId: string = "";
  getProductListOptionApiCallId: string = "";
  checkMobilePriceListID: string = "";
  authToken: string = "";
  disableLoadMoreSection = false;

  state: S = {
    isLoading: false,
    isSaveLoading: false,
    addPriceInitialvalues: {
      name: '',
      price_list_to_copy: '',
      priceListType: '',
      taxType: '',
      section: [],
      product: [],
      product_currency_id: "SAR",
      added_by: '',
    },
    accountError: '',
    selectedPriceListToCopy: null,
    dropdownOptions: {
      priceListToCopy: [],
      productsList: [],
      currency: [
        {id: 'SAR', option: 'SAR'},
        {id: '%', option: '%'},
      ],
      priceListType: [
        {
          id: 1, 
          option: 'Store',
          value: "Store"
        },
        {
          id: 2, 
          option: 'Mobile',
          value: "Mobile"
        },
      ],
      taxType: [
        {
          id: 1, 
          option: 'Include in Product Price', 
          value: 1
        },
        {
          id: 2, 
          option: 'On Top of Product Price', 
          value: 0
        },
      ],
      section: [],
      preference: [],
      productNameOptionList: [],
    },
    sectionSelectedData: [],
    productSelectedData: [],
    isSectionSelectAll: false,
    isSelectAll: false,
    addPriceModal: false,
    mobileConfirmModal: false,
    openPriceListSnackbar: false,
    forceCreateMobilePriceList: false,
    mobileDeactiveMsg: '',
    selectedPriceListType: '',
    selectedTaxType: '',
    priceListMinusValueError: false,
    priceListMinusValueMessage: '',
    pricelistDropPage: 1,
    autoCopmpleteValue: '',
    selectPricelistProductForAdd: [],
    addPricelistSelectedOptionForList: [],
    pricelistProductList: [],
    isSelectedAll: false,
    productNextPage: 1,
    currentProductData: [],
    searchQuery: false,
    selectedProductWhileEdit: [],
    selectedSections: [],
    selectedSectionsForList: [],
    sectionOptions: [],
    sectionAutoCopmpleteValue:"",
    sectionNextPage: 1,
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    const requestId: any = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const successResponse: any = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch(requestId) {
      case this.addPriceListOptionApiCallId:
        this.addPriceListOptionApiCallResponse(successResponse);
        break;
      case this.getSectionListAsPerPriceListApiCallId:
        this.getSectionListOptionApiCallResponse(successResponse);
        break;
      case this.getProductListOptionApiCallId:
        this.getProductListOptionApiCallResponse(successResponse);
        break;
      case this.getPriceListApiCallId:
        this.getPricelistApiCallResponse(successResponse);
        break;
      case this.checkMobilePriceListID:
        this.getCheckMobilePriceListApiResponse(successResponse);
        break;
    }

    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const authToken = localStorage.getItem("token");
    if (typeof (authToken) === "string") {
      this.authToken = authToken;
    }
    // Customizable Area End
  }

  // Customizable Area Start
  checkGetResponse(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      return true
    }
    else {
      handleLogout(this.props.navigation, responseJson?.errors);
      return false
    }
  }

  handlePriceModalClose = () => {
    this.setState({
      isSelectedAll: false,
      productSelectedData: [], 
      sectionSelectedData: [], 
      selectedPriceListToCopy: null,
      priceListMinusValueError: false,
      pricelistDropPage: 1,
      priceListMinusValueMessage: "",
      selectedTaxType: '', 
      selectedPriceListType: '',
      dropdownOptions: {
        ...this.state.dropdownOptions,
        priceListToCopy: []
      },
      addPriceInitialvalues: {
        name: '',
        price_list_to_copy: '',
        priceListType: '',
        taxType: '',
        section: [],
        product: [],
        product_currency_id: "SAR",
        added_by: '',
      },
      selectedSections: [],
      selectPricelistProductForAdd: [],
      selectedSectionsForList: [],
      addPricelistSelectedOptionForList: []
    },() => {
      this.getPriceList();
      this.props.onClose();
    });
  }

  getPriceList() {
    const { autoCopmpleteValue } = this.state;
    const page_no = this.state.pricelistDropPage;
    let header = {
      token: this.authToken
    }
    const searchQuery = autoCopmpleteValue ? `&filter_by[name]=${this.state.autoCopmpleteValue}` : '';
    let apiUrl = 
    `${configJSON.getPriceListApiEndPoint}?price_list_type=${this.state.selectedPriceListType}&page_no=${page_no}&per_page=${this.itemsPerPage}&price_list_to_copy_dropdown=true&dropdown=true`+ searchQuery;

    const getAccount = apiCall({
      httpBody: {},
      header: header,
      url: apiUrl,
      httpMethod: configJSON.getPriceListApiMethod,
    });
    this.disableLoadMorePriceList = true;
    this.getPriceListApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getPricelistApiCallResponse(responseJson: { data: Array<DropdownOption>}) {
    if (this.checkGetResponse(responseJson)) {
      const array = (responseJson?.data || []).map((item: DropdownOption) => {
        return {
          id: item.attributes.id,
          option: item.attributes.name
        }
      });
      this.disableLoadMorePriceList = array.length < this.itemsPerPage;
      this.setState((previous) => {
        let newPriceListToCopy = previous.pricelistDropPage === 1 ? array : concat(previous.dropdownOptions.priceListToCopy, array)
        previous.selectedPriceListToCopy && newPriceListToCopy.unshift(previous.selectedPriceListToCopy)
        return {
          dropdownOptions: {
            ...previous.dropdownOptions,
            priceListToCopy: uniqBy(newPriceListToCopy, "id")
          },
        }
      });
    }  
  }

  handleCloseMobileModal = () => {
    this.setState({
      mobileConfirmModal: false,
      selectedPriceListType: '',
    }, () => this.getPriceList())
  }

  confirmDeactiveMobilePriceList = () => {
    this.setState({
      forceCreateMobilePriceList: true,
      selectedPriceListType: "Mobile",
      mobileConfirmModal: false
    });
  }

  checkIsMobilePriceListAvailable = () => {
    let apiUrl = configJSON.checkMobilePriceListEndPoint;
    let header = {
      "Content-type": "application/json",
      token: this.authToken
    }
    const getAccount = apiCall({
      header: header,
      httpBody: {},
      url: apiUrl,
      httpMethod: configJSON.getPriceListApiMethod,
    });

    this.checkMobilePriceListID = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  handleTaxTypeDropdown = (value: number | string) => {
    this.setState({selectedTaxType: value});
  }

  handlePriceListTypeDropdown = (value: string) => {
    this.setState({ 
      selectedPriceListType: value,
      selectedPriceListToCopy: null,
      pricelistDropPage: 1,
      autoCopmpleteValue: "",
      dropdownOptions: {
        ...this.state.dropdownOptions,
        priceListToCopy: []
      },
    }, 
      () => this.getPriceList()
    );
    if(value === "Mobile") {
      this.checkIsMobilePriceListAvailable();
    }
  }

  handleAddNewPriceList(values: IFormValue) {
    this.setState({isSaveLoading:true,isLoading: true});
    let pricelistType;
    if(this.state.forceCreateMobilePriceList) {
      pricelistType = "Mobile"
    } else {
      pricelistType = this.state.selectedPriceListType
    }

    let httpBody = {
      data: {
        attributes: {
          name: values?.name,
          force_create: this.state.forceCreateMobilePriceList,
          price_list_type: pricelistType,
          price_list_to_copy: values?.price_list_to_copy,
          tax_type: this.state.selectedTaxType,
          product_ids: values?.product,
          category_ids: values?.section,
          add_by: values?.added_by || 0,
          product_currency_id: values?.product_currency_id
        }
      }
    }

    let headers:any = {
      "Content-type": "application/json" 
    }

    let apiUrl = `${configJSON.getPriceListApiEndPoint}?token=${this.authToken}`;
    const getAccount = apiCall({
      header: headers,
      httpBody: httpBody,
      url: apiUrl,
      httpMethod: configJSON.addPriceListApiMethod,
    });

    this.addPriceListOptionApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  handleCatergoriesOptions(value: {id: number, option: string} | null) {
    this.setState({
      selectedPriceListToCopy: value, 
      isSectionSelectAll: false, 
      sectionSelectedData: [],
      isSelectAll: false,
      productSelectedData: [],
      selectPricelistProductForAdd: [],
      addPricelistSelectedOptionForList: [],
      pricelistProductList: []
    });
    this.disableLoadMoreSection = true;

    if (!value) return;

    const searchQuery = this.state.sectionAutoCopmpleteValue ? `&filter_by[query]=${this.state.sectionAutoCopmpleteValue}` : ''
    
    let apiUrl = `${configJSON.getSectionListApiEndPoint}?token=${this.authToken}&price_list_id=${toString(value?.id)}&dropdown=true&page_no=${this.state.sectionNextPage}${searchQuery}&per_page=${this.itemsPerPage}`;

    const getAccount = apiCall({
      header: {},
      httpBody: {},
      url: apiUrl,
      httpMethod: configJSON.getSectionListApiMethod,
    });

    this.getSectionListAsPerPriceListApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  handleSectionMultiSelect(getData: {id: number, option: string}) {
    if(this.state.isSectionSelectAll) {
      this.setState({
        isSectionSelectAll: false,
        selectPricelistProductForAdd: [], 
        addPricelistSelectedOptionForList: []
      });
    }
    this.setState({productSelectedData: [], isSelectAll: false});
    let idCheck = getData?.id;
    let newArray;
    if(this.state.sectionSelectedData.some((object: {id: number, option: string}) => object.id === idCheck)) {
      newArray = this.state.sectionSelectedData?.filter((objc: {id: number, option: string}) => objc.id !== idCheck);
      this.setState({sectionSelectedData: newArray, pricelistProductList: []}, () => this.handleOptionByPriceList())
    }
    else {
      this.setState({sectionSelectedData: [...this.state.sectionSelectedData, getData], pricelistProductList: []}, () => this.handleOptionByPriceList())
    }
  }

  handleOptionByPriceList() {
    const sectionSelectedIds = this.state.selectedSections.filter(item => item.id !== "-1").map((item) => item.id)
    const filterBySectionIds = !this.state.isSectionSelectAll ? `&category_ids[]=${sectionSelectedIds}` : "";
    let apiUrl = `${configJSON.getProductListApiEndPoint}?dropdown=true&price_list_id=${this.state.selectedPriceListToCopy?.id}${filterBySectionIds}&page_no=${this.state.productNextPage}&filter_by[query]=${this.state.autoCopmpleteValue}`;
    const getAccount = apiCall({
      httpBody: {},
      header: {
        token: this.authToken
      },
      url: apiUrl,
      httpMethod: configJSON.getProductListApiMethod,
    });

    this.getProductListOptionApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  handleSectionSelectAll(values: IFormValue) {
    if (this.state.isSectionSelectAll) {
      this.setState({ isSectionSelectAll: false, sectionSelectedData: [], productSelectedData: [], selectPricelistProductForAdd: [], addPricelistSelectedOptionForList: [] })
      this.setState(() => ({
        addPriceInitialvalues: {
          name: values.name,
          priceListType: values.priceListType,
          taxType: values.taxType,
          price_list_to_copy: values.price_list_to_copy,
          section: [],
          product_currency_id: values.product_currency_id,
          added_by: values.added_by,
          product: []
        }
      }))
    }
    else {
      this.setState({ isSectionSelectAll: true, sectionSelectedData: this.state.dropdownOptions.section, selectPricelistProductForAdd: [], addPricelistSelectedOptionForList: [] }, () => this.handleOptionByPriceList())
      this.setState(() => ({
        addPriceInitialvalues: {
          name: values.name,
          priceListType: values.priceListType,
          taxType: values.taxType,
          price_list_to_copy: values.price_list_to_copy,
          section: this.state.dropdownOptions.section?.map((item: {id: number | string, option: string}) => item.id) as [],
          product_currency_id: values.product_currency_id,
          added_by: values.added_by,
          product: this.state.dropdownOptions.productsList?.map((item: {id: number | string, option: string}) => { return item.id }) as []
        }
      }))
    }
  }

  getSectionListOptionApiCallResponse(responseJson: any) {
    if (this.checkGetResponse(responseJson)) {
      this.setState(() => ({
        dropdownOptions: {
          ...this.state.dropdownOptions,
          section: responseJson.data?.map((item: any) => { return { id: item.attributes.id, option: item.attributes.section_name } })
        },
        sectionOptions: responseJson.data?.map((item: any) => { return { id: item.attributes.id, option: item.attributes.section_name } })
      }))
      this.disableLoadMoreSection = responseJson.data?.length < this.itemsPerPage
    } else {
      this.setState({ isLoading: false })
    }
  }

  addPriceListOptionApiCallResponse(responseJson: any) {
    if (this.checkGetResponse(responseJson)) {
      this.setState({ 
        isLoading: false, 
        accountError: '', 
        pricelistDropPage: 1,
        selectedPriceListToCopy: null, 
        sectionSelectedData: [], 
        productSelectedData: [],
        selectedTaxType: '',
        selectedPriceListType: '',
        priceListMinusValueError: false,
        priceListMinusValueMessage: '',
        openPriceListSnackbar: true,
        dropdownOptions: {
          ...this.state.dropdownOptions,
          priceListToCopy: []
        },
        addPriceInitialvalues: {
          name: '',
          price_list_to_copy: '',
          priceListType: '',
          taxType: '',
          section: [],
          product: [],
          product_currency_id: "SAR",
          added_by: '',
        },
        selectedSections: [],
        selectPricelistProductForAdd: [],
        selectedSectionsForList: [],
        addPricelistSelectedOptionForList: [],
        isSaveLoading: false
      });
      this.props.onClose();
      this.getPriceList();
    } else {
      const errorKey = Object.keys(responseJson?.errors)[0];
      if(errorKey == "price") {
        this.setState({ 
          isSaveLoading: false,
          priceListMinusValueError: true,
          priceListMinusValueMessage: responseJson?.errors?.price
        })
      }
      this.setState({isSaveLoading: false, isLoading: false, accountError: responseJson?.errors?.name[0] })
    }
  }

  getCheckMobilePriceListApiResponse = (responseJson: any) => {
    if(responseJson.confirmation_request) {
      this.setState({
        mobileDeactiveMsg: responseJson.message,
        mobileConfirmModal: true
      })
    }
  }

  getProductListOptionApiCallResponse(responseJson: any) {
    if (this.checkGetResponse(responseJson)) {
      const existingData = this.state.pricelistProductList;
      this.setState(() => ({
        dropdownOptions: {
          ...this.state.dropdownOptions,
          productsList: responseJson.data?.map((item: any) => { return { id: item.attributes.id, option: item.attributes.product_name } })
        },
        pricelistProductList: uniqBy(existingData.concat(responseJson.data), "id"),
        currentProductData: responseJson.data
      }), () => {
        this.state.isSelectedAll && this.setState({
          addPricelistSelectedOptionForList: [...this.state.addPricelistSelectedOptionForList, ...responseJson.data.map((item: IProductItems) => { return { id: item.id, option: item.attributes.product_name } })],
        })
      })
    } else {
      this.setState({ isLoading: false })
    }
  }

  handleScrollPricelistDropdown = (event: React.SyntheticEvent) => {
    const listboxNode = event.currentTarget;

    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    if (
      listboxNode.scrollHeight - position <= 1.3 &&
      !this.disableLoadMorePriceList
      ) {
      this.setState({
        pricelistDropPage: this.state.pricelistDropPage + 1
      },
        () => this.getPriceList()
      )
    }
  }

  handleAutoCompleteChange = (getValue: string) => {
    if (getValue === this.state.autoCopmpleteValue) return;
    if(getValue.length < 1 || getValue.length > 2) {
      this.setState({
        autoCopmpleteValue: getValue,
        pricelistDropPage: 1,
      }, () => this.getPriceList());
    }
  }

  checkPricelistSelectAllText = (getValue: {id: string | number, option: string}[]) => {
    this.setState({ selectPricelistProductForAdd: getValue })
  };

  handlePricelistClickOnSelectAll = () => {
    this.setState({isSelectedAll: true, selectPricelistProductForAdd: []})
  };

  handleAutoCompleteProductChange = (getValue: string) => {
    this.setState({ autoCopmpleteValue: getValue, productNextPage: 1 });
    if (getValue.length < 1 || getValue.length > 2) {
      this.handleOptionByPriceList()
    }
  }

  debouncedFunction = debounce(
    (newInputValue: string, inputFunction: (inputValue: string) => void) =>
      inputFunction(newInputValue),
    700,
    { maxWait: 2000 }
  );

  handlePricelistEmptyAutoSelectValue = () => {
    this.setState({ autoCopmpleteValue: "" })
  };

  handlePricelistScrollProductDropdown = (event: React.SyntheticEvent) => {
    const listboxNode = event.currentTarget;
    const position = listboxNode.scrollTop + listboxNode.clientHeight;

    if (listboxNode.scrollHeight - position <= 1.30 && this.state.currentProductData.length !== 0) {
      this.setState({ productNextPage: this.state.productNextPage + 1 }, () => {
        this.handleOptionByPriceList()
      })
    }
  };

  handlePricelistSelectOptions = (value: { id: string | number, option: string }[], option: { id: string | number, option: string }, setFieldValue: Function) => {
    if (option.id == "-1") {
      if(this.state.isSelectedAll) {
        this.setState({ isSelectedAll: false, selectPricelistProductForAdd: [], addPricelistSelectedOptionForList: [] });
      } else {
        this.setState({
          isSelectedAll: true,
          selectPricelistProductForAdd: [{ id: "-1", option: "Select All" }],
          addPricelistSelectedOptionForList: [{ id: "-1", option: "Select All" }, ...this.state.pricelistProductList.map((item) => { return { id: item.id, option: item.attributes.product_name } })]
        }, () => setFieldValue('product', ["All"]));
      }
      return;
    }

    const isContained = value.some((item) => item.id == option.id)
    
    if (isContained) {
      value = value.filter((item) => item.id !== "-1")
      this.setState({ addPricelistSelectedOptionForList: value.filter((item) => item.id != option.id),selectPricelistProductForAdd: value.filter((item) => item.id != option.id), isSelectedAll: false }, () => setFieldValue('product', value.filter((item) => item.id != option.id).map((item) => item.id)))
    } else {
      value = value.filter((item) => item.id !== "-1")
      this.setState({ addPricelistSelectedOptionForList: [...value, option], selectPricelistProductForAdd: [...value, option], isSelectedAll: false }, () => setFieldValue('product', [...value, option].map((item) => item.id)))
    }
  };

  handleChangeSelectedPriceList = (newSelectedPriceList: {id: number, option: string} | null, setFieldValue: (field: string, value: string | number | Array<number>) => void) => {
    setFieldValue("price_list_to_copy", newSelectedPriceList ? newSelectedPriceList.id : "");
    setFieldValue("product", [])
    setFieldValue("section", [])
    this.handleCatergoriesOptions(newSelectedPriceList)
    if (!newSelectedPriceList) {
      this.debouncedFunction("", this.handleAutoCompleteChange)
    }
  }
  debouncedFunctionSection = debounce(
    (newInputValue: string, inputFunction: (inputValue: string) => void) => inputFunction(newInputValue),
    700,
    { maxWait: 2000 }
  );

  handleScrollSectionDropdown = (event: React.SyntheticEvent) => {
    if (this.disableLoadMoreSection) return;

    const checkListboxNode = event.currentTarget;
    const boxPosition = checkListboxNode.scrollTop + checkListboxNode.clientHeight;

    if (checkListboxNode.scrollHeight - boxPosition <= 1.30) {
      this.setState((prevState) => ({ sectionNextPage: prevState.sectionNextPage + 1 }), () => {
        this.handleCatergoriesOptions(this.state.selectedPriceListToCopy)
      })
    }
  };

  handleSectionAutoCompleteChange = (getValue: string) => {
    if (getValue === this.state.sectionAutoCopmpleteValue) return;
    this.setState({ sectionAutoCopmpleteValue: getValue, sectionNextPage: 1 }, () => {
      if (getValue.length < 1 || getValue.length > 2) {
        this.handleCatergoriesOptions(this.state.selectedPriceListToCopy)
      }
    });
  }
  handleSelectSectionOptions = (value: { id: string | number, option: string }[], option: { id: string | number, option: string }, setFieldValue: Function) => {
    let updateStateData: Partial<S> = {
      isSelectedAll: false,
      autoCopmpleteValue: "",
      pricelistProductList: []
    }
    let fieldValue: Array<string | number> = []
    if (option.id == "-1") {
      updateStateData = this.state.isSectionSelectAll ?
        { ...updateStateData, isSectionSelectAll: false, selectedSections: [], selectedSectionsForList: [] }
        :
        {
          ...updateStateData,
          isSectionSelectAll: true,
          selectedSections: [{ id: "-1", option: "Select All" }],
          selectedSectionsForList: [{ id: "-1", option: "Select All" }, ...this.state.sectionOptions]
        };
      fieldValue = updateStateData.isSectionSelectAll ? ["All"] : []

    } else {
      const isValueContained = value.some((item) => item.id == option.id)
      value = value.filter((item) => item.id !== "-1")
      const newItems = isValueContained ? value.filter((item) => item.id != option.id) : [...value, option]
      updateStateData = updateMultiSelectData(newItems, this.state.sectionOptions, 'isSectionSelectAll', 'selectedSections', 'selectedSectionsForList');
      updateStateData.autoCopmpleteValue = '';
      fieldValue = newItems.map((item) => item.id)
    }
    this.setState(updateStateData as Pick<S, keyof S>, () => {
      setFieldValue("section", fieldValue)
      this.handleOptionByPriceList()
    })
    this.setState({ selectPricelistProductForAdd : [], addPricelistSelectedOptionForList: [], isSelectedAll: false})
  };
  // Customizable Area End
}
