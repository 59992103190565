import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isLoading: boolean;
  organizationId: number;
  organizationItem: {
    name: string;
    tax_number: string;
  };
  permissionStatus: PermissionStatus;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class OrganizationListViewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getOrganizationCallId: string = "";

  async componentDidMount() {
    let organizationId: any = this.props.navigation.getParam("organizationId");
    this.setState({ organizationId: organizationId }, () =>
      this.getOrganizationDetails()
    );
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),

      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.LayoutDataMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isLoading: false,
      organizationId: 0,
      organizationItem: {
        name: "",
        tax_number: "",
      },
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getOrganizationCallId) {
        if (responseJson && responseJson.data) {
          const item = {
            name: responseJson.data.attributes.name,
            tax_number: responseJson.data.attributes.tax_number,
          };
          this.setState({
            organizationItem: item,
            isLoading: false,
          });
        }
      }
    }

    this.receiveDataFromLayout(message)
    // Customizable Area End
  }

  // Customizable Area Start
  receiveDataFromLayout = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.LayoutMessageData)
        );
        if (recievedData.userContext) {
          this.handleUserChange(recievedData.userContext)
        }
    }
  }

  navigateEditOrganizationPage = () => {
    this.props.navigation.navigate("EditOrganization", {
      organizationId: this.state.organizationId,
    });
  };

  handleBack = () => {
    console.log("goback called");
    this.props.navigation.goBack();
  };

  getOrganizationDetails = () => {
    this.setState({ isLoading: true });
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem(configJSON.token),
    };
    const apiUrl =
      configJSON.getAllOrgListEndPoint + "/" + this.state.organizationId;
    const getOrgDetailmessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getOrganizationCallId = getOrgDetailmessage.messageId;
    getOrgDetailmessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );
    getOrgDetailmessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getOrgDetailmessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getOrgDetailmessage.id, getOrgDetailmessage);
  };

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.organisationPermission;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    })
  };

  // Customizable Area End
}
