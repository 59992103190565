import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  InputLabel,
  Typography,
  Chip,
  Grid,
  Hidden,
  styled
} from "@material-ui/core";

import { LoadingComponent } from "../../../components/src/Loader.web";
import CfViewProductDetailsController, {
  Props,
} from "./CfViewProductDetailsController";
import { CustomPaper } from "../../../components/src/customComponents/CustomTags.web";
import { StyledPageHeader, priceListWebStyle } from "./CfPriceList.web";
import { defaultIcon } from "./assets";
import { colors } from "../../../blocks/utilities/src/Colors";
export const configJSON = require("./config");
import FullHeightSkeleton from "../../../components/src/FullHeightSkeleton.web";
import {
  renderBaseOnConditions
} from "../../../blocks/utilities/src/CustomBlockHelpers";
// Customizable Area End

export default class CfViewProductDetails extends CfViewProductDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
  setProductData(data: any) {
    if (data !== undefined && data !== null) {
      return (
        <Typography style={viewProductDetailsWebStyle.productData}>
          {data}
        </Typography>
      );
    } else {
      return (
        <Typography style={viewProductDetailsWebStyle.productData}>
          --
        </Typography>
      );
    }
  }

  setMultiSectionData(data: any) {
    if (data?.length !== undefined && data?.length !== 0) {
      return (
        <Typography style={viewProductDetailsWebStyle.productData}>
          <div style={viewProductDetailsWebStyle.chips}>
            {data?.map((section: any, index: any) => {
              return (
                <Chip
                  key={index}
                  label={section?.attributes?.section_name}
                  style={viewProductDetailsWebStyle.chip}
                />
              );
            })}
          </div>
        </Typography>
      );
    } else {
      return (
        <Typography style={viewProductDetailsWebStyle.productData}>
          --
        </Typography>
      );
    }
  }

  setMultiServiceData(data: any) {
    if (data?.length !== undefined && data?.length !== 0) {
      return (
        <Typography style={viewProductDetailsWebStyle.productData}>
          <div style={viewProductDetailsWebStyle.chips}>
            {data?.map((item: any, index: any) => {
              const service = item?.attributes?.service?.data;
              return (
                <Chip
                  key={index}
                  label={service?.attributes?.name}
                  style={viewProductDetailsWebStyle.chip}
                />
              );
            })}
          </div>
        </Typography>
      );
    } else {
      return (
        <Typography style={viewProductDetailsWebStyle.productData}>
          --
        </Typography>
      );
    }
  }

  setMultiMeasurementData(data: any) {
    if (data?.length !== undefined && data?.length !== 0) {
      return (
        <Typography style={viewProductDetailsWebStyle.productData}>
          <div style={viewProductDetailsWebStyle.chips}>
            {data?.map((item: string, index: number) => {
              return (
                <Chip
                  key={index}
                  label={item}
                  style={viewProductDetailsWebStyle.chip}
                />
              );
            })}
          </div>
        </Typography>
      );
    } else {
      return (
        <Typography style={viewProductDetailsWebStyle.productData}>
          --
        </Typography>
      );
    }
  }

  getMultiServicePrice(data: any) {
    if (data?.length !== undefined && data?.length !== 0) {
      return data?.map((item: any) => {
        const price = item?.attributes?.price;
        const sqmPrice = item?.attributes?.sqm_price;
        const sqmVal = item?.attributes?.sqm;
        const weightPrice = item?.attributes?.weight_price;
        const weightVal = item?.attributes?.weight;
        const service = item?.attributes?.service?.data?.attributes;
        const isSqm  = this.state?.productDetails?.catalogue_variants[0]
        ?.attributes?.measurement_type.includes("SQM")
        const isWieght  = this.state?.productDetails?.catalogue_variants[0]
        ?.attributes?.measurement_type.includes("Weight")
        if(!isSqm && !isWieght){
          return (
            <Grid
              key={"service-price-item-" + service.id}
              item
              xs={12}
              sm={12}
              md={6}
            >
              <CustomInputLabel >
                Price for {service?.name}
              </CustomInputLabel>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Typography style={viewProductDetailsWebStyle.productData}>
                  SAR {price}
                </Typography>
                {item?.attributes.default && (
                  <Chip
                    label='default'
                    style={{
                      ...viewProductDetailsWebStyle.chip,
                      margin: "0 4px",
                    }}
                  />
                )}
              </Box>
            </Grid>
          );
        }else{
          return (
            <>
            {isSqm && <> <Grid
              key={"service-price-item-" + service.id}
              item
              xs={12}
              sm={12}
              md={6}
            >
              <CustomInputLabel >
                SQM
              </CustomInputLabel>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Typography style={viewProductDetailsWebStyle.productData}>
                   {sqmVal} SQM
                </Typography>
              </Box>
            </Grid>
            <Grid
              key={"service-price-item-" + service.id}
              item
              xs={12}
              sm={12}
              md={6}
            >
              <CustomInputLabel >
                Price for {service?.name}
              </CustomInputLabel>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Typography style={viewProductDetailsWebStyle.productData}>
                  SAR {sqmPrice} per  {sqmVal} SQM
                </Typography>
              
              </Box>
            </Grid></>}

          {isWieght && <>  <Grid
              key={"service-price-item-" + service.id}
              item
              xs={12}
              sm={12}
              md={6}
            >
              <CustomInputLabel >
                Weight
              </CustomInputLabel>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Typography style={viewProductDetailsWebStyle.productData}>
                   {weightVal} Kg
                </Typography>
              </Box>
            </Grid>
            <Grid
              key={"service-price-item-" + service.id}
              item
              xs={12}
              sm={12}
              md={6}
            >
              <CustomInputLabel >
                Price for {service?.name}
              </CustomInputLabel>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Typography style={viewProductDetailsWebStyle.productData}>
                  SAR {weightPrice} per 1 Kg
                </Typography>
              
              </Box>
            </Grid> </>}
            </>
          );
        }
      });
    }
  }

  getRendereMeasurement = () => {
    return (
      <>
      { this.state?.productDetails?.product_type?.id !== 1 &&
      <Grid item xs={12} sm={12} md={6}>
          <CustomInputLabel >
            {configJSON.measurementText}
          </CustomInputLabel>
            {this.state?.productDetails?.length !== 0 &&
            this.setMultiMeasurementData(
              this.state?.productDetails?.catalogue_variants[0]
                ?.attributes?.measurement_type
            )}
        </Grid>
      }
      </>
    )
  }

  renderService = () => {
    return(
      <>
      { this.state?.productDetails?.product_type?.id !== 1 && 
        <Grid item xs={12} sm={12} md={6}>
          <CustomInputLabel >
            Service
          </CustomInputLabel>
          {this.state?.productDetails?.length !== 0 &&
            this.setMultiServiceData(
              this.state?.productDetails?.catalogue_variants[0]
                ?.attributes?.catalogue_variants_services?.data
            )}
        </Grid>
      }
      </>
    )
  }

    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
    <Box>
        <>
          {this.state.isLoading ? (
            <FullHeightSkeleton/>
          ) : (
            <div>
              <Box
                style={{ ...priceListWebStyle.contentWrapper}}
              >
                      <div style={priceListWebStyle.headerWrapper}>
                        <StyledPageHeader>Product</StyledPageHeader>

                        <LoadingComponent
                          isLoading={this.state.isLoadingPermission}
                          height={44}
                          width={160}
                          disableParentWidth
                          isAllowed={this.state.permissionStatus.editPermission}
                          children={
                            <CustomButton
                              data-test-id='btnEditProduct'
                              className="addBtn"
                              onClick={this.handleNavigateEdit}
                            >
                              Edit
                            </CustomButton>
                          }
                        />
                      </div>
                <CustomPaper style={viewProductDetailsWebStyle.paper}>
                  <Grid container spacing={3} id='modal-content'>
                    <Grid item xs={12} sm={12} md={6}>
                      <CustomInputLabel >
                        Price List
                      </CustomInputLabel>
                      {this.setProductData(
                        this.state?.productDetails?.length !== 0 &&
                          this.state?.productDetails?.catalogue_variants[0]
                            ?.attributes?.price_list?.name
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <CustomInputLabel >
                        Product Type
                      </CustomInputLabel>
                      {this.setProductData(
                        this.state?.productDetails?.product_type?.value
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <CustomInputLabel >
                        Product Name
                      </CustomInputLabel>
                      {this.setProductData(
                        this.state?.productDetails?.product_name
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <CustomInputLabel >
                        Product Arabic Name
                      </CustomInputLabel>
                      {this.setProductData(
                        this.state?.productDetails?.product_second_name
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <CustomInputLabel >
                        Section
                      </CustomInputLabel>
                      {this.setMultiSectionData(
                        this.state?.productDetails?.sections?.data
                      )}
                    </Grid>
                    {this.renderService()}
                    <Grid item xs={12} sm={12} md={6}>
                      <CustomInputLabel >
                        Product Image
                      </CustomInputLabel>
                      <Typography style={viewProductDetailsWebStyle.productData}>
                        {this.state?.productDetails?.length !== 0 &&
                        this.state?.productDetails?.catalogue_variants[0]
                          ?.attributes?.image !== null ? (
                          <img
                            src={
                              this.state.productDetails?.catalogue_variants[0]
                                ?.attributes?.image?.image
                            }
                            style={{ width: "45px", height: "45px" }}
                            alt='product_image'
                          />
                        ) : (
                          <img src={defaultIcon} />
                        )}
                      </Typography>
                    </Grid>
                    <Hidden xsDown smDown>
                      <Grid item md={6} lg={6} xl={6}></Grid>
                    </Hidden>
                  </Grid>
                </CustomPaper>
              {this.state?.productDetails?.product_type?.id !== 1 &&  <CustomPaper>
                  <Grid container spacing={3} id='modal-content'>
                 
                  <Grid item xs={12} sm={12} md={6}>
                      <CustomInputLabel >
                        Print Tag
                      </CustomInputLabel>
                      {this.setProductData(
                        this.state?.productDetails?.is_print_tag ? "Enabled" : "---"
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {this.state?.productDetails?.parent?.data?.attributes
                        ?.product_name ? (
                        <>
                          <CustomInputLabel
                            
                          >
                            Parent
                          </CustomInputLabel>
                          {this.setProductData(
                            this.state?.productDetails?.parent?.data?.attributes
                              ?.product_name
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </Grid>
                    {this.getRendereMeasurement()}
          
                    <Grid item xs={12} sm={12} md={6}>
                      {this.state?.productDetails?.length !== 0 &&
                      this.state?.productDetails?.catalogue_variants[0]
                        ?.attributes?.measurement_type.includes("Quantity") ? (
                        <>
                          <CustomInputLabel
                            
                          >
                            Pieces
                          </CustomInputLabel>
                          {this.setProductData(
                            this.state?.productDetails?.length !== 0 &&
                              this.state?.productDetails?.catalogue_variants[0]
                                ?.attributes?.pieces
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </Grid>
                    {this.state.productDetails.length !== 0 &&
                      this.getMultiServicePrice(
                        this.state?.productDetails?.catalogue_variants[0]
                          ?.attributes?.catalogue_variants_services?.data
                      )}

                    <Grid item xs={12} sm={12} md={6}>
                      <CustomInputLabel >
                        Minimum Washing Cycle
                      </CustomInputLabel>
                      {this.setProductData(
                        this.state?.productDetails?.minimum_washing_cycle
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <CustomInputLabel >
                        Maximum Washing Cycle
                      </CustomInputLabel>
                      {this.setProductData(
                        this.state?.productDetails?.maximum_washing_cycle
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <CustomInputLabel >
                        Preference
                      </CustomInputLabel>
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={
                            this.state?.productDetails?.preference?.data
                              ?.attributes?.gallery_icon?.image
                          }
                          alt='preference_image'
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "7px",
                          }}
                        />
                        <span>
                          {
                            this.state?.productDetails?.preference?.data
                              ?.attributes?.preference_first_name
                          }
                        </span>
                      </span>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <CustomInputLabel >
                        Conveyor Bag
                      </CustomInputLabel>
                      {this.setProductData(
                        this.state?.productDetails?.conveyor_bag?.value
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <CustomInputLabel >
                        Conveyor Priority
                      </CustomInputLabel>
                      {this.setProductData(
                        this.state?.productDetails?.conveyor_priority?.value
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <CustomInputLabel >
                        Slot Space
                      </CustomInputLabel>
                      {this.setProductData(
                        this.state?.productDetails?.slot_space?.value
                      )}
                    </Grid>

                      {renderBaseOnConditions(this.state.isB2bActive, <Grid item xs={12} sm={12} md={6}>
                      <CustomInputLabel >
                        {configJSON.approximateProductWeightTxt}
                      </CustomInputLabel>
                      {this.setProductData(
                        this.state?.productDetails?.approx_weight
                      )}
                    </Grid>,<></>)}
                   
                  </Grid>
                </CustomPaper>}
                <Box style={{ marginTop: "48px", marginBottom: "40px" }}>
                  <CustomButton
                    data-test-id='btnBack'
                    onClick={() => this.handleBackToProductList()}
                    className="backBtn"
                  >
                    Back
                  </CustomButton>
                </Box>
              </Box>
            </div>
          )}
        </>
      </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const viewProductDetailsWebStyle: any = {
  fieldLabel: {
    marginBottom: "6px",
    color: "#4d4d4d",
    fontWeight: 600,
    fontSize: "18px",
    fontFamily: "Montserrat",
    textTransform: "capitalize",
  },
  fieldSet: {
    padding: "12px 8px",
  },
  chips: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    minHeight: "38px",
  },
  chip: {
    margin: "2px 5px 2px 0",
    backgroundColor: "rgba(32, 75, 156, 0.1)",
    color: "#204B9C",
    height: "26px",
    borderRadius: "8px",
  },
  productData: {
    fontSize: "14px",
    fontFamily: "Montserrat",
    fontWeight: 500,
  },
  paper: {
    marginBottom: "32px",
  },
};

const CustomButton = styled("button")({
  borderRadius:"8px",
  cursor: "pointer",
  "&.cancelBtn": {
    backgroundColor: colors().antiFlashWhite,
    color: colors().slateGray,
    width: '128px',
    height: '56px',
    textTransform: 'none',
    border: "0",
    padding:"16px",
    cursor: "pointer",
    "@media (max-width: 1024px)": {
      width: '128px',
      height: '44px',
      padding:"10px 0"
    },
    "@media (max-width: 768px)": {
      padding: "10px 0",
    },
  },
  "&.addBtn": {
    "@media (max-width: 1024px)": {
      width: '128px',
      height: '44px',
     padding:"10px 0"
    },
    backgroundColor: colors().cyancobaltblue,
    color: colors().white,
    width: '184px',
    height: '56px',
    textTransform: "none",
    border: "0",
    fontWeight: 700,
    fontSize: "16px",
    fontFamily: "Montserrat",
    cursor: "pointer",
   
    "&:hover": {
      backgroundColor: colors().cyancobaltblue,
      color: colors().white
    },
    "@media (max-width: 768px)": {
      padding: "10px 0",
    },
  },
  "&.backBtn": {
    color: colors().white,
    width: '128px',
    height: '56px',
    backgroundColor: colors().cyancobaltblue,
    textTransform: "none",
    "@media (max-width: 1024px)": {
      width: '128px',
      height: '44px',
     padding:"10px 0"
    },
    fontWeight: 700,
    border: "0",
    fontSize: "16px",
    fontFamily: "Montserrat",
    "@media (max-width: 768px)": {
      padding: "10px 0",
    },
    "&:hover": {
      backgroundColor: colors().cyancobaltblue,
      color: colors().white
    },
  },
})

const CustomInputLabel = styled("div")({
  marginBottom: "6px",
  color: colors().viewTextColor,
  fontWeight: 600,
  fontSize: "18px",
  fontFamily: "Montserrat",
  textTransform: "capitalize",
  "@media (max-width: 1024px)": {
    fontSize:'16px'
  },
})

// Customizable Area End
