import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ICompany } from "../../CfSubCompany/src/CompanyListController.web";

// Customizable Area Start
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
interface IRegion {
  id?: string;
  attributes: {
    id?: string;
    branch_name: string;
    branch_short_name: string;
    branch_id: string;
    vat_no: string;
    tin_no: string;
    country_id: number;
    company_id: number;
    telephone: string;
    address: string;
    email: string;
    others: {
      [key: string]: string | { title: string; isSelected: boolean }[];
    };
    company: {
      id: string;
      attributes: {
        id: number;
        name: string;
      };
    };
    company_data: {
      id: string;
      option:string;
    };
  };
}

interface ICountry {
  country_code: string;
  updated_at: string;
  name: string;
  created_at: string;
  id: number;
}

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  addModalVisible: boolean;
  regionInfo: IRegion;
  initialRegionInfo: IRegion;
  modalCategory: "text" | "dropdown";
  modalTitle: string;
  modalText: string;
  modalOptions: { title: string; isSelected: boolean }[];
  regionId: string;
  formErrors: { [key: string]: string };
  snackBarMessage: string;
  countries: ICountry[];
  companies: ICompany[];
  isLoading: boolean;
  permissionStatus: PermissionStatus;
  modalError:{
    title:string|null,
    text:string|null,
    option:string|null
  },
  otherFieldError: Record<string, string | null>
}

interface SS {
  id: any;
}

export default class AddRegionController extends BlockComponent<Props, S, SS> {
  updateRegionCallId: string = "";
  getRegionCallId: string = "";
  getRegionSuggestionCallId: string = "";
  addRegionCallId: string = "";
  getCountriesCallId: string = "";
  getCompaniesCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.LayoutDataMessage)
    ];

    this.state = {
      companies: [],
      countries: [],
      snackBarMessage: "",
      formErrors: {},
      regionId: "",
      modalOptions: [{ title: "", isSelected: false }],
      addModalVisible: false,
      modalCategory: "text",
      modalTitle: "",
      modalText: "",
      regionInfo: {
        attributes: {
          branch_name: "",
          branch_short_name: "",
          branch_id: "",
          tin_no: "",
          telephone: "",
          address: "",
          email: "",
          others: {},
          country_id: 0,
          company_id: 0,
          vat_no: "",
          company: {
            id: "",
            attributes: {
              id: 0,
              name: "",
            },
          },
          company_data:{
            id: "",
            option:''
          }
        },
      },
      initialRegionInfo: {
        attributes: {
          branch_name: "",
          vat_no: "",
          tin_no: "",
          branch_id: "",
          country_id: 0,
          company_id: 0,
          telephone: "",
          branch_short_name: "",
          address: "",
          email: "",
          others: {},
          company: {
            attributes: {
              name: "",
              id: 0,
            },
            id: "",
          },
          company_data:{
            id: "",
            option:''
          }
        },
      },
      isLoading: false,
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      },
      modalError:{
        title:null,
        text:null,
        option:null
      },
      otherFieldError: {}
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  receiveUpdateRegion = (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (responseJson && responseJson.data) {
      if (responseJson.data.type !== "error") {
        if (this.state.regionId) this.showMessage("Region Updated Successfully");
        else this.showMessage("Region Added Successfully");
        setTimeout(() => {
          this.props.navigation.goBack();
        }, 2000);
      } else {
        const formErrors = this.state.formErrors;
        const telephoneError = responseJson.data.attributes.errors.telephone;
        if (telephoneError) {
          formErrors[configJSON.telephone] = telephoneError[0];
        }
        this.setState({ formErrors , isLoading: false});
      }
    }
  };
  handleScrollCompanyDropdown = (event: React.SyntheticEvent) => {
   
  };
  receiveGetRegion = (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (responseJson && responseJson.data) {
      let regionInfo = responseJson.data as IRegion;
      if (regionInfo.attributes.company) {
        regionInfo.attributes.company_id = Number(regionInfo.attributes.company.id);
      }
      regionInfo = {
        ...regionInfo,
        attributes:{
          ...regionInfo.attributes,
          company_data:{
            id:regionInfo.attributes.company.id,
            option:regionInfo.attributes.company.attributes.name
          }
        }
      }
      this.setState({ regionInfo, initialRegionInfo: regionInfo,isLoading:false });
    }
  };

  receiveGetRegionSuggestion = (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (responseJson && responseJson.data) {
      if (this.state.regionId) return;
      let regionInfo = this.state.regionInfo;
      let suggestion = responseJson.data as IRegion;
      regionInfo.attributes.branch_id = suggestion.attributes.branch_id;
      this.setState({ regionInfo: { ...regionInfo }, initialRegionInfo: { ...regionInfo } });
    }
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.getCompaniesCallId) {
        if (responseJson && responseJson.data) {
          let companies = responseJson.data as ICompany[];
          this.setState({ companies });
        }
      } else if (apiRequestCallId === this.getRegionCallId) {
        this.receiveGetRegion(message);
      } else if (apiRequestCallId === this.getRegionSuggestionCallId) {
        this.receiveGetRegionSuggestion(message);
      } else if (apiRequestCallId === this.updateRegionCallId) {
        this.receiveUpdateRegion(message);
      }
    }

    this.receiveDataFromLayout(message)
  }

  getRegion = (regionId: string) => {
    this.setState({isLoading: true})
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem(configJSON.tokenKey),
    };
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getRegionCallId = getDataMsg.messageId;

    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodType);
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.regionListApi + "/" + regionId
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  getRegionSuggestion = () => {
    const reqHeader = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem(configJSON.tokenKey),
    };
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getRegionSuggestionCallId = getDataMsg.messageId;

    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodType);
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.regionSuggestionApi
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(reqHeader));
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  updateCreateRegionInfo = () => {
    const regionId = this.state.regionId;
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem("token"),
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateRegionCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.regionListApi}` + (regionId ? `/${regionId}` : "")
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    const data = { ...this.state.regionInfo };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ data })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      regionId ? configJSON.putMethod : configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // web events

  onCancel = () => {
    this.setState({ regionInfo: { ...this.state.initialRegionInfo } });
    this.props.navigation.goBack();
  };

  isEmail = (fieldName: string, value: string) => {
    if (!configJSON.emailReg.test(value)) {
      return { status: false, message: "Invalid email address." };
    }
    return { status: true, message: "" };
  }

  checkEmail = (email: string, formErrors: { [key: string]: string }) => {
    if (!email) formErrors[configJSON.email] = "Email is required";
    else {
      const message = this.isEmail("", email).message;
      if (message) formErrors[configJSON.email] = message;
    }
  };
   
  checkFormErrors = () => {
    const { regionInfo } = this.state;
    const { attributes } = regionInfo;
    const { branch_name, address, company_id, email, telephone, branch_id, branch_short_name } =
      attributes;
    const formErrors: { [key: string]: string } = {};
    this.checkEmail(email, formErrors);
    if (!branch_name.trim()) formErrors[configJSON.regionName] = "Region name is required";
    if (!address.trim()) formErrors[configJSON.address] = "Address is required";
    if (!company_id) formErrors[configJSON.company] = "Company is required";
    if (!branch_id.trim()) formErrors[configJSON.regionId] = "Region ID is required";
    if (!telephone.trim()) formErrors[configJSON.telephone] = "Telephone is required";
    if (!branch_short_name.trim())
      formErrors[configJSON.regionShortName] = "Region Short Name is required";
    else {
      if (telephone.length < 8 || telephone.length > 15) {
        formErrors[configJSON.telephone] = "Telephone invalid or Unrecognized Phone Number";
      }
    }

    this.setState({ formErrors });
    return Object.keys(formErrors).length === 0;
  };

  onSave = () => {
    let isOtherFieldValid = this.checkAllProperties(this.state.regionInfo.attributes.others)
    if (this.checkFormErrors() && isOtherFieldValid) {
      this.setState({isLoading: true})
      this.updateCreateRegionInfo();
    }
  };
  showMessage = (message: string) => {
    this.setState({ snackBarMessage: message });
  };
  

  onAddButtonClick = () => {
    this.setState({ addModalVisible: true, modalTitle: "", modalText: "", modalOptions: [{isSelected:false,title:""}] });
  };
  onModalCancelClick = () => {
    this.setState({ 
      addModalVisible: false,
      modalError:{
      option:null,
      text:null,
      title:null
    } });
  };
  handleBack = () => {
    this.props.navigation.history.goBack();
  };
  handleValidateDropDown = () => {
    if(this.state.modalTitle.trim() ===  ""){
      this.setState((prevValue)=>({
        modalError:{
          ...prevValue.modalError,
          title:"Text is required"
        },
      }))
    }else{
      this.setState((prevValue)=>({
        modalError:{
          ...prevValue.modalError,
          title:null
        },
      }))
    } 
    if(this.state.modalOptions[0].title.trim()===""){
      this.setState((prevValue)=>({
        modalError:{
          ...prevValue.modalError,
          option:"Option is required"
        },
      }))
    }else {
      this.setState((prevValue)=>({
       modalError:{
         ...prevValue.modalError,
         option:null
       },
     }))
    }
  }
  handleValidateModal = () => {
    if(this.state.modalCategory === "text"){
      if(this.state.modalTitle.trim()===""){
        this.setState((prevValue)=>({
          modalError:{
            ...prevValue.modalError,
            title:"Title is required"
          },
        }))
      }else{
        this.setState((prevValue)=>({
          modalError:{
            ...prevValue.modalError,
            title:null
          },
        }))
      }
      if(this.state.modalText.trim()===""){
        this.setState((prevValue)=>({
          modalError:{
            ...prevValue.modalError,
            text:"Text is required"
          },
        }))
      }else{
        this.setState((prevValue)=>({
          modalError:{
            ...prevValue.modalError,
            text:null
          },
        }))
      } 
      if(this.state.modalTitle.trim()===""||this.state.modalText.trim()===""){
        return false
      }
    }else {
      this.handleValidateDropDown()
      if(this.state.modalTitle.trim()===""||this.state.modalOptions[0].title.trim()===""){
        return false
      }
    }
    return true
  }
  onModalAddClick = () => {
    
    if(this.handleValidateModal()){
      const others =
      this.state.modalCategory === "text"
      ? {
        ...this.state.regionInfo.attributes.others,
        [this.state.modalTitle]: this.state.modalText,
      }
      : {
        ...this.state.regionInfo.attributes.others,
              [this.state.modalTitle]: this.state.modalOptions,
            };
      this.setState({
        modalCategory: "text",
        modalTitle: "",
        modalText: "",
        modalOptions: [{ title: "", isSelected: false }],
        addModalVisible: false,
        regionInfo: {
          ...this.state.regionInfo,
          attributes: {
            ...this.state.regionInfo.attributes,
            others,
          },
        },
      });
    }
  };

  onModalCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      modalCategory: event.target.value as "text" | "dropdown",
      modalError:{
        option:null,
        text:null,
        title:null
      }
    });
  };
  async componentDidMount() {
    const regionId = window.location.search.split("=")[1];
    this.getCompanies();
    if (regionId) {
      this.getRegion(regionId);
      this.setState({ regionId });
    }
    this.getRegionSuggestion();
  }

  onAddOption = () => {
    this.setState({
      modalOptions: [...this.state.modalOptions, { title: "", isSelected: false }],
    });
  };

  onDeleteOption = (index: number) => {
    const modalOptions = [...this.state.modalOptions];
    modalOptions.splice(index, 1);
    this.setState({ modalOptions });
  };
  closeSnackBar = () => {
    this.setState({ snackBarMessage: "" });
  };

  handleEdit = () => {
    const regionId = this.state.regionId;
    window.localStorage.setItem("regionId", regionId);
    this.props.navigation.history.push("Settings-General-RegionListEdit?regionId=" + regionId);
  };

  getCompanies = () => {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: window.localStorage.getItem(configJSON.tokenKey),
    };
    this.getCompaniesCallId = getDataMsg.messageId;

    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodType);
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.companyListEndPoint
    );
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.regionNewPermission;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    })
  };

  handleDeleteDynamicField = (propToDelete:string) => {
    this.setState(prevState => ({
      regionInfo: {
        ...prevState.regionInfo,
        attributes: {
          ...prevState.regionInfo.attributes,
          others: Object.fromEntries(
            Object.entries(prevState.regionInfo.attributes.others)
              .filter(([key]) => key !== propToDelete)
          )
        }
      }
    }));
  }

  checkAllProperties = <T extends Record<string, string | { isSelected: boolean; title: string; }[]>>(
    rawData: T
  ): boolean => {
    const processValidationResults: Record<string, string | null> = {};
    let isValidResult = true;
  
    for (const [propertyName, value] of Object.entries(rawData)) {
      let errorMessage: string | null = null;
  
      if (
        (typeof value === 'string' && value === '') ||
        (Array.isArray(value) && value.every(option => !option.isSelected))
      ) {
        errorMessage = `${propertyName} is required`;
      }
  
      processValidationResults[propertyName] = errorMessage;
      if (errorMessage) isValidResult = false;
    }
  
    this.setState({ otherFieldError: processValidationResults });
    return isValidResult;
  };
  
  validateOtherField = (errorObject:Record<string,string|null>,propertyName:string) => {
    return errorObject[propertyName]
  }
  
  receiveDataFromLayout = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.LayoutMessageData)
        );
        if (recievedData.userContext) {
          this.handleUserChange(recievedData.userContext)
        }
    }
  }

  // Customizable Area End
}
