// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area End
export const configJSON = require("../config");

export interface Props {
    navigation?: any;
    id?: string;
    // Customizable Area Start
    fromAddCustomer?: boolean;
    open: boolean;
    onClose?: any;
    classes?: any;
    responseData: Data;
    getPreferenceProductDetail?:any;
    editable?:boolean;
    saveForFuture?:boolean;
    headerTitle:string;
    data: Item[];
    handleClose: any;
    preferenceData?: any;
    selectedService: any;
    onCreate: (data: any) => void;
    onCreateForCustomer?: (data: any[]) => void;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isOpen: boolean;
    showProductError: boolean;
    showColorError: boolean;
    showDamageError: boolean;
    showStainError: boolean;
    showPatternError: boolean;
    showMaterialError: boolean;
    showCheckboxError: boolean;
    showRadioError: boolean;
    form: IFormPreference;
    selectedOptions: { specification_id: string, option_id: number[] }[];
    selectedOptionsList: { specification_id: string, option_id: string }[][];
    preferenceStateData: any;
    preferenceList: any;
    service_id: number;
    product_ids: number[];
    service_ids: number[];
    productList: {
        id: string,
        type: "catalogue_dropdown",
        attributes: {
            id: number,
            product_name: string,
        }
    }[];
    serviceList: {
        id: string,
        type: "service_dropdown",
        attributes: {
            id: number,
            name: string,
        }
    }[];

    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}
// Customizable Area Start

export interface IFormPreference {
    customer_id: number;
    preferences_attributes: [
        {
            id: number;
            catalogue_variant_id: number;
            preference_id: number;
            upcharge_list_ids: number[];
            service_id: number;
            label?: string;
            notes: string;
            save_for_future: boolean;
            pref_spec_options_attributes: { specification_option_id: string; }[]
        }
    ]
}

export type Options = {
    id: number | string;
    label: string;
    selected: boolean;
};

export interface Item {
    showErrorCheckbox?: boolean;
    showRadioDataError?: boolean;
    product: string;
    productId?: string | number;
    color: string;
    colorId?: string | number;
    damage: string;
    damageId?: string | number;
    stain: string;
    stainId?: string | number;
    pattern: string;
    patternId?: string | number;
    material: string;
    materialId?: string | number;
    options: string[];
    radioOption: string;
    textNote?:string;
    isSaveFuture?:boolean
}

export type Specification = {
    id: number;
    name: string;
    options_attributes: Options[];
};

export type Upcharge = {
    id: string;
    name: string;
    checked: boolean
    price: number;
    icon: string;

};
export type Preference = {
    id: string;
    name?: string;
    checked: boolean;
    icon?: string
};

export type Service = {
    id: number;
    name: string;
    icon: string | null;
};

export type Data = {
    id: string;
    type: string;
    attributes: {
        id: number;
        name: string;
        product_second_name: string;
        specifications: Specification[];
        upcharges: Upcharge[];
        preferences: Preference[];
        services: Service[];
    };
};

// Customizable Area End

export default class CreatePreferenceItemController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getProductListCallId: string = "";
    getServiceListCallId : string = "";

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.RestAPIResponceSuccessMessage),
            getName(MessageEnum.RestAPIResponceErrorMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
        ];

        this.state = {
            isOpen: false,
            showProductError: false,
            showColorError: false,
            showDamageError: false,
            showStainError: false,
            showPatternError: false,
            showMaterialError: false,
            showCheckboxError: false,
            showRadioError: false,
            form: {} as IFormPreference,
            selectedOptions: [],
            preferenceStateData: {},
            preferenceList: [],
            service_id: 0,
            service_ids: [],
            product_ids: [],
            selectedOptionsList: [],
            productList: [],
            serviceList: [],
        };
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    componentDidMount(): any {
        // Customizable Area Start
        this.getServiceList();
        this.getProductList();
        // Customizable Area End
    }
    // Customizable Area Start

    getProductList = () => {
        const headers = {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
        };
    
        const apiUrl = "bx_block_catalogue/catalogues?dropdown=true";
    
        const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    
        message.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          apiUrl
        );
    
        message.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
    
        message.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET",
        );
        this.getProductListCallId = message.messageId;
        runEngine.sendMessage(message.id, message);
    };

    getServiceList = () => {
        const headers = {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        };
    
        const apiUrl = "/bx_block_categories/services?dropdown=true";
    
        const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    
        message.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          apiUrl
        );
    
        message.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
    
        message.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "GET"
        );
        this.getProductListCallId = message.messageId;
        runEngine.sendMessage(message.id, message);
    };
    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevProps.preferenceData !== this.props.preferenceData && !this.props.fromAddCustomer) {
            this.setState({
                preferenceStateData: this.props.preferenceData,
                selectedOptions: this.props.preferenceData?.attributes?.specifications.map((specification: Specification) => {
                    return {
                        specification_id: specification?.id,
                        option_id: specification?.options_attributes?.find(item => item.selected)?.id || ''
                    }
                })
            });
        }  
    }

    handleCreateClick = () => {
        if (!this.props.fromAddCustomer) {
            const preferenceItemData = {
                id: this.props.preferenceData.id,
                upchargeListIds: this.state.preferenceStateData.attributes?.upcharges?.filter((item: Upcharge) => item.checked).map((item: Upcharge) => item.id),
                preferenceId: this.state.preferenceStateData.attributes?.preferences?.find((item: Preference) => item.checked)?.id,
                selectedOptions: this.state.selectedOptions,
                serviceId: this.props.selectedService.id,
                saveForFuture: this.state.preferenceStateData.attributes?.save_for_future,
                notes: this.state.preferenceStateData.attributes?.notes,
            }
            this.props.onCreate(preferenceItemData)
        }
        else {
            const preferenceItemData = this.state.preferenceList.map((item: any , index :number) => {
                    return {
                        id: item.id,
                        upchargeListIds: item.attributes.upcharges.filter((item: Upcharge) => item.checked).map((item: Upcharge) => item.id),
                        preferenceId: item.attributes.preferences.find((item: Preference) => item.checked)?.id,
                        selectedOptions: this.state.selectedOptionsList[index],
                        serviceId: this.state.service_ids[index],
                    }
                }
            )
            if(this.props.onCreateForCustomer)
                this.props.onCreateForCustomer(preferenceItemData)
        }
    }

    getSpecificationOptionList = (
        data: Array<{
          id: number;
          label: string;
          selected: boolean;
        }>
      ) => {
        const optionlist = data.map((spec) => {
          return {
            id: spec.id,
            option: spec.label,
          };
        });
        return optionlist;
    };

    handleOnSelectSpecification = (
        specification_index: number,
        selectedOptionId: number
      ) => {
        const { selectedOptions } = this.state;
        const specification = selectedOptions[specification_index]

        const isValueContained = specification.option_id.includes(selectedOptionId);
        const newValue = isValueContained ? specification.option_id.filter(optionId => optionId !== selectedOptionId) : [...specification.option_id, selectedOptionId]
        const newSelectedOptions = selectedOptions.map(
            (specificationItem, index) => index === specification_index ? ({...specificationItem, option_id: newValue}) : specificationItem
        )
        this.setState({selectedOptions: newSelectedOptions})
    };
    
    // Customizable Area End
    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      
            if (apiRequestCallId === this.getProductListCallId) {
                  this.setState(() => ({
                    productList: responseJson?.data,
                  }));
            }
            else if (apiRequestCallId === this.getServiceListCallId) {
                this.setState(() => ({
                  serviceList: responseJson?.data,
                }));
          }
        }

        // Customizable Area End
    }
}

