import React from "react";

// Customizable Area Start
import { Box, styled, Typography, Divider, IconButton } from '@material-ui/core';
import TrucatedTextWithTooltip from "../../../components/src/commonComponents/TruncatedTextWithTooltip.web";
import * as assets from "./assets";
import { colors } from "../../../blocks/utilities/src/Colors";
import { Chart } from "react-google-charts";
import DashboardTabPanel from "../../../components/src/DashboardTabPanel.web";
import { FlexiTypo, StyledSpan } from "./CustomerDashboard.web";
import FilterPopover from "../../../components/src/FilterPopover";
import FilterIcon from '@material-ui/icons/FilterListRounded';
import Skeleton from '@material-ui/lab/Skeleton';
import ExportModal from "../../../components/src//ExportModal";
import { sortCondition, renderBaseOnConditions, sortStringCondition } from "../../../blocks/utilities/src/CustomBlockHelpers"
import { getGroupNameDisplay } from "../../cfsalesdashboard3/src/utils";
import InfiniteScroll from 'react-infinite-scroll-component';
import { storeNameBasedOnGroup } from "./utils";
import { LoadingComponent } from "../../../components/src/Loader.web";
// Customizable Area End

import DiscountCreditSectionController, {
  Props,
  configJSON,
} from "./DiscountCreditSectionController.web";

export default class Dashboard extends DiscountCreditSectionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderOverall = () => {
    const { generalDiscountData, loyaltyCreditData, compensationCreditData } = this.state;
    const discountCreditCards = [
      {
        title: "General Discount",
        icon: assets.generalDiscountIcon,
        orders: generalDiscountData.order_count,
        amount: generalDiscountData.amount,
        bgColor: ["#F6F0FF", "F0E5FF"],
      },
      {
        title: "Campaign Discount",
        icon: assets.campaignDiscountIcon,
        orders: 0,
        amount: "0.00",
        bgColor: ["#F7F6C0", "EDECB9"],
      },
      {
        title: "Loyalty Credit",
        icon: assets.loyaltyCreditIcon,
        orders: loyaltyCreditData.order_count,
        amount: loyaltyCreditData.credit_amount,
        bgColor: ["#DBF9D8", "#CAF4CC"],
      },
      {
        title: "Compensation Credit",
        icon: assets.compensationCreditIcon,
        orders: compensationCreditData.order_count,
        amount: compensationCreditData.credit_amount,
        bgColor: ["#FAE1BF", "#F3D9B5"],
      },
      {
        title: "Compensation Paid",
        icon: assets.compensationPaidIcon,
        orders: 0,
        amount: "0.00",
        bgColor: ["#D3E4EA", "#C4DCE5"],
      },
    ]
    return (
      <Box className='overAllContainer'>
        {discountCreditCards.map((obj, ind) => {
          const { title, icon, orders, amount, bgColor } = obj;
          return this.renderDiscountCard({ primary: bgColor[0], secondary: bgColor[1] }, title, icon, orders, amount)
        })}
      </Box>
    );
  };

  renderDiscountCard = (cardBgColor: {primary:string,secondary:string},cardLabel:string,cardIcon:string,orderQty:string| number,amountValue:string|number) => {
    return (
      <DiscountCard
        key={`${cardLabel}-discountCard`}
        style={{ backgroundColor: cardBgColor.primary }}
      >
        <Box id="firstBox" className='firstBox' style={{ backgroundColor:cardBgColor.secondary }}>
          <TrucatedTextWithTooltip
            charCount={26}
            classNames='discountTitle'
            text={cardLabel}
          />
          <img src={cardIcon} alt={`${cardLabel}-sales`}></img>
        </Box>
        <Box id="overLastBox" className='lastBox'>
          <Box id="section1" className='section'>
            <Typography id="section1Title" className='title'>{configJSON.ordersTitle}</Typography>
            <Typography id="section1Value" className='value'>{orderQty}</Typography>
          </Box>
          <Box id="section2" className='section'>
            <Typography id="section2Title" className='title'>{configJSON.amountTitle}</Typography>
            <TrucatedTextWithTooltip classNames='value' text={`SAR ${amountValue}`} charCount={9} maxWidth="95px"/>
          </Box> 
        </Box>
      </DiscountCard>
    )
  }

  renderAllStore = () => {
    const { storewiseDiscountData } = this.state;
    return (
      <AllStoresContainer>
        <Title style={{ paddingLeft: "4px" }}>{configJSON.allStoreTitle}</Title>
        <div id="scrollableDiv" data-test-id="store-wise-discout-section" style={{ height: 500, overflow: "auto" }}>
        {!this.state.getStorewiseDiscountLoading ? 
          <InfiniteScroll
            dataLength={storewiseDiscountData.length}
            next={this.fetchMoreData}
            hasMore={this.state.storeWiseDiscountMetaInfo.totalCount !== this.state.storewiseDiscountData.length}
            loader={this.state.storeWiseDiscountMetaInfo.totalCount !== this.state.storewiseDiscountData.length && <Skeleton data-test-id="skeleton_storeData" variant="rect" width={"100%"} height={214} />}
            scrollableTarget="scrollableDiv"
          >
            {storewiseDiscountData.map((storeObj, inde) => {
              const storeDiscountCreditCards = [
                {
                  title: "General Discount",
                  icon: assets.generalDiscountIcon,
                  orders: storeObj.general_discount.orders_count,
                  amount: storeObj.general_discount.amount,
                },
                {
                  title: "Campaign Discount",
                  icon: assets.campaignDiscountIcon,
                  orders: 0,
                  amount: "0.00",
                },
                {
                  title: "Loyalty Credit",
                  icon: assets.loyaltyCreditIcon,
                  orders: storeObj.loyalty_credit.orders_count,
                  amount: storeObj.loyalty_credit.amount,
                },
                {
                  title: "Compensation Credit",
                  icon: assets.compensationCreditIcon,
                  orders: storeObj.compensation_credit.orders_count,
                  amount: storeObj.compensation_credit.amount,
                },
                {
                  title: "Compensation Paid",
                  icon: assets.compensationPaidIcon,
                  orders: 0,
                  amount: "0.00",
                },
              ];
          return (
            <Box className='boxContainer' key={`${inde}-store`}>
              <Box className='hoverBox'>
                <Typography className='storeTitle'>{storeObj.store_name}</Typography>
                <Box className='overAllContainer gap-4'>
                  {storeDiscountCreditCards.map((card) => {
                    const { title, icon, orders, amount } = card;
                    return this.renderAllStoreDiscountCard(title, icon, orders, amount);
                  })}
                </Box>
              </Box>
              <Divider className='storeDivider' />
            </Box>
          );
        })}
          </InfiniteScroll>
          : <Skeleton data-test-id="skeleton_storeData" variant="rect" width={"100%"} height={500} />
        }
        </div>
      </AllStoresContainer>
    );
  };

  renderAllStoreDiscountCard = (cardLabel:string,cardIcon:string,orderQty:string|number,orderAmount:string|number) => {
    return (
      <DiscountCard key={`${cardLabel}-store-Card`}>
        <Box className='firstBox'>
          <TrucatedTextWithTooltip
            text={cardLabel}
            charCount={26}
            classNames='discountTitle'
          />
          <img src={cardIcon}></img>
        </Box>
        <Box className='lastBox'>
          <Box className='section'>
            <Typography className='title'>{configJSON.ordersTitle}</Typography>
            <Typography className='value'>{orderQty}</Typography>
          </Box>
          <Box className='section'>
            <Typography className='title'>{configJSON.amountTitle}</Typography>
            <Typography className='value'>SAR {orderAmount}</Typography>
          </Box>
        </Box>
      </DiscountCard>
    )
  }

  renderBullet = (bulletColor:string,label:string) => {
    return(
      <Box className="bulletContainer">
        <Box className="bulletRage" style={{background:bulletColor}} />
        <Typography className="label-12-400">{label}</Typography>
      </Box>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <Box>
          <DashboardTabPanel navigation={this.props.navigation} id="DiscountCreditSection" permissionStatus={this.state.permissionStatus} />
          <SectionWrapper
            id={sortCondition(this.state.isOpenExportModal, "print-wrapper", undefined) as string}
          >

            <Box>
              <Box id="header" className="headerRowOne">
                <Box id="hs" className="headerRowOneSubCont">
                  <FlexiTypo id="t1" Weight={700} FS={"16px"}>
                    Region : {" "}
                    <StyledSpan FS={"16px"} Weight={400} ColorTxt={colors().darkjunglegreen}>
                      {this.state.regionName}
                    </StyledSpan>
                  </FlexiTypo>
                  <FlexiTypo className="ellispseStore" FS={"16px"} Weight={700} id="t2">
                    Store : {" "}
                    <StyledSpan Weight={400} FS={"16px"} ColorTxt={colors().darkjunglegreen}>
                    {storeNameBasedOnGroup(this.state.storeId)}
                    </StyledSpan>
                  </FlexiTypo>
                  <FlexiTypo className="ellispseStore" FS={"16px"} Weight={700} id="t2">
                    Group : {" "}
                    <StyledSpan Weight={400} FS={"16px"} ColorTxt="#0F172A">
                    {getGroupNameDisplay(this.state.groupId)}
                    </StyledSpan>
                  </FlexiTypo>
                </Box>
                <Box className="headerActionCont">
                  <div id="filterDas" data-test-id="filterButton" onClick={(event) => { this.handleFilterOpen(event) }}>
                    <LoadingComponent
                      disableParentWidth
                      isLoading={
                        this.isLoading()
                      }
                      height={44}
                      width={44}
                      isAllowed={true}
                      children={
                    <IconButton id="btn1" className={sortStringCondition(
                      this.state.isAppliedFilter,
                      "filterBtn active-border",
                      "filterBtn"
                    )}>
                      <FilterIcon color={this.handleReturnColorType()} />
                      {renderBaseOnConditions(
                        this.state.isAppliedFilter,
                        <div className="filterActiveIcon"></div>,
                        <></>
                      )}
                    </IconButton>
                      } />
                  </div>
                  
                  <button className="exportBtn" type="button" onClick={() => this.handleExportModalStats()}>
                    {configJSON.exportAll}
                  </button>

                  <FilterPopover
                    onClose={() => { this.handleCloseFilterPopover() }}
                    anchor={this.state.filterAnchor}
                    data-test-id="filterPopover"
                    filters={this.state.filters}
                    onFilterChange={this.handleFilterChangeAccount}
                  />
                </Box>
              </Box>

              <Box id="h2" className="headerRowTwo">
                <FlexiTypo Weight={700} FS={"16px"}>
                  Period : {" "}
                  <StyledSpan Weight={400} FS={"16px"} ColorTxt={colors().darkjunglegreen}>
                    {this.handleDateFilterParam().startDateText} _ {this.handleDateFilterParam().endDateText}
                  </StyledSpan>
                </FlexiTypo>
              </Box>
            </Box>

            {this.renderOverall()}
            <Box className="section2">
              <Box style={{ width: "40%" }} className="containerGraph">
                <Typography className="label-19-500">
                  Discount amount <span className="label-12-400">(General  + Campaign) in SAR</span>
                </Typography>
                <Box className="pieGraphWrapper">
                  <Box className="pieGraph">
                    <Chart
                      chartType='PieChart'
                      width='120px'
                      height='200px'
                      data={[
                        ["Task", "Count"],
                        ["Male", 85],
                        ["Female", 45]
                      ]}
                      options={{ ...graphOptions, backgroundColor: "#F8FAFC" }}
                    />
                    <Box className='pieHoleTextBig'>
                      <Typography className="label-19-500" align="center">{"0"}</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className="bulletWrapper">
                  {this.renderBullet("#BAADEA", "General Discount")}
                  {this.renderBullet("#AAAD5C", "Campaign Discount")}
                </Box>
              </Box>
              <Box style={{ width: "60%" }} className="containerGraph">
                <Typography className="label-19-500">
                  Credit amount <span className="label-12-400">(Loyalty Credit + Compensation Credit + Compensation Paid) in SAR</span>
                </Typography>
                <Box className="pieGraphWrapper">
                  <Box className="pieGraph">
                    <Chart
                      chartType='PieChart'
                      width='120px'
                      height='200px'
                      data={[
                        ["Task", "Count"],
                        ["Loyalty  Credit", 85],
                        ["Compensation Credit", 45],
                        ["Compensation Paid", 58]
                      ]}
                      options={{ ...graphOptions, backgroundColor: "#F8FAFC", colors: ["#DDC39E", "#AEC5CD", "#ADDAAF"] }}
                    />
                    <Box className='pieHoleTextBig'>
                      <Typography className="label-19-500" align="center">{"0"}</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className="bulletWrapper">
                  {this.renderBullet("#DDC39E", "Loyalty  Credit")}
                  {this.renderBullet("#AEC5CD", "Compensation Credit")}
                  {this.renderBullet("#ADDAAF", "Compensation Paid")}
                </Box>
              </Box>
            </Box>
            {this.renderAllStore()}
          </SectionWrapper>
        </Box>
                  
        {
          this.state.isOpenExportModal && <ExportModal isOpen onCancel={this.handleExportModalStats} onConfirm={this.handleExportConfirm} />
        }

      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const Title = styled(Typography)({
  fontSize: "16px",
  fontWeight: 700,
  fontFamily: "Montserrat",
  lineHeight: "24px",
  color: `${colors().darkjunglegreen}`,
  "& .currencyType": {
    fontWeigth: "400 !important",
  }
})

const SectionWrapper = styled(Box)({
  flexDirection: "column",
  display: "flex",
  gap: 20,
  paddingBottom: 20,
  "& .filterBtn": {
    position: "relative",
    height: "44px",
    width: "44px",
    borderRadius: "8px",
    backgroundColor: "#fff",
    border: "1px solid #ECECEC",
    "& .MuiSvgIcon-root": {
      color: colors().darkliver
    },
    "&.active-border": {
      borderColor: colors().cyancobaltblue,
    },
    "& .filterActiveIcon": {
      position: "absolute",
      background: "#204B9C",
      top: "-5px",
      right: "-5px",
      minWidth: "15px",
      minHeight: "15px",
      borderRadius: "50%"
    },
  },
  "& .headerActionCont": {
    display: "flex",
    gap: "18px"
  },
  "& .exportBtn": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    background: colors().lightgray,
    fontWeight: 600,
    cursor: "pointer",
    height: "44px",
    border: 0,
    padding: "10px 16px",
    fontSize:"16px",
    color: colors().darkliver,
  },
  "& .ellispseStore":{
    "maxWidth": "380px",
    "textOverflow": "ellipsis",
    "display": "block",
    "whiteSpace": "nowrap",
    "overflow": "hidden"
  },
  "& .headerRowOne": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline"
  },
  "& .headerRowOneSubCont": {
    display: "flex",
    gap: "30px"
  },
  "& .headerRowTwo": {
  },
  "& .pieGraph": {
    position: 'relative',
    width: "120px",
    minWidth: "100px"
  },
  "& .pieHoleTextBig":{
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%"
  },
  "& .bulletRage":{
    width:"12px",
    height:"12px",
    borderRadius:"50%"
  },
  "& .bulletWrapper":{
    display:"flex",
    alignItems:"center",
    gap:"12px"
  },
  "& .bulletContainer":{
    display:"flex",
    alignItems:"center",
    gap:"8px"
  },
  "& .label-19-500":{
    fontSize: "19px",
    fontWeight: 500,
    fontFamily: "Montserrat",
  },
  "& .label-12-400":{
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "Montserrat",
  },
  "& .containerGraph":{
    padding:"16px",
    borderRadius:"12px",
    border: "1px solid #ECECEC",
    boxSizing:"border-box",
    background: "#F8FAFC",
    minHeight: "247px",
    "display": "flex",
    "flexDirection": "column",
    "justifyContent": "space-between"
  },
  "& .section2":{
    display:"flex",
    gap:"22px"
  },
  "& .overAllContainer": {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    gap: "15px",
    overflowX: "scroll",
    "&.gap-4": {
      gap: 4
    },
    "&::-webkit-scrollbar": {
      width: "3px",
      height: "3px"
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "5px"
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555"
    }
  },
  "&#print-wrapper": {
    "& .headerActionCont": {
        display:"none"
    }
}
})

const DiscountCard = styled(Box)({

  width: "20%",
  minWidth:"190px",
  backgroundColor: `${colors().brightgray}`,
  borderRadius: "12px",
  "& .firstBox": {
    borderRadius: "12px",
    backgroundColor: `${colors().brightgray}`,
    display: "flex",
    flexDirection: "row",
    gap: "15px",
    padding: "15px 12px 15px 15px",
    alignItems: "center",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    height: "68px",
    width: "100%",
    "& .discountTitle": {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "22.4px",
      color: `${colors().darkjunglegreen}`,
      fontFamily: "Montserrat",
    },
    "& img": {
      height: "24px",
      width: "24px",
    }
  },
  "& .lastBox": {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    padding: "15px 20px 15px 15px",
    gap: "10px",
    "& .section": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "7px",
      "& .title": {
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        color: `${colors().darkliver}`,
        fontFamily: "Montserrat",
      },
      "& .value": {
        color: `${colors().darkjunglegreen}`,
        fontSize: "18px",
        fontWeight: 500,
        lineHeight: "22.8px",
        fontFamily: "Montserrat"
      }
    }
  }
})

const AllStoresContainer = styled(Box)({
  border: `1px solid ${colors().lightborder}`,
  borderRadius: "12px",
  display: "flex",
  width: "100%",
  flexDirection: "column",
  marginBottom: "20px",
  padding: "12px 8px",
  "& .boxContainer": {
    display: "flex",
    marginTop: "15px",
    flexDirection: "column",
    gap: "15px",
    "& .hoverBox": {
      padding: "15px 14px",
      borderRadius: "12px",
      backgroundColor: "rgba(233, 237, 245, 0.5)",
      "& .storeTitle": {
        fontSize: "16px",
        marginBottom: "15px",
        fontWeight: 600,
        lineHeight: "19.2px",
        fontFamily: "Montserrat",
      },
    }
  },
  "& .ProgressContainer": {
    margintop: "16px",
    padding: "16px 32px 0",
    display: "flex",
    gap: "115px",
    flexDirection: "row",
    alignItems: "center",
    "& .circularProgress": {
      position: "absolute",

      transform: "rotate(40deg) !important",
    },
    "& .circleLabel": {
      lineHeight: "25px",
      position: "absolute",
      fontFamily: "Montserrat",
      fontSize: "22px",
      color: `${colors().darkjunglegreen}`,
      fontWeight: 500,
    }
  }
})

const graphOptions = {
  pieHole: 0.7,
  legend: "none",
  colors: ["#BAADEA", "#AAAD5C"],
  is3D: false,
  enableInteractivity: true,
  pieSliceText: "none",
  chartArea: { 'width': '100%', 'height': '100%' }
};

// Customizable Area End