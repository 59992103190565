// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { makeApiMessage as MakeApiMessage } from '../../../components/src/common'
import { IUserContext } from "../../../../packages/blocks/navigationmenu/src/PageContainerController.web";
import { ClassNameMap } from "@material-ui/styles";
import { navigateTo } from "../../../blocks/utilities/src/CustomBlockHelpers";

export const configJSON = require("./config");

export interface Props {
  classes: ClassNameMap,
   navigation: {
    navigate: Function,
    goBack:()=>void,
    getParam: Function;
  };
}
interface RegionAttributes {
  id: number;
  branch_name: string;
  name: string;
}

export interface RegionData {
  id: string;
  type: string;
  attributes: RegionAttributes;
}

interface AreaAttributes {
  id: number;
  area_name: string;
}

export interface AreaData {
  id: string;
  type: string;
  attributes: AreaAttributes;
}
interface StoreManagementAttributes {
  id: number;
  store_name: string;
  name_translation: string;
}

export interface StoreManagementData {
  id: string;
  type: string;
  attributes: StoreManagementAttributes;
}
export interface Region {
  id: string;
  type: string;
  attributes: {
      id: number;
      branch_name: string;
      name: string;
  };
}

export interface Area {
  id: string;
  type: string;
  attributes: {
      id: number;
      area_name: string;
  };
}

export interface StoreManagement {
  id: string;
  type: string;
  attributes: {
      id: number;
      store_name: string;
      name_translation: string;
  };
}
interface StoreGroupAttributes {
  id: number;
  name: string;
  second_name: string;
  active: boolean;
  created_at: string;
  updated_at: string;
  regions: {
      data: Region[];
  };
  areas: {
      data: Area[];
  };
  store_managements: {
      data: StoreManagement[];
  };
}

export interface StoreGroupData {
  id: string;
  type: string;
  attributes: StoreGroupAttributes;
}

interface S {
  storeGroupInfo: StoreGroupData;
  initialGroupInfo:StoreGroupData;
  isLoading:boolean;
}
export interface OptionType {
  id: number ;
  name: string;
}

interface SS {
  id: string | number;
}
// Customizable Area End

export default class StoreGroupAddController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start

  // Customizable Area End

  constructor(props: Props) {
    // Customizable Area Start

    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.LayoutDataMessage)
    ];

    this.state = {
      storeGroupInfo:{} as StoreGroupData,
      initialGroupInfo: {} as StoreGroupData,
      isLoading: false
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  detailMessageId: string = "";

  async receive(from: string, message: Message) {
    const requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const successResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(requestId === this.detailMessageId && successResponse?.data){
      let data = successResponse?.data
      this.setState({
        initialGroupInfo:data,
        storeGroupInfo:data,
        isLoading: false
      });
    }

    this.receiveDataFromLayout(message)

  }
  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    const id = this.props.navigation.getParam("id");
    if (id) {
      this.getStoreGroupDetail();
    }
  }

  receiveDataFromLayout = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.LayoutMessageData)
        );
        if (recievedData.userContext) {
          this.handleUserChange(recievedData.userContext)
        }
    }
  }

  getStoreGroupDetail = () => {
    this.setState({isLoading:true})
    const id = this.props.navigation.getParam("id");
    let requestMessage = MakeApiMessage({
      url: configJSON.saveStoreGroup +'/'+ id,
      headers: { token: localStorage.getItem("token") },
      method: "get",
    });
    this.detailMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCancelClick = () => {
    this.props.navigation.goBack();
  };

  handleUserChange = (userContext: IUserContext) => {
    
  };
  handleEditGroup(storeId: string){
    this.props.navigation.navigate("StoreGroupEdit",{
      id: storeId,
    });
  }

  handleGoBack = () => {
    navigateTo({ 
      props: this.props,
      screenName: "StoreGroupList",
    })
  }

  // Customizable Area End
}