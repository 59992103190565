// Customizable Area Start
import React from "react";
import { Box, styled, TablePagination, Snackbar } from "@material-ui/core";
import StoreListController, { Props } from "./StoreListController.web";
import CashierHeader from "../../../components/src/CashierHeader";
import { colors } from "../../utilities/src/Colors";
import { IFilter } from "../../../components/src/FilterPopover";
import {
  TableRow,
  TableCell,
  OrderTable,
  RowContainer,
} from "../../../components/src/OrderTable/src";
const IconSortingUp = require("../../../components/src/assets/sort/sort top.png");
const IconSortingNormal = require("../../../components/src/assets/sort/sort.png");
const IconSortingDown = require("../../../components/src/assets/sort/sort down.png");
const configJSON = require("./config");
const Strings = configJSON.StoreList.Strings;
import Alert from "@material-ui/lab/Alert";

// Customizable Area End

class StoreList extends StoreListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  incomingTransactionTableHeader() {
    return (
      <RowContainer header>
        <TableRow columns={"repeat(7, 1fr)"} className='table-cols'>
          {[
            {
              title: Strings.storeID,
              sortingKey: "store_id",
            },
            {
              title: Strings.store,
              sortingKey: "store_name",
            },
            {
              title: Strings.DateOfPostingCash,
              sortingKey: "date_of_posting_cash",
            },
            {
              title: Strings.salesPcs,
              sortingKey: "sales_pcs",
            },
            {
              title: Strings.salesAmount,
              sortingKey: "sale_amount",
            },
            {
              title: Strings.revenueAmount,
              sortingKey: "revenue_amount",
            },
            {
              title: Strings.declaredCash,
              sortingKey: "declare_cash",
            },
          ].map((label: { title: string; sortingKey: string }, index) => (
            <TableCell
              contentFontSize='13px'
              header
              content={this.getHeaderRender(label, index)}
              className='align-item-center'
              sortingIcons={[IconSortingNormal, IconSortingUp, IconSortingDown]}
              sortingKey={label.sortingKey}
              sortingDisabled={!Boolean(label.sortingKey)}
              {...this.getSortingProps()}
            />
          ))}
        </TableRow>
      </RowContainer>
    );
  }

  incomingTransactionTableBody() {
    return (
      <RowContainer>
        {this.state.storeList?.length === 0 && (
          <TableRow
            key={"NoDataTableRow"}
            className='table-rows-norecord'
            columns='1fr'
          >
            <TableCell
              type='none'
              className='w-100'
              content={
                <Box className='no_records'>
                  {configJSON.stringsListPage_noRecordsFound}
                </Box>
              }
            />
          </TableRow>
        )}
        {this.state.storeList?.slice(0,this.props.listLimit).map((item, index) => {
          const {
            store_id,
            store_name,
            posting_cash_date,
            sales_pcs,
            sale_amount,
            revenue_amount,
            declare_cash,
          } = item || {};
          return (
            <Box className='mainrow'>
              <TableRow columns={"repeat(7, 1fr)"} className='table-rows'>
                {[
                  <StyleContent>{store_id}</StyleContent>,
                  <StyleContent>{store_name}</StyleContent>,
                  <DateStyleContent>
                     {this.handleMomentDateFormat(
                      posting_cash_date,
                      "DD-MM-yyyy - h:mm A"
                    )}
                  </DateStyleContent>,
                  <StyleContent>{sales_pcs}</StyleContent>,
                  <StyleContent>SAR {sale_amount}</StyleContent>,
                  <StyleContent>SAR {revenue_amount}</StyleContent>,
                  <StyleContent>SAR {declare_cash}</StyleContent>,
                ].map((value, index, array) => (
                  <TableCell
                    content={(<>{value}</>) as React.ReactNode}
                    type={this.getTableCellType(index, array.length)}
                  />
                ))}
              </TableRow>
            </Box>
          );
        })}
      </RowContainer>
    );
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { onlyTable } = this.props;
    // Customizable Area End

    return (
      // Customizable Area Start
        <StyledWrapper>
          <MainBodyBox>
            {!onlyTable && <CashierHeader
              handleBack={()=>this.props.goBack(this.state.filters)}
              handleFilterButton={this.handleFilterButton}
              title='All stores'
              handleCloseFilterButton={this.handleCloseFilterButton}
              isTransferAmount={false}
              handleFilterChange={this.handleFilterChange}
              filters={this.state.filters as IFilter[]}
              filterAnchor={
                this.state.filterBtnAnchor as HTMLDivElement | undefined
              }
              periodDateRange={this.getPeriodDate(
                this.handleDateFilterParam().startDateText,
                this.handleDateFilterParam().endDateText
              )}
              isArrowRender={true}
              handleExport={this.handleClickExport}
            />}
            <Box id='tableContainer' className='tableContainer' style={{height: "500px", overflowY:"auto"}}>
              <OrderTable
                minWidth={1380}
                minWidth1024={887}
                minWidth1280={1109}
                minWidth1366={1184}
              >
                {this.incomingTransactionTableHeader()}
                {this.incomingTransactionTableBody()}
              </OrderTable>
              {this.state.storeList?.length > 0 && !onlyTable && (
                <TablePagination
                  count={this.state.totalCount}
                  className='tablePagination'
                  page={this.state.page - 1}
                  data-test-id='pagination'
                  rowsPerPageOptions={[10]}
                  rowsPerPage={this.state.pageSize}
                  component='div'
                  onPageChange={(
                    _event: React.MouseEvent<HTMLElement> | null,
                    page: number
                  ) => this.handlePageChange(page)}
                />
              )}
            </Box>
          </MainBodyBox>
          <Snackbar
            anchorOrigin={{
              vertical: configJSON.top,
              horizontal: configJSON.center,
            }}
            autoHideDuration={2000}
            open={this.state.snackbar.open}
            onClose={this.handleCloseSnackbar}
          >
            <Alert severity={this.state.snackbar.severity}>
              {this.state.snackbar.message}
            </Alert>
          </Snackbar>
        </StyledWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export const StyledWrapper = styled("div")({
  "& .tableContainer": {
    flex: "1 1 0",
  },
  "& .headerWrapper": {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginBottom: 12,
    alignItems: "center",
  },
  "& .PageHeading": {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: colors().darkjunglegreen,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
    },
  },
  "& .datePeriod": {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
  },
  "& .d-flex": {
    display: "flex",
  },
  "& .filterButtonAccount": {
    marginRight: "24px",
    cursor: "pointer",
    background: colors().white,
    height: "44px",
    color: colors().darkliver,
    width: "44px",
    fontSize: "24px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid",
    borderColor: colors().lightborder,
    "@media only screen and (max-width: 1024px)": {
      height: 44,
      width: 44,
    },
  },
  "& .addButton": {
    textTransform: "unset",
    fontFamily: "Montserrat",
    background: colors().cyancobaltblue,
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: colors().white,
    height: "44px",
    width: "194px",
    borderRadius: "8px",
    "&:hover": {
      background: colors().cyancobaltblue,
      color: colors().white,
    },
    "@media only screen and (max-width: 1024px)": {
      height: 44,
    },
  },
  "& .exportAll": {
    marginRight: "24px",
    textTransform: "unset",
    fontFamily: "Montserrat",
    background: "#E2E8F0",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "20px",
    color: colors().darkliver,
    height: "44px",
    width: "111px",
    borderRadius: "8px",
    "&:hover": {
      background: "#E2E8F0",
      color: colors().darkliver,
    },
    "@media only screen and (max-width: 1024px)": {
      height: 44,
    },
  },
  "& .table": {
    minWidth: 700,
  },
  "& .noRecordFound": {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: 600,
  },
  "& .tableRow": {
    background: colors().cyancobaltblue,
    color: colors().white,
  },
  "& .print-icon": {
    right: 0,
    bottom: 0,
    width: 26,
    margin: 16,
    cursor: "pointer",
    textAlign: "right",
    position: "absolute",
  },
  "& .edit-icon": {
    right: "35px",
    bottom: 0,
    width: 26,
    margin: 16,
    cursor: "pointer",
    textAlign: "right",
    position: "absolute",
  },
  "& .editbox": {
    right: "50px",
    bottom: 10,
    cursor: "pointer",
    textAlign: "right",
    position: "absolute",
  },
  "& .table-cols": {
    marginTop: "20px",
  },
  "& .table-rows > div:last-child": {
    position: "relative",
  },
  "& .table-rows > div:nth-last-child(2)": {
    position: "relative",
  },
  "& .table-rows > div": {
    fontSize: "12px",
    textAlign: "left",
    minHeight: "100px",
    textTransform: "capitalize",
    fontWeight: "500 !important",
  },
  "& .confAmountInput": {
    border: "1px solid #ECECEC",
    height: "44px",
    width: "101px",
    padding: "11px 8px",
    boxSizing: "border-box",
    borderRadius: "8px 0px 0px 8px",
  },
  "& .confAmountSubmitBtn": {
    height: "44px",
    padding: "10px 16px 10px 16px",
    width: "93px",
    borderRadius: "0px 8px 8px 0px",
    background: "#204B9C",
    color: "#fff",
    textTransform: "none",
  },
  "& .table-rows-norecord > div": {
    fontSize: "12px",
    textAlign: "left",
    minHeight: "50px",
    textTransform: "capitalize",
    fontWeight: "500 !important",
  },
  "& .tablePagination": {
    overflow: "unset",
  },
  "& .no_records": {
    width: "100%",
    fontSize: 16,
    fontWeight: 700,
    textAlign: "center",
  },
  "& .w-100": {
    width: "100%",
  },
  "& .mainrow": {
    cursor: "pointer",
  },
});

const MainBodyBox = styled(Box)({
  marginTop: "20px",
});

const StyleContent = styled("span")({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "16px",
  color: colors().viewTextColor,
  "@media only screen and (max-width: 1024px)": {
    fontSize: "12px",
  },
});

const DateStyleContent = styled("span")({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "16px",
  color: colors().viewTextColor,
  width: "150px",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "12px",
  },
});

export default StoreList;
// Customizable Area End
