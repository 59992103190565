// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { DetailResponse } from "./B2bBusinessAccountAddController";
import { toString } from "lodash";
import { makeApiMessage } from "../../../components/src/common";
import { navigateTo, sortStringCondition, PermissionStatus, checkForNewPermissonStatus, customPermissionApiKey } from "../../utilities/src/CustomBlockHelpers";
import moment from "moment";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");

export type BusinessAccount = {
  id?: string
  company_name: string
  sub_company_name: string
  business_customer: string
  business_account_no: string
  business_address: string
  contract_person: string
  email: string
  phone_no: string
  purchase_order: string
  sales_order: string[]
  cost_center: string
  sub_cost_center: string
  business_start_date: string
  business_end_date: string
  contract_manager_name: string
  contract_manager_regions: string
  contract_manager_email: string
  contract_manager_phone_no: string
  region_ids: number[]
  area_ids: number[]
  store_management_ids: number[]
  regions: string[]
  areas: string[]
  stores: string[]
  auto_renewal: string
  catalogue: string
  no_of_groups: string[]
  no_of_items_per_order: string
  total_no_of_order_for_contract: string
  order_period_type_for_contract: string
  no_of_pieces_per_order: string
  total_no_of_pieces_for_contract: string
  pieces_period_type_for_contract: string
  groupConfigs: {
    id: string
    "no_of_order": string,
    "order_period_type": string,
    "pieces_period_type": string,
    "business_customer_group": {
      "id": number,
      "group_name": string
    },
    "price_list": {
      "id": number,
      "name": string
    } | null,
    "item_pics_configurations": {
      product_master_id: number
      name: string
      no_of_pieces: number | null,

    }[]
  }[]
  available_time: string
  available_days: string[]
}

const defaultAccountData: BusinessAccount = {
  company_name: "",
  sub_company_name: "",
  business_customer: "",
  business_account_no: "",
  business_address: "",
  contract_person: "",
  contract_manager_name: "",
  contract_manager_regions: "",
  contract_manager_email: "",
  contract_manager_phone_no: "",
  cost_center: "",
  sub_cost_center: "",
  email: "",
  phone_no: "",
  purchase_order: "",
  sales_order: [],
  business_start_date: "",
  business_end_date: "",
  region_ids: [],
  area_ids: [],
  store_management_ids: [],
  regions: [],
  areas: [],
  stores: [],
  auto_renewal: "",
  catalogue: "",
  no_of_groups: [],
  no_of_items_per_order: "",
  total_no_of_order_for_contract: "",
  order_period_type_for_contract: "",
  total_no_of_pieces_for_contract: "",
  pieces_period_type_for_contract: "",
  no_of_pieces_per_order: "",
  groupConfigs: [],
  available_time: "",
  available_days: []

}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: {
    getParam: (param: string) => string | undefined
  };
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accountData: BusinessAccount
  errorMessage: string
  loading: boolean
  permissionStatus: PermissionStatus
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ViewBusinessAccountController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  businessAcountIdParam = this.props.navigation.getParam("navigationBarTitleText")
  businessAccountDetailCallId = ""
  getRegionsCallId = ""
  getStoresCallId = ""
  getAreasCallId = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.LayoutDataMessage)
    ];
    // Customizable Area End

    // Customizable Area Start
    this.state = {
      accountData: defaultAccountData,
      errorMessage: "",
      loading: true,
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      }
    }
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.businessAcountIdParam && this.getBusinessAccountDetail(this.businessAcountIdParam)
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.businessAccountDetailCallId:
          this.parseBusinessAccountDetail(responseJson)
          break;
        case this.getRegionsCallId:
          const regionResponse = responseJson.data || []
          this.setState((prev) => ({
            accountData: {
              ...prev.accountData,
              regions: prev.accountData.region_ids
                .map(regionId => regionResponse.find((region: { attributes: { id: number; name: string } }) => region.attributes.id === regionId)?.attributes.name)
                .filter(Boolean)
            }
          }))
          break;
        case this.getAreasCallId:
          const areaResponse = responseJson.data || []
          this.setState((prev) => ({
            accountData: {
              ...prev.accountData,
              areas: prev.accountData.area_ids
                .map(areaId => areaResponse.find((area: { attributes: { id: number; area_name: string } }) => area.attributes.id === areaId)?.attributes.area_name)
                .filter(Boolean)
            }
          }))
          break;
        case this.getStoresCallId:
          const storeResponse = responseJson.data || []
          this.setState((prev) => ({
            accountData: {
              ...prev.accountData,
              stores: prev.accountData.store_management_ids
                .map(storeId => storeResponse.find((store: { attributes: { id: number; store_name: string } }) => store.attributes.id === storeId)?.attributes.store_name)
                .filter(Boolean)
            }
          }))
          break;
        default:
          break;
      }
    }
    this.retrieveLayoutInfo(message)
    // Customizable Area End
  }

  // Customizable Area Start
  getBusinessAccountDetail = (businessAcountId: string) => {
    const apiMessage = makeApiMessage({
      method: configJSON.httpGetMethod,
      url: configJSON.apiEndPoints.businessAccount + `/${businessAcountId}`
    })
    this.businessAccountDetailCallId = apiMessage.messageId
    this.send(apiMessage)
  }

  parseBusinessAccountDetail = (response: DetailResponse) => {
    if (!response.data) {
      this.setState({
        errorMessage: "unable to fetch business account detail",
        loading: false
      })
      return;
    }
    const responseData = response.data.attributes
    const {
      company,
      contract_manager,
      contract_email,
      contract_phone_no,
      business_available_times,
      account_group_configurations,
      price_list,
      auto_renewal,
      no_of_items_per_order,
      no_of_pieces_per_order,
      business_start_date,
      business_end_date,
      total_no_of_order_for_contract,
      total_no_of_pieces_for_contract,
      ...rest
    } = responseData;
    const formValue: BusinessAccount = {
      ...defaultAccountData,
      ...rest,
      id: response.data.id,
      company_name: company.name,
      available_time: business_available_times.length ? `${this.formatTime(business_available_times[0].start_time)} to ${this.formatTime(business_available_times[0].end_time)}` : "",
      contract_manager_name: toString(contract_manager?.attributes.full_name),
      contract_manager_phone_no: toString(contract_manager?.attributes.full_phone_number),
      contract_manager_email: toString(contract_manager?.attributes.email),
      contract_manager_regions: toString(contract_manager?.attributes.regions.map(region => region.branch_name).join(", ")),
      business_start_date: this.formatDate(business_start_date),
      business_end_date: this.formatDate(business_end_date),
      no_of_groups: account_group_configurations.data.map(
        groupConfig => groupConfig.attributes.business_customer_group.group_name
      ),
      groupConfigs: account_group_configurations.data.map(
        groupConfig => ({
          ...groupConfig.attributes,
          id: groupConfig.id,
          no_of_order: toString(groupConfig.attributes.no_of_order?.toLocaleString("en-US")),
          item_pics_configurations: groupConfig.attributes.item_pics_configurations.map(item => ({
            product_master_id: item.attributes.product_master.id,
            name: item.attributes.product_master.name,
            no_of_pieces: item.attributes.no_of_pieces
          }))
        })
      ),
      catalogue: toString(price_list?.name),
      auto_renewal: sortStringCondition(auto_renewal, "ON", "OFF"),
      no_of_items_per_order: toString(no_of_items_per_order?.toLocaleString("en-US")),
      no_of_pieces_per_order: toString(no_of_pieces_per_order?.toLocaleString("en-US")),
      total_no_of_order_for_contract: toString(total_no_of_order_for_contract?.toLocaleString("en-US")),
      total_no_of_pieces_for_contract: toString(total_no_of_pieces_for_contract?.toLocaleString("en-US")),
    }

    this.setState({ accountData: formValue, loading: false }, () => {
      this.getRegions()
      this.getAreas(formValue.region_ids)
      this.getStores(formValue.area_ids)
    })
  }

  getOptions = (
    apiCallId:
      "getRegionsCallId"
      | "getAreasCallId"
      | "getStoresCallId"
    ,
    endPoint: string
  ) => {
    const message = makeApiMessage({
      url: endPoint,
      method: "GET"
    })
    this[apiCallId] = message.messageId
    this.send(message)
  }

  getRegions = () => {
    this.getOptions("getRegionsCallId", configJSON.apiEndPoints.regionList)
  }

  getAreas = (regionIds: number[]) => {
    if (regionIds.length) {
      this.getOptions("getAreasCallId", configJSON.apiEndPoints.areaList + regionIds.join(","))
    }
  }

  getStores = (areaIds: number[]) => {
    if (areaIds.length) {
      this.getOptions("getStoresCallId", configJSON.apiEndPoints.b2bStoresList + areaIds.join(","))
    }
  }

  formatTime = (inputTime: string) => {
    if (!inputTime) return null;
    const parsedTime = moment.parseZone(inputTime);
    return parsedTime.format("hh:mm A");
  }

  formatDate = (inputDate: string) => inputDate ? moment(inputDate).format("DD/MM/YYYY") : ""

  goToEdit = () => {
    if (this.businessAcountIdParam && this.businessAcountIdParam === this.state.accountData.id) {
      navigateTo({
        id: this.state.accountData.id,
        screenName: "B2bBusinessAccountEdit",
        props: this.props
      })
    }
  }

  goBack = () => {
    navigateTo({
      id: this.state.accountData.id,
      screenName: "B2bBusinessAccountList",
      props: this.props
    })
  }

  retrieveLayoutInfo = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
      const recievedData = message.getData(
        getName(MessageEnum.LayoutMessageData)
      );
      if(recievedData.userContext) {
        this.processUserChange(recievedData.userContext)
      }
    }
  }

  processUserChange  = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.b2bBusinessAccountPermission;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value,
    })
  };

  // Customizable Area End
}