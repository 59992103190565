// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import React from "react";
import { IMyUser } from "./utils";
import PageContainerWebAdapter from "../../adapters/src/PageContainerWebAdapter";

export const configJSON = require("./config");
export interface IUserContext {
  isSuperAdmin: boolean;
  user: IMyUser | undefined;
  refreshUser: () => void;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: unknown;
  id?: string;
  hideActivePassiveUsers?: boolean;
  children: React.ReactNode;
  onOpenedChanged?: (opened: boolean) => void;
  onDrawerWidthChanged?: (width: number) => void;
  onSearchText?: (searchText: string) => void;
  handleInputChange?: Function;
  onUserChange?: (context: IUserContext) => void;
  afterPermissionComponent?: React.ReactNode;
  afterSearchComponent?: React.ReactNode;
  onRegionChange?: (regionId: string, regionName: string) => void;
  onMultiRegionChange?: (regionId: string, regionName: string,isSelectedAll?: boolean) => void;
  onStoreChange?: (regionId: string, storeName: string, isSelectedAll: boolean) => void;
  onStoreAndGroupChange?: (storeId: string, storeName: string, isSelectedAll: boolean,groupId:string,groupName: string) => void;
  resposniveDrawer?: {"1024":string,"1280":string,"1366":string}
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class PageContainerController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  pageAdapter: PageContainerWebAdapter;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    // Customizable Area End

    // Customizable Area Start
    this.pageAdapter = new PageContainerWebAdapter();

    this.state = {
     
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
   
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End
}
