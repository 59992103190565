// Customizable Area Start
import React from "react";
import CatalogueCarController, { Props } from "./CatalogueCardController.web";
import { Box, styled, Tooltip, withStyles } from "@material-ui/core";
import { colors } from "../../../blocks/utilities/src/Colors";
import {
  button_minus_dark,
  button_minus_light,
  button_plus_dark,
  sub_product_icon,
  NoImagePlaceholder,
} from "./assets";
import { CatalogueVariantsServices } from "../../../components/src/Interface";
import { renderBaseOnConditions } from "../../../blocks/utilities/src/CustomBlockHelpers";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export default class CatalogueCard extends CatalogueCarController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  showCatalogueName = (fullName: string) => {
    if (fullName.length >= 11) {
      return (
        <CatalogueTooltip
          title={<p className='catalogue-name'>{fullName}</p>}
          arrow
        >
          <span className='catalogue-name'>{fullName.slice(0, 11)}...</span>
        </CatalogueTooltip>
      );
    } else {
      return <p className='catalogue-name'>{fullName}</p>;
    }
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const catalogueVariant = this.props.catalogue?.attributes;
    const catalogueVariantData = this.props.catalogue?.attributes
      ?.catalogue_variants?.data[0]?.attributes;
    const services = this.state.services.filter(
      (service) => service.service.active
    );
    const isSubProductAvailable = catalogueVariant?.sub_products?.data?.length
      ? true
      : false;
    const isParentProduct = catalogueVariant?.product_type_id === "Parent";
    // Customizable Area End
    return (
      // Customizable Area Start
      (services.length > 0 || isParentProduct ) && (
        <CatalogueCardBox
          data-test-id='product-card-component'
          onClick={() => !isSubProductAvailable && this.handleClickOnCard()}
        >
          <Box className='catalogue-image-wrapper'>
            {
              catalogueVariantData?.image?.image ? 
              <img
              src={catalogueVariantData?.image?.image}
              className='catalogue-image'
              alt='catalogue-image'
              />
              :
              <div className='catalogue-image-label'><span className='catalogue-name' style={{fontSize: "12px", whiteSpace: "wrap"}}>{catalogueVariant?.product_name}</span></div>
            }
          </Box>
          <Box className='mt-8'>
          { catalogueVariantData?.image?.image ?
            this.showCatalogueName(catalogueVariant?.product_name)
            :
            <div style={{height: "15px"}}></div>
          }
            {renderBaseOnConditions(
              isSubProductAvailable,
              <Box className='subproduct-tag'>
                <img
                  src={sub_product_icon}
                  alt='catalogue-image'
                  width={18}
                  height={18}
                />
                <div
                  onClick={() => this.handleClickOnCard()}
                  className={`viewprodbtn`}
                  data-test-id='view-btn-product'
                >
                  View Products
                </div>
              </Box>,
              <></>
            )}
            {renderBaseOnConditions(
              !isParentProduct && !this.state.isB2bActive,
              <p className='catalogue-price'>SAR {this.getCataloguePrice()}</p>,
              <></>
            )}
          </Box>
          {renderBaseOnConditions(
            !isParentProduct,
            <Box className='catalogue-action-button mt-8'>
              <button className='catalogue-qty-button'>
                <img
                  src={
                    this.props?.selectedProductQuantity < 1
                      ? button_minus_light
                      : button_minus_dark
                  }
                  alt='qty-button'
                  data-test-id={`catalogue-decrease-qty-${catalogueVariant.id}`}
                  onClick={this.removeQuaitityHandler}
                />
              </button>
              <span data-test-id={`field-quantity-${catalogueVariant.id}`}>
                {this.props.selectedProductQuantity}
              </span>
              <button className='catalogue-qty-button'>
                <img
                  src={button_plus_dark}
                  alt='qty-button'
                  data-test-id={`catalogue-increase-qty-${catalogueVariant.id}`}
                  onClick={this.addQuaitityHandler}
                />
              </button>
            </Box>,
            <></>
          )}
          {renderBaseOnConditions(
            !isParentProduct,
            <Box
              className='catalogue-services-wrap mt-8'
              data-test-id={`service-wrap-${catalogueVariant.id}`}
            >
              {services?.length > 0 &&
                services.map((item: CatalogueVariantsServices) => {
                  const service = item.service;
                  const icon = item.service.icon.data?.attributes.image;
                  const measurments = catalogueVariantData?.measurement_type as string[];
                  let price: string | number = this.getCataloguePriceBasedOnCatalogueService(item, measurments);
                  return (
                    <Box
                      className='catalogue-service-icon'
                      data-test-id={`catalogue-service-${service.id}`}
                      onClick={() => this.handleSelectService(service)}
                    >
                      <CatalogueTooltip
                        title={<p>SAR {price}</p>}
                        arrow
                      >
                        <img src={icon} alt='service-icon' />
                      </CatalogueTooltip>
                      <Box
                        className={
                          this.props.selectedServiceId === service.id
                            ? "active-service"
                            : "deactivate-service"
                        }
                      />
                    </Box>
                  );
                })}
            </Box>,
            <></>
          )}
        </CatalogueCardBox>
      )
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CatalogueCardBox = styled(Box)({
  border: `1px solid ${colors().lightborder}`,
  padding: "4px",
  boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  maxWidth: "105px",
  minHeight: "154px",
  width: "100%",
  borderRadius: "8px",
  "& .mt-8": {
    margin: "8px 0 0 0",
  },
  "& .catalogue-image-wrapper": {
    position: "relative",
    width: "100%",
    textAlign: "center",
  },
  "& .subproduct-tag": {
    textAlign: "center",
    marginTop: "13px"
  },
  "& .viewprodbtn": {
    backgroundColor: colors().antiFlashWhite,
    color: colors().slateGray,
    width: "76px",
    height: "38px",
    borderRadius: 8,
    fontSize: 11,
    fontWeight: 600,
    cursor: "pointer",
    padding: "5px",
    marginTop: "10px",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "9px",
      width: "60px",
      height: "36px",

    },
  },
  "& .catalogue-image": {
    width: "52px",
    height: "52px",
    objectFit: "cover",
    borderRadius: "8px",
  },
  "& .catalogue-image-label": {
    height: "52px",
    display: "flex",
    justifyContent:"center",
    alignItems: "center"
  },
  "& .catalogue-name": {
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "center",
    fontFamily: "Montserrat",
    fontWeight: 500,
    color: colors().darkjunglegreen,
    maxWidth: "70px",
    overflow: "hidden",
    cursor: "pointer",
    whiteSpace: "nowrap",
    "@media only screen and (max-width: 1280px)": {
      fontSize: "10px",
      maxWidth: "65px",
    },
    "@media only screen and (max-width: 1024px)": {
      fontSize: "9px",
      maxWidth: "50px",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  "& .catalogue-price": {
    fontSize: "12px",
    lineHeight: "18px",
    textAlign: "center",
    fontFamily: "Montserrat",
    fontWeight: 400,
    color: colors().slateGray,
    "@media only screen and (max-width: 1280px)": {
      fontSize: "10px",
    },
    "@media only screen and (max-width: 1024px)": {
      fontSize: "9px",
    },
  },
  "& .catalogue-qty-button": {
    outline: "none",
    border: "none",
    cursor: "pointer",
    margin: "0 8px",
    "&:hover": {
      backgroundColor: "transparent",
    },
    "& > img": {
      width: "18px",
    },
    "@media only screen and (max-width: 1280px)": {
      "& > img": {
        width: "16px",
      },
    },
    "@media only screen and (max-width: 1024px)": {
      "& > img": {
        width: "14px",
      },
    },
  },
  "& .catalogue-action-button": {
    display: "flex",
    alignItems: "center",
    fontSize: "13px",
    "@media only screen and (max-width: 1280px)": {
      fontSize: "13px",
    },
    "@media only screen and (max-width: 1024px)": {
      fontSize: "9px",
    },
  },
  "& .catalogue-qty": {
    fontSize: "14px",
    lineHeight: "22px",
    fontFamily: "Montserrat",
    fontWeight: 400,
    color: colors().darkjunglegreen,
  },
  "& .catalogue-services-wrap": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "4px",
    flexWrap: "wrap",
  },
  "& .catalogue-service-icon": {
    cursor: "pointer",
    "& img": {
      width: "20px",
      height: "20px",
      objectFit: "cover",
      "@media only screen and (max-width: 1280px)": {
        width: "16px",
        height: "16px",
      },
      "@media only screen and (max-width: 1024px)": {
        width: "14px",
        height: "14px",
      },
    },
  },
  "& .active-service": {
    height: "2px",
    margin: "2px 0 0 0",
    background: colors().cyancobaltblue,
  },
  "& .deactivate-service": {
    height: "2px",
    margin: "2px 0 0 0",
  },
  "@media only screen and (max-width: 1280px)": {
    maxWidth: "95px",
  },
  "@media only screen and (max-width: 1024px)": {
    maxWidth: "75px",
  },
});

export const CatalogueTooltip = withStyles({
  tooltip: {
    color: "black",
    fontSize: "12px",
    backgroundColor: "white",
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 8px 32px 0px, rgba(0, 0, 0, 0.03) 0px 4px 8px 0px",
  },
  arrow: {
    color: "white",
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 8px 32px 0px, rgba(0, 0, 0, 0.03) 0px 4px 8px 0px",
  },
})(Tooltip);
// Customizable Area End
