// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  CustomerPreferece,
  CustomerPreferencesData,
  CustomerSpecification,
  CustomerUpcharge,
  IChargeListItem,
  IlaundryOrder,
  PreferemcesItem,
} from "../../../components/src/Interface";
import { makeApiMessage } from "../../../components/src/common";
import moment from "moment";
// Customizable Area End

// Customizable Area Start

export interface MapPreferencesData {
  order_items_preference_id: number;
  product_name: string;
  service_name: string;
  service_icon: string;
  height: string;
  width: string;
  weight: string;
  specifications: Array<CustomerSpecification>;
  upcharge_list: Array<CustomerUpcharge>;
  preferece_list: Array<CustomerPreferece>;
}

interface SelectedSpecification {
  [key: string]: { id: number; option: string }[];
}

export interface SelectedPreferenceData {
  order_items_id: number;
  order_items_preference_id: number;
  selected_specification: Array<CustomerSpecification>;
  selected_specifications: SelectedSpecification;
  selected_upcharges: Array<number>;
  temp_selected_upcharges: Array<number>;
  selected_preference: number;
}

export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: unknown;
  id: string;
  laundryOrder: IlaundryOrder | null;
  open: boolean;
  handleClosePopup: Function;
  handleOnSavePreference: Function;
  isCfPlantAdjustmentscreen: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  customerPreferencesData: Array<CustomerPreferencesData>;
  preferencesMapData: Array<MapPreferencesData>;
  selectedPreferencesData: Array<SelectedPreferenceData>;
  commonUpchargeList: Array<CustomerUpcharge>;
  commonPreferencesList: Array<CustomerPreferece>;
  lineItemPrefrencesList: Array<PreferemcesItem>;
  isEditPreferenceLoading: boolean;
  selectedCommonPreference: number | null;
  selectedCommonUpcharges: number[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class EditPreferenceController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPreferencesApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    // Customizable Area End

    // Customizable Area Start
    this.state = {
      customerPreferencesData: [],
      preferencesMapData: [],
      selectedPreferencesData: [],
      commonUpchargeList: [],
      commonPreferencesList: [],
      isEditPreferenceLoading: false,
      selectedCommonPreference: null,
      selectedCommonUpcharges: [],
      lineItemPrefrencesList: []
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getCustomerPreferecesApi();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      this.handleApiResponse(message);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleApiResponse = (message: Message) => {
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (
      apiRequestCallId &&
      responseJson &&
      !responseJson?.errors &&
      !responseJson?.error
    ) {
      if (apiRequestCallId === this.getPreferencesApiCallId) {
        this.handlePreEditPreferencesData(responseJson.data);
      }
    }
  };

  handleAssignSpecification = (
    specifications: { [key: string]: string | [string]} | null,
    customerSpecifications: CustomerSpecification[]
  ) => {
    if (specifications !== null) {
      const result: Record<string, { id: number; option: string }[]> = {};

      for (const keyVal in specifications) {
        let selectedValues = specifications[keyVal];
        if(!Array.isArray(selectedValues)) {
          selectedValues = [selectedValues]
        }

        const spec = customerSpecifications.find((spec) => spec.name === keyVal);

        if (spec) {
          const selectedOptions = spec.options_attributes
            .filter((option) => selectedValues.includes(option.label))
            .map((option) => ({
              id: option.id,
              option: option.label,
            }));

          result[keyVal] = selectedOptions;
        } else {
          result[keyVal] = [];
        }
      }

      return result;
    } else {
      return {}
    }
  };

  removeDuplicatesUpchares = (upchargeList: CustomerUpcharge[]) => {
    const uniqueItems = upchargeList.filter(
      (item, index, self) =>
        index === self.findIndex((temp) => temp.id === item.id)
    );
    return uniqueItems;
  };

  handleCommonUpchargeList = (
    upchargeList: CustomerUpcharge[],
    selectedUpcharges: IChargeListItem[]
  ) => {
    const { commonUpchargeList, selectedCommonUpcharges } = this.state;

    const upchargeIds = selectedUpcharges.map((item) => item.attributes.id);
    
    const filtered = [...new Set([...commonUpchargeList, ...upchargeList])];
    const selectedFilterd = [...new Set([...selectedCommonUpcharges, ...upchargeIds])];

    const newFilteredArray = filtered.map((item) => {
      if(selectedFilterd.includes(item.id)) {
        item.checked = true;
      }  else {
        item.checked = false;
      }
      return item;
    });

    this.setState({
      commonUpchargeList: this.removeDuplicatesUpchares(newFilteredArray),
      selectedCommonUpcharges: selectedFilterd
    });
  };

  removeDuplicatesPreferences = (preferencesList: CustomerPreferece[]) => {
    const uniqueItems = preferencesList.filter(
      (item, index, self) =>
        index === self.findIndex((temp) => temp.id === item.id)
    );
    return uniqueItems;
  };

  handleSelectCommonPrefernce = () => {
    const { lineItemPrefrencesList } = this.state;
    const allIds = lineItemPrefrencesList.map((item) => item.attributes.id);
    let isAllSame = allIds.every((item) => item === allIds[0]);
    if(isAllSame) {
      this.setState({
        selectedCommonPreference: allIds[0]
      });
    }
  };
  
  handleCommonPreferencesList = (
    preferencesList: CustomerPreferece[],
    selectedPreference: PreferemcesItem,
    isLastIndex: boolean
  ) => {
    const { commonPreferencesList, lineItemPrefrencesList } = this.state;

    const filtered = [
      ...new Set([...commonPreferencesList, ...preferencesList]),
    ];

    this.setState({
      commonPreferencesList: this.removeDuplicatesPreferences(filtered),
      lineItemPrefrencesList: [...lineItemPrefrencesList, selectedPreference]
    }, () => {
      if(isLastIndex) {
        this.handleSelectCommonPrefernce();
      };
    });
  };

  handlePreEditPreferencesData = (response: CustomerPreferencesData) => {
    const { laundryOrder } = this.props;
    let tempMapDataArray: Array<MapPreferencesData> = [];
    let tempSelectedDataArray: Array<SelectedPreferenceData> = [];

    if (response) {
      if (laundryOrder) {
        laundryOrder.order_items.forEach((orderItem) => {
          response.attributes.customer_preferences_attributes.forEach(
            (prefereceAtt) => {
              if (
                Number(prefereceAtt.id) ===
                orderItem.attributes.id
              ) {
                if (orderItem.attributes.order_item_preferences.length > 0) {
                  orderItem.attributes.order_item_preferences.forEach(
                    (orderItemPreference, index) => {
                      // Handle make list for common upcharge
                      this.handleCommonUpchargeList(
                        prefereceAtt.attributes.upcharge_lists,
                        orderItemPreference.attributes.upcharges
                      );

                      // Handle set common preferences
                      this.handleCommonPreferencesList(
                        prefereceAtt.attributes.preferences,
                        orderItemPreference.attributes.preference,
                        index === orderItem.attributes.order_item_preferences.length - 1
                      );

                      const selectDataObj = {
                        order_items_id: orderItem.attributes.id,
                        order_items_preference_id:
                          orderItemPreference.attributes.id,
                        selected_specification: prefereceAtt.attributes.specifications,
                        selected_specifications: this.handleAssignSpecification(
                          orderItemPreference.attributes.specifications,
                          prefereceAtt.attributes.specifications
                        ),
                        selected_upcharges:
                          orderItemPreference.attributes.upcharges.map(
                            (upcharge_id) => upcharge_id.attributes.id
                          ),
                        temp_selected_upcharges: [],
                        selected_preference:
                          orderItemPreference.attributes.preference.attributes
                            .id,
                      };
                      tempSelectedDataArray.push(selectDataObj);

                      // This is object create to mapping data
                      const mapDataObj = {
                        order_items_preference_id:
                          orderItemPreference.attributes.id,
                        product_name: orderItem.attributes.catalogue.name,
                        height: orderItem.attributes.height,
                        width: orderItem.attributes.width,
                        weight: orderItem.attributes.weight,
                        service_name:
                          orderItem.attributes.service.attributes.name,
                        service_icon:
                          orderItem.attributes.service.attributes.icon.data
                            .attributes.image,
                        specifications: prefereceAtt.attributes.specifications,
                        upcharge_list: prefereceAtt.attributes.upcharge_lists,
                        preferece_list: prefereceAtt.attributes.preferences,
                      };
                      tempMapDataArray.push(mapDataObj);
                    }
                  );
                }
              }
            }
          );
        });
      }
    }

    this.setState({
      preferencesMapData: tempMapDataArray,
      selectedPreferencesData: tempSelectedDataArray,
      isEditPreferenceLoading: false,
    });
  };

  getCustomerPreferecesApi = () => {
    this.setState({
      isEditPreferenceLoading: true,
    });
    const { laundryOrder } = this.props;

    const varIds = laundryOrder?.order_items
      .map((item) => item.attributes.customer_preference_id)
      .filter((item) => item)
      .map((Iitem) => `&ids[]=${Iitem}`);

    const apiUrl =
      configJSON.getCustomerPreferenceListAPIEndPoint +
      `${laundryOrder?.account_id}${varIds}&allow_access=true&order_id=${laundryOrder?.id}`;

    let message = makeApiMessage({
      url: apiUrl,
      method: "GET",
    });
    this.getPreferencesApiCallId = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  renderPreferenceRadioVal = (order_id: number, preference_id: number) => {
    const { selectedPreferencesData } = this.state;
    const selected_data = selectedPreferencesData.find(
      (selectedData) => selectedData.order_items_preference_id == order_id
    );
    if (selected_data?.selected_preference == preference_id) {
      return true;
    } else {
      return false;
    }
  };

  handlePreferenceSelect = (preference_id: number, order_id: number) => {
    const { selectedPreferencesData } = this.state;
    const tempData = [...selectedPreferencesData];
    const selectedIndex = tempData.findIndex(
      (tempdata) => tempdata.order_items_preference_id === order_id
    );
    tempData[selectedIndex].selected_preference = preference_id;
    this.setState({ selectedPreferencesData: tempData, selectedCommonPreference: null });
  };

  renderUpchargeSelectionVal = (upcharge_id: number, order_id: number) => {
    const { selectedPreferencesData } = this.state;
    const selected_data = selectedPreferencesData.find(
      (selectedData) => selectedData.order_items_preference_id == order_id
    );
    if (selected_data?.selected_upcharges.includes(upcharge_id)) {
      return true;
    } else {
      return false;
    }
  };

  handleSelectUpcharge = (upcharge_id: number, order_id: number) => {
    const { selectedPreferencesData } = this.state;
    const tempData = [...selectedPreferencesData];
    const selectedIndex = tempData.findIndex(
      (tempdata) => tempdata.order_items_preference_id === order_id
    );
    const isAlreadyAdded =
      tempData[selectedIndex].selected_upcharges.includes(upcharge_id);
    if (isAlreadyAdded) {
      const newFilterUpcharge = tempData[
        selectedIndex
      ].selected_upcharges.filter((upcharge) => upcharge !== upcharge_id);
      tempData[selectedIndex].selected_upcharges = newFilterUpcharge;
    } else {
      tempData[selectedIndex].selected_upcharges.push(upcharge_id);
    }
    this.setState({
      selectedPreferencesData: tempData,
    }, () => this.handleCheckIsAllHaveSameUpcharge());
  };

  handleCheckIsAllHaveSameUpcharge = () => {
    const { selectedCommonUpcharges, commonUpchargeList } = this.state;

    const newArray = selectedCommonUpcharges.filter((item) =>
      this.handleCheckInPrefrences(item)
    );

    const tempCommonUpcharges = JSON.parse(
      JSON.stringify(commonUpchargeList)
    ) as CustomerUpcharge[];

    if (newArray?.length == 0) {
      tempCommonUpcharges.forEach((common) => {
        common.checked = false;
      });
    } else {
      tempCommonUpcharges.forEach((common) => {
        if (newArray?.includes(common.id)) {
          common.checked = true;
        } else {
          common.checked = false;
        }
      });
    }

    this.setState({
      commonUpchargeList: tempCommonUpcharges,
      selectedCommonUpcharges: newArray,
    });
  };

  handleCheckInPrefrences = (upchargeNo: number) => {
    const { selectedPreferencesData } = this.state;
    let isHere = false;
    selectedPreferencesData.forEach((data) => {
      if(data.selected_upcharges.includes(upchargeNo)) {
        isHere = true;
      };
    });
    return isHere;
  }

  handleSelectionUpchargeFromDropdown = (
    values: number[],
    order_id: number
  ) => {
    const { selectedPreferencesData } = this.state;
    const tempData = [...selectedPreferencesData];
    const selectedIndex = tempData.findIndex(
      (tempdata) => tempdata.order_items_preference_id === order_id
    );
    values.forEach((value) => {
      if (tempData[selectedIndex].selected_upcharges.includes(value)) {
        const tempFilterArr = tempData[selectedIndex].selected_upcharges.filter(
          (array) => array !== value
        );
        tempData[selectedIndex].selected_upcharges = tempFilterArr;
      } else {
        tempData[selectedIndex].selected_upcharges.push(value);
      }
    });
    this.setState({
      selectedPreferencesData: tempData,
    });
  };

  handleSpecificationVal = (specification_id: number, order_id: number) => {
    const { selectedPreferencesData } = this.state;
    const selected_data = selectedPreferencesData.find(
      (selectedData) => selectedData.order_items_preference_id == order_id
    );

    let tempId = undefined;
    selected_data?.selected_specification.forEach((selectedSpec) => {
      if (selectedSpec.id === specification_id) {
        const selected_attribute = selectedSpec.options_attributes.find(
          (option) => option.selected
        );
        tempId = selected_attribute?.id;
      }
    });
    return tempId ? tempId : "";
  };

  handleOnSelectSpecification = (
    order_id: number,
    specification_id: number,
    value: { id: number; option: string }[],
    selectedOption: { id: number; option: string }
  ) => {
    const { selectedPreferencesData } = this.state;
    const tempSelectedPreferencesData = JSON.parse(
      JSON.stringify(selectedPreferencesData)
    ) as SelectedPreferenceData[];
    let selected_data = tempSelectedPreferencesData.find(
      (selectedData) => selectedData.order_items_preference_id == order_id
    );
    let selected_data_index = tempSelectedPreferencesData.findIndex(
      (selectedData) => selectedData.order_items_preference_id == order_id
    );
    let tempSpecification = [
      ...(selected_data?.selected_specification as CustomerSpecification[]),
    ];

    const newArray = tempSpecification.map((tempSpec) => {
      if (tempSpec.id === specification_id) {
        if (selected_data) {
          const isValueContained = value.some(
            (item) => item.id === selectedOption.id
          );
          if (isValueContained) {
            selected_data.selected_specifications[tempSpec.name] = value.filter(
              (item) => item.id !== selectedOption.id
            );
          } else {
            selected_data.selected_specifications[tempSpec.name] = [
              ...value,
              selectedOption,
            ];
          }
        }
      }
      return tempSpec;
    });
    tempSpecification = newArray;
    tempSelectedPreferencesData[selected_data_index].selected_specification =
      tempSpecification;
    this.setState({
      selectedPreferencesData: tempSelectedPreferencesData,
    });
  };

  handleSelectedSpecificationVal = (
    specification_name: string,
    order_id: number
  ) => {
    const { selectedPreferencesData } = this.state;
    const selected_data = selectedPreferencesData.find(
      (selectedData) => selectedData.order_items_preference_id == order_id
    );

    if (selected_data) {
      if (selected_data.selected_specifications[specification_name]) {
        return selected_data.selected_specifications[specification_name];
      } else {
        return [];
      }
    }
  };

  onSavePreferences = () => {
    const { selectedPreferencesData } = this.state;
    this.props.handleOnSavePreference(selectedPreferencesData);
  };

  handleCheckedUncheckedUpcharge = (
    tempChecked: CustomerUpcharge,
    tempselected: SelectedPreferenceData
  ) => {
    if (tempChecked.checked) {
      if (!tempselected.selected_upcharges.includes(tempChecked.id)) {
        tempselected.selected_upcharges.push(tempChecked.id);
      }
    } else {
      if (tempselected.temp_selected_upcharges.includes(tempChecked.id)) {
        return;
      } else {
        const updateId = tempselected.selected_upcharges.filter(
          (updateId) => updateId !== tempChecked.id
        );
        tempselected.selected_upcharges = updateId;
      }
    }
  };

  handleChangeCommonUpchargeChecked = () => {
    const { selectedCommonUpcharges, commonUpchargeList } = this.state;
    const tempCommonUpchargeList = JSON.parse(
      JSON.stringify(commonUpchargeList)
    ) as CustomerUpcharge[];

    if (selectedCommonUpcharges?.length == 0) {
      tempCommonUpchargeList.forEach((common) => {
        common.checked = false;
      });
    } else {
      tempCommonUpchargeList.forEach((common) => {
        if (selectedCommonUpcharges?.includes(common.id)) {
          common.checked = true;
        } else {
          common.checked = false;
        }
      });
    }

    this.setState(
      {
        commonUpchargeList: tempCommonUpchargeList,
      },
      () => this.handleCheckedInOrderItems()
    );
  };

  handleCheckedInOrderItems = () => {
    const { commonUpchargeList, preferencesMapData, selectedPreferencesData } =
      this.state;

    let tempSelectedData = JSON.parse(
      JSON.stringify(selectedPreferencesData)
    ) as SelectedPreferenceData[];

    const updatedData = tempSelectedData.map((tempselected) => {
      preferencesMapData.forEach((mapData) => {
        if (
          mapData.order_items_preference_id ==
          tempselected.order_items_preference_id
        ) {
          const allPresentUpcharge = mapData.upcharge_list.map(
            (upcharge) => upcharge.id
          );
          commonUpchargeList.forEach((tempChecked) => {
            if (allPresentUpcharge.includes(tempChecked.id)) {
              this.handleCheckedUncheckedUpcharge(tempChecked, tempselected);
            }
          });
        }
      });
      return tempselected;
    });

    this.setState({
      selectedPreferencesData: updatedData,
    });
  };

  handleSelectAllUpchargeSelect = () => {
    const { commonUpchargeList, selectedCommonUpcharges } = this.state;
    const allCommonUpchargeIds = commonUpchargeList.map(
      (upcharge) => upcharge.id
    );

    if (allCommonUpchargeIds.length === selectedCommonUpcharges.length) {
      this.setState(
        {
          selectedCommonUpcharges: [],
        },
        () => this.handleChangeCommonUpchargeChecked()
      );
    } else {
      this.setState(
        {
          selectedCommonUpcharges: allCommonUpchargeIds,
        },
        () => this.handleChangeCommonUpchargeChecked()
      );
    }
  };

  handleSingleCommonUpchargeSelect = (values: unknown[]) => {
    const { selectedCommonUpcharges } = this.state;
    let tempSelectedUpcharge = JSON.parse(
      JSON.stringify(selectedCommonUpcharges)
    ) as number[];

    values.forEach((value) => {
      if (tempSelectedUpcharge.includes(value as number)) {
        const filtered = tempSelectedUpcharge.filter((temp) => temp !== value);
        tempSelectedUpcharge = filtered;
      } else {
        tempSelectedUpcharge.push(value as number);
      }
    });

    this.setState(
      {
        selectedCommonUpcharges: tempSelectedUpcharge,
      },
      () => this.handleChangeCommonUpchargeChecked()
    );
  };

  handleChangeCommonUpcharge = (values: unknown[]) => {
    if (values.includes("selectAll")) {
      // set common upcharge unchecked/checked for select all
      this.handleSelectAllUpchargeSelect();
    } else {
      // set common upcharge unchecked/checked
      this.handleSingleCommonUpchargeSelect(values);
    }
  };

  handleReturnDataHaveValue = (value: number) => {
    const { preferencesMapData } = this.state;
    const filterData = preferencesMapData.map((data) => {
      const preferenceIds = data.preferece_list.map((listIds) => listIds.id);
      if (preferenceIds.includes(value)) {
        return data.order_items_preference_id;
      }
    });
    return filterData;
  };

  handleChangeCommonPreference = (value: number) => {
    const { selectedPreferencesData } = this.state;
    // Find the data which includes this preferences id
    const dataHaveValue = this.handleReturnDataHaveValue(value);

    // Find the data whose data we have to update
    const selectedOrders = selectedPreferencesData.filter((selected) =>
      dataHaveValue.includes(selected.order_items_preference_id)
    );

    // Change the preference for all items
    const tempSelectedOrders = JSON.parse(
      JSON.stringify(selectedOrders)
    ) as SelectedPreferenceData[];
    tempSelectedOrders.forEach((data) => {
      data.selected_preference = value;
    });

    // set the data into state
    this.setState({
      selectedCommonPreference: value,
      selectedPreferencesData: tempSelectedOrders,
    });
  };

  getMomentFormat(dateStr: Date | string) {
    return moment(dateStr).format("DD/MM/YYYY");
  }

  getSpecificationOptionList = (
    data: Array<{
      id: number;
      label: string;
      selected: boolean;
    }>
  ) => {
    const optionlist = data.map((spec) => {
      return {
        id: spec.id,
        option: spec.label,
      };
    });
    return optionlist;
  };
  // Customizable Area End
}
