import { IFilter } from "../../../components/src/FilterPopover";
import _ from "lodash";

export interface ISubPermissionGroup {
  id: number;
  name: string;
  permitted: boolean;
}
export interface IPermissionGroup {
  id: number;
  name: string;
  permitted: boolean;
  permission_group_key: string;
  permission_sub_groups: Array<ISubPermissionGroup>;
}

export interface IAccountsGroup {
  id: number;
  name: string;
}

export interface IRoleData {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    active: boolean;
    accounts_count: string;
    role_permissions_count: number;
    permission_groups: IPermissionGroup[];
    accounts: IAccountsGroup[];
    gallery_icon: {
      id: number;
      image: string;
    };
  };
}

export interface RolesList {
  data: IRoleData[];
}

export interface IPermissionListingData {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
  };
}

export interface ISnackbar {
  message: string;
  open: boolean;
  severity: "success" | "warning" | "error" | "info";
}

export interface IMeta {
  next_page: number;
  pervious_page: number;
  total_pages: number;
  total_count: number;
  current_page: number
}

export interface GetIconsResponse {
  id: string;
  type: string;
  attributes: {
    id: number;
    image: string;
    name: string;
    second_name: string;
    image_type: string;
  };
}

export interface GetIcons {
  id: number;
  item: React.ReactNode;
  title: string;
  attributes: {
    image: string;
    name: string;
    gallery_icon: {
      id: string;
      image: string;
    };
  };
}

export const checkFilterSorting = (filters: IFilter[], index: number) => {
    if (filters) {
        if (filters[index]?.value === "ASC") {
            return "asc"
        }
        return filters[index]?.value === "DESC" ? "desc" : ""
    }
    return ""
}

export const handleHeaderClick = (filters: IFilter[], handleSetFilterDataOnSortClick: (updatedFilters:IFilter[])=>void) => {
    const updatedFilters = _.cloneDeep(filters);
    const permissionFilterIndex = updatedFilters.findIndex(
      (filter) => filter.title === "Permission"
    );

    if (permissionFilterIndex !== -1) {
      updatedFilters[permissionFilterIndex].value = "";
      handleSetFilterDataOnSortClick(updatedFilters)
    }
  }
