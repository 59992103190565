// Customizable Area Start
import React from "react";
import { Box, Button, styled } from "@material-ui/core";
import { FilterList as FilterIcon } from "@material-ui/icons";
import FilterPopover, { IFilter } from "../../components/src/FilterPopover";
import Add from "@material-ui/icons/Add";
const arrowBack = require("./arrow_back.png");
import { renderBaseOnConditions, sortStringCondition } from "../../blocks/utilities/src/CustomBlockHelpers";
// Customizable Area End

// Customizable Area Start

interface Props {
  title: string;
  handleFilterButton: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  handleCloseFilterButton: () => void;
  handleFilterChange: (filters: IFilter[]) => void;
  handleNavigateTransfterAmt?: () => void;
  filterAnchor: HTMLDivElement | undefined;
  filters: IFilter[];
  isTransferAmount: boolean;
  periodDateRange: string;
  isArrowRender?: boolean;
  handleBack?: () => void;
  handleExport?: () => void;
  isAppliedFilter?: boolean;
}
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
const CashierHeader: React.FC<Props> = (props) => {
  const {
    filters,
    filterAnchor,
    handleFilterButton,
    handleCloseFilterButton,
    handleFilterChange,
    handleNavigateTransfterAmt,
    isTransferAmount,
    title,
    periodDateRange,
    isArrowRender,
    handleBack,
    handleExport,
    isAppliedFilter = false
  } = props;

  const handleReturnColorType = () => {
    return isAppliedFilter ? "primary" : "inherit";
  };

  return (
    <>
      <Box className='headerWrapper'>
        <TitleDiv>
          {isArrowRender && (
            <img
              onClick={handleBack}
              className='backarrow'
              data-test-id='back-btn'
              src={arrowBack}
            />
          )}
          <h2 className='PageHeading'>{title}</h2>
        </TitleDiv>
        <Box className='d-flex'>
          <div
            data-test-id='filterBtn'
            className={sortStringCondition(
              isAppliedFilter,
              "filterButtonAccount active-border",
              "filterButtonAccount"
            )}
            onClick={handleFilterButton}
          >
            <FilterIcon color={handleReturnColorType()} />
            {renderBaseOnConditions(
              isAppliedFilter,
              <div className="filterActiveIcon"></div>,
              <></>
            )}
          </div>
          <FilterPopover
            onClose={handleCloseFilterButton}
            anchor={filterAnchor}
            onFilterChange={handleFilterChange}
            filters={filters}
          />
          <Button
            onClick={handleExport}
            data-test-id='export-all'
            className='exportAll'
          >
            Export all
          </Button>
          {isTransferAmount && (
            <Button
              onClick={handleNavigateTransfterAmt}
              data-test-id='transfer-amount'
              className='addButton'
            >
              <Add /> <StylesSpan>Transfer Amount</StylesSpan>
            </Button>
          )}
        </Box>
      </Box>
      <Box>
        <h2 className='PageHeading'>
          Period : <span className='datePeriod'>{periodDateRange}</span>{" "}
        </h2>
      </Box>
    </>
  );
};

const StylesSpan = styled("span")({
  marginLeft: "5px",
});

const TitleDiv = styled("span")({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  "& .backarrow": {
    cursor: "pointer",
  },
});

export default CashierHeader;
// Customizable Area End
