import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Grid,
  TablePagination,
  styled,
  Dialog,
  Checkbox,
  Tooltip
} from "@material-ui/core";

import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DroppableProvided,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";

import {
  createTheme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import { Check, DragHandle } from "./assets";

import ServicesListController, {
  Props,
  configData,
  ServiceListType
} from "./ServicesListController.web";

import "./PreferenceList.css";

import ConfirmationModal from "../../../components/src/customComponents/ConfirmationModal.web";
import { Skeleton } from "@material-ui/lab";

import ThreeDotDropdown from "../../../components/src/ThreeDotDropdown";
import Loader, {LoadingComponent} from "../../../components/src/Loader.web";
import styles from "./ServiceWebStyles";
import FilterButton from "../../../components/src/customComponent/filterButton.web";
import VerticalServiceTabs from "../../../components/src/customComponent/verticalTabsService.web";

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat, sans-serif",
    fontWeightLight: 500,
    fontWeightBold: 700,
    fontWeightMedium: 600,
    fontSize: 12,
    h1: {
      fontSize: 24,
    },
    h2: {
      fontSize: 20,
    },
    h3: {
      fontSize: 16,
    },
  },
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

import CustomArrowIcon from "../../../components/src/customComponents/CustomArrowIcon.web";
import {renderBaseOnConditions, sortCondition} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { colors } from "../../../blocks/utilities/src/Colors";
import { MainButtonComponent } from "../../../components/src/customComponents/CustomTags.web";

// Customizable Area End

 class ServicesList extends ServicesListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.handleDragEnd = this.handleDragEnd.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start

  renderServiceGroupByModal = () => {
    const {
      openSectionGroupModal,
      serviceDropdownList,
      clickedServiceRowData,
      selectedServiceGroupId
    } = this.state;
    return (
      <ServiceGroupByModal
        maxWidth='xs'
        open={openSectionGroupModal}
        onClose={this.handleCloseGroupModal}
      >
        <div className='modal-wrapper'>
          <h5 className='modal-heading'>Group With : {clickedServiceRowData?.name}</h5>
          <p className='modal-para'>
            Choose the services you want to group with this service.
          </p>
          <div className='modal-area-wrapper'>
            {serviceDropdownList.map((service) => {
              return (
                <div className='modal-list-item'>
                  <p>{service.attributes.name}</p>
                  <StyledCheckbox
                    onClick={() =>
                      this.handleCheckedService(String(service.attributes.id))
                    }
                    data-test-id={`check-service-${service.id}`}
                    checked={selectedServiceGroupId.includes(
                      String(service.attributes.id)
                    )}
                  />
                </div>
              );
            })}
          </div>
          <div className='button-block'>
            <MainButtonComponent
              className='buttonSecondary'
              data-test-id="cancel-modal"
              onClick={this.handleCloseGroupModal}
            >
              Cancel
            </MainButtonComponent>
            <MainButtonComponent
              className='btnSubNext'
              data-test-id="save-modal"
              onClick={this.handleCallServiceGroupAPI}
            >
              Save
            </MainButtonComponent>
          </div>
        </div>
      </ServiceGroupByModal>
    );
  };

  render() {

    return (
      <ThemeProvider theme={theme}>
        <DragDropContext
          data-test-id='dragDropContext'
          onDragEnd={this.handleDragEnd}
        >
          <>
            <ServicesListWrapper className={`margin parentContainer pageWrapper`}>
              <Loader loading={this.state.loading} />
              <Box className="titleContainer">
                <Typography className="titleContainerText">
                  {configData.service}
                </Typography>
                <Box className="actionBox">
                  <FilterButton
                    callFilterApi={this.handleApplyFilter}
                    clearFilter={this.clearFilter}
                    clear={this.clear}
                    isUnderLine={true}
                    sections={this.state.sections}
                    isFilterApplied={this.state.isFilterSelected}
                  >
                    <VerticalServiceTabs
                      data-test-id="verticalService"
                      getSelectedValue={this.getSelectedTabValue}
                      selctedTabs={this.state.filters}
                      sections={this.state.sections}
                      updateSections={this.updateSectionLists}
                      getCurrentTabValue={this.getCurrentTabValue}
                      setServiceName={(value: string) => { this.setServiceName(value) }}
                      optionsList={this.state.optionsList}
                    />
                  </FilterButton>
                <LoadingComponent
                isLoading={this.state.isLoadingPermission}
                height={56}
                width={184}
                isAllowed={this.state.permissionStatus.createPermission}
                children={ 
                  <Button
                    data-test-id='add-service'
                    variant='contained'
                    className="addBtn"
                    onClick={() => this.handleRedirect()}
                  >
                    {configData.addService}
                  </Button>
                }
                />
                </Box>
              </Box>

              <Box className="flex">
                <Card className="productIconCard" style={{overflowY: "auto", flex: "1 1 0"}}>
                  <CardHeader
                    className="cstHeader"
                    title={
                      <Grid
                        container
                        justifyContent='space-around'
                        style={{ flexWrap: "nowrap" }}
                      >
                        <Grid item xs={5} className="marginLeft76">
                          <Box className="serviceContainer">
                            <Box  style={webStyle.sortingImageContainer}>
                              <Typography className="columnTitle serviceCol">
                                {configData.serviceName}
                              </Typography>
                              <CustomArrowIcon
                                up='serviceNameUp'
                                down='serviceNameDown'
                                handleSelect={this.handleSortByName}
                                selected={this.state.sortByServiceName.value}
                              />
                            </Box>
                            <Box  style={webStyle.sortingSectionContainer}>
                              <Typography className="columnTitle sectionCol">
                                {configData.section}
                              </Typography>
                              <CustomArrowIcon
                                up='sectionNameUp'
                                down='sectionNameDown'
                                handleSelect={this.handleSortBySectionName}
                                selected={this.state.sortBySectionName.value}
                              />
                            </Box>
                          </Box>
                        </Grid>

                        <Grid item xs={3} />

                        <Grid item xs={3} className="activeContainer">
                          <Box className="sortingImageContainer">
                            <Typography  className="columnTitle">
                              {configData.active}
                            </Typography>
                         
                          </Box>
                          <Box  style={webStyle.onlineName}>
                            <Typography className="columnTitle">
                              {configData.online}
                            </Typography>
                           
                          </Box>
                          <Box className="grpBycol">
                            <Typography className="columnTitle">
                              Group By
                            </Typography>
                           
                          </Box>
                        </Grid>
                      </Grid>
                    }
                  />
                  <Droppable droppableId='cardData' data-test-id='dropable'>
                    {(
                      provided: DroppableProvided,
                      snapshot: DroppableStateSnapshot
                    ) => (
                      <CardContent
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <div className="tableRow" >
                      {this.state.isLoading && [...Array(5)].map((_, cellIndex) => (
                              <Skeleton animation='wave' style={{ margin: "5px 0" }} variant='text' height={100}/>

                        ))}
                    </div>
                        { this.state.serviceList.length===0 ?  
                       !this.state.isLoading && <Box style={webStyle.tableBodyInnerChild}>
                    
                      <Typography style={webStyle.bodyText as React.CSSProperties}>
                        {configData.noData}
                      </Typography>
                    </Box> 
                    : !this.state.isLoading && this.state.serviceList.map(
                          (item: ServiceListType, index) => (
                            <Draggable
                              key={`${item.id}${item.name}`}
                              draggableId={item.id}
                              index={index}
                              isDragDisabled={this.state.isDragableDisabled ? true : false}
                            >
                              {(
                                provided: DraggableProvided,
                                snapshot: DraggableStateSnapshot
                              ) => (
                                <div
                                  ref={provided.innerRef}
                                  data-test-id={`list-item-${index}`}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <Grid
                                    container
                                    key={`${item.id}${item.name}`}
                                    style={{ opacity: !item.active ? 0.7 : 1 }}
                                    className="marginTopBotton10"
                                  >
                                    <Grid
                                      container
                                      xs={1}
                                      justifyContent='center'
                                      alignItems='center'
                                      style={webStyle.threeLine}
                                    >
                                      <img
                                        src={DragHandle}
                                        height={24}
                                        width={24}
                                        alt='dragHandle'
                                      />
                                    </Grid>
                                    <Grid item xs={10} direction='row' >
                                      <Grid
                                        container
                                        className="listParenContainer"
                                        style={{minHeight: '64px', height: 'unset'}}
                                        justifyContent='space-around'
                                        alignItems='center'
                                      >
                                        <Grid item xs={2}>
                                          <Typography
                                            className="rowText"
                                            data-test-id="service-name"
                                          >
                                            {item.name}
                                          </Typography>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={5}
                                          className="marginLeft22"
                                        >
                                          <Typography
                                            className="rowText"
                                          >
                                            {item.sectionList.join(", ")}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                          <Typography
                                            variant='h6'
                                            align='center'
                                          >
                                            {item.active ? (
                                              <img src={Check} alt='check' />
                                            ) : (
                                              "--"
                                            )}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                          <Typography
                                            variant='h6'
                                            align='left'
                                          >
                                            {item.onlineOrder ? (
                                              <img src={Check} alt='check' />
                                            ) : (
                                              "--"
                                            )}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                      <Tooltip data-test-id={`tooptip-${item.id}`} title={sortCondition(this.state.permissionStatus.groupingPermission as boolean,"","You don't have permission") as NonNullable<React.ReactNode>} placement="top">
                                        <ChooseOptionButton
                                          disabled={!this.state.permissionStatus.groupingPermission}
                                          data-test-id={`choose-button-${item.id}`}
                                          onClick={() => this.handleOpenServiceGroup(item)}
                                        >
                                          Choose Service
                                        </ChooseOptionButton>
                                      </Tooltip>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={1}
                                      justifyContent='center'
                                      alignItems='center'
                                    >
                                      <Box
                                        style={webStyle.threeDots}
                                      >
                                        <ThreeDotDropdown
                                          data-test-id='threeDotDropdown'
                                          options={this.getOptionsPerRow(item.active)}
                                          color='black'
                                          getSelectedOption={
                                            this.getSelectedOption
                                          }
                                          item={item}
                                        />
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </div>
                              )}
                            </Draggable>
                          )
                        )}
                      </CardContent>
                    )}
                  </Droppable>
                </Card>
                <TablePagination
                  className={'service-module-pagination'}
                  data-test-id='pagination'
                  rowsPerPageOptions={[10]}
                  component='div'
                  count={this.state.totalCount}
                  rowsPerPage={10}
                  page={this.state.currentPage - 1}
                  onPageChange={(event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
                    this.handlePagination(page + 1)
                  }}
                />
                <ConfirmationModal
                 message={this.state.messageText}
                  open={this.state.openModal}
                  title={configData.confirmDeactivate}
                  confirmText={configData.confirmDeactivateBtn}
                  displayItem={false}
                  handleClose={() => this.setState({ openModal: false })}
                  handleConfirm={() =>
                    this.deActivateService(this.state.selectedServiceId)
                  }
                  dynamic={true}
                 />
                <ConfirmationModal
                  open={this.state.deActivateServiceSucess}
                  title={configData.successFullDeactivateTitle}
                  message={this.state.messageText}
                  confirmText={configData.backToListing}
                  displayItem={true}
                  handleClose={() =>
                    this.setState({ deActivateServiceSucess: false })
                  }
                  handleConfirm={() =>
                    this.setState({ deActivateServiceSucess: false })
                  }
                />
              </Box>
            </ServicesListWrapper>
          </>
          {this.state.openSectionGroupModal && this.renderServiceGroupByModal()}
        </DragDropContext>
      </ThemeProvider>
    );
  }
  // Customizable Area End
}

// Customizable Area Start

const webStyle = {
  sortingImageContainer: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
    marginLeft:"28px",
  },
  sortingSectionContainer: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
    
  },
  tableBodyInnerChild: {
  border: "1px solid rgba(236, 236, 236, 1)",
  flexGrow: 2,
  display: "flex",
  // gap: "22rem",
  paddingLeft: "10px",
  height: "4rem",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "8px",
  width: " 100%",
 },
  taxContainer: {
    maxWidth: "100%",
    width: "50%",
  },
  bodyText: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "22px",
  },
  onlineName:{
    display: "flex",
    gap: "5px",
    alignItems: "center",
  },
  threeLine:{
    paddingRight:'20px'
  },
  threeDots:{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    marginLeft: '40px'
  }
  
}
const ServicesListWrapper = styled("div")({
  "& .customCheckbox": {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 8px',
    gap: '7px',
    backgroundColor: 'rgba(32, 75, 156, 0.1)',
    borderRadius: '16px',
    color: "#204B9C",
    

  },
  "& .secondName": {
    height: '60px',
    borderRadius: '8px',
    display: "flex",
    justifyContent: "center",
    paddingLeft: '8px'
  },
  "& .cancelBtn": {
    backgroundColor: '#F1F5F9',
    color: '#64748B',
    width: '126px',
    height: '56px',
    textTransform: 'none',
    border: "0"
  },
  "& .addBtn": {
    backgroundColor: "#204B9C",
    color: "#ffff",
    height: "56px",
    width: '184px',
    padding:"16px",
    marginLeft: "32px",
    textTransform: "none",
    fontSize: "16px",
    boxShadow:"none",
    fontWeight: 600,
    border: "0",
    "&:hover": {
      backgroundColor: "#204B9C",
      color: "#ffff"
    },
    borderRadius:'8px',
    "@media (max-width: 1024px)": {
      height: "44px",
      width: '128px',
      fontSize: "12px",
     fontWeight: 500,
    },
    "@media (max-width: 768px)": {
      padding: "10px 0px",
    },
  },
  "& .servicesBtn": {
    display: "flex",
    flexWrap: "wrap",
    boxSizing: 'border-box',
    minHeight: "60px",
    justifyContent: "center",
    borderRadius: "8px",
    padding: "10px",
  },
  "& .flexContainer": {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px"
  },
  "& .enterName": {
    display: "flex",
    justifyContent: "center",
    height: "60px",
    borderRadius: '8px',
    paddingLeft: "8px"
  },
 
  "& .button": {
    fontSize: "16px",
    fontWeight: 700
  },

  "& .dropDown": {
    maxWidth: '498px',
    overflowY: 'auto'


  },
  "& .cardBox": {
    minHeight: "320px",
    borderRadius: "12px",
    border: 0,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)"
  },
  "& .titleContainer": {
    display: "flex",
    borderColor: "1px solid red",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px 0 0 10px",
  },
  "& .productContainer": {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    backgroundColor: "white",
    marginTop: "20px",
  },
  "& .titleContainerText": {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '29.26px',
    "@media (max-width: 1024px)": {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight:"19.2px",
    },
    margin:"30px 0"
  },
  "& .mainContainer": {
    margin: '32px 0',

  },
  "& .GalleryTitle": {
    display: "flex",
    borderColor: "1px solid red",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px 0 0 10px",
  },
  "& .listParenContainer": {
    border: "1px solid #ECECEC",
    height: "64px",
    borderRadius: "8px",
    width:'105%'
  },
 
    "& .ImgContainer": {
      height: 150,
    },
    "& .productImg": {
      width: "100%",
      height: "100%",
    },
    "& .detailContent": {
      display: "flex",
      flexDirection: "column",
    },

    "& .galleryBtn": {
      backgroundColor: "#204B9C",
      color: "#FFFFFF",
      borderRadius: "8px",
  
    },
    " &.productIconCard": {
      minHeight: "400px",
      borderRadius: "12px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)"
    },
    "& .cstHeader": {
      background: "#204B9C",
      boxShadow: "0px 4px 8px rgb(0 0 0 / 3%), 0px 8px 32px rgb(0 0 0 / 6%)",
      borderRadius: "12px 12px 0px 0px",
      padding: "19px 44px",
      color: "#fff",
      "@media (max-width: 1366px)": {
        padding: "15px",
      },
      "@media (max-width: 1024px)": {
        padding: "10px",
      },
      "@media (max-width: 768px)": {
        padding: "5px",
      },
    },
    "& .cardSmTitle": {
      fontWeight: 600,
      fontSize: "18px",
    },
    "& .cardContent": {
      padding: "32px 65px",
    },
    "& .gridBlock": {
      textAlign: "center",
    },
    "& .cardTitle": {
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "22px",
      color: "#1A1A1A",
    },
    "& .grid": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .subtitle": {
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "22px",
    },
  
    "& .marginTop": {
      marginTop: "2rem",
    },
    "& .fw600": {
      fontWeight: 600,
    },
    "& .margin": {
      // margin: "2rem 0",
    },
    "& .flex": {
      display: "flex",
      flexDirection: "column",
      height: "100%"
    },
    "& .flexBetween": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& .marginBottom_26": {
      marginBottom: "26px",
    },
    "& .fontSize_24": {
      fontSize: "24px",
    },
 
    "& .lineHeight_29": {
      lineHeight: "29.26px",
    },
    "& .padding_46": {
      padding: "46px 0",
    },
    "& .buttonPrimary": {
      height: "56px",
      width: "184px",
      fontSize: "16px",
    },
    "& .maxWidth300": {
      maxWidth: "300px",
    },
    "& .marginLeft23": {
      marginLeft: "23px",
    },
    "& .marginTopBotton10": {
      margin: "10px 0",
    },
    "& .marginLeft22": {
      marginLeft: "22px",
      "@media (max-width: 1366px)": {
        marginLeft: "10px",
      },
      "@media (max-width: 1024px)": {
        marginLeft: "10px",
      },
    },
    "& .textCenter": {
      textAlign: "center",
    },
    "& .parentContainer":{
        width: '100%',
        padding:'10px 49px'
    },
    "& .serviceContainer":{
        display: 'flex',
        justifyContent: 'space-evenly',
        gap: '4rem',
        "@media (max-width: 1366px)": {
          gap: "rem",
        },
        "@media (max-width: 1024px)": {
          gap: "2rem",
        },
        "@media (max-width: 768px)": {
          gap: "1rem",
        },
    },
    "& .activeContainer":{    
      display: 'flex',
      justifyContent: 'space-around',
      marginRight: '5rem'
  },
  "& .rowText": {
    fontSize: "16px",
    fontWeight: 600,
    "@media (max-width: 1366px)": {
      fontSize: "16px",
      fontWeight: 500,
    },
    "@media (max-width: 1024px)": {
      fontSize: "12px",
      fontWeight: 400,
    },
  },
  "& .columnTitle": {
    fontSize: "16px",
    fontWeight: 600,
    "@media (max-width: 1366px)": {
      fontSize: "14px",
      fontWeight: 500,
    },
    "@media (max-width: 1024px)": {
      fontSize: "12px",
      fontWeight: 400,
      position:'relative',
      left:'10px'
    }
  },
  "& .serviceCol":{
    marginLeft:"40px",
    "@media (max-width: 1560px)": {
      marginLeft:"0px"
    },
    "@media (max-width: 1366px)": {
      marginLeft:"32px"
    },
    "@media (max-width: 1024px)": {
      marginLeft:"22px"
    },
  },
  "& .grpBycol":{
    marginRight:"5px",
    "@media (max-width: 1440px)": {
      marginRight:"-7px"
    },
    "@media (max-width: 1366px)": {
      marginRight:"27px"
    },
    "@media (max-width: 1280px)": {
      marginRight:"18px"
    },
    "@media (max-width: 1024px)": {
      marginRight:"0px"
    },
  },
  "& .actionBox": {
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    gap: "1rem"
  }
})

const StyledCheckbox = styled(Checkbox)({
  "&.MuiCheckbox-root": {
    width: "20px",
    height: "20px",
    borderRadius: "6px",
  },
  "&.Mui-checked": {
    color: `${colors().cyancobaltblue} !important`,
  },
});

const ChooseOptionButton = styled("button")({
  padding: "6px 12px",
  background: "#204b9c",
  outline: "none",
  border: "none",
  color: "#ffffff",
  borderRadius: "8px",
  width:'150%',
  position:'relative',
  right:'40px',
  "@media (max-width: 1024px)": {
    width:'170p%',
    textAlign:'center',
    fontSize:'10px',
    position:'relative',
    marginLeft:'10px'
  },
  "@media (max-width: 1280px)": {
    width:'160%',
    
  },
});

const ServiceGroupByModal = styled(Dialog)({
  "& .MuiDialog-paperWidthSm": {
    width: "100%",
  },
  "& .MuiDialog-paperScrollPaper": {
    borderRadius: "12px",
  },
  "& .modal-wrapper": {
    padding: "24px"
  },
  "& .modal-heading": {
    fontSize: "24px",
    fontWeight: 600,
    color: colors().viewTextColor,
    textAlign: "center",
    margin: "0 0 16px 0",
  },
  "& .modal-para": {
    fontSize: "14px",
    fontWeight: 500,
    color: colors().slateGray,
    textAlign: "center",
    margin: "0 12px 0 0",
  },
  "& .modal-area-wrapper": {
    display: "flex",
    flexDirection: "column"
  },
  "& .modal-list-item": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px 16px",
    margin: "6px 0 0 0",
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 600,
    borderRadius: "8px",
    width: "100%",
    "&:nth-child(odd)": {
      border: `1px solid ${colors().lightborder}`,
    },
    "&:nth-child(even)": {
      border: `1px solid ${colors().lightborder}`,
      background: colors().lightborder
    }
  },
  "& .button-block": {
    display: "flex",
    alignItems: "center",
    margin: "24px 0 0 0"
  },
});

export default withStyles(styles)(ServicesList);

// Customizable Area End
