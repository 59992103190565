// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { makeApiMessage } from "../../../components/src/common";
import {
  checkIsFilterApplied,
  debounce,
  navigateTo,
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey,
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { ISortingData } from "../../../components/src/OrderTable/src/TableCell";
import { IFilter } from "../../../components/src/FilterPopover";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");

const emptyRowObj = {
  id: "",
  type: "",
  attributes: {
    id: 0,
    group_name: "",
    no_of_items_per_wash: 0,
    no_of_pieces_per_item: 0,
    designation: "",
    vip: false,
    description: "",
    accounts_count: 0,
    activated: false,
    company: {
      id: 0,
      name: "",
    },
    accounts: [],
  },
};
interface CustomerAccount {
  id: number;
  name: string;
  full_phone_number: string;
  email: null | string;
}
export interface CustomerGroup {
  id: string;
  type: string;
  attributes: {
    id: number;
    group_name: string;
    no_of_items_per_wash: null | number;
    no_of_pieces_per_item: null | number;
    designation: string;
    vip: boolean;
    description: string;
    accounts_count: number;
    activated: boolean;
    company: {
      id: number;
      name: string;
    };
    accounts: CustomerAccount[];
  };
}

interface SingleCustomer {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    full_phone_number: string;
    email: null | string;
  };
}

interface IMeta {
  next_page: number;
  pervious_page: number;
  total_pages: number;
  total_count: number;
  current_page: number;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isAppliedFilter: boolean;
  isLoadingPermission: boolean;
  customerGroupList: Array<CustomerGroup>;
  listingLoading: boolean;
  isOpenShowAllCustomers: boolean;
  sortingData: ISortingData;
  tableListingLoading: boolean;
  customerModalDetails: CustomerGroup | null;
  page: number;
  per_page: number;
  meta: IMeta;
  searchCustomerGroupString: string;
  customerModalPage: number;
  customerModalMeta: IMeta;
  customerModalTableList: SingleCustomer[];
  searchCustomerVal: string;
  groupId: string;
  query: string;
  openActionPopover: (EventTarget & HTMLButtonElement) | null;
  currentClickedRow: CustomerGroup;
  openDeactiveModel: boolean;
  confirmDeactiveModalOpen: boolean;
  snackBarOpen: boolean;
  snackBarMessage: string;
  severity: "warning" | "error" | "success";
  filters: IFilter[];
  filterAnchor: HTMLDivElement | boolean | undefined;
  suggestionFieldTitle: string;
  permissionStatus: PermissionStatus;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class B2bCustomerGroupController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCustomerGroupApiCallId: string = "";
  getCustomerDetailApiCallId: string = "";
  deactivateGroupApiCallId: string = "";
  activateGroupApiCallId: string = "";
  filterSuggestionApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SearchTextMessage),
      getName(MessageEnum.LayoutDataMessage),
    ];
    // Customizable Area End

    // Customizable Area Start
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      isAppliedFilter: false,
      isLoadingPermission: true,
      customerGroupList: [],
      listingLoading: false,
      tableListingLoading: false,
      isOpenShowAllCustomers: false,
      sortingData: {
        name: "",
        phone: "",
        email: "",
      },
      page: 1,
      per_page: 10,
      meta: {
        next_page: 1,
        pervious_page: 1,
        total_pages: 1,
        total_count: 1,
        current_page: 1,
      },
      customerModalPage: 1,
      searchCustomerGroupString: "",
      customerModalMeta: {
        next_page: 1,
        pervious_page: 1,
        total_pages: 1,
        total_count: 1,
        current_page: 1,
      },
      customerModalDetails: null,
      customerModalTableList: [],
      searchCustomerVal: "",
      groupId: "",
      query: "",
      openActionPopover: null,
      currentClickedRow: emptyRowObj,
      openDeactiveModel: false,
      confirmDeactiveModalOpen: false,
      snackBarOpen: false,
      snackBarMessage: "",
      severity: "error",
      filters: [
        {
          title: "Customer Group",
          type: "autocompolete",
          value: "",
          apiKey: "group_name",
          options: [],
        },
        {
          title: "Company",
          type: "autocompolete",
          value: "",
          apiKey: "company_name",
          options: [],
        },
        {
          title: "Designation",
          type: "autocompolete",
          value: "",
          apiKey: "designation",
          options: [],
        },
        {
          title: "VIP Privilege",
          type: "select",
          value: "",
          options: [
            {
              label: "True",
              value: "true",
            },
            {
              label: "False",
              value: "false",
            },
          ],
        },
      ],
      filterAnchor: undefined,
      suggestionFieldTitle: "",
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false,
        activatePermission: false,
      },
    };
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleStorageFilter();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getCustomerGroupApiCallId) {
        this.handleGetCustomerGroupResponse(responseJson);
      } else if (apiRequestCallId === this.getCustomerDetailApiCallId) {
        this.handleGetCustomerDetailsResponse(responseJson);
      } else if (apiRequestCallId === this.deactivateGroupApiCallId) {
        this.handleGetCustomerDeactivateResponse(responseJson);
      } else if (apiRequestCallId === this.activateGroupApiCallId) {
        this.handleGetCustomerActivateResponse(responseJson);
      } else if (apiRequestCallId === this.filterSuggestionApiCallId) {
        this.handleFilterSuggestionApiResponse(responseJson);
      }
    }
    this.receiveSearchTextData(message);
    this.receiveDataFromLayout(message);
    // Customizable Area End
  }

  // Customizable Area Start
  handleReturnColorType = () => {
    const { isAppliedFilter } = this.state;
    return isAppliedFilter ? "primary" : "inherit";
  };

  getCustomerGroupList = () => {
    this.setState({ listingLoading: true });

    const { page, searchCustomerGroupString } = this.state;

    const pageQuery = `?page_no=${page}&per_page=${10}`;

    const searchQuery = searchCustomerGroupString
      ? `&filter_by[query]=${searchCustomerGroupString}`
      : "";

    // group name filter query set
    const groupNameFilter = this.state.filters.find(
      (item: IFilter) => item.title === "Customer Group"
    )?.value;
    const groupFilterQuery = groupNameFilter
      ? `&filter_by[group_name]=${groupNameFilter}`
      : "";

    // company name filter query set
    const companyNameFilter = this.state.filters.find(
      (item: IFilter) => item.title === "Company"
    )?.value;
    const companyFilterQuery = companyNameFilter
      ? `&filter_by[company_name]=${companyNameFilter}`
      : "";

    // designation name filter query set
    const designationFilter = this.state.filters.find(
      (item: IFilter) => item.title === "Designation"
    )?.value;
    const designationFilterQuery = designationFilter
      ? `&filter_by[designation]=${designationFilter}`
      : "";

    // // Apply filter on vip
    const vipFilter = this.state.filters.find(
      (item: IFilter) => item.title === "VIP Privilege"
    )?.value;
    const vipFilterQuery = vipFilter ? `&filter_by[vip]=${vipFilter}` : "";

    const apiUrl =
      configJSON.customerGroupListEndPoint +
      pageQuery +
      searchQuery +
      groupFilterQuery +
      companyFilterQuery +
      designationFilterQuery +
      vipFilterQuery;

    let message = makeApiMessage({
      url: apiUrl,
      method: "GET",
    });
    this.getCustomerGroupApiCallId = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  handleGetCustomerGroupResponse = (response: {
    data: Array<CustomerGroup>;
    meta: IMeta;
  }) => {
    if (response) {
      this.setState({
        customerGroupList: response.data,
        meta: response.meta,
        listingLoading: false,
      });
    }
  };

  handleNaviagetToAddPage = () => {
    navigateTo({ props: this.props, screenName: "B2bAddCustomerGroup" });
  };

  handleOpenShowAll = (customer: CustomerGroup) => {
    if (!customer.attributes.activated) {
      return;
    }
    this.setState({
      isOpenShowAllCustomers: true,
      customerModalDetails: customer,
      tableListingLoading: true,
    });
    this.getCustomerDetails(customer.id);
  };

  onCloseShowCustomerDialog = () => {
    this.setState({
      isOpenShowAllCustomers: false,
      customerModalTableList: [],
      customerModalMeta: {
        next_page: 1,
        pervious_page: 1,
        total_pages: 1,
        total_count: 1,
        current_page: 1,
      },
      customerModalPage: 1,
    });
  };

  getSortingProps() {
    return {
      sortingData: this.state.sortingData,
      onQueryChange: (query: string) => this.handleQueryChange(`${query}`),
      onChange: (sortingData: ISortingData) => this.setState({ sortingData }),
    };
  }

  handleQueryChange = (query: string) => {
    this.setState(
      {
        query,
        customerModalPage: 1,
        customerModalTableList: [],
        tableListingLoading: true,
      },
      () => this.getTableCustomerList()
    );
  };

  getTypeOfOrderTable = (rowIndex: number) => {
    if (rowIndex === 0) {
      return "left";
    } else if (rowIndex === 2) {
      return "right";
    } else {
      return "middle";
    }
  };

  getTableCustomerList = () => {
    const { customerModalPage, per_page, groupId, searchCustomerVal, query } =
      this.state;

    const pageQuery = `?page_no=${customerModalPage}&per_page=${per_page}&group_id=${groupId}`;

    const searchQuery = searchCustomerVal
      ? `&filter_by[query]=${searchCustomerVal}&is_group= true`
      : "";

    const sortingQuery = query ? `&${query}` : "";

    const apiUrl =
      configJSON.customerListEndPoint + pageQuery + searchQuery + sortingQuery;

    let message = makeApiMessage({
      url: apiUrl,
      method: "GET",
    });
    this.getCustomerDetailApiCallId = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  getCustomerDetails = (customerId: string) => {
    this.setState({ groupId: customerId }, () => this.getTableCustomerList());
  };

  handleGetCustomerDetailsResponse = (response: {
    data: SingleCustomer[];
    meta: IMeta;
    status: number;
  }) => {
    if (response.data) {
      const { customerModalTableList } = this.state;
      const dataList = Array.from(
        new Map(
          customerModalTableList
            .concat(response.data)
            .map((customer) => [customer.id, customer])
        ).values()
      );
      this.setState({
        customerModalTableList: dataList,
        customerModalMeta: response.meta,
        tableListingLoading: false,
      });
    } else if (response.status === 500) {
      this.setState({
        tableListingLoading: false,
        snackBarMessage: "Internal server error",
        snackBarOpen: true,
        severity: "error",
      });
    }
  };

  receiveSearchTextData = (message: Message) => {
    if (message.id === getName(MessageEnum.SearchTextMessage)) {
      const recievedData = message.getData(
        getName(MessageEnum.SearchMessageText)
      );
      if (recievedData) {
        this.handleSearchCustomerGroup(recievedData.searchText);
      }
    }
  };

  handleSearchCustomerGroup = (value: string) => {
    this.setState(
      {
        page: 1,
        searchCustomerGroupString: value,
      },
      this.getCustomerGroupList
    );
  };

  handleOnPageChange = (page: number) => {
    this.setState(
      {
        page: page + 1,
      },
      this.getCustomerGroupList
    );
  };

  handleSearchCustomer = (value: string) => {
    this.setState(
      {
        customerModalPage: 1,
        searchCustomerVal: value,
        customerModalTableList: [],
        tableListingLoading: true,
      },
      () => this.getTableCustomerList()
    );
  };

  debouncedFetchSearchResults = debounce(this.handleSearchCustomer, 500);
  handleOpenActionPopver = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    clickedItem: CustomerGroup
  ) => {
    this.setState({
      openActionPopover: event.currentTarget,
      currentClickedRow: clickedItem,
    });
  };

  handleCloseActionPopver = () => {
    this.setState({ openActionPopover: null });
  };

  handleNavigateToView = () => {
    const { currentClickedRow } = this.state;

    this.props.navigation.navigate("ViewCustomerGroup", {
      id: currentClickedRow.id,
    });
  };

  handleNavigateToEdit = () => {
    const { currentClickedRow } = this.state;

    this.props.navigation.navigate("B2bEditCustomerGroup", {
      id: currentClickedRow.id,
    });
  };

  handleActivateDeactivate = () => {
    const { currentClickedRow } = this.state;
    if (currentClickedRow && currentClickedRow.attributes.activated) {
      this.setState({
        openActionPopover: null,
        openDeactiveModel: true,
      });
    } else {
      this.activateCustomerGroup();
    }
  };

  handleCloseConfirmModal = () => {
    this.setState({ openDeactiveModel: false });
  };

  handleDeactivate = () => {
    this.deActivateCustomerGroup();
    this.setState({ openDeactiveModel: false });
  };

  deActivateCustomerGroup = () => {
    const { currentClickedRow } = this.state;

    const apiUrl = configJSON.createCustomerEndPoint;

    let message = makeApiMessage({
      url: apiUrl + `/${currentClickedRow.id}/deactivate`,
      method: "PUT",
    });
    this.deactivateGroupApiCallId = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  handleGetCustomerDeactivateResponse = (response: { data: string }) => {
    if (response && response.data) {
      this.setState({ confirmDeactiveModalOpen: true });
    }
  };

  handleGoBackToListing = () => {
    this.setState({
      confirmDeactiveModalOpen: false,
      currentClickedRow: emptyRowObj,
    });
    this.getCustomerGroupList();
  };

  activateCustomerGroup = () => {
    const { currentClickedRow } = this.state;

    const apiUrl = configJSON.createCustomerEndPoint;

    let message = makeApiMessage({
      url: apiUrl + `/${currentClickedRow.id}/activate`,
      method: "PUT",
    });
    this.activateGroupApiCallId = message.messageId;
    runEngine.sendMessage(message.id, message);
  };

  handleGetCustomerActivateResponse = (response: { data: string }) => {
    if (response && response.data) {
      this.setState({
        openActionPopover: null,
        snackBarOpen: true,
        snackBarMessage: response.data,
        severity: "success",
      });
      setTimeout(() => this.getCustomerGroupList(), 500);
    }
  };

  handleSnackbarClose = () => {
    this.setState({ snackBarOpen: false, snackBarMessage: "" });
  };

  handleClosePopOver = () => {
    this.setState({ filterAnchor: undefined });
  };

  handleFilterChange = (filters: IFilter[]) => {
    // set filter applied value set
    if (checkIsFilterApplied(filters)) {
      window.localStorage.setItem(
        "b2b_customer_group_filter",
        JSON.stringify(filters)
      );
    } else {
      window.localStorage.removeItem("b2b_customer_group_filter");
    }

    this.setState(
      {
        filters,
        isAppliedFilter: checkIsFilterApplied(filters),
        page: 1,
      },
      this.getCustomerGroupList
    );
  };

  handleGroupFilterAutoComplete = (title: string, value: string) => {
    this.setState({ suggestionFieldTitle: title });

    this.state.filters.forEach((item: IFilter) => {
      if (item.title === title) {
        item.value = value;
      }
      return item;
    });

    let ApiUrl;
    if (title === "Customer Group") {
      ApiUrl =
        configJSON.groupFilterSuggestionEndPoint + `?group_name=${value}`;
    } else if (title === "Company") {
      ApiUrl =
        configJSON.groupFilterSuggestionEndPoint + `?company_name=${value}`;
    } else {
      ApiUrl =
        configJSON.groupFilterSuggestionEndPoint + `?designation=${value}`;
    }

    let requestMessage = makeApiMessage({
      url: ApiUrl,
      method: "GET",
    });
    this.filterSuggestionApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  debouncedCustomerGroupSuggestion = debounce(
    this.handleGroupFilterAutoComplete,
    700
  );

  onFilterClickHandler = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    this.setState({ filterAnchor: event.currentTarget });
  };

  handleFilterSuggestionApiResponse = (response: { suggestion: string[] }) => {
    if (Array.isArray(response.suggestion)) {
      const list =
        response.suggestion.map((value: string) => {
          return {
            label: value,
            value,
          };
        }) || [];

      const updatedFilters = this.state.filters.map((item: IFilter) => {
        if (item.title === this.state.suggestionFieldTitle) {
          if (item.value !== "") {
            item.options = list;
          } else {
            item.options = [];
          }
        }
        return item;
      });
      this.setState({ filters: updatedFilters });
    } else {
      const updatedFilters = this.state.filters.map((item: IFilter) => {
        if (item.title === this.state.suggestionFieldTitle) {
          item.options = [];
        }
        return item;
      });
      this.setState({ filters: updatedFilters });
    }
  };

  handleStorageFilter = () => {
    const applied_b2b_customer_group_filter = window.localStorage.getItem(
      "b2b_customer_group_filter"
    );
    if (applied_b2b_customer_group_filter) {
      this.setState(
        {
          filters: JSON.parse(applied_b2b_customer_group_filter),
          isAppliedFilter: checkIsFilterApplied(
            JSON.parse(applied_b2b_customer_group_filter)
          ),
        },
        () => this.getCustomerGroupList()
      );
    } else {
      this.getCustomerGroupList();
    }
  };

  handleUserChange = (userContext: IUserContext) => {
    const userData = userContext.user?.attributes.permission_groups;
    const apiKey = customPermissionApiKey.b2bCustomerGroupPermission;
    const value = checkForNewPermissonStatus(
      apiKey,
      userData as Array<PermissionGroupArray>
    );
    this.setState({
      permissionStatus: value,
      isLoadingPermission: false,
    });
  };

  receiveDataFromLayout = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
      const recievedData = message.getData(
        getName(MessageEnum.LayoutMessageData)
      );
      if (recievedData.userContext) {
        this.handleUserChange(recievedData.userContext);
      }
    }
  };

  fetchMoreTableCustomers = () => {
    if (
      this.state.customerModalPage === this.state.customerModalMeta.total_pages
    ) {
      return;
    }

    this.setState(
      {
        customerModalPage: this.state.customerModalPage + 1,
      },
      () => {
        this.getTableCustomerList();
      }
    );
  };
  // Customizable Area End
}
