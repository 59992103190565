import React from 'react';
import { withRouter} from 'react-router-dom';
import SideBarComp from './SideBar.web';
import {
  Box,
  styled,
  StyledComponentProps
} from "@material-ui/core";
import LayoutController, {
  Props,
  configJSON,
} from './LayoutController.web';
import HeaderComp from './Header.web';

interface StyledDrawerResProps {
  responsive?: {
    "1024": string,
    "1280": string,
    "1366": string
  },
  open: boolean
}
export class Layout extends LayoutController {
  constructor(props: Props) {
    super(props);
  }

  navigate = (to: string, params: Record<string, string>) => {
    let url = this.props.routeMap[to].path;
    // replace params ids in the url with actual values
    if (params && Object.keys(params).length > 0) {
      Object.keys(params).forEach(param => {
        const re = RegExp(`\:${param}\\??`); // eslint-disable-line no-useless-escape
        url = url.replace(re, encodeURIComponent(params[param]));
      });
    }
    // removing empty params from url - every string between /: and ?
    url = url.replace(/\/:(.*?)(?=\/|$)/g, "");
    // if the route is not a modal
    if (!this.props.routeMap[to].modal) {
      this.props.history.push(url);
      // if the route is a modal
    } else {
      // checking if the url ends with a slash or not
      const slash = /\/$/.test(this.props.match.url) ? "" : "/";
      // current url in the browser + slash + modal url with parameters
      url = this.props.match.url + slash + url;
      // removing the */ from the url
      url = url.replace(/\*\/?/g, "");
      this.props.history.push(url);
    }
  };

  getParam = (param: number, alternative: string) => {
    return this.props.match.params[param] || alternative;
  };

  goBack = () => {
    this.props.history.goBack();
  };

  handleChildrenWithProps = (): React.ReactNode => {
    return React.Children.map(this.props.children, (child) => {
      if (React.isValidElement<{className:string}>(child)) {
        const updatedChild = React.cloneElement(child, {
          className: "pageContainerWrapper"
        });
        return updatedChild;
      }
      return child;
    });
  };

  render() {
    return (
      <NavigationWrapper className="root" open={this.state.drawerOpen} responsive={this.props.location.pathname !== '/Dashboard-PlantReport' ? this.props.resposniveDrawer : configJSON.responsiveDrawerValue}>
        <Box className='sideBar'>
          <SideBarComp navigation={{ navigate: this.navigate, goBack: this.goBack, getParam: this.getParam, location: this.props.location }} onOpenedChanged={this.handleDrawerOpen} />
        </Box>
        <div className={"wrapperBlock"}>
          <HeaderComp
            navigation={{ navigate: this.navigate, goBack: this.goBack, getParam: this.getParam, location: this.props.location }}
          ></HeaderComp>
          <div className={"childContainer"}>
            {this.handleChildrenWithProps()}
          </div>
        </div>
      </NavigationWrapper>
    )
  }
}

const NavigationWrapper = styled(Box)((props: StyledComponentProps & StyledDrawerResProps) => ({
  "& .pageContainerWrapper": {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  "& .wrapperBlock": {
    flex: "1 1 0",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
  },
  "& .childContainer": {
    padding: "0 32px",
    flex: "1 1 0",
    overflow: "auto",
  },
  "&.root": {
    display: "flex",
    width: "100vw",
    height: "100vh",
  }
}));

export default withRouter(Layout);