import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForPermissonStatus,
  customPermissionApiKey
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
import moment from "moment";
import {
  getStorageData,
} from "framework/src/Utilities";
import { makeApiMessage } from "../../../components/src/common";

export interface ReportItem {
  id: string;
  label: string;
  checked: boolean;
}

export interface IOption {
  id: number;
  label: string;
}
export interface ReportItemWithSection {
  [Key: string]: {
    [key: string] : ReportItem[]
  }
}
export interface SalesAndRevenueReport {
  [key: string]: ReportItem[]
}

export interface RevenueReport {
  [key: string]: ReportItem[] | boolean
}

export interface SectionServiceSalesReport {
  [key: string]: ReportItem[] | SalesAndRevenueReport
}

export interface DropdownType {
  id: string;
  option: string;
}

interface FieldsData {
  [key: string]: ReportItem[] | SalesAndRevenueReport[] | SectionServiceSalesReport | RevenueReport
}

interface DailySalesData {
  name: string;
  orders?: boolean;
  pieces?: boolean;
  amount_without_vat?: boolean;
  amount?: boolean;
  vat?: boolean; 
  total_inc_vat?: boolean;
  total_revenue? : boolean;
  cash?: boolean;
  card?: boolean;
  online?: boolean;
  store_paid?: boolean;
  credit?: boolean;
  unpaid?: boolean;
  discount?: boolean;
  total_orders?: boolean;
  total_pieces?: boolean;
  total_amount?: boolean;
}

const generateData = (fields: string[]) => {
  return fields.map((field, index) => ({
    label: field,
    id: `${index}`,
    checked: false
  }))
}

const generateReportList = ((fields: string[]) => {
  return fields.map((fields, index) => ({
    id: `${index + 1}`,
    option: fields
  }))
})
const reportListData = generateReportList([
  'Orders Sales Report',
  'Orders Revenue Report',
  'Laundry Sales & Revenue Report',
  'Consolidated Sales & Revenue Report',
  'Section & Service Sales Report',
  'Adjustment Report',
  'Daily Sales Report',
  'Trend products Sales Report',
])
const fieldsData: FieldsData = {
  'Trend products Sales Report': generateData(["item_name", "arabic_name", "services", "quantity", "pcs", "amount", "up_charge","diff_pieces"]),
  'Orders Sales Report': generateData(["Store ID", "Store Name", "Invoice No", "Placed", "Staff Taking Order", "Ready By", "Cleaned", "Staff Marking Cleaned", "Delivered", "Staff Completing", "Customer", "Customer ID", "Email", "Phone", "Address", "Promo Signup ID", "Pieces", "Summary", "Arabic", "Notes", "Pickup", "Delivery", "Mobile App", "Driver Name", "Paid", "Payment Type", "Payment Date", "Staff Taking Payment", "Route #", "Discount", "Credit", "Total", "Total after Credit Used", "Tax", "Status", "Credit Note?", "Credit Note Amount", "Credit Note Number", "Credit Note Creation Date", "Credit Note Created By"]),
  'Orders Revenue Report': generateData(["Store ID","Store Name","Order ID","Placed","Staff Taking Order", "Ready By","Partial Payment","Payment Date","Cleaned","Staff Marking Cleaned","Collected","Staff Completing","Customer","Customer ID","Custom ID","Business Name","Phone","Address","Promo Signup ID","Pieces","Summary", "Notes","Pickup","Delivery","Mobile App","Final Payment Paid","Payment Type","Check Number","Staff Taking Payment","Route #","Discount","Credit","Amount","Total after Credit Used","Tax","Subtotal"]),
  'Laundry Sales & Revenue Report': {
    'store_information': generateData(['store_id', 'store_name']),
    'clothes': generateData(['orders', 'pieces', 'amount_without_vat']),
    'carpets': generateData(['orders', 'pieces', 'amount_without_vat']),
    'credit_orders': generateData(['orders', 'pieces', 'amount','vat']),
    'total': generateData(['total_orders', 'total_pieces', 'total_amount','vat', 'discount', 'total_inc_vat', 'revenue']),
    'payment_methods': generateData(['cash', 'card', 'online', 'store_paid', 'used_credit', 'unpaid']),
    'average_cloth_sales': generateData(['average_orders', 'average_pieces', 'average_order_amount', 'average_pieces_amount']),
    'average_carpet_sales': generateData(['average_orders', 'average_pieces', 'average_order_amount', 'average_pieces_amount']),
    'difference_showing_in_number_value': generateData(['order_different', 'pieces_different', 'revenue_different', 'sales_amount_different']),
    'difference_showing_in_percentage': generateData(['order_different', 'pieces_different', 'revenue_different', 'sales_amount_different']),
    'total_for_each_column': false
  },
  'Consolidated Sales & Revenue Report': {
    'store_information': generateData(['store_id', 'store_name']),
    'order_details': generateData(['orders', 'pieces','sales_exc_vat', 'discount', 'net_sales', 'vat','sales_inc_vat', 'revenue']),
    'credit_note_details': generateData(['orders','pieces', 'amount', 'vat']),
    'payment_methods': generateData(['cash', 'card', 'online_paid', 'wallet_cash_paid', 'store_paid', 'unpaid']),
    'additional_transaction_details': generateData(['loyality_credit','compensation_credit', 'compensation_cash_paid']),
    'total_for_each_column': false
  },
  'Adjustment Report': {
    'store_details': generateData(['store_id', 'store_name', 'store_address']),
    'original_order': generateData(['order_number','number_of_product', 'pieces', 'amount_without_vat', 'vat', 'credit_used', 'paid_status', 'discount']),
    'credit_order': generateData(['order_number', 'number_of_product', 'pieces', 'amount_without_vat', 'vat', 'wallet', 'paid', 'payment_type']),
    'new_order': generateData(['order_number', 'number_of_product', 'pieces', 'amount_without_vat', 'paid', 'requested_by', 'created_by', 'reason', 'payment_type', 'remarks', 'date']),
    'total_for_each_column': false
  },
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  permissionStatus: PermissionStatus;
  selectedFromDate: string;
  selectedToDate: string;
  selectedStore: Array<number>;
  selectedGroup: Array<number>;
  storeList: Array<IOption>,
  selectedRegion: Array<number>;
  selectedArea: Array<number>;
  regionList: Array<IOption>
  reportType: DropdownType | null;
  filterFields: SalesAndRevenueReport[] | ReportItemWithSection[] | ReportItem[] | SalesAndRevenueReport
  reportList: DropdownType[];
  showFilterOptions: boolean;
  message: {
    name: string;
    type: "error" | "success" | "info" | "warning" | undefined;
  };
  showLoading: boolean;
  isLoadingPermission: boolean
  isB2bActive: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SalesReportingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  storeListMessageId: string = "";
  regionListMessageId: string = "";
  exportOrderSalesReportMessageAPIId: string = "";
  dailySalesFilterListMessageId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.BroadcastNavbarDataMessage),
      getName(MessageEnum.LayoutDataMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      },
      selectedFromDate: '',
      selectedToDate: '',
      selectedRegion: [],
      regionList: [],
      selectedStore: [],
      selectedArea: [],
      selectedGroup: [],
      storeList: [],
      reportType: null,
      filterFields: [],
      reportList: reportListData,
      showFilterOptions: false,
      message: {
        name: '',
        type: 'info'
      },
      showLoading: false,
      isLoadingPermission: true,
      isB2bActive: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    this.handelStoreListApiResponse(message);
    this.handleResForGetAllRegion(message);
    this.handleResForExport(message);
    this.handleResForDailySalesFilter(message);
    
    this.receiveDataFromLayout(message);
    this.receiveBroadcastedData(message)
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  receiveDataFromLayout = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.LayoutMessageData)
        );
        if (recievedData.userContext) {
            this.handleUserChange(recievedData.userContext);
        }
    }
  }

  receiveBroadcastedData = (message: Message) => {
    if (message.id === getName(MessageEnum.BroadcastNavbarDataMessage)) {
      const receivedData = message.getData(
        getName(MessageEnum.BroadcastNavbarData)
      );
      const {storeId,regionMultiId,groupId,areaId} = receivedData
      const regionIdArray =regionMultiId ? regionMultiId.split(',').map(Number) : [];
      const storeIdArray = storeId ? storeId.split(',').map(Number): [];
      const groupIdArray = groupId ? String(groupId).split(',').map(Number): [];
      const areaIdArray = areaId ? String(areaId).split(',').map(Number): [];

      this.setState({selectedArea:areaIdArray ,selectedRegion: regionIdArray, selectedStore:storeIdArray,selectedGroup:groupIdArray})
    }
  }

  async componentDidMount() {
    const isB2b = await getStorageData("accountType");
    if(isB2b === "b2b"){
      this.setState({isB2bActive: true})
    }else{
      this.setState({isB2bActive: false})
    }
  }
  
  getStoreList(regionId: string) {
    const message = makeApiMessage({
      method: configJSON.exampleGetAPiMethod,
      url: `${configJSON.getStoreListEndPoint}&region_id=${regionId}`,
    });
    this.storeListMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  getDailySalesFilterList() {
    const { reportType} = this.state;
    const message = makeApiMessage({
      method: configJSON.exampleGetAPiMethod,
      url: reportType && reportType?.option === 'Daily Sales Report' ? configJSON.getDailySalesFiltersEndPoints : configJSON.getSectionSalesFiltesEndPoints,
    });

    this.dailySalesFilterListMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  getRegionList() {
    const message = makeApiMessage({
      method: configJSON.exampleGetAPiMethod,
      url: `${configJSON.getAllRegionEndPoint}`,
    });

    this.regionListMessageId = message.messageId;
    runEngine.sendMessage(message.id, message);
  }

  handelStoreListApiResponse(message: Message) {
    if (
      this.storeListMessageId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({
        storeList: response.data
          .map((item: { attributes: unknown }) => item.attributes)
          .map(
            ({
              id: varId,
              store_name,
            }: {
              id: number;
              store_name: string;
            }) => ({
              id: varId,
              label: store_name,
            })
          ),
      });
    }
  }

  generateDailySalesData = (data: DailySalesData[] ) => {
    return data.reduce((finalData: any, element: DailySalesData) => {
          finalData[element.name] = generateData(Object.keys(element).filter(key => key !== 'name'))
          return finalData
    }, {})
  }

  handleResForDailySalesFilter = async (message: Message) => {
    if( this.dailySalesFilterListMessageId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson) {
        const data = this.generateDailySalesData(responseJson);
        this.selectAllRevenueFields(data, true, false);
        this.setState({
          filterFields: data
        })
      }
    }
  }

  handleResForGetAllRegion = async (message: Message) => {
    if (this.regionListMessageId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.setState({ regionList : responseJson.data
          .map((item: { attributes: unknown }) => item.attributes)
          .map(
            ({
              id: varId,
              branch_name,
            }: {
              id: number;
              branch_name: string;
            }) => ({
              id: varId,
              label: branch_name,
            })
          )})
      }
    }
  }

  downloadURI = (uri: string, name = 'Report') =>{
    let link = document.createElement("a");
    link.download = name;
    link.href = uri;
    link.click();
  }
  handleResForExport = async (message: Message) => {
    if (this.exportOrderSalesReportMessageAPIId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let responseType = this.state.message;
      if (responseJson && responseJson.file_url) {
        this.downloadURI(responseJson.file_url, this.state.reportType?.option);
        responseType =  { name: responseJson.message, type: "success"}
      }
      if (responseJson && responseJson.error) {
        responseType =  { name: responseJson.error, type: "error"}
      }
      this.setState({
        message: responseType,
        showLoading: false
      })
    }
  }

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.accountPermission;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value,
      isLoadingPermission: false
    })
  };

  handleFromDateInput(varDate: Date | null) {
    this.setState({
      selectedFromDate: moment(varDate).format("YYYY-MM-DD"),
    });
  }
  handleToDateInput(varDate: Date | null) {
    this.setState({
      selectedToDate: moment(varDate).format("YYYY-MM-DD"),
    });
  }

  handleUpdatedLabels(items: unknown) {
    const { storeList } = this.state;

    const array: Array<number | string> = JSON.parse(JSON.stringify(items));

    function findLabelById(labelId: unknown) {
      let option = storeList.find((item: IOption) => item.id === labelId);
      return option && option.label;
    }

    const labels = array
      .map((arrayId: unknown) => findLabelById(arrayId))
      .filter((item) => item)
      .join(", ");

    const newLabel =
      labels.length > 20 ? labels.substring(0, 20) + "..." : labels;

    return newLabel;
  }

  getRenderValueOfReason(item: unknown) {
    let label = this.state.regionList.find(
      (reason: IOption) => reason.id === (item as number)
    )?.label;
    return item ? (label as string) : configJSON.stringsListPage_select_region;
  }

  handleReportChange = (newSelectedReportType: DropdownType | null) =>{
    this.setState({
      reportType: newSelectedReportType,
      showFilterOptions: false,
    }, () => {
      const reportType = newSelectedReportType?.option || '';
      if (configJSON.dynamicFilterFields.indexOf(reportType) !== -1) {
        this.getDailySalesFilterList();
      } else {
        const copyFilterFields = JSON.parse(JSON.stringify(fieldsData[reportType]));
        if (configJSON.directFields.indexOf(reportType) !== -1) {
          this.updateCheckbox(copyFilterFields, null, true, 'simpleFilter')
        }
        if (configJSON.revenueFields.indexOf(reportType) !== -1) {
          this.selectAllRevenueFields(copyFilterFields, true, false);
        }
        this.setState({
          filterFields: copyFilterFields
        })
      }
    })
  }

  selectAllRevenueFields = (filterFields: RevenueReport, isCheckboxClicked: boolean, isReportChange: boolean) => {
    Object.keys(filterFields).forEach((key) => {
      if (key === 'total_for_each_column') {
       filterFields[key] = isCheckboxClicked
      } else {
        (filterFields[key] as ReportItem[]).forEach((field: ReportItem) => {
            field.checked = isCheckboxClicked
        })
      }
    })
    if (!isReportChange) {
      this.setState({
        filterFields: filterFields as SalesAndRevenueReport
      })
    }
  }

  updateCheckbox = (fields: ReportItem[], currentField: ReportItem | null, isSelectAll=false, filterType='', ) => {
    const selectedAll = fields.every(field => field.checked)

    fields.forEach((field: ReportItem) => {

      if(field.label === currentField?.label) {
        field.checked = !field.checked
      }
      if(isSelectAll) {
        field.checked = !selectedAll
      }
    })

    if(filterType === 'simpleFilter') {
      this.setState({
        filterFields: fields
      })
    }
  }

  handleFilter = (currentField: any, parentElement: any, subKey='') => {
    const copyFilterFields = JSON.parse(JSON.stringify(this.state.filterFields));
      if(parentElement === 'total_for_each_column') {
        copyFilterFields[parentElement] = !copyFilterFields[parentElement]
      } else {
        if(Array.isArray(copyFilterFields[parentElement])) {
          this.updateCheckbox(copyFilterFields[parentElement], currentField)
        } else {
          Object.keys(copyFilterFields[parentElement]).forEach((key) => {
            if(subKey && subKey === key) {
              this.updateCheckbox(copyFilterFields[parentElement][key], currentField)
            }
          })
        }
      }
 
    this.setState({
      filterFields: copyFilterFields
    })
  }

  validateFilters = () => {
    const { reportType, selectedFromDate, selectedToDate, selectedStore} = this.state;
    if(reportType && selectedFromDate && selectedToDate && moment(selectedToDate).isSameOrAfter(selectedFromDate) && selectedStore.length > 0 ) {
      return true;
    }
    return false;
  }

  getAllTheCheckedColumns = () => {
    const copyFilterFields = JSON.parse(JSON.stringify(this.state.filterFields));
    if(Array.isArray(copyFilterFields)) {
      return copyFilterFields.filter((field) => field.checked).map(field => field.label);
    } else {
      const checkedFields: any = {}
      Object.keys(copyFilterFields).forEach(key => {
          if(key !== 'total_for_each_column') {
              checkedFields[key] = copyFilterFields[key].reduce((obj: any, field: ReportItem) => {                      
                    return { ...obj, 
                      ...(field.checked && {[field.label]: field.checked})
                    }
              }, {})
          } else {
            if(copyFilterFields[key]) {
              checkedFields[key] = copyFilterFields[key]
            }
          }
      })
      return checkedFields;
    }
    
  }

  checkIfAllFieldsChecked = () => {
    let fieldsNotSelected: boolean[] = [];
    const copyFilterFields = JSON.parse(JSON.stringify(this.state.filterFields));

    Object.keys(copyFilterFields).forEach(key => {
      if (key !== 'total_for_each_column') {
        fieldsNotSelected = [...fieldsNotSelected, ...copyFilterFields[key].filter((fieldObj: ReportItem) => !fieldObj.checked).map((fieldData: ReportItem) => fieldData.checked)]
      } else {
        if (!copyFilterFields[key]) {
          fieldsNotSelected.push(copyFilterFields[key])
        }
      }
    })
    if (fieldsNotSelected.length > 0) {
      return false;
    }
    return true
  }

  generateAllDailySalsCheckedColumns = () => {
    const copyFilterFields = JSON.parse(JSON.stringify(this.state.filterFields));
    return Object.keys(copyFilterFields).map((key) => {
      const obj: { [key: string]: any } = {
        name: key,
      }

      copyFilterFields[key].forEach((element: ReportItem) => {
        if (element.checked) {
          obj[element.label] = element.checked;
        }
      })
      return obj
    })
  }

  handleExport = () => {
    const { reportType } = this.state
      if (this.validateFilters()) {
        this.setState({
          showLoading: true
        })
        let url = configJSON.salesReportingEndPoint[reportType ? reportType.option : ''];
        let headers = {
          token: localStorage.getItem(configJSON.token),
          "Content-Type": configJSON.exampleApiContentType,
        };
        const { selectedFromDate, selectedToDate, selectedStore,selectedGroup, selectedArea} = this.state;
        let filterColumns: Array<string> = [];
          filterColumns = configJSON.dynamicFilterFields.indexOf(reportType?.option) !== -1 ? this.generateAllDailySalsCheckedColumns() : this.getAllTheCheckedColumns() ;
        let body = {
          start_date: selectedFromDate,
          end_date: selectedToDate,
          reportType: configJSON.reportType[reportType?.option || '']  ? configJSON.reportType[reportType?.option || ''] : reportType?.option,
          region_ids: this.state.selectedRegion,
          store_ids: selectedStore,
          group_ids: selectedGroup,
          area_ids: selectedArea,
          filter_columns: filterColumns
        };

        const getAccount = makeApiMessage({
          body: JSON.stringify(body),
          headers: headers,
          url: url,
          method: configJSON.examplePostAPiMethod
        });
        this.exportOrderSalesReportMessageAPIId = getAccount.messageId;
        runEngine.sendMessage(getAccount.id, getAccount);
      }
  }

  handleShowFilter = () => {
      this.setState({
        showFilterOptions: !this.state.showFilterOptions
      })
  }

  handleCloseSnackbar = () => {
    this.setState({
      message: {
        type: "info",
        name: "",
      },
    });
  };

  capitalizeFirstCharacter = (sentence: string) => {
    let label = sentence;
    if(label.includes('_id')) {
      label = label.replace('id', 'ID')
    }
    if(label === 'amount_without_vat') {
      return 'Amount (Without VAT)'
    }
    if(label.includes('vat')) {
      label = label.replace('vat', 'VAT')
    }
    let words = label.split('_');
    for (let i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    let capitalizedSentence = words.join(' ');
    return capitalizedSentence;
  }

  formatDate = (inputString?: string) => inputString ? moment(inputString).format("DD-MM-YYYY") : ""
  // Customizable Area End
}
