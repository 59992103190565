// Customizable Area Start
import React from "react";
import FilterIcon from "@material-ui/icons/FilterList";
import SectionListController, { Props } from "./SectionListController.web";
import "./PreferenceList.css";
import DragableList from "../../../components/src/customComponents/DragableList.web";
import FilterPopover from "../../../components/src/FilterPopover";
import { styled, Dialog, Checkbox } from "@material-ui/core";
import { renderBaseOnConditions, sortStringCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";
import { colors } from "../../../blocks/utilities/src/Colors";
import { MainButtonComponent } from "../../../components/src/customComponents/CustomTags.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

class SectionList extends SectionListController {
    constructor(props: Props) {
      super(props);
      // Customizable Area Start
      // Customizable Area End
    }

    // Customizable Area Start

    renderSectionGroupByModal = () => {
      const {
        openSectionGroupModal,
        sectionDropdownList,
        clickedSectionRowData,
        selectedSectionGroupId
      } = this.state;
      return (
        <SectionGroupByModal
          maxWidth='xs'
          open={openSectionGroupModal}
          onClose={this.handleCloseGroupModal}
        >
          <div className='modal-wrapper'>
            <h5 className='modal-heading'>Group With : {clickedSectionRowData?.name}</h5>
            <p className='modal-para'>
              Choose the sections you want to group with this section.
            </p>
            <div className='modal-area-wrapper'>
              {sectionDropdownList.map((section) => {
                return (
                  <div className='modal-list-item'>
                    <p>{section.attributes.name}</p>
                    <StyledCheckbox
                      onClick={() =>
                        this.handleCheckedSection(String(section.attributes.id))
                      }
                      data-test-id={`check-section-${section.id}`}
                      checked={selectedSectionGroupId.includes(
                        String(section.attributes.id)
                      )}
                    />
                  </div>
                );
              })}
            </div>
            <div className='button-block'>
              <MainButtonComponent
                className='buttonSecondary'
                data-test-id="cancel-modal"
                onClick={this.handleCloseGroupModal}
              >
                Cancel
              </MainButtonComponent>
              <MainButtonComponent
                className='btnSubNext'
                data-test-id="save-modal"
                onClick={this.handleCallSectionGroupAPI}
              >
                Save
              </MainButtonComponent>
            </div>
          </div>
        </SectionGroupByModal>
      );
    };

    // Customizable Area End

    render() {
    // Customizable Area Start
    const { sectionList, openModalId, permissionStatus } = this.state;
    return (
      <>
        <SectionWrapper>
        <div className='preference-list-container p-0 m-0 pageWrapper'>
          <div className='preference-top'>
            <h2>Sections</h2>
            <div className='filtergroup'>
              <div
                data-test-id="filterIcon"
                className={sortStringCondition(
                  this.state.isAppliedFilter,
                  "filterButton active-border",
                  "filterButton"
                )}
                onClick={(event) => { this.onFilterClick(event)}}
              >
                <FilterIcon color={this.handleReturnColorType()} />
                {renderBaseOnConditions(
                  this.state.isAppliedFilter,
                  <div className="filterActiveIcon"></div>,
                  <></>
                )}
              </div>
              <FilterPopover
                data-test-id='filterPopover'
                onClose={this.handleClosePopOver}
                anchor={this.state.filterAnchor as HTMLDivElement}
                onFilterChange={this.handleFilterChange}
                onAutoCompleteChange={(title: string, value: string) => {this.handleFilterAutoCompleteChange(title, value)}}
                filters={this.state.filters}
              />
              {
                renderBaseOnConditions(
                  permissionStatus.createPermission,
                  <button
                    data-test-id='Products-SectionListAdd'
                    onClick={this.handleAddSection}
                    className="addBtn"
                  >
                    Add Section
                  </button>,
                  <></>
                )
              }
            </div>
          </div>

          <DragableList
            data-test-id="draggableList"
            data={sectionList}
            onClickChooseButton={this.handleOpenSectionGroup}
            permissionStatus={this.state.permissionStatus}
            openModalId={openModalId}
            handleOpenModel={this.handleOpenModel}
            handleView={this.handleSetionView}
            handleEdit={this.handleSectionEdit}
            handleDeActivate={this.handleDeActivateSectionList}
            handleActivate={this.handleActivate}
            handleDragAndDrop={this.handleDragAndDrop}
            verifyMessage={this.state.verifyMessage}
            successMessage={this.state.successMessage}
            buttonText={this.state.buttonText}
            deActivateMessage={this.state.deActivateMessage}
            goToNextPage={this.goToNextPage}
            goToPreviousPage={this.goToPreviousPage}
            meta={this.state.meta}
            isDragableDisabled={this.state.isDragableDisabled}
            handleSortingBySectionName={this.handleSortingBySectionName}
            nameSorting={this.state.nameSorting}
            isLoading = {this.state.isLoading}
            groupByContClassName="groupByCol"
          />
        </div>
        </SectionWrapper>
        {this.state.openSectionGroupModal && this.renderSectionGroupByModal()}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const SectionWrapper = styled("div")({
  paddingLeft:"12px",
  "& .groupByCol":{
    paddingLeft:"0px",
    "@media (max-width: 1560px)": {
      paddingLeft:"32px"
    },
    "@media (max-width: 1440px)": {
      paddingLeft:"24px"
    },
    "@media (max-width: 1366px)": {
      paddingLeft:"18px"
    },
    "@media (max-width: 1300px)": {
      paddingLeft:"0px"
    }
  },
  "& .p-0": {
    padding: 0
  },
  "& .m-0": {
    margin: 0
  },
  "& .px-8": {
    padding: "0 8px",
    fontSize: "15px"
  },
  "& .filtergroup button": {
    width: '146px',
    height: '56px',
    "@media (max-width: 1366px)": {
      height: "46px",
      width: '140px',
      fontSize: "14px",
     fontWeight: 600,
    },
    "@media (max-width: 1024px)": {
      fontSize: "12px",
     fontWeight: 500,
    },
  },
  "& .preference-top h2": {
      fontSize: '24px',
      fontWeight: 600,
      lineHeight:"29.26px",
    "@media (max-width: 1024px)": {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight:"19.2px",
    },
  },
  "& .filterButton": {
    position: "relative",
    padding: 16,
    borderRadius: 8,
    border: `1px solid ${colors().lightborder}`,
    cursor: "pointer",
    "@media (max-width: 1366px)": {
      padding: 10
     }, 
    "&.active-border": {
      border: `1px solid ${colors().cyancobaltblue}`,
    },
  },
  "& .filterActiveIcon": {
    position: "absolute",
    background: colors().cyancobaltblue,
    top: "-5px",
    right: "-5px",
    minWidth: "15px",
    minHeight: "15px",
    borderRadius: "50%"
  },
  "& .preference-top": {
    margin: "20px 0"
  },
  "& .addBtn":{
    height: "56px",
    width: '184px',
    padding:"16px",
    backgroundColor: "#204B9C",
    color: "#ffff",
    marginLeft: "32px",
    textTransform: "none",
    fontSize: "16px !important",
    fontWeight: 600,
    border: "0",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    "&:hover": {
      backgroundColor: "#204B9C",
      color: "#ffff"
    },
    borderRadius:'8px',
    "@media (max-width: 1024px)": {
      height: "44px",
      width: '128px',
    },
    "@media (max-width: 768px)": {
      padding: "10px 0px",
    },
  }
});

const SectionGroupByModal = styled(Dialog)({
  "& .MuiDialog-paperWidthSm": {
    width: "100%",
  },
  "& .MuiDialog-paperScrollPaper": {
    borderRadius: "12px",
  },
  "& .modal-wrapper": {
    padding: "24px"
  },
  "& .modal-heading": {
    fontSize: "24px",
    fontWeight: 600,
    color: colors().viewTextColor,
    textAlign: "center",
    margin: "0 0 16px 0",
  },
  "& .modal-para": {
    fontSize: "14px",
    fontWeight: 500,
    color: colors().slateGray,
    textAlign: "center",
    margin: "0 12px 0 0",
  },
  "& .modal-area-wrapper": {
    display: "flex",
    flexDirection: "column"
  },
  "& .modal-list-item": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px 16px",
    margin: "6px 0 0 0",
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 600,
    borderRadius: "8px",
    width: "100%",
    "&:nth-child(odd)": {
      border: `1px solid ${colors().lightborder}`,
    },
    "&:nth-child(even)": {
      border: `1px solid ${colors().lightborder}`,
      background: colors().lightborder
    }
  },
  "& .button-block": {
    display: "flex",
    alignItems: "center",
    margin: "24px 0 0 0"
  },
});

const StyledCheckbox = styled(Checkbox)({
  "&.MuiCheckbox-root": {
    width: "20px",
    height: "20px",
    borderRadius: "6px",
  },
  "&.Mui-checked": {
    color: `${colors().cyancobaltblue} !important`,
  },
});
export default SectionList;
// Customizable Area End
