import React from 'react';
import { Tooltip, styled, Box, Typography } from '@material-ui/core';
import { withStyles, Theme } from '@material-ui/core/styles'

type DataItem = {
    label: string;
    value: number;
    color: string;
};

type Segment = {
    path: string;
    color: string;
    label: string;
    value: number;
    percentage: number;
    index: number;
};

interface DonutChartProps {
    data: DataItem[];
    holeSize?: number;
    width?: number;
    height?: number;
    centerText?: string;
    centerTextClassName?: string;
}

const CustomDonutChart: React.FC<DonutChartProps> = ({
    data,
    holeSize = 0.8,
    width = 300,
    height = 300,
    centerText,
    centerTextClassName = 'center-text',
}) => {

    const calculateSegments = (): Segment[] => {
        const total = data.reduce((sum, item) => sum + item.value, 0);

        const singleDataPoint = data.find((item) => item.value === total);

        if (singleDataPoint) {
            return [
                {
                    path: "M 50,0 A 50,50 0 1,0 50,100 A 50,50 0 1,0 50,0 Z",
                    color: singleDataPoint.color,
                    label: singleDataPoint.label,
                    value: singleDataPoint.value,
                    percentage: 100,
                    index: 0,
                },
            ];
        }

        let startAngle = 0;
        return data.map((item, index) => {
            const sliceAngle = total === 0 ? 0 : (item.value / total) * 360;
            const endAngle = startAngle + sliceAngle;
            const segmentPath = describeArc(50, 50, 50, 50 * holeSize, startAngle, endAngle);

            const result = {
                path: segmentPath,
                color: item.color,
                label: item.label,
                value: item.value,
                percentage: total === 0 ? 0 : (item.value / total) * 100,
                index,
            };

            startAngle = endAngle;
            return result;
        });
    };

    const describeArc = (
        x: number,
        y: number,
        outerRadius: number,
        innerRadius: number,
        startAngle: number,
        endAngle: number
    ): string => {
        if (startAngle === endAngle) {
            return ''; // Return an empty string for 0 angle
        }

        const innerStart = polarToCartesian(x, y, innerRadius, endAngle);
        const innerEnd = polarToCartesian(x, y, innerRadius, startAngle);
        const outerStart = polarToCartesian(x, y, outerRadius, endAngle);
        const outerEnd = polarToCartesian(x, y, outerRadius, startAngle);

        const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

        const d = [
            'M', outerStart.x, outerStart.y,
            'A', outerRadius, outerRadius, 0, largeArcFlag, 0, outerEnd.x, outerEnd.y,
            'L', innerEnd.x, innerEnd.y,
            'A', innerRadius, innerRadius, 0, largeArcFlag, 1, innerStart.x, innerStart.y,
            'Z',
        ].join(' ');

        return d;
    };

    const polarToCartesian = (
        centerX: number,
        centerY: number,
        radius: number,
        angleInDegrees: number
    ): { x: number; y: number } => {
        const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
        return {
            x: centerX + radius * Math.cos(angleInRadians),
            y: centerY + radius * Math.sin(angleInRadians),
        };
    };

    const segments = calculateSegments();
    const total = data.reduce((sum, item) => sum + item.value, 0);

    return (
        <StyledWrapper>
            {total > 0 ?
                <div className="donut-chart-container" style={{ width, height }}>
                    <svg viewBox="0 0 100 100" className="donut-chart">
                        {segments.map((segment) => (
                            segment.path && (
                                <CustomTooltip arrow key={segment.index} title={`${segment.label}: ${segment.value} (${segment.percentage.toFixed(2)}%)`} placement='right'>
                                    <path
                                        d={segment.path}
                                        fill={segment.color}
                                        className="segment"
                                    />
                                </CustomTooltip>
                            )
                        ))}
                    </svg>
                </div> :
                <div style={{ width, height }} className='noDataContainer'>
                    <Typography className="label-600-16">No Data</Typography>
                </div>
            }
        </StyledWrapper>
    );
};

const StyledWrapper = styled(Box)({
    "& .noDataContainer":{
        display:"grid",
        placeItems:"center",
    },
    "& .label-600-16":{
        fontSize: "16px",
        fontFamily: "Montserrat",
        fontWeight: 400
    },
    "& .donut-chart-container": {
        "position": "relative"
    },
    "& .donut-chart": {
        "width": "100%",
        "height": "100%"
    },
    "& .segment": {
        "transition": "opacity 0.3s",
        "stroke": "white",
        "strokeWidth": "0.5",
        "&:hover": {
            "opacity": "0.8"
        },
    },
    "& .center-text": {
        "fontSize": "13px",
        "fill": "#19a110",
        "fontWeight": "bold",
        "fontFamily": "Arial, sans-serif"
    }
})

const CustomTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
    arrow: {
        color: theme.palette.common.white,
    }
}))(Tooltip);

export default CustomDonutChart;
