import { SectionWiseData } from "./Cfsalesdashboard3Controller"
import { IUserContext } from "../../navigationmenu/src/PageContainerController.web";

type ChartType = {
    data: Array<{
      section: string;
      sub_total: number;
      vat: number
    }>
}

type SummarySale = {
  data: Array<{
    section: string;
    discount: string;
    sub_total: string;
    vat: number;
    net: string
  }>
}

type IMultiSelectOption = {
  id: string;
  option: string;
}

export const transformSectionWiseData = (apiResponse: SectionWiseData) => {
  const dataChart = apiResponse.data.sections_data.map(section => ({
    name: section.section,
    value_1: section.orders,
    value_2: section.pieces
  }))

  const dataHeader = [{ name: "", value_1: apiResponse.data.total_orders, value_2: apiResponse.data.total_pieces }]

  return { dataChart, dataHeader }
}

export const handleUserContext = (context: IUserContext) => {
  const { region, store_management } = context.user?.attributes.employee_proifle.data.attributes || {}
  const regionId = region?.data.attributes.id.toString() ?? ""
  const regionName = region?.data.attributes.branch_name.toString() ?? ""
  const storeId = store_management?.store_id?.toString() ?? ""
  const storeName = store_management?.store_name?.toString() ?? ""
  return {regionId, regionName, storeId, storeName}
}

export const transformDataChart = (dataResponse: ChartType) => {
    const transform = dataResponse.data.map((response, index) => ({
      name: response.section,
      value_1: response.vat,
      value_2: response.sub_total
    }))
    return transform
}

export const transformDataSummary = (dataResponse: SummarySale) => {
  const transform = dataResponse.data.map((response) => ({
    name: response.section,
    value_1: response.vat,
    value_2: response.sub_total
  }))
  return transform
}

export const handleSectionWiseDataResponse = (apiResponse: SectionWiseData) => {
  const newData = apiResponse.data.sections_data.map(section => ({
    name: section.section,
    value_1: section.orders,
    value_2: section.pieces
  }))

  const headerData = apiResponse.data.sections_data.map(section => ({
    name: section.section,
    value: section.orders,
  }))

  return { newData, headerData }
}

export const getRegionStoreParam = (storeId: string, regionId: string,groupId?: string, areaId?:string) => {
    const regionParam = regionId ? `region_ids=${regionId}` : ""
    let storeParam = storeId ? `&store_ids=${storeId}` : ""
    const groupParam = groupId  ? `&group_ids=${groupId}` : ""
    const areaParam = areaId  ? `&area_ids=${areaId}` : ""
    storeParam = regionId ? storeParam + groupParam + areaParam : ""
    const regionStoreParam = regionParam + storeParam
    return regionStoreParam
}

export const removeSarUnit = (value?: string | number) => {
  const removedSarStr = (value ?? "").toString().replace("SAR ", "")
  return removedSarStr
}

export const getStoreNameDisplay = (storeName: string) => {
  const storeArr = storeName.split(",").length
  const isAllStore = storeName === "" || storeName === "All Stores"
 return  isAllStore ? "All Stores" : `Selected Store (${storeArr})` 
}

export const getGroupNameDisplay = (groupName: string) => {
  const groupArr = String(groupName)?.split(",").length
  return groupName ? `Selected Group (${groupArr})` : `Selected Group (0)`
}

export const getNewOptionsListFromSelection = (isSelectedAll: boolean, listSelected: IMultiSelectOption[]) => {
  const onlySelectedAllValue = isSelectedAll ? [{ id: "-1", option: "Select All" }] : listSelected
  const combineSelectedList = isSelectedAll ? [{ id: "-1", option: "Select All" },...listSelected] : listSelected
  return {
    onlySelectedAllValue,
    combineSelectedList
  }
}

//Next Custom Function
export const handleSectionWiseDataResponseNext = (apiResponseNext: SectionWiseData) => {
  const newDatas = apiResponseNext.data.sections_data.map(section => ({
    name_next: section.section,
    value_1_next: section.orders,
    value_2_next: section.pieces
  }))

  const headerDatas = apiResponseNext.data.sections_data.map(section => ({
    name_next: section.section,
    value_next: section.orders,
  }))

  const footerDatas = apiResponseNext.data.sections_data.map(section => ({
    footerValueNext1: section.section,
    footerValueNext2: section.orders,
  }))

  const dataOrders = apiResponseNext.data.sections_data.map(section => (section.orders))
  const dataPieces = apiResponseNext.data.sections_data.map(section => (section.pieces))
  const dataSections = apiResponseNext.data.sections_data.map(section => (section.section))

  return { newDatas, headerDatas, footerDatas, dataOrders, dataPieces, dataSections }
}

