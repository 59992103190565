// Customizable Area Start
import React from "react";
import {
  Grid,
  Button,
  InputLabel,
  Box,
  styled,
  CircularProgress,
  Snackbar,
  InputAdornment,
  Typography
} from "@material-ui/core";
import { Formik } from "formik";
import Alert from "@material-ui/lab/Alert";
import * as Yup from "yup";
import {
  InputField,
  CustomPaper,
} from "../../../components/src/customComponents/CustomTags.web";
import AutocompleteSelect from "../../../components/src/AutocompleteSelect.web";
import { colors } from "../../../blocks/utilities/src/Colors";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";
import B2BCustomerCreationController, {
  IB2BCompanyDropDown,
  IB2BCustomerGroupDropdown,
  IB2BPriceListDropDown,
  IBusinessCustomerDropDown,
  IGoogleDataOption,
  Props,
  configJSON,
} from "./B2BCustomerCreationController.web";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { locationIcon, radioWhite, radioBlue } from "./assets";
 
// Customizable Area End

export class B2BCustomerCreation extends B2BCustomerCreationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  finalErrorMessage(error: boolean, touch: boolean) {
    if (error) {
      if (touch) {
        return (
          <div
            style={{
              color: "red",
              fontSize: "14px",
              margin: "5px 5px 5px 0",
              fontFamily: "Montserrat",
            }}
          >
            {error}
          </div>
        );
      } else {
        return null;
      }
    } else {
      return;
    }
  }

  headerText() {
    return configJSON.txtCreateCustomer;
  }

  buttonText() {
    if (this.state.buttonLoading) {
      return (
        <CircularProgress style={{ width: 16, height: 16, color: "white" }} />
      );
    } else {
      if(this.isEdit){
        return configJSON.btnTxtSave;
      }
      return configJSON.btnTxtAdd;
    }
  }

  getValidation = () => {
    const phoneRegex: any = '^[0-9+]+$';
    return Yup.object({
      name: Yup.string()
        .trim()
        .required(configJSON.customerValidation.nameValidation),
      email: Yup.string()
        .trim()
        .notRequired()
        .nullable()
        .email("Invalid email"),
      employee_id: Yup.string()
      .trim()
      .required(configJSON.customerValidation.employeeIdValidation),
      mobile_number_prefix: Yup.string()
        .matches(phoneRegex, configJSON.customerValidation.invalidMobilePrefix),
      mobile_number: Yup.string()
        .trim()
        .required(configJSON.customerValidation.mobileRequired),
      company: Yup.string()
        .required(configJSON.customerValidation.compayNameRequired),
      gender: Yup.string()
        .required(configJSON.customerValidation.genderRequired),
        business_customer_name: Yup.string()
        .required(configJSON.customerValidation.customerNameRequired),
    });
  }

  handleReturnPricelistName = () => {
    const { selectedPricelist } = this.state;
    return selectedPricelist ? selectedPricelist.name : "";
  };

  render() {

    const b2bCompanyListData = this.state.b2bCompanyList.map(
      (item: IB2BCompanyDropDown) => ({
        id: item.id,
        option: item.attributes.name,
      })
    );

    const b2bPriceListData = this.state.b2bPriceList.map(
      (item: IB2BPriceListDropDown) => ({
        id: item.id,
        option: item.attributes.name,
      })
    );

    const b2bCustomerGrouptData = this.state.b2bCustomerGroupList.map(
      (item: IB2BCustomerGroupDropdown) => ({
        id: item.id,
        option: item.attributes.group_name,
      })
    );

    const b2bBusinessCustomertData = this.state.b2bBusinessCustomerList.map(
      (item: IBusinessCustomerDropDown) => ({
        id: item.id,
        option: item.attributes.business_customer,
      })
    );

    return (
      <>
        <Box>
          <CustomLoader loading={this.state.isLoading || this.state.isEditLoading}/>
          <Box>
            <StyleDiv>
              <PageTitle>{this.headerText()}</PageTitle>
            </StyleDiv>
            <Formik
              data-test-id='customerCreationFormik'
              initialValues={this.state.customerInitialValues}
              onSubmit={(values) => {
                this.handleSubmit(values);
              }}
              validationSchema={this.getValidation()}
              enableReinitialize
            >
              {({
                errors,
                touched,
                handleSubmit,
                getFieldProps,
                setFieldValue,
                values,
              }: any) => (
                <form onSubmit={handleSubmit} data-test-id='addPriceForm'>
                  <CustomPaper>
                    <StyleGrid container direction='column'>
                      <Grid container spacing={2} id='modal-content'>
                        <Grid item xs={12} sm={8} md={6}>
                          <StyledInputLabel data-test-id='lblTxtName'>
                            {configJSON.customerFields.txtLblName}*
                          </StyledInputLabel>
                          <InputField
                            inputProps={{
                              "data-test-id": "field-name",
                            }}
                            data-test-id='txtName'
                            error={this.checkError(errors.name, touched.name)}
                            id='name'
                            name='name'
                            fullWidth={true}
                            placeholder='Enter Customer Name'
                            variant='outlined'
                            {...getFieldProps("name")}
                          />
                          {this.finalErrorMessage(errors.name, touched.name)}
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                          <StyledInputLabel>
                            {configJSON.customerFields.txtLblMobileNumber}*
                          </StyledInputLabel>
                          <Grid container spacing={2}>
                            <Grid item xs={4} sm={3} md={3} lg={2}>
                              <InputField
                                inputProps={{
                                  "data-test-id": "field-prefix",
                                }}
                                error={this.checkError(
                                  errors.mobile_number_prefix,
                                  touched.mobile_number_prefix
                                )}
                                id='mobile_number_prefix'
                                name='mobile_number_prefix'
                                fullWidth={true}
                                placeholder='Enter Prefix'
                                variant='outlined'
                                InputProps={{
                                  startAdornment: 
                                  <InputAdornment style={{marginRight: "2px"}} position="start">
                                  <p style={{color:"black"}}>+</p>
                                  </InputAdornment>,
                                }}
                                {...getFieldProps("mobile_number_prefix")}
                              />
                            </Grid>
                            {this.finalErrorMessage(
                            errors.mobile_number_prefix,
                            touched.mobile_number_prefix
                          )}
                            <Grid item xs={8} sm={9} md={9} lg={10}>
                              <InputField
                                onFocus={(
                                  e: React.FocusEvent<HTMLInputElement>
                                ) => {
                                  e.target.addEventListener(
                                    "wheel",
                                    function(e: WheelEvent) {
                                      e.preventDefault();
                                    },
                                    { passive: false }
                                  );
                                }}
                                inputProps={{
                                  "data-test-id": "field-mobile",
                                }}
                                type='number'
                                error={this.checkError(
                                  errors.mobile_number,
                                  touched.mobile_number
                                )}
                                id='mobile_number'
                                name='mobile_number'
                                fullWidth={true}
                                placeholder='Mobile number'
                                variant='outlined'
                                {...getFieldProps("mobile_number")}
                              />
                            </Grid>
                          </Grid>
                          {this.finalErrorMessage(
                            errors.mobile_number,
                            touched.mobile_number
                          )}
                        </Grid>

                        <Grid item xs={12} sm={8} md={6} style={{marginTop:"20px"}}>
                          <StyledInputLabel>
                            {configJSON.customerFields.txtLblEmail}
                          </StyledInputLabel>
                          <InputField
                            inputProps={{
                              "data-test-id": "field-email",
                            }}
                            error={this.checkError(errors.email, touched.email)}
                            id='email'
                            name='email'
                            fullWidth={true}
                            placeholder='Enter Email Id'
                            variant='outlined'
                            {...getFieldProps("email")}
                          />
                          {this.finalErrorMessage(errors.email, touched.email)}
                        </Grid>

                        <Grid item xs={12} sm={8} md={6} style={{marginTop:"20px"}}>
                          <StyledInputLabelAutoComplete data-test-id='lblTxtCompany'>
                            {configJSON.customerFields.txtLblCompany}*
                          </StyledInputLabelAutoComplete>
                          <AutocompleteSelect
                            data-test-id='companyListDropdown'
                            fieldName='company'
                            placeholder='Type to Search Company Name'
                            onChange={(
                              _event: React.ChangeEvent,
                              value: {
                                id: string;
                                option: string;
                              }
                            ) =>
                              this.handleComapnySelection(value, setFieldValue)
                            }
                            value={values.company}
                            disableClear
                            options={b2bCompanyListData}
                            listBoxStyle={{ maxHeight: 270 }}
                          />
                         {this.finalErrorMessage(errors.company, touched.company)}
                        </Grid>

                        <Grid item xs={12} sm={8} md={6} style={{marginTop:"20px"}}>
                          <StyledInputLabelAutoComplete data-test-id='txtLblBusinessCustomer'>
                            {configJSON.customerFields.txtLblBusinessCustomer}*
                          </StyledInputLabelAutoComplete>
                          
                           <AutocompleteSelect
                            data-test-id='businessCustomerDropdown'
                            fieldName='business_customer_name'
                            placeholder='Select Business Customer'
                            onChange={(
                              _event: React.ChangeEvent,
                              value: {
                                id: string;
                                option: string;
                              }
                            ) =>
                              this.handleBusinessCustomerSelection(value, setFieldValue)
                            }
                            value={values.business_customer_name}
                            disableClear
                            options={b2bBusinessCustomertData}
                            listBoxStyle={{ maxHeight: 270 }}
                          />
                          {this.finalErrorMessage(
                            errors.business_customer_name,
                            touched.business_customer_name
                          )}
                        </Grid>

                        <Grid item xs={12} sm={8} md={6} style={{marginTop:"20px"}}>
                          <StyledInputLabelAutoComplete data-test-id='txtLblSelectCustomerGroup'>
                            {configJSON.customerFields.txtLblSelectCustomerGroup}
                          </StyledInputLabelAutoComplete>
                          <AutocompleteSelect
                            data-test-id='customerGroupListDropdown'
                            fieldName='customer_group'
                            placeholder='Select Customer Group'
                            onChange={(
                              _event: React.ChangeEvent,
                              value: {
                                id: string;
                                option: string;
                              }
                            ) =>
                              this.handleCustomerGroupSelection(value, setFieldValue)
                            }
                            value={values.customer_group}
                            disableClear
                            options={b2bCustomerGrouptData}
                            listBoxStyle={{ maxHeight: 270 }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={8} md={6} style={{marginTop:"20px"}}>
                          <StyledInputLabel data-test-id='txtLblDepartmentName'>
                            {configJSON.customerFields.txtLblDepartmentName}
                          </StyledInputLabel>
                          <InputField
                            inputProps={{
                              "data-test-id": "field-department-name",
                            }}
                            error={this.checkError(
                              errors.department_name,
                              touched.department_name
                            )}
                            id='department_name'
                            name='department_name'
                            fullWidth={true}
                            placeholder='Enter Department Name'
                            variant='outlined'
                            {...getFieldProps("department_name")}
                          />
                          {this.finalErrorMessage(
                            errors.department_name,
                            touched.department_name
                          )}
                        </Grid>

                        <Grid item xs={12} sm={8} md={6} style={{marginTop:"20px"}}>
                          <StyledInputLabel data-test-id='txtLblDepartmentId'>
                            {configJSON.customerFields.txtLblDepartmentId}
                          </StyledInputLabel>
                          <InputField
                            inputProps={{
                              "data-test-id": "field-department-id",
                            }}
                            error={this.checkError(
                              errors.department_id,
                              touched.department_id
                            )}
                            id='department_id'
                            name='department_id'
                            fullWidth={true}
                            placeholder='Enter Department ID'
                            variant='outlined'
                            {...getFieldProps("department_id")}
                          />
                          {this.finalErrorMessage(
                            errors.department_id,
                            touched.department_id
                          )}
                        </Grid>

                        <Grid item xs={12} sm={8} md={6} style={{marginTop:"20px"}}>
                          <StyledInputLabel data-test-id='txtLblEmployeeId'>
                            {configJSON.customerFields.txtLblEmployeeId}*
                          </StyledInputLabel>
                          <InputField
                            inputProps={{
                              "data-test-id": "field-employee-id",
                            }}
                            error={this.checkError(
                              errors.employee_id,
                              touched.employee_id
                            )}
                            id='employee_id'
                            name='employee_id'
                            fullWidth={true}
                            placeholder='Enter Employee ID'
                            variant='outlined'
                            {...getFieldProps("employee_id")}
                          />
                          {this.finalErrorMessage(
                            errors.employee_id,
                            touched.employee_id
                          )}
                        </Grid>

                        <Grid item xs={12} sm={8} md={6} style={{marginTop:"20px"}}>
                          <StyledInputLabel data-test-id='txtLblCostCenter'>
                            {configJSON.customerFields.txtLblCostCenter}
                          </StyledInputLabel>
                          <InputField
                            inputProps={{
                              "data-test-id": "field-cost-center",
                            }}
                            error={this.checkError(
                              errors.cost_center,
                              touched.cost_center
                            )}
                            id='cost_center'
                            name='cost_center'
                            fullWidth={true}
                            placeholder='Type or Type to Select'
                            variant='outlined'
                            {...getFieldProps("cost_center")}
                          />
                          {this.finalErrorMessage(
                            errors.cost_center,
                            touched.cost_center
                          )}
                        </Grid>

                        <Grid item xs={12} sm={8} md={6} style={{marginTop:"20px"}}>
                          <StyledInputLabel data-test-id='txtLblSubCostCenter'>
                            {configJSON.customerFields.txtLblSubCostCenter}
                          </StyledInputLabel>
                          <InputField
                            inputProps={{
                              "data-test-id": "field-sub-cost-center",
                            }}
                            error={this.checkError(
                              errors.subcost_center,
                              touched.subcost_center
                            )}
                            id='subcost_center'
                            name='subcost_center'
                            fullWidth={true}
                            placeholder='Type or Type to Select'
                            variant='outlined'
                            {...getFieldProps("subcost_center")}
                          />
                          {this.finalErrorMessage(
                            errors.subcost_center,
                            touched.subcost_center
                          )}
                        </Grid>

                        <Grid item xs={12} sm={8} md={6} style={{marginTop:"20px"}}>
                          <StyledInputLabelAutoComplete>
                            {configJSON.customerFields.txtLblGender}*
                          </StyledInputLabelAutoComplete>
                           <AutocompleteSelect
                            data-test-id='genderListDropdown'
                            fieldName='gender'
                            placeholder='Select Gender'
                            onChange={(
                              _event: React.ChangeEvent,
                              value: {
                                id: string;
                                option: string;
                              }
                            ) =>
                              this.handleGenderSelection(
                                value,
                                setFieldValue
                              )
                            }
                            value={values.gender}
                            disableClear
                            options={this.state.dropdownOptions?.genderList}
                            listBoxStyle={{ maxHeight: 270 }}
                          />
                          {this.finalErrorMessage(
                            errors.gender,
                            touched.gender
                          )}
                        </Grid>

                        <Grid item xs={12} sm={8} md={6} style={{marginTop:"20px"}}>
                          <StyledInputLabelAutoComplete data-test-id='txtLblAddress'>
                            {configJSON.customerFields.txtLblAddress}
                          </StyledInputLabelAutoComplete>
                          <CustomGooglePlacesAutocomplete data-test-id="GooglePlacesAutocomplete">
                            <GooglePlacesAutocomplete
                              apiKey={configJSON.googleMapAPI}
                              debounce={500}
                              selectProps={{
                                classNamePrefix: "react-select",
                                placeholder: "Type or Select",
                                noOptionsMessage: () => "Address not found",
                                onChange: (data: IGoogleDataOption) =>
                                  setFieldValue("street_address", data?.label),
                                value: values.street_address
                                  ? [{ value: values.street_address, label: values.street_address }]
                                  : null,
                                components: {
                                  Option: (props: any) => {
                                    const {
                                      data,
                                      innerRef,
                                      innerProps,
                                    } = props;
                                    const isSelected = props.getValue()[0]?.value == data?.value.description
                                    return (
                                      <Box
                                        ref={innerRef}
                                        {...innerProps}
                                        className={`option-item ${isSelected && "selected"}`}
                                      >
                                        <img
                                          alt='radio'
                                          src={
                                            isSelected ? radioBlue : radioWhite
                                          }
                                        />
                                        <Typography>{data?.label}</Typography>
                                      </Box>
                                    );
                                  },
                                },
                              }}
                            />
                          </CustomGooglePlacesAutocomplete>
                        </Grid>

                        <Grid item xs={12} sm={8} md={6} style={{marginTop:"20px"}}>
                          <StyledInputLabelAutoComplete data-test-id='txtLblCity'>
                            {configJSON.customerFields.txtLblCity}
                          </StyledInputLabelAutoComplete>
                          <AutocompleteSelect
                            data-test-id='cityListDropdown'
                            fieldName='city'
                            placeholder='Select City'
                            onChange={(
                              _event: React.ChangeEvent,
                              value: {
                                id: string;
                                option: string;
                              }
                            ) =>
                              this.handleCitySelection(
                                value,
                                setFieldValue
                              )
                            }
                            value={values.city}
                            disableClear
                            options={this.state.dropdownOptions.cityList}
                            listBoxStyle={{ maxHeight: 270 }}
                          />
                          {this.finalErrorMessage(errors.city, touched.city)}
                        </Grid>

                        <Grid item xs={12} sm={8} md={6} style={{marginTop:"20px"}}>
                          <StyledInputLabel data-test-id='txtLblPinCode'>
                            {configJSON.customerFields.txtLblPinCode}
                          </StyledInputLabel>
                          <InputField
                            inputProps={{
                              "data-test-id": "field-postal-code",
                            }}
                            error={this.checkError(
                              errors.postal_code,
                              touched.postal_code
                            )}
                            id='postal_code'
                            name='postal_code'
                            fullWidth={true}
                            placeholder='Enter Pin Code'
                            variant='outlined'
                            {...getFieldProps("postal_code")}
                          />
                          {this.finalErrorMessage(
                            errors.postal_code,
                            touched.postal_code
                          )}
                        </Grid>

                        <Grid item xs={12} sm={8} md={6} style={{marginTop:"20px"}}>
                          <StyledInputLabel data-test-id='txtLblNotes'>
                            {configJSON.customerFields.txtLblNotes}
                          </StyledInputLabel>
                          <InputField
                            inputProps={{
                              "data-test-id": "field-notes",
                            }}
                            error={this.checkError(errors.notes, touched.notes)}
                            id='notes'
                            name='notes'
                            fullWidth={true}
                            placeholder='Enter Notes'
                            variant='outlined'
                            {...getFieldProps("notes")}
                          />
                          {this.finalErrorMessage(errors.notes, touched.notes)}
                        </Grid>

                        <Grid item xs={12} sm={8} md={6} style={{marginTop:"20px"}}>
                          <StyledInputLabel data-test-id='txtLblPrivateNotes'>
                            {configJSON.customerFields.txtLblPrivateNotes}
                          </StyledInputLabel>
                          <InputField
                            inputProps={{
                              "data-test-id": "field-private-notes",
                            }}
                            error={this.checkError(
                              errors.private_notes,
                              touched.private_notes
                            )}
                            id='private_notes'
                            name='private_notes'
                            fullWidth={true}
                            placeholder='Enter Private Notes'
                            variant='outlined'
                            {...getFieldProps("private_notes")}
                          />
                          {this.finalErrorMessage(
                            errors.private_notes,
                            touched.private_notes
                          )}
                        </Grid>

                        <Grid item xs={12} sm={8} md={6} style={{marginTop:"20px"}}>
                          <StyledInputLabel data-test-id='txtLblPriceList'>
                            {configJSON.customerFields.txtLblPriceList}
                          </StyledInputLabel>
                          <InputField
                            inputProps={{
                              "data-test-id": "field-price-list"
                            }}
                            disabled={true}
                            value={this.handleReturnPricelistName()}
                            id='price_list'
                            name='price_list'
                            fullWidth={true}
                            placeholder='Price List'
                            variant='outlined'
                          />
                        </Grid>

                        <Grid item xs={12} sm={8} md={6} style={{marginTop:"20px"}}>
                          <StyledInputLabel data-test-id='txtLblNoOfOrders'>
                            {configJSON.customerFields.txtLblNoOfOrders}
                          </StyledInputLabel>
                          <InputField
                            type='number'
                            inputProps={{
                              "data-test-id": "field-nooforder",
                            }}
                            value={this.handleCheckNullVal(this.state.noOfOrdrers)}
                            disabled={true}
                            id='no_of_orders'
                            name='no_of_orders'
                            fullWidth={true}
                            placeholder='No Of Orders'
                            variant='outlined'
                          />
                        </Grid>

                        <Grid item xs={12} sm={8} md={6} style={{marginTop:"20px"}}>
                          <StyledInputLabel data-test-id='txtLblNoOfPiecesOrders'>
                            {configJSON.customerFields.txtLblNoOfPiecesOrders}
                          </StyledInputLabel>
                          <InputField
                            type='number'
                            inputProps={{
                              "data-test-id": "field-nooforder-pieces",
                            }}
                            value={this.handleCheckNullVal(this.state.noOfPiecesOrders)}
                            disabled={true}
                            id='no_of_pieces_per_order'
                            name='no_of_pieces_per_order'
                            fullWidth={true}
                            placeholder='No. of Pieces per Order'
                            variant='outlined'
                          />
                        </Grid>
                      </Grid>
                    </StyleGrid>
                  </CustomPaper>
                  <StyleBox className={"actionButtonSection"}>
                    <StyledCancelButton
                      data-test-id='cancelBtn'
                      onClick={() => this.handleRedirect()}
                    >
                      {configJSON.btnTxtCancel}
                    </StyledCancelButton>
                    <StyledButton data-test-id='btn-submit' type='submit'>
                      {this.buttonText()}
                    </StyledButton>
                  </StyleBox>
                </form>
              )}
            </Formik>
          </Box>
          <Snackbar
              data-test-id='errorMsg'
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={this.state.snackbarOpen}
              autoHideDuration={4000}
              onClose={this.handleSnackbarClose}
            >
              <Alert severity={this.state.snakcbarSeverity}>{this.state.snackbarMessage}</Alert>
            </Snackbar>
        </Box>
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start

const StyleDiv = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  margin: "30px 0px",
  alignItems: "center",
});

const PageTitle = styled("div")({
  fontFamily: "Montserrat",
  fontWeight: 600,
  fontStyle: "normal",
  lineHeight: "29px",
  fontSize: "24px",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "16px",
  },
});

const StyledInputLabel = styled(InputLabel)({
  fontSize: "16px",
  fontFamily: "Montserrat",
  lineHeight: "15.6px",
  fontWeight: 600,
  color: "#334155",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "13px",
  },
  "& .compensationtxt": {
    display: "inline-block",
    color: colors().cyancobaltblue,
    fontWeight: 600,
  },
});

const StyledInputLabelAutoComplete = styled(InputLabel)({
  fontSize: "16px",
  fontFamily: "Montserrat",
  lineHeight: "15.6px",
  fontWeight: 600,
  color: "#334155",
  marginBottom: "8px",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "13px",
  },
  "& .compensationtxt": {
    display: "inline-block",
    color: colors().cyancobaltblue,
    fontWeight: 600,
  },
});

const StyledButton = styled(Button)({
  height: "56px",
  width: "184px",
  background: "rgb(32, 75, 156)",
  backgroundColor: "rgb(32, 75, 156)",
  color: "rgb(255, 255, 255)",
  fontWeight: 600,
  fontSize: "16px",
  borderRadius: "8px",
  lineHeight: "24px",
  marginLeft: "10px",
  textTransform: "unset",
  cursor: "pointer",
  border: "none",
  "&:hover": {
    background: "#204B9C",
    color: "#FFFFFF",
  },
  "@media only screen and (max-width: 1024px)": {
    height: "44px !important",
    width: "126px !important",
  },
});

const StyledCancelButton = styled(Button)({
  height: "56px",
  width: "184px",
  background: "rgb(32, 75, 156)",
  fontWeight: 600,
  fontSize: "16px",
  borderRadius: "8px",
  lineHeight: "24px",
  marginLeft: "10px",
  textTransform: "unset",
  backgroundColor: "#F1F5F9 !important",
  color: "#64748B !important",
  "@media only screen and (max-width: 1024px)": {
    height: "44px !important",
    width: "126px !important",
  },
});

const StyleGrid = styled(Grid)({
  "& .dropdownTextbox": {
    borderRadius: 8,
  },
  "& .dropdownDefaultValue": {
    display: "none",
  },
  "& .actionButtonSection": {
    marginTop: "48px",
    marginBottom: "40px",
    display: "flex",
    flexWrap: "wrap-reverse",
  },
});

const StyleBox = styled(Box)({
  marginTop: "48px",
  marginBottom: "40px",
  display: "flex",
  flexWrap: "wrap-reverse",
})

const CustomGooglePlacesAutocomplete = styled(Box)({
  "& .react-select__indicator": {
    display: "none",
  },
  "& .react-select__indicators": {
    minHeight: "24px",
    minWidth: "24px",
    margin: "auto 8px",
    backgroundImage: `url(${locationIcon})`,
  },
  "& .react-select__indicator-separator": {
    display: "none"
  },
  "& .react-select__input-container": {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "14px",
    paddingTop: 0,
    paddingBottom: 0,
    margin: 0
  },
  "& .react-select__placeholder": {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "14px",
  },
  "& .react-select__value-container": {
    padding: "18px 8px"
  },
  "& .react-select__control": {
    borderRadius: "8px"
  }, 
  "& .option-item": {
    padding: "6px 16px",
    display: "flex",
    gap: 8,
    "& p": {
      fontFamily: "Montserrat",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px"
    }
  },
  "& .option-item.selected": {
    "& p": {
      color: "#204B9C"
    }
  }
});

export default B2BCustomerCreation;
// Customizable Area End
