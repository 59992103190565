import {MenuProps } from "@material-ui/core"

export const productTypes = {
  NORMAL: "noraml",
  SUBPRODUCT: "Sub-Product",
  PARENT: "Parent",
};

export const CustomMenuUpchargeDrop: Partial<MenuProps> = {
  PaperProps: {
    style: { 
      maxHeight: 250,
      width: 300
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

export interface CatalogueDataList {
  id: string;
  type: string;
  attributes: {
    id: number;
    product_name: string;
    product_second_name: string;
    minimum_washing_cycle: number;
    maximum_washing_cycle: number;
    preference_id: number;
    created_at: string;
    updated_at: string;
    order_number: number;
    product_type_id: string;
    preference: {
      data: PreferenceData;
    };
    sub_products: {
      data: any[];
    };
    catalogue_variants: CatalogueVariant[];
    name: string;
  };
}

interface PreferenceData {
  id: string;
  type: string;
  attributes: {
    id: number;
    icon_id: number;
    preference_first_name: string;
    preference_second_name: string;
    active: boolean;
    gallery_icon: {
      id: number;
      image: string;
      name_translation: string;
    };
    name: string;
  };
}

interface CatalogueVariant {
  id: string;
  type: string;
  attributes: {
    id: number;
    pieces: number;
    active: boolean;
    created_at: string;
    updated_at: string;
    catalogue: {
      id: string;
      type: string;
      attributes: {
        id: number;
        product_name: string;
        product_second_name: string;
        name: string;
      };
    };
    name: string;
    image: {
      id: number;
      image: string;
    };
    measurement: {
      id: number;
      value: string;
    };
    product_currency_id: string;
    catalogue_variants_services: {
      data: CatalogueService[];
    };
  };
}

export interface CatalogueService {
  id: string;
  type: string;
  attributes: {
    id: number;
    price: string;
    catalogue_variant_id: number;
    default: boolean;
    currency: string;
    service: {
      data: ServiceData;
    };
  };
}

export interface ServiceData {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    second_name: string;
    short_name: string;
    icon_id: number;
    active: boolean;
    online_order: boolean;
    order_number: number;
    icon: {
      data: IconData;
    };
    name_translation: string;
  };
}

interface IconData {
  id: string;
  type: string;
  attributes: {
    id: number;
    image: string;
    name: string;
    second_name: string;
    image_type: string;
    name_translation: string;
  };
}

export interface IUpchargeListItem {
  attributes: {
    id: number;
    name: string;
    price: number;
    upcharge_type: string;
    icon: {
      attributes: {
        image: null;
        name: string;
        second_name: string;
        image_type: string;
      };
    };
  };
}

export interface IpreferenceData {
  id: string;
  type: string;
  attributes: {
    specifications: never[];
    id: number;
    icon_id: number;
    preference_first_name: string;
    preference_second_name: string;
    active: boolean;
    gallery_icon: {
      id: number;
      image: string;
      name_translation: string;
    };
    name: string;
  };
}
export interface IAdjustCartProduct {
  id: undefined | number;
  catalogue_id: number;
  catalogue_variant_id: number;
  name: string;
  product_name: string;
  product_second_name: string;
  unit_price: number;
  image: string;
  quantity: number;
  service_name: string;
  service_id: number;
  parent_Product_id: null | string | number;
  category_id: number;
  upcharge_list_ids: IUpchargeListItem[];
  preference_id: number;
  isCarpetAndFurnitureProduct?: boolean;
  notes?: string;
  height?: number;
  width?: number;
  weight?: number;
}

export interface SelectedService {
  name: string;
  id: number;
}

export interface CreatePreferencesData {
  id: string;
  notes: string;
  preferenceId: number;
  saveForFuture: null | boolean;
  selectedOptions: {
    option_id: number;
    specification_id: number;
  }[];
  serviceId: number;
  upchargeListIds: Array<number>;
}

export interface IadjustSpecification {
  id: number;
  name: string;
  options_attributes: {
    id: number;
    label: string;
    selected: boolean;
  }[];
}

export interface UpdateOrderBody {
  specifications: unknown;
  preference_id: number;
  upcharge_list_ids: Array<number>;
  catalogue_variant_id: number;
}

export interface SpecificationSelected {
  Color: string;
  Damage: string;
  Material: string;
  Strains: string;
}

export interface UniqueCombination {
  service_id: number;
  category_id: number;
}

export interface CancelOrderResponse {
  status: number;
  cancel_order: boolean;
}

export interface CartsItemsPreferencesData {
  catalogue_variant_id: number;
  catalogue_preference_id: number;
  service_id: number;
}

export interface StoreManagementDetails {
  data: {
    attributes: {
      store_configuration: {
        attributes: {
          price_list_id: number;
          item_preference_popup: boolean;
          sections: {
            id: number,
            name: string
          }[]
        }
      }
    }
  }
}

export interface EditSpecificationType {
  [key: string]: { id: number; option: string }[];
}